import classNames from 'classnames'
import { get, isFunction } from 'lodash'
import React from 'react'

const FeeFieldPreview = ({
  formik: { values },
  name,
  prefix,
  format,
  placeholder,
  placeholderOnly,
  suffix,
  ...props
}) => {
  const price = get(values, 'price')
  const customFeeMessage = get(values, 'customFeeMessage')

  let value = price || customFeeMessage

  if (format && isFunction(format)) value = format(value, values)

  let content = ''

  if (placeholderOnly) {
    if (placeholder && value) {
      content = placeholder
    }
  } else {
    content = (
      <>
        {prefix}
        {value || placeholder}
        {' '}
        {suffix}
      </>
    )
  }

  return (
    <div {...props.props} className={classNames('PreviewText', props.className, { placeholderOnly })}>
      {content}
    </div>
  )
}

export default FeeFieldPreview
