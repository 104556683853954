import React, { useEffect } from 'react'
import { connect, getIn } from 'formik'
import { SelectField, TextField } from '.'

const OrderDeadline = ({
 formik: { values, setFieldValue }, description, options, ...rest
}) => {
  useEffect(() => {
    const orderDeadline = getIn(values, 'orderDeadline')
    const orderDeadlineFormat = getIn(values, 'orderDeadlineFormat')
    if (orderDeadline === '') {
      setFieldValue('orderDeadlineFormat', 'hours')
    } else if (orderDeadline >= 60) {
      if (orderDeadlineFormat && orderDeadlineFormat == 'hours') {
        setFieldValue('orderDeadlineFormat', orderDeadlineFormat)
        return
      }
      if (orderDeadline % 60 === 0) {
        setFieldValue('orderDeadline', orderDeadline / 60)
        setFieldValue('orderDeadlineFormat', 'hours')
      } else {
        setFieldValue('orderDeadline', orderDeadline)
        setFieldValue('orderDeadlineFormat', 'minutes')
      }
    } else {
      setFieldValue('orderDeadlineFormat', orderDeadlineFormat || 'minutes')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row">
      <div className="col-sm-3">
        <TextField name="orderDeadline" data-cy="orderDeadline" {...rest} />
      </div>
      <div className="col-sm-3 no-padding">
        <SelectField name="orderDeadlineFormat" options={options} />
      </div>
      <div className="col-sm-6" style={{ marginTop: 6 }}>
        <div className="after">Before check out time</div>
      </div>
      <div className="col-sm-12" style={{ marginTop: 10 }}>
        {description && <div className="after warning-background">{description}</div>}
      </div>
    </div>
  )
}

export default connect(OrderDeadline)
