import React from 'react'
import { TextField } from '../../forms/fields'
import RequestIconsList from '../../components/contact-host/RequestIconsList'
import { getIn, connect } from 'formik'

import '../../styles/TitleAndIconsGroup.scss'

const TitleAndIconsGroup = connect(({ saveOnExit, title, iconURL, readOnly, formik: { values, setFieldValue } }) => {
  const initialIconURL = getIn(values, iconURL)

  return (
    <div className="title-icon-group">
      <TextField
        name={title}
        saveOnExit={!readOnly && saveOnExit}
        readOnly={readOnly}
        placeholder="Title"
        onChange={e => {
          setFieldValue(title, e.target.value)
        }}
      />
      <RequestIconsList
        onChange={value => {
          setFieldValue(iconURL, value)
        }}
        name={iconURL}
        topic={{ iconURL: initialIconURL }}
      />
    </div>
  )
})
export default TitleAndIconsGroup
