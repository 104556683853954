import React from 'react'
import {
 UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'

import { DotsIcon } from '../../icons'

export const ListOption = props => <DropdownItem {...props} />

const ListOptions = ({ children, style, ...props }) => {
  return (
    <UncontrolledButtonDropdown direction="left" onClick={evt => evt.stopPropagation()}>
      <DropdownToggle
        style={{
          margin: 0,
          color: '#3e92cc',
          background: 'transparent',
          border: 'none',
          borderRadius: 0,
          padding: '0px',
          display: 'flex',
          ...style,
        }}
        {...props}
      >
        <DotsIcon />
      </DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

ListOptions.defaultProps = {
  style: {},
}

export default ListOptions
