import React from 'react'
import Icon from './Icon'

const ClockIcon2 = () => {
  return (
    <Icon width="20" height="20" viewBox="0 0 12 13" fill="none">
      <path
        d="M5.98178 0.664429C2.85278 0.664429 0.307129 3.21008 0.307129 6.33908C0.307129 9.46809 2.85278 12.0137 5.98178 12.0137C9.11079 12.0137 11.6564 9.46809 11.6564 6.33908C11.6564 3.21008 9.11079 0.664429 5.98178 0.664429ZM5.98178 10.8788C3.47869 10.8788 1.44206 8.84217 1.44206 6.33908C1.44206 3.83599 3.47869 1.79936 5.98178 1.79936C8.48487 1.79936 10.5215 3.83599 10.5215 6.33908C10.5215 8.84217 8.48487 10.8788 5.98178 10.8788Z"
        fill="#5591C7"
      />
      <path
        d="M6.5485 3.50183H5.41357V6.57409L7.28224 8.44275L8.08463 7.64036L6.5485 6.10423V3.50183Z"
        fill="#5591C7"
      />
    </Icon>
  )
}

export default ClockIcon2
