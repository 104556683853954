import { COMMON_PREVIEW } from './commonPreview'
import { RebookSMSPreview } from '../../../forms/previews'

const REBOOK_NOTIFICATION_PREVIEW = {
  className: 'MobilePreview',
  title: 'Listing Preview',
  subtitle: 'Service Listing',
  children: [
    {
      className: 'sms-container has-thin-scroll scroll-grey',
      children: [
        {
          component: RebookSMSPreview,
        },
      ],
    },
  ],
}

export const REBOOK_PREVIEW = {
  0: [COMMON_PREVIEW.SERVICE_LISTING_PREVIEW, COMMON_PREVIEW.SERVICE_DETAIL_PREVIEW],
  1: [REBOOK_NOTIFICATION_PREVIEW],
  2: [],
}
