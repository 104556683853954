import React from 'react'
import adIconURL from '../images/icons/ad-original-icon.png'

const AdvertisementOriginalIcon = props => (
  <span fill="none" {...props}>
    <img src={adIconURL} alt="ads-icon" />
  </span>
)

export default AdvertisementOriginalIcon
