import React, { useState,useEffect } from 'react'
import { MESSAGES } from '../../constants/strings'
import ChipsInput from '../ChipsInput'
import Tooltip from '../Tooltip'

const EmailSetupPMSReport = ({dispatch, apiRequest}) => {
    const [inputValue, setInputValue] = useState('')
    const [selectedItem, setSelectedItem] = useState([])
    useEffect(() => {
        const fetchReportEmailAddress = async () => {
          const { json } = await dispatch(apiRequest(['/me/emailAddressForPmsReport'], 'GET'))
          if (json) {
            json.emailAddress && json.emailAddress.value && setSelectedItem(json.emailAddress.value.split(','))
          }
        }
        fetchReportEmailAddress()
      }, [])

    const setEmailAddress = async () => {
        await dispatch(
          apiRequest(['/me/setEmailAddressForPmsReport'], 'POST', {
            emailAddress: selectedItem.join(','),
          })
        )
      }
  return (

    <div className="w-50 email-report-setup">
      <div className="feature-name pt-3">
        <h4>{`${MESSAGES.EMAIL_SETUP}`}</h4>
      </div>
      <div>
        <h6 className="mt-1 mb-2">
          Email Recipients
          <Tooltip tabIndex={-1} target="email">
            Press enter after every email to add multiple recipients
          </Tooltip>
        </h6>

        <ChipsInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />

        <button className="button mt-3" onClick={setEmailAddress}>
          {MESSAGES.SAVE_EMAIL}
        </button>
      </div>
    </div>
  )
}

export default EmailSetupPMSReport;
