import winstonLogger from './winstonLogger'

const restrictedRoutes = ['/api/dashboard/pms/properties']

const filterRoutes = obj => {
  const copy = JSON.parse(JSON.stringify(obj))
  if (restrictedRoutes.some(route => obj.apiInfo.endpoint.includes(route))) {
    copy.metadata = null
  }
  return copy
}

const parseLogObj = obj => {
  let parsedObj = {
    module: obj.module || null,
    dateTime: obj.dateTime || new Date(),
    sessionId: obj.sessionId || null,
    metadata: obj.metadata || null,
    message: obj.message || null,
    error: obj.error || null,
    apiInfo: obj.apiInfo || null,
    httpResponse: obj.httpResponse || null,
  }

  parsedObj = filterRoutes(parsedObj)
  return parsedObj
}

const Logger = {
  http(obj) {
    const httpObj = parseLogObj(obj)
    const httpLog = `${httpObj.module} <=> ${httpObj.dateTime}
        ${httpObj.sessionId ? `Session ID: ${httpObj.sessionId}` : ''}
        ${httpObj.metadata ? `Metadata: ${JSON.stringify(httpObj.metadata)}` : ''}
        API Details: ${JSON.stringify(httpObj.apiInfo)}
        ${httpObj.httpResponse ? `Response: ${httpObj.httpResponse}` : ''}`

    winstonLogger.http(httpLog)
  },
}

export default Logger
