import React from 'react'

import Resource from '../Resource'
import { openModalWithContainer } from '../reducers/modals'

const AnnouncementContainer = ({ me, getConfigs }) => {
  let reAuthorizePMS;
  if (me
    && me.organization
    && me.organization.reservationService
    && me.organization.reservationServiceConfig
    && me.organization.reservationService === 'ownerRez'
    && !me.organization.reservationServiceConfig.access_token
    && getConfigs
    && getConfigs.reAuthorizeOwnerRezOrgIds) {
    const requireReAuthorizeForOwnerRez = getConfigs.reAuthorizeOwnerRezOrgIds.split(',').map(i => Number(i));
    if (!requireReAuthorizeForOwnerRez.includes(Number(me.organization.id))) return null;
    reAuthorizePMS = 'ownerRez';
  }
  return (
    <Resource collection path={['announcements']} placeholder={[]}>
      {(announcements, { dispatch, onRefresh }) => {
        if (announcements.length > 0 || reAuthorizePMS) {
          dispatch(
            openModalWithContainer('Announcements',
              {
                announcements,
                me,
                onRefresh,
                reAuthorizePMS,
                getConfigs,
              },
              { closeOnEscape: false }),
          )
        }
        return null
      }}
    </Resource>
  )
}

export default AnnouncementContainer
