import React from 'react'
import '../styles/User.scss'
import PhoneNumber from './PhoneNumber'
import fallbackAvatar from '../images/hostinput-fallback.png'
import { ListCellBadge } from './list'

function User({ user: { photoURL, name, contactNumber, isDefault } }) {
  return (
    <div className="User">
      <div className="avatar">
        <img src={photoURL || fallbackAvatar} alt="user-avatar" />
      </div>
      <div className="details">
        <div className="name">
          {name}
          {isDefault && <ListCellBadge label="Default" />}
        </div>
        <div className="number">
          <PhoneNumber>{typeof contactNumber !== 'object' && contactNumber}</PhoneNumber>
        </div>
      </div>
    </div>
  )
}

export default User
