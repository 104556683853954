import React from 'react'
import { ContentPicker } from '../../components/content'
import {
  WIFI, HOUSE_RULES, CONTACT_US, GENERAL_INFO,
} from '../../constants/forms/content'
import { GeneralInfo } from '.'
import { SECTIONS, SUB_SECTIONS } from '../../constants/thirdPartyAnalytics'
import ContactHostPicker from '../../components/contact-host/ContactHostPicker'

const PropertyDetails = ({ recordAnalytics, property: { id } }) => {
  const handleAnalytics = subSection => {
    recordAnalytics && recordAnalytics({ section: SECTIONS.PROPERTY_DETAILS, subSection })
  }

  return (
    <div className="PropertyDetails PropertyDetail">
      <div className="general">
        <h6>{GENERAL_INFO.title}</h6>
        <GeneralInfo recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.GENERAL_INFO)} />
      </div>

      <div className="rules">
        <h6>{HOUSE_RULES.title}</h6>
        <ContentPicker
          content={HOUSE_RULES}
          recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.HOUSE_RULES)}
          propertyId={id}
        />
      </div>
      <div className="wifi">
        <h6>{WIFI.title}</h6>
        <ContentPicker content={WIFI} recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.WIFI)} propertyId={id} />
      </div>
      <div className="contact">
        <h6>{CONTACT_US.title}</h6>
        <ContactHostPicker
          content={CONTACT_US}
          recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.CONTACT_US)}
          propertyId={id}
        />
      </div>
    </div>
  )
}

export default PropertyDetails
