import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { Wizard } from '../../components/wizard'
import { generateWizard } from '../../forms/utils'
import { MESSAGE_WIZARD } from '../../constants/forms/messages'
import Preview from '../../components/form-builder/Preview'
import { MESSAGE_PREVIEW_WIZARD } from '../../constants/forms/messagePreview'

const MessageContainer = ({
  message,
  message: {
 id, hostId, readReceipt, host,
},
  onSave,
  dispatch,
  isDuplicate,
  originalMessageId,
}) => {
  const titleComponent = props => (
    <ModalHeader
      name="Announcement"
      verb={{ create: 'Create an', duplicate: 'Duplicate an' }}
      hasItem={Object.keys(message).length !== 0}
      showProgressText={false}
      isDuplicate={isDuplicate}
      {...props}
    />
  )

  const initialMessageValue = {
    hostId,
    readReceipt,
    host,
    isDuplicate,
    originalMessageId,
  }

  const footerComponent = props => <ModalFooter {...props} />

  const onSaveText = message.id ? 'Update' : 'Publish'

  const formatValues = values => {
    delete values.undefined

    if (isDuplicate) return values
    return {
      id,
      ...values,
    }
  }

  const formValues = generateWizard({ form: MESSAGE_WIZARD, props: message })

  const formData = {
    ...formValues,
    initialValues: {
      ...formValues.initialValues,
      ...initialMessageValue,
    },
  }

  const preview = Preview('message', '', {})

  return (
    <Wizard
      titleComponent={titleComponent}
      bodyWrapper={ModalBody}
      footerWrapper={footerComponent}
      formData={formData}
      formatValues={formatValues}
      onSave={onSave}
      onSaveText={onSaveText}
      dispatch={dispatch}
      bodyWrapperClass="no-bg announcement-form"
      preview={preview}
      steps={[]}
    />
  )
}

export default MessageContainer
