import React from 'react'
import { withRouter } from 'react-router'
import { MESSAGES } from '../../constants/strings'

const BrandingCard = () => {
  return (
    <div className="db-welcome">
      <h6>{MESSAGES.BRANDING_YOUR_EXPERIENCE}</h6>
      <div className="welcome-block">
        <h4>{MESSAGES.WELCOME_SCREEN}</h4>
        <p>{MESSAGES.CUSTOMIZE_WELCOME_SCREEN_MESSAGE}</p>
        <button className="button">{MESSAGES.CUSTOMIZE_WELCOME_SCREEN}</button>
        <em>{`*${MESSAGES.REQUIRED_GUEST_RESERVATION}`}</em>
      </div>
    </div>
  )
}

export default withRouter(BrandingCard)
