import React from 'react'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
import Resource from '../Resource'
import { asyncDelay } from '../Utils'
import { Loading } from '../components/PageStates'
import { Tabs } from '../components'
import {
  PropertyDetailHeader,
  PropertyDetails,
  CheckInOut,
  Recommendations,
  Reservations,
  Services,
  AmenitiesAndSupplies,
  SlideshowAndAdvertisement,
  WelcomeScreenAndVideo,
  Messages,
} from './PropertyDetail'
import AnalyticsContainer from './AnalyticsContainer'
import '../styles/Card.scss'
import '../styles/PropertyDetailContainer.scss'
import { thirdPartyAnalytics } from '../services'
import { EVENTS } from '../constants/thirdPartyAnalytics'

const PROPERTY_DETAIL_TABS = [
  {
    slug: '',
    component: PropertyDetails,
    label: 'Property Details',
  },
  {
    slug: 'reservations',
    component: Reservations,
    label: 'Reservations',
  },
  {
    slug: 'analytics',
    component: AnalyticsContainer,
    label: 'Analytics',
  },
  {
    slug: 'checkinout',
    component: CheckInOut,
    label: 'Check In / Out',
  },
  {
    slug: 'property-info',
    component: AmenitiesAndSupplies,
    label: 'Property Info',
  },
  {
    slug: 'recommendations',
    component: Recommendations,
    label: 'Recommendations',
  },
  {
    slug: 'services',
    component: Services,
    label: 'Services',
  },
  {
    slug: 'SlideshowAndAds',
    component: SlideshowAndAdvertisement,
    label: 'Slideshow / Ads',
  },
  {
    slug: 'welcome',
    component: WelcomeScreenAndVideo,
    label: 'Welcome Screen / Videos',
  },
  {
    slug: 'announcements',
    component: Messages,
    label: 'Announcements',
  },
]

const PropertyDetailContainer = ({ match, history }) => {
  const { params } = match

  const { me: userInfo, properties: propertyDetailObject } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  const recordAnalytics = properties => {
    let updatedProperties = { ...analyticsProperties }
    const propertyName =
      Object.entries(propertyDetailObject).length && propertyDetailObject[params.id]
        ? propertyDetailObject[params.id].nickname
          ? propertyDetailObject[params.id].nickname
          : propertyDetailObject[params.id].addressLine1
        : ''
    if (isObject(properties)) {
      updatedProperties = {
        ...updatedProperties,
        ...properties,
        propertyId: params.id,
        propertyName,
      }
    }
    thirdPartyAnalytics.track(EVENTS.PROPERTY_DETAILS, updatedProperties)
  }

  return (
    <Resource path={['properties', params.id]} placeholderComponent={Loading}>
      {(property, { dispatch, onSave, NestedResource }) => {
        if (property.isArchived) {
          history.push('/properties/archive')
        }
        return (
          <div className="PropertyDetailContainer">
            <PropertyDetailHeader
              property={property}
              onSave={onSave}
              dispatch={dispatch}
              recordAnalytics={recordAnalytics}
            />
            <Tabs
              path={`properties/${property.id}`}
              tabs={PROPERTY_DETAIL_TABS}
              dispatch={dispatch}
              NestedResource={NestedResource}
              property={property}
              recordAnalytics={recordAnalytics}
              reservations={property.reservations}
              onSave={async changes => {
                const result = await onSave(changes)
                await asyncDelay(400)
                return result
              }}
            />
          </div>
        )
      }}
    </Resource>
  )
}

export default PropertyDetailContainer
