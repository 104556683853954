import React, { useState } from 'react'
import TagsInput from './TagsInput'

export default function ChipsInput({ inputValue, setInputValue, selectedItem, setSelectedItem, disabled }) {
  const [, setEmailRecipients] = useState([])

  const validateEmail = email => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (email.match(mailformat)) {
      return true
    }
    return false
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === 'Tab' || event.key === ' ' || event.type === 'blur') {
      const newSelectedItem = [...selectedItem]
      const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim())

      if (duplicatedValues !== -1) {
        setInputValue('')
        return
      }
      if (!event.target.value.replace(/\s/g, '').length) return

      if (validateEmail(event.target.value.trim())) {
        newSelectedItem.push(event.target.value.trim())
      }
      setSelectedItem(newSelectedItem)
      setInputValue('')
    }
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }

  const addRecipients = chips => {
    setEmailRecipients(chips)
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSelectedItem(newSelectedItem)
  }

  return (
    <TagsInput
      selectedTags={addRecipients}
      handleChange={handleChange}
      inputValue={inputValue}
      setInputValue={setInputValue}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      handleKeyDown={handleKeyDown}
      fullWidth
      variant="outlined"
      id="tags"
      name="tags"
      placeholder="add Tags"
      label="tags"
      disabled={disabled}
    />
  )
}
