import React from 'react'
import { openModalWithContainer } from '../../reducers/modals'
import Pill from '../Pill'
import OnboardingBreadcrumbs from './OnboardingBreadcrumbs'
import propertyIdExample from '../../images/property-id-example.png'
import BackButton from './buttons/BackButton'
import SaveContinueButton from './buttons/SaveContinueButton'
import { MESSAGES } from '../../constants/strings'

const DevicePasscodeStep = ({ me, dispatch, onSaveOrganization }) => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="onboardingForm">
            <div id="header">
              <img src="/gvg-logo-white-updated.svg" alt="GuestView Guide" className="signup-hdr-logo" />
            </div>
            <div className="main-content">
              <OnboardingBreadcrumbs step={4} length={5} />
              <h2 className="h2">{MESSAGES.SETUP_DISPLAY}</h2>
              <p>{MESSAGES.SETUP_DISPLAY_MSG_1}</p>
              <div className="default-body green-body">
                <div className="form-group row">
                  <div className="col-md-6">
                    <p>
                      <strong>{MESSAGES.PROPERTY_ID}</strong>
                      {' '}
                      {MESSAGES.SETUP_DISPLAY_MSG_2}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <img className="property-id-example" src={propertyIdExample} alt="property-id-example" />
                  </div>
                </div>
              </div>
              <div className="default-body">
                <div className="form-group row" style={{ marginTop: '40px' }}>
                  <div className="col-md-4">
                    <label htmlFor="name">{MESSAGES.DISPLAY_PASSCODE}</label>
                  </div>
                  <div className="col-md-8 ">
                    <div className="device-passcode">
                      <Pill invert text={me.organization.passcode} />
                      <button
                        className="action link inline"
                        onClick={() => dispatch(
                            openModalWithContainer('PasscodeContainer', {
                              organization: me.organization,
                              onSave: organization => onSaveOrganization(organization),
                            }),
                          )}
                      >
                        {MESSAGES.EDIT}
                      </button>
                    </div>
                    <p className="tip">{MESSAGES.SETUP_DISPLAY_MSG_3}</p>
                  </div>
                </div>
              </div>
              <div id="footer">
                <BackButton to="/onboarding/3" />
                <SaveContinueButton to="/onboarding/5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevicePasscodeStep
