/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { openModalWithContainer } from '../../reducers/modals'
import PhoneNumber from '../PhoneNumber'
import { pluralize } from '../../Utils'
import { EmptyNavItemPlaceholder } from '../PageStates'
import AdminIcon from '../../icons/AdminIcon'
import Resource from '../../Resource'
import { MasterList, AddButton, ListActions, ListCellBadge } from '../list'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { PROPERTY_TYPES } from '../../constants/forms/propertyInfo'
import { MESSAGES } from '../../constants/strings'
import PropertyDrawer from '../drawer/PropertyDrawer'

const alignCellTextMiddle = {
  options: {
    classNames: 'middle',
  },
}

const COLUMNS = [
  {
    id: 'notifications',
    columns: [
      {
        Header: MESSAGES.PHONE,
        accessor: 'phone',
        Cell: ({
          row: {
            original: { phone },
          },
        }) => <PhoneNumber>{typeof phone !== 'object' && phone}</PhoneNumber>,
        ...alignCellTextMiddle,
      },
      {
        Header: MESSAGES.EMAIL,
        accessor: 'email',
        Cell: ({
          row: {
            original: { email },
          },
        }) => (email ? <div>{email}</div> : '-'),
        ...alignCellTextMiddle,
      },
      {
        Header: MESSAGES.NAME,
        accessor: 'name',
        sortType: (a, b) => {
          const nameA = get(a, 'original.name', '')
          const nameB = get(b, 'original.name', '')
          return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
        },
        ...alignCellTextMiddle,
      },
      {
        Header: MESSAGES.PROPERTIES,
        accessor: 'properties',
        sortType: (a, b) => {
          const propertyA = get(a, 'original.properties.length', '')
          const propertyB = get(b, 'original.properties.length', '')
          return propertyA - propertyB
        },
        Cell: ({ row: { original }, initialState: { propertiesLength, openRecipientProperties } }) => {
          return original && original.propertyIds && original.propertyIds?.length > 0 ? (
            <div
              className="link-text"
              onClick={event => {
                openRecipientProperties(original, event)
                event.stopPropagation()
              }}>
              {`${original.propertyIds ? original.propertyIds.length : 0} of ${propertiesLength} ${pluralize(
                propertiesLength,
                'property'
              )}`}
            </div>
          ) : (
            <div>{`${0} of ${propertiesLength} ${pluralize(propertiesLength, 'property')}`}</div>
          )
        },
        options: {
          classNames: 'middle',
          hideOn: ['mobile'],
        },
      },
      {
        Header: MESSAGES.IS_DEFAULT,
        accessor: 'isDefault',
        Cell: ({
          row: {
            original: { isDefault },
          },
        }) => (isDefault ? <ListCellBadge label="Default" /> : ''),
        ...alignCellTextMiddle,
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original: notificationContact }, initialState: { rowAction, onDeleteItem, onUpdateItem } }) => {
          const edit = () => rowAction(notificationContact)
          const setAsDefault = () => {
            window.confirm(MESSAGES.REPLACE_DEFAULT_CONTACT) &&
              onUpdateItem({ ...notificationContact, isDefault: true })
          }
          const remove = () => {
            ;(notificationContact.properties.length === 0 ||
              window.confirm(
                `Are you sure you want to remove this notification? It is used by ${pluralize(
                  notificationContact.properties.length,
                  'property',
                  null,
                  true
                )}.`
              )) &&
              onDeleteItem(notificationContact)
          }

          return (
            <PopoverMenu data-cy="notification-options">
              <PopoverMenuItem data-cy="notification-option-edit" onClick={edit}>
                {MESSAGES.EDIT}
              </PopoverMenuItem>
              {!notificationContact.isDefault && (
                <PopoverMenuItem data-cy="notification-option-edit" disabled onClick={setAsDefault}>
                  {MESSAGES.SET_AS_DEFAULT}
                </PopoverMenuItem>
              )}
              {!notificationContact.isDefault && (
                <PopoverMenuItem data-cy="notification-option-remove" onClick={remove}>
                  {MESSAGES.REMOVE}
                </PopoverMenuItem>
              )}
              {!notificationContact.isDefault && (
                <PopoverMenuItem data-cy="notification-option-edit" disabled onClick={setAsDefault}>
                  Set As Default
                </PopoverMenuItem>
              )}
            </PopoverMenu>
          )
        },
        options: {
          classNames: 'middle-right',
        },
      },
    ],
  },
]

const NotificationsTable = ({
  openRecipientProperties,
  notifications,
  properties,
  propertiesLength,
  onUpdateItem,
  onDeleteItem,
  dispatch,
  drawerContent,
  showDrawer,
  setDrawer,
}) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  const [PropertyIdForEdit] = useState(0)
  const rowAction = notification => {
    dispatch(
      openModalWithContainer('NotificationContainer', {
        properties,
        notification,
        onSave: onUpdateItem,
        PropertyIdForEdit,
      })
    )
  }

  const initialState = {
    sortBy: [{ id: 'name', desc: false }],
    onDeleteItem,
    openRecipientProperties,
    propertiesLength,
    onUpdateItem,
    rowAction,
  }
  return (
    <>
      <PropertyDrawer
        header="Notification"
        drawerData={drawerContent}
        showDrawer={showDrawer}
        setDrawer={setDrawer}
        properties={properties}
      />
      <MasterList
        columns={memoizedColumns}
        data={notifications}
        rowAction={rowAction}
        initialState={initialState}
        search={false}
        data-cy="notifications-table"
      />
    </>
  )
}

function Notifications() {
  const NotificationsContainerWithData = ({
    dispatch,
    notifications,
    properties,
    onCreateItem,
    onUpdateItem,
    onDeleteItem,
  }) => {
    const [showDrawer, setDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})

    const openRecipientProperties = (data, event) => {
      event.stopPropagation()
      setDrawerContent(data)
      if (data.propertyIds.length > 0 || data.propertyId) {
        setDrawer(true)
      }
    }
    const openModal = params =>
      dispatch(
        openModalWithContainer('NotificationContainer', {
          properties,
          notification: {},
          ...params,
          onSave: onCreateItem,
        })
      )
    return (
      <div className="settings-section">
        <h2>{MESSAGES.CHECK_IN_OUT_NOTIFICATION_CONTACT}</h2>
        <h6>{MESSAGES.NOTIFICATION_CONTACT}</h6>
        <ListActions>
          <AddButton id="addContact" data-cy="btn-add-message" item="Contact" onClick={openModal} />
        </ListActions>
        {notifications.length !== 0 ? (
          <NotificationsTable
            drawerContent={drawerContent}
            showDrawer={showDrawer}
            setDrawer={setDrawer}
            openRecipientProperties={openRecipientProperties}
            notifications={notifications}
            properties={properties}
            propertiesLength={properties.length}
            dispatch={dispatch}
            onDeleteItem={onDeleteItem}
            onUpdateItem={onUpdateItem}
            onCreateItem={openModal}
          />
        ) : (
          <EmptyNavItemPlaceholder navItem="a number" icon={<AdminIcon width="72" height="72" />} size="small" />
        )}
      </div>
    )
  }

  return (
    <Resource collection path={['notifications']} placeholder={[]}>
      {(notifications, { onUpdateItem, onCreateItem, onDeleteItem, dispatch }) => (
        <Resource collection path={['properties', `?type=${PROPERTY_TYPES.current}`]} placeholder={[]}>
          {properties => (
            <NotificationsContainerWithData
              notifications={notifications}
              properties={properties}
              dispatch={dispatch}
              onCreateItem={onCreateItem}
              onUpdateItem={onUpdateItem}
              onDeleteItem={onDeleteItem}
            />
          )}
        </Resource>
      )}
    </Resource>
  )
}

export default withRouter(Notifications)
