import React from 'react'
import { get } from 'lodash'
import classNames from 'classnames'

const SMSPreview = ({
 formik: { values }, name, prefix, format, placeholder, suffix, status, ...props
}) => {
  const value = get(values, name)
  const title = get(values, 'name')
  const hasPayment = get(values, 'hasPaymentProvider')

  let paymentText = ''
  if (hasPayment) {
    if (status === 'Approved') {
      paymentText = ' and your payment has been processed'
    } else {
      paymentText = ' and your payment authorization has been canceled'
    }
  }

  const message = `[${status}] Your request for ${title} is ${status.toLowerCase()}${paymentText}.`

  return (
    <div {...props.props} className={classNames('PreviewSMS', props.className)}>
      {message}
      {' '}
      {value}
    </div>
  )
}

export default SMSPreview
