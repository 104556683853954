export const CARD_CONTENT_FIRST = [
         {
           name: 'PROPERTY_DETAILS',
           label: 'Property Details',
           link: '/content',
           buttonText: 'Add / Manage',
           cardDescription:
             'Assign guest Wi-Fi, house rules, and setup a contact number that guests can use to notify you of issues at the property.',
         },
         {
           name: 'CHECK_IN_OUT_INFO',
           label: 'Check In / Out Info',
           link: '/content/check-in-out',
           buttonText: 'Add / Manage',
           cardDescription:
             'Set the standard Check In / Out Time, Check Out Message, Check Out List, and Check In / Out Notification Contact.',
         },
         {
           name: 'CHECK_IN_OUT_NOTIFICATION',
           label: 'Check In / Out Notification',
           link: '/content/check-in-out',
           buttonText: 'Add / Manage',
           cardDescription: 'Set the standard Check In / Out Notification contact.',
         },
         {
           name: 'PROPERTY_INFO',
           label: 'Property Info',
           link: '/content/amenities-and-supplies',
           buttonText: 'Add / Manage',
           cardDescription: 'Inform guests of amenities, extra supplies, and policies at your properties.',
         },
         {
           name: 'WELCOME',
           label: 'Welcome Screen',
           link: '/content/welcome-screen',
           buttonText: 'Customize',
           cardDescription: 'Customize the Welcome screen with your brand, images, and welcome message.',
         },
         {
           name: 'RECOMMENDATIONS',
           label: 'Recommendations',
           link: '/content/recommendations',
           buttonText: 'Add / Manage',
           cardDescription:
             'Recommend to your guests local restaurants, activities, and directories (grocery, ATM, etc.)',
         },
       ]

export const CARD_CONTENT_SECOND = [
  {
    name: 'WELCOME_VIDEO',
    label: 'Welcome Video',
    link: '/content/welcome-screen/videos',
    buttonText: 'Add / Manage',
    cardDescription:
      'Add and customize Welcome Video for the user to see during Check In with your message and branding',
  },
  {
    name: 'SERVICES',
    label: 'Promote/Sell Services',
    link: '/services',
    buttonText: 'Add / Manage',
    cardDescription:
      'Promote and sell services directly to your guests. Link a Stripe account to receive payments directly.',
  },
  {
    name: 'SLIDESHOW',
    label: 'Slideshow',
    link: '/slideshow',
    buttonText: 'Add / Manage',
    cardDescription: 'Add photo collections for a screensaver that plays when the display is not in active use.',
  },
  {
    name: 'ADVERTISEMENTS',
    label: 'Advertisements',
    link: '/slideshow/advertisement',
    buttonText: 'Add / Manage',
    cardDescription: 'Advertise your services or local businesses on the slideshow.',
  },
  {
    name: 'MESSAGES',
    label: 'Announcements',
    link: '/announcements',
    buttonText: 'Add / Manage',
    cardDescription: 'Notify your guests of trash pick-up day or other important information.',
  },
]

export const CARD_INDICATORS = {
  NO_CONTENT: 'no-content',
  DEFAULT_CONTENT: 'default-content',
  INCOMPLETE: 'default-content',
  COMPLETE: 'complete',
  CUSTOMIZED: 'complete',
  PROGRESS: 'complete',
}

export const ADDITIONAL_STRINGS = {
  ADD_PROPERTY_MESSAGE:
    'Create a property profile and property ID by adding a property address, nickname, photo, and syncing reservations.',
  ADD_PROPERTY_LABEL: 'Add Properties',
  PROPERTIES: 'properties',
  YOUR_PROPERTIES: 'Your properties',
  RESERVATION_MESSAGE_SINGLE: 'property currently does not have reservations.',
  RESERVATION_MESSAGE_MULTIPLE: 'properties currently do not have reservations.',
  VIEW_INFO: 'View Info',
  STRING_ZERO: '0',
  STRING_ONE: '1',
  NUMBER_ONE: 1,
  NUMBER_TWO: 2,
  DEFAULT_CONTENT: 'DEFAULT_CONTENT',
  INCOMPLETE: 'INCOMPLETE',
  RESERVATIONS: 'RESERVATIONS',
  CONCIERGE_HEADING: 'Content for your digital concierge',
  SET_UP_RESERVATIONS: 'Set up reservations',
  WELCOME_VIDEO: 'WELCOME_VIDEO',
}

export const SLIDESHOW_DURATIONS = [
  {
    value: 15,
    label: '15 seconds',
    chipLabel: '15 sec',
  },
  {
    value: 30,
    label: '30 seconds',
    chipLabel: '30 sec',
  },
  {
    value: 60,
    label: '60 seconds',
    chipLabel: '60 sec',
  },
  {
    value: 90,
    label: '90 seconds',
    chipLabel: '90 sec',
  },
]

export const SLIDESHOW_COUNT_NUMBERS = [1, 2, 3, 4]
