import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import moment from 'moment'

import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import Loader from '../../icons/Loader'
import '../../styles/ExtractAdReports.scss'
import Tooltip from '../../components/Tooltip'
import excelIcon from '../../images/excelicon.png'
import pdfIcon from '../../images/pdficon.png'
import ChipsInput from '../../components/ChipsInput'

const ExtractAdReport = ({
  advertisement,
  closeModal,
  adEnabled,
  setAdEnabled,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  inputValueNow,
  setInputValueNow,
  selectedItemNow,
  setSelectedItemNow,
  reportType,
  setReportType,
}) => {
  const adCreationDate = moment(advertisement.createdAt)
  const [maxStartDate, setMaxStartDate] = useState(new Date())
  const [maxEndDate] = useState(new Date())
  const [minDate] = useState(adCreationDate.toDate())
  const yesterday = moment()
    .subtract(1, 'd')
    .toDate()
  const duration = moment.duration(moment().diff(adCreationDate))
  const days = duration.asDays()

  useEffect(() => {
    const shouldEnableAd = reportType === 1 || days > 29
    setAdEnabled && setAdEnabled(shouldEnableAd)
    setTimeout(() => {
      !shouldEnableAd &&
        document.getElementById('adIsDisabledTip') &&
        document.getElementById('adIsDisabledTip').click()
    }, 1000)
    if (reportType === 0) {
      const backDays = days > 29 ? 30 : days
      const pdfStartDate = moment().subtract(backDays, 'd')
      setStartDate(pdfStartDate.toDate())
      setMaxStartDate(pdfStartDate.toDate())
    }
  }, [])

  useEffect(() => {
    const shouldEnableAd = reportType === 1 || days > 29
    setAdEnabled && setAdEnabled(shouldEnableAd)
    if (reportType === 0) {
      if (
        document.getElementsByClassName('bs-tooltip-bottom') &&
        document.getElementsByClassName('bs-tooltip-bottom').length
      ) {
        document.getElementsByClassName('bs-tooltip-bottom')[0].style.display = 'block'
      }
      const backDays = days > 29 ? 30 : days
      const pdfStartDate = moment().subtract(backDays, 'd')
      setStartDate(pdfStartDate.toDate())
      setMaxStartDate(pdfStartDate.toDate())
      setEndDate(yesterday)
    } else {
      setMaxStartDate(new Date())
      if (
        document.getElementsByClassName('bs-tooltip-bottom') &&
        document.getElementsByClassName('bs-tooltip-bottom').length
      ) {
        document.getElementsByClassName('bs-tooltip-bottom')[0].style.display = 'none'
      }
    }
  }, [reportType])

  const handleStartDateChange = date => {
    setStartDate(date)
    if (reportType == 0) {
      const newEndDate = moment(date).add(29, 'd')
      setEndDate(newEndDate.toDate())
    }
  }

  return (
    <div>
      <p className="noteInEmail"> Note: An email will be send out with the data for the selected duration </p>
      <div className="d-flex align-items-top">
        <div className="report-icon">
          <img src={reportType ? excelIcon : pdfIcon} alt="pdf/excel" />
        </div>
        <div className="ml-4 report-form">
          <div>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <label className={`btn  ${reportType === 0 ? 'active btn-info' : 'btn-outline-info'} `}>
                <input type="radio" name="report" onChange={() => setReportType(0)} checked={reportType === 0} /> PDF
              </label>
              <label className={`btn  ${reportType === 0 ? 'btn-outline-info' : 'active btn-info'} `}>
                <input type="radio" name="report" onChange={() => setReportType(1)} checked={reportType === 1} /> Excel
              </label>
            </div>
            <Tooltip tabIndex={-1} target="adIsDisabledTip">
              PDF reports are only available after at least 30 days of ad placement
            </Tooltip>
          </div>
          <div className="mt-3">
            <div className="field has-date-picker date-range-container">
              <div>
                <label className="mb-1">From </label>
                <DatePicker
                  value={startDate}
                  onChange={date => handleStartDateChange(date)}
                  format="MM/dd/yyyy"
                  clearIcon={null}
                  calendarIcon={<img src="/cal.svg" width="20px" height="20px" alt="calendar" />}
                  maxDate={maxStartDate}
                  minDate={minDate}
                  disabled={!adEnabled}
                />
              </div>
              <div>
                <label className="mb-1">To </label>
                <DatePicker
                  value={endDate}
                  onChange={date => setEndDate(date)}
                  format="MM/dd/yyyy"
                  clearIcon={null}
                  maxDate={maxEndDate}
                  minDate={minDate}
                  calendarIcon={<img src="/cal.svg" width="20px" height="20px" alt="calendar" />}
                  disabled={reportType === 0}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label className="mb-1">
              Email Recipients
              <Tooltip tabIndex={-1} target="email-immadiate">
                Press enter after every email to add multiple recipients
              </Tooltip>
            </label>
            <ChipsInput
              inputValue={inputValueNow}
              setInputValue={setInputValueNow}
              selectedItem={selectedItemNow}
              setSelectedItem={setSelectedItemNow}
              disabled={!adEnabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtractAdReport
