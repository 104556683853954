import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import EasyCropper from 'react-easy-crop'
import { ModalHeader, ModalBody, ModalFooter } from './modal'
import '../styles/Cropper.scss'
import { ZoomIcon } from '../icons'
import classNames from 'classnames'
import Loading from './Loading'

const Cropper = ({
  file: { localFileURL, localImage, localFileType },
  desiredSize,
  isMobile,
  allowRotate,
  orientation,
  flexible,
  onChange,
  value,
  uploadHandler,
  setProgress,
  progress,
  setFileData,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [aspect, setAspect] = useState(desiredSize.width / desiredSize.height)
  const [rotation, setRotation] = useState(0)
  const [localFileRegion, setLocalFileRegion] = useState(null)
  const [uploading, setUploading] = useState(false)

  const getMaximumZoom = () => {
    return localImage ? Math.min(localImage.width / desiredSize.width, localImage.height / desiredSize.height) : 1
  }

  const onCropComplete = (e, pixels) => {
    setLocalFileRegion(pixels)
  }

  const onReset = () => {
    setUploading(false)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotation(0)
    setProgress(0)
    setLocalFileRegion(null)
    setFileData({})
  }

  const onEditDone = () => {
    if (orientation === 'square') {
      desiredSize.height = desiredSize.width
    }
    if (
      getMaximumZoom() < 1
      && !flexible
      && !window.confirm(
        'Are you sure you want to upload this image? Upscaling will occur when it is presented on the display. You may want to choose a larger image.',
      )
    ) {
      return
    }
    setUploading(true)

    let { width } = desiredSize
    let { height } = desiredSize

    if (allowRotate) {
      const tall = localImage.height > localImage.width

      if (flexible) {
        if (tall) {
          height = desiredSize.height
          width = desiredSize.height / (localImage.height / localImage.width)
        } else {
          width = desiredSize.width * 2
          height = (desiredSize.width / (localImage.width / localImage.height)) * 2
        }
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const safeArea = Math.max(localImage.width, localImage.height) * 2
      const radianAngle = (rotation * Math.PI) / 180

      // set each dimensions to double largest dimension to allow for a safe area for the
      // image to rotate in without being clipped by canvas context
      canvas.width = safeArea
      canvas.height = safeArea

      // translate canvas context to a central location on image to allow rotating around the center.
      ctx.translate(safeArea / 2, safeArea / 2)
      ctx.rotate(radianAngle)
      ctx.translate(-safeArea / 2, -safeArea / 2)

      // draw rotated image and store data.
      ctx.drawImage(localImage, safeArea / 2 - localImage.width * 0.5, safeArea / 2 - localImage.height * 0.5)
      const data = ctx.getImageData(0, 0, safeArea, safeArea)

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = localFileRegion.width
      canvas.height = localFileRegion.height

      // paste generated rotate image with correct offsets for x,y crop values.
      ctx.putImageData(
        data,
        0 - safeArea / 2 + localImage.width * 0.5 - localFileRegion.x,
        0 - safeArea / 2 + localImage.height * 0.5 - localFileRegion.y,
      )
      // Upload the image
      canvas.toBlob(
        blob => {
          blob.name = 'picture.jpg'
          uploadHandler(blob)
        },
        localFileType,
        0.92,
      )
    } else {
      const canvas = document.createElement('canvas')

      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(
        localImage,
        localFileRegion.x,
        localFileRegion.y,
        localFileRegion.width,
        localFileRegion.height,
        0,
        0,
        width,
        height,
      )
      canvas.toBlob(
        blob => {
          blob.name = 'picture.jpg'
          uploadHandler(blob)
        },
        localFileType,
        0.92,
      )
    }
  }

  const maxZoom = getMaximumZoom()

  return (
    <Modal isOpen className={classNames('ImageDropzoneModal', { 'm-modal': isMobile })}>
      <ModalHeader hideClose>Edit Image</ModalHeader>
      <ModalBody>
        {progress > 0 && (
          <div className="ShowProgress">
            <span>
              <Loading
                style={{
                  margin: 0,
                  marginRight: '18px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              />
              {`${progress}%`}
            </span>
          </div>
        )}
        <div
          className="cropper-wrapper"
          onWheelCapture={e => {
            // There is a bug in Cropper which causes the image to "jump" too small /
            // move offcenter if you zoom out and the maxZoom is <1. We just prevent
            // the scroll events from reaching the component.
            if (maxZoom < 1) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        >
          <EasyCropper
            image={localFileURL}
            crop={crop}
            zoom={zoom}
            maxZoom={maxZoom}
            aspect={aspect}
            onCropChange={crop => setCrop(crop)}
            onZoomChange={zoom => setZoom(zoom)}
            onCropComplete={onCropComplete}
            rotation={rotation}
            onRotationChange={rotation => allowRotate && setRotation(rotation)}
          />
        </div>
      </ModalBody>
      <div className="image-controls-container">
        <div className="zoom-container">
          {maxZoom < 1 ? (
            <div className="text-danger">
              This image is too small. Upscaling will occur when it is presented on the display.
            </div>
          ) : (
            <div className="range-container">
              <ZoomIcon />
              <input
                className="custom-range-slider"
                type="range"
                value={zoom}
                step={0.05}
                onChange={e => setZoom(e.target.value)}
                min={1}
                max={maxZoom}
              />
            </div>
          )}
        </div>
      </div>
      <ModalFooter>
        <div className="buttons-container">
          <button className="btn btn-outline-primary" onClick={onReset}>
            Cancel
          </button>
          <button
            data-cy="upload-button"
            className="btn btn-primary"
            disabled={!localFileRegion || uploading}
            onClick={onEditDone}
          >
            Upload
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default Cropper
