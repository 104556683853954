import React from 'react'
import Icon from './Icon'

function CustomAdvertisementsIcon(props) {
  return (
    <Icon viewBox="0 0 42 70" {...props}>
      <path fill="#fff" d="M1.5 1.5h39v67h-39z" />
      <path d="M39 3v64H3V3h36m3-3H0v70h42V0z" fill="#5691c7" />
      <g>
        <path
          d="M20.69 35.77c0-1.22-1.24-2.2-2.78-2.2s-2.78.99-2.78 2.2v2.42c0 1.22 1.25 2.2 2.78 2.2s2.78-.99 2.78-2.2v-2.42z"
          fill="none"
          stroke="#4392d0"
        />
        <path fill="#4392d0" d="M9.56 31.38v4.36l23.18 3.57V27.82z" />
        <path fill="none" stroke="#fff" d="M30.04 39.98V27.14" />
      </g>
    </Icon>
  )
}

export default CustomAdvertisementsIcon
