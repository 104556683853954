/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { get, camelCase, sortBy } from 'lodash'
import classNames from 'classnames'
import Select, { components } from 'react-select'
import { Modal } from 'reactstrap'
import { connect as formikConnect } from 'formik'
import { fieldify } from './utils'
import { withContent } from '../../components/content/ContentProvider'
import AddCategoryContainer from '../../containers/modals/AddCategoryContainer'
import { apiRequest } from '../../reducers/data'
import { confirm } from '../../containers/modals/AlertMessageContainer'

const CustomOption = props => {
  const { data, innerRef, innerProps } = props
  return data.customLink ? (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 12px' }} onClick={data.onClick}>
      <div className="link">{data.label}</div>
    </div>
  ) : (
    <components.Option {...props}>
      <div style={{ cursor: 'default' }} className="d-flex justify-content-between align-items-center">
        <div className="flex-1 subCatLabel" onClick={() => data.changeCategory(data)}>
          {data.label}
        </div>
        {data.custom && (
          <i
            className="necicon-close deleteSubCat"
            onClick={() => data.onDeleteCategory(data.label, !data.allSubCategoriesArr.includes(data.label))}
          >
            {' '}
          </i>
        )}
      </div>
    </components.Option>
  )
}

const SelectCategoryField = ({
  dispatch,
  onUpdateItem,
  data,
  initalData,
  categoryType,
  fieldName,
  fieldsPath,
  formik: { setFieldValue, values },
  invalid,
}) => {
  const path = `${fieldsPath}.${fieldName}`
  const id = get(data, '[0].id')
  const content = get(data, '[0].content')

  const [category, setCategory] = useState()
  const [isAdding, setIsAdding] = useState(false)
  const [options, setOptions] = useState([])

  const reference = useRef()
  useEffect(() => {
    const initalCategory = get(values, path) || get(initalData, path)
    setCategory(initalCategory)
  }, [])

  useEffect(() => {
    const initialOptions = get(data, `[0].${fieldsPath}.${categoryType}`)
    const newOptions = sortBy(
      initialOptions.filter(option => option.value !== 'newCategory'),
      ['value'],
    )
    // newOptions.push({ label: 'Add New', value: 'newCategory', customLink: true, onClick: () => setIsAdding(true) })
    // setOptions(newOptions)
    const allSubCategoriesArr = []
    const getAllSubCategories = async (type, id) => {
      const { json } = await dispatch(apiRequest(['customizations', type], 'GET'))
      json.customizations.forEach(cat => {
        if (id) {
          cat.id !== id && allSubCategoriesArr.push(cat.content.category)
        } else {
          allSubCategoriesArr.push(cat.content.category)
        }
      })
    }
    initalData && initalData.content
      ? getAllSubCategories(initalData.type, initalData.id)
      : getAllSubCategories(categoryType)
    const modifiedNewOptions = newOptions.map(item => {
      return {
        ...item,
        onDeleteCategory,
        changeCategory,
        allSubCategoriesArr,
      }
    })
    modifiedNewOptions.unshift({
      label: 'Add New',
      value: 'newCategory',
      customLink: true,
      onClick: () => setIsAdding(true),
    })
    setOptions(modifiedNewOptions)
  }, [data])

  const onDeleteCategory = (label, canDelete) => {
    const shouldEmpty = reference.current
      && reference.current.props
      && reference.current.props.value
      && reference.current.props.value[0]
      && reference.current.props.value[0].label === label
    if (canDelete) {
      const updatedCategory = content[categoryType].filter(catType => catType.label !== label)
      confirm({
        title: 'Confirmation',
        body: 'Are you sure you want to delete this sub-category ?',
        onConfirm: () => {
          onUpdateItem({
            id,
            content: {
              ...content,
              [categoryType]: updatedCategory,
            },
          }).then(_ => {
            shouldEmpty && setFieldValue(path, '')
            setIsAdding(false)
          })
        },
      })
    } else {
      confirm({
        title: 'Alert',
        body: 'Cannot remove sub-category that is linked to another recommendation.',
        onlyConfirm: true,
      })
    }
  }

  const changeCategory = category => {
    setCategory(category.label)
    setFieldValue(path, category.label)
  }

  const onCreateCategory = newCategory => {
    const newCategoryObj = {
      label: newCategory,
      value: camelCase(newCategory),
      custom: true,
    }
    const shouldNotAdd = content[categoryType].find(item => item.value === newCategoryObj.value)
    if (shouldNotAdd) {
      changeCategory(shouldNotAdd)
      setIsAdding(false)
      return
    }

    onUpdateItem({
      id,
      content: {
        ...content,
        [categoryType]: [...content[categoryType], newCategoryObj],
      },
    }).then(_ => {
      changeCategory(newCategoryObj)
      setIsAdding(false)
    })
  }

  return (
    <>
      <Select
        classNamePrefix="select"
        className={classNames('select-list select-category', {
          'is-invalid': invalid,
        })}
        options={options}
        components={{ Option: CustomOption }}
        ref={reference}
        value={options.filter(option => option.label === category)}
      // onChange={category => changeCategory(category)}
      />
      <Modal isOpen={isAdding} onUpdateItem={onUpdateItem}>
        <AddCategoryContainer
          onCreateCategory={newCategory => onCreateCategory(newCategory)}
          closeModal={() => setIsAdding(false)}
        />
      </Modal>
    </>
  )
}

export default withContent(fieldify(formikConnect(SelectCategoryField)))
