import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { toNumber } from 'lodash'
import Select, { components } from 'react-select'
import { useSelector } from 'react-redux'
import { TabHeader, Tabs } from '../components'
import { Loading } from '../components/PageStates'
import { AdvertisementTable } from '../components/advertisements'
import { ADVERTISEMENT } from '../constants/forms/advertisements'
import { PROPERTY_TYPES } from '../constants/forms/propertyInfo'
import Resource from '../Resource'
import { ContentEditor, SlideshowOld } from '../components/content'
import { confirm } from './modals/AlertMessageContainer'
import { ClockIcon2 } from '../icons'
import { SLIDESHOW_DURATIONS } from '../constants/home'
import { MESSAGES } from '../constants/strings'
import '../styles/AdvertisementContainer.scss'
import '../styles/ContentPicker.scss'
import { thirdPartyAnalytics } from '../services'
import { ACTIONS, EVENTS } from '../constants/thirdPartyAnalytics'
import PropertyDrawer from '../components/drawer/PropertyDrawer'

const Advertisement = ({ data: customization, onUpdateItem: onCustomizationUpdate, recordAnalytics }) => {
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})

  const openRecipientProperties = (data, event) => {
    event.stopPropagation()
    setDrawerContent(data)
    if (data.properties.length > 0) {
      setDrawer(true)
    }
  }

  const onAction = (action, func, extra) => {
    action !== ACTIONS.DELETE && recordAnalytics(action, extra.name)
    return func(extra)
  }

  const onCustomizationUpdateHandler = extra => {
    const { noOfAds, noOfSlideshows } = extra.content
    recordAnalytics(ACTIONS.CHANGE_CADENCE, null, { noOfAds, noOfSlideshows })
    return onCustomizationUpdate(extra)
  }

  return (
    <div className="AdvertisementsContainer TabContent pt-0">
      <ContentEditor className="newAdvertisement">
        <Resource collection path={['promos', 'all']} apiPath={['promos']} placeholderComponent={Loading}>
          {(data, { dispatch, onUpdateItem, onCreateItem, onDeleteItem }) => (
            <div>
              <div className="Notes" style={{ marginBottom: '20px' }}>
                <div className="Note">{MESSAGES.ADVERTISEMENT_NOTE_1}</div>
                <div className="Note">{MESSAGES.ADVERTISEMENT_NOTE_2}</div>
                <br />
                <div className="Note">
                  You need to{' '}
                  <Link to="/content/recommendations" className="back-to-properties">
                    add recommendations{' '}
                  </Link>{' '}
                  or{' '}
                  <Link to="/services" className="back-to-properties">
                    add services
                  </Link>{' '}
                  before creating advertisements except for adding custom ads.
                </div>
              </div>
              <Resource
                collection
                path={['properties', `?type=${PROPERTY_TYPES.current}`]}
                placeholderComponent={Loading}>
                {properties => (
                  <>
                    <PropertyDrawer
                      header="Advertisement"
                      drawerData={drawerContent}
                      showDrawer={showDrawer}
                      setDrawer={setDrawer}
                      properties={properties}
                    />
                    <AdvertisementTable
                      {...ADVERTISEMENT}
                      dispatch={dispatch}
                      advertisement={data}
                      onCreateItem={onAction.bind(this, ACTIONS.CREATE, onCreateItem)}
                      onUpdateItem={onAction.bind(this, ACTIONS.UPDATE, onUpdateItem)}
                      onDeleteItem={onAction.bind(this, ACTIONS.DELETE, onDeleteItem)}
                      properties={properties}
                      openRecipientProperties={openRecipientProperties}
                      customization={customization}
                      onCustomizationUpdate={onCustomizationUpdateHandler}
                      recordAnalytics={recordAnalytics}
                    />
                  </>
                )}
    </Resource>
  </div>
          )}
        </Resource>
      </ContentEditor>
    </div>
  )
}

const ADVERTISEMENT_SLIDESHOW_TABS = [
  {
    slug: '',
    component: SlideshowOld,
    label: 'Slideshow',
  },
  {
    slug: 'advertisement',
    component: Advertisement,
    label: 'Advertisements',
  },
]

const SingleValue = props => (
  <components.SingleValue {...props}>
    <div className="d-flex align-items-center justify-content-between">
      <ClockIcon2 />
      <span className="mx-2">{`${MESSAGES.DISPLAY_DURATION}:`}</span>
      {props.data.chipLabel}
    </div>
  </components.SingleValue>
)

const AdvertisementsContainer = ({ match, me }) => {
  const [slideshowDuration, setSlideshowDuration] = useState(0)
  const showConfirmation = (value, data, onUpdateItem) => {
    confirm({
      title: 'Confirmation',
      body: `Are you sure to change the duration to ${value} seconds?`,
      onConfirm: () => {
        setSlideshowDuration(toNumber(value))
        recordAnalytics(ACTIONS.CHANGE_DURATION, null, { duration: toNumber(value) })
        onUpdateItem({
          ...data.customizations[0],
          content: {
            ...data.customizations[0].content,
            duration: toNumber(value),
          },
        })
      },
    })
  }

  const { me: userInfo } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  const recordAnalytics = (action, name, extra) => {
    const updatedProperties = name
      ? {
          ...analyticsProperties,
          action,
          name,
          ...extra,
        }
      : { ...analyticsProperties, action, ...extra }
    thirdPartyAnalytics.track(EVENTS.ADVERTISEMENT, updatedProperties)
  }

  return (
    <div className="ContentContainer">
      <Resource collection path={['customizations', 'promos']} placeholderComponent={Loading}>
        {(data, { dispatch, onUpdateItem, onRefresh }) => {
          !slideshowDuration
            && data.customizations
            && data.customizations.length
            && data.customizations[0]
            && data.customizations[0].content
            && setSlideshowDuration(data.customizations[0].content.duration)
          return (
            <div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <TabHeader title="Slideshow/Advertisements" />
                </div>
                <div className="duration-select col-sm-12 col-md-6 mt-n2">
                  <div className="select-wrap">
                    <Select
                      isSearchable={false}
                      className="duration-select-2"
                      value={SLIDESHOW_DURATIONS.find(item => item.value === slideshowDuration)}
                      options={SLIDESHOW_DURATIONS}
                      onChange={item => {
                        item.value !== slideshowDuration && showConfirmation(item.value, data, onUpdateItem)
                      }}
                      components={{ SingleValue }}
                    />
                  </div>
                </div>
              </div>
              <ContentEditor className="ContentEditor">
                <Tabs
                  path="slideshow"
                  match={match}
                  me={me}
                  tabs={ADVERTISEMENT_SLIDESHOW_TABS}
                  data={data.customizations[0]}
                  onUpdateItem={onUpdateItem}
                  dispatch={dispatch}
                  onRefresh={onRefresh}
                  recordAnalytics={recordAnalytics}
                />
              </ContentEditor>
            </div>
          )
        }}
      </Resource>
    </div>
  )
}

export default withRouter(AdvertisementsContainer)
