import classNames from 'classnames'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { SERVICE_TEMPLATES } from '../../constants/services'
import { MESSAGES } from '../../constants/strings'
import BundleServiceIcon from '../../icons/BundleServiceIcon'
import SingleServiceIcon from '../../icons/SingleServiceIcon'
import { formatters } from '../../Utils'

import ServicePrice from './ServicePrice'
import ServiceRequestName from './ServiceRequestName'
import ServiceRequestResponse from './ServiceRequestResponse'

const PENDING_MODES = ['PAYMENT_CHECKOUT_PENDING', 'PAYMENT_PENDING', 'APPROVAL_PENDING', 'PAYMENT_CHECKOUT_PENDING']

const ServiceRequestItem = ({
 request, dispatch, supportPaymentProvider, onUpdateItem, checkCartAbandon,
}) => {
  const {
 service, parameters, reservation, phone, property, createdAt,
} = request
  const quantity = get(parameters, 'quantity', 1)
  const address = formatters.address(property)
  const { template, price } = service

  return (
    <div
      className={classNames('service-card', { 'is-pending': PENDING_MODES.includes(request.status) }, request.status)}
    >
      <div className="col-group d-flex flex-column">
        {/** First Row */}
        <div className="d-flex justify-content-between has-two-col">
          <div className="card-service-name mb-3">
            {template === SERVICE_TEMPLATES.BUNDLE ? <BundleServiceIcon /> : <SingleServiceIcon />}
            <small>{MESSAGES.SERVICE_NAME}</small>
            <ServiceRequestName service={service} quantity={quantity} />
          </div>

          <div className="card-price mb-3">
            <small>{MESSAGES.PRICE}</small>
            <ServicePrice
              service={service}
              amount={price}
              hasPaymentProvider={!!parameters.checkout_id}
              supportPaymentProvider={supportPaymentProvider}
              dispatch={dispatch}
              request
              parameters={parameters}
            />
            {!parameters.checkout_id && <span>{MESSAGES.MANUAL_PAYMENT}</span>}
          </div>
        </div>

        {/** Second Row */}
        <div className="d-flex justify-content-between has-two-col">
          <div className="card-guest-name mb-3">
            <small>{MESSAGES.GUEST_NAME}</small>
            <div className="one-liner-text">
              {reservation && reservation.partyName ? reservation.partyName : MESSAGES.GUEST}
            </div>
            <div>{phone}</div>
          </div>

          <div className="card-check-in mb-3">
            <small>{MESSAGES.CHECK_IN_OUT}</small>
            <div>
              {reservation && reservation.beginDate && moment(reservation.beginDate).format('L')}
              {' '}
              –
            </div>
            <div>{reservation && reservation.endDate && moment(reservation.endDate).format('L')}</div>
          </div>
        </div>

        {/** Third Row */}
        <div className="d-flex justify-content-between has-two-col">
          <div className="card-property-address mb-3">
            <small>{MESSAGES.PROPERTY_ADDRESS}</small>
            <div className="one-liner-text">{address[0]}</div>
            <div className="one-liner-text">{address[1]}</div>
          </div>

          <div className="card-received mb-3">
            <small>{MESSAGES.RECEIVED}</small>
            <div title={moment(createdAt).format('LLLL')}>{moment(createdAt).fromNow()}</div>
          </div>
        </div>
      </div>
      <div className="card-footer-status">
        <ServiceRequestResponse
          {...request}
          dispatch={dispatch}
          onUpdateItem={onUpdateItem}
          checkCartAbandon={checkCartAbandon}
        />
      </div>
    </div>
  )
}

export default ServiceRequestItem
