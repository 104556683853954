/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { find, get, isUndefined, orderBy } from 'lodash'
import classNames from 'classnames'

import { FormModal } from '../../forms'
import { generateForm } from '../../forms/utils'
import { withContent } from './ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { ListCellBadge } from '../list'
import { CONTENT_FIELDS_PATH } from '../../constants/forms/content'

import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { GoToIcon } from '../../icons'
import { MESSAGES } from '../../constants/strings'

import PropertyDrawer from '../drawer/PropertyDrawer'
import { pluralize } from '../../Utils'

const NEW_CUSTOM_CUSTOMIZATION_KEY = 'new_custom'

const SelectLabel = ({ customization: { name, isDefault } }) => (
  <div className="SelectLabel">
    {name} {isDefault && <ListCellBadge label="Default" />}
  </div>
)

const CustomizationForm = ({ handleSubmit, formFields }) => {
  return <form onSubmit={handleSubmit}>{formFields}</form>
}

const ContentPicker = ({
  propertyId,
  data,
  content,
  onCreateItem,
  onUpdateItem,
  history,
  totalProperties,
  recordAnalytics,
}) => {
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})
  const { fields } = content
  const [selectedCustomizationId, setSelectedCustomizationId] = useState()

  useEffect(() => {
    const currentSet = find(data, ({ propertyIds }) => propertyIds && propertyIds.includes(propertyId))
    if (currentSet && isUndefined(selectedCustomizationId)) {
      setSelectedCustomizationId(currentSet.id)
    }
  }, [data])
  // Create Options
  const globalCustomizations = Object.values(data).filter(({ propertyId }) => !propertyId)
  const sortedCustomizations = orderBy(globalCustomizations, ['isDefault', 'name'], ['desc', 'asc'])

  const options = Object.values(sortedCustomizations).reduce((options, customization) => {
    options.push({
      value: customization.id,
      label: <SelectLabel customization={customization} />,
    })
    return options
  }, [])

  const custom = find(data, customization => customization.propertyId === propertyId)

  const customOption = {
    value: get(custom, 'id', NEW_CUSTOM_CUSTOMIZATION_KEY),
    label: 'Custom for this property...',
  }

  options.push(customOption)

  // Create Form
  const isCustom = selectedCustomizationId === customOption.value
  const customization = find(data, ({ id }) => id === selectedCustomizationId, {})
  const formFields = fields({ readOnly: !isCustom, saveOnExit: isCustom }).map(field => ({
    ...field,
    name: `${CONTENT_FIELDS_PATH}.${field.name}`,
  }))

  const customizationForm = generateForm({
    form: formFields,
    props: customization,
  })

  const openRecipientProperties = (data, event) => {
    event.stopPropagation()
    setDrawerContent(data)
    setDrawer(true)
  }

  const onSubmit = async values => {
    recordAnalytics && recordAnalytics()
    if (selectedCustomizationId === NEW_CUSTOM_CUSTOMIZATION_KEY) {
      const newCustomization = await onCreateItem({
        ...values,
        propertyIds: [propertyId],
        propertyId,
      })

      // If creating new custom customization at property level, handle switch once created
      if (propertyId) {
        setSelectedCustomizationId(newCustomization.json.id)
      }
    } else {
      return onUpdateItem({
        ...values,
        propertyIds: [propertyId],
        propertyId: isCustom && propertyId,
        id: selectedCustomizationId,
      })
    }
  }

  const customizatioPropertyIds = get(customization, 'propertyIds', [])
  const propertiesApplied = customizatioPropertyIds.length
  const selectedContent = data && data.find(content => content.propertyIds && content.propertyIds.includes(propertyId))

  return (
    <>
      <PropertyDrawer header="Content" drawerData={drawerContent} showDrawer={showDrawer} setDrawer={setDrawer} />
      <div className="ContentPicker Card">
        <div className={classNames('select-wrapper', { custom: isCustom })}>
          <Select
            classNamePrefix="select"
            className="select-list"
            options={options}
            onChange={customization => {
              setSelectedCustomizationId(customization.value)
              if (customization.value !== NEW_CUSTOM_CUSTOMIZATION_KEY) {
                onUpdateItem({ id: `${propertyId}/${customization.value}/switch` })
                recordAnalytics && recordAnalytics()
              }
            }}
            value={selectedCustomizationId && options.filter(option => option.value === selectedCustomizationId)}
          />
          <PopoverMenu>
            <PopoverMenuItem className="properties-applied">
              <div className="meta">{MESSAGES.USED_BY}</div>
              <div className="bold">
                {
                  <div
                    className="link-text"
                    onClick={event => {
                      openRecipientProperties(selectedContent, event)
                      event.stopPropagation()
                    }}>
                    {`${
                      selectedContent?.propertyIds ? selectedContent?.propertyIds.length : 0
                    } of ${totalProperties} ${pluralize(totalProperties, 'property')}`}
                  </div>
                }
              </div>
            </PopoverMenuItem>
            {selectedCustomizationId && (
              <PopoverMenuItem
                className={classNames({ disabled: isCustom })}
                onClick={() =>
                  !isCustom ? history.push(`/content/${content.location}?edit=${customization.id}`) : null
                }>
                <GoToIcon /> Open This Component
              </PopoverMenuItem>
            )}
            <PopoverMenuItem onClick={() => history.push(`/content/${content.location}?add=${content.type}`)}>
              <GoToIcon /> {MESSAGES.CREATE_NEW_COMPONENT}
            </PopoverMenuItem>
            {content.type === 'wifi' && (
              <PopoverMenuItem
                className={classNames({ disabled: !data.length || !selectedCustomizationId })}
                onClick={() => {
                  if (!data.length || !selectedCustomizationId) {
                    return
                  }
                  const defaultCustomization = data.find(item => item.isDefault === true)

                  const filterPropertyId =
                    (customization &&
                      customization.propertyIds &&
                      customization.propertyIds.filter(id => id !== propertyId)) ||
                    []
                  customization &&
                    onUpdateItem({
                      propertyIds: [...filterPropertyId],
                      id: customization.id,
                    })
                  customization && customization.isDefault
                    ? setSelectedCustomizationId(null)
                    : defaultCustomization
                    ? setSelectedCustomizationId(defaultCustomization.id)
                    : setSelectedCustomizationId(null)
                }}>
                <GoToIcon /> Remove From Property
              </PopoverMenuItem>
            )}
          </PopoverMenu>
        </div>
        <div className="card-content padding-bottom">
          {!selectedCustomizationId && content.type === 'wifi' ? (
            <div className="noWifi"> No Wi-Fi component assigned. </div>
          ) : (
            <FormModal
              enableReinitialize
              initialValues={customizationForm.newInitialValues}
              validationSchema={customizationForm.validationNew}
              onSubmit={onSubmit}
              render={_ => <CustomizationForm formFields={customizationForm.component} />}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default withRouter(withContent(ContentPicker))
