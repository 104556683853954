import React from 'react'
import Currency from '../../components/Currency'
import '../../styles/AnalyticsContainer.scss'
import infoImg from '../../images/info.png'
import { MESSAGES } from '../../constants/strings'

const Services = ({ servicesData }) => {
  return (
    <div className="Services">
      <div className="title">{MESSAGES.SERVICES}</div>
      <div className="services">
        {servicesData.map(({ label, value = 0, currency }) => (
          <div className="service-container" key={label}>
            <div className="service-info">
              <div className="service-name">{label}</div>
            </div>
            <div className="service-total">
              {currency ? (
                <>
                  <img title={MESSAGES.CUSTOM_REQUEST_PRICING} className="service-image" src={infoImg} alt="info" />
                  <Currency value={Math.floor(value / 100)} />
                </>
              ) : (
                value
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services
