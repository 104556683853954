import React from 'react'

const Messages = ({ title }) => {
  return (
    <div className="title-cell">
      <div className="first-line">{title}</div>
    </div>
  )
}

export default Messages
