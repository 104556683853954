import { useDispatch } from 'react-redux'
import React, { Fragment } from 'react'
import {
  CheckboxField,
  IconsField,
  ImageDropzoneField,
  ImageSelection,
  MediaField,
  RichTextEditorField,
  TextareaField,
  TextField,
  ToggleSwitchField,
  VideoDropzoneField,
} from '../../forms/fields'
import { CONTENT_FIELDS_PATH, COMMONS_FIELDS } from './content'
import { Yup } from '../../forms'
import Tooltip from '../../components/Tooltip'
import { SUB_SECTIONS } from '../thirdPartyAnalytics'
import { COMMON_FIELDS } from './services'
import TitleAndIconsGroup from '../../forms/fields/TitleAndIconsGroup'

const IconField = ({ readOnly, saveOnExit }) => {
  const dispatch = useDispatch()
  return {
    component: IconsField,
    name: 'iconURL',
    manualGroup: true,
    props: {
      saveOnExit,
      readOnly,
      dispatch,
    },
  }
}

const ImageUrl = { name: 'imageURL' }
const VideoURL = { name: 'videoURL' }
const ResourceURL = { name: 'resourceURL' }
const Steps = { name: 'steps' }

const MediasField = ({ readOnly, saveOnExit }) => {
  const dispatch = useDispatch()
  return {
    component: MediaField,
    name: 'mediaType',
    manualGroup: true,
    props: {
      saveOnExit,
      readOnly,
      dispatch,
      fieldsPath: CONTENT_FIELDS_PATH,
    },
  }
}

const RichTextsEditorField = ({ readOnly, saveOnExit }) => {
  return {
    label: 'Description',
    name: 'description',
    component: RichTextEditorField,
    props: {
      saveOnExit,
      readOnly,
      maxLength: 1500,
      type: 'textarea',
      placeholder: 'Message to guests',
      rows: 4,
    },
  }
}

const PROPERTY_INFO_FIELDS = ({ readOnly, saveOnExit }) => {
  return [
    {
      fields: [
        {
          component: TitleAndIconsGroup,
          validation: Yup.string()
            .trim()
            .required('required'),
          label: 'Title',
          className: 'name',
          name: 'name',
          vertical: false,
          props: {
            readOnly,
            placeholder: 'Name',
            saveOnExit,
            title: 'name',
            iconURL: 'content.iconURL',
          },
        },
        {
          label: (
            <div>
              Nickname
              <Tooltip tabIndex={-1} target="GuestServicesNumber">
                Nickname is only visible to the PM
              </Tooltip>
            </div>
          ),
          name: 'nickname',
          component: TextField,
          validation: Yup.string(),
          props: {
            maxLength: 80,
            readOnly,
            placeholder: 'Nickname',
          },
          className: 'name',
          vertical: false,
        },
        ImageUrl,
        VideoURL,
        ResourceURL,
        Steps,
        MediasField(readOnly, saveOnExit),
        RichTextsEditorField(readOnly, saveOnExit),
      ],
    },
    {
      fields: [
        {
          ...COMMONS_FIELDS.propertyIds({
            isDefault: false,
            isAllowedMultiple: true,
          }),
        },
      ],
    },
  ]
}

const WELCOME_VIDEO_FIELDS = ({ readOnly, saveOnExit }) => {
  const dispatch = useDispatch()
  return [
    {
      fields: [
        {
          ...COMMONS_FIELDS.name,
          label: 'Name',
          name: 'title',
          className: 'name',
          vertical: false,
          validation: Yup.string()
            .trim()
            .max(100, '100 characters allowed')
            .required('required'),
          props: {
            readOnly,
            placeholder: 'Name',
          },
        },
        {
          ...COMMON_FIELDS.draggableMedia,
          label: (
            <Fragment>
              Video
              <Tooltip tabIndex={-1} target="WelcomeVideo">
                We recommend keeping the videos under 1 minute and of high resolution (for best experience use 720p)
              </Tooltip>
            </Fragment>
          ),
          name: 'url',
          validation: Yup.array()
            .required()
            .label('Video')
            .default([]),
          props: {
            ...COMMON_FIELDS.draggableMedia.props,
            sublabel: '',
            mediaType: 'video',
            accept: 'video/*,.mkv,.3gp',
            upload: {
              server: '/api/dashboard',
              signingUrlQueryParams: { path: 'uploaded-video/' },
            },
            maxMediaLimit: 1,
            postMedia: true,
            dispatch,
          },
        },
        { name: 'duration' },
        { name: 'imageURL' },
        { name: 'id' },
        {
          label: 'Short message',
          name: 'message',
          component: RichTextEditorField,
          validation: Yup.string().default('<p>Welcome! We hope you enjoy your stay.</p>'),
          props: {
            // saveOnExit,
            // readOnly,
            maxLength: 300,
            type: 'textarea',
            placeholder: 'Message to guests',
            rows: 4,
            value: '<p>Welcome! We hope you enjoy your stay.</p>',
          },
        },
        {
          label: (
            <Fragment>
              Prevent Skip Video
              <Tooltip tabIndex={-1} target="WelcomeVideoSkip">
                Turn this feature off if viewing the video is not mandatory
              </Tooltip>
            </Fragment>
          ),
          name: 'preventSkip',
          component: [
            {
              component: CheckboxField,
              props: {
                label: '',
                name: 'preventSkip',
                toggleSwitch: true,
              },
              validation: Yup.boolean().default(false),
            },
          ],
        },
        {
          label: 'Prevent Mute Video',
          label: (
            <Fragment>
              Prevent Mute Video
              <Tooltip tabIndex={-1} target="WelcomeVideoMute">
                Turn this feature off if viewing the video with sound is not mandatory
              </Tooltip>
            </Fragment>
          ),

          name: 'preventMute',
          component: [
            {
              component: CheckboxField,
              props: {
                label: '',
                name: 'preventMute',
                toggleSwitch: true,
              },
              validation: Yup.boolean().default(false),
            },
          ],
        },
      ],
    },
    {
      fields: [
        {
          ...COMMONS_FIELDS.propertyIds({
            isDefault: false,
            isAllowedMultiple: true,
          }),
        },
        {
          // ! dont remove these space !
          label: ' ',
          name: 'status',
          component: CheckboxField,
          props: {
            customLabel: 'Enable Now',
            name: 'status',
            toggleSwitch: true,
          },
          validation: Yup.boolean().default(true),
        },
      ],
    },
  ]
}

const defaultImages = [
  'https://nec-assets.s3.amazonaws.com/uploaded-image/1a7b2c2c-ca51-4a62-9be5-99a1fcddcec5_picture.jpg',
  'https://nec-assets.s3.amazonaws.com/uploaded-image/06fcc6f5-0771-4bd6-a1ee-72fc133847ea_picture.jpg',
  'https://nec-assets.s3.amazonaws.com/uploaded-image/43afae19-ba14-4b15-85f8-73548bc85dcb_picture.jpg',
  'https://nec-assets.s3.amazonaws.com/uploaded-image/f9e7e609-ddd5-41cd-9953-11e2153a2a20_picture.jpg',
]

const WELCOME_SCREEN_FIELDS = ({ readOnly, saveOnExit, data }) => {
  return [
    {
      fields: [
        {
          ...COMMONS_FIELDS.name,
          label: 'Name',
          className: 'name',
          vertical: false,
          validation: Yup.string()
            .trim()
            .max(100, '100 characters allowed')
            .required('required'),
          props: {
            readOnly,
            placeholder: 'Name',
          },
        },
        {
          label: 'Display Wi-fi Info',
          name: 'showWifiInfo',
          validation: Yup.boolean().default(true),
          component: CheckboxField,
          props: {
            label: 'Display Wi-fi info',
            name: 'showWifiInfo',
            toggleSwitch: true,
            saveOnExit,
            readOnly,
          },
          validation: Yup.boolean().default(true),
        },
        {
          ...COMMON_FIELDS.draggableMedia,
          label: 'Upload Logo',
          name: 'url',
          validation: Yup.array().default([]),
          props: {
            ...COMMON_FIELDS.draggableMedia.props,
            sublabel: '',
            mediaType: 'image',
            accept: 'image/*',
            upload: {
              server: '/api/dashboard',
              signingUrlQueryParams: { path: 'uploaded-image/' },
            },
            maxMediaLimit: 1,
            minMediaLimit: 1,
          },
        },
        {
          label: 'Welcome Text',
          name: 'text',
          component: TextareaField,
          props: {
            maxLength: 300,
            shouldToggle: false,
            saveOnExit,
            readOnly,
          },
          validation: Yup.string()
            .default('Set your bags down and relax. Think of me as your concierge!')
            .max(300, '300 characters allowed'),
        },
        {
          label: 'Check In Button Text',
          name: 'checkinButtonText',
          component: TextareaField,
          props: {
            maxLength: 50,
            shouldToggle: false,
            saveOnExit,
            readOnly,
          },
          validation: Yup.string()
            .trim()
            .default('Check In')
            .max(50, '50 characters allowed')
            .required('required'),
        },
        {
          label: 'Background',
          name: 'background',
          component: ImageSelection,
          props: {
            defaultImages,
            dimensions: { width: 1080, height: 1920 },
            displayScale: 8,
            saveOnExit,
          },
          validation: Yup.string()
            .required('required')
            .default(defaultImages[0]),
        },
      ],
    },
    {
      fields: [
        {
          ...COMMONS_FIELDS.propertyIds({
            isDefault: !!(data && data.isDefault),
            isAllowedMultiple: true,
          }),
        },
        {
          // ! dont remove these space !
          label: ' ',
          name: 'status',
          component: CheckboxField,
          disabled: data.isDefault,
          props: {
            customLabel: 'Enable Now',
            name: 'status',
            toggleSwitch: true,
          },
          validation: Yup.boolean().default(true),
        },
      ],
    },
  ]
}

export const PROPERTY_TYPES = { archived: 'archived', current: 'current', all: 'all' }

export const STATUS_LABELS = {
  ARCHIVED: 'Archived',
  CURRENT: 'Current',
  PAIRED: 'Paired',
  UNPAIRED: 'Unpaired',
}

export const PROPERTY_TYPES_TABS_LABEL = {
  listedProperties: 'Listed Properties',
  archivedProperties: 'Archived Properties',
}

export const PROPERTY_VIEWS_TYPES = { listView: 'listView', gridView: 'gridView' }

export const LISTING_EMPTY_TEXT = {
  archived: 'You have not yet archived a property',
  current: 'You have not yet added a property',
  search: 'No search results found',
}
export const POLICIES = {
  location: 'amenities-and-supplies/policies',
  type: 'policy',
  title: 'Policy',
  description:
    'Long form policies that can feature images, videos, and PDFs that are featured in the property information pages.',
  addNew: '+ New Policy',
  item: 'Policy',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any policies',
  // fields: PROPERTY_INFO_FIELDS,
  contentEmptyMessage: 'You have not yet added or applied any policies for this property',
  event: SUB_SECTIONS.POLICIES,
  steps: PROPERTY_INFO_FIELDS,
}

export const AMENITIES = {
  location: 'amenities-and-supplies',
  type: 'amenity',
  title: 'Amenity',
  addNew: '+ New Amenity',
  item: 'Amenity',
  verb: {
    create: 'Add an',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any amenities',
  contentEmptyMessage: 'You have not yet added or applied any amenities for this property',
  event: SUB_SECTIONS.AMENITIES,
  steps: PROPERTY_INFO_FIELDS,
}

export const SUPPLIES = {
  location: 'amenities-and-supplies/supplies',
  type: 'supply',
  title: 'Supply',
  addNew: '+ New Supply',
  item: 'Supply',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any supplies',
  contentEmptyMessage: 'You have not yet added or applied any supplies for this property',
  event: SUB_SECTIONS.SUPPLIES,
  steps: PROPERTY_INFO_FIELDS,
}

export const PROPERTY_OPTIONS = {
  ARCHIVE_PROPERTY: 'Archive this property',
  RELIST_PROPERTY: 'Relist this property',
}

export const PROPERTY_STATUS = {
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
  PAIRED: 'PAIRED',
  UNPAIRED: 'UNPAIRED',
}

export const DATE_FORMATS = {
  FULL_DATE: 'MMM DD, YYYY h:mm a',
}

export const WELCOME_VIDEO = {
  location: '',
  type: 'videos',
  title: 'Welcome Video',
  description: '',
  addNew: '+ Add Welcome Video',
  item: 'Welcome Video',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any welcome video',
  isNotCustomization: true,
  event: SUB_SECTIONS.WELCOME_VIDEO,
  steps: WELCOME_VIDEO_FIELDS,
}

export const WELCOME_SCREEN = {
  location: '',
  type: 'welcome',
  title: 'Welcome Screen',
  defaultUrl: 'https://nec-assets.s3.amazonaws.com/uploaded-image/1a7b2c2c-ca51-4a62-9be5-99a1fcddcec5_picture.jpg',
  description: '',
  addNew: '+ Add Welcome Screen',
  item: 'Welcome Screen',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any Welcome screen',
  event: SUB_SECTIONS.WELCOME_SCREEN,
  steps: WELCOME_SCREEN_FIELDS,
}
