import React from 'react'
import { get } from 'lodash'
import moment from 'moment'

import classNames from 'classnames'
import Messages from '../../components/messages/Messages'
import MessagesDateType from '../../components/messages/MessagesDateType'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'

import GREETING from '../../images/icons/alert-welcome.svg'
import ALERT from '../../images/icons/alert-alert.svg'
import INFORMATION from '../../images/icons/alert-info.svg'
import REMINDER from '../../images/icons/alert-reminder.svg'
import { pluralize } from '../../Utils'

const alignCellTextMiddle = {
  options: {
    classNames: 'middle',
  },
}

export const MESSAGE_LOGO = {
  accessor: 'logo',
  Cell: ({ row: { original } }) => {
    let src
    switch (original.category) {
      case 'GREETING':
        src = <img src={GREETING} alt={original.category} />
        break
      case 'ALERT':
        src = <img src={ALERT} alt={original.category} />
        break
      case 'INFORMATION':
        src = <img src={INFORMATION} alt={original.category} />
        break
      case 'REMINDER':
        src = <img src={REMINDER} alt={original.category} />
        break
      default:
        break
    }
    return <div className="notification-icons">{src}</div>
  },
  disableSortBy: true,
  options: {
    classNames: 'td-has-icon',
  },
}

export const MESSAGE_TITLE = {
  Header: 'Announcements',
  accessor: 'title',
  sortType: (a, b) => {
    const titleA = get(a, 'original.title', '')
    const titleB = get(b, 'original.title', '')

    return titleA.toLowerCase().localeCompare(titleB.toLowerCase())
  },
  Cell: ({ row: { original }, initialState: { openMessage } }) => {
    return <Messages title={original.title} />
  },
  ...alignCellTextMiddle,
  options: {
    classNames: 'valigntop pl-1 font13',
    headerClassNames: 'pl-1',
  },
}

export const RECURRENCE = {
  Header: 'Recurrence',
  accessor: 'recurrence',
  Cell: ({ row: { original } }) => (
    <small>
      {['now'].includes(original.datetime.type) ? 'One Time' : null}
      {['datetime'].includes(original.datetime.type) ? 'One Time' : null}
      {['recurring', 'relative'].includes(original.datetime.type) ? 'Recurring' : null}
    </small>
  ),
  options: {
    hideOn: ['tablet', 'mobile'],
  },
}

export const SCHEDULE = {
  Header: 'Schedule',
  accessor: 'datetime',

  sortType: (a, b) => {
    const dateA = get(a, 'original.datetime.value', '')
    const dateB = get(b, 'original.datetime.value', '')
    return dateA.toString().localeCompare(dateB.toString())
  },
  Cell: ({ row: { original } }) => {
    return <MessagesDateType original={original} />
  },
  ...alignCellTextMiddle,
  options: {
    hideOn: ['tablet', 'mobile'],
    classNames: 'no-truncate',
  },
}

export const CATEGORY = {
  Header: 'Category',
  accessor: 'category',
  Cell: ({ row: { original } }) => {
    const Capitalize = category => {
      return category?.charAt(0).toUpperCase() + category?.slice(1)
    }
    return Capitalize(original.category?.toLowerCase())
  },
  options: {
    hideOn: ['tablet', 'mobile'],
  },
}

export const MESSAGE_STATUS = {
  Header: 'Status',
  accessor: 'status',
  Cell: ({ row: { original } }) => (
    <div className={classNames('capitalize text-color', { success: original.enabled }, { danger: !original.enabled })}>
      {original.enabled ? 'Enabled' : 'Disabled'}
    </div>
  ),
  options: {
    hideOn: ['tablet', 'mobile'],
  },
}

export const MESSAGE_OPTIONS = {
  id: 'options',
  accessor: 'options',
  disableSortBy: true,
  Cell: ({
    row: { original: message },
    initialState: { onUpdateItem, onDeleteItem, duplicateMessage, openMessage },
  }) => {
    const onUpdate = () => {
      openMessage(message)
    }

    const duplicate = () => {
      duplicateMessage(message)
    }
    const deleteMessage = async () => {
      (message.properties.length === 0
        || window.confirm(
          `Are you sure you want to remove this announcement? It is used by ${pluralize(
            message.properties.length,
            'property',
            null,
            true,
          )}.`,
        ))
        && (await onDeleteItem({ id: message.id }))
    }
    const isMessageEnabled = message.enabled
    const disable = () => {
      onUpdateItem({ id: message.id, enabled: false, datetime: message.datetime })
    }
    const enable = () => {
      onUpdateItem({ id: message.id, enabled: true, datetime: message.datetime })
    }

    return (
      <PopoverMenu>
        <PopoverMenuItem onClick={onUpdate}>Edit</PopoverMenuItem>
        <PopoverMenuItem onClick={duplicate}>Duplicate</PopoverMenuItem>
        <PopoverMenuItem onClick={isMessageEnabled ? disable : enable}>
          {isMessageEnabled ? 'Disable' : 'Enable'}
        </PopoverMenuItem>
        <PopoverMenuItem onClick={deleteMessage}>Delete</PopoverMenuItem>
      </PopoverMenu>
    )
  },
  options: {
    classNames: 'middle-right shrink',
    headerClassNames: 'options-align',
  },
}

export const MESSAGE_CREATED_CELL = {
  id: 'createdAt',
  Header: 'Date Created',
  accessor: ({ createdAt }) => moment(createdAt),
  sortType: (a, b) => {
    const dateA = get(a, 'original.createdAt', '')
    const dateB = get(b, 'original.createdAt', '')
    return moment(dateA).diff(moment(dateB))
  },
  // eslint-disable-next-line react/display-name
  Cell: ({ cell: { value }, row: { original } }) => (
    <small className="date-time" title={`Created At: ${moment(original.createdAt).format('LLLL')}`}>
      <date>{value.format('MMM DD, YYYY')}</date>
      <time>{value.format('hh:mm a')}</time>
    </small>
  ),
  options: {
    hideOn: ['tablet', 'mobile'],
  },
}
