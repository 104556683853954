import React from 'react'
import Icon from './Icon'

const PlayIcon = props => (
  <Icon viewBox="0 0 164 164" width={20} height={20} fill="none" {...props}>
    <circle cx="81.5" cy="81.5" r="76.5" stroke="white" strokeOpacity="0.5" strokeWidth="10" />
    <path
      d="M117.137 73.8633L68.7617 44.3008C67.418 43.5169 66.0182 43.125 64.5625 43.125C62.2109 43.237 60.3073 44.0208 58.8516 45.4766C57.2839 47.0443 56.5 48.9479 56.5 51.1875V110.312C56.5 112.552 57.2839 114.456 58.8516 116.023C60.3073 117.479 62.2109 118.263 64.5625 118.375C66.0182 118.375 67.418 117.983 68.7617 117.199L117.137 87.6367C119.6 85.957 120.888 83.6615 121 80.75C120.888 77.8385 119.6 75.543 117.137 73.8633ZM64.5625 110.312V51.1875L112.938 80.582L64.5625 110.312Z"
      fill="white"
      fillOpacity="0.7"
    />
  </Icon>
)

export default PlayIcon
