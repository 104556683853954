import React from 'react'
import { range } from 'lodash'
import '../../styles/Onboarding.scss'

const OnboardingBreadcrumbs = ({ step, length }) => {
  return (
    <div className="row breadcrumbs">
      {range(0, length).map(index => (
        <div className={index < step ? 'breadcrumb full' : 'breadcrumb'} key={index} />
      ))}
    </div>
  )
}

export default OnboardingBreadcrumbs
