import React from 'react'
import pluralize from 'pluralize'
import { get, uniqueId } from 'lodash'
import Skeleton from '../../../components/Skeleton'
import Accordion from '../../../components/Accordion'
import { COMMON_FIELDS, CUSTOM_FIELDS } from '../services'
import {
  DescriptionPreview,
  ImagePreview,
  SMSPreview,
  TextPreview,
  FeeFieldPreview,
  DetailHTMLPreview,
  Divider,
  ImageCarouselPreview,
  PreviewFooter,
  OrderDeadlinePreview,
} from '../../../forms/previews'
import { ClockIcon3 } from '../../../icons'
import imagePlaceholder from '../../../images/image-placeholder.png'

const SHOW_FEE_TYPE = ['hourly', 'daily']

const numberFormatter = value => {
  if (!Number(value)) return value
  const amount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value / 100)
  return amount
}

const priceFormatter = (value, formValues) => {
  const feeType = get(formValues, 'feeType')
  if (feeType === 'free') return 'Free'
  let result = numberFormatter(value)
  if (SHOW_FEE_TYPE.includes(feeType)) {
    result = `${result} ${feeType}`
  }
  return result
}

const orderDeadlineFormatter = (orderDeadline, { orderDeadlineFormat }) => {
  if (!orderDeadline || !orderDeadlineFormat) return null

  if (orderDeadline >= 60) {
    if (orderDeadlineFormat && orderDeadlineFormat == 'hours') {
      return `${orderDeadline} ${orderDeadlineFormat}`
    }
    if (orderDeadline % 60 === 0) {
      orderDeadline /= 60
      orderDeadlineFormat = 'hour'
    } else {
      orderDeadlineFormat = 'minute'
    }
  } else {
    orderDeadlineFormat = orderDeadlineFormat || 'minute'
  }

  orderDeadlineFormat = pluralize(orderDeadlineFormat, +orderDeadline)
  return `${orderDeadline} ${orderDeadlineFormat}`
}

const priceRangeFormatter = choices => {
  const content = ''

  if (!choices || (choices && !choices.length)) return content

  let allFeeRates = []

  for (const index in choices) {
    const { feeRates } = choices[index]
    const currentFeeRates = feeRates.map(item => item.price && item.price).filter(item => item && item)
    allFeeRates = [...allFeeRates, ...currentFeeRates]
  }

  if (!allFeeRates.length) allFeeRates = [0]

  if (allFeeRates.length >= 2) {
    const minValue = numberFormatter(Math.min(...allFeeRates))
    const maxValue = numberFormatter(Math.max(...allFeeRates))

    return `${minValue} - ${maxValue}`
  }
  if (allFeeRates.length === 1) {
    return numberFormatter(allFeeRates[0])
  }

  return null
}

export const FORMATTERS = {
  priceFormatter,
  orderDeadlineFormatter,
  priceRangeFormatter,
}

const NOTIFICATION_PREVIEW = {
  className: 'MobilePreview',
  title: 'Listing Preview',
  subtitle: 'Service Listing',
  children: [
    {
      className: 'sms-container has-thin-scroll scroll-grey',
      children: [
        {
          component: SMSPreview,
          name: COMMON_FIELDS.approvalMessage.name,
          status: 'Approved',
        },
        {
          component: SMSPreview,
          name: COMMON_FIELDS.declineMessage.name,
          status: 'Declined',
        },
      ],
    },
  ],
}

const SERVICE_LISTING_PREVIEW = {
  className: 'ListingPreview only-list',
  Parent: Accordion,
  title: 'Listing Preview',
  subtitle: 'Service Listing',
  id: uniqueId('service_listing_custom_preview'),
  children: [
    {
      name: CUSTOM_FIELDS.draggableMedia.name,
      component: ImagePreview,
      placeholder: imagePlaceholder,
      props: {},
    },
    {
      className: 'ServiceListPreview',
      children: [
        {
          component: TextPreview,
          className: 'PreviewTitle',
          name: COMMON_FIELDS.name.name,
          placeholder: <Skeleton height={12} width="30%" />,
        },
        {
          component: DescriptionPreview,
          className: 'mt-1',
          name: COMMON_FIELDS.summarySubtitle.name,
          placeholder: <Skeleton height={8} count={2} width="100%" />,
        },
        {
          component: FeeFieldPreview,
          className: 'font-weight-bold',
          placeholder: <Skeleton height={8} width="40%" />,
          name: COMMON_FIELDS.feeFields.name,
          format: priceFormatter,
        },
      ],
    },
  ],
}

const SERVICE_DETAIL_PREVIEW = {
  className: 'ListingPreview ServiceDetail kiosk-ratio',
  Parent: Accordion,
  title: 'Service Detail Page',
  subtitle: 'Service Detail',
  id: uniqueId('service_details_custom_preview'),
  children: [
    {
      className: 'ServiceDetailPreview',
      children: [
        {
          className: 'ServiceDetailPreviewMain',
          children: [
            {
              component: TextPreview,
              className: 'PreviewTitle',
              name: COMMON_FIELDS.name.name,
              placeholder: <Skeleton height={12} width="30%" />,
            },
            {
              component: FeeFieldPreview,
              className: 'font-weight-bold',
              name: COMMON_FIELDS.feeFields.name,
              placeholder: <Skeleton height={8} width="40%" />,
              format: FORMATTERS.priceFormatter,
            },
            {
              component: ImageCarouselPreview,
              name: CUSTOM_FIELDS.draggableMedia.name,
              placeholder: imagePlaceholder,
            },
            {
              component: OrderDeadlinePreview,
              name: COMMON_FIELDS.orderDeadline.name,
              className: 'DeadlinePreview',
              placeholder: '24 hours',
              prefix: <ClockIcon3 className="Icon" />,
              suffix: 'before check out',
              format: FORMATTERS.orderDeadlineFormatter,
            },
            { component: Divider },
            {
              className: 'scrollable-container',
              children: [
                {
                  component: DescriptionPreview,
                  name: COMMON_FIELDS.summarySubtitle.name,
                  // className: 'ServiceDetailSummary',
                  placeholder: <Skeleton height={8} />,
                },
                {
                  component: DetailHTMLPreview,
                  heading: 'Description',
                  name: COMMON_FIELDS.detailHTML.name,
                  placeholder: (
                    <>
                      <Skeleton height={8} style={{ marginBottom: 4 }} count={4} />
                      <Skeleton height={8} width="50%" />
                    </>
                  ),
                },
              ],
            },
          ],
        },
        {
          children: [
            {
              component: TextPreview,
              className: 'PreviewTitle',
              name: CUSTOM_FIELDS.serviceInstructions.name,
              placeholder: 'Service Instructions',
              placeholderOnly: true,
            },
            {
              component: PreviewFooter,
              verb: 'request service',
              more: true,
            },
          ],
        },
      ],
    },
  ],
}

export const COMMON_PREVIEW = {
  NOTIFICATION_PREVIEW,
  SERVICE_LISTING_PREVIEW,
  SERVICE_DETAIL_PREVIEW,
}
