/* eslint-disable guard-for-in */
import React from 'react'
import { isFunction, set, get, reduce, map, keys, merge, isObject } from 'lodash'
import { Yup, GenerateForm } from '.'
import { GeneratePreview } from './previews'

const fieldModal = {
  component: 'component',
  props: 'props',
}

const getDefault = _default => {
  if (_default === null || _default === undefined) return ''
  return isFunction(_default) ? _default() : _default
}

const getEmptyValueForDataType = validation => {
  switch (validation?._type) {
    case 'boolean':
      return false
    case 'string':
      return ''
    case 'array':
      return []
    case 'object':
      return {}
    default:
      return ''
  }
}

const createField = field => {
  const outputField = {}
  map(Object.keys(fieldModal), modelKey => {
    set(outputField, fieldModal[modelKey], get(field, modelKey))
  })
  return outputField
}

const generateFields = group => {
  if (!group.component) return null
  const fields = []
  const isArray = Array.isArray(group.component)
  const isFieldArray = group.type === 'fieldArray'
  if (!isArray) {
    const field = {
      ...group,
      props: {
        ...group.props,
        label: group.label,
        name: group.name,
        disabled: group.disabled,
      },
    }
    fields.push(createField(field))
  } else if (isArray && isFieldArray) {
    group.component.map(field => fields.push({ ...field, ...createField(field) }))
  } else {
    group.component.map(field => fields.push(createField(field)))
  }
  return fields
}

const createValidation = ({ name, validation }) => {
  if (validation) {
    if (!Yup.isSchema(validation)) {
      return validation
    }
    const validationObj = {}
    set(validationObj, name, validation)
    return validationObj
  }
  const validationObj = {}
  set(validationObj, name, Yup.string())
  return validationObj
}

const generateValidation = group => {
  if (!Array.isArray(group.component && !group.validation)) {
    return createValidation({ name: group.name, validation: group.validation })
  }
  return group.component.reduce((validationObj, field) => {
    const fieldValidation = createValidation({ name: field.props.name, validation: field.validation })
    return {
      ...validationObj,
      ...fieldValidation,
    }
  }, {})
}

export const generateForm = ({ form, props }) => {
  const formGroupKeys = Object.keys(form)

  const formGroups = reduce(
    formGroupKeys,
    (groups, groupKey) => {
      const required = get(form[groupKey], 'required', '')
      const fields = generateFields(form[groupKey])
      const group = {
        label: form[groupKey].label,
        sublabel: form[groupKey].sublabel,
        note: form[groupKey].note,
        rowClassName: form[groupKey].rowClassName,
        fieldClassName: form[groupKey].fieldClassName,
        vertical: form[groupKey].vertical,
        name: form[groupKey].name,
        required,
        options: form[groupKey].options,
        fields,
        manualGroup: form[groupKey].manualGroup || false,
        className: form[groupKey].className,
        additionalFieldClass: form[groupKey].additionalFieldClass,
        labelTooltipText: form[groupKey].labelTooltipText,
        type: form[groupKey].type,
        addTitle: form[groupKey].addTitle,
        addVerb: form[groupKey].addVerb,
        customAddButton: form[groupKey].customAddButton,
        validation: form[groupKey].validation,
        shouldCollapse: form[groupKey].shouldCollapse,
        headerIdentifier: form[groupKey].headerIdentifier,
        readOnly: form[groupKey].readOnly,
        maxLength: form[groupKey].maxLength,
        disabled: form[groupKey].disabled,
        hideDeleteIconOnFirstIndex: form[groupKey].hideDeleteIconOnFirstIndex,
        saveOnExit: form[groupKey].saveOnExit,
      }
      fields && groups.push(group)
      return groups
    },
    []
  )
  const component = <GenerateForm formGroups={formGroups} />

  const validation = reduce(
    formGroupKeys,
    (validationObj, groupKey) => {
      const groupValidation = generateValidation(form[groupKey])
      return {
        ...validationObj,
        ...groupValidation,
      }
    },
    {}
  )

  const fieldNames = Object.keys(validation)

  const initialValues = reduce(
    fieldNames,
    (initialValuesObj, fieldName) => {
      let defaultValue = get(validation, `${fieldName}._default`, '')

      if (validation[fieldName]._defaultDefault && isFunction(validation[fieldName]._defaultDefault)) {
        const deepValue = get(props, fieldName)
        return set(initialValuesObj, fieldName, deepValue || validation[fieldName]._defaultDefault())
      }

      if (!defaultValue && !Yup.isSchema(validation[fieldName]) && Object.keys(get(validation, fieldName)).length) {
        const currentObj = {}
        // eslint-disable-next-line no-unused-vars
        for (const key in validation[fieldName]) {
          const element = validation[fieldName][key]
          const deepKey = `${fieldName}.${key}`
          const deepValue = get(props, deepKey)
          const deepDefaultValue = element._default
          set(currentObj, key, deepValue === null || deepValue === undefined ? deepDefaultValue : deepValue)
        }
        set(initialValuesObj, fieldName, currentObj)
      }

      if (isFunction(defaultValue)) {
        defaultValue = defaultValue()
      }
      const value = get(props, fieldName)
      const initialVal = value === null || value === undefined ? defaultValue : value
      set(initialValuesObj, fieldName, initialVal)
      return initialValuesObj
    },
    {}
  )

  // TODO: Use for all
  const fieldsObj = Object.keys(form).reduce((fields, fieldKey) => {
    set(fields, form[fieldKey].name, { ...form[fieldKey] })
    return fields
  }, {})

  const valNew = reduce(
    keys(fieldsObj),
    (validationObj, fieldKey) => {
      const field = fieldsObj[fieldKey]
      if (field.name && !isObject(field.name)) {
        if (field.validation) {
          set(validationObj, field.name, field.validation)
        } else if (Array.isArray(field.component)) {
          // eslint-disable-next-line no-unused-vars
          for (const componentIndex in field.component) {
            const { validation, name } = field.component[componentIndex]
            if (validation) {
              set(validationObj, name, validation)
            } else {
              set(validationObj, name, Yup.string())
            }
          }
        } else {
          set(validationObj, field.name, Yup.string())
        }
      } else {
        // TODO: FIX ME - recurrsion
        const nestedValidation = reduce(
          keys(field),
          (nestedValidationObj, nestedFieldKey) => {
            if (nestedFieldKey !== 'undefined') {
              set(nestedValidationObj, nestedFieldKey, field[nestedFieldKey].validation)
            } else {
              return (nestedValidationObj = {
                ...nestedValidationObj,
                ...field[nestedFieldKey].validation,
              })
            }
            return nestedValidationObj
          },
          {}
        )
        set(validationObj, fieldKey, Yup.object().shape(nestedValidation))
      }
      return validationObj
    },
    {}
  )

  const newInitialValues = reduce(
    keys(fieldsObj),
    (initialValuesObj, fieldKey) => {
      const field = fieldsObj[fieldKey]
      if (field.name && !isObject(field.name)) {
        let initialVal = ''
        const value = get(props, field.name)
        if (field.validation) {
          const defaultValue = field.validation && getDefault(field.validation._default)
          initialVal = value === null || value === undefined ? defaultValue : value
        } else if (Array.isArray(field.component)) {
          // eslint-disable-next-line no-unused-vars
          for (const componentIndex in field.component) {
            const {
              validation,
              props: { name },
            } = field.component[componentIndex]
            const currentValue = get(props, name)
            if (validation) {
              const _default = validation && getDefault(validation._default)
              initialVal = currentValue === null || currentValue === undefined ? _default : currentValue
              set(initialValuesObj, name, initialVal)
            } else {
              set(initialValuesObj, name, currentValue || '')
            }
          }
        }
        set(initialValuesObj, field.name, value === null || value === undefined ? initialVal : value)
      } else {
        const value = get(props, fieldKey)
        const initialVal = value === null || value === undefined ? {} : value

        const nestedInitialValue = reduce(
          keys(field),
          (nestedInitialValueObj, nestedFieldKey) => {
            if (nestedFieldKey !== 'undefined') {
              const value = get(props, `${fieldKey}.${nestedFieldKey}`)
              const defaultValue =
                getDefault(field[nestedFieldKey].validation?._default) ||
                getEmptyValueForDataType(field[nestedFieldKey].validation)
              const initialVal = value === null || value === undefined ? defaultValue : value
              set(nestedInitialValueObj, nestedFieldKey, initialVal)
            }
            return nestedInitialValueObj
          },
          {}
        )
        const merged = merge(initialVal, nestedInitialValue)
        set(initialValuesObj, fieldKey, merged)
      }
      return initialValuesObj
    },
    {}
  )

  // TODO: FIX THIS AWFULNESS - Temp soliuton
  // if (form.find(({ name }) => name === 'content.topics')) {
  //   const topics = get(initialValues, 'content.topics', [{ text: 'Other', readOnly: true }])
  //   initialValues = {
  //     ...initialValues,
  //     content: {
  //       ...initialValues.content,
  //       topics,
  //     },
  //   }
  // }

  return {
    component,
    validation: Yup.object().shape(validation),
    validationNew: Yup.object().shape(valNew),
    initialValues,
    newInitialValues,
  }
}

const generatePreview = ({ preview }) => {
  if (preview && preview.length) {
    return (
      <>
        <input type="checkbox" id="cs-sidepanel" />
        <label htmlFor="cs-sidepanel" className="preview-opener">
          Device Preview <i className="triangle-left" />
        </label>
        <div className="preview-mobile-container">
          <GeneratePreview preview={preview} />
        </div>
      </>
    )
  }
  return <></>
}

const checkInFullForm = (item, form) => {
  const formKeys = []
  for (const key in form) {
    const element = form[key]
    for (const index in element) {
      const { name } = element[index]
      formKeys.push(name)
    }
  }
  if (item.name && !formKeys.includes(item.name)) return null
  return item
}

// Compare values of form from the generic preview of module and
// remove the preview fields that are not present in the form
const mapFormValuesToPreview = (formStep, preview, form) => {
  const formKeys = []
  for (const index in formStep) {
    const { name } = formStep[index]
    formKeys.push(name)
  }

  const updatedPreview = [...preview]
  for (const index in updatedPreview) {
    const { children } = updatedPreview[index]
    if (children && children.length) {
      const checkChildren = _children => {
        const updatedChildren = [..._children]
        for (const deepIndex in _children) {
          let { children: deepChildren } = _children[deepIndex]
          const { name: deepName, formDependant } = _children[deepIndex]
          if (formDependant) {
            const formDependantItem = checkInFullForm(_children[deepIndex], form)
            if (!formDependantItem) updatedChildren[deepIndex] = []
          } else if (deepName && !formKeys.includes(deepName)) {
            updatedChildren[deepIndex] = []
          } else if (deepChildren && deepChildren.length) {
            deepChildren = checkChildren(deepChildren)
            updatedChildren[deepIndex] = { ...updatedChildren[deepIndex], children: deepChildren }
          }
        }
        return updatedChildren
      }
      const updatedChildren = checkChildren(children)
      updatedPreview[index] = { ...updatedPreview[index], children: updatedChildren }
    }
  }
  return updatedPreview
}

export const generateFormPreview = ({ preview, form }) => {
  const steps = Object.keys(preview)
  return reduce(
    steps,
    (previewSteps, step) => {
      let previewStep = null
      if (preview) {
        const _preview = Array.isArray(preview[step]) ? get(preview, [step]) : get(preview, `${step}.preview`)
        const _formStep = get(form, [step])
        const updatedPreview = mapFormValuesToPreview(_formStep, _preview, form)
        previewStep = generatePreview({ preview: updatedPreview })
      }
      set(previewSteps, `${step}`, previewStep)
      return previewSteps
    },
    []
  )
}

export const generateWizard = ({ form, props, newValidation }) => {
  const steps = Object.keys(form)

  return reduce(
    steps,
    (wizardSteps, step) => {
      if (React.isValidElement(form[step].component)) {
        set(wizardSteps, `steps.${step}.component`, form[step].component)
      } else {
        const fields = Array.isArray(form[step]) ? get(form, [step]) : get(form, `${step}.fields`)
        const formData = generateForm({ form: fields, props })
        const validation = newValidation ? formData.validationNew : formData.validation
        const stepData = { validation, component: formData.component }

        set(wizardSteps, `steps.${step}`, stepData)
        const currentInitialValues = get(wizardSteps, 'initialValues')
        let newInitialValues = {}
        if (newValidation) {
          newInitialValues = merge(currentInitialValues, formData.newInitialValues)
        } else {
          newInitialValues = merge(currentInitialValues, formData.initialValues)
        }
        set(wizardSteps, 'initialValues', newInitialValues)
      }

      const className = get(form, `${step}.className`)
      className && set(wizardSteps, `steps.${step}.className`, form[step].className)
      return wizardSteps
    },
    {}
  )
}

export const getRelativeTimeInMinutesOrHours = (frequency, frequencyType, hours) => {
  if (frequency === 'BEFORE_CHECKOUT') {
    if (frequencyType === 'hours') return -(hours * 60)

    if (frequencyType === 'minutes') return -hours
  }

  if (frequency === 'AFTER_CHECKIN') {
    if (frequencyType === 'hours') return hours * 60

    if (frequencyType === 'minutes') return hours
  }
}
