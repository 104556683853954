import React from 'react'
import Icon from './Icon'

const AddIcon = props => (
  <Icon width="20" height="20" viewBox="0 0 19 18" fill="none" {...props}>
    <path
      d="M18.7297 14.6502L11.4731 1.17606C11.2809 0.820214 10.9972 0.52317 10.6517 0.316202C10.3062 0.109234 9.91177 0 9.50988 0C9.10799 0 8.71354 0.109234 8.36805 0.316202C8.02256 0.52317 7.73882 0.820214 7.5467 1.17606L0.282572 14.6502C0.0924416 14.9942 -0.00492021 15.3825 0.000191384 15.7763C0.00530298 16.1701 0.112709 16.5556 0.311705 16.8945C0.5107 17.2334 0.794333 17.5138 1.13433 17.7078C1.47433 17.9018 1.85882 18.0025 2.24947 18H16.7628C17.1503 18.0003 17.5312 17.8991 17.8681 17.7063C18.2051 17.5135 18.4866 17.2358 18.6849 16.9002C18.8833 16.5647 18.9917 16.183 18.9995 15.7925C19.0074 15.4019 18.9144 15.0161 18.7297 14.6727V14.6502ZM8.39605 5.03654C8.39605 4.73775 8.51379 4.45119 8.72337 4.23992C8.93295 4.02864 9.21721 3.90994 9.5136 3.90994C9.81 3.90994 10.0943 4.02864 10.3038 4.23992C10.5134 4.45119 10.6312 4.73775 10.6312 5.03654V10.2114C10.6312 10.5102 10.5134 10.7967 10.3038 11.008C10.0943 11.2193 9.81 11.338 9.5136 11.338C9.21721 11.338 8.93295 11.2193 8.72337 11.008C8.51379 10.7967 8.39605 10.5102 8.39605 10.2114V5.03654ZM9.54341 15.1835C9.28996 15.1835 9.0422 15.1077 8.83146 14.9657C8.62073 14.8238 8.45648 14.622 8.35949 14.386C8.26249 14.1499 8.23712 13.8902 8.28656 13.6396C8.33601 13.389 8.45806 13.1588 8.63727 12.9782C8.81649 12.7975 9.04482 12.6745 9.2934 12.6246C9.54198 12.5748 9.79964 12.6003 10.0338 12.6981C10.268 12.7959 10.4681 12.9615 10.6089 13.1739C10.7497 13.3864 10.8249 13.6361 10.8249 13.8916C10.8249 14.2342 10.6899 14.5628 10.4495 14.8051C10.2092 15.0473 9.88327 15.1835 9.54341 15.1835Z"
      fill="#C83D3D"
    />
  </Icon>
)

export default AddIcon
