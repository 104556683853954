import React from 'react'
import { Input } from 'reactstrap'
import { fieldify } from './utils'
import '../../styles/Fields.scss'

const SelectField = fieldify(
  ({
 field, form: { setFieldValue }, onEnd, options, children, placeholder, format, ...rest
}) => {
    const onChange = evt => {
      onEnd && onEnd(evt.target.value)
      if (format) {
        setFieldValue(field.name, format(evt.target.value))
      } else {
        return field.onChange(evt)
      }
    }
    return (
      <Input type="select" className="SelectField" {...field} {...rest} disabled={rest.readOnly} onChange={onChange}>
        <option hidden value="">
          {placeholder || 'Please Select'}
        </option>
        {options
          && options.map(option => {
            const entries = Object.entries(option)
            const [value, label] = entries[0]
            return (
              <option key={value} value={value}>
                {label}
              </option>
            )
          })}
        {children && children}
      </Input>
    )
  },
)

export default SelectField
