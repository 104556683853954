/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { find, isFunction } from 'lodash'

import { withContent } from './ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'

import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { GoToIcon } from '../../icons'

const SelectLabel = ({ label }) => (
  <div className="SelectLabel">
    {label}
  </div>
)

const SwitchPicker = ({
  propertyId,
  data,
  content,
  onUpdateItem,
  history,
  recordAnalytics,
}) => {
  const [toggle, setToggle] = useState(null)

  useEffect(() => {
    const currentSet = find(data, ({ propertyIds }) => propertyIds && propertyIds.includes(propertyId))
    const truthyIndex = content.switch.options.findIndex(op => op.value === true);
    if (truthyIndex === -1) return;
    if (currentSet && currentSet.content?.[content.triggerKey]) {
      setToggle(truthyIndex);
    } else {
      setToggle(truthyIndex === 0 ? 1 : 0)
    }
  }, [data])
  // Create Options

  const options = Object.values(content.switch.options).reduce((options, option) => {
    options.push({
      value: option.value,
      label: <SelectLabel label={option.label} />,
    })
    return options
  }, [])

  const onSubmit = async option => {
    recordAnalytics && recordAnalytics()
    const currentData = JSON.parse(JSON.stringify(data[0]))
    if (option.value) {
      currentData.propertyIds.push(propertyId)
    } else {
      currentData.propertyIds = currentData.propertyIds.filter(pId => pId !== propertyId)
    }
    return onUpdateItem(isFunction(content.formatValues) ? content.formatValues(currentData) : currentData)
  }

  return (
    <div className="ContentPicker Card">
      <div className="select-wrapper">
        <Select
          classNamePrefix="select"
          className="select-list"
          options={options}
          onChange={onSubmit}
          value={options[toggle]}
        />
        <PopoverMenu>
          <PopoverMenuItem
            onClick={() => history.push(`/content/${content.location}?edit=${data[0].id}`)}
          >
            <GoToIcon />
            {' '}
            Open This Component
          </PopoverMenuItem>
        </PopoverMenu>
      </div>
    </div>
  )
}

export default withRouter(withContent(SwitchPicker))
