/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { openModalWithContainer } from '../../reducers/modals'
import { MasterList } from '../list'
import { SLIDESHOW_COLUMNS } from '../../constants/tables/slideshows'
import { EmptyNavItemPlaceholder } from '../PageStates'
import PropertyDrawer from '../drawer/PropertyDrawer'

const SlideshowTable = ({
  data,
  rowAction,
  onUpdateItem,
  onDeleteItem,
  openRecipientProperties,
  onSetDefault,
  onConfirmDelete,
  dispatch,
  properties,
  onDuplicate,
}) => {
  const memoizedColumns = useMemo(() => SLIDESHOW_COLUMNS, [])

  const initialState = {
    hiddenColumns: ['createdAt'],
    onUpdateItem,
    onDeleteItem,
    rowAction,
    dispatch,
    openRecipientProperties,
    onSetDefault,
    onConfirmDelete,
    properties,
    onDuplicate,
  }

  return data?.length > 0 ? (
    <MasterList
      columns={memoizedColumns}
      data={data}
      rowAction={rowAction}
      initialState={initialState}
      search={false}
    />
  ) : (
    <EmptyNavItemPlaceholder />
  )
}

export default connect(null, { openModalWithContainer })(
  ({
    data,
    properties,
    onUpdateItem,
    onDeleteItem,
    dispatch,
    onSetDefault,
    onConfirmDelete,
    populateDrawer,
    rowAction,
    onDuplicate,
  }) => {
    const [showDrawer, setDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})

    const openRecipientProperties = (data, event) => {
      event.stopPropagation()
      setDrawerContent(data)
      setDrawer(true)
    }

    return (
      <>
        <PropertyDrawer
          header="Slideshow"
          drawerData={drawerContent}
          showDrawer={showDrawer}
          setDrawer={setDrawer}
          properties={properties}
        />
        <SlideshowTable
          data={data}
          rowAction={rowAction}
          properties={properties}
          onUpdateItem={onUpdateItem}
          onDeleteItem={onDeleteItem}
          dispatch={dispatch}
          onSetDefault={onSetDefault}
          onConfirmDelete={onConfirmDelete}
          openRecipientProperties={openRecipientProperties}
          onDuplicate={onDuplicate}
        />
      </>
    )
  },
)
