import React, { useState } from 'react'

import { FaBan } from 'react-icons/fa'
import { MESSAGES } from '../../constants/strings'
import { CheckIcon } from '../../icons'
import '../../styles/ServicesContainer.scss'

const ServiceButtons = props => {
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async action => {
    const { onUpdateItem, id, hasPaymentProvider } = props
    let message = MESSAGES.SERVICE_CONFIRMATION_MESSAGE_FIRST_PART + action + MESSAGES.SERVICE_CONFIRMATION_MESSAGE_SECOND_PART

    if (hasPaymentProvider) {
      if (action === 'approve') {
        message += MESSAGES.SERVICE_CONFIRMATION_MESSAGE_THIRD_PART
      }
      if (action === 'decline') {
        message += MESSAGES.SERVICE_CONFIRMATION_MESSAGE_FORTH_PART
      }
    } else {
      if (action === 'approve') {
        message = `${MESSAGES.SERVICE_CONFIRMATION_MESSAGE_FIFTH_PART} ${message}`
      }
    }

    message += '?'

    setIsLoading(true)
    if (window.confirm(message)) {
      onUpdateItem({ action, id })
    } else {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <button
        className="btn btn-outline btn-success"
        data-cy="btn-approve-service"
        onClick={() => onFinish('approve')}
        disabled={isLoading}
      >
        <CheckIcon />
        {MESSAGES.APPROVE}
      </button>
      <button
        className="btn btn-outline btn-danger"
        data-cy="btn-decline-service"
        onClick={() => onFinish('decline')}
        disabled={isLoading}
      >
        <FaBan />
        {MESSAGES.DECLINE}
      </button>
    </div>
  )
}

export default ServiceButtons
