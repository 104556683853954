import React, { useState } from 'react'
import { Input } from 'reactstrap'

export const SectionHeader = ({ title }) => {
  return (
    <div>
      <h2 className="messages-subtitle">{title}</h2>
    </div>
  )
}
