/* eslint-disable react/display-name */
import React from 'react'
import { get } from 'lodash'
import { ContentEditor, DefaultContentForm, ContentEditorTable } from '.'
import { RECOMMENDATIONS_CONTENT_COLUMNS } from '../../constants/tables/recommendations'
import { RECOMMENDATIONS, RESTAURANTS, ACTIVITIES, DIRECTORY } from '../../constants/forms/recommendations'
import { RestaurantsIcon, ActivitiesIcon, DirectoryIcon } from '../../icons'
import { Tabs } from '..'
import { CONTENT_FIELDS_PATH } from '../../constants/forms/content'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const RECOMMENDATIONS_TABS = [
  {
    slug: '',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Restaurant"
        content={RESTAURANTS}
        columns={RECOMMENDATIONS_CONTENT_COLUMNS}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
        modalSize="l"
        fieldsConfig={data => {
          const isProvider = get(data, `${CONTENT_FIELDS_PATH}.rating`, false) // TODO: Has provider
          return isProvider ? { readOnly: true } : { readOnly: false }
        }}
      />
    ),
    label: 'Restaurants',
    icon: RestaurantsIcon,
  },
  {
    slug: 'activities',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Activity"
        content={ACTIVITIES}
        columns={RECOMMENDATIONS_CONTENT_COLUMNS}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
        modalSize="l"
        fieldsConfig={data => {
          const isProvider = get(data, `${CONTENT_FIELDS_PATH}.rating`, false) // TODO: Has provider
          return isProvider ? { readOnly: true } : { readOnly: false }
        }}
      />
    ),
    label: 'Activities',
    icon: ActivitiesIcon,
  },
  {
    slug: 'directory',
    component: ({ properties, recordAnalytics }) => (
      <>
        {/* <div className="box">
          <DefaultContentForm content={RECOMMENDATIONS} />
        </div> */}
        <ContentEditorTable
          header="Directory"
          content={DIRECTORY}
          columns={RECOMMENDATIONS_CONTENT_COLUMNS}
          properties={properties}
          recordAnalytics={recordAnalytics}
          addButtonTop
          modalSize="l"
          fieldsConfig={data => {
            const isProvider = get(data, `${CONTENT_FIELDS_PATH}.rating`, false) // TODO: Has provider
            return isProvider ? { readOnly: true } : { readOnly: false }
          }}
        />
      </>
    ),
    label: 'Directory',
    icon: DirectoryIcon,
  },
]

const NavChildren = () => (
  <div className="box">
    <DefaultContentForm content={RECOMMENDATIONS} />
  </div>
)

const Recommendations = ({ properties, recordAnalytics }) => {
  return (
    <div className="Recommendations">
      <ContentEditor>
        <Tabs
          properties={properties}
          recordAnalytics={recordAnalytics.bind(this, SECTIONS.RECOMMENDATION)}
          path="content/recommendations"
          tabs={RECOMMENDATIONS_TABS}
          isSubTabs
          navTitle="Recommendations"
          navText="Add personalized restaurant, activity, and directory recommendations (hospitals, banks, gas stations, etc.) for your guests to discover during their stay. All recommendations are on the organizational level and can be applied to any nearby property."
          navChildren={NavChildren}
        />
      </ContentEditor>
    </div>
  )
}

export default Recommendations
