import React, { useEffect } from 'react'
import { isEmpty } from 'lodash';
import LOGO from '../../images/gvg-square-logo.png'
import loading from '../../images/gif/loading.gif'

const OAuthPopup = () => {
  const query = window.location.search.split('?')[1]

  const queryToObject = (query) => {
    const parameters = new URLSearchParams(query);
    return Object.fromEntries(parameters.entries());
  };

  useEffect(() => {
    const data = queryToObject(query);
    if (isEmpty(data) || (window && !window.opener)) {
      window.location.replace(`${window.location.origin}/home`)
      return;
    }
    const { url, code, error } = data
    if (url) {
      const parameters = window.location.search.split('?')[2]
      window.location.replace(`${url}?${parameters}`)
    } else if (error) {
      window.opener.postMessage({
        type: 'OAUTH_ERROR',
        error,
      })
      window.close()
    } else if (code) {
      window.opener.postMessage({
        type: 'OAUTH_RESPONSE',
        code,
      })
      window.close()
    }
  })

  return (
    <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '40vh' }}>
      <div><img src={LOGO} alt='GVG-Logo' width='140px' /></div>
      <div><img src={loading} alt='loading' style={{ margin: '15px 0px', width: '50px', paddingLeft: '10px' }} /></div>
    </div>
  )
}

export default OAuthPopup
