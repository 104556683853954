const PROGRESS = 'tasks/PROGRESS'

export function checkProgress(path, options = {}) {
  return dispatch => {
    dispatch({
      type: PROGRESS,
      values: 'testttt',
      path,
      options,
    })
  }
}

const initialState = {
  test: 'testing',
}

export default (state = initialState, action) => {
  let newState
  switch (action.type) {
    case PROGRESS:
      newState = {
        ...state,
        [action.path]: {
          ...(state[action.path] || {}),
          ...action.options,
          ...action.values,
        },
      }
      return newState

    default:
      return state
  }
}
