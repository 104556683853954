import Storage from '../Storage'
import api from '../api'

const tasks = new Storage('tasks')

const PROGRESS = 'tasks/PROGRESS'
const DISMISS = 'tasks/DISMISS'
const CLOSE = 'tasks/CLOSE'

export function checkProgress(path, options = {}) {
  return dispatch => api.get(path).then(res => {
      if (res.status === 200) {
        dispatch({
          type: PROGRESS,
          values: res.data,
          path,
          options,
        })
      }
    })
}

export const dismissTask = path => ({ type: DISMISS, path })
export const closeTask = path => ({ type: CLOSE, path })

const initialState = tasks.get('paths') || {}

export default (state = initialState, action) => {
  let newState
  switch (action.type) {
    case CLOSE:
      newState = {}
      delete newState[action.path]
      tasks.set('paths', newState)
      return newState

    case DISMISS:
      newState = {
        ...state,
      }
      delete newState[action.path]
      tasks.set('paths', newState)
      return newState

    case PROGRESS:
      newState = {
        ...state,
        [action.path]: {
          ...(state[action.path] || {}),
          ...action.options,
          ...action.values,
        },
      }
      tasks.set('paths', newState)
      return newState

    default:
      return state
  }
}
