import React from 'react'
import { get } from 'lodash'
import { Formik } from 'formik'
import Resource from '../../Resource'
import {
 TextareaField, ImageDropzoneField, ImageSelection, ToggleSwitchField,
} from '../../forms/fields'
import { generateForm } from '../../forms/utils'
import { CustomWelcomePreview } from '.'
import '../../styles/CustomWelcome.scss'
import { MESSAGES } from '../../constants/strings'

const defaultImages = [
  'https://nec-assets.s3.amazonaws.com/uploaded-image/1a7b2c2c-ca51-4a62-9be5-99a1fcddcec5_picture.jpg',
  'https://nec-assets.s3.amazonaws.com/uploaded-image/06fcc6f5-0771-4bd6-a1ee-72fc133847ea_picture.jpg',
  'https://nec-assets.s3.amazonaws.com/uploaded-image/43afae19-ba14-4b15-85f8-73548bc85dcb_picture.jpg',
  'https://nec-assets.s3.amazonaws.com/uploaded-image/f9e7e609-ddd5-41cd-9953-11e2153a2a20_picture.jpg',
]

const CUSTOM_WELCOME_FIELDS = [
  {
    label: MESSAGES.WIFI_INFO,
    name: 'showWifiInfo',
    component: ToggleSwitchField,
    props: {
      saveOnExit: true,
      toggleSwitch: true,
      customLabel: 'Display Wi-fi Info',
    },
  },
  {
    label: MESSAGES.LOGO,
    name: 'logo',
    component: ImageDropzoneField,
    props: {
      desiredSize: { width: 200, height: 200 },
      displaySize: { width: 100, height: 62.5 },
      showClear: true,
      defaultLabel: true,
      saveOnExit: true,
      flexible: true,
    },
  },
  {
    label: MESSAGES.WELCOME_TEXT,
    name: 'text',
    component: TextareaField,
    props: {
      maxLength: 100,
      saveOnExit: true,
      shouldToggle: false,
    },
  },
  {
    label: MESSAGES.BACKGROUND,
    name: 'background',
    component: ImageSelection,
    props: {
      defaultImages,
      dimensions: { width: 1080, height: 1920 },
      displayScale: 8,
    },
  },
]

const CustomWelcomeForm = ({ values, form, handleSubmit }) => {
  return (
    <div className="CustomWelcome">
      <div className="custom-welcome-form">
        <div className="custom-welcome-fields">{form}</div>
        <CustomWelcomePreview values={values} />
      </div>
    </div>
  )
}

const CustomWelcome = _ => {
  return (
    <Resource collection path={['customizations', 'welcome']}>
      {(data, { onUpdateItem }) => {
        const customization = data && data.customizations && data.customizations.find(({ isDefault }) => isDefault)
        const welcome = get(customization, 'content', {})
        const customWelcome = generateForm({
          form: CUSTOM_WELCOME_FIELDS,
          props: welcome,
        })
        const background = get(welcome, 'background') || defaultImages[0]
        return (
          <Formik
            enableReinitialize
            initialValues={{ ...customWelcome.initialValues, background }}
            onSubmit={values => {
              onUpdateItem({
                id: `${customization.id}`,
                content: { ...values },
                propertyId: null,
              })
            }}
            render={props => <CustomWelcomeForm form={customWelcome.component} {...props} />}
          />
        )
      }}
    </Resource>
  )
}

export default CustomWelcome
