import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
import calender from '../../images/calender.png'
import { ADDITIONAL_STRINGS } from '../../constants/home'
import { thirdPartyAnalytics } from '../../services'
import {
 EVENTS, ACTIONS, SECTIONS, SUB_SECTIONS,
} from '../../constants/thirdPartyAnalytics'

const AddPropertyCard = ({
 propertyHighlights, cardData = {}, populateDrawer, history,
}) => {
  const showReservation = cardData.statusLabel !== ADDITIONAL_STRINGS.STRING_ZERO

  const { me: userInfo } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }
  const recordAnalytics = () => {
    const updatedProperties = { ...analyticsProperties }
    thirdPartyAnalytics.track(EVENTS.HOME_ADD_PROPERTY, updatedProperties)
  }

  return (
    <Fragment>
      <h4 className="db-card-title">
        <i>{ADDITIONAL_STRINGS.NUMBER_ONE}</i>
        <span>{ADDITIONAL_STRINGS.YOUR_PROPERTIES}</span>
      </h4>
      <div className={`add-property ${!showReservation ? 'all-border-radius' : ''}`}>
        <i
          className="icon-plus"
          onClick={() => {
            recordAnalytics()
            history.push('/properties?import=true')
          }}
        />
        <h4>{ADDITIONAL_STRINGS.ADD_PROPERTY_LABEL}</h4>
        <p>{ADDITIONAL_STRINGS.ADD_PROPERTY_MESSAGE}</p>
        <div className="property-count">
          <h5>{propertyHighlights.propertyCount}</h5>
          <em>{ADDITIONAL_STRINGS.PROPERTIES}</em>
        </div>
      </div>
      {showReservation && (
        <div className="reservation-card">
          <div className="calender-col">
            <img src={calender} alt="calendar" />
          </div>
          <div>
            {cardData.statusLabel === ADDITIONAL_STRINGS.STRING_ONE ? (
              <p>
                {cardData.statusLabel}
                {' '}
                {ADDITIONAL_STRINGS.RESERVATION_MESSAGE_SINGLE}
              </p>
            ) : (
              <p>
                {cardData.statusLabel}
                {' '}
                {ADDITIONAL_STRINGS.RESERVATION_MESSAGE_MULTIPLE}
              </p>
            )}
            <p>
              <Link
                to="/home"
                onClick={() => {
                  populateDrawer(cardData)
                }}
              >
                {ADDITIONAL_STRINGS.VIEW_INFO}
                {' '}
                &#62;
              </Link>
            </p>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default withRouter(AddPropertyCard)
