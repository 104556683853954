import React from 'react'
import { PAYABLE_FEE_TYPES } from '../../constants/services'
import { MESSAGES } from '../../constants/strings'
import { formatPrice, getUserReadableDuration } from '../../Utils'

const ServiceRequestName = ({ service, quantity }) => {
  const { name, additionalCheckoutTime, feeType } = service
  const { price } = formatPrice(service)
  const addedTime = getUserReadableDuration(additionalCheckoutTime)

  return (
    <>
      <h2>{name}</h2>
      {quantity && quantity > 0 && (
        <small>
          {`${MESSAGES.QUANTITY}: ${quantity}`}
          {PAYABLE_FEE_TYPES.includes(feeType) && price && `, ${price} each`}
        </small>
      )}
      {additionalCheckoutTime && (
        <small>
          {`${MESSAGES.ADDITIONAL_TIME}: ${addedTime}`}
          {PAYABLE_FEE_TYPES.includes(feeType) && price && `, ${price}`}
        </small>
      )}
    </>
  )
}

export default ServiceRequestName
