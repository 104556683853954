import { generateFormPreview } from '../../forms/utils'
import getGenericModulePreview from './previewFactory'

const Preview = (module, template, form) => {
  const genericModulePreview = getGenericModulePreview(module, template)

  return generateFormPreview({ preview: genericModulePreview, form })
}

export default Preview
