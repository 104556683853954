const SET_ORIENTATION = 'SET_ORIENTATION'

export const setOrientation = (payload = 'original') => {
  return {
    type: SET_ORIENTATION,
    payload,
  }
}

export default function reducer(state = { orientation: 'original' }, action) {
  switch (action.type) {
    case SET_ORIENTATION:
      return {
        ...state,
        ...{ orientation: action.payload },
      }
    default:
      return state
  }
}
