import React, { Fragment, useState } from 'react'
import Select from 'react-select';
import { MESSAGES } from '../../constants/strings'
import { displayErrorToast } from '../../utils/displayToast'
import '../../styles/AdminContainer.scss'

const DuplicateProperty = ({
    dispatch, apiRequest, organizatons, propertyMap, getProperties,
}) => {
    const [organizationFrom, setOrganizationFrom] = useState()
    const [organizationTo, setOrganizationTo] = useState()
    const [propertySelected, setPropertySelected] = useState()
    const onDuplication = async () => {
        if (!propertySelected || !organizationTo) {
          displayErrorToast('Please select all fields. ')
          return
        }
        await dispatch(
          apiRequest([`properties/${propertySelected.value}/clone`], 'POST', {
            organizationId: organizationTo.value,
          })
        )
      }
  return (
    <Fragment>
      <div className="feature-name pt-3">
        <h4>{`${MESSAGES.DUPLICATE_PROPERTY}`}</h4>
      </div>

      <div className="row duplication-property-from">
        <div className="col-md-4 from-select">
          <h6>{`${MESSAGES.FROM}`}</h6>
          <div className="select-box">
            <Select
              options={organizatons}
              value={organizationFrom}
              placeholder={MESSAGES.ORGANIZATION}
              onChange={e => {
                    setPropertySelected(null)
                    getProperties(e.value)
                    setOrganizationFrom(e)
                  }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="select-box">
            <h6>&nbsp;</h6>
            <Select
              options={propertyMap}
              value={propertySelected}
              placeholder={MESSAGES.PROPERTY}
              onChange={e => {
                    setPropertySelected(e)
                  }}
            />
          </div>
        </div>
        <div className="col-md-4 to-select">
          <h6>{`${MESSAGES.TO}`}</h6>
          <div className="select-box">
            <Select
              options={organizatons}
              value={organizationTo}
              placeholder={MESSAGES.ORGANIZATION}
              onChange={e => {
                    setOrganizationTo(e)
                  }}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="button" onClick={onDuplication}>
            {MESSAGES.DUPLICATE}
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default DuplicateProperty
