import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import dummyURL from '../../images/avatar_placeholder.png'
import { Yup, SubmitButton } from '../../forms'
import { HostFields, HostDescription, withHostForm } from '../../components/Host'
import { SUB_SECTIONS, ACTIONS } from '../../constants/thirdPartyAnalytics'

const HostContainer = props => {
  const { handleSubmit, host, recordAnalytics } = props

  const hasItem = Object.keys(host).length
  const verb = {
    create: 'New',
    edit: 'Edit Current',
  }

  const handleAddHost = e => {
    e.preventDefault()
    recordAnalytics &&
      recordAnalytics({
        subSection: SUB_SECTIONS.MANAGE_HOST,
        action: ACTIONS.CREATE,
      })
    handleSubmit()
  }

  return (
    <form onSubmit={handleAddHost}>
      <ModalHeader name="Host" verb={verb} hasItem={hasItem} />
      <ModalBody>
        <HostDescription />
        <HostFields />
      </ModalBody>
      <ModalFooter>
        <SubmitButton color="primary" data-cy="btn-add-host-done">
          Save
        </SubmitButton>
      </ModalFooter>
    </form>
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('required')
    .default('')
    .nullable(),
  contactNumber: Yup.string()
    .phone()
    .required('required')
    .nullable(),
  phoneExt: Yup.string().nullable(),
  photoURL: Yup.string()
    .default(dummyURL)
    .nullable(),
})

export default withHostForm({ validationSchema })(HostContainer)
