import React from 'react'
import { pluralize } from '../../Utils'

const RecipientProperties = ({
  propertiesLength,
  properties,
  openRecipientProperties,
  totalPropertiesCount = 0,
  isHistoryView = false,
}) => {
  return (
    <div className="title-cell link-text">
      <div className="" onClick={openRecipientProperties} style={{ cursor: 'pointer' }}>
        <strong>{`${propertiesLength} of ${totalPropertiesCount} `}</strong>
        {pluralize(totalPropertiesCount, 'Property', null, false)}
        <br />
        {isHistoryView && 'Read'}
      </div>
    </div>
  )
}

export default RecipientProperties
