/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { openModalWithContainer } from '../../reducers/modals'
import { Announcement, ServiceIcon } from '../../icons'
import { EmptyNavItemPlaceholder } from '../PageStates'
import { MasterList } from '../list'
import RecipientProperties from './RecipientProperties'
import { UPDATED_CELL } from '../../constants/tables/common'
import MessagesDrawer from './MessagesDrawer'
import {
  MESSAGE_CREATED_CELL,
  MESSAGE_LOGO,
  MESSAGE_OPTIONS,
  MESSAGE_STATUS,
  MESSAGE_TITLE,
  RECURRENCE,
  SCHEDULE,
} from '../../constants/tables/messages'

const COLUMNS = [
  {
    id: 'messages',
    columns: [
      MESSAGE_LOGO,
      MESSAGE_TITLE,
      {
        accessor: 'updatedAt',
      },
      {
        Header: 'Assigned to',
        accessor: 'properties',
        sortType: (a, b) => {
          const propertyA = get(a, 'original.properties.length', '')
          const propertyB = get(b, 'original.properties.length', '')
          return propertyA - propertyB
        },
        Cell: ({ row: { original }, initialState: { properties, openRecipientProperties } }) => {
          return (
            <RecipientProperties
              openRecipientProperties={e => openRecipientProperties(original, e)}
              properties={properties}
              propertiesLength={original.properties?.length || 0}
              totalPropertiesCount={properties.length}
              original={original}
            />
          )
        },
        options: {
          classNames: 'clickable',
          hideOn: ['tablet', 'mobile'],
        },
      },
      MESSAGE_CREATED_CELL,
      RECURRENCE,
      SCHEDULE,
      MESSAGE_STATUS,
      MESSAGE_OPTIONS,
    ],
  },
]

const MessagesTable = ({
  messages,
  properties,
  onUpdateItem,
  openMessage,
  onCreateItem,
  onDeleteItem,
  openRecipientProperties,
  duplicateMessage,
  openModal,
}) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  const initialState = {
    hiddenColumns: 'updatedAt',
    sortBy: [{ id: 'updatedAt', desc: true }],
    properties,
    onUpdateItem,
    openMessage,
    onCreateItem,
    openRecipientProperties,
    duplicateMessage,
    onDeleteItem,
    openModal,
  }

  return (
    <MasterList
      columns={memoizedColumns}
      data={messages}
      initialState={initialState}
      rowAction={openMessage}
      search
      emptyState={{ icon: Announcement, text: 'You have not yet added any announcements' }}
    />
  )
}

export default connect(null, { openModalWithContainer })(
  ({ messages, properties, openModalWithContainer, onUpdateItem, openModal, status, onCreateItem, onDeleteItem }) => {
    const [showDrawer, setDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})

    const saveDuplicate = message => {
      delete message.id
      onCreateItem(message)
    }
    // functions the same as rowAction but performed on an individual cell,
    // open message is used twice. 1. in messages cell 2. for the edit tab in dot options
    const openMessage = (message, e) => {
      openModalWithContainer(
        'MessageContainer',
        {
          onSave: onUpdateItem,
          message,
          properties,
        },
        {
          size: 'xl',
          customClass: 'add-custom-service-modal',
        }
      )
    }

    const duplicateMessage = message => {
      const originalMessageId = message.id
      openModalWithContainer(
        'MessageContainer',
        {
          message,
          isDuplicate: true,
          properties,
          onSave: saveDuplicate,
          originalMessageId,
        },
        {
          size: 'xl',
          customClass: 'add-custom-service-modal',
        }
      )
    }

    const openRecipientProperties = (message, event) => {
      event.stopPropagation()
      setDrawerContent(message)
      if (message.properties.length > 0) {
        setDrawer(true)
      }
    }

    return (
      <>
        <MessagesDrawer
          drawerData={drawerContent}
          showDrawer={showDrawer}
          setDrawer={setDrawer}
          properties={properties}
        />
        <MessagesTable
          messages={messages}
          properties={properties}
          columns={COLUMNS}
          onUpdateItem={onUpdateItem}
          status={status}
          onCreateItem={onCreateItem}
          onDeleteItem={onDeleteItem}
          openMessage={openMessage}
          openRecipientProperties={openRecipientProperties}
          duplicateMessage={duplicateMessage}
          openModal={openModal}
        />
        {/* {messages.length === 0 && (
          <EmptyNavItemPlaceholder
            text={'You have not yet added any announcements'}
            navItem="an announcement"
            icon={<MessageIcon height="72" width="72" />}
          />
        )} */}
      </>
    )
  }
)
