import React from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import { SortArrowIcon } from '../../icons'

const ListHeadCell = ({
 className, column, children, ...props
}) => {
  const headerOptionClass = get(column, 'options.headerClassNames')
  const hideOn = get(column, 'options.hideOn')
  const hideOnClassnames = hideOn && hideOn.map(device => `hide-${device}`)

  return (
    <th className={classNames(['ListHeadCell', className, headerOptionClass, hideOnClassnames])}>
      <div
        className={classNames(
          'list-head-cell-inner',
          { sorted: column && column.isSorted },
          { 'can-sort': column && column.canSort },
        )}
        {...props}
      >
        {children}
        {column && (
          <div className="sorting-arrows">
            {column.canSort && (
              <>
                {(column.isSortedDesc || !column.isSorted) && (
                  <div className="arrow asc">
                    <SortArrowIcon />
                  </div>
                )}
                {(!column.isSortedDesc || !column.isSorted) && (
                  <div className="arrow desc">
                    <SortArrowIcon />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </th>
  )
}

export default ListHeadCell
