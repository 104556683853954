import classNames from 'classnames';
import React from 'react'

const Arrow = ({ hide = false, icon = () => null, onClick = () => null }) => {
  const Icon = icon;
  return (
    <div className={classNames('tab-arrow', { 'tab-arrow-hide': hide })} onClick={onClick}>
      <Icon className="arrow-icon" />
    </div>
  )
}

export default Arrow;
