/* eslint-disable react/display-name */
import React from 'react'
import { uniq, isEmpty } from 'lodash'

// Doing this manually as default 'filter: 'includes'' doesn't work since updating
export function includesFilter(rows, id, filterValue) {
  if (!isEmpty(filterValue)) {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return filterValue.includes(rowValue)
    })
  }
  return rows
}

export const SelectRowCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type="checkbox" ref={resolvedRef} {...rest} />
})

export function SelectColumnFilter({
  column: {
 filterValue, setFilter, preFilteredRows, id, filterFilters, extraFilterOptions,
},
  initialState,
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  let filteredOptions = options
  if (filterFilters) {
    filteredOptions = filterFilters(options, initialState)
  }

  if (extraFilterOptions) {
    const extraOptions = extraFilterOptions(initialState)
    filteredOptions.push(...extraOptions)
  }

  filteredOptions = uniq(filteredOptions)
    .sort()
    .filter(item => item !== '')

  return (
    <div className="FilterSelect">
      <select
        className="form-control"
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {filteredOptions.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}
