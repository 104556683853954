import React, { Fragment, useState } from 'react'
import 'react-select/dist/react-select.cjs'
import { Tabs } from '../components'
import '../styles/AdminContainer.scss'
import { MESSAGES } from '../constants/strings'
import { apiRequest } from '../reducers/data'
import ManageRevenue from '../components/superadmin/ManageRevenue'
import ChangePropertyAddress from '../components/superadmin/ChangePropertyAddress'
import EmailSetupPMSReport from '../components/superadmin/EmailSetupPMSReport'
import DuplicateProperty from '../components/superadmin/DuplicateProperty'
import Devices from '../components/superadmin/Devices'

function AdminContainer({ dispatch, me }) {
  const SUPERADMIN_TABS = [
    {
      slug: 'manage-revenue-share',
      component: ManageRevenue,
      label: 'Manage Revenue Share',
    },
    {
      slug: 'duplicate-property',
      component: DuplicateProperty,
      label: 'Duplicate Unit',
    },
    {
      slug: 'change-property-address',
      component: ChangePropertyAddress,
      label: 'Property Address',
    },
    {
      slug: 'email-setup-pms-report',
      component: EmailSetupPMSReport,
      label: 'Email for PMS',
    },
    {
      slug: 'devices',
      component: Devices,
      label: 'Devices',
    },
  ]
  const [properties, setProperties] = useState([])
  const onSync = async () => {
    await dispatch(apiRequest(['features/zoura-rate-plans-sync'], 'POST'))
  }
  const getProperties = async orgId => {
    const propertiesByOrganizationId = await dispatch(apiRequest([`/properties/byOrganizationId/${orgId}`], 'GET'))
    propertiesByOrganizationId.json && setProperties(propertiesByOrganizationId.json)
  }
  const organizatons = me.organizations.map(organizationData => ({
    value: organizationData.id,
    label: organizationData.name.trim() || organizationData.id,
  }))

  const propertyMap = properties.map(propertyData => ({
    value: propertyData.id,
    label: propertyData.nickname ?? propertyData.addressLine1,
  }))
  return (
    <div className="AdminContainer">
      <div className="TabHeader">
        <div className="title">
          <div className="TabTitle">{MESSAGES.ADMIN}</div>
        </div>
      </div>
      <Fragment>
        <Tabs
          path="admin"
          tabs={SUPERADMIN_TABS}
          onSync={onSync}
          dispatch={dispatch}
          apiRequest={apiRequest}
          organizatons={organizatons}
          propertyMap={propertyMap}
          getProperties={getProperties}
        />
      </Fragment>
    </div>
  )
}

export default AdminContainer
