import React from 'react'
import Icon from './Icon'

function RightArrowIcon(props) {
  return (
    <Icon viewBox="0 0 45 35" {...props}>
      <path
        d="M34.757 14.5H1.5A1.5 1.5 0 0 0 0 16v3a1.5 1.5 0 0 0 1.5 1.5h33.257l-9.878 9.879 4.242 4.242 15-15a3 3 0 0 0 0-4.242l-15-15L24.88 4.62l9.878 9.879z"
        fill="#275248"
      />
    </Icon>
  )
}

export default RightArrowIcon
