import React from 'react'
import Resource from '../../Resource'
import Loading from '../Loading'

export const withContent = Wrapped => {
  const withContent = ({ ...props }) => {
    const path = []
    if (props.content.type === 'checkInOutNotification') {
      path.push('notifications')
    } else {
      if (!props.content.isNotCustomization) {
        path.push('customizations')
      }
      if (props.propertyOnly) {
        path.push(`${props.content.type}/${props.propertyId}`)
      } else {
        path.push(props.content.type)
      }
    }

    return (
      <Resource collection path={path} placeholderComponent={Loading}>
        {(data, {
 dispatch, onUpdateItem, onCreateItem, onDeleteItem, onRefresh,
}) => {
          let totalProperties = 0
          if (props.content.type === 'checkInOutNotification') {
            const modifiedData = data.map(item => {
              const propertyIds = item && item.properties && item.properties.map(property => property.id)
              totalProperties = item && item.totalProperties
              const customizationObject = {
                ...item,
                name: item.name,
                content: {
                  name: item.name,
                  email: item.email,
                  phone: item.phone,
                  checkin: item.checkin,
                  checkout: item.checkout,
                },
                propertyId: null,
                propertyIds,
                type: 'checkoutNotification',
                id: item.id,
              }
              return customizationObject
            })

            data.customizations = modifiedData
            data.totalProperties = totalProperties
            data.totalCities = []
          }
          return (
            <Wrapped
              data={props.content.isNotCustomization ? data[props.content.type] : data.customizations}
              totalProperties={data.totalProperties}
              totalCities={data.totalCities}
              onUpdateItem={onUpdateItem}
              onCreateItem={onCreateItem}
              onDeleteItem={onDeleteItem}
              dispatch={dispatch}
              {...props}
              onRefresh={onRefresh}
            />
          )
        }}
      </Resource>
    )
  }

  return withContent
}
