import React from 'react'
import { Tabs } from '../../components'
import ContentTable from './ContentTable'
import { RESTAURANTS, ACTIVITIES, DIRECTORY } from '../../constants/forms/recommendations'
import { RECOMMENDATION_COLUMNS, RECOMMENDATION_SELECT_COLUMNS } from '../../constants/tables/recommendations'
import { RestaurantsIcon, ActivitiesIcon, DirectoryIcon } from '../../icons'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const Restaurants = ({ property, recordAnalytics }) => (
  <ContentTable
    content={RESTAURANTS}
    columns={RECOMMENDATION_COLUMNS}
    selectExistingColums={RECOMMENDATION_SELECT_COLUMNS}
    propertyId={property.id}
    propertyCity={property.addressCity}
    recordAnalytics={recordAnalytics}
    emptyState={{ icon: RestaurantsIcon, text: 'You have not yet added or applied any restaurants for this property' }}
    propertyOnly
    modalSize="l"
  />
)

const Activities = ({ property, recordAnalytics }) => (
  <ContentTable
    content={ACTIVITIES}
    columns={RECOMMENDATION_COLUMNS}
    selectExistingColums={RECOMMENDATION_SELECT_COLUMNS}
    propertyId={property.id}
    propertyCity={property.addressCity}
    recordAnalytics={recordAnalytics}
    emptyState={{ icon: ActivitiesIcon, text: 'You have not yet added or applied any activities for this property' }}
    propertyOnly
    modalSize="l"
  />
)

const Directory = ({ property, recordAnalytics }) => (
  <ContentTable
    content={DIRECTORY}
    columns={RECOMMENDATION_COLUMNS}
    selectExistingColums={RECOMMENDATION_SELECT_COLUMNS}
    propertyId={property.id}
    propertyCity={property.addressCity}
    recordAnalytics={recordAnalytics}
    emptyState={{
      icon: DirectoryIcon,
      text: 'You have not yet added or applied any directory items for this property',
    }}
    propertyOnly
    modalSize="l"
  />
)

const RECOMMENDATION_TABS = [
  {
    slug: '',
    component: Restaurants,
    label: 'Restaurants',
    icon: RestaurantsIcon,
  },
  {
    slug: 'activities',
    component: Activities,
    label: 'Activities',
    icon: ActivitiesIcon,
  },
  {
    slug: 'directory',
    component: Directory,
    label: 'Directory',
    icon: DirectoryIcon,
  },
]

const Recommendations = ({ property, recordAnalytics }) => {
  const handleAnalytics = (subSection, action) => {
    recordAnalytics && recordAnalytics({ section: SECTIONS.RECOMMENDATION, subSection, action })
  }

  return (
    <div className="Recommendations">
      <Tabs
        navTitle="Recommendations"
        path={`properties/${property.id}/recommendations`}
        tabs={RECOMMENDATION_TABS}
        recordAnalytics={handleAnalytics}
        property={property}
        isSubTabs
        navText="Add personalized restaurant, activity, and directory recommendations (hospitals, banks, gas stations, etc.) for your guests to discover during their stay. All recommendations are on the organizational level and can be applied to any nearby property."
      />
    </div>
  )
}

export default Recommendations
