import React from 'react'
import { TextField } from '.'

const TextFieldMeta = ({
 beforeField, afterField, component: MyComponent, defaultProps, ...rest
}) => {
  return (
    <div className="TextFieldMeta">
      {beforeField && <div className="before">{beforeField}</div>}
      <MyComponent {...rest} />
      {afterField && <div className="after">{afterField}</div>}
    </div>
  )
}

export default TextFieldMeta

TextFieldMeta.defaultProps = {
  component: TextField,
}
