import React from 'react'
import { PAYABLE_FEE_TYPES, SERVICE_TEMPLATES } from '../../constants/services'
import { MESSAGES } from '../../constants/strings'
import { openModalWithContainer } from '../../reducers/modals'

import { formatPrice, getMinMaxPrices } from '../../Utils'
import PaymentIcon from './PaymentIcon'

const ServicePrice = ({
  service,
  hasPaymentProvider,
  supportPaymentProvider,
  amount,
  dispatch,
  request,
  parameters,
}) => {
  const { price, priceType } = formatPrice(service, amount)
  let paymentIcon = null

  if (
    supportPaymentProvider
    && (PAYABLE_FEE_TYPES.includes(service.feeType) || service.template === SERVICE_TEMPLATES.BUNDLE)
  ) {
    if (hasPaymentProvider) {
      paymentIcon = <PaymentIcon style={{ marginLeft: '8px' }} />
    } else {
      paymentIcon = <PaymentIcon style={{ marginLeft: '8px', color: '#D5D5D5' }} />
    }
  }

  if (service.template === SERVICE_TEMPLATES.BUNDLE) {
    const { minPrice, maxPrice } = getMinMaxPrices(service.serviceChoices || []) || {}
    return (
      <>
        <button
          className="text-link"
          onClick={event => {
            event.stopPropagation()
            dispatch(
              openModalWithContainer(
                request ? 'BundleServiceResponseContainer' : 'BundleServiceDetailContainer',
                {
                  service,
                  parameters,
                  request,
                },
                { size: 's', customClass: 'modal-sm' },
              ),
            )
          }}
        >
          <div className="text-underline no-wrap">
            {MESSAGES.BUNDLE_PACKAGE}
            {paymentIcon}
          </div>
          {!request && (
            <div className="service-pricing">
              {minPrice}
              {maxPrice && ` - ${maxPrice}`}
            </div>
          )}
        </button>
      </>
    )
  }

  if (service.feeType) {
    const value = `${price || ''} ${priceType || ''} ${
      service.customFeeMessage ? `${MESSAGES.CUSTOM}: ${service.customFeeMessage}` : ''
    }`
    return (
      <div
        title={value}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
        {' '}
        {paymentIcon || ''}
      </div>
    )
  }
  if (service.fee && !service.feeType) {
    // old crappy service
    return (
      <div>
        {price}
        {' '}
        {priceType}
      </div>
    )
  }
  return <div>{MESSAGES.NO_CHARGE}</div>
}

export default ServicePrice
