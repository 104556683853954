import React, { useState, Fragment } from 'react'
import { connect as formikConnect } from 'formik'
import { Wizard } from '../../components/wizard'
import { generateWizard } from '../../forms/utils'
import { ServicesAdvertisementIcon, RecommendationsAdvertisementIcon, CustomAdvertisementsIcon } from '../../icons'
import Choices from '../../components/Choices'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import '../../styles/Advertisements.scss'
import { SERVICE_WIZARD, RECOMMENDATION_WIZARD, CUSTOM_WIZARD } from '../../constants/forms/advertisements'

const ADVERTISEMENT_TYPE_CHOICES = ({ isEdit = false }) => [
  {
    icon: RecommendationsAdvertisementIcon,
    templateName: 'Recommendation Ad',
    templateDescription: 'Add an advertisement for your recommended businesses.',
    templateKey: 'Recommendation',
    name: 'Recommendation Ad',
    verb: 'New',
    form: RECOMMENDATION_WIZARD({ isEdit }),
  },
  {
    icon: ServicesAdvertisementIcon,
    templateName: 'Service Ad',
    templateDescription: 'Add an advertisement for the various services that you offer.',
    templateKey: 'Service',
    name: 'Service Ad',
    verb: 'New',
    form: SERVICE_WIZARD({ isEdit }),
  },
  {
    icon: CustomAdvertisementsIcon,
    templateName: 'Custom Ad',
    templateDescription:
      'Add a custom advertisement for promoting events or content that are not tied to your recommendations or offered services.',
    templateKey: 'Custom',
    name: 'Custom Ad',
    verb: 'New',
    form: CUSTOM_WIZARD,
  },
]

const AdvertisementsContainer = ({ advertisement, dispatch, onSave, medias, selectedImageId, image }) => {
  const currentSelectedTemplateIndex = ADVERTISEMENT_TYPE_CHOICES({
    isEdit: advertisement.id,
  }).findIndex(({ templateKey }) => templateKey === advertisement.type)

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(currentSelectedTemplateIndex)
  const selectedTemplate = ADVERTISEMENT_TYPE_CHOICES({ isEdit: advertisement.id })[selectedTemplateIndex] || null

  if (selectedTemplateIndex === -1) {
    return (
      <Fragment>
        <ModalHeader name="Template" verb={{ create: 'Choose a' }} />
        <ModalBody>
          <div className="ad-template">
            <Choices
              choices={ADVERTISEMENT_TYPE_CHOICES({ isEdit: advertisement.id })}
              setChoice={setSelectedTemplateIndex}
            />
          </div>
        </ModalBody>
      </Fragment>
    )
  } else {
    const advertisementFormData = generateWizard({ form: selectedTemplate.form, props: advertisement })

    const onSaveText = advertisement.id ? 'Update' : 'Publish'
    const formatValues = v => {
      return {
        id: advertisement.id,
        ...v,
        type: selectedTemplate.templateKey,
      }
    }

    const formData = {
      ...advertisementFormData,
      initialValues: {
        ...advertisementFormData.initialValues,
        type: selectedTemplate.templateKey,
        originalImageURL: advertisement.originalImageURL,
        medias,
        selectedImageId,
        image
      },
    }

    const titleComponent = props => (
      <ModalHeader
        name={selectedTemplate.name}
        verb={{ create: selectedTemplate.verb }}
        hasItem={advertisement.id}
        {...props}
      />
    )
    let otherButtons
    if (selectedTemplate.templateKey === 'Service') {
      otherButtons = '   The advertisement will be displayed on all properties on which this service is assigned'
    }
    if (selectedTemplate.templateKey === 'Recommendation') {
      otherButtons = '   The advertisement will be displayed on all properties on which this recommendation is assigned'
    }
    const footerComponent = props => <ModalFooter otherButtons={otherButtons} {...props} />
    return (
      <div className="AdvertisementContainer">
        <Wizard
          titleComponent={titleComponent}
          bodyWrapper={ModalBody}
          formData={formData}
          footerWrapper={footerComponent}
          formatValues={formatValues}
          onSave={onSave}
          dispatch={dispatch}
          onSaveText={onSaveText}
        />
      </div>
    )
  }
}

export default formikConnect(AdvertisementsContainer)
