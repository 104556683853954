import React from 'react'
import Icon from './Icon'

function ServiceIcon(props) {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" strokeWidth="2" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 5.11111C8.66667 4.49746 9.16413 4 9.77778 4H14.2222C14.8359 4 15.3333 4.49746 15.3333 5.11111C15.3333 5.72476 14.8359 6.22222 14.2222 6.22222H12.5556V7.35041C17.206 7.63719 20.8889 11.4996 20.8889 16.2222H3.11111C3.11111 11.4996 6.79404 7.63719 11.4444 7.35041V6.22222H9.77778C9.16413 6.22222 8.66667 5.72476 8.66667 5.11111ZM3.11111 17.3333C2.49746 17.3333 2 17.8308 2 18.4444C2 19.0581 2.49746 19.5556 3.11111 19.5556H20.8889C21.5025 19.5556 22 19.0581 22 18.4444C22 17.8308 21.5025 17.3333 20.8889 17.3333H3.11111Z"
      />
    </Icon>
  )
}

export default ServiceIcon
