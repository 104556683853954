import React from 'react'
import { connect, getIn } from 'formik'
import AddressInput from '../../components/AddressInput'

const AddressField = ({
 name, readOnly, placeholder, formik: { values, setFieldValue },
}) => {
  const onChange = addressParts => {
    const {
      addressCity,
      addressCountry,
      addressLine1,
      addressLine2,
      addressState,
      addressZIP,
      latitude,
      longitude,
    } = addressParts
    if (latitude && longitude) {
      const currentValues = getIn(values, name)

      const locationData = {
        ...currentValues,
        city: addressCity,
        country: addressCountry,
        address1: addressLine1,
        address2: addressLine2,
        state: addressState,
        zip: addressZIP,
        coordinates: {
          latitude,
          longitude,
        },
      }
      setFieldValue(name, locationData)
    }
  }

  return (
    <>
      <AddressInput
        formik
        name={`${name}.displayAddress`}
        placeholder={placeholder}
        value={{
          addressLine1: '',
          addressLine2: '',
          addressCity: '',
          addressState: '',
          addressZIP: '',
          addressCountry: '',
        }}
        onChange={addressParts => onChange(addressParts)}
        setFieldValue={setFieldValue}
        readOnly={readOnly}
      />
    </>
  )
}

export default connect(AddressField)
