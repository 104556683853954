import React, { Fragment } from 'react'
import { Input } from 'reactstrap'
import { fieldify } from './utils'
import CharactersLeft from './CharactersLeft'

const TextareaField = fieldify(({
  field, form, invalid, maxLength, saveOnExit, shouldToggle, readOnly, ...rest
}) => {
  const onBlur = () => {
    saveOnExit && form.handleSubmit()
  }
  return (
    <Fragment>
      <Input
        className="TextareaField"
        type="textarea"
        invalid={invalid}
        maxLength={maxLength}
        {...field}
        {...rest}
        onBlur={onBlur}
        disabled={readOnly || rest.disabled}
      />
      <CharactersLeft length={field.value ? field.value.length : 0} maxLength={maxLength} />
    </Fragment>
  )
})

export default TextareaField
