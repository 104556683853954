import React from 'react'
import { find, get } from 'lodash'
import moment from 'moment'
import { RECIPIENT_PROPERTY, UPDATED_CELL } from './common'
import { TablePreviewImage } from '../../components/Table'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'
import { AdvertisementMetrics } from '../../components/metrics'
import { confirm } from '../../containers/modals/AlertMessageContainer'
import MediaDisplay from '../../components/MediaDisplay'
import { pluralize } from '../../Utils'

import { Padlock } from '../../TinySvgs'
import { ListCellBadge } from '../../components/list'
import fallBackIcon from '../../images/icon-art-collection-default.png'
import RecipientProperties from '../../components/drawer/RecipentProperties'
import { MESSAGES } from '../strings'

const isCustom = bundle => bundle.organizationId !== null

export const SLIDESHOW_IMAGE = {
  id: 'image',
  disableSortBy: true,
  Cell: ({ cell: { value }, row: { original } }) => {
    const imageObj = get(original, 'images[0]')

    return (
      <TablePreviewImage
        src={imageObj?.image?.standard ? imageObj.image.standard : fallBackIcon}
        contain={!imageObj?.image?.standard}
        height={137}
        width={77}
      />
    )
  },
  options: {
    classNames: 'shrink padding pr-0',
  },
}

export const COLLECTION_NAME = {
  Header: MESSAGES.COLLECTION_NAME,
  id: 'name',
  accessor: 'name',
  //  sortType: (a, b) => {
  //    const nameA = get(a, 'original.name', '')
  //    const nameB = get(b, 'original.name', '')
  //    return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
  //  },
  Cell: ({ row: { original } }) => {
    return (
      <>
        <div className="slideshow-title-head">
          <span className="slideshow-list-default">{original?.default && <ListCellBadge label="Default" />}</span>
          {/* }  <Link
                            to="/slideshow"
                            onClick={openModal}
                            onClick={() => this.setEditingView(bundle.id)}
                            data-cy="artwork-collection-name"
                          >
                            {bundle.name}
                            </Link> */}
          <div className="link-text"> {original?.name} </div>

          {!isCustom(original) && <span style={{ color: 'gray', marginLeft: 7 }}>({Padlock} stock)</span>}
        </div>
        <div style={{ color: 'black', cursor: 'default' }} data-cy="artwork-collection-count">
          {original?.images.length} {pluralize(original?.images.length, 'image')}
        </div>
      </>
    )
  },
}

const PROPERTIES_APPLIED = {
  Header: MESSAGES.PROPERTIES,
  accessor: ({ propertyIds }) => (propertyIds ? propertyIds.length : 0),
  Cell: ({ row: { original }, cell: { value }, initialState: { properties, populateDrawer } }) => {
    return original.propertyIds.length === 0 ? (
      <div
        className="link-text"
        onClick={event => {
          populateDrawer(original, event)
          event.stopPropagation()
        }}>
        {`${original.propertyIds ? original.propertyIds.length : 0} of ${properties.length} ${pluralize(
          properties.length,
          'property'
        )}`}
      </div>
    ) : (
      <div>{`${original.propertyIds.length} of ${properties.length} ${pluralize(properties.length, 'property')}`}</div>
    )
  },
  options: {
    hideOn: ['mobile'],
  },
}

const SLIDESHOW_OPTIONS = {
  accessor: 'Options',
  disableSortBy: true,
  Cell: ({ row: { original }, initialState: { onSetDefault, onDuplicate, rowAction, onConfirmDelete } }) => {
    return isCustom(original) ? (
      <PopoverMenu>
        <PopoverMenuItem onClick={() => onDuplicate(original)}>Duplicate</PopoverMenuItem>
        {!original.default && <PopoverMenuItem onClick={() => rowAction(original)}>Edit</PopoverMenuItem>}
        {!original.default && <PopoverMenuItem onClick={() => onConfirmDelete(original)}>Delete</PopoverMenuItem>}
        {!original.default && <PopoverMenuItem onClick={() => onSetDefault(original)}>Set As Default</PopoverMenuItem>}
      </PopoverMenu>
    ) : null
  },
}

export const SLIDESHOW_COLUMNS = [
  {
    id: 'slideshows',
    columns: [
      { ...SLIDESHOW_IMAGE },
      { ...COLLECTION_NAME },
      // PROPERTIES_APPLIED,
      {
        Header: MESSAGES.PROPERTIES,
        accessor: ({ propertyIds }) => (propertyIds ? propertyIds.length : 0),
        Cell: ({ row: { original }, initialState: { properties, openRecipientProperties } }) => {
          return (
            <RecipientProperties
              openRecipientProperties={e => openRecipientProperties(original, e)}
              properties={properties}
              propertiesLength={original.propertyIds?.length || (original.propertyId ? 1 : 0)}
              original={original}
              totalPropertiesCount={properties.length}
            />
          )
        },
        options: {
          hideOn: ['mobile'],
        },
      },
      { ...UPDATED_CELL },
      SLIDESHOW_OPTIONS,
    ],
  },
]
