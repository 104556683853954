import React from 'react'
import '../styles/PropertyGridContainer.scss'
import moment from 'moment'
import { PropertyItem } from '../components/Property'
import { MESSAGES } from '../constants/strings'

const PropertyGridContainer = ({ results, archive, onArchive }) => {
  return (
    <>
      {archive && (
        <p className="pb-2">
          <b>{`${MESSAGES.IMPORTANT_NOTE}:`}</b>
          {MESSAGES.ARCHIVED_PROPERTY_CANNOT_BE_UPDATED}
        </p>
      )}
      <div className="PropertyGrid">
        {results
          .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt))
          .map(prop => (
            <PropertyItem archive={archive} onArchive={onArchive} key={prop.id} {...prop} />
          ))}
      </div>
    </>
  )
}

export default PropertyGridContainer
