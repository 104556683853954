/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Pill from '../Pill'
import { openModalWithContainer } from '../../reducers/modals'
import { SUB_SECTIONS, ACTIONS } from '../../constants/thirdPartyAnalytics'
import { MESSAGES } from '../../constants/strings'

function ManagePasscode({
  me, dispatch, onSaveMe, recordAnalytics,
}) {
  return (
    <div className="settings-section">
      <h2>{MESSAGES.MANAGE_PASSCODE}</h2>
      <div className="">
        <div className="passcode">
          <div className="label">
            {MESSAGES.CURRENT_PASSCODE}
            {' '}
          </div>
          <div className="value">
            <Pill invert text={me.organization.passcode} />
            {' '}
          </div>
          <div className="action-container">
            <a
              className="action"
              onClick={() => {
                dispatch(
                  openModalWithContainer('PasscodeContainer', {
                    organization: me.organization,
                    onSave: organization => {
                      recordAnalytics({
                        subSection: SUB_SECTIONS.MANAGE_PASSCODE,
                        action: ACTIONS.UPDATE,
                      })
                      return onSaveMe({ organization })
                    },
                  }),
                )
              }}
            >
              {MESSAGES.EDIT}
            </a>
            {/* todo: determine if we need to add a share passcode modal or let users request from gvg */}
            {/* <a className="action">Share passcode</a>  */}
          </div>
        </div>
      </div>
    </div>
  )
}

ManagePasscode.defaultProps = {
  me: {},
  dispatch: () => { },
  onSaveMe: () => { },
  recordAnalytics: () => { },
}

export default ManagePasscode
