import React from 'react'
import { Link } from 'react-router-dom'
import { MESSAGES } from '../../../constants/strings'

const BackButton = ({ to }) => {
  return (
    <Link to={to} className="btn btn-outline-primary back">
      {MESSAGES.BACK}
    </Link>
  )
}

export default BackButton
