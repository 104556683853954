import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { MESSAGES } from '../../constants/strings'
import ServiceButtons from '../../components/services/ServiceButtons'
import { formatPrice } from '../../Utils'
import { FEE_OPTIONS } from '../../constants/services'

const BundleServiceResponseContainer = ({
  service,
  id,
  onUpdateItem,
  hasPaymentProvider,
  parameters,
  request = false,
}) => {
  let total = 0
  let quantity = 0

  const getChoiceFee = choice => {
    let sum = 0
    let totalSum = 0
    for (const rate of choice.feeRates) {
      if (rate.feeType !== 'custom') {
        sum += rate.price
        totalSum += choice.quantity * rate.price
      }
    }
    total += totalSum
    quantity += choice.quantity
    return sum
  }

  const addCustomQuantity = qty => {
    quantity += qty
    return qty
  }

  const getPrice = amount => {
    const { price, priceType } = formatPrice(service, amount)
    return `${price} ${priceType}`
  }

  const getFeeType = choice => {
    return FEE_OPTIONS[choice.feeRates[0].feeType]
  }

  const renderChoices = () => {
    return (
      parameters.selectedChoices
      && parameters.selectedChoices.length > 0
      && parameters.selectedChoices.map(choice => {
        return (
          <div className="bundle-choice-list">
            <h6>{choice.title}</h6>
            {choice.feeRates[0].feeType !== 'custom' && (
              <span>{`${getFeeType(choice)}: ${getPrice(getChoiceFee(choice))} (${choice.quantity})`}</span>
            )}
            {choice.feeRates[0].feeType === 'custom' && (
              <span>
                {`${getFeeType(choice)}: ${choice.feeRates[0].customFeeMessage} (${addCustomQuantity(
                  choice.quantity,
                )})`}
              </span>
            )}
          </div>
        )
      })
    )
  }

  return (
    <>
      <div className="modal-title-has-border">
        <ModalHeader>
          <b className="weight-500">
            {request && `${MESSAGES.REQUEST_FOR} `}
            {service.name}
          </b>
        </ModalHeader>
      </div>
      <ModalBody className="bundle-service-response-modal p-0 no-bg mb-0">
        <div className="has-thin-scroll scroll-grey bundle-choice-list-container">{renderChoices()}</div>
        {!request && (
          <>
            <div className="bundle-service-response-total">
              <h4>
                {MESSAGES.TOTAL}
                {' '}
              </h4>
              <div className="popup-bundle-qty">
                <h4>{`${MESSAGES.QUANTITY}: ${quantity} `}</h4>
              </div>
              <span>{`${MESSAGES.PRICE}: ${getPrice(total)} `}</span>
            </div>
          </>
        )}
      </ModalBody>
      <div className="bundle-service-response-modal-footer">
        <ModalFooter>
          {request && (
            <>
              <div className="bundle-service-response-total total-modal-footer">
                <h4>
                  {MESSAGES.TOTAL}
                  {' '}
                </h4>
                <div className="popup-bundle-qty">
                  <h4>{`${MESSAGES.QUANTITY}: ${quantity} `}</h4>
                </div>
                <h4>
                  {`${MESSAGES.PRICE}: ${getPrice(total)} `}
                  {' '}
                </h4>
              </div>
            </>
          )}
          {!request && <ServiceButtons id={id} onUpdateItem={onUpdateItem} hasPaymentProvider={hasPaymentProvider} />}
        </ModalFooter>
      </div>
    </>
  )
}

export default BundleServiceResponseContainer
