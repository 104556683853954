import React from 'react'
import SkeletonComponent from 'react-loading-skeleton'

export default function Skeleton({
  count = 1,
  width = '100%',
  height = 8,
  color = '#525352',
  animation = false,
  ...props
}) {
  return (
    <SkeletonComponent
      height={height}
      count={count}
      baseColor={color}
      enableAnimation={animation}
      width={width}
      {...props}
    />
  )
}
