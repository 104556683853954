import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const confirm = ({
 title, body, onConfirm, onlyConfirm = false,
}) => {
  return confirmAlert({
    title: title || 'Are you sure?',
    message: body,
    buttons: onlyConfirm
      ? [
          {
            label: 'OK',
            className: 'btn btn-primary',
          },
        ]
      : [
          {
            label: 'Cancel',
            className: 'btn btn-secondary',
          },
          {
            label: 'Confirm',
            className: 'btn btn-primary',
            onClick: () => onConfirm(),
          },
        ],
    closeOnClickOutside: false,
  })
}
