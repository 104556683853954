import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { formatters } from '../../Utils'
import { PropertyIcon } from '../../icons'
import PropertyGridContainer from '../../containers/PropertyGridContainer'
import PropertyListContainer from '../../containers/PropertyListContainer'
import { EmptyNavItemPlaceholder } from '../PageStates'

import { LISTING_EMPTY_TEXT, PROPERTY_VIEWS_TYPES } from '../../constants/forms/propertyInfo'

const Properties = ({
 results, userSearch, archive, activePropertyView, onArchive,
}) => {
  const search = results => {
    const filteredResults = results.filter(result => {
      const address = formatters.address(result)
      const nickname = get(result, 'nickname', '') || ''
      const hostName = get(result, 'host.name', '') || ''
      const searableItems = [...address, nickname, hostName].join(' ').toLowerCase()
      return searableItems.includes(userSearch.toLowerCase())
    })

    return filteredResults
  }

  const noResultText = archive ? LISTING_EMPTY_TEXT.archived : LISTING_EMPTY_TEXT.current

  const [filteredResults, setFilteredResults] = useState(search(results, userSearch))

  useEffect(() => {
    setFilteredResults(search(results, userSearch))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearch, results])

  if (filteredResults.length) {
    return activePropertyView === PROPERTY_VIEWS_TYPES.gridView ? (
      <PropertyGridContainer archive={archive} onArchive={onArchive} results={filteredResults} />
    ) : (
      <PropertyListContainer archive={archive} onArchive={onArchive} results={filteredResults} />
    )
  }
  return (
    <EmptyNavItemPlaceholder
      icon={<PropertyIcon width="72" height="72" />}
      text={!results.length ? noResultText : LISTING_EMPTY_TEXT.search}
    />
  )
}

export default Properties
