import React from 'react'
import Icon from './Icon'

function ActivitiesIcon(props) {
  return (
    <Icon viewBox="0 0 24 16" {...props} width="1em" height="1em">
      <g clipPath="url(#activites_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0H0v4.588a3.176 3.176 0 110 6.353v4.588h24v-4.588a3.177 3.177 0 010-6.353V0zm-6.353 2.118H6.353v1.058h11.294V2.118zM6.353 13.412v-1.06h11.294v1.06H6.353zm6.397-6.68L12 4.942l-.75 1.79-1.935.16 1.471 1.267-.446 1.89L12 9.041l1.66 1.008-.446-1.89 1.471-1.267-1.935-.16z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="activites_svg__clip0">
          <path fill="#fff" d="M0 0h24v15.529H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default ActivitiesIcon
