import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ListRow, ListCell } from '../list'
import { PropertyAddress } from '../Property'
import { MESSAGES } from '../../constants/strings'

import fallbackAvatar from '../../images/home-photo-placeholder.jpg'
import { formatters } from '../../Utils'

const PropertyListItem = property => {
  const { photoURL, isImported, isSelected, onClick } = property
  const [defaultImage, setDefaultImage] = useState(false)

  const addressParts = formatters.address(property)
  const [, line2] = addressParts

  useEffect(() => {
    if (property && property.photoURL == '') {
      setDefaultImage(true)
    }
  }, [property])

  const addDefaultSrc = ev => {
    ev.target.src = fallbackAvatar
    ev.target.style.objectFit = 'contain'
    setDefaultImage(true)
  }

  const propertyHasAddress = line2?.trim()

  return (
    <ListRow
      className={classNames('property-list-item', { imported: isImported, 'cursor-none': !propertyHasAddress })}
      onClick={!isImported && propertyHasAddress ? onClick : () => {}}>
      <ListCell className="center" style={{ width: '47px' }}>
        {!isImported && (
          <input
            className="property-checkbox"
            type="checkbox"
            name="imported"
            value={isSelected}
            checked={isSelected}
            disabled={!propertyHasAddress}
            readOnly
          />
        )}
      </ListCell>
      <ListCell className="shrink" style={{ width: '108px' }}>
        <figure className="holder-100x70">
          <img src={photoURL || fallbackAvatar} onError={addDefaultSrc} className="property-image" alt="property" />
        </figure>
      </ListCell>
      <ListCell className="property-address">
        <PropertyAddress {...property} noAddress={!propertyHasAddress} />
      </ListCell>
      <ListCell className="center">
        <div className="property-imported">
          {isImported && <div className="badge secondary">{MESSAGES.IMPORTED}</div>}
        </div>
      </ListCell>
    </ListRow>
  )
}

export default PropertyListItem
