import React from 'react'
import Icon from './Icon'

function QuestionCircleIcon(props) {
  return (
    <Icon
      height="24"
      width="24"
      viewBox="0 0 32 32"
      className="onboarding-icon"
      fill="currentColor"
      fillRule="evenodd"
      {...props}
    >
      <path
        d="M15,21 C15.7796961,21 16.4204487,21.5897719 16.4931334,22.3546371 L16.5,22.5 C16.5,23.2796961 15.9102281,23.9204487 15.1453629,23.9931334 L15,24 C14.1715729,24 13.5,23.3342028 13.5,22.5 C13.5,21.7203039 14.0897719,21.0795513 14.8546371,21.0068666 L15,21 Z M15,6 C18.3137085,6 21,8.6862915 21,12 C21,14.7957591 19.0878408,17.1449096 16.5,17.8109738 L16.5,17.8109738 L16.5,18.0015652 C16.5,18.8291279 15.8342028,19.5 15,19.5 C14.1715729,19.5 13.5,18.8360095 13.5,18.0015652 L13.5,18.0015652 L13.5,16.4984348 C13.5,15.6708721 14.1657972,15 15,15 C16.6568542,15 18,13.6568542 18,12 C18,10.3431458 16.6568542,9 15,9 C13.3431458,9 12,10.3431458 12,12 C12,12.8284271 11.3284271,13.5 10.5,13.5 C9.67157288,13.5 9,12.8284271 9,12 C9,8.6862915 11.6862915,6 15,6 Z M15,27 C21.627417,27 27,21.627417 27,15 C27,8.372583 21.627417,3 15,3 C8.372583,3 3,8.372583 3,15 C3,21.627417 8.372583,27 15,27 Z M15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 Z"
        id="path-1"
      />
      <path d="M0 0h24v24H0z" fill="none" />
      {' '}
    </Icon>
  )
}

export default QuestionCircleIcon
