import React from 'react'
import OnboardingBreadcrumbs from './OnboardingBreadcrumbs'
import BackButton from './buttons/BackButton'
import SkipButton from './buttons/SkipButton'
import SaveContinueButton from './buttons/SaveContinueButton'
import { DefaultContentForm } from '../content'
import { CHECK_IN_OUT, CHECKOUT_DETAILS } from '../../constants/forms/content'
import { MESSAGES } from '../../constants/strings'

const CheckInCheckOutStep = () => {
  const checkoutDetailFields = CHECKOUT_DETAILS.fields({ saveOnExit: true })
  const checkoutDetailFieldsToRender = checkoutDetailFields && checkoutDetailFields.filter(({ name }) => name === 'checkoutMessage')

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="onboardingForm">
            <div id="header">
              <img src="/gvg-logo-white-updated.svg" alt="GuestView Guide" className="signup-hdr-logo" />
            </div>
            <div className="main-content">
              <OnboardingBreadcrumbs step={3} length={5} />
              <h2 className="h2">{MESSAGES.ADD_CHECK_IN_OUT_INFO}</h2>
              <p>{MESSAGES.CHECK_IN_OUT_MSG}</p>
              <div className="default-body">
                <DefaultContentForm content={CHECK_IN_OUT} />
                {checkoutDetailFieldsToRender.length && (
                  <DefaultContentForm content={CHECKOUT_DETAILS} fields={checkoutDetailFieldsToRender} />
                )}
              </div>
              <div id="footer">
                <BackButton to="/onboarding/2" />
                <div>
                  <SkipButton to="/onboarding/4" />
                  <SaveContinueButton to="/onboarding/4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CheckInCheckOutStep
