import React, { useMemo } from 'react'
import moment from 'moment'
import { MasterList } from '../components/list'
import { EmptyNavItemPlaceholder } from '../components/PageStates'
import TabHeader from '../components/TabHeader'
import Resource from '../Resource'
import { apiRequest } from '../reducers/data'
import { BackLink } from '../components'
import { DATE_FORMATS } from '../constants/forms/propertyInfo'
import { confirm } from './modals/AlertMessageContainer'
import { displayErrorToast } from '../utils/displayToast'

const COLUMNS = [
  {
    id: 'organizations',
    columns: [
      {
        Header: 'Customer',
        accessor: 'organization.name',
        Cell: ({ row: { original } }) => <div>{original.organization.name}</div>,
      },
      {
        Header: 'GIS Revenue Share',
        disableSortBy: true,
        Cell: ({ row: { original }, initialState }) => (
          <div className="gis-share">
            <input
              max={100}
              min={0}
              defaultValue={original.gisShare || 0}
              type="number"
              id={`${original.organizationId}gisShare`}
            />
            <button
              key={original.organizationId}
              onClick={() => {
                handleSave(original, initialState.updateShare)
              }}
            >
              Save
            </button>
          </div>
        ),
      },
      {
        Header: 'Customer Revenue Share',
        Cell: ({ row: { original } }) => <div>{100 - original.gisShare}</div>,
      },
      {
        Header: 'Last Updated On',
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => <div>{moment(original.updatedAt).format(DATE_FORMATS.FULL_DATE)}</div>,
        options: {
          classNames: 'middle',
          hideOn: ['mobile'],
        },
      },
      {
        Header: 'Last Updated BY',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return <div>{`${original.user?.firstName} ${original.user?.lastName}`}</div>
        },
        options: {
          classNames: 'middle',
          hideOn: ['mobile'],
        },
      },
    ],
  },
]

const handleSave = (original, updateShare) => {
  const id = `${original.organizationId}gisShare`
  const gisShare = document.getElementById(id).value
  const decimalCheck = gisShare % 1
  if (gisShare) {
    if (decimalCheck > 0) {
      document.getElementById(id).value = original.gisShare
      // window.alert('Only whole numbers are allowed')
      displayErrorToast('Only whole numbers are allowed')
    } else if (gisShare < 0 || gisShare > 100) {
      document.getElementById(id).value = original.gisShare
      // window.alert('Please input value between 0-100')
      displayErrorToast('Please input value between 0-100')
    } else {
      confirm({
        title: 'Update GIS Share',
        body: `Update GIS Share value to ${gisShare}% for ${original.organization.name}?`,
        onConfirm: () => {
          updateShare(original.organizationId, gisShare)
        },
      })
    }
  } else {
    document.getElementById(id).value = original.gisShare
    displayErrorToast('Please input value between 0-100')
    // window.alert('Please input value between 0-100')
  }
}

function OrganizationsTable({
 organizations, onUpdateItem, onDeleteItem, onRefresh, dispatch,
}) {
  const updateShare = async (organizationId, share) => {
    if (share >= 0 && share <= 100) {
      const { response } = await dispatch(
        apiRequest([`features/revenue-share/${organizationId}`], 'PUT', {
          gisShare: parseInt(share, 10),
        }),
      )
      if (response.ok) {
        onRefresh()
      }
    }
  }
  const initialState = {
    onUpdateItem,
    onDeleteItem,
    onRefresh,
    updateShare,
  }

  const memoizedColumns = useMemo(() => COLUMNS, [])
  return (
    <div className="table-container">
      <MasterList
        columns={memoizedColumns}
        data={organizations}
        initialState={initialState}
        data-cy="organizations-table"
      />
    </div>
  )
}

function RevenueShareContainer() {
  return (
    <Resource collection path={['features', 'revenue-share']} placeholder={[]}>
      {(organizations, {
 onUpdateItem, onDeleteItem, dispatch, onRefresh,
}) => (
  <div className="RevenueShareContainer">
    <div className="back pb-3">
      <BackLink to="/admin" text="Back to Admin" />
    </div>
    <TabHeader title="Revenue Share" />
    {organizations.length === 0 ? (
      <EmptyNavItemPlaceholder dataPiece="organizations" />
          ) : (
            <OrganizationsTable
              organizations={organizations}
              onRefresh={onRefresh}
              onUpdateItem={onUpdateItem}
              onDeleteItem={onDeleteItem}
              dispatch={dispatch}
            />
          )}
  </div>
      )}
    </Resource>
  )
}

export default RevenueShareContainer
