import React from 'react'
import classNames from 'classnames'
import '../../styles/Choices.scss'

const TemplateChoices = ({ choices, setChoice, setKey }) => {
  return (
    <div className={classNames('choices-list-wrapper has-thin-scroll')}>
      <div className="template-list-container d-flex flex-wrap w-100">
        {choices.map((choice, index) => {
          const setWith = setKey ? choice[setKey] : index

          return (
            <>
              <div
                className={classNames('choices-listing', `${choice.isUnique ? 'is-unique' : ''}`)}
                onClick={() => setChoice(setWith)}
                key={choice.id}>
                <div className="figure">
                  <i className="necicon-star" />
                  <img src={choice.defaultImage} alt="template-default" />
                </div>
                <div className="name-description">
                  <h5>{choice.templateName}</h5>
                  <p>{choice.templateDescription}</p>
                </div>
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default TemplateChoices
