import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
import moment from 'moment'

import Resource from '../Resource'
import { openModalWithContainer } from '../reducers/modals'
import { Loading } from '../components/PageStates'
import TabHeader from '../components/TabHeader'
import { MessageContainerHeader, MessagesTable, SentMessagesTable } from '../components/messages'
import '../styles/Badge.scss'
import { PROPERTY_TYPES } from '../constants/forms/propertyInfo'
import { thirdPartyAnalytics } from '../services'
import { ACTIONS, EVENTS } from '../constants/thirdPartyAnalytics'
import { Tabs } from '../components'
import { getAnnouncementSchedule } from '../utils/messages'
import { apiRequest } from '../reducers/data'
import { Maintenance } from '../components'

const MESSAGES_TABS = [
  {
    slug: '',
    component: MessagesTable,
    label: 'Scheduled Announcements',
  },
  {
    slug: 'sent',
    component: SentMessagesTable,
    label: 'View Sent Announcements',
  },
]

const MessagesContainerWithData = props => {
  const {
    dispatch,
    status,
    messages,
    properties,
    onUpdateItem,
    title,
    onCreateItem,
    recordAnalytics,
    onDeleteItem,
    NestedResource,
  } = props
  const [_messages, setMessages] = useState([])

  useEffect(() => {
    setMessages(messages)
  }, [messages])

  const getMessages = async path => {
    if (path === '/announcements/') {
      const response = await dispatch(apiRequest(['messages'], 'GET'))
      if (response && response.json) {
        setMessages(response.json)
      }
    }
  }

  const openModal = params => {
    setTimeout(() => {
      dispatch(
        openModalWithContainer(
          'MessageContainer',
          {
            properties,
            message: {},
            recordAnalytics,
            ...params,
          },
          {
            size: 'xl',
            customClass: 'add-custom-service-modal',
          }
        )
      )
    }, 100)
  }

  const onAction = (action, func, extra) => {
    if (action !== ACTIONS.DELETE) {
      const updatedAt = moment.utc().format()
      const data = { action, updatedAt }
      if (action === ACTIONS.CREATE) {
        data.createdAt = updatedAt
      }

      if (extra) {
        if (action === ACTIONS.DELETE) {
          extra = _messages.find(m => m.id === extra.id)
          data.deletedAt = updatedAt
        }
        if (extra.category) {
          data.category = extra.category
        }
        data.schedule = getAnnouncementSchedule({ announcement: extra })
      }

      recordAnalytics && recordAnalytics(data)
    }
    return func(extra)
  }

  return (
    <div>
      <TabHeader title={title} count={_messages.length} className="with-notes" />
      <MessageContainerHeader
        data={_messages}
        openModal={openModal}
        status={status}
        onCreateItem={onAction.bind(this, ACTIONS.CREATE, onCreateItem)}
      />
      <Tabs
        tabs={MESSAGES_TABS}
        path="announcements"
        messages={_messages}
        properties={properties}
        openModal={openModal}
        status={status}
        onUpdateItem={onAction.bind(this, ACTIONS.UPDATE, onUpdateItem)}
        onCreateItem={onAction.bind(this, ACTIONS.CREATE, onCreateItem)}
        onDeleteItem={onAction.bind(this, ACTIONS.DELETE, onDeleteItem)}
        NestedResource={NestedResource}
        onNavClick={getMessages}
      />
    </div>
  )
}

const MAP_HUMAN_TO_DB_STATUS = {
  archived: {
    db: 'DELETED',
    title: 'Archived Messages',
  },
  active: {
    db: 'ACTIVE',
    title: 'Announcements',
  },
}

const MessagesContainer = ({ match }) => {
  const [maintenanceStatus, setMaintenanceStatus] = useState(false)
  const { params } = match
  const status = MAP_HUMAN_TO_DB_STATUS[params.status] || MAP_HUMAN_TO_DB_STATUS.active
  const path = ['messages']
  const { title } = status

  const { me: userInfo } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  const recordAnalytics = properties => {
    let updatedProperties = { ...analyticsProperties }
    if (isObject(properties)) updatedProperties = { ...updatedProperties, ...properties }
    thirdPartyAnalytics.track(EVENTS.ANNOUNCEMENT, updatedProperties)
  }

  useEffect(() => {
    setMaintenanceStatus(JSON.parse(localStorage.getItem('maintenance')))
  }, [])

  return maintenanceStatus ? (
    <Maintenance />
  ) : (
    <Resource collection path={path} apiPath={path} placeholderComponent={Loading}>
      {(messages, { onUpdateItem, onCreateItem, onDeleteItem, dispatch, NestedResource }) => (
        <Resource collection path={['properties', `?type=${PROPERTY_TYPES.current}`]} placeholder={[]}>
          {properties => (
            <MessagesContainerWithData
              title={title}
              actions={status.actions}
              messages={messages}
              status={status.db}
              recordAnalytics={recordAnalytics}
              properties={properties}
              dispatch={dispatch}
              onCreateItem={onCreateItem}
              onUpdateItem={onUpdateItem}
              onDeleteItem={onDeleteItem}
              NestedResource={NestedResource}
            />
          )}
        </Resource>
      )}
    </Resource>
  )
}

export default MessagesContainer
