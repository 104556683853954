import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MESSAGES } from '../../constants/strings'
import { displayErrorToast } from '../../utils/displayToast'

const HEADER_STYLES = {
  textAlign: 'center',
  marginBottom: 20,
}

function OrganizationSwitcherContainer({
  organizations, me, onSelect, onCreate,
}) {
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(-1)
  const [addOrganization, setAddOrganization] = useState(false)
  const [newOrgName, setNewOrgName] = useState(null)

  useEffect(() => {
    const filtered = filteredOrganizations()
    if (selected >= filtered.length) {
      setSelected(filtered.length - 1)
    }
    if (me && me.error) {
      notify(me.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  const onKeyDown = e => {
    if (e.key === 'ArrowUp') {
      setSelected(Math.max(0, selected - 1))
      e.preventDefault()
    }
    if (e.key === 'ArrowDown') {
      setSelected(Math.min(filteredOrganizations().length - 1, selected + 1))
      e.preventDefault()
    }
    if (e.key === 'Enter') {
      const org = filteredOrganizations()[selected]
      org && onSelect(org)
      e.preventDefault()
    }
  }

  const notify = msg => {
    displayErrorToast(msg)
  }

  const onAddOrganization = () => {
    const name = newOrgName

    let error = null
    if (organizations.find(o => o.name === name)) {
      error = 'This name is in use, please select another name.'
    }
    if (name.length === 0) {
      error = 'Please enter an organization name.'
    }

    if (error) {
      notify(error)
      return
    }
    onCreate({ name })
  }

  const filteredOrganizations = () => {
    return organizations.filter(o => o.name.toLowerCase().includes(search.toLowerCase()))
  }

  if (addOrganization) {
    return (
      <div className="create-new-org">
        <div className="Card">
          <h2 style={HEADER_STYLES}>{MESSAGES.CREATE_ORGANIZATION}</h2>
          <label>{MESSAGES.CREATE_ORGANIZATION_DESC}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => setNewOrgName(e.target.value)}
            placeholder="ABC Property Management Company"
            required
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <button
            style={{ marginTop: 20 }}
            className="btn btn-outline-primary font-weight-bold"
            onClick={onAddOrganization}
          >
            {MESSAGES.CREATE_ORGANIZATION}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="org-selection">
      <div className="Card">
        <div className="card-content">
          <div className="org-card-header">
            <h2 style={HEADER_STYLES}>{MESSAGES.SELECT_ORGANIZATION}</h2>
            <input
              type="text"
              id="filter"
              className="form-control"
              placeholder="Search..."
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={search}
              onKeyDown={onKeyDown}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
          <div className="org-card-body">
            {filteredOrganizations().map((org) => (
              <div key={org.id}>
                <div className="org-row" onClick={() => onSelect(org)}>
                  {org.name}
                </div>
              </div>
            ))}
          </div>
          <div className="org-card-footer">
            <div
              style={{
                fontStyle: 'italic',
                textAlign: 'center',
                paddingBottom: 20,
              }}
            >
              — or —
            </div>
            <button
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => setAddOrganization(true)}
            >
              <FaPlus style={{ marginRight: 7 }} />
              {`${MESSAGES.ADD_ORGANIZATION}...`}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganizationSwitcherContainer

OrganizationSwitcherContainer.defaultProps = {
  organizations: [],
  me: {},
  onSelect: () => { },
  onCreate: () => { },
}
