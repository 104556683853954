import React from 'react'
import SelectField from './SelectField'
import { formatDuration } from '../../Utils'

const checkoutIncrements = () => {
  const increment = 30
  const max = 360
  const total = []

  for (let i = 1; increment * i <= max; i++) {
    total.push(increment * i)
  }
  return total
}

const timeOptions = checkoutIncrements().map(minute => {
  return formatDuration(minute)
})

const TimeSelector = props => {
  return <SelectField {...props} format={value => parseInt(value, 10)} options={timeOptions} />
}

export default TimeSelector
