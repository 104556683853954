/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from 'react'
import { get } from 'lodash'
import {
 useTable, useSortBy, useGlobalFilter, useFilters, useRowSelect,
} from 'react-table'
import classNames from 'classnames'
import {
 List, ListHead, ListHeadCell, ListBody, ListRow, ListCell, ListActions, ListSearchAndFilter,
} from '.'
import { ACTIONS } from '../../constants/thirdPartyAnalytics'

const defaultPropGetter = () => ({})
let recordAnalyticsTimer = null
const RECORD_ANALYTICS_TIME = 1000
function MasterList({
  setSelectedItems,
  columns,
  data,
  rowAction,
  initialState,
  emptyState,
  getRowProps = defaultPropGetter,
  actions: ListActionsComponent,
  stateReducer,
  search,
  searchTheme,
  recordAnalytics,
  externalSearch,
  ...props
}) {
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState,
      filterTypes,
      stateReducer,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
  )

  const headerGroup = headerGroups[1]

  const EmptyIcon = get(emptyState, 'icon')
  const emptyText = get(emptyState, 'text')
  const emptyClassname = get(emptyState, 'className')
  const showEmptyTable = EmptyIcon || emptyText

  const setGlobalFilterHandler = extra => {
    // clearTimeout(recordAnalyticsTimer)
    // if (extra && recordAnalytics) {
    //   recordAnalyticsTimer = setTimeout(() => {
    //     recordAnalytics(ACTIONS.SEARCH)
    //   }, RECORD_ANALYTICS_TIME)
    // }
    return setGlobalFilter(extra)
  }

  useEffect(() => {
    // https://github.com/tannerlinsley/react-table/issues/2210 = Issue to keep selected on change
    // const selectedIds = selectedFlatRows.map(d => d.original.id)
    // selectedFlatRows.map(d => d.original.id)
    const selectedRowIds = Object.keys(state.selectedRowIds)
    const selectedIds = selectedRowIds.map(selectedRowId => data[selectedRowId].id)

    setSelectedItems && setSelectedItems(selectedIds)

    if (externalSearch) {
      externalSearch.current = setGlobalFilterHandler
    }
  }, [selectedFlatRows])

  return (
    <>
      {(ListActionsComponent || search) && (
        <ListActions>
          <>
            <div className="wrapper-left">{ListActionsComponent}</div>
            {search && (
              <div className="wrapper-right">
                <ListSearchAndFilter
                  data={data}
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilterHandler}
                  headerGroup={headerGroup}
                  theme={searchTheme}
                />
              </div>
            )}
          </>
        </ListActions>
      )}
      {rows.length > 0 && (
        <List {...props}>
          <ListHead>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <ListHeadCell key={column.id} column={column} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </ListHeadCell>
              ))}
            </tr>
          </ListHead>
          <ListBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              const onClick = rowAction ? () => rowAction(row.original) : null
              return (
                <ListRow
                  className={classNames({ 'has-row-action': onClick })}
                  {...row.getRowProps(getRowProps(row))}
                  onClick={onClick}
                  key={row.id}
                >
                  {row.cells.map((cell, ind) => {
                    const cellClassNames = get(cell, 'column.options.classNames')
                    const cellHideOn = get(cell, 'column.options.hideOn')
                    const callHideClassNames = cellHideOn && cellHideOn.map(device => `hide-${device}`)
                    const style = {
                      minWidth: cell.column.minWidth ? `${cell.column.minWidth}px` : null,
                    }
                    return (
                      <ListCell
                        className={[cellClassNames, callHideClassNames]}
                        style={style}
                        {...cell.getCellProps()}
                        key={ind}
                      >
                        {cell.render('Cell')}
                      </ListCell>
                    )
                  })}
                </ListRow>
              )
            })}
          </ListBody>
        </List>
      )}
      {!rows.length && emptyText && EmptyIcon && (
        <div className={classNames('empty-list', emptyClassname)}>
          {EmptyIcon && <EmptyIcon size={40} />}
          {emptyText && <div>{emptyText}</div>}
        </div>
      )}
    </>
  )
}

export default MasterList

MasterList.defaultProps = {
  search: true,
  searchTheme: 'light',
}
