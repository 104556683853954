export const MESSAGES = {
  ARCHIVE_PAIRING_CONFIRMATION:
    'This property is paired with a display. Are you sure you want to archive this property?',
  ARCHIVE_CONFIRMATION:
    'Archiving will remove this property from your dashboard and will stop collecting analytics data. Historical data for this property will remain same.',
  RELIST_CONFIRMATION: 'Are you sure you want to relist this property?',
  ARCHIVE: 'Archive',
  RELIST: 'Relist',
  ICAL_INFO_MESSAGE:
    'You can add up to 10 iCal links. In the event of a conflict between systems the order of the iCal links will determine the priority. Links that aggregate multiple sources such as AirBnB should be last on the list to prevent conflicts.',
  ICAL_CHANGE_NOTIFICATION:
    "One or more changes have been made. You must click on the 'Save & Sync' button to save the changes.",
  REMOVE_ICAL_SYNC: 'Remove iCal',
  ICAL_ALREADY_EXISTS: 'iCal already exists',
  MANAGE_ICAL_LINKS: 'Manage iCal Links',
  ADD_ICAL_LINK: 'Add iCal Link',
  REMOVE_SYNC: 'Remove Sync',
  DELETE_ICAL_LINK: 'Delete iCal Link',
  SETUP_ICAL_LINK: 'Setup iCal Link',
  REMOVE_ICAL_SYNC_MESSAGE:
    'This will remove all the iCal links and will stop the Sync process. Any existing iCal reservations will be converted into manual reservations that you may edit.',
  REMOVE_ICAL_LINK_MESSAGE: 'Are you sure you want to delete this iCal link?',
  EXTERNAL_SERVICE_ICAL: 'ical',
  SYNC_CONFIRMATION_TITLE: 'Sync Confirmation',
  ICAL_SYNC_CONFIRMATION_MESSAGE:
    'Your future manual reservations will be deleted on syncing. In progress reservations remain unchanged. Would you like to sync?',
  NOTIFICATION_CONTACT:
    'Please enter all contact information for those who wish to receive a check in and check out notification. If enabled, the notification will also include the check in survey results for first impression feedback on the property. Notifications to phone numbers will require a number that can receive text messages.',
  ZOURA_PRODUCTS_RATE_PLANS: 'Zuora Products Rate Plans',
  SYNC_NOW: 'Sync Now',
  ADVERTISEMENT_NOTE_1:
    'Create slideshow ads for recommended business or services that have been added to the console. Custom Ads are not tied to any service or recommendation.',
  ADVERTISEMENT_NOTE_2:
    'The advertisements will automatically be displayed to all the assigned properties for each recommendation or service.',
  NO_OF_ADS: 'No. of ads in a row',
  NO_OF_SLIDESHOWS: 'No. of slideshows between ads',
  AD_SINGULAR_STRING: 'ad',
  AD_PLURAL_STRING: 'ads',
  SLIDESHOW_SINGULAR_STRING: 'slideshow',
  SLIDESHOW_PLURAL_STRING: 'slideshows',
  BETWEEN: 'between',
  PORTRAIT: 'portrait',
  SHOW_YELP_RESTAURANTS: 'Automatically show Yelp recommended restaurants',
  CALL_GUEST: 'Call Guest',
  RESEND_PAYMENT_LINK: 'Resend Payment Link',
  BUNDLE_PACKAGE: 'Bundle / Package',
  CUSTOM: 'Custom',
  PENDING_RESPONSE: 'Pending Response',
  TOTAL: 'Total',
  REQUEST_FOR: 'Request for',
  MANUAL_PAYMENT: 'Manual Payment',
  QUANTITY: 'Quantity',
  SERVICE_NAME: 'Service Name',
  GUEST_NAME: 'Guest Name',
  PROPERTY_ADDRESS: 'Property Address',
  PRICE: 'Price',
  CHECK_IN_OUT: 'Check In - Check Out',
  RECEIVED: 'Received',
  ADDITIONAL_TIME: 'Additional time',
  PRICE_FOR: 'Price for',
  PAYMENT_LINK_SENT: 'Payment link sent.',
  IMPORTANT_NOTE: 'IMPORTANT NOTE',
  NO_PROPERTY_FOUND: 'No property found',
  ARCHIVED_PROPERTY_CANNOT_BE_UPDATED: 'You cannot edit or change information of archived properties.',
  ARE_YOU_SURE_TO_LEAVE: 'Are you sure you want to leave? The property information will not be saved.',
  ADD_PHOTO: 'Add a photo',
  PASSCODE: 'PASSCODE',
  PROPERTY_ID: 'PROPERTY ID',
  DISPLAY_PREVIEW: 'Display Preview',
  USED_BY: 'Used By',
  OPEN_THIS_COMPONENT: 'Open This Component',
  CREATE_NEW_COMPONENT: 'Create New Component',
  CONFIRM_TO_DELETE_RESERVATION: 'Are you sure you want to delete this reservation?',
  EDIT: 'Edit',
  REMOVE: 'Remove',
  PROPERTY_DOESNOT_HAVE_RESERVATIONS: 'This property does not have any reservations',
  MANAGEMENT_SERVICE: 'Management Service',
  ICAL: 'iCal Calendar (.ics)',
  APPROVE: 'Approve',
  DECLINE: 'Decline',
  SERVICE_CONFIRMATION_MESSAGE_FIRST_PART: 'Are you sure you want to ',
  SERVICE_CONFIRMATION_MESSAGE_SECOND_PART: ' this request',
  SERVICE_CONFIRMATION_MESSAGE_THIRD_PART: ' and capture funds to your Stripe account',
  SERVICE_CONFIRMATION_MESSAGE_FORTH_PART: " and cancel guest's payment authorization",
  SERVICE_CONFIRMATION_MESSAGE_FIFTH_PART:
    'This service does not automatically process payment. Please ensure that payment has been made before approving this service.',
  STRIPE_PAYMENT_ON: 'Stripe Payment: ON',
  REVENUE_SHARE_PLAN: 'revenue share plan with GuestView Guide',
  WANT_TO_ACCEPT_PAYMENTS: 'Want to accept payments?',
  CONNECT_WITH_STRIPE: 'Connect with Stripe',
  NO_CHARGE: 'No Charge',
  GUEST: 'Guest',
  PAYMENT_AUTHORIZATION_CANCELED: 'Payment authorization canceled',
  PAYMENT_AUTHORIZED: 'Payment authorized',
  NO_SERVICE_REQUEST: 'No service requests received yet.',
  SEARCH_PLACEHOLDER: 'Search...',
  CLICK_ON: 'Click on',
  ADD_SERVICE_BUTTON: 'Add Service button',
  TO_ADD_SERVICES: 'to add services',
  SERVICES: 'Services',
  SERVICE_REQUESTS: 'Service Requests',
  PAIRED_PROPERTY: 'Paired Property',
  GUEST_STAYS: 'Guest Stays',
  OF_RESERVATION_LABEL: 'of Reservations used the display',
  PAGE_VIEWS: 'Pageviews',
  TOP_SECTION_BY_PAGEVIEW: 'Top Sections by Pageviews',
  PAGE_VIEWS_LOWERCASE: 'pageviews',
  PROPERTIES_BY_USAGE: 'Properties by Usage',
  TOP_PAGES: 'Top Pages',
  TOP_SEND_TO_PHONE_MESSAGES: 'Top "Send to Phone" Messages',
  TOP_QR_SCANS: 'Top "QR Scans"',
  PAGE_NAME: 'Page Name',
  SECTION: 'Section',
  PROPERTIES: 'Properties',
  NUMBER_SMS_SENT: '# SMS Sent',
  CUSTOM_REQUEST_PRICING: 'Custom Request Pricing is not included in this total',
  WELCOME_VIDEOS: 'Welcome Videos',
  AVERAGE_COMPLETION_RATE: 'Average completion rate',
  NO_RESERVATION_FOR_DATE_RANGE: 'No reservations for selected date range.',
  EMAIL: 'Email',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  ROLE: 'Role',
  INTEGRATION: 'Integration',
  MY_INFO: 'My Info',
  MANAGE_PASSCODE: 'Manage Passcode',
  CURRENT_PASSCODE: 'Current passcode:',
  MANAGE_COMPANY_NAME: 'Manage Company Name',
  CURRENT_COMPANY_NAME: 'Current company name:',
  MANAGE_INTEGRATIONS: 'Manage Integrations',
  INTEGRATION_NAME: 'Integration Name',
  ID: 'ID',
  DISPLAYS: 'Displays',
  MANAGE_HOSTS: 'Manage Hosts',
  NAME: 'Name',
  PHONE: 'Phone',
  EXTRA: 'Ext.',
  REMOVE_HOST_CONFIRMATION: 'Are you sure you want to delete this host?',
  SET_AS_DEFAULT: 'Set As Default',
  IS_DEFAULT: 'Is Default',
  REPLACE_DEFAULT_CONTACT: 'Are you sure you want to replace the existing default with this contact?',
  REPLACE_DEFAULT_HOST: 'Are you sure you want to replace the existing default with this property host?',
  CHECK_IN_OUT_NOTIFICATION_CONTACT: 'Check In / Out Notification Contact',
  MANAGE_USERS: 'Users',
  MOBILE: 'Mobile',
  JOINED: 'Joined',
  RESEND: 'Resend',
  REVIEW_REMINDER_DURING_CHECKOUT: 'Review reminder during check out',
  REVIEW_REMINDER: 'Review reminder',
  SETUP_TEXT_MESSAGES: 'Setup a text message reminder to leave a review when a guest leaves positive feedback',
  REVIEW_REMINDER_APPEARANCE: "Review reminder will appear when the guest's feedback is",
  TEXT_MESSAGES_ON_GUEST_PHONE:
    'After a guest leaves a review based on the above setting, they will be asked to enter their cell phone number. Two days after guest checks out, they will receive the below text message. If possible, add a URL link redirecting your guest to your review site of choice.',
  MESSAGE_FIELD_REQD: 'Message field is required',
  YELP_RECOMMENDATIONS: 'Yelp Recommendations',
  ADD_SLIDESHOW_COMPONENT: 'New Slideshow',
  BROWSE_STOCK_COLLECTION: 'Browse Stock Collections',
  COLLECTION_NAME: 'Collection Name',
  STOCK: 'stock',
  NAME_FIELD_REQD: 'Name field is required.',
  SLIDESHOW_DEFAULT_NOTE:
    'The default component is assigned to any property that does not have a component assigned or a custom value set.',
  MIN_ONE_IMAGE_REQD: 'Minimum one image is required.',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  ADD_LINK: 'Add a link',
  PROMO_INSTRUCTIONS:
    'Add a button to the image so a guest can send a text message to their phone with custom text and link you provide below',
  PROMOTE_LINK_TO_TEXT: 'Link to text',
  WIFI_INFO: 'Wi-fi Info',
  LOGO: 'Logo',
  WELCOME_TEXT: 'Welcome Text',
  BACKGROUND: 'Background',
  SAVING: 'Saving...',
  SAVED: 'Saved!',
  RECOMMENDED: 'Recommended',
  EMPTY: 'EMPTY',
  ADMIN: 'Admin',
  SELECT_DEVICE_ERROR: 'Please select a device',
  DISPLAY_BLACKLISTED_DEVICE: 'Display All Blacklisted Devices',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  MANAGE_REVENUE_SHARE: 'Manage Revenue Share',
  RESYNC_PROPERTY_INFO: 'Re-Sync Property Information',
  PROPERTIES_SELECTED: 'properties selected',
  CLEAR_SELECTION: 'Clear selections',
  SELECT_ALL: 'Select all',
  ADD_PROPERTY_MANUALLY: 'Add Property Manually',
  IMPORTED: 'Imported',
  SELECT_PROPERTY_FROM_SYNC: 'Select Property for Reservation Sync',
  SYNC_RESERVATIONS: 'Sync Reservations',
  NOTE: 'NOTE',
  CANNOT_BE_CUSTOMIZED: 'This cannot be customized on a property-by-property basis',
  BRANDING_YOUR_EXPERIENCE: 'Branding your interactive experience',
  WELCOME_SCREEN: 'Welcome Screen',
  CUSTOMIZE_WELCOME_SCREEN_MESSAGE: 'Customize the Welcome screen with your brand, images, and welcome message',
  CUSTOMIZE_WELCOME_SCREEN: 'Customize Welcome Screen',
  REQUIRED_GUEST_RESERVATION: 'Required guest’s reservations',
  SETUP_TIPS: 'Setup Tips',
  USE_DISPLAY_AS_ADS: 'Use the display as Ads platform',
  INCREASE_AWARENESS_MSG:
    'Increase awareness of your services and local businesses through setting up the advertisements',
  LEARN_MORE: 'Learn More',
  GUEST_SERVICE_NUMBER: 'Guest Services Number',
  OK: 'OK',
  READ_BY: 'Read by',
  ANALYTICS_NOT_AVAILABLE: '(Analytics not available at this time.)',
  LAST_30_DAYS_STATS: 'Last 30 days stats',
  BACK: 'Back',
  SKIP: 'Skip',
  ADD_CHECK_IN_OUT_INFO: 'Add Check In and Check Out Information',
  CHECK_IN_OUT_MSG:
    'Please enter the check in and check out times for your properties as well as a friendly check out message to your guests that will apply to all of your properties. You can edit this message at any time in the content tab.',
  SETUP_DISPLAY: 'Setting Up Your GuestView Guide Displays',
  SETUP_DISPLAY_MSG_1:
    'When you install your GuestView Guide Display in your property, you will be prompted to enter a Property ID to pair the display to a specific property. You will then be prompted to enter the Display Passcode to confirm that you have permission to pair with that property.',
  SETUP_DISPLAY_MSG_2:
    'can be found on each Property page directly under the address at the top of the page. Each Property ID is a “C” followed by five numbers.',
  SETUP_DISPLAY_MSG_3: 'The Display Passcode is a six-digit number that allows administrative access to displays.',
  DISPLAY_PASSCODE: 'Display Passcode',
  TAP_TO_CHECKIN: 'Tap To Check In',
  GUEST_NAME_BRACKET: '(Guest Name)',
  PREVIEW: 'Preview',
  WIFI_INFO_BRACKET: '(wifi info)',
  NEXT: 'Next',
  NO_ITEM_ADDED_YET: 'You have not yet added ',
  NO_SERVICE_PURCHASED: 'No Services Purchased',
  DISPLAY_DURATION: 'Display duration',
  SELECT_PROPERTIES: 'Select Properties',
  VIEW_FILTERED_PROPERTIES: 'View filtered properties',
  VIEW_SELECTED_PROPERTIES: 'View selected properties',
  CREATE_ORGANIZATION: 'Create an Organization',
  CREATE_ORGANIZATION_DESC:
    'Type an organization name below. The organization will be created immediately and you can add properties, create hosts, etc. To give others access to the account, invite one or more admins.',
  SELECT_ORGANIZATION: 'Select an Organization',
  ADD_ORGANIZATION: 'Add an Organization',
  GENERATE_RANDOM_PASSCODE: 'Generate random passcode',
  CHANGE: 'Change',
  IMPORT_PROPERTIES_FROM: 'Import your selected properties from',
  SYNC_PROPERTIES_FROM_PMS: 'Sync property and reservation information from the following property management systems',
  CONTINUE: 'Continue',
  RETRIEVING: 'Retrieving',
  THIS_MAY_TAKE_TIME: 'Properties. This may take some time.',
  UNABLE_TO_FETCH_PROPERTIES: 'Unable to fetch properties due to internet connectivity. Please try again.',
  CANCEL: 'Cancel',
  SELECT_THIS_COLLECTION: 'Select This Collection',
  DONE: 'Done',
  PERSONALIZE_EXPERIENCE: 'Personalize the Experience',
  HOSTS: 'Hosts',
  EXAMPLE: 'Example',
  RECOMMENDATION_MESSAGES: 'Recommendation Messages',
  DISPLAY_RECOMMENDATION_BADGE: 'Display Recommended Badge',
  SAVE_AND_CONTINUE: 'Save & Continue',
  LOW_RESOLUTION_IMAGE: 'Low resolution image',
  WHAT_TYPE_OF_ISSUE: 'What type of issue would you like to report',
  DISABLE_IN_CHECKIN_FLOW: 'Disable in check in flow',
  INCLUDE_IN_CHECKIN_FLOW: 'Include in check in flow',
  DISABLE_IN_CHECKOUT_FLOW: 'Disable in check out flow',
  DUPLICATE_PROPERTY: 'Duplicate Property',
  DUPLICATE: 'Duplicate',
  FROM: 'From',
  TO: 'To',
  CHANGE_ADDRESS: 'Change Property Address',
  ORGANIZATION: 'Organization',
  PROPERTY: 'Property',
  ADDRESS: 'Address',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  LAT: 'Latitude',
  LONG: 'Longitude',
  ZIP: 'Zipcode',
  UNIT: 'Unit or Apt#',
  ORIGINAL: 'original',
  EMAIL_SETUP: 'Email Setup For Monthly PMS report',
  SAVE: 'Save',
  SAVE_EMAIL: 'Save Email',
  AD_ORIENTATION_TEXT: 'Image may get distorted with orientation changes',
  LAST_LOGGED_IN: 'Last logged In',
  PROPERTY_HOSTS: 'Property Hosts',
  DELETE: 'Delete',
  SLIDESHOW_CUSTOM : 'Add custom text and link'
}
