import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { TextField } from '../../forms/fields'
import { Yup, Form, SubmitButton } from '../../forms'
import { generateForm } from '../../forms/utils'

const CATEGORY_FIELDS = {
  fields: () => [
    {
      label: 'Sub-Category',
      name: 'newCategory',
      component: TextField,
      vertical: true,
      validation: Yup.string()
        .trim()
        .required('Sub-category can not be empty.'),
      props: {
        placeholder: 'New Sub-Category',
      },
    },
  ],
}

const CategoryForm = ({ closeModal, handleSubmit, formFields }) => {
  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader closeModal={closeModal} name="Add New Sub-Category" verb={false} />
      <ModalBody>{formFields}</ModalBody>
      <ModalFooter>
        <SubmitButton onClick={handleSubmit}>Add</SubmitButton>
      </ModalFooter>
    </form>
  )
}

const AddCategoryContainer = ({ onCreateCategory, closeModal, ...props }) => {
  const form = generateForm({ form: CATEGORY_FIELDS.fields(), props: {} })

  const onSubmit = values => {
    onCreateCategory(values.newCategory)
  }

  return (
    <Form
      enableReinitialize
      validationSchema={form.validationNew}
      initialValues={{ newCategory: '' }}
      onSubmit={onSubmit}
      render={props => <CategoryForm closeModal={closeModal} formFields={form.component} {...props} />}
    />
  )
}

export default AddCategoryContainer
