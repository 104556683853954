import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { Hamburger } from '.'
import '../../styles/MobileNavigation.scss'

const menu = {
  open: {
    clipPath: 'ellipse(200% 200% at 10px 10px)',
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
      velocity: 3,
    },
    zIndex: 2,
    display: 'block',
  },
  closed: {
    clipPath: 'ellipse(0% 0% at -20px -20px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 150,
      damping: 40,
    },
    zIndex: -1,
    transitionEnd: {
      display: 'none',
    },
  },
}

const list = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      staggerDirection: 1,
    },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const listItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const MobileNavigation = ({ navItems, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <div className="MobileNavigation">
      <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Hamburger isMenuOpen={isMenuOpen} />
      </div>
      <motion.div className="menu" initial={false} animate={isMenuOpen ? 'open' : 'closed'} variants={menu}>
        <div className="menu-content">
          {/* TODO: Nav Links Component */}
          <motion.ul className="list" animate={isMenuOpen ? 'open' : 'closed'} variants={list}>
            {navItems.map(({ path, label, icon: Icon }) => (
              <motion.li key={label} className="nav-item" variants={listItem}>
                <NavLink className="nav-item-link" to={path} onClick={() => setIsMenuOpen(false)}>
                  <div className="icon-wrapper">
                    <Icon size={28} />
                  </div>
                  {label}
                </NavLink>
              </motion.li>
            ))}
          </motion.ul>
        </div>
        {children}
      </motion.div>
    </div>
  )
}

export default MobileNavigation
