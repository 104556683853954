import React from 'react'
import {
 Form, FormGroup, Label, Alert,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import ImageDropzoneInput from '../../components/ImageDropzoneInput'
import AddressInput from '../../components/AddressInput'
import { HostInput } from '../../components/Host'
import Map from '../../components/Map'
import { ContentPickerForAddProperty } from '../../components/content'
import { WIFI } from '../../constants/forms/content'

import { Timezones } from '../../constants'

// TODO: Change to add property, remove what we don't need. Formik and validation
const Step1 = props => {
  const {
    dispatch,
    onChange,
    timezone,
    latitude,
    longitude,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressZIP,
    photoURL,
    hostId,
    error,
    nickname,
    onFinish,
    onWifiUpdation,
  } = props

  return (
    <div className="row">
      <div className="col-sm-6">
        {error && <Alert color="danger">{error}</Alert>}
        <Form>
          <FormGroup id="ap1-address-input">
            <Label for="address">
              <span className="required">*</span> Address
            </Label>
            <AddressInput
              value={{
                addressLine1,
                addressLine2,
                addressCity,
                addressState,
                addressZIP,
                addressCountry,
              }}
              onChange={addressParts => onChange(addressParts)}
            />
          </FormGroup>
          <FormGroup id="ap1-nickname-input">
            <Label for="address">Nickname</Label>
            <input
              className="form-control"
              placeholder="Nickname"
              value={nickname}
              data-cy="input-nickname"
              maxLength="50"
              onChange={e => onChange({ nickname: e.target.value })}
            />
          </FormGroup>
          <FormGroup style={{ marginTop: '2rem' }} id="ap1-image-upload">
            <Label for="address">Property Photo</Label>
            <ImageDropzoneInput
              name="photoURL"
              label="Upload"
              value={photoURL}
              desiredSize={{ width: 370 * 2, height: 244 * 2 }}
              onChange={photoURL => onChange({ photoURL })}
            />
          </FormGroup>

          <div className="wifi">
            <h6>{WIFI.title}</h6>
            <ContentPickerForAddProperty onWifiUpdation={onWifiUpdation} content={WIFI} />
          </div>

          <FormGroup style={{ marginTop: '2rem' }} id="ap1-host-select">
            <Label for="address">Host</Label>
            <div>The host's photo will be displayed as the contact for the property.</div>
            <div style={{ marginTop: 14, border: '1px solid #ddd' }} className="shadow-sm">
              <HostInput
                value={hostId}
                autoselectIfEmpty
                onChange={hostId => onChange({ hostId })}
                dispatch={dispatch}
              />
            </div>
          </FormGroup>
          <FormGroup style={{ marginTop: '2rem' }} id="ap1-timezone-select">
            <Label for="timezone">Timezone</Label>
            <select
              className="form-control"
              name="timezone"
              id="timezone"
              value={timezone}
              onChange={e => onChange({ timezone: e.target.value })}>
              {Timezones.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </FormGroup>
        </Form>
        <div className="required">* Required Field</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '12px',
            textAlign: 'right',
          }}>
          <Link to="/properties">
            <button className="btn btn-outline-primary btn-sm" id="ap1-cancel-btn" style={{ marginRight: 10 }}>
              Cancel
            </button>
          </Link>
          <button onClick={onFinish} className="btn btn-primary" data-cy="save-property">
            Save Property
          </button>
        </div>
      </div>
      <div className="col-sm-6" style={{ paddingTop: 28 }}>
        {latitude ? (
          <Map markers={[]} key={`${latitude}-${longitude}`} home={{ position: { lat: latitude, lng: longitude } }} />
        ) : (
          <div className="map-placeholder" />
        )}
      </div>
    </div>
  )
}

export default Step1
