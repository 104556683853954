import { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { FaList, FaThLarge } from 'react-icons/fa'
import { SERVICE_REQUEST_VIEWS_TYPES } from '../../constants/forms/services/forms'
import { MESSAGES } from '../../constants/strings'
import { SearchIcon } from '../../icons'

const ServiceRequestTableHeader = ({ onToggleClick, activeTabView, setRequestSearch }) => {
  const debounceSearch = useCallback(debounce(setRequestSearch, 250), [])

  const handleSearchChange = event => {
    const { value } = event.target
    debounceSearch(value)
  }

  return (
    <div className="service-grid-search mb-3">
      <div className="ListSearchAndFilter wrapper-right mr-3">
        <div className="Search light">
          <div className="search-icon">
            <SearchIcon size={18} />
          </div>
          <input
            type="search"
            className="search-input"
            placeholder={MESSAGES.SEARCH_PLACEHOLDER}
            data-cy="property-search"
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <button
        className="header-btn grid disabled-header"
        disabled={activeTabView === SERVICE_REQUEST_VIEWS_TYPES.GRID}
        onClick={() => onToggleClick(SERVICE_REQUEST_VIEWS_TYPES.GRID)}
      >
        <FaThLarge style={{ marginTop: '-2px' }} />
      </button>
      <button
        className="header-btn list disabled-header"
        disabled={activeTabView === SERVICE_REQUEST_VIEWS_TYPES.LIST}
        onClick={() => onToggleClick(SERVICE_REQUEST_VIEWS_TYPES.LIST)}
      >
        <FaList style={{ marginTop: '-2px' }} />
      </button>
    </div>
  )
}

export default ServiceRequestTableHeader
