/* eslint-disable react/display-name */
import React from 'react'
import classNames from 'classnames'

export default function (props) {
  return (
    <div {...props} className={classNames('form-group-note', props.className)}>
      <div className="FormNote">{props.children}</div>
    </div>
  )
}
