import React from 'react'
import adIconURL from '../images/icons/ad-square-icon.jpg'

function AdvertisementSquareIcon(props) {
  return (
    <span fill="none" {...props}>
      <img src={adIconURL} alt="ads-icon" />
    </span>
  )
}

export default AdvertisementSquareIcon
