import React from 'react'
import FeedbackReport from './FeedbackReport'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'

import '../../styles/AnalyticsContainer.scss'
import { MESSAGES } from '../../constants/strings'

const FeedbackReportContainer = ({ hasReservations, params }) => (hasReservations ? (
  <Resource collection path={['analytics', 'feedbacks', params]} placeholderComponent={Loading}>
    {({ feedbackReports = {} }) => (
      <div className="FeedbackReportContainer">
        {Object.keys(feedbackReports).map(report => {
            return <FeedbackReport {...feedbackReports[report]} key={report} />
          })}
      </div>
      )}
  </Resource>
  ) : (
    <div className="no-reservations">{MESSAGES.NO_RESERVATION_FOR_DATE_RANGE}</div>
  ))

export default FeedbackReportContainer
