import React from 'react'
import { connect } from 'react-redux'
import HostAndQuotesStep from '../components/onboarding/HostAndQuotesStep'
import CheckInCheckOutStep from '../components/onboarding/CheckInCheckOutStep'
import DevicePasscodeStep from '../components/onboarding/DevicePasscodeStep'
import AddAdminsInstallersStep from '../components/onboarding/AddAdminsInstallersStep'

import '../styles/Onboarding.scss'

const OnboardingContainer = ({
  screen, me, onSaveMe, ...props
}) => {
  const onSaveOrganization = organization => {
    return onSaveMe({
      organization,
    })
  }

  return (
    <div className={`onboarding-fullscreen viewing-${screen}`}>
      <div className="background" />
      {screen === '2' ? (
        <HostAndQuotesStep isDefault />
      ) : screen === '3' ? (
        <CheckInCheckOutStep />
      ) : screen === '4' ? (
        <DevicePasscodeStep me={me} onSaveOrganization={onSaveOrganization} dispatch={props.dispatch} />
      ) : (
        <AddAdminsInstallersStep me={me} onSaveOrganization={onSaveOrganization} dispatch={props.dispatch} />
      )}
    </div>
  )
}

export default connect(null)(OnboardingContainer)
