import React from 'react'

function SvgFCheck(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <path fill="currentColor" d="M11.714 27.786L1 17.071l4.286-4.285 6.428 6.428 15-15L31 8.5 11.714 27.786z" />
    </svg>
  )
}

export default SvgFCheck
