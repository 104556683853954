import React from 'react'
import { FaCcStripe } from 'react-icons/fa'

const PaymentIcon = ({ style, ...props }) => (
  <FaCcStripe width="2em" height="2em" style={{ color: '#32335C', ...style }} {...props} />
)
PaymentIcon.defaultProps = {
  style: {},
}
export default PaymentIcon
