import React, { useEffect, useRef } from 'react'
import { isArray } from 'lodash'
import { useDispatch } from 'react-redux'
import DraggableMediaInput from '../../components/DraggableMediaInput'
import { S3_ROOT_URL } from '../../constants'
import { apiRequest } from '../../reducers/data'
import { fieldify } from './utils'

const DraggableMediaField = fieldify(({ field, form, invalid, saveOnExit, validateForm = null, skip, ...rest }) => {
  const dispatch = useDispatch()
  const mediaArrayRef = useRef(null)

  const dragProps = {
    onDragEnd(currentIndex, nextIndex) {
      const updatedItems = [...field.value]
      const currentItem = updatedItems[currentIndex]
      const nextItem = updatedItems[nextIndex]
      if (currentItem && nextItem) {
        [currentItem.mediaOrder, nextItem.mediaOrder] = [nextItem.mediaOrder, currentItem.mediaOrder]
      }
      updatedItems[currentIndex] = currentItem
      updatedItems[nextItem] = nextItem
      form.setFieldValue(field.name, updatedItems)
    },
    nodeSelector: '.list',
    handleSelector: 'img, video',
  }

  const deleteItem = currentItem => {
    let updatedItems = field.value.filter(item => item !== currentItem)
    updatedItems = updatedItems.map((item, index) => ({ ...item, mediaOrder: index + 1 }))
    form.setFieldValue(field.name, updatedItems)
    mediaArrayRef.current = updatedItems
    if (rest.imageURLShouldSet) {
      form.setFieldValue('content.imageURL', '')
    }
  }

  const init = () => {
    if (field.value && field.value[0] && !field.value[0].id && !skip) {
      postServiceMedia({ mediaObj: field.value[0], init: true })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, [])

  const postServiceMedia = async ({ mediaObj, preview, init }) => {
    let json
    if (rest.postMedia) {
      json = await dispatch(apiRequest(['transcoder/start'], 'POST', { uploadedFileKey: mediaObj.fileKey }))
      json = json.json
    } else {
      const data = await dispatch(apiRequest(['medias/'], 'POST', mediaObj))
      json = data.json
    }
    let updatedField = []
    if (init) {
      updatedField = [
        {
          ...mediaObj,
          id: json.data.id,
          mediaOrder: field.value.length,
          mediaDuration: json.data.mediaDuration,
        },
      ]
    } else {
      const mediaField = {
        ...mediaObj,
        id: json.data.id,
        mediaOrder: field.value.length + 1,
        preview,
      }

      if (mediaArrayRef.current && isArray(mediaArrayRef.current)) {
        updatedField = [...mediaArrayRef.current]
        mediaField.mediaOrder = mediaArrayRef.current.length + 1
        mediaArrayRef.current = null
      } else {
        updatedField = [...field.value]
      }
      updatedField.push(mediaField)
    }
    form.setFieldValue(field.name, updatedField)
  }

  const onFinish = async (info, file, complete) => {
    try {
      let { type } = file
      type = type.split('/')
      type = type[0]

      const preview = URL.createObjectURL(file)

      let mediaObj = { mediaType: type.toUpperCase() }
      let mediaDuration
      if (type === 'video') {
        const { json } = await dispatch(apiRequest(['transcoder/start'], 'POST', { uploadedFileKey: info.fileKey }))
        const mediaUrl = `${S3_ROOT_URL}/${json.outputFileKey}`
        if (rest.imageURLShouldSet) {
          form.setFieldValue('content.imageURL', `${mediaUrl}.0000000.jpg`)
        }

        const video = document.createElement('video')
        video.src = preview
        video.addEventListener('loadedmetadata', () => {
          mediaObj.mediaDuration = Math.round(video.duration)
        })
        mediaObj = { ...mediaObj, mediaUrl, fileKey: info.fileKey }
      } else if (type === 'image') {
        mediaObj = { ...mediaObj, mediaUrl: `${S3_ROOT_URL}/${info.fileKey}` }
      }
      if (rest.entityType) {
        mediaObj = { ...mediaObj, entityType: rest.entityType }
      }
      await postServiceMedia({ mediaObj, preview })
      complete && complete()
      validateForm && validateForm()
    } catch (err) {}
  }

  return (
    <>
      <DraggableMediaInput
        {...field}
        invalid={invalid}
        className="DraggableMediaField has-thin-scroll scroll-grey scroll-x"
        dragProps={dragProps}
        deleteItem={deleteItem}
        onFinish={onFinish}
        {...rest}
        saveOnExit={saveOnExit && form.handleSubmit}
      />
    </>
  )
})

export default DraggableMediaField
