import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { isNil, uniqueId } from 'lodash'
import { Modal } from 'reactstrap'
import Cropper from 'react-easy-crop'
import { MdCameraAlt } from 'react-icons/md'
import loadImage from 'blueimp-load-image/js'
import { ModalHeader, ModalBody, ModalFooter } from './modal'
import { s3 } from '../services'
import '../styles/DropzoneInput.scss'
import '../styles/CustomRangeSlider.scss'
import 'react-toastify/dist/ReactToastify.css'
import { displayErrorToast } from '../utils/displayToast'
import {
  ZoomIcon,
  RotateImageIcon,
  AdvertisementExampleIcon,
  AdvertisementSquareIcon,
  AdvertisementOriginalIcon,
  AddIcon,
} from '../icons'
import { S3_ROOT_URL, ExpressServerRoot } from '../constants'
import TooltipComponent from './TooltipComponent'

//Define toBlob for browsers that do not have it
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var canvas = this
      setTimeout(function () {
        var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], { type: type || 'image/png' }))
      })
    },
  })
}

const ImageDropzoneInput = (props) => {
  const inputId = uniqueId('image-input');
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cancelCount, setCancelCount] = useState(0);
  const [localFileURL, setLocalFileURL] = useState(null);
  const [localFileRegion, setLocalFileRegion] = useState(null);
  const [localImage, setLocalImage] = useState(null);
  const [localFileType, setLocalFileType] = useState('image/jpeg');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(props.desiredSize.width / props.desiredSize.height);
  const [saveOriginal, setSaveOriginal] = useState(props.saveOriginal)
  const [orientation, setOrientation] = useState(props.orientation)
  const [custom, setCustom] = useState(false)

  const load = (file) => {
    loadImage(
      file,
      img => {
        var base64data = img.toDataURL(file.type)
        props.flexible && setAspect(getAspectRatio(img))
        setLocalFileURL(base64data)
        setLocalFileType(file.type)
        setLocalImage(img)
      },
      {
        orientation: true,
        meta: true,
        canvas: true,
      }
    )
  }

  const loadImageFile = async (value) => {
    const img = await fetch(value, { cache: 'no-cache' })
    const blob = await img.blob()
    const file = new File([blob], value)
    load(file)
  }

  const getAspectRatio = (img) => {
    let aspect
    switch (props.orientation) {
      case 'original':
        aspect = (img?.width / img?.height) || 1
      case 'square':
        aspect = 1
        break
      case 'portrait':
        aspect = props.desiredSize.width / props.desiredSize.height
        break
    }

    return aspect
  }

  useEffect(() => {
    localImage && orientation === 'original' && onEditDone()
  }, [localImage])

  useEffect(() => {
    if (props.orientation && props.orientation !== orientation) {
      setOrientation(props.orientation)
      setAspect(getAspectRatio(localImage))
      if (props.originalImageURL) {
        if (props.orientation === 'original') {
          props.onChange(props.originalImageURL)
        } else {
          loadImageFile(props.originalImageURL)
        }
      }
    }
  }, [props.orientation]);

  const onDragOver = e => {
    e.preventDefault()
  }

  const onDrop = e => {
    e.preventDefault()
    onProcessFile(e.dataTransfer.files[0])
  }

  const onPickedFile = e => {
    if (e.target.files && e.target.files.length) {
      onProcessFile(e.target.files[0])
    }
  }

  const notify = msg => {
    displayErrorToast(msg)
  }

  const onProcessFile = file => {
    if (!file) return

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
      notify('Sorry, please select a PNG or JPG image.')
      return
    }
    if (file.size > 1024 * 1024 * 12) {
      notify('Sorry, please choose an image that is less than 12MB in size.')
      return
    }
    setCustom(true)
    load(file)
  }

  const onCropComplete = (e, pixels) => {
    setLocalFileRegion(pixels);
  }

  const onReset = () => {
    setIsUploading(false);
    setProgress(0);
    setLocalFileURL(null);
    setLocalFileRegion(null);
    setLocalImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCancelCount(cancelCount + 1);
    setRotation(0);
    setCustom(false)
  }

  const uploadOriginalImg = () => {
    var c = document.createElement("canvas");
    var ctx = c.getContext("2d");

    c.width = localImage.width;
    c.height = localImage.height;

    ctx.drawImage(localImage, 0, 0);
    c.toBlob(function (blob) {
      blob.name = 'picture.jpg'
      upload(blob)
    }, localFileType, 0.92);
  }

  const onEditDone = () => {
    saveOriginal && custom && orientation !== 'original' && uploadOriginalImg()
    const {
      desiredSize, flexible, allowRotate,
    } = props

    let { height, width } = { ...desiredSize }

    let fileRegion = localFileRegion
    if (orientation === 'original') {
      fileRegion = localImage
    }

    if (orientation === 'square') {
      height = width
    }
    if (
      getMaximumZoom() < 1
      && !flexible
      && !window.confirm(
        'Are you sure you want to upload this image? Upscaling will occur when it is presented on the display. You may want to choose a larger image.',
      )
    ) {
      return
    }
    setIsUploading(true);

    if (allowRotate) {
      const tall = localImage.height > localImage.width

      if (flexible || orientation === 'original') {
        if (tall) {
          height = height
          width = height / (localImage.height / localImage.width)
        } else {
          width = width * 2
          height = (width / (localImage.width / localImage.height)) * 2
        }
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const safeArea = Math.max(localImage.width, localImage.height) * 2
      const radianAngle = (rotation * Math.PI) / 180

      // set each dimensions to double largest dimension to allow for a safe area for the
      // image to rotate in without being clipped by canvas context
      canvas.width = safeArea
      canvas.height = safeArea

      // translate canvas context to a central location on image to allow rotating around the center.
      ctx.translate(safeArea / 2, safeArea / 2)
      ctx.rotate(radianAngle)
      ctx.translate(-safeArea / 2, -safeArea / 2)

      // draw rotated image and store data.
      ctx.drawImage(localImage, safeArea / 2 - localImage.width * 0.5, safeArea / 2 - localImage.height * 0.5)
      const data = ctx.getImageData(0, 0, safeArea, safeArea)

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = fileRegion.width
      canvas.height = fileRegion.height

      // paste generated rotate image with correct offsets for x,y crop values.
      let dx = 0 - safeArea / 2 + localImage.width * 0.5
      let dy = 0 - safeArea / 2 + localImage.height * 0.5
      if (orientation !== 'original') {
        dx = dx - fileRegion.x
        dy = dy - fileRegion.y
      }
      ctx.putImageData(data, dx, dy)

      // Upload the image
      canvas.toBlob(
        blob => {
          blob.name = 'picture.jpg'
          upload(blob)
        },
        localFileType,
        0.92,
      )
    } else {
      const canvas = document.createElement('canvas')

      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(
        localImage,
        fileRegion.x,
        fileRegion.y,
        fileRegion.width,
        fileRegion.height,
        0,
        0,
        width,
        height,
      )
      canvas.toBlob(
        blob => {
          blob.name = 'picture.jpg'
          upload(blob)
        },
        localFileType,
        0.92,
      )
    }
  }

  const onUploadError = err => {
    if (err.includes('401')) {
      window.location = `${ExpressServerRoot}/login?next=${encodeURIComponent(window.location.href)}`
      return
    }
    notify(
      `An error occurred while uploading the image: ${err.toString()}. You may want to disable ad-blockers and try again.`,
    )
    onReset()
  }

  const onUploadProgress = _progress => {
    if (props.value) {
      props.onChange(null)
    }
    setProgress(_progress);
  }

  const onUploadFinished = info => {
    saveOriginal && custom && props.onSaveOriginal(`${S3_ROOT_URL}/${info.fileKey}`)
    onReset()
    props.onChange(`${S3_ROOT_URL}/${info.fileKey}`)
    if (props.saveOnExit) {
      props.saveOnExit()
    }
  }

  const getMaximumZoom = () => {
    const { desiredSize } = props
    return localImage ? Math.min(localImage.width / desiredSize.width, localImage.height / desiredSize.height) : 1
  }

  const upload = file => s3.upload({
    file,
    subfolder: 'uploaded-image/',
    onProgress: onUploadProgress,
    onError: onUploadError,
    onFinished: onUploadFinished,
  })

  const clear = () => {
    onReset()
    props.onChange(null)
    if (props.saveOnExit) {
      setTimeout(() => {
        props.saveOnExit()
      }, 0)
    }
  }

  const rotate = direction => {
    const roundedAngle = Math.floor(rotation / 90) * 90
    if (direction === 'right') {
      setRotation((roundedAngle + 90) % 360)
    } else {
      setRotation((roundedAngle + 270) % 360)
    }
  }

  const {
    desiredSize,
    displaySize,
    invalid,
    label,
    isMobile,
    showSelectNew,
    showClear,
    clearLabel,
    showUploaded,
    defaultLabel,
    flexible,
    name,
    allowRotate,
    readOnly,
    showIcon,
  } = props

  const maxZoom = getMaximumZoom()

  const getHeight = () => {
    if (!displaySize) {
      return
    }
    if (orientation === 'square') {
      return '280px'
    }
    return `${displaySize.height}px`
  }

  const getWidth = () => {
    if (!displaySize) {
      return
    }

    if (orientation === 'square') {
      return '318px'
    }
    return `${displaySize.width}px`
  }

  return (
    <div
      className={classNames('ImageDropzoneInput', {
        'is-invalid': invalid,
        square: orientation === 'square',
      })}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onClick={() => readOnly && alert('Sorry, you cannot edit this image')}>
      <input
        id={inputId}
        name={name}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        key={cancelCount}
        onChange={onPickedFile}
        disabled={readOnly}
      />
      {props.value ? (
        <>
          {showUploaded && (
            <div
              className={classNames('DropzoneInput populated', {
                square: orientation === 'square',
                ['original-size-view']: orientation === 'original',
              })}>
              <label htmlFor={inputId} className="dropzone-label">
                <img
                  src={props.value}
                  alt="Upload Preview"
                  className={classNames({
                    ['original-img']: orientation === 'original',
                  })}
                  style={{
                    maxWidth: getWidth(),
                    maxHeight: getHeight(),
                    height: getHeight(),
                  }}
                />
                {showIcon ? (
                  orientation === 'original' ? (
                    <AdvertisementOriginalIcon />
                  ) : orientation === 'square' ? (
                    <AdvertisementSquareIcon />
                  ) : (
                    <AdvertisementExampleIcon className="icon" width="300px" height="150px" />
                  )
                ) : null}
              </label>
            </div>
          )}
          {(showSelectNew || showClear) && (
            <div className="controls">
              {showSelectNew && (
                <div className="select-new-controls">
                  <label htmlFor={inputId} className="link s add-new-link inline">
                    Add a new image…
                  </label>
                  <div className="instructions">
                    {`(${desiredSize.width} x ${desiredSize.height}px | JPEG or PNG )`}
                  </div>
                </div>
              )}
              {showClear && (
                <div onClick={clear} className="clear-controls link s add-new-link inline">
                  {clearLabel || 'Remove'}
                </div>
              )}
            </div>
          )}
        </>
      ) : isMobile ? (
        <div className="DropzoneInput">
          <label htmlFor={inputId} className="m-img-label">
            <div className="progress" style={{ width: `${Math.round(props.progress)}%` }} />
            <MdCameraAlt size={50} />
            Add Photo
          </label>
        </div>
      ) : props.addNew ? (
        <>
          <div className="DropzoneInput" style={{ flexDirection: 'row' }} data-tip data-for="addNew">
            <label htmlFor={inputId}>
              <AddIcon />
              {label && !defaultLabel ? label : 'Add New'}
            </label>
          </div>
          <TooltipComponent offset={{ left: 25 }} id="addNew">
            <div>
              {`(${desiredSize.width} x ${orientation === 'square' ? desiredSize.width : desiredSize.height
                }px | JPEG or PNG)`}
            </div>
          </TooltipComponent>
        </>
      ) : (
        <div className="DropzoneInput" style={{ flexDirection: 'row' }}>
          <label htmlFor={inputId} className="btn btn-outline-primary btn-sm upload-button">
            {label && !defaultLabel ? label : 'Upload'}
          </label>
          {!props.isMobile && (
            <div>
              <div>
                {' '}
                or
                {props.instructions || ' drag and drop a photo'}
              </div>
              <div style={{ opacity: 0.5, fontSize: '0.9em' }}>
                {`(${desiredSize.width} x ${orientation === 'square' ? desiredSize.width : desiredSize.height
                  }px | JPEG or PNG)`}
              </div>
            </div>
          )}
        </div>
      )}

      {localFileURL && orientation !== 'original' && (
        <Modal
          isOpen
          className={classNames('ImageDropzoneModal', {
            'm-modal': isMobile,
          })}>
          <ModalHeader hideClose>Edit Image</ModalHeader>
          <ModalBody>
            <div
              className="cropper-wrapper"
              onWheelCapture={e => {
                // There is a bug in Cropper which causes the image to "jump" too small /
                // move off-center if you zoom out and the maxZoom is <1. We just prevent
                // the scroll events from reaching the component.
                if (maxZoom < 1) {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}>
              <Cropper
                image={localFileURL}
                crop={crop}
                zoom={zoom}
                maxZoom={maxZoom}
                aspect={aspect}
                onCropChange={_crop => setCrop(_crop)}
                onZoomChange={_zoom => setZoom(_zoom)}
                onCropComplete={onCropComplete}
                rotation={rotation}
                onRotationChange={_rotation => allowRotate && setRotation(_rotation)}
              />
              {progress > 0 && (
                <div
                  style={{
                    zIndex: 100,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(255,255,255,0.7)',
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 500,
                    paddingTop: 240,
                    position: 'absolute',
                  }}>
                  {`Uploading... ${progress}%`}
                </div>
              )}
            </div>
          </ModalBody>
          <div className="image-controls-container">
            <div className="zoom-container">
              {maxZoom < 1 ? (
                <div className="text-danger">
                  This image is too small. Upscaling will occur when it is presented on the display.
                </div>
              ) : (
                <div className="range-container">
                  <ZoomIcon />
                  <input
                    className="custom-range-slider"
                    type="range"
                    value={zoom}
                    step={0.05}
                    onChange={e => setZoom(e.target.value)}
                    min={1}
                    max={maxZoom}
                  />
                </div>
              )}
            </div>
            {!flexible && allowRotate && (
              <div className="rotate-container">
                <div className="rotate-button" onClick={() => rotate('right')}>
                  <RotateImageIcon />
                </div>
                <div className="rotate-button flip" onClick={() => rotate('left')}>
                  <RotateImageIcon />
                </div>
              </div>
            )}
          </div>
          <ModalFooter>
            <div className="buttons-container">
              <button className="btn btn-outline-primary" onClick={onReset}>
                Cancel
              </button>
              <button
                data-cy="upload-button"
                className="btn btn-primary"
                disabled={!localFileRegion || isUploading}
                onClick={onEditDone}>
                Upload
              </button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

ImageDropzoneInput.defaultProps = {
  showSelectNew: false,
  showClear: false,
  defaultLabel: false,
  format: 'jpeg',
  showUploaded: true,
  flexible: false,
  allowRotate: !isIOS,
}

export default ImageDropzoneInput;
