import React from 'react'

const ContentEditor = ({ children, ...rest }) => {
  return (
    <div className="ContentEditor" {...rest}>
      {children}
    </div>
  )
}

export default ContentEditor
