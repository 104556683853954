import React from 'react'
import RichTextEditor from '../../components/RichTextEditor'
import { fieldify } from './utils'

const RichTextEditorField = fieldify(({
  field, form, invalid, ...rest
}) => {
  const className = ['form-control', invalid ? 'is-invalid' : null].filter(v => v).join(' ')
  return (
    <>
      <div className={className} style={{ padding: 0, backgroundColor: 'transparent', border: 'none' }}>
        <RichTextEditor {...field} {...rest} value={field.value} />
      </div>
    </>
  )
})

export default RichTextEditorField
