/* eslint-disable react/display-name */
import React from 'react'
import Form from './Form'
import { closeModal } from '../reducers/modals'

export default function ({ formatValues, next, ...etc }) {
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const data = formatValues ? formatValues(values) : values
    try {
      const { json, response } = await etc.onSave(data)
      if (response.status === 200 || response.status === 201) {
        if (!etc.onSuccess) {
          console.warn('You didn\'t define onSuccess for this modal')
        }

        if (etc.onSuccess) etc.onSuccess()

        if (next) {
          next()
        } else {
          etc.dispatch(closeModal())
        }
      } else {
        setErrors({ _error: json.error })
        setSubmitting(false)
      }
    } catch (error) {
      console.error(error)
      setErrors({ _error: error })
      setSubmitting(false)
      etc.dispatch(closeModal())
    }
  }
  return <Form onSubmit={onSubmit} {...etc} />
}
