import React from 'react'
import ProgressBar from './ProgressBar'
import '../styles/Progress.scss'

function Progress({ completed, total, noun, verb }) {
  return (
    <div className="Progress">
      <ProgressBar progress={(completed / total) * 100} />
      <div className="progress-text-container">
        <span className="progress-completed">{completed}</span> of {total} {noun} {verb}
      </div>
    </div>
  )
}
export default Progress
