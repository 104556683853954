import React from 'react'
import Icon from './Icon'

function RestaurantsIcon(props) {
  return (
    <Icon viewBox="0 0 22 24" {...props} width="1em" height="1em">
      <g clipPath="url(#restaurants_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0h9v10.714a3.429 3.429 0 01-3.429 3.429h-.428v8.571h3.214a.643.643 0 110 1.286H.643a.643.643 0 110-1.286h3.214v-8.571H3.43A3.429 3.429 0 010 10.714V0zm14.786 0c.355 0 .643.288.643.643v5.786c0 .71.575 1.285 1.285 1.285h.429V.643a.643.643 0 111.286 0v7.071h.428c.71 0 1.286-.575 1.286-1.285V.643a.643.643 0 111.286 0v5.786A2.571 2.571 0 0118.857 9h-.428v14.357a.643.643 0 11-1.286 0V9h-.429a2.571 2.571 0 01-2.571-2.571V.643c0-.355.288-.643.643-.643z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="restaurants_svg__clip0">
          <path fill="#fff" d="M0 0h21.429v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default RestaurantsIcon
