import React from 'react'
import '../styles/CreateNewProperty.scss'

function CreateNewProperty({ choices, onCancel, history }) {
  return (
    <div className="CreateNewProperty border-0 mt-0 mb-4">
      <div className="template-container manual-entry">
        <h5>Manual Entry</h5>
        {/* <div>Create a property in the Guestview Guide system:</div> */}
        <div>Add a property manually. Add reservations manually or sync reservations via iCal.</div>
        <button onClick={() => choices.setChoice(onCancel, history)}>+ New Property</button>
        <div className="or-separator">
          <span>OR</span>
        </div>
        {/* <div
          onClick={() => choices.setChoice(onCancel, history)}
          className="create-template"
          data-cy="create-new-property"
        >
          <div className="logo">
            {choices.icon}
            <div className="name">{choices.templateName}</div>
          </div>
          <div className="description">{choices.templateDescription}</div>
        </div> */}
      </div>
    </div>
  )
}

export default CreateNewProperty
