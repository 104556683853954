import React, { useEffect, useState } from 'react'
import { isEmpty, map, get } from 'lodash'

import { EmptyNavItemPlaceholder, Loading } from '../PageStates'
import { ServiceIcon } from '../../icons'
import Resource from '../../Resource'
import { apiRequest } from '../../reducers/data'
import ServiceRequestGridContainer from '../../containers/ServiceRequestGridContainer'
import ServiceRequestListContainer from '../../containers/ServiceRequestListContainer'
import ServiceRequestTableHeader from './ServiceRequestTableHeader'
import { SERVICE_REQUEST_VIEWS_TYPES } from '../../constants/forms/services/forms'
import { formatters } from '../../Utils'
import { MESSAGES } from '../../constants/strings'

const ServiceRequestTable = ({ requests, onUpdateItem, supportPaymentProvider, dispatch }) => {
  const [data, setData] = useState(requests)
  const [inProgress, setInProgress] = useState(false)
  const [activeTabView, setActiveTabView] = useState(SERVICE_REQUEST_VIEWS_TYPES.GRID)
  const [searchTerm, setSearchTerm] = useState('')
  let timer

  const search = results => {
    const filteredResults = results.filter(result => {
      const address = formatters.address(get(result, 'property', ''))
      const serviceName = get(result, 'service.name', '') || ''
      const searableItems = [...address, serviceName].join(' ').toLowerCase()
      return searableItems.includes(searchTerm.toLowerCase())
    })

    return filteredResults
  }

  const checkCartAbandon = async () => {
    if (!inProgress) {
      setInProgress(true)
      let paymentPending = data.filter(x => x.status === 'PAYMENT_PENDING')
      if (paymentPending.length > 0) {
        const requestIds = map(paymentPending, 'id')
        const { json } = await dispatch(apiRequest(['requests/status'], 'POST', { requestIds }))
        let temp = data
        for (const req of json.requests) {
          temp = temp.map(x => (x.id === req.id ? { ...x, status: req.status, text: req.text } : x))
        }
        setData(temp)

        paymentPending = temp.filter(x => x.status === 'PAYMENT_PENDING')
        if (paymentPending.length > 0) {
          timer = setTimeout(() => {
            checkCartAbandon()
          }, process.env.REACT_APP_CART_ABANDONED_INTERVAL)
        }
        setInProgress(false)
      }
    }
  }
  const [filteredResults, setFilteredResults] = useState(search(data))

  useEffect(() => {
    checkCartAbandon()

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    setFilteredResults(search(data))
  }, [requests, searchTerm, data])

  const props = {
    data: filteredResults,
    onUpdateItem,
    supportPaymentProvider,
    dispatch,
    searchTerm,
    checkCartAbandon,
  }

  return (
    <>
      <ServiceRequestTableHeader
        onToggleClick={setActiveTabView}
        activeTabView={activeTabView}
        setRequestSearch={setSearchTerm}
      />

      {activeTabView === SERVICE_REQUEST_VIEWS_TYPES.GRID ? (
        <ServiceRequestGridContainer {...props} />
      ) : (
        <ServiceRequestListContainer {...props} />
      )}
    </>
  )
}

export default ({ paymentProvider, recordAnalytics, activeTabView }) => {
  return (
    <Resource collection path={['requests']} placeholderComponent={Loading}>
      {(requests, { onUpdateItem, dispatch }) => {
        return (
          <div>
            {!requests.length ? (
              <EmptyNavItemPlaceholder
                icon={<ServiceIcon height="72" width="72" />}
                text={MESSAGES.NO_SERVICE_REQUEST}
              />
            ) : (
              <ServiceRequestTable
                requests={requests}
                onUpdateItem={async item => {
                  recordAnalytics(item.action, item.id)
                  await onUpdateItem(item)
                  window.location.reload()
                }}
                supportPaymentProvider={!isEmpty(paymentProvider)}
                dispatch={dispatch}
                activeTabView={activeTabView}
              />
            )}
          </div>
        )
      }}
    </Resource>
  )
}
