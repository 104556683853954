import ContactRequestTopicsFields from '../../components/contact-host/ContactRequestTopicsFields'
import { Yup } from '../../forms'
import {
  TextField,
  TextareaField,
  SelectPropertiesField,
  ListField,
  CheckboxField,
  WifiFields,
  ContactToggleField,
  AutoCompleteField,
  RichTextEditorField,
} from '../../forms/fields'
import { IconsS3Path } from '../generic'
import { CHECKIN_CHECKOUT_COLUMNS } from '../tables/content'
import { SUB_SECTIONS } from '../thirdPartyAnalytics'
import { CONTACT_HOST_WIZARD } from './contactHost'

export const CONTENT_FIELDS_PATH = 'content'

export const ALLOWED_MULTIPLE = ['amenity', 'supply', 'policy', 'restaurant', 'activity']

export const ALLOWED_DUPLICATES = [
  'welcome',
  'contact',
  'wifi',
  'rules',
  'checkInOut',
  'checkoutDetails',
  'amenity',
  'supply',
  'policy',
  'checkoutChecklist',
  'checkoutNotification',
]

export const ALLOWED_DEFAULT = [
  'contact',
  'wifi',
  'rules',
  'checkInOut',
  'checkoutDetails',
  'checkoutChecklist',
  'checkoutNotification',
  'checkinSurvey',
  'checkoutSurvey',
]

export const EXCLUDE_FROM_CONTENT = ['name', 'propertyIds']

export const COMMONS_FIELDS = {
  name: {
    label: 'Name',
    name: 'name',
    component: TextField,
    validation: Yup.string().required('required'),
    props: {
      maxLength: 80,
      placeholder: 'Component Name',
    },
  },
  propertyIds: ({ isDefault, isAllowedMultiple }) => {
    return {
      label: 'Apply to Properties',
      name: 'propertyIds',
      note:
        !isAllowedMultiple &&
        (isDefault
          ? 'The default component is assigned to any property that does not have a component assigned or a custom value set.'
          : 'Assigning this component will override the property’s current settings.'),
      component: SelectPropertiesField,
      validation: Yup.array().default([]),
      props: {
        readOnly: isDefault,
        saveOnExit: false,
      },
    }
  },
}

export const DEFAULT_WIFI = {
  isDefault: () => {
    return {
      label: 'Set WiFi As Default',
      name: 'isDefault',
      component: CheckboxField,
      validation: Yup.boolean().default(false),
      props: {
        showLabel: false,
      },
    }
  },
}

export const WIFI = {
  location: '',
  type: 'wifi',
  title: 'WiFi',
  description: 'Inform your guests on how to connect to WiFi in your properties.',
  addNew: '+ New WiFi Component',
  verb: {
    create: 'Add',
    edit: 'Edit',
  },
  event: SUB_SECTIONS.WIFI,
  fields: ({ readOnly, saveOnExit }) => [
    {
      component: WifiFields,
      manualGroup: true,
      name: 'wifiFormat',
      props: {
        saveOnExit,
        readOnly,
        fieldsPath: CONTENT_FIELDS_PATH,
      },
    },
  ],
}

export const HOUSE_RULES = {
  location: '',
  type: 'rules',
  title: 'Property Guidelines',
  description:
    'A list of short reminders for guests about important policies. These guidelines will be displayed during check in and prominently on the GuestView Guide.',
  addNew: '+ New Property Guidelines Component',
  verb: {
    create: 'Add',
    edit: 'Edit',
  },
  event: SUB_SECTIONS.HOUSE_RULES,
  fields: ({ readOnly, saveOnExit }) => [
    {
      label: 'Property Guidelines',
      name: 'ruleList',
      component: ListField,
      validation: Yup.array()
        .of(
          Yup.object().shape({
            text: Yup.string()
              .trim('The rule cannot include leading and trailing spaces')
              .required('required'),
          })
        )
        .default(() => [])
        .required('required'),
      disabled: readOnly,
      props: {
        fast: false,
        saveOnExit,
        type: 'textarea',
        rows: 1,
        maxLength: 350,
        addNew: 'Property Guidelines',
        skipBlurMode: true,
      },
    },
    {
      name: 'includeInCheckIn',
      label: 'Include in Check In flow',
      component: CheckboxField,
      validation: Yup.boolean().default(true),
      props: {
        toggleSwitch: true,
        enableDisable: true,
        saveOnExit,
        readOnly,
        fast: false,
      },
    },
  ],
}

export const CHECK_IN_OUT_NOTIFICATION = {
  location: 'check-in-out', // TODO: Group level
  type: 'checkInOutNotification',
  title: 'Check In / Out Notification',
  description: 'Set the check in and check out notifications for your properties.',
  addNew: '+ New Check In / Out Notification Component',
  verb: {
    create: 'Add',
    edit: 'Edit',
  },
  columns: CHECKIN_CHECKOUT_COLUMNS,
  fields: ({ readOnly, saveOnExit }) => [
    {
      label: 'Name',
      name: 'name',
      component: TextField,
      validation: Yup.string().required('required'),
      props: {
        saveOnExit,
        readOnly,
      },
    },
    {
      label: 'Email',
      name: 'email',
      component: TextField,
      validation: Yup.string().required('required'),
      props: {
        saveOnExit,
        readOnly,
      },
    },
    {
      label: 'Phone',
      name: 'phone',
      component: TextField,
      validation: Yup.string().required('required'),
      props: {
        saveOnExit,
        readOnly,
      },
    },
    {
      label: 'Checkin',
      name: 'checkin',
      component: CheckboxField,
      validation: Yup.boolean(),
      props: {
        saveOnExit,
        readOnly,
        showLabel: false,
      },
    },
    {
      label: 'Checkout',
      name: 'checkout',
      component: CheckboxField,
      validation: Yup.bool(),
      props: {
        saveOnExit,
        readOnly,
        showLabel: false,
      },
    },
  ],
}

export const CHECK_IN_OUT = {
  location: 'check-in-out', // TODO: Group level
  type: 'checkInOut',
  title: 'Check In / Out Times',
  description: 'Set the check in and check out times for your properties.',
  addNew: '+ New Check In / Out Time Component',
  verb: {
    create: 'Add',
    edit: 'Edit',
  },
  event: SUB_SECTIONS.CHECK_IN_OUT,
  fields: ({ readOnly, saveOnExit }) => [
    {
      label: 'Check In Time',
      name: 'checkinTime',
      component: TextField,
      validation: Yup.string().required('required'),
      props: {
        saveOnExit,
        readOnly,
        type: 'time',
        'data-cy': 'checkinTime',
      },
    },
    {
      label: 'Check Out Time',
      name: 'checkoutTime',
      component: TextField,
      validation: Yup.string().required('required'),
      props: {
        saveOnExit,
        readOnly,
        type: 'time',
        'data-cy': 'checkoutTime',
      },
    },
  ],
}

export const CHECKOUT_DETAILS = {
  location: 'check-in-out',
  type: 'checkoutDetails',
  title: 'Check Out Message',
  description: 'The check out message will display prominently during the check out phase.',
  addNew: '+ New Check Out Message Component',
  verb: {
    create: 'Add',
    edit: 'Edit',
  },
  event: SUB_SECTIONS.CHECKOUT_DETAILS,
  fields: ({ readOnly, saveOnExit }) => [
    {
      label: 'Thank You Quote',
      name: 'checkoutMessage',
      component: TextField,
      validation: Yup.string().required('required'),
      props: {
        saveOnExit,
        readOnly,
        maxLength: 210,
        type: 'textarea',
        placeholder: 'Message to guests',
        rows: 4,
        datacy: 'checkoutMessage',
      },
    },
  ],
}

export const CHECKIN_SURVEY = {
  location: 'check-in-out',
  type: 'checkinSurvey',
  title: 'Check In Survey',
  description: 'Survey will record the customer check in feedback',
  event: SUB_SECTIONS.CHECKIN_SURVEY,
  triggerKey: 'removeFromCheckIn',
  formatValues: values => ({
    ...values,
    content: {
      ...values.content,
      removeFromCheckIn: true,
    },
  }),
  switch: {
    options: [
      {
        label: 'Check In survey disabled from Check In flow',
        value: true,
      },
      {
        label: 'Check In survey enabled from Check In flow',
        value: false,
      },
    ],
  },
  fields: ({ readOnly, saveOnExit }) => [
    {
      name: 'removeFromCheckIn',
      label: 'Remove from check in flow',
      // component: CheckboxField,
      validation: Yup.boolean().default(true),
      props: {
        toggleSwitch: true,
        onOff: true,
        saveOnExit,
        readOnly,
      },
    },
  ],
}

export const CHECKOUT_SURVEY = {
  location: 'check-in-out',
  type: 'checkoutSurvey',
  title: 'Check Out Survey',
  description: 'Survey will record the customer check out feedback',
  event: SUB_SECTIONS.CHECKOUT_SURVEY,
  triggerKey: 'removeFromCheckOut',
  formatValues: values => ({
    ...values,
    content: {
      ...values.content,
      removeFromCheckOut: true,
    },
  }),
  switch: {
    options: [
      {
        label: 'Check Out survey disabled from Check Out flow',
        value: true,
      },
      {
        label: 'Check Out survey enabled from Check Out flow',
        value: false,
      },
    ],
  },
  fields: ({ readOnly, saveOnExit }) => [
    {
      name: 'removeFromCheckOut',
      label: 'Remove from check out flow',
      // component: CheckboxField,
      validation: Yup.boolean().default(true),
      props: {
        toggleSwitch: true,
        onOff: true,
        saveOnExit,
        readOnly,
      },
    },
  ],
}

export const CHECKOUT_CHECKLIST = {
         location: 'check-in-out',
         type: 'checkoutChecklist',
         title: 'Check Out Checklist',
         description:
           'A short list of reminders for the guests and items they need to complete before their check out. The Checklist will be displayed prominently during the check out flow.',
         addNew: '+ New Check Out Checklist Component',
         verb: {
           create: 'Add',
           edit: 'Edit',
         },
         event: SUB_SECTIONS.CHECKOUT_CHECKLIST,
         fields: ({ readOnly, saveOnExit }) => [
           {
             label: 'Require Completion Confirmation',
             name: 'includeConfirmation',
             component: CheckboxField,
             validation: Yup.boolean().default(true),
             disabled: readOnly,
             props: {
               toggleSwitch: true,
               enableDisable: true,
               saveOnExit,
               fast: false,
             },
           },
           {
             label: 'Checklist',
             name: 'checklist',
             component: ListField,
             validation: Yup.array()
               .of(Yup.object().shape({ text: Yup.string().required('required') }))
               .default(() => [])
               .required('required'),
             disabled: readOnly,
             props: {
               fast: false,
               saveOnExit,
               addNew: 'Check Out Checklist Item',
               skipBlurMode: true,
             },
           },
         ],
       }

export const DEFAULT_MESSAGE_FOR_REQUESTS_ON =
  'For life threatening emergency situations, please call 911 or go to the nearest emergency room. For all other matters, select the relevant topic below and our service team will be in touch with you.'

export const DEFAULT_MESSAGE_FOR_REQUESTS_OFF =
  '<p>For life threatening emergency situations, please call 911 or go to the nearest emergency room. For all other matters contact us using the phone number above and our service team will be in touch with you.</p>'

  
export const CONTACT_US = {
  location: 'contact',
  type: 'contact',
  title: 'Contact Us',
  description:
    'Allow your guests to submit contact requests directly via the GuestView Guide. When not enabled, guests will see the contact information included with the Host',
  addNew: '+ New Contact Us Component',
  item: 'Contact Us',
  verb: {
    create: 'Add',
    edit: 'Edit',
    duplicate: 'Duplicate',
  },
  event: SUB_SECTIONS.CONTACT_US,
  fields: ({ readOnly, saveOnExit, contactHostOptions }) =>
    CONTACT_HOST_WIZARD({ readOnly, saveOnExit, contactHostOptions })[0],
}

export const GENERAL_INFO = {
  title: 'General Info',
  HOST: {
    title: 'Host',
    CREATE_NEW: 'Create a host...',
    NO_HOST_SELECTED: 'No host selected',
  },
  NICKNAME: 'Nickname',
  TIMEZONE: 'Timezone',
}
