/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { Loading } from '../../components/PageStates'
import { OrderableList } from '../../components/list'
import { ServiceIcon } from '../../icons'
import {
  SERVICE_IMAGE,
  SERVICE_NAME,
  SERVICE_PRICE,
  SERVICE_STATUS,
  SERVICE_PROMOTIONS,
} from '../../constants/tables/services'
import { UPDATED_CELL } from '../../constants/tables/common'
import { ACTIONS, SECTIONS } from '../../constants/thirdPartyAnalytics'

const COLUMNS = [
  {
    id: 'services',
    columns: [SERVICE_IMAGE, SERVICE_NAME, SERVICE_PRICE, SERVICE_PROMOTIONS, SERVICE_STATUS, UPDATED_CELL],
  },
]

const ServiceTable = ({ data, onUpdateItem, dispatch }) => {
  const filteredData = data.filter(service => !Array.isArray(service))
  const memorizedColumns = useMemo(() => COLUMNS, [])
  const initialState = {
    dispatch,
  }

  return (
    <OrderableList
      columns={memorizedColumns}
      data={filteredData}
      updateOrder="reorder"
      relation="property_service"
      onUpdateItem={onUpdateItem}
      item="Services"
      manageLink="/services"
      initialState={initialState}
      emptyState={{ icon: ServiceIcon, text: 'You have not yet added any services for this property' }}
    />
  )
}

const Services = ({ NestedResource, recordAnalytics }) => {
  return (
    <NestedResource collection path={['services']} placeholderComponent={Loading}>
      {(active, { onUpdateItem, dispatch }) => {
        const onUpdate = props => {
          recordAnalytics && recordAnalytics({ section: SECTIONS.SERVICES, action: ACTIONS.REORDER })
          return onUpdateItem(props)
        }

        return <ServiceTable data={active} onUpdateItem={onUpdate} dispatch={dispatch} />
      }}
    </NestedResource>
  )
}

export default Services
