import React, { useEffect, useRef, useState } from 'react'
import { connect as formikConnect } from 'formik'
import {
  orderBy, isEmpty, get, isFunction,
} from 'lodash'
import { withRouter, matchPath } from 'react-router-dom'
import { closeModal, changeModalProps } from '../reducers/modals'
import { RECOMMENDATION_TYPES } from '../constants/forms/recommendations'
import { PROPERTY_TYPES } from '../constants/forms/propertyInfo'
import Map from '../components/Map'
import YelpResultList from '../components/YelpResultList'
import Resource from '../Resource'
import YelpSearchResultsContainer from '../components/YelpSearchResultsContainer'
import { EditIcon, YelpLogoIcon } from '../icons'

import '../styles/RecommendationAddContainer.scss'

const { Point: GPoint, LatLngBounds, Size: GSize } = window.google.maps

function propertiesToCities(properties) {
  const byCity = {}
  // eslint-disable-next-line no-unused-vars
  for (const property of properties) {
    byCity[property.addressCity] = byCity[property.addressCity] || []
    byCity[property.addressCity].push(property)
  }

  return Object.keys(byCity)
    .sort()
    .map(city => {
      return {
        name: city,
        latitude: byCity[city].reduce((sum, p) => sum + p.latitude, 0) / byCity[city].length,
        longitude: byCity[city].reduce((sum, p) => sum + p.longitude, 0) / byCity[city].length,
      }
    })
}

// function defaultPropertiesForNewRecommendation(recommendation, { properties, city }) {
//   // TODO: In the future, we could auto-select all the properties within 1 mile, etc.
//   // using the recommendation.coordinates and properties[].latitude / longitude.
//   return properties.filter(p => p.addressCity === city.name)
// }

const RecommendationAddContainerWithData = (props) => {
  const {
    cities, properties, recommendations, initialTerm, propertyId,
  } = props;
  const currentProperty = propertyId && properties.find(property => property.id === parseInt(propertyId, 10));
  const cityInURL = currentProperty && currentProperty.addressCity;

  const [city, setCity] = useState(props.cities.find(c => c.name === cityInURL) || props.cities[0]);
  const [highlightedId, setHighlightedId] = useState(null);
  const mapRef = useRef(null);
  const yelpListRef = useRef(null);

  useEffect(() => {
    onFitToCity(city);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  const onCreateRecommendationFromResult = result => {
    const {
      dispatch,
      next,
      formik: { setFieldValue },
    } = props

    const { location } = result
    const contentItems = {
      isRecommended: true,
      provider: 'yelp',
      imageURL: result.image_url,
      location: {
        displayAddress: location.display_address.join(', '),
        address1: location.address1,
        address2: location.address2,
        address3: location.address3,
        city: location.city,
        country: location.country,
        zip: location.zip_code,
        coordinates: result.coordinates,
        state: location.state,
      },
      phone: result.phone,
      url: `https://www.yelp.com/biz/${result.id}`,
      providerData: {
        ...result,
      },
    }

    setFieldValue('name', result.name)
    setFieldValue('content', contentItems)
    dispatch(changeModalProps({ fieldsConfig: { readOnly: true } }))
    next()
  }

  const onCreateRecommendation = () => {
    const {
      next,
      dispatch,
      formik: { resetForm, setFieldValue },
    } = props
    resetForm()
    const contentItems = {
      isRecommended: true,
    }
    setFieldValue('content', contentItems)
    dispatch(changeModalProps({ fieldsConfig: { readOnly: false } }))
    next()
  }

  const onEditRecommendation = data => {
    const { dispatch, history } = props
    const content = RECOMMENDATION_TYPES.find(recommendation => recommendation.type === data.type)

    dispatch(closeModal())
    setTimeout(() => {
      history.push(`/content/${content.location}?edit=${data.id}`)
    }, 200)
  }

  const onChangeCity = _city => {
    setCity(_city)
    onFitToCity(_city)
  }

  const onFitToCity = city => {
    const bounds = new LatLngBounds()

    props.properties
      .filter(p => p.addressCity === city.name)
      .forEach(p => bounds.extend({
        lat: p.latitude,
        lng: p.longitude,
      }))
    mapRef?.current?.fitBounds(bounds)
  }

  return (
    <div className="RecommendationAddContainer">
      <YelpSearchResultsContainer latitude={city.latitude} longitude={city.longitude} initialTerm={initialTerm}>
        {({
          results, term, fetching, onChangeTerm,
        }) => {
          const existingRecommendationIds = recommendations.map(recommendation => {
            const providerId = get(recommendation, 'content.providerData.id')
            return providerId
          })
          let filteredResults = results.slice(0, 20)
          if (isEmpty(term)) {
            filteredResults = orderBy(results, ({ id }) => !existingRecommendationIds
              .includes(id), ['desc'])
              .slice(0, 20)
          }

          return (
            <div className="list-and-map">
              <div className="list-and-controls">
                <div className="place-search card p-3 shadow-sm rounded">
                  <div className="city-selector-wrapper">
                    <select
                      className="form-control"
                      value={city.name}
                      onChange={e => onChangeCity(cities.find(c => c.name === e.target.value))}
                    >
                      {cities.map(city => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="search-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      data-cy="input-recommendation-yelp-search"
                      value={term}
                      onChange={onChangeTerm}
                    />
                    {fetching ? <div className="spinner" /> : null}
                  </div>
                  <div className="logo-and-manual-wrapper">
                    <div className="yelp-logo-wrapper">
                      <YelpLogoIcon width={50} />
                    </div>
                    <button className="manual-recommendation link inline s" onClick={onCreateRecommendation}>
                      <EditIcon />
                      {' '}
                      Add manually
                    </button>
                  </div>

                  <YelpResultList
                    ref={yelpListRef}
                    propertyId={propertyId}
                    results={filteredResults}
                    highlightedId={highlightedId}
                    onChooseResult={onCreateRecommendationFromResult}
                    onEditRecommendation={onEditRecommendation}
                    recommendations={recommendations}
                    onHoverResult={result => {
                      setHighlightedId(result && result.id)
                      if (result) {
                        mapRef?.current.panToIfInvisible({
                          lat: result.coordinates.latitude,
                          lng: result.coordinates.longitude,
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div className="map-container">
                <Map
                  ref={mapRef}
                  markers={[].concat(
                    filteredResults.map((r, idx) => {
                      return {
                        id: `${r.id}-${highlightedId === r.id}`,
                        resultId: r.id,
                        label: `${idx + 1}`,
                        icon: {
                          path: 'M 0,0 C -3,-20 -12,-22 -12,-30 A 12,12 0 1,1 12,-30 C 12,-22 3,-20 0,0 z',
                          fillColor: highlightedId === r.id ? '#FFFFFF' : '#FF7353',
                          fillOpacity: 1,
                          strokeColor: '#000',
                          strokeWeight: 2,
                          labelOrigin: new GPoint(0, -30),
                          scale: 1,
                        },
                        position: { lat: r.coordinates.latitude, lng: r.coordinates.longitude },
                      }
                    }),
                    properties.map(p => {
                      return {
                        id: p.id,
                        icon: {
                          url: require('../images/icons/icon-map-home@2x.png'),
                          scaledSize: new GSize(36, 36),
                        },
                        position: { lat: p.latitude, lng: p.longitude },
                      }
                    }),
                  )}
                  onClickMarker={marker => {
                    setHighlightedId(marker.resultId)
                    if (yelpListRef && yelpListRef.current && isFunction(yelpListRef.current.scrollTo)) {
                      yelpListRef.current.scrollTo(marker.resultId)
                    }
                  }}
                  key={`${city.latitude}-${city.longitude}`}
                />
              </div>
            </div>
          )
        }}
      </YelpSearchResultsContainer>
    </div>
  )
}

const RecommendationAddContainer = (props) => {
  const { content, history } = props;
  const match = matchPath(history.location.pathname, {
    path: '/properties/:id/recommendations/:subTab?',
    exact: true,
    strict: false,
  })

  return (
    <Resource collection path={['constants', 'recommendation-categories']} placeholder={null}>
      {categories => (
        <Resource collection path={['customizations', content.type]} placeholder={null}>
          {(recommendations, {
            onCreateItem, onDeleteItem, dispatch, onUpdateItem,
          }) => (
            <Resource collection path={['properties', `?type=${PROPERTY_TYPES.current}`]} placeholder={null}>
              {properties => {
                if (properties === null || recommendations === null || categories === null) {
                  return <div>Loading...</div>
                }
                if (properties.length === 0) {
                  return <div>Please create a property before adding recommendations.</div>
                }
                return (
                  <RecommendationAddContainerWithData
                    {...props}
                    dispatch={dispatch}
                    onUpdateItem={onUpdateItem}
                    properties={properties}
                    categories={categories}
                    recommendations={recommendations.customizations}
                    onCreateRecommendation={onCreateItem}
                    onDeleteRecommendation={onDeleteItem}
                    cities={propertiesToCities(properties)}
                    initialTerm={content.term}
                    propertyId={match && match.params.id}
                  />
                )
              }}
            </Resource>
          )}
        </Resource>
      )}
    </Resource>
  )
}

export default withRouter(formikConnect(RecommendationAddContainer))
