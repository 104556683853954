/* eslint-disable react/display-name */
import React from 'react'
import classNames from 'classnames'
import { get, find } from 'lodash'
import {
  PROPERTIES_APPLIED,
  UPDATED_CELL,
  CREATED_CELL,
  COMPONENT_NAME,
  RECIPIENT_PROPERTY,
  COMPONENT_NICKNAME,
} from './common'
import { ListCellBadge } from '../../components/list'
import { TablePreviewImage } from '../../components/Table'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'
import { ALLOWED_DUPLICATES, ALLOWED_DEFAULT } from '../forms/content'
import { pluralize } from '../../Utils'
import { GoToLink } from '../../components'
import WelcomeVideoMetrics from '../../components/metrics/WelcomeVideoMetrics'
import { confirm } from '../../containers/modals/AlertMessageContainer'
import fallBackIcon from '../../images/media-placeholder.svg'

export const CONTENT_IMAGE = {
  id: 'image',
  Cell: ({ row }) => {
    const image =
      get(row.original, 'content.imageURL') || get(row.original, 'content.image_url') || get(row.original, 'imageURL')
    const fallbackImage = get(row.original, 'content.iconURL') || fallBackIcon

    return (
      <TablePreviewImage
        key={row.original.id}
        src={image || fallbackImage}
        contain={!image}
        fallbackIcon={fallbackImage}
      />
    )
  },
  options: {
    classNames: 'shrink',
  },
}
// TODO: Merge above and passin params
export const CONTENT_IMAGE_SMALL = {
  id: 'image',
  Cell: ({ row }) => {
    const image = get(row.original, 'content.imageURL') || get(row.original, 'content.image_url')
    const fallbackImage = get(row.original, 'content.iconURL') || fallBackIcon

    return (
      <TablePreviewImage
        key={row.original.id}
        src={image || fallbackImage}
        contain={!image}
        width={90}
        height={72}
        fallbackIcon={fallbackImage}
      />
    )
  },
  options: {
    classNames: 'shrink',
  },
}

export const PROPERTY_CONTENT_OPTIONS = {
  id: 'options',
  accessor: 'propertyId',
  Cell: ({ row, cell: { value }, initialState: { openModal, onDeleteItem, onUpdateItem, content, propertyId } }) => {
    const remove = () => {
      window.confirm(
        'Are you sure you want to delete this component? The properties applied will be reassigned to the default component.'
      ) && onDeleteItem({ ...row.original, id: `../${row.original.id}` })
    }

    const removeFromProperty = () => {
      const currentPropertyIds = row.original.propertyIds
      const newPropertyIds = currentPropertyIds.filter(id => parseInt(id, 10) !== parseInt(propertyId, 10))
      const data = {
        ...row.original,
        propertyIds: newPropertyIds,
      }

      window.confirm(`Are you sure you want to remove this ${content.item} from this property`) &&
        onUpdateItem({ ...data, id: `../${row.original.id}` })
    }

    return (
      <PopoverMenu>
        {value ? (
          <>
            <PopoverMenuItem onClick={() => openModal(row.original)}>Edit</PopoverMenuItem>
            <PopoverMenuItem onClick={remove}>Delete</PopoverMenuItem>
          </>
        ) : (
          <>
            <PopoverMenuItem className="link">
              <GoToLink to={`/content/${content.location}?edit=${row.original.id}`} text="Edit" where="Content" />
            </PopoverMenuItem>
            <PopoverMenuItem onClick={removeFromProperty}>Remove from this property</PopoverMenuItem>
          </>
        )}
      </PopoverMenu>
    )
  },
}

export const CONTENT_OPTIONS = {
  accessor: 'Options',
  disableSortBy: true,
  Cell: ({ row: { original: customization }, initialState: { openModal, onDeleteItem, onUpdateItem } }) => {
    const duplicate = () => {
      openModal({ ...customization, isDefault: false, isDuplicate: true })
    }

    const update = () => {
      openModal({ ...customization, isDefault: customization.isDefault })
    }

    const remove = () => {
      let errorMsg =
        'Are you sure you want to delete this component? The properties applied will be reassigned to the default component.'

      if (customization.type === 'checkoutNotification') {
        errorMsg = 'Are you sure you want to delete this contact?'
      }
      window.confirm(errorMsg) && onDeleteItem(customization)
    }
    const setDefault = () => {
      confirm({
        title: 'Confirmation',
        body: 'Are you sure you want to set this component as default?',
        onConfirm: () => onUpdateItem({ ...customization, isDefault: true }),
      })
    }

    const isAllowedDuplicate = ALLOWED_DUPLICATES.includes(customization.type)
    const isAllowedDefault = ALLOWED_DEFAULT.includes(customization.type) && !customization.isDefault
    return (
      !(customization.type === 'checkinSurvey' || customization.type === 'checkoutSurvey') && (!customization.isDefault || isAllowedDuplicate) && (
        <PopoverMenu>
          <PopoverMenuItem onClick={update}>Edit</PopoverMenuItem>
          {isAllowedDuplicate && <PopoverMenuItem onClick={duplicate}>Duplicate</PopoverMenuItem>}
          {!customization.isDefault && <PopoverMenuItem onClick={remove}>Delete</PopoverMenuItem>}
          {isAllowedDefault && <PopoverMenuItem onClick={setDefault}>Set As Default</PopoverMenuItem>}
        </PopoverMenu>
      )
    )
  },
}

// Table - Content Level
export const CONTENT_COLUMNS = [
  {
    id: 'content',
    columns: [
      COMPONENT_NAME,
      RECIPIENT_PROPERTY,
      UPDATED_CELL,
      CREATED_CELL,
      {
        id: 'isDefault',
        accessor: ({ isDefault }) => isDefault,
      },
      {
        id: 'propertyId',
        accessor: ({ propertyId }) => propertyId,
      },
      {
        ...CONTENT_OPTIONS,
      },
    ],
  },
]

export const CONTENT_WITH_IMAGE_COLUMNS = [
  {
    id: 'content-with-image',
    columns: [CONTENT_IMAGE, ...CONTENT_COLUMNS[0].columns],
  },
]

export const CONTENT_COLUMNS_PROPERTY_INFO = [
  {
    id: 'content',
    columns: [
      COMPONENT_NAME,
      COMPONENT_NICKNAME,
      RECIPIENT_PROPERTY,
      UPDATED_CELL,
      CREATED_CELL,
      {
        id: 'isDefault',
        accessor: ({ isDefault }) => isDefault,
      },
      {
        id: 'propertyId',
        accessor: ({ propertyId }) => propertyId,
      },
      {
        ...CONTENT_OPTIONS,
      },
    ],
  },
]

export const CONTENT_WITH_IMAGE_COLUMNS_PROPERTY_INFO = [
  {
    id: 'content-with-image',
    columns: [CONTENT_IMAGE, ...CONTENT_COLUMNS_PROPERTY_INFO[0].columns],
  },
]

export const WELCOME_VIDEO_COLUMNS = [
  {
    id: 'welcome-video',
    columns: [
      {
        id: 'image',
        Cell: ({ row: { original } }) => {
          const image = get(original, 'imageURL')
          const fallbackImage = get(original, 'content.iconURL') || fallBackIcon

          return (
            <TablePreviewImage
              key={original.id}
              src={image || fallbackImage}
              contain={!image}
              fallbackIcon={fallbackImage}
              height={137}
              width={77}
            />
          )
        },
        options: {
          classNames: 'shrink',
        },
      },
      {
        Header: 'Component Name',
        accessor: ({ title }) => title && title.toLowerCase(),
        Cell: ({ row: { original } }) => {
          const name = original && original.title
          // const isCustom = customization.propertyId && !customization.name
          // const name = isCustom ? `Custom` : customization.name
          return (
            <div className="welcome-video-title-head">
              <div className={classNames('content-name bold')}>{name}</div>
              <div>
                <WelcomeVideoMetrics welcomeVideo={original} />
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Properties',
        accessor: ({ propertyIds }) => propertyIds.length,
        Cell: ({
          row: { original },
          cell: { value },
          initialState: { propertiesCount, properties, openRecipientProperties },
          initialRows,
        }) => {
          const customPropertyId = get(original, 'propertyId')
          const customProperty = find(properties, ({ id }) => id === customPropertyId)
          const propertyName = get(customProperty, 'nickname') || get(customProperty, 'addressLine1') || 'this property'
          const text = customPropertyId
            ? `Custom for ${propertyName}`
            : `${value} of ${propertiesCount} ${propertiesCount > 1 ? 'properties' : 'property'}`
          return original.propertyIds ? (
            <div
              className="link-text"
              onClick={event => {
                openRecipientProperties(original, event)
                event.stopPropagation()
              }}>
              {`${original.propertyIds ? original.propertyIds.length : 0} of ${properties.length} ${pluralize(
                properties.length,
                'property'
              )}`}
            </div>
          ) : (
            <div>
              {`${original.propertyIds.length} of ${properties.length} ${pluralize(properties.length, 'property')}`}
            </div>
          )
        },
        options: {
          hideOn: ['mobile'],
        },
      },
      {
        Header: 'Status',
        accessor: ({ status }) => (status ? 'enabled' : 'disabled'),
        Cell: ({ cell: { value } }) => {
          return (
            <div
              className={classNames(
                'capitalize text-color',
                { success: value === 'enabled' },
                { danger: value === 'disabled' }
              )}>
              {value}
            </div>
          )
        },
      },
      {
        Header: 'Duration',
        accessor: ({ duration }) => duration,
        Cell: ({ cell: { value } }) => {
          const minutes = Math.floor(value / 60)
          const seconds = value - minutes * 60
          const time = (minutes ? `${minutes} min ` : '') + (seconds ? `${seconds} sec` : '')
          return <div>{time}</div>
        },
        options: {
          hideOn: ['mobile'],
        },
      },
      {
        Header: 'Size',
        accessor: ({ size }) => `${size || 0} mb`,
        Cell: ({ cell: { value } }) => {
          return <div>{value}</div>
        },
        options: {
          hideOn: ['mobile'],
        },
      },
      UPDATED_CELL,
      CREATED_CELL,
      {
        id: 'isDefault',
        accessor: ({ isDefault }) => isDefault,
      },
      {
        id: 'propertyId',
        accessor: ({ propertyId }) => propertyId,
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original }, initialState: { onUpdateItem, onDeleteItem, openModal } }) => {
          const update = () => openModal(original)
          const toggle = () => {
            original.status
              ? confirm({
                  title: 'Confirmation',
                  body: `Are you sure you want to Disable this video? ${
                    original.propertyIds && original.propertyIds.length
                      ? 'The selected properties will have no welcome video to show after this change.'
                      : ''
                  }`,
                  onConfirm: () => onUpdateItem({ ...original, status: !original.status }),
                })
              : onUpdateItem({ ...original, status: !original.status })
          }
          const remove = () => {
            confirm({
              title: 'Confirmation',
              body: `Are you sure you want to Delete this video? ${
                original.propertyIds && original.propertyIds.length
                  ? 'The selected properties will have no welcome video to show after this change and the component will no longer exist.'
                  : ''
              }`,
              onConfirm: () => onDeleteItem(original),
            })
          }
          return (
            <PopoverMenu>
              <PopoverMenuItem onClick={update}>Edit</PopoverMenuItem>
              <PopoverMenuItem onClick={toggle}>{original.status ? 'Disable' : 'Enable'}</PopoverMenuItem>
              <PopoverMenuItem onClick={remove}>Delete</PopoverMenuItem>
            </PopoverMenu>
          )
        },
        options: {
          classNames: 'right',
        },
      },
    ],
  },
]

const getPropertyCount = (row, currentRow, currentCount, propertiesCount) => {
  const {
    isDefault,
    content: { status },
  } = currentRow
  if (isDefault) {
    for (const index in row) {
      const {
        original: {
          id,
          content: { status },
          propertyIds,
          isDefault,
        },
      } = row[index]
      if (!status && !isDefault) {
        currentCount += propertyIds.length
      }
    }
  } else if (!status) {
    return '-'
  }
  return `${currentCount} of ${propertiesCount}`
}

export const WELCOME_SCREEN_COLUMNS = [
  {
    id: 'welcome-screen',
    columns: [
      {
        id: 'image',
        Cell: ({ row, cell: { value } }) => {
          const image =
            get(row.original, 'content.background') ||
            'https://nec-assets.s3.amazonaws.com/uploaded-image/1a7b2c2c-ca51-4a62-9be5-99a1fcddcec5_picture.jpg'
          return <TablePreviewImage key={row.original.id} src={image} contain={!image} height={137} width={77} />
        },
        options: {
          classNames: 'shrink',
        },
      },
      {
        Header: 'Component Name',
        accessor: ({ name }) => name && name.toLowerCase(),
        Cell: ({ row: { original } }) => {
          const name = original && original.name
          return (
            <div className={classNames('content-name bold')}>
              {name}
              {original.isDefault && <ListCellBadge label="Default" />}
            </div>
          )
        },
      },
      {
        Header: 'Properties',
        accessor: ({ propertyIds }) => propertyIds.length,
        Cell: ({
          row: { original },
          cell: { value },
          initialState: { propertiesCount, properties, openRecipientProperties },
          initialRows,
        }) => {
          const customPropertyId = get(original, 'propertyId')
          const customProperty = find(properties, ({ id }) => id === customPropertyId)
          const propertyName = get(customProperty, 'nickname') || get(customProperty, 'addressLine1') || 'this property'
          const text = customPropertyId
            ? `Custom for ${propertyName}`
            : `${value} of ${propertiesCount} ${propertiesCount > 1 ? 'properties' : 'property'}`
          return (
            <div
              className="link-text"
              onClick={event => {
                openRecipientProperties(original, event)
                event.stopPropagation()
              }}>
              {`${original.propertyIds ? original.propertyIds.length : 0} of ${properties.length} ${pluralize(
                properties.length,
                'property'
              )}`}
            </div>
          )
        },
        options: {
          hideOn: ['mobile'],
        },
      },
      {
        Header: 'Status',
        accessor: ({ content, isDefault }) => {
          return (content && content.status) || isDefault ? 'enabled' : 'disabled'
        },
        Cell: ({ cell: { value } }) => {
          return (
            <div
              className={classNames(
                'capitalize text-color',
                { success: value === 'enabled' },
                { danger: value === 'disabled' }
              )}>
              {value}
            </div>
          )
        },
      },
      UPDATED_CELL,
      {
        id: 'isDefault',
        accessor: ({ isDefault }) => isDefault,
      },
      {
        id: 'propertyId',
        accessor: ({ propertyId }) => propertyId,
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original }, initialState: { onUpdateItem, onDeleteItem, openModal } }) => {
          const toggle = () => {
            original.content.status
              ? confirm({
                  title: 'Confirmation',
                  body: `Are you sure you want to Disable this screen? ${
                    original.propertyIds && original.propertyIds.length
                      ? 'The selected properties will have the default welcome screen displayed after this change.'
                      : ''
                  }`,
                  onConfirm: () =>
                    onUpdateItem({ ...original, content: { ...original.content, status: !original.content.status } }),
                })
              : onUpdateItem({ ...original, content: { ...original.content, status: !original.content.status } })
          }
          const update = () => openModal(original)
          const remove = () => {
            confirm({
              title: 'Confirmation',
              body: `Are you sure you want to Delete this screen? ${
                original.propertyIds && original.propertyIds.length
                  ? 'The selected properties have the default welcome screen displayed after this change and the component will no longer exist.'
                  : ''
              }`,
              onConfirm: () => onDeleteItem(original),
            })
          }
          const setDefault = () => {
            confirm({
              title: 'Confirmation',
              body: 'Are you sure you want to set this screen as default?',
              onConfirm: () => onUpdateItem({ ...original, isDefault: true }),
            })
          }
          if (!original.isDefault) {
            return (
              <PopoverMenu>
                <PopoverMenuItem onClick={toggle}>
                  {original.content && original.content.status ? 'Disable' : 'Enable'}
                </PopoverMenuItem>
                <PopoverMenuItem onClick={update}>Edit</PopoverMenuItem>
                <PopoverMenuItem onClick={remove}>Delete</PopoverMenuItem>
                <PopoverMenuItem onClick={setDefault}>Set As Default</PopoverMenuItem>
              </PopoverMenu>
            )
          }
          return null
        },
        options: {
          classNames: 'right',
        },
      },
    ],
  },
]

export const CHECKIN_CHECKOUT_COLUMNS = [
  {
    id: 'content',
    columns: [
      COMPONENT_NAME,
      PROPERTIES_APPLIED,
      UPDATED_CELL,
      {
        Header: 'Notifications',
        Cell: ({ row: { original: customization } }) => {
          return (
            <div>
              {customization.checkin && (
                <small>
                  <span className="bold">Check In: </span>
                  {customization.setting?.checkin_notify_rating?.sort().join(', ')}
                </small>
              )}
              {customization.checkout && (
                <small>
                  <span className="bold">Check Out: </span>
                  {customization.setting?.checkout_notify_rating?.sort().join(', ')}
                </small>
              )}
            </div>
          )
        },
      },
      CREATED_CELL,
      {
        id: 'isDefault',
        accessor: ({ isDefault }) => isDefault,
      },
      {
        id: 'propertyId',
        accessor: ({ propertyId }) => propertyId,
      },
      {
        ...CONTENT_OPTIONS,
      },
    ],
  },
]
