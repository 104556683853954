import React from 'react'
import Icon from './Icon'

function AddIcon(props) {
  return (
    <Icon viewBox="0 0 12 12" {...props} width="1em" height="1em">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.667 0a1 1 0 0 0-1 1v3.667H1a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h3.667V11a1 1 0 0 0 1 1h.666a1 1 0 0 0 1-1V7.333H11a1 1 0 0 0 1-1v-.666a1 1 0 0 0-1-1H7.333V1a1 1 0 0 0-1-1h-.666z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default AddIcon
