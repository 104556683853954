/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { HomeFillIcon } from '@primer/octicons-react'
import SavingToast from './components/SavingToast'
import Pill from './components/Pill'
import './styles/Navigation.scss'
import { thirdPartyAnalytics } from './services'
import { EVENTS } from './constants/thirdPartyAnalytics'
import {
  Announcement,
  PropertyIcon,
  SettingIcon,
  ServiceIcon,
  SlideshowIcon,
  ChartIcon,
  Logo,
  SlideshowNew,
} from './icons'
import { ExpressServerRoot } from './constants'
import { hasAccessTo } from './resources/features'
import MobileNavigation from './components/app/MobileNavigation'
import HamburgerIcon from './icons/HamburgerIcon'
// import LogoGraphic from './icons/LogoGraphics'
import LogoOnly from '../src/images/logo-graphic-only.svg'
import { toggleNavigationView } from './reducers/config'
import moment from 'moment'
import { TIME_OPTIONS } from './constants'

const NAV_ITEMS = [
  {
    path: '/home',
    label: 'Home',
    icon: HomeFillIcon,
  },
  {
    path: '/properties',
    label: 'Properties',
    icon: PropertyIcon,
  },
  {
    path: '/content',
    label: 'Content',
    icon: SlideshowIcon,
  },
  {
    path: '/announcements',
    label: 'Announcements',
    icon: Announcement,
  },
  {
    path: '/services',
    label: 'Services',
    icon: ServiceIcon,
  },
  {
    path: '/slideshow',
    label: 'Slideshow / Ads',
    icon: SlideshowNew,
  },
  {
    path: '/analytics',
    label: 'Analytics',
    icon: ChartIcon,
  },
  {
    path: '/settings',
    label: 'Settings',
    icon: SettingIcon,
  },
  // todo: uncomment this when we have features that will be whitelisted
  // {
  //   path: '/admin',
  //   label: 'Admin',
  //   featureKey: 'admin',
  //   icon: ProfileIcon,
  // },
]

export const Top = ({ me, canSwitchOrgs, onSwitchOrgs, children, dispatch }) => {
  const analyticsProperties = {
    organization: me && me.organization && me.organization.name,
    user: me && `${me.firstName} ${me.lastName}`,
    pm_id: me.email,
    date_of_logout: `${new Date().toLocaleDateString('en-US', TIME_OPTIONS)} `,
    time_of_logout: new Date().toLocaleTimeString(),
  }

  const recordAnalytics = event => {
    const updatedProperties = { ...analyticsProperties }
    thirdPartyAnalytics.track(event, updatedProperties)
  }

  return (
    <nav className="top-navbar">
      <MobileNavigation navItems={NAV_ITEMS}>{children}</MobileNavigation>
      <div className="property-account">
        <span onClick={() => dispatch(toggleNavigationView())}>
          <HamburgerIcon />
        </span>
        {me.organization && me.organization.name}
      </div>
      <div className="toast-wrapper">
        <SavingToast />
      </div>
      <div className="nav-dropdown">
        <UncontrolledDropdown>
          <DropdownToggle nav caret data-cy="dropdown-nav-menu">
            <div className="email">{me.email}</div>
          </DropdownToggle>
          <DropdownMenu right>
            {me.organization && <DropdownItem disabled>{me.organization.name}</DropdownItem>}
            {me.organization && me.organization.passcode && (
              <div style={{ padding: '.25rem 1.5rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '16px 0',
                  }}>
                  <Pill invert column metaText="Device Passcode">
                    {me.organization.passcode}
                  </Pill>
                </div>
              </div>
            )}
            {canSwitchOrgs && <DropdownItem divider />}
            {canSwitchOrgs && (
              <a onClick={onSwitchOrgs}>
                <DropdownItem data-cy="btn-nav-logout">Switch Organizations...</DropdownItem>
              </a>
            )}
            {process.env.REACT_APP_STOREFRONT_URL && (
              <>
                <DropdownItem divider />
                <a
                  href={`${process.env.REACT_APP_STOREFRONT_URL}/nec/editprofile`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => recordAnalytics(EVENTS.ACCOUNT_AND_BILLING)}>
                  <DropdownItem>Account & Billing</DropdownItem>
                </a>
              </>
            )}
            {me.organization && <DropdownItem divider />}
            <div style={{ marginLeft: '25px' }}>
              Support
              <div className="navbar-link" style={{ marginTop: '8px', marginBottom: '8px' }}>
                <a href="https://knowledgebase.guestviewguide.com/resources" target="_blank" rel="noopener noreferrer">
                  FAQ and Resources
                </a>
              </div>
              <div className="navbar-link">help@guestviewguide.com</div>
              <div className="navbar-link">9am-5pm PST Mon-Fri</div>
            </div>
            <DropdownItem divider />
            <Link
              to="/password-change"
              onClick={() => {
                recordAnalytics(EVENTS.CHANGE_PASSWORD)
              }}>
              <DropdownItem>Change Password</DropdownItem>
            </Link>
            <DropdownItem divider />

            <Link
              onClick={() => {
                localStorage.clear()
                recordAnalytics(EVENTS.SIGN_OUT)
                window.location.href = `${ExpressServerRoot}/logout`
              }}>
              <DropdownItem> Sign out</DropdownItem>
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </nav>
  )
}

export const Left = ({ children, me }) => {
  return (
    <>
      <div className="branding">
        <Logo />
        <img src={LogoOnly} />
      </div>
      <nav className="sidebar">
        <ul className="nav-items">
          {NAV_ITEMS.filter(({ featureKey }) => {
            if (featureKey) {
              return hasAccessTo(featureKey, me)
            }
            return true
          }).map(tab => {
            return (
              <li key={tab.path} className="nav-item">
                <NavLink className="nav-item-link" to={tab.path}>
                  <div className="icon-wrapper">
                    <tab.icon size={28} />
                  </div>
                  <strong>{tab.label}</strong>
                </NavLink>
              </li>
            )
          })}
        </ul>
        {children}
      </nav>
    </>
  )
}

export const LeftSuperAdmin = () => {
  return (
    <>
      <div className="branding">
        <Logo />
      </div>
    </>
  )
}
