import { get, isEmpty, isNumber } from 'lodash'
import moment from 'moment'
import { duration } from 'moment-duration-format'
import { RRule } from 'rrule'
// eslint-disable-next-line no-unused-vars

export function asyncDelay(delay, callback = () => {}) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      callback()
      resolve()
    }, delay)
  })
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export function setDisplayName(Hoc, WrappedComponent) {
  Hoc.displayName = `${getDisplayName(Hoc)}(${getDisplayName(WrappedComponent)})`
}

export function pluralize(count, singular, plural, returnCount) {
  const prefix = returnCount ? `${count} ` : ''
  const vowels = ['a', 'e', 'i', 'o', 'u']
  if (count === 1) {
    // If count is equal to 1 or zero then singular else plural
    return prefix + singular
  }
  if (plural) {
    return prefix + plural
  }
  const lastCharacter = singular.length - 1
  const secondLastCharacter = singular.length - 2
  if (singular[lastCharacter] === 'y' && !vowels.includes(singular[secondLastCharacter])) {
    return `${prefix}${singular.substr(0, lastCharacter)}ies`
  }
  return `${prefix}${singular}s`
}

export const getFirst = (obj, paths, defaultValue) => {
  for (let i = 0; i < paths.length; i += 1) {
    const path = paths[i]
    const value = get(obj, path)
    if (!isEmpty(value)) {
      return value
    }
  }
  return defaultValue
}

export const formatters = {
  address: (value, mapping = {}) => {
    mapping = {
      address_1: 'addressLine1',
      address_2: 'addressLine2',
      city: 'addressCity',
      state: 'addressState',
      zipcode: 'addressZIP',
      ...mapping,
    }
    const empty = v => get(v) && get(v).length && get(v) !== 'null'
    const get = v => value[mapping[v] || v]
    const output = []
    output.push(
      ['address_1', 'address_2']
        .filter(empty)
        .map(get)
        .join(' '),
    )
    output.push(
      `${['city', 'state']
        .filter(empty)
        .map(get)
        .join(', ')
        .trim()} ${['zipcode']
        .filter(empty)
        .map(get)
        .join(' ')
        .trim()}`,
    )
    return output
  },
  count: value => (isNumber(value) ? `(${value})` : null),
  ccode: value => `C${value}`,
}

export const sortAddress = (address1, address2) => {
  // Get first line of each address to sort
  const first = address1.addressLine1
  const second = address2.addressLine1
  // convert the address to only the numbers within the string
  const numbersOfAddress1 = first.match(/\d+/g).map(Number)
  const numbersOfAddress2 = second.match(/\d+/g).map(Number)
  // get the leading digit of the address
  const leadingDigitAddress1 = Number(numbersOfAddress1.toString(numbersOfAddress1[0]).substring(0, 1))
  const leadingDigitAddress2 = Number(numbersOfAddress2.toString(numbersOfAddress2[0]).substring(0, 1))
  // if the leading digits are equal, sort by the full number
  if (leadingDigitAddress1 === leadingDigitAddress2) {
    return numbersOfAddress1[0] - numbersOfAddress2[0]
  }
  return leadingDigitAddress1 - leadingDigitAddress2
}

export const getSortableAddress = property => {
  const address = formatters.address(property)
  const [firstLine, secondLine] = address
  const nickName = get(property, 'nickname', '')
  return `${nickName ? `${nickName} ` : ''}${firstLine} ${secondLine}`
}

const FEE_TYPES = {
  fixed: '',
  hourly: 'hourly',
  daily: 'daily',
  free: 'Free',
}

const CURRENCIES = {
  US: '$',
}

export const formatPrice = ({
 price, feeType, fee, currency,
}, amount) => {
  const currencySymbol = get(CURRENCIES, currency, CURRENCIES.US)
  const priceType = get(FEE_TYPES, feeType, FEE_TYPES.fixed)

  // priority:
  // amount > price > fee
  // amount is to compute (quantity * price) for service requests
  if (isNumber(amount) || isNumber(price)) {
    const decimalPoint = 2
    return {
      price: Number((amount || price) / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPoint,
        maximumFractionDigits: decimalPoint,
      }),
      priceType,
    }
  }

  if (feeType && fee && isNumber(parseFloat(fee, 10))) {
    return {
      price: `${currencySymbol}${fee}`,
      priceType,
    }
  }

  // TODO old crappy services
  return {
    price: fee,
    priceType,
  }
}

// For every reservation the property has, check if it ends today or later
// If the property has a reservation that ends today or later, do not display the empty state
export const hasNoReservations = reservations => {
  let allReservationsOver = true
  const now = moment()

  reservations.forEach(reservation => {
    const checkout = moment(reservation.endDate).set({
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
    if (checkout.isSameOrAfter(now)) {
      allReservationsOver = false
    }
  })
  // return true if there are no reservations that end today or later
  return allReservationsOver
}

export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export function getUserReadableDuration(minute) {
  if (minute < 60) {
    return moment.duration(minute, 'minutes').format('m [minutes]')
  }
  if (minute === 60) {
    return moment.duration(minute, 'minutes').format('h [hour]')
  }
  if (minute > 60) {
    return moment.duration(minute, 'minutes').format('h [hour]', 1)
  }
  return ''
}

export function formatDuration(minute) {
  const label = getUserReadableDuration(minute)
  return { [minute]: label }
}

export function getMinMaxPrices(serviceChoices) {
  if (!serviceChoices.length > 0) return null
  const allPrices = []
  serviceChoices.map(choice => choice.feeRates.map(fr => fr.price && allPrices.push(Number(fr.price))))

  if (!allPrices.length) return

  const { price: maxPrice } = formatPrice({ currency: 'US', price: Math.max(...allPrices) })
  const { price: minPrice } = formatPrice({ currency: 'US', price: Math.min(...allPrices) })
  return { maxPrice: maxPrice && maxPrice !== minPrice ? maxPrice : null, minPrice }
}

export function ordinalDate(date) {
  const j = date % 10
  const k = date % 100
  if (j === 1 && k !== 11) {
    return `${date}st`
  }
  if (j == 2 && k != 12) {
    return `${date}nd`
  }
  if (j == 3 && k != 13) {
    return `${date}rd`
  }
  return `${date}th`
}

export function weekdayString(days) {
  const weekdays = [
    { value: 'SU', label: 'Sun' },
    { value: 'MO', label: 'Mon' },
    { value: 'TU', label: 'Tues' },
    { value: 'WE', label: 'Wed' },
    { value: 'TH', label: 'Thurs' },
    { value: 'FR', label: 'Fri' },
    { value: 'SA', label: 'Sat' },
  ]
  const labels = []
  weekdays.map(day => {
    const { value, label } = day
    if (days.includes(RRule[value])) {
      labels.push(label)
    }
  })
  const str = `${labels.slice(0, -1).join(', ')} and ${labels.slice(-1)}`
  return str
}
