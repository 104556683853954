/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { capitalize, get } from 'lodash'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { EmptyNavItemPlaceholder } from '../../components/PageStates'

import { openModalWithContainer } from '../../reducers/modals'
import Resource from '../../Resource'
import AdminIcon from '../../icons/AdminIcon'
import { MasterList, ListActions, AddButton } from '../../components/list'

import RoleHAU from '../../components/tabHostsAndUsers/RoleHAU'
import PhoneHAU from '../../components/tabHostsAndUsers/PhoneHAU'
import EmailHAU from '../../components/tabHostsAndUsers/EmailHAU'
import JoinedHAU from '../../components/tabHostsAndUsers/JoinedHAU'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'
import { MESSAGES } from '../../constants/strings'

const alignCellTextMiddle = {
  options: {
    classNames: 'middle',
  },
}

const COLUMNS = [
  {
    id: 'admins',
    columns: [
      {
        Header: MESSAGES.NAME,
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }) => {
          return (
            <div className="title-cell">
              <div className="first-line" style={{ marginBottom: 0 }}>{`${firstName} ${lastName}`}</div>
            </div>
          )
        },
        ...alignCellTextMiddle,
      },
      {
        Header: MESSAGES.EMAIL,
        accessor: 'email',
        Cell: ({
          row: {
            original: { firstName, email },
          },
        }) => <EmailHAU firstName={firstName} email={email} />,
        options: {
          classNames: 'middle',
          hideOn: ['mobile'],
        },
      },
      {
        Header: MESSAGES.MOBILE,
        accessor: 'phone',
        Cell: ({
          row: {
            original: { phone },
          },
        }) => <PhoneHAU phone={phone} />,
        options: {
          classNames: 'middle',
          hideOn: ['mobile'],
        },
      },
      {
        Header: MESSAGES.ROLE,
        id: 'role',
        accessor: ({ role }) => capitalize(role),
        Cell: ({
          cell: { value },
          row: {
            original: { firstName },
          },
        }) => <RoleHAU firstName={firstName} role={value} />,
        options: {
          classNames: 'middle',
          hideOn: ['mobile'],
        },
      },
      {
        Header: MESSAGES.JOINED,
        id: 'createdAt',
        accessor: ({ createdAt }) => moment(createdAt),
        Cell: ({
          cell: { value },
          row: {
            original: { firstName },
          },
        }) => <JoinedHAU firstName={firstName} date={value} />,
        ...alignCellTextMiddle,
      },
      {
        Header: MESSAGES.LAST_LOGGED_IN,
        id: 'lastLoggedIn',
        accessor: ({ lastLoggedIn }) => (lastLoggedIn ? moment(lastLoggedIn) : null),
        Cell: ({ cell: { value } }) => <div className="title-cell">{value ? value.format('LLL') : 'N/A'}</div>,
        ...alignCellTextMiddle,
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({
          row: { original: admin },
          initialState: { rowAction, onDeleteItem, onUpdateInvite, onDeleteInvite, me },
        }) => {
          const ROLES = {
            MANAGER: {
              label: 'manager',
              access: 'sign in and manage properties.',
            },
          }

          const DEFAULT_ROLE = {
            label: `${admin.role} user`,
            access: 'access the Display Passcode needed to manage the GuestView Guide Display.',
          }

          const deleteMessage = ({ label, access }) =>
            `Are you sure you want to delete this ${label.toLowerCase()}? They will no longer be able to ${access}`

          // {admin.email !== me.email && edit | delete
          // In previous table, what is the purpose of this functionality ??

          const edit = () => rowAction(admin)
          const remove = () =>
            window.confirm(deleteMessage(get(ROLES, admin.role, DEFAULT_ROLE))) && onDeleteItem(admin)

          const resendInvitaton = () => onUpdateInvite({ ...admin, updatedAt: moment() })
          const deleteInvitation = () =>
            window.confirm(deleteMessage(get(ROLES, admin.role, DEFAULT_ROLE))) && onDeleteInvite(admin)

          let tabAdminOptions = {
            option1: MESSAGES.EDIT,
            option2: MESSAGES.DELETE,
            function1: edit,
            function2: remove,
          }

          if (!admin.firstName) {
            tabAdminOptions = {
              option1: MESSAGES.RESEND,
              option2: MESSAGES.DELETE,
              function1: resendInvitaton,
              function2: deleteInvitation,
            }
          }
          return (
            <PopoverMenu data-cy="user-options">
              {admin.role !== 'MAINTENANCE' && (
                <PopoverMenuItem data-cy="user-option-edit" onClick={tabAdminOptions.function1}>
                  {tabAdminOptions.option1}
                </PopoverMenuItem>
              )}
              {me.email !== admin.email && (
                <PopoverMenuItem data-cy="user-option-remove" onClick={tabAdminOptions.function2}>
                  {tabAdminOptions.option2}
                </PopoverMenuItem>
              )}
            </PopoverMenu>
          )
        },
        options: {
          classNames: 'middle-right',
        },
      },
    ],
  },
]

const TabAdminTable = ({
  admins,
  me,
  invites,
  dispatch,
  onUpdateItem,
  onDeleteItem,
  onUpdateInvite,
  onDeleteInvite,
}) => {
  const tableData = [...admins, ...invites]

  const rowAction = admin => {
    if (!admin.firstName) {
      return
    }

    if (admin.role === 'MAINTENANCE') {
      return
    }
    dispatch(
      openModalWithContainer('AdminContainer', {
        onSave: onUpdateItem,
        admin,
        me,
      })
    )
  }

  const initialState = {
    me,
    rowAction,
    onUpdateItem,
    onDeleteItem,
    onUpdateInvite,
    onDeleteInvite,
  }
  const memoizedColumns = useMemo(() => COLUMNS, [])
  return (
    <MasterList
      columns={memoizedColumns}
      data={tableData}
      rowAction={rowAction}
      initialState={initialState}
      data-cy="users-table"
    />
  )
}

const TabAdmin = () => {
  return (
    <div>
      <h2>{MESSAGES.MANAGE_USERS}</h2>
      <Resource collection path={['users', 'admin']} placeholder={{}}>
        {(admin, { onRefresh }) => (
          <Resource collection path={['users', 'invite']} placeholder={[]}>
            {(invites, { onCreateItem, onUpdateItem: onUpdateInvite, onDeleteItem: onDeleteInvite, dispatch }) => {
              const inviteNewUser = () => {
                dispatch(
                  openModalWithContainer('AdminContainer', {
                    admin: {},
                    onSave: onCreateItem,
                    onSuccess: onRefresh,
                  })
                )
              }
              return (
                <div className="settings-section">
                  <ListActions>
                    <AddButton item="New User" verb="" onClick={inviteNewUser} />
                  </ListActions>
                  <Resource collection path={['users', 'admin']} placeholder={[]}>
                    {(admins, { onUpdateItem, onDeleteItem }) => (
                      <>
                        {admins.length === 0 ? (
                          <EmptyNavItemPlaceholder
                            icon={<AdminIcon height="72" width="72" />}
                            navItem="an administrator"
                          />
                        ) : (
                          <Resource collection path={['me']} placeholder={{}} key={admin.id}>
                            {me => (
                              <TabAdminTable
                                admins={admins}
                                me={me}
                                invites={invites}
                                dispatch={dispatch}
                                onUpdateItem={onUpdateItem}
                                onDeleteItem={onDeleteItem}
                                onUpdateInvite={onUpdateInvite}
                                onDeleteInvite={onDeleteInvite}
                              />
                            )}
                          </Resource>
                        )}
                      </>
                    )}
                  </Resource>
                </div>
              )
            }}
          </Resource>
        )}
      </Resource>
    </div>
  )
}

export default withRouter(TabAdmin)
