import React, { Fragment } from 'react'
import Progress from '../Progress'
import { ModalBody, ModalFooter } from '../modal'
import { AlertIcon } from '../../icons'
import { MESSAGES } from '../../constants/strings'

const ProgressContainer = ({
 tasks, progressPath, closeModal, serviceKey = 'Escapia',
}) => {
  return (
    <Fragment>
      <ModalBody>
        <div className="properties-progress-container">
          <Progress {...tasks[progressPath]} />
          <div className="white-content-box properties-progress-message-container">
            <div className="icon">
              <AlertIcon />
            </div>
            <div className="message">
              <p>
                Getting property information from
                {' '}
                {serviceKey}
                {' '}
                may take several minutes. You can close this window.
                Check back on the Properties page to see when properties are ready to be chosen.
              </p>
              <p>
                If property information is not being retrieved, check the permissions on
                {' '}
                {serviceKey}
                {' '}
                to make sure they
                are set correctly.
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary btn-small" onClick={closeModal}>
          {MESSAGES.OK}
        </button>
      </ModalFooter>
    </Fragment>
  )
}

export default ProgressContainer
