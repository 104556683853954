import React from 'react'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'
import '../../styles/AnalyticsContainer.scss'
import { pluralize } from '../../Utils'
import { StatBar } from '../../components'
import { MESSAGES } from '../../constants/strings'

const MetricsHeader = ({ params }) => (
  <Resource collection path={['analytics', 'header', params]} placeholderComponent={Loading}>
    {({
 guestStays, usage, pairedPropertiesCount, singleProperty,
}) => {
      const stats = [
        {
          label: pluralize(pairedPropertiesCount, MESSAGES.PAIRED_PROPERTY),
          value: pairedPropertiesCount,
          hide: singleProperty,
        },
        { label: MESSAGES.GUEST_STAYS, value: guestStays },
        { label: MESSAGES.OF_RESERVATION_LABEL, value: usage, suffix: '%' },
      ].filter(stat => !stat.hide)
      return (
        <div className="MetricsHeader">
          <StatBar stats={stats} />
        </div>
      )
    }}
  </Resource>
)

export default MetricsHeader
