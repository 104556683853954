import React from 'react'
import { connect } from 'react-redux'
import { MESSAGES } from '../../constants/strings'
import { RightArrowIcon } from '../../icons'
import '../../styles/CustomWelcomePreview.scss'

const CustomWelcomePreview = ({
 values: {
 background, logo, text, showWifiInfo,
}, orgName,
}) => {
  return (
    <div className="CustomWelcomePreview card shadow-sm rounded">
      <div className="preview-title">{MESSAGES.PREVIEW}</div>
      <div className="device" style={{ background: `url(${background})` }}>
        {showWifiInfo && <div className="wifi-container example-text">{MESSAGES.WIFI_INFO_BRACKET}</div>}
        <div className="logo">
          {logo ? (
            <img className="logo-img" src={logo} alt="preview-logo" />
          ) : (
            <div className="example-text org-name-center">{orgName}</div>
          )}
        </div>
        <div className="guest-name-container example-text">
          <div className="guest-name">{MESSAGES.GUEST_NAME}</div>
        </div>
        <div className="welcome-text">{text}</div>
        <div className="checkin">
          <div className="checkin-button">
            <RightArrowIcon />
          </div>
          {MESSAGES.TAP_TO_CHECKIN}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    orgName: state.data.me.organization.name,
  }
}

export default connect(mapStateToProps)(CustomWelcomePreview)
