import ContactHostMessageField from '../../components/contact-host/ContactHostMessageField'
import ContactRequestTopicsFields from '../../components/contact-host/ContactRequestTopicsFields'
import { Yup } from '../../forms'
import {
  CheckboxField, ContactToggleField, SelectPropertiesField, AutoCompleteField,
} from '../../forms/fields'
import { COMMONS_FIELDS, DEFAULT_MESSAGE_FOR_REQUESTS_OFF, DEFAULT_MESSAGE_FOR_REQUESTS_ON } from './content'

export const CONTACT_HOST_WIZARD = ({ readOnly, saveOnExit, contactHostOptions }) => ({
  0: [
    {
      ...COMMONS_FIELDS.name,
      props: {
        ...COMMONS_FIELDS.name.props,
        readOnly,
        saveOnExit,
        fast: false,
      },
    },
    {
      label: 'Allow Requests',
      name: 'requestEnabled',
      component: CheckboxField,
      validation: Yup.boolean().default(true),
      props: {
        toggleSwitch: true,
        onOff: true,
        fast: false,
        saveOnExit,
        readOnly,
        onComplete: async (form, requestEnabled) => {
          await form.setFieldValue(
            'content.message',
            requestEnabled ? DEFAULT_MESSAGE_FOR_REQUESTS_ON : DEFAULT_MESSAGE_FOR_REQUESTS_OFF,
          )
        },
      },
    },
    {
      label: 'Message',
      name: 'message',
      component: ContactHostMessageField,
      validation: Yup.string().default(DEFAULT_MESSAGE_FOR_REQUESTS_ON),
      disabled: ({ values }) => !values.content.requestEnabled || readOnly,
      props: {
        maxLength: values => (values?.content?.requestEnabled ? 240 : 700),
        fast: false,
        rows: 4,
        saveOnExit,
        readOnly,
      },
    },
    {
      label: 'Allow Urgent Requests',
      name: 'urgentEnabled',
      component: CheckboxField,
      validation: Yup.boolean().default(true),
      disabled: ({ values }) => !values.content.requestEnabled || readOnly,
      props: {
        toggleSwitch: true,
        onOff: true,
        fast: false,
        saveOnExit,
        readOnly,
      },
    },
    {
      // component: ContactList,
      name: 'contacts',
      label: 'Contact',
      type: 'fieldArray',
      maxLength: 10,
      shouldCollapse: true,
      headerIdentifier: 'contactName',
      addTitle: 'another contact and topic',
      customAddButton: null,
      disabled: values => !values.content.requestEnabled || readOnly,
      hideDeleteIconOnFirstIndex: true,
      saveOnExit,
      validation: Yup.array()
        .when('requestEnabled', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              contactName: Yup.string().required('Required'),
              contactEmail: Yup.string().atLeastOne(['contactEmail', 'contactPhone']),
              contactPhone: Yup.string()
                .phone()
                .label('phone')
                .atLeastOne(['contactEmail', 'contactPhone'])
                .nullable(),
              topics: Yup.array()
                .of(
                  Yup.object().shape({
                    text: Yup.string().required('Topic title is required'),
                    iconURL: Yup.string(),
                  }),
                )
                .default(() => [])
                .required('Minimum one topic is required'),
            }),
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              contactName: Yup.string().nullable(),
              contactEmail: Yup.string().nullable(),
              contactPhone: Yup.string().nullable(),
              topics: Yup.array()
                .of(
                  Yup.object().shape({
                    text: Yup.string(),
                    iconURL: Yup.string(),
                  }),
                )
                .default(() => []),
            }),
          ),
        })
        .default([
          {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            topics: [
              {
                text: '',
                iconURL: '',
              },
            ],
          },
        ]),
      component: [
        {
          label: 'Contact Name',
          name: 'contactName',
          component: AutoCompleteField,
          disabled: ({ values }) => !values.content.requestEnabled || readOnly,
          required: ({ values }) => values.content.requestEnabled,
          props: {
            ...COMMONS_FIELDS.name.props,
            placeholder: 'John Doe',
            readOnly,
            fast: false,
            saveOnExit,
            options: contactHostOptions,
            onChange: async (value, field, form) => {
              const fieldPath = field.name.split('.', field.name.split('.').length - 1).join('.')
              if (typeof value === 'string') {
                await form.setFieldValue(`${fieldPath}.contactName`, value)
              } else {
                await form.setFieldValue(`${fieldPath}.contactName`, value.contactName)
                await form.setFieldValue(`${fieldPath}.contactPhone`, value.contactPhone || '')
                await form.setFieldValue(`${fieldPath}.contactEmail`, value.contactEmail || '')
                await form.setFieldValue(`${fieldPath}.id`, value.id || null)
              }
              saveOnExit && form.handleSubmit()
            },
          },
        },
        {
          label: 'Contact Information',
          component: ContactToggleField,
          name: ['contactPhone', 'contactEmail'],
          disabled: ({ values }) => !values.content.requestEnabled || readOnly,
          required: ({ values }) => values.content.requestEnabled,
          props: {
            toggleSwitch: true,
            phoneLabel: 'Mobile Phone Number',
            readOnly,
            saveOnExit,
          },
        },
        {
          name: 'topics',
          component: ContactRequestTopicsFields,
          additionalFieldClass: 'col-sm-12',
          disabled: values => !values.content.requestEnabled || readOnly,
          props: {
            required: values => values.content.requestEnabled,
            // maxItems: 10,
            maxLength: 45,
            skipBlurMode: true,
            readOnly,
            saveOnExit,
            fast: false,
          },
        },
      ],
    },
  ],
  1: [
    {
      label: 'Apply to properties',
      name: 'propertyIds',
      component: SelectPropertiesField,
      validation: Yup.array()
        .default([])
        .atLeast(1)
        .label('property')
        .required(),
    },
  ],
})
