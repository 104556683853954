/* eslint-disable react/display-name */
import React, { Component } from 'react'
import classNames from 'classnames'
import { uniq, pickBy, groupBy } from 'lodash'
import { fieldify } from './utils'

import '../../styles/SelectWithCheckboxes.scss'

function Optgroup({
  options = [], label, readOnly, onSelect,
}) {
  const toggleAll = () => {
    const values = options.map(({ value }) => value)
    const selected = options.filter(({ selected }) => selected).length === options.length
    _onSelect(values, !selected)
  }

  const _onSelect = (values, selected) => {
    if (onSelect) {
      onSelect(values, selected)
    }
  }

  return (
    <div className="select-checkboxes-optgroup">
      <h5>
        <div>{label}</div>
        {!readOnly && (
          <div className="link inline" onClick={toggleAll}>
            {options.filter(({ selected }) => selected).length === options.length ? 'Unselect all' : 'Select all'}
          </div>
        )}
      </h5>
      <div>
        {options.map(({ value, label, selected }) => (
          <Option key={value} value={value} onSelect={_onSelect} selected={selected} readOnly={readOnly}>
            {label}
          </Option>
        ))}
      </div>
    </div>
  )
}

export const Option = ({
  name, value, label, children, onSelect, selected, readOnly,
}) => {
  const onClick = evt => {
    onSelect && onSelect([value], evt.target.checked)
  }
  return (
    <div
      className={classNames('select-checkboxes-option', {
        'read-only': readOnly,
      })}
    >
      <label>
        <input
          type="checkbox"
          name={name}
          value={value}
          onChange={onClick}
          checked={selected || false}
          data-cy="checkbox-address"
          disabled={readOnly}
        />
        {label || children}
      </label>
    </div>
  )
}

const Select = props => <div className="select-checkboxes-list" {...props} />

const SelectWithCheckboxesField = fieldify(
  class extends Component {
    state = {
      search: '',
      showSelected: false,
    }

    toggleShowSelected = () => this.setState({ showSelected: !this.state.showSelected })

    onSelect = (values, selected) => {
      let newValues = [...this.props.field.value]
      if (selected) {
        newValues = uniq([...newValues, ...values])
      } else {
        newValues = newValues.filter(value => !values.includes(value))
      }
      this.props.form.setFieldValue(this.props.field.name, newValues)
    }

    onSearch = evt => {
      this.setState({ search: evt.target.value })
    }

    render() {
      const { invalid, options } = this.props
      const { search, showSelected } = this.state

      let value = options
      if (search.length > 0) {
        value = pickBy(value, ({ label, searchText }) => {
          const str = searchText || label
          if (typeof str !== 'string') {
            // eslint-disable-next-line no-console
            console.warn('label or searchText must be a string')
            return true
          }
          return str.toLowerCase().includes(search.toLowerCase())
        })
      }

      if (showSelected) {
        value = pickBy(value, ({ value }) => this.props.field.value.includes(value))
      }

      const groups = groupBy(value, 'group')
      const ids = Object.values(value).map(({ value }) => value)
      return (
        <div
          className={classNames('select-checkboxes', {
            'is-invalid': invalid,
          })}
        >
          <div className="select-checkboxes-input">
            <input type="search" placeholder="Find a property" value={search} onChange={this.onSearch} />
          </div>
          <Select>
            {ids.length === 0 && <em>No properties found.</em>}
            {Object.keys(groups).map(label => (
              <Optgroup
                label={label}
                options={groups[label].map(option => ({
                  ...option,
                  selected: this.props.field.value.includes(option.value),
                }))}
                key={label}
                onSelect={this.onSelect}
                readOnly={this.props.readOnly}
              />
            ))}
          </Select>
          <div className="select-checkboxes-footer">
            <div className="select-checkboxes-label">
              {this.props.field.value.length}
              {' '}
              selected
            </div>
            {!this.props.readOnly && (
              <div className="action-links">
                <div className="link inline" onClick={() => this.onSelect(ids, true)}>
                  Select All
                </div>
                <div className="link inline" onClick={() => this.onSelect(ids, false)}>
                  Unselect All
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
  },
)
export default SelectWithCheckboxesField
