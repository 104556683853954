import React from 'react'
import { SERVICE_TEMPLATES } from '../../constants/services'
import BundleServiceIcon from '../../icons/BundleServiceIcon'
import SingleServiceIcon from '../../icons/SingleServiceIcon'

const ServiceName = ({
 title, nickName, summary, template,
}) => {
  return (
    <div className="ServiceName title-cell">
      <div className="first-line-wrapper">
        <div className="first-line link has-icon" data-cy="service-name">
          {template === SERVICE_TEMPLATES.BUNDLE ? <BundleServiceIcon /> : <SingleServiceIcon />}
          {title}
        </div>
      </div>
      {nickName && (
      <small className="nicknameLongText">
        (
        {nickName}
        )
      </small>
)}
      <div className="second-line">{summary}</div>
    </div>
  )
}

export default ServiceName
