import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import EnabledIcon from '../../icons/EnabledIcon';
import DisabledIcon from '../../icons/DisabledIcon';
import DeviceAddressFormatter from './DeviceAddressFormatter';
import { MESSAGES } from '../../constants/strings';

const TableColumns = ({
    headerCheckboxChecked,
    toggleHeaderCheckbox,
    handleCheckboxChange,
  }) => {
    const alignCellTextMiddle = {
        options: {
            classNames: 'middle',
        },
    };
    const CheckboxHeader = ({ isChecked, toggleHeaderCheckbox }) => {
        return (
          <div className="header-checkbox">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => {
                toggleHeaderCheckbox(e);
            }}
            />
          </div>
        );
    };
    const COLUMNS = [
        {
            id: 'superadmin',
            columns: [
                {
                    Header: <CheckboxHeader isChecked={headerCheckboxChecked} toggleHeaderCheckbox={toggleHeaderCheckbox} />,
                    accessor: 'checkbox',
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => {
                        return (
                          <div className="table-checkbox">
                            <input
                              type="checkbox"
                              checked={original.isChecked}
                              onChange={(e) => handleCheckboxChange(original.serialNumber, e.target.checked)}
                            />
                          </div>
                        );
                    },
                    ...alignCellTextMiddle,
                },
                {
                    Header: 'Serial number',
                    accessor: 'devices',
                    sortType: (a, b) => {
                        const serialNumA = get(a, 'original.serialNumber', '');
                        const statusNumB = get(b, 'original.serialNumber', '');
                        return serialNumA
                            .toLowerCase()
                            .localeCompare(statusNumB.toLowerCase());
                    },
                    Cell: ({ row: { original } }) => {
                        return <div>{original.serialNumber}</div>;
                    },
                    ...alignCellTextMiddle,
                },
                {
                    Header: 'Status',
                    accessor: 'device-status',
                    sortType: (a, b) => {
                        const statusA = get(a, 'original.status', '');
                        const statusB = get(b, 'original.status', '');
                        return statusA
                            .toLowerCase()
                            .localeCompare(statusB.toLowerCase());
                    },
                    Cell: ({ row: { original } }) => {
                        return <div>{original.status}</div>;
                    },
                    ...alignCellTextMiddle,
                },
                {
                    Header: 'Organization',
                    accessor: 'organization',
                    sortType: (a, b) => {
                        const orgA = get(a, 'original.organization.name', '');
                        const orgB = get(b, 'original.organization.name', '');
                        return orgA
                            .toLowerCase()
                            .localeCompare(orgB.toLowerCase());
                    },
                    Cell: ({ row: { original } }) => {
                        return <div>{original.organization.name}</div>;
                    },
                    ...alignCellTextMiddle,
                },
                {
                    Header: 'Property Address',
                    accessor: 'properties',
                    sortType: (a, b) => {
                        const addressA = get(
                            a,
                            'original.property.addressLine1',
                            '',
                        );
                        const addressB = get(
                            b,
                            'original.property.addressLine1',
                            '',
                        );
                        return addressA
                            .toLowerCase()
                            .localeCompare(addressB.toLowerCase());
                    },
                    Cell: ({ row: { original } }) => {
                      if (!original.property) {
                        return 'N/A'
                      } 
                      return (
                        <DeviceAddressFormatter
                          addressLine1={original.property.addressLine1}
                          addressCity={original.property.addressCity}
                          addressState={original.property.addressState}
                          status={original.status}
                        />
                      )
                    },
                    ...alignCellTextMiddle,
                },
                {
                    Header: 'Service-enabled',
                    accessor: 'service-device',
                    sortType: (a, b) => {
                        const blacklistedA = get(a, 'original.isBlacklisted', '');
                        const blacklistedB = get(b, 'original.isBlacklisted', '');
                        return String(blacklistedA)
                            .toLowerCase()
                            .localeCompare(String(blacklistedB).toLowerCase());
                    },
                    Cell: ({ row: { original } }) => {
                        return (
                          <div
                            className={classNames({
                                    'service-disabled': original.isBlacklisted,
                                    'service-enabled': !original.isBlacklisted,
                                })}
                          >
                            {original.isBlacklisted ? (
                              <DisabledIcon width={19} height={19} />
                                ) : (
                                  <EnabledIcon width={19} height={19} />
                                )}
                            <div
                              className={classNames({
                                'service-disabled-text': original.isBlacklisted,
                                'service-enabled-text': !original.isBlacklisted,
                            })}
                            >
                              {original.isBlacklisted ? MESSAGES.DISABLED : MESSAGES.ENABLED}
                            </div>
                          </div>
                        );
                    },
                    ...alignCellTextMiddle,
                },
            ],
        },
    ];
    return COLUMNS;
};

export default TableColumns;
