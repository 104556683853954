import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { groupBy } from 'lodash'
import { Option } from '../../forms/fields/SelectWithCheckboxesField'
import { formatPropertiesForField } from '../../forms/fields/SelectPropertiesField'
import '../../styles/List.scss'

const PropertySelectionContainer = ({
 properties, slideShow, propertyIds, setpropertyIds,
}) => {
  // Custom Select for properties selection
  const Select = props => <div className="select-checkboxes-list" {...props} />

  const [propertyGroups, setpropertyGroups] = useState([])
  const [Search, setSearch] = useState('')

  useEffect(() => {
    setpropertyIds(slideShow.propertyIds)

    const optionsForProperty = formatPropertiesForField(properties)
    const groups = groupBy(optionsForProperty, 'group')
    Object.keys(groups).forEach(label => groups[label].forEach(item => {
        if (slideShow.propertyIds.includes(item.value)) {
          item.selected = true
        }
      }))
    setpropertyGroups(groups)
  }, [])

  const onSelect = (value, label) => {
    const updatedValues = {
      ...propertyGroups,
      [label]: propertyGroups[label].map(item => {
        if (item.value == value) {
          !item.selected && !propertyIds.includes(value) && setpropertyIds([...propertyIds, value])
          if (item.selected) {
            const index = propertyIds.indexOf(value)
            if (index > -1) {
              const array = [...propertyIds]
              array.splice(index, 1)
              setpropertyIds([...array])
            }
          }
          return { ...item, selected: !item.selected }
        }
        return item
      }),
    }
    setpropertyGroups(updatedValues)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }
  const filtered = value => {
    const propertyGroupsReplica = { ...propertyGroups }
    const newTempArr = {}
    Object.keys(propertyGroupsReplica).forEach(
      label => (newTempArr[label] = propertyGroupsReplica[label].filter(item => item.searchText.includes(value))),
    )
    return newTempArr
  }

  const selectAll = () => {
    const tempPropIds = []
    const propertyGroupsReplica = Search ? filtered(Search) : { ...propertyGroups }
    Object.keys(propertyGroupsReplica).forEach(label => propertyGroupsReplica[label].forEach(item => {
        tempPropIds.push(item.value)
        item.selected = true
      }))
    Search ? setpropertyIds([...propertyIds, ...tempPropIds]) : setpropertyIds(tempPropIds)
  }

  const unSelectAll = () => {
    const tempPropIds = []
    const propertyGroupsReplica = Search ? filtered(Search) : { ...propertyGroups }
    Object.keys(propertyGroupsReplica).forEach(label => propertyGroupsReplica[label].forEach(item => {
        tempPropIds.push(item.value)
        item.selected = false
      }))
    const proppertyIdsForRemoval = propertyIds.filter(id => !tempPropIds.includes(id))
    setpropertyIds(proppertyIdsForRemoval)
  }

  const filteredPropertyGroup = Search ? filtered(Search) : propertyGroups
  return (
    <>
      <div className="List2">
        <div className={classNames('select-checkboxes2', { 'is-invalid': false })}>
          <div className="select-checkboxes-input">
            <input type="search" placeholder="Find a property" onChange={handleSearch} value={Search} />
          </div>
          <Select>
            <div className="select-checkboxes-optgroup">
              <div>
                {Object.keys(propertyGroups).map(label => (
                  <div>
                    <h5 key={label}>
                      <div>{label}</div>
                    </h5>
                    {filteredPropertyGroup[label].map(({ value, label: label2, selected }) => (
                      <Option
                        key={value}
                        value={value}
                        onSelect={() => {
                          onSelect(value, label)
                        }}
                        selected={selected}
                        readOnly={false}
                      >
                        {label2}
                      </Option>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Select>
        </div>
      </div>
      <div className="select-checkboxes-footer">
        <div className="select-checkboxes-label bold">
          {propertyIds.length}
          {' '}
          selected
        </div>
        {true && (
          <div className="action-links">
            <div className="link inline" onClick={selectAll}>
              Select All
            </div>
            <div className="link inline" onClick={unSelectAll}>
              Unselect All
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PropertySelectionContainer
