import { useEffect } from 'react';
import { connect } from 'react-redux';

import { checkProgress } from '../resources/tasks';

function TaskManager({ tasks, checkProgress }) {
  const intervals = {};

  useEffect(() => {
    Object.keys(tasks).forEach(path => {
      intervals[path] = window.setInterval(() => {
        checkProgress(path);
      }, 3000);
    });

    return () => {
      Object.keys(tasks).forEach(path => {
        window.clearInterval(intervals[path]);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, checkProgress]);

  return null;
}

export default connect(({ tasks }) => ({ tasks }), { checkProgress })(TaskManager);
