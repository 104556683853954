import { Drawer } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { DrawerList, SentDrawerList } from './PropertyDrawerList'
import { PropertyIcon } from '../../icons'
import { MESSAGES } from '../../constants/strings'

import '../../styles/PropertyHeader.scss'

const PropertyDrawer = ({ header, showDrawer, setDrawer, properties, drawerData = {}, sentList = false }) => {
  const [findProperty, setFindProperty] = useState('')

  const recipientProperties = drawerData.properties
  const nonArchivedRecipientProperties =
    recipientProperties && recipientProperties.filter(property => property.isArchived !== true)

  const filteredProperties = findProperty
    ? nonArchivedRecipientProperties?.filter(
        property =>
          property?.nickname?.toLowerCase().includes(findProperty) ||
          property?.addressLine1?.toLowerCase().includes(findProperty)
      ) || []
    : nonArchivedRecipientProperties

  const debounceSearch = useCallback(debounce(setFindProperty, 250), [])

  function handleSearchChange(event) {
    const { value } = event.target
    debounceSearch(value.toLowerCase())
  }

  return (
    <Drawer anchor="right" open={showDrawer} onClose={() => setDrawer(false)}>
      <div className="dashboard-side-panel property-list-panel">
        <div className="close-side-panel">
          <span onClick={() => setDrawer(false)}>
            <i className="cross1" />
            <i className="cross2" />
          </span>
        </div>
        <div className="side-panel-content">
          <h2>{header}</h2>
          <p>
            {drawerData.propertyIds?.length !== 0 && !drawerData.propertyId
              ? `Below is the list of all properties that this ${header} has been ${
                  sentList ? 'sent to' : 'assigned to'
                }:`
              : `This ${header} is not assigned to any properties`}
          </p>
          {recipientProperties?.length ? (
            <input
              type="search"
              className="search property-search property-drawer"
              placeholder="Search address, nickname"
              data-cy="property-search"
              onChange={handleSearchChange}
            />
          ) : (
            ''
          )}
          <div className="properties-list-wrapper">
            {!filteredProperties?.length ? (
              <div className="no-property-container">
                <div className="property-icon">
                  <PropertyIcon width="72" height="72" stroke="none" />
                </div>
                <div className='no-property-text'>
                  <b>{MESSAGES.NO_PROPERTY_FOUND}</b>
                </div>
              </div>
            ) : (
              <>
                {!sentList && <DrawerList data={filteredProperties} />}
                {sentList && <SentDrawerList data={filteredProperties} />}
              </>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default PropertyDrawer
