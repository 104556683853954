/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { get, sortBy } from 'lodash'
import classNames from 'classnames'
import { useTable, useGlobalFilter, useFilters } from 'react-table'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Link } from 'react-router-dom'
import {
 List, ListHead, ListHeadCell, ListBody, ListRow, ListCell, ListActions, AddButton,
} from '.'
import { HandleIcon } from '../../icons'
import ListSearchAndFilter from './ListSearchAndFilter'

const SortableItem = SortableElement(({ children }) => children)

const SortableList = SortableContainer(({ children }) => {
  return children
})

const sortData = (data, relation) => {
  return sortBy(data, [`${relation}.sortOrder`, 'createdAt', 'name'])
}

const defaultPropGetter = () => ({})

function OrderableList({
  columns,
  data,
  rowAction,
  hasRowAction,
  initialState,
  emptyState,
  getRowProps = defaultPropGetter,
  order,
  setOrder,
  onCreateItem,
  onUpdateItem,
  updateOrder,
  updateOrderProps,
  relation,
  item,
  manageLink,
  search,
  searchTheme,
  orderable = true,
  ...props
}) {
  const [orderedData, setOrderdData] = useState(sortData(data, relation))
  const [canReorder, setCanReorder] = useState(false)
  const [hasData, setHasData] = useState(false)

  const {
 getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state,
} = useTable(
    {
      columns,
      data: orderedData,
      initialState,
      manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
  )

  const headerGroup = headerGroups[1]

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const movedItem = orderedData[oldIndex]
    const newOrder = orderedData.filter(id => id !== movedItem)
    newOrder.splice(newIndex, 0, movedItem)
    const newOrderIds = newOrder.map(({ id }) => id)
    const propsToSend = updateOrderProps ? { ...updateOrderProps } : {} // TEMP
    onUpdateItem({ id: updateOrder, newOrderIds, ...propsToSend })
    setOrderdData(newOrder)
  }

  useEffect(() => {
    if (orderedData.length !== data.length || !canReorder) {
      const newOrderData = sortData(data, relation)
      setOrderdData(newOrderData)
    }
    setHasData(data.length > 0)
  }, [data])

  const onCreate = () => {
    if (onCreateItem) {
      setCanReorder(false)
      onCreateItem()
    }
  }

  const onEdit = row => {
    if (row.original && rowAction) {
      setCanReorder(false)
      rowAction(row.original)
    }
  }

  const EmptyIcon = get(emptyState, 'icon')
  const emptyText = get(emptyState, 'text')
  const showEmptyTable = EmptyIcon || emptyText

  return (
    <>
      <ListActions>
        <div className="wrapper-left">
          {onCreateItem && <AddButton item={item} onClick={onCreate} />}
          {manageLink && (
            <Link className="button" to={manageLink}>
              Manage
              {' '}
              {item}
            </Link>
          )}
          {orderable && hasData && (
            <button
              className={classNames('button outline', { active: canReorder })}
              onClick={() => setCanReorder(!canReorder)}
            >
              {canReorder ? 'Done' : 'Reorder List'}
            </button>
          )}
        </div>
        {search && (
          <div className="wrapper-right">
            <ListSearchAndFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              headerGroup={headerGroup}
              theme={searchTheme}
            />
          </div>
        )}
      </ListActions>
      {hasData ? (
        <List {...props}>
          {hasData && (
            <ListHead>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {canReorder && <th className="ListHeadCell" />}
                {headerGroup.headers.map(column => (
                  <ListHeadCell key={column.id} column={column}>
                    {column.render('Header')}
                  </ListHeadCell>
                ))}
              </tr>
            </ListHead>
          )}
          <SortableList onSortEnd={onSortEnd}>
            <ListBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)

                return (
                  <SortableItem key={`item-${row.id}`} index={parseInt(row.id, 10)} disabled={!canReorder}>
                    <ListRow
                      {...row.getRowProps(getRowProps(row))}
                      onClick={() => onEdit(row)}
                      key={row.id}
                      className={classNames({
                        'has-row-action': hasRowAction && hasRowAction(row.original),
                      })}
                      // className="no-hover"
                    >
                      {canReorder && (
                        <td className="ListCell handle shrink center">
                          <HandleIcon size={12} />
                        </td>
                      )}
                      {row.cells.map(cell => {
                        // TODO: Shared Component with this and MasterList
                        const cellClassNames = get(cell, 'column.options.classNames')
                        const cellHideOn = get(cell, 'column.options.hideOn')
                        const callHideClassNames = cellHideOn && cellHideOn.map(device => `hide-${device}`)
                        return (
                          <ListCell
                            key={cell.id}
                            className={[cellClassNames, callHideClassNames]}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </ListCell>
                        )
                      })}
                    </ListRow>
                  </SortableItem>
                )
              })}
            </ListBody>
          </SortableList>
        </List>
      ) : (
        showEmptyTable && (
          <div className="empty-list no-border">
            {EmptyIcon && <EmptyIcon size={40} />}
            {emptyText && <div>{emptyText}</div>}
          </div>
        )
      )}
    </>
  )
}

export default OrderableList

OrderableList.defaultProps = {
  search: true,
  searchTheme: 'light',
}
