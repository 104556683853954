import React from 'react'
import Icon from './Icon'

function DisabledIcon(props) {
  return (
    <Icon viewBox="0 0 19 19" {...props}>
      <path
        d="M9.5 10.55L11.675 12.725C11.8125 12.8625 11.9875 12.9312 12.2 12.9312C12.4125 12.9312 12.5875 12.8625 12.725 12.725C12.8625 12.5875 12.9312 12.4125 12.9312 12.2C12.9312 11.9875 12.8625 11.8125 12.725 11.675L10.55 9.5L12.725 7.325C12.8625 7.1875 12.9312 7.0125 12.9312 6.8C12.9312 6.5875 12.8625 6.4125 12.725 6.275C12.5875 6.1375 12.4125 6.06875 12.2 6.06875C11.9875 6.06875 11.8125 6.1375 11.675 6.275L9.5 8.45L7.325 6.275C7.1875 6.1375 7.0125 6.06875 6.8 6.06875C6.5875 6.06875 6.4125 6.1375 6.275 6.275C6.1375 6.4125 6.06875 6.5875 6.06875 6.8C6.06875 7.0125 6.1375 7.1875 6.275 7.325L8.45 9.5L6.275 11.675C6.1375 11.8125 6.06875 11.9875 6.06875 12.2C6.06875 12.4125 6.1375 12.5875 6.275 12.725C6.4125 12.8625 6.5875 12.9312 6.8 12.9312C7.0125 12.9312 7.1875 12.8625 7.325 12.725L9.5 10.55ZM9.5 17C8.4625 17 7.4875 16.803 6.575 16.409C5.6625 16.015 4.86875 15.4807 4.19375 14.8062C3.51875 14.1312 2.9845 13.3375 2.591 12.425C2.1975 11.5125 2.0005 10.5375 2 9.5C2 8.4625 2.197 7.4875 2.591 6.575C2.985 5.6625 3.51925 4.86875 4.19375 4.19375C4.86875 3.51875 5.6625 2.9845 6.575 2.591C7.4875 2.1975 8.4625 2.0005 9.5 2C10.5375 2 11.5125 2.197 12.425 2.591C13.3375 2.985 14.1312 3.51925 14.8062 4.19375C15.4812 4.86875 16.0157 5.6625 16.4097 6.575C16.8037 7.4875 17.0005 8.4625 17 9.5C17 10.5375 16.803 11.5125 16.409 12.425C16.015 13.3375 15.4807 14.1312 14.8062 14.8062C14.1312 15.4812 13.3375 16.0157 12.425 16.4097C11.5125 16.8037 10.5375 17.0005 9.5 17Z"
        fill="#F03A48"
      />
    </Icon>
  )
}

export default DisabledIcon
