import React from 'react'
import classNames from 'classnames'

const DeviceStatus = ({ status }) => {
  let deviceStatus = 'Active'
  if (status === 'UNPAIRED') {
    deviceStatus = 'Inactive'
  }

  const statusClass = {
    inactive: status === 'UNPAIRED',
    active: status === 'PAIRED',
  }

  return (
    <div className={classNames({ ...statusClass })}>
      <div className="first-line">{deviceStatus}</div>
    </div>
  )
}
export default DeviceStatus
