import React from 'react'
import { formatters } from '../Utils'
import '../styles/TabTitle.scss'

function TabTitle({ title, count, steps }) {
  return (
    <div className="TabTitle">
      {title}
      {count >= 0 && <span className="count">{formatters.count(count)}</span>}
      {steps && <span className="steps">{steps}</span>}
    </div>
  )
}

export default TabTitle
