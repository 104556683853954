import React from 'react'
import classNames from 'classnames'
import { get, isArray, isFunction } from 'lodash'
import { fieldify } from './utils'
import '../../styles/ToggleSwitch.scss'

const ChoiceField = fieldify(
  ({
    field,
    form,
    label,
    icon: Icon,
    image,
    labelFirst,
    toggleSwitch,
    customLabel,
    showLabel,
    type,
    group,
    defaultChecked,
    invalid,
    disabled,
    readOnly,
    saveOnExit,
    onOff,
    relations,
    related,
    className,
    onComplete,
    enableDisable,
    ...rest
  }) => {
    const { setFieldValue, values, handleSubmit } = form
    const currentValue = get(values, field.name)

    const onCompleteHandler = _currentVal => {
      if (isFunction(onComplete)) {
        onComplete(form, _currentVal)
      }
    }

    const onChange = evt => {
      let newValue
      if (evt.target.type === 'checkbox') {
        if (currentValue && currentValue.includes(field.value)) {
          newValue = currentValue.filter(value => value !== field.value)
        } else if (currentValue) {
          newValue = [...currentValue, field.value]
        } else {
          newValue = [field.value]
        }
        if (rest.isHead) {
          if (!newValue.includes(rest.value)) {
            newValue = []
          }
        }
      } else {
        newValue = [field.value]
      }
      if (rest.onSelect) {
        rest.onSelect(newValue[0])
        setFieldValue(field.name, newValue[0])
      } else {
        setFieldValue(field.name, newValue)
      }
      if (saveOnExit) handleSubmit()
    }

    const modifyRelations = _val => {
      if (relations && isArray(relations)) {
        for (const index in relations) {
          const { name, onCheck, onUnCheck, defaultValue } = relations[index]
          if (_val) {
            if (values[name] && isArray(values[name])) {
              setFieldValue(name, defaultValue ? [defaultValue] : [])
            } else {
              setFieldValue(name, onCheck)
            }
          } else {
            /* Other component does not rerender to get disabled if we set the fieldValue
              to the existing value.Hence setting it to null.Need to implement rerender for disabling
              in better way */

            const current = get(values, name)
            if (values[name] && isArray(values[name])) {
              if (current !== null && onUnCheck === false) {
                setFieldValue(name, [])
              } else {
                setFieldValue(name, defaultValue ? [defaultValue] : [])
              }
            } else {
              if (current !== null && onUnCheck === false) {
                setFieldValue(name, null)
              } else {
                setFieldValue(name, onUnCheck)
              }
            }
          }
        }
      }
      if (rest.onSelect) {
        rest.onSelect()
      }
    }

    const checkDisabled = () => {
      if (disabled || readOnly) return true
      if (rest.head) {
        const isHeadSelected = get(values, rest.head)
        if (!isHeadSelected) return true
      }

      if (related && isArray(related)) {
        for (const index in related) {
          const { name, disableOnUnCheck, disableOnCheck } = related[index]
          const currentValue = get(values, name)
          if (currentValue && disableOnCheck) {
            return true
          }
          if (!currentValue && disableOnUnCheck) {
            return true
          }
        }
      }

      return false
    }

    const hasValue = () => field.value && currentValue && currentValue.includes(field.value)

    return (
      <label
        className={classNames(
          'ChoiceField',
          { ToggleSwitch: toggleSwitch, [className]: className },
          { 'label-first': labelFirst }
        )}>
        {group ? (
          <input
            {...field}
            disabled={checkDisabled()}
            className={classNames('input', { 'd-none': image?.hideCheckBox })}
            type={type}
            onChange={onChange}
            checked={field.value && currentValue && currentValue.includes(field.value)}
          />
        ) : (
          <input
            {...field}
            onChange={evt => {
              if (!readOnly) {
                field.onChange(evt)
                modifyRelations(!currentValue)
                window.setTimeout(() => saveOnExit && handleSubmit(), 0)
                onCompleteHandler(!currentValue)
              }
            }}
            disabled={checkDisabled()}
            className={classNames('input', { 'd-none': image?.hideCheckBox })}
            type={type}
            defaultChecked={defaultChecked}
            checked={!!currentValue}
          />
        )}
        {toggleSwitch && <span className="slider" />}
        <div className="label">
          {showLabel && (
            <div className={classNames('label-text', { 'label-text-grayed': checkDisabled() })}>
              {onOff
                ? currentValue
                  ? 'On'
                  : 'Off'
                : enableDisable
                ? currentValue
                  ? 'Enable'
                  : 'Disable'
                : customLabel || label}
            </div>
          )}
          {Icon && (
            <div className="label-icon">
              <Icon />
            </div>
          )}
          {image && Object.keys(image).length && (
            <div className={classNames('label-icon', { 'emoti-group': image.smallIcon })}>
              {image.enabled && <img src={hasValue() ? image.enabled : image.disabled} alt="checkbox" />}
              {image.fadeOnDisable && <img src={image.src} alt="checkbox" style={hasValue() ? {} : { opacity: 0.3 }} />}
            </div>
          )}
        </div>
      </label>
    )
  }
)

export const RadioField = props => <ChoiceField {...props} type="radio" />
export const CheckboxField = props => (
  <ChoiceField {...props} type="checkbox" value={props.value ? props.value : true} />
)

export default ChoiceField

ChoiceField.defaultProps = {
  group: false,
  onOff: false,
  showLabel: true,
  readOnly: false,
}
