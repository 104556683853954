import React, { Fragment } from 'react'
import { fieldify } from './utils'
import TextareaField from './TextareaField'

const SMSField = fieldify(({
 field, form, invalid, maxLength, saveOnExit, shouldToggle, approveOrDeny, ...rest
}) => {
  const status = approveOrDeny
  const statusLower = status.toLowerCase()
  const { name } = form.values

  const hasPayment = form.values.hasPaymentProvider

  let paymentText = ''
  if (hasPayment) {
    if (status === 'Approved') {
      paymentText = ' and your payment has been processed'
    } else {
      paymentText = ' and your payment authorization has been canceled'
    }
  }
  let approveOrDenyMessage = ''
  if (status === 'Approved') {
    approveOrDenyMessage = ` ${form.values.approvalMessage}`
  } else {
    approveOrDenyMessage = ` ${form.values.declineMessage}`
  }

  const message = `[${status}] Your request for ${name} is ${statusLower}${paymentText}.`

  return (
    <Fragment>
      <TextareaField
        shouldToggle={shouldToggle}
        saveOnExit={saveOnExit}
        form={form}
        field={field}
        maxLength={maxLength}
        {...rest}
      />
    </Fragment>
  )
})

export default SMSField
