import { get } from 'lodash'
import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'

export default ({
 formik: { values }, name, heading, placeholder,
}) => {
  const value = get(values, name)

  const headerContent = heading ? `<h5>${heading}</h5>` : null

  if (value) {
    return (
      <div
        className="PreviewDescription"
        dangerouslySetInnerHTML={{ __html: `${headerContent || ''}<small>${value}</small>` }}
      />
    )
  }
  return (
    <div className="PreviewDescription">
      {heading && (
        <>
          <br />
          <strong>{heading}</strong>
          <br />
          <br />
        </>
      )}
      {placeholder || <Skeleton count={1} />}
      {' '}
    </div>
  )
}
