import React from 'react'
import { MESSAGES } from '../../constants/strings'

const ModalFooter = ({
 previousButton, continueButton, otherButtons, children,
}) => {
  return (
    <div className="ModalFooter">
      {previousButton && <div className="footer-item">{previousButton}</div>}
      {otherButtons && (
        <div className="footer-item right">
          <strong style={{ marginRight: 5 }}>{`${MESSAGES.NOTE}:`}</strong>
          {otherButtons}
        </div>
      )}
      {continueButton && <div className="footer-item">{continueButton}</div>}
      {children}
    </div>
  )
}

export default ModalFooter
