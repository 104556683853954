import React from 'react'
import {
  MyInfo, ManagePasscode, ManageIntegrations, Devices,
} from '.'
import ManageCompanyName from './ManageCompanyName'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const GeneralInfo = ({
  me, onSaveMe, onRefreshMe, dispatch, recordAnalytics,
}) => {
  return (
    <>
      <MyInfo me={me} />
      <ManagePasscode me={me} onSaveMe={onSaveMe} dispatch={dispatch} recordAnalytics={recordAnalytics} />
      <ManageCompanyName me={me} onSaveMe={onSaveMe} dispatch={dispatch} recordAnalytics={recordAnalytics} />
      <ManageIntegrations me={me} onRefreshMe={onRefreshMe} />
      <Devices />
    </>
  )
}

export default GeneralInfo
