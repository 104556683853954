import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { connect } from 'react-redux'
import { Loading, EmptyNavItemPlaceholder } from '../components/PageStates'
import Resource from '../Resource'
import { openModalWithContainer } from '../reducers/modals'
import { PROPERTY_TYPES } from '../constants/forms/propertyInfo'
import { SlideshowIcon, SearchIcon } from '../icons'
import { ContentEditorDescription } from '../components/content'
import { SLIDESHOW } from '../constants/forms/slides'
import { apiRequest } from '../reducers/data'
import { AdvertisementDrawer } from '../components/advertisements'

import { confirm } from './modals/AlertMessageContainer'
import { thirdPartyAnalytics } from '../services'
import { ACTIONS, EVENTS } from '../constants/thirdPartyAnalytics'
import '../styles/Search.scss'
import SlideshowTable from '../components/Slideshow/SlideshowTable'
import { MESSAGES } from '../constants/strings'

let recordAnalyticsTimer = null

function SlideshowContainerWithData(props) {
  const [showDrawer, setShowDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})
  const [searchText, setSearchText] = useState('')

  const onDuplicate = async bundle => {
    const { dispatch } = props
    const args = { name: `${bundle.name} copy`, images: bundle.images, propertyIds: [] }
    const { json } = await dispatch(apiRequest(['art-mode-bundles'], 'POST', args))
    props.recordAnalytics(ACTIONS.DUPLICATE, args.name)
    openModal(json, true)
  }

  const setDefault = async (id, name) => {
    const { dispatch } = props
    await dispatch(apiRequest(['art-mode-bundles', id, 'default'], 'PUT', {}))
    props.recordAnalytics(ACTIONS.CHANGE_DEFAULT, name)
  }

  const populateDrawer = data => {
    setDrawerContent(data)
    setShowDrawer(true)
  }

  const handleSearch = e => {
    // clearTimeout(recordAnalyticsTimer)
    // if (e.target.value) {
    //   recordAnalyticsTimer = setTimeout(() => {
    //     props.recordAnalytics(ACTIONS.SEARCH)
    //   }, 250)
    // }
    setSearchText(e.target.value)
  }

  const filteredList = () => {
    const filterList = props.bundles.filter(bundle => {
      return bundle.name && bundle.name.toLowerCase().includes(searchText.toLowerCase())
    })
    return searchText ? filterList : props.bundles
  }

  const openModal = (bundle, firstTime = false) => {
    const {
      properties, onUpdateItem, dispatch, onRefresh, onDeleteItem,
    } = props

    dispatch(
      openModalWithContainer('SlideshowContainerModal', {
        propertiesCount: properties.length,
        properties,
        firstTime,
        onUpdateItem,
        onDeleteItem,
        slideShow: bundle,
        id: bundle.id,
        onRefresh,
        totalProperties: properties.length,
        onPropertySelect: openModal,
      }),
    )
  }

  const {
    properties, onCreateItem, onDeleteItem, onRefresh, onUpdateItem, dispatch,
  } = props
  const bundles = filteredList()
  // Define a few helper methods
  const isCustom = bundle => bundle.organizationId !== null
  const propertiesWithBundle = bundle => properties.filter(p => p.artModeBundleId === bundle.id)

  // We only display bundles that are custom or standard bundles that are in use
  const displayedBundles = bundles.filter(b => isCustom(b) || propertiesWithBundle(b).length > 0)
  const sortedBundles = displayedBundles.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  )

  const onSetDefault = async bundle => {
    await setDefault(bundle.id, bundle.name)
    onRefresh()
  }

  // Define a few row actions
  const onConfirmDelete = bundle => {
    confirm({
      title: 'Confirmation',
      body: 'Are you sure you want to remove this item?',
      onConfirm: () => {
        onDeleteItem(bundle)
      },
      onClick: onRefresh,
    })
  }

  return (
    <div>
      <AdvertisementDrawer
        data={drawerContent}
        properties={properties}
        showDrawer={showDrawer}
        setDrawer={setShowDrawer}
      />
      <ContentEditorDescription description={SLIDESHOW.description} />
      <div>
        <div className="tab-actions" style={{ marginBottom: 20, display: 'flex' }}>
          <button
            className="btn btn-primary"
            onClick={async () => {
              const { json } = await onCreateItem({ name: 'Untitled Collection', images: [], propertyIds: [] })
              openModal(json, true) // context.router.history.push(`/slideshow/${json.id}`)
            }}
          >
            <FaPlus style={{ marginRight: '4px' }} />
            {' '}
            {MESSAGES.ADD_SLIDESHOW_COMPONENT}
          </button>
          <button
            className="btn btn-outline-primary"
            style={{ marginLeft: 10 }}
            onClick={() => {
              dispatch(
                openModalWithContainer('StockSlideshowsContainer', {
                  stockBundles: bundles.filter(b => !isCustom(b)),
                  onCreate: extra => {
                    const name = extra.name && extra.name.split(' copy')[0]
                    return onCreateItem(extra, name)
                  },
                  setEditingView: openModal,
                }),
              )
            }}
          >
            {MESSAGES.BROWSE_STOCK_COLLECTION}
          </button>
          <div style={{ flex: 1 }} />
          <div className="Search light">
            <div className="search-icon">
              <SearchIcon size={18} />
            </div>
            <input className="search-input" placeholder="Search Slideshow" onChange={handleSearch} />
          </div>
        </div>
        {displayedBundles.length === 0 ? (
          <EmptyNavItemPlaceholder icon={<SlideshowIcon height="72" width="72" />} navItem="a collection" />
        ) : (
          <>
            <SlideshowTable
              data={sortedBundles}
              rowAction={openModal}
              onUpdateItem={onUpdateItem}
              onDeleteItem={onDeleteItem}
              dispatch={dispatch}
              onSetDefault={onSetDefault}
              onConfirmDelete={onConfirmDelete}
              populateDrawer={populateDrawer}
              onDuplicate={onDuplicate}
              properties={properties}
            />
          </>
        )}
      </div>
    </div>
  )
}

export function SlideshowContainer({ analyticsProperties }) {
  const recordAnalytics = (action, name) => {
    const updatedProperties = { ...analyticsProperties, action, name }
    thirdPartyAnalytics.track(EVENTS.SLIDESHOW, updatedProperties)
  }

  return (
    <Resource collection path={['art-mode-bundles']} placeholderComponent={Loading}>
      {(bundles, {
        onUpdateItem, onCreateItem, onDeleteItem, onRefresh, dispatch,
      }) => {
        const onAction = (action, func, extra, name) => {
          if (action !== ACTIONS.DELETE) {
            if (name) {
              recordAnalytics(ACTIONS.COPY_STOCK, name)
            } else {
              recordAnalytics(action, extra.name)
            }
          }
          return func(extra)
        }

        return (
          <Resource
            collection
            path={['properties', `?type=${PROPERTY_TYPES.current}`]}
            placeholderComponent={Loading}
          >
            {properties => (
              <SlideshowContainerWithData
                properties={properties}
                bundles={bundles}
                onCreateItem={onAction.bind(this, ACTIONS.CREATE, onCreateItem)}
                onUpdateItem={onAction.bind(this, ACTIONS.UPDATE, onUpdateItem)}
                onDeleteItem={onAction.bind(this, ACTIONS.DELETE, onDeleteItem)}
                onRefresh={onRefresh}
                recordAnalytics={recordAnalytics}
                dispatch={dispatch}
              />
            )}
          </Resource>
        )
      }}
    </Resource>
  )
}

export default connect(state => {
  const { me: userInfo } = state.data
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  return {
    analyticsProperties,
  }
})(SlideshowContainer)