const openOAuthPopup = url => {
  const top = window.outerHeight / 2 + window.screenY - 700 / 2;
  const left = window.outerWidth / 2 + window.screenX - 600 / 2;
  return window.open(
    `${window.location.origin}/oauth?url=${url}`,
    'OAUTH',
    `height=700,width=600,top=${top},left=${left}`,
    'opener',
  );
}

export default openOAuthPopup;
