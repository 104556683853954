import { useEffect, useState } from 'react'
import { displayErrorToast } from '../utils/displayToast'

function YelpSearchResultsContainer({ ...props }) {
  const [fetching, setFetching] = useState(false)
  const [term, setTerm] = useState('')
  const [results, setResults] = useState([])
  const [refreshTimeout, setRefreshTimeout] = useState(null)
  const [mounted, setMounted] = useState(true)

  useEffect(() => {
    setRefreshTimeout(null)
    setMounted(true)
    fetchResultsNow()

    return () => {
      clearTimeout(refreshTimeout)
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    setResults([])
    fetchResultsNow()
  }, [props.latitude, props.longitude])

  useEffect(() => {
    fetchResultsSoon()
  }, [term])

  const notify = msg => {
    displayErrorToast(msg)
  }

  const fetchResultsSoon = () => {
    clearTimeout(refreshTimeout)
    setRefreshTimeout(setTimeout(() => {
      if (!mounted) {
        return
      }
      fetchResults({ term })
    }, 500))
  }

  const fetchResultsNow = () => {
    clearTimeout(refreshTimeout)
    fetchResults({ term })
  }

  const fetchResults = async ({ term }) => {
    const { latitude, longitude, initialTerm } = props
    // const termToUse = term ? `${term}, ${initialTerm}` : initialTerm
    const termToUse = term || initialTerm
    setFetching(true)
    const response = await fetch('/api/dashboard/recommendations/find', {
      body: JSON.stringify({
        term: termToUse,
        latitude,
        longitude,
        limit: 40,
      }),
      credentials: 'include',
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    })
    const json = await response.json()

    if (!mounted) {
      return
    }
    if (json.error) {
      notify(`Yelp API Response: ${JSON.stringify(json.error)}`)
      setFetching(false)
      setResults([])
      return
    }
    setFetching(false)
    setResults(json)
  }

  const onChangeTerm = e => setTerm(e.target.value)

  return props.children({
    fetching,
    results,
    term,
    onChangeTerm,
  })
}
export default YelpSearchResultsContainer
