import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import WithExternalProperties from './WithExternalProperties'
import { ProgressContainer } from '../../components/import-properties'
import ImportPropertyItem from '../../components/ImportPropertyItem'
import Resource from '../../Resource'

import '../../styles/ImportContainer.scss'
import { MESSAGES } from '../../constants/strings'

const ImportReservationsContainer = props => {
  const [selectedExternalProperty, setSelectedExternalProperty] = useState(null)
  const [selectedProperties, setSelectedProperties] = useState([])
  const [query, setQuery] = useState('')
  const [disableSync, setDisableSync] = useState(false)
  const { tasks } = props
  const propertyId = props.property.id
  const progressPath = tasks['pms/progress'] ? 'pms/progress' : 'escapia/progress'
  const isImporting = tasks[progressPath] ? !tasks[progressPath].isCompleted : null

  const onSave = async () => {
    const { onSave } = props

    await onSave({
      externalServiceId: selectedExternalProperty.externalServiceId,
    })

    onCancel()
  }

  const onCancel = () => {
    props.closeModal()
  }

  return (
    <div>
      <WithExternalProperties
        isImporting={isImporting}
        onCancel={onCancel}
        to={`/properties/${propertyId}/reservations`}
        componentType="Reservations"
        title="Select Property for Reservation Sync">
        {({ externalProperties = [], fetchProperties, service }) => {
          const fields = ['addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZIP']
          const filteredProperties = !query
            ? externalProperties
            : externalProperties.filter(property => {
                const includes = fields.filter(field => {
                  return property[field] ? property[field].toLowerCase().includes(query.toLowerCase()) : false
                })
                return includes.length
              })
          const serviceKey = service ? service.name : ''

          const showingPropertiesString = `Showing ${filteredProperties.length} properties ${
            filteredProperties.length !== externalProperties.length ? `of ${externalProperties.length}` : ''
          }`
          return (
            <Resource collection path={['properties', propertyId, 'reservations']} placeholder={[]}>
              {(reservations, { onRefresh }) => (
                <Fragment>
                  <ModalHeader closeModal={onCancel}>{MESSAGES.SELECT_PROPERTY_FROM_SYNC}</ModalHeader>
                  {isImporting ? (
                    <ProgressContainer
                      tasks={tasks}
                      progressPath={progressPath}
                      closeModal={onCancel}
                      serviceKey={serviceKey}
                    />
                  ) : (
                    externalProperties.length && (
                      <Fragment>
                        <ModalBody>
                          <div className="import-properties-filter-container">
                            <span>{showingPropertiesString}</span>
                            <div
                              className="link inline"
                              onClick={() => {
                                setSelectedProperties([])
                                fetchProperties({ resync: true })
                              }}>
                              {MESSAGES.RESYNC_PROPERTY_INFO}
                            </div>
                          </div>
                          <Input
                            type="search"
                            placeholder="Search by property address"
                            value={query || ''}
                            onChange={evt => setQuery(evt.target.value)}
                          />
                          <div className="property-items-outer">
                            {filteredProperties.map((propObj, index) => (
                              <ImportPropertyItem
                                key={index}
                                {...propObj}
                                onClick={() => setSelectedExternalProperty(propObj)}
                                isSelected={selectedExternalProperty === propObj}
                                isImported={false}
                              />
                            ))}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <button
                            disabled={!selectedExternalProperty || disableSync}
                            className="btn btn-primary btn-small"
                            onClick={() => {
                              setDisableSync(true)
                              onSave().then(() => {
                                setDisableSync(false)

                                onRefresh()
                                window.location.reload()
                              })
                            }}>
                            {MESSAGES.SYNC_RESERVATIONS}
                          </button>
                        </ModalFooter>
                      </Fragment>
                    )
                  )}
                </Fragment>
              )}
            </Resource>
          )
        }}
      </WithExternalProperties>
    </div>
  )
}

export default connect(state => ({ tasks: state.tasks }))(ImportReservationsContainer)
