import React from 'react'

const CharactersLeft = ({ length, maxLength }) => (
  <div className="character-counter">
    <span className="numbers">
      <span className="count">{Number(length).toLocaleString()}</span>
      /
      {Number(maxLength).toLocaleString()}
    </span>
    characters
  </div>
)

export default CharactersLeft
