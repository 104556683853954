import React from 'react'
import Icon from './Icon'

function ChevronRightArrow(props) {
  return (
    <Icon height="12" width="12" viewBox="0 0 12 12" {...props}>
      <path
        d="M4.781.375a1 1 0 0 0-1.562 1.25L6.719 6l-3.5 4.375a1 1 0 0 0 1.562 1.25l4-5a1 1 0 0 0 0-1.25z"
        fill="fill"
      />
    </Icon>
  )
}

export default ChevronRightArrow
