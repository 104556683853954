/* eslint-disable react/display-name */
import React, { useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { get, find } from 'lodash'
import { pluralize } from '../../Utils'
import { SelectRowCheckbox } from './commonFilters'
import { ListCellBadge } from '../../components/list'
import RecipientProperties from '../../components/drawer/RecipentProperties'
import { MESSAGES } from '../strings'

export const COMPONENT_NAME = {
  Header: 'Component Name',
  accessor: ({ name }) => name && name.toLowerCase(),
  Cell: ({ row: { original: customization } }) => {
    const isCustom = customization.propertyId && !customization.name
    const name = isCustom ? 'Custom' : customization.name
    return (
      <>
        <div className={classNames('content-name bold', { link: !isCustom })}>
          {name} {customization.isDefault && <ListCellBadge label="Default" />}
        </div>
        {customization.type === 'checkinSurvey' && (
          <div className="disableStyle">{MESSAGES.DISABLE_IN_CHECKIN_FLOW}</div>
        )}
        {customization.type === 'checkoutSurvey' && (
          <div className="disableStyle">{MESSAGES.DISABLE_IN_CHECKOUT_FLOW}</div>
        )}
        {customization.type === 'rules' && customization.content.includeInCheckIn && (
          <div className="disableStyle">{MESSAGES.INCLUDE_IN_CHECKIN_FLOW}</div>
        )}
      </>
    )
  },
}

export const COMPONENT_NICKNAME = {
         Header: 'Nickname',
         accessor: ({ content: { nickname } }) => {
           return nickname && nickname.toLowerCase()
         },
         Cell: ({ row: { original: customization } }) => {
           const nickname = customization?.content?.nickname ? customization?.content?.nickname : 'N/A'
           return <div className={classNames('content-name bold')}>{nickname}</div>
         },
       }

export const CREATED_CELL = {
  id: 'createdAt',
  Header: 'Created',
  accessor: ({ createdAt }) => moment(createdAt),
  sortType: (a, b) => {
    const dateA = get(a, 'original.createdAt', '')
    const dateB = get(b, 'original.createdAt', '')
    return moment(dateA).diff(moment(dateB))
  },
  Cell: ({ cell: { value } }) => <small title={value.format('LLLL')}>{value.format('LLL')}</small>,
}

export const UPDATED_CELL = {
  id: 'updatedAt',
  Header: 'Updated',
  accessor: ({ updatedAt }) => moment(updatedAt),
  sortType: (a, b) => {
    const dateA = get(a, 'original.updatedAt', '')
    const dateB = get(b, 'original.updatedAt', '')
    return moment(dateA).diff(moment(dateB))
  },
  // eslint-disable-next-line react/display-name
  Cell: ({ cell: { value }, row: { original } }) => (
    <small className="date-time" title={`Created At: ${moment(original.createdAt).format('LLLL')}`}>
      <date>{value.format('MMM DD, YYYY')}</date>
      <time>{value.format('hh:mm a')}</time>
    </small>
  ),
  options: {
    hideOn: ['tablet', 'mobile'],
  },
}

export const PROPERTIES_APPLIED = {
  Header: 'Properties',
  accessor: 'properties',
  sortType: (a, b) => {
    const propertyA = get(a, 'original.properties.length', '')
    const propertyB = get(b, 'original.properties.length', '')
    return propertyA - propertyB
  },
  Cell: ({ row: { original }, initialState: { properties, propertiesCount, openRecipientProperties } }) => {
    return (
      <RecipientProperties
        openRecipientProperties={e => openRecipientProperties(original, e)}
        properties={properties}
        propertiesLength={original.propertyIds?.length || (original.propertyId ? 1 : 0)}
        original={original}
        totalPropertiesCount={propertiesCount}
      />
    )
  },
  options: {
    hideOn: ['mobile'],
  },
}

export const RECIPIENT_PROPERTY = {
  Header: 'Assigned to',
  accessor: 'properties',
  sortType: (a, b) => {
    const propertyA = get(a, 'original.properties.length', '')
    const propertyB = get(b, 'original.properties.length', '')
    return propertyA - propertyB
  },
  Cell: ({ row: { original }, initialState: { propertiesCount, properties, openRecipientProperties } }) => {
    return (
      <RecipientProperties
        openRecipientProperties={e => openRecipientProperties(original, e)}
        properties={properties}
        propertiesLength={original.propertyIds?.length || (original.propertyId ? 1 : 0)}
        original={original}
        totalPropertiesCount={propertiesCount}
      />
    )
  },
}

export const SELECT_ROW = {
  id: 'selection',
  Header: ({ isAllRowsSelected, toggleAllRowsSelected, getToggleAllRowsSelectedProps }) => (
    <div aria-hidden="true" className="link s" onClick={() => toggleAllRowsSelected(!isAllRowsSelected)}>
      {isAllRowsSelected ? 'Unselect All' : 'Select All'}
    </div>
  ),
  Cell: ({ row }) => <SelectRowCheckbox {...row.getToggleRowSelectedProps()} />,
  options: {
    classNames: 'y-center',
    headerClassNames: 'select-all-header',
  },
}
