import React from 'react'
import classNames from 'classnames'
import { connect, FieldArray } from 'formik'
import { get, isFunction, reduce } from 'lodash'

import '../../styles/ListField.scss'
import { TextField } from '../../forms/fields'
import RequestIconsList from './RequestIconsList'

const ContactRequestTopicsFields = ({
  maxItems,
  name,
  saveOnExit,
  disabled,
  addNew,
  fast,
  type,
  rows,
  maxLength,
  formik: { values, setFieldValue, handleSubmit },
  skipBlurMode = false,
  required,
  readOnly,
}) => {
  const items = get(values, name, []).sort((a, b) => (b.isDefault || false) - (a.isDefault || false))

  const isDisabled = isFunction(disabled) ? disabled(values) : disabled
  const isRequired = isFunction(required) ? required(values) : required
  const topicsCount = reduce(values.content.contacts, (prev, current) => prev + current?.topics?.length || 0, 0)
  const hasMaxItems = maxItems && topicsCount >= maxItems

  const onChange = (value, name) => {
    setFieldValue(name, value)
  }

  const resetTopics = () => {
    const updatedTopics = [...items].filter(item => item.isDefault)
    setFieldValue(name, updatedTopics)
  }

  return (
    <div className="topics-section">
      <div className="topic-title-container pb-2">
        <h3>
          {isRequired && <span className="required">*</span>}
          {' '}
          Topics
        </h3>
        {!readOnly && !isDisabled && items?.length > 1 && (
          <button type="button" onClick={resetTopics}>
            Delete all
            {' '}
            <span className="necicon-trash-bin" />
          </button>
        )}
      </div>
      <FieldArray
        name={name}
        validateOnChange={false}
        validateOnBlur={false}
        render={arrayHelpers => {
          // if (!items.length) arrayHelpers.push({ text: '', iconURL: '' })

          const add = () => {
            items && arrayHelpers.push({ text: '', iconURL: '' })
          }
          return (
            <div className="ListField">
              {items.map((_, index) => {
                const textFieldName = `${name}.${index}.text`
                const iconFieldName = `${name}.${index}.iconURL`

                const topic = get(values, `${name}.${index}`, {})

                const remove = () => {
                  arrayHelpers.remove(index)
                  saveOnExit
                    && setTimeout(() => {
                      handleSubmit()
                    }, 500)
                }
                return (
                  <div key={index} className="ListItemField">
                    <label>Title</label>
                    <div className={classNames({ isDefault: (topic.isDefault || readOnly) && !isDisabled }, 'field-wrapper')}>
                      <div className="field-container">
                        <TextField
                          name={textFieldName}
                          saveOnExit={!readOnly && saveOnExit}
                          readOnly={readOnly}
                          disabled={readOnly || isDisabled || topic.isDefault}
                          type={type || 'text'}
                          rows={rows}
                          fast={fast}
                          maxLength={maxLength}
                          onChange={e => {
                            const { value } = e.target
                            onChange(value, textFieldName)
                          }}
                          skipBlurMode={skipBlurMode}
                        />
                        <RequestIconsList
                          onChange={value => onChange(value, iconFieldName)}
                          name={iconFieldName}
                          topic={topic}
                          disabled={readOnly || isDisabled}
                          saveOnExit={saveOnExit}
                          handleSubmit={handleSubmit}
                        />
                      </div>
                    </div>
                    {!topic.isDefault && !readOnly && !isDisabled && items.length > 1 && (
                      <button type="button" className="action-btn">
                        <span className="necicon-trash-bin" onClick={!isDisabled ? remove : null} />
                      </button>
                    )}
                  </div>
                )
              })}
              <div className="list-field-controls">
                <div
                  tabIndex={0}
                  className={classNames('link link-sm', {
                    disabled: isDisabled || hasMaxItems || readOnly,
                  })}
                  onClick={isDisabled || hasMaxItems || readOnly ? null : add}
                >
                  + New
                  {' '}
                  {addNew || 'Topic'}
                  {' '}
                  {maxItems && `(${maxItems - topicsCount}/${maxItems} remaining)`}
                </div>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default connect(ContactRequestTopicsFields)

ContactRequestTopicsFields.defaultProps = {
  disabled: false,
}
