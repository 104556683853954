import React, { useMemo } from 'react'
import { capitalize } from 'lodash'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'

import '../../styles/AnalyticsContainer.scss'
import { MasterList } from '../../components/list'
import { MESSAGES } from '../../constants/strings'

const COLUMNS = [
  {
    id: 'topPhoneMessages',
    columns: [
      {
        Header: MESSAGES.PAGE_NAME,
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => <div className="page-name capitalize">{value}</div>,
      },
      {
        Header: MESSAGES.SECTION,
        accessor: 'section',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => <div>{capitalize(value)}</div>,
      },
      {
        Header: MESSAGES.PROPERTIES,
        accessor: 'properties',
        Cell: ({ cell: { value }, initialState: { numProperties } }) => (
          <div>
            {value}
            {' '}
            of
            {' '}
            {numProperties}
          </div>
        ),
        options: {
          hideOn: ['mobile'],
        },
      },
      {
        id: 'views',
        Header: MESSAGES.NUMBER_SMS_SENT,
        accessor: 'views',
        Cell: ({ cell: { value } }) => <div className="views">{value}</div>,
      },
    ],
  },
]
const TopPhoneMessages = ({ numProperties, params, singleProperty }) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  const hiddenColumns = singleProperty ? ['properties'] : []
  const initialState = {
    hiddenColumns,
    numProperties,
    sortBy: [{ id: 'views', desc: true }],
  }
  return (
    <>
      <Resource collection path={['analytics', 'phone', params]} placeholderComponent={Loading}>
        {({ topPhoneMessages = [] }) => {
          return (
            <div className="TopPhoneMessages">
              <div className="title">{MESSAGES.TOP_SEND_TO_PHONE_MESSAGES}</div>
              <div className="top-phone-messages-list">
                <MasterList
                  columns={memoizedColumns}
                  data={topPhoneMessages}
                  initialState={initialState}
                  search={false}
                />
              </div>
            </div>
          )
        }}
      </Resource>
      <Resource collection path={['analytics', 'scan', params]} placeholderComponent={Loading}>
        {({ topScans = [] }) => {
          return (
            <div className="TopPhoneMessages">
              <div className="title">{MESSAGES.TOP_QR_SCANS}</div>
              <div className="top-phone-messages-list">
                <MasterList columns={memoizedColumns} data={topScans} initialState={initialState} search={false} />
              </div>
            </div>
          )
        }}
      </Resource>
    </>
  )
}

export default TopPhoneMessages
