import React from 'react'

const ContentEditorDescription = ({
 title, description, children, ...rest
}) => {
  return (
    <div className="ContentEditorDescription" {...rest}>
      {title && <h4>{title}</h4>}
      <p>{description}</p>
      {children}
    </div>
  )
}

export default ContentEditorDescription
