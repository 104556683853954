import React from 'react'
import classNames from 'classnames'
import '../styles/StatBar.scss'
import { round } from '../Utils'

function StatBar({ stats, horizontalItem }) {
  return (
    <div
      className={classNames('StatBar', { horizontalItem })}
      style={{ gridTemplateColumns: `repeat(${stats.length}, 1fr)` }}
    >
      {stats.map(({
 label, value, precision = 2, suffix = '', prefix = '',
}) => (
  <div className="StatItem" key={label}>
    <div className="StatValue">
      {prefix}
      {value === null || value === undefined
              ? '–'
              : Number(value ? round(value, precision) : value).toLocaleString()}
      {suffix}
    </div>
    <div className="StatLabel">{label}</div>
  </div>
      ))}
    </div>
  )
}
StatBar.defaultProps = {
  horizontalItem: false,
}
export default StatBar
