/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useEffect, useState } from 'react'

import { Input } from 'reactstrap'
import { connect } from 'formik'
import PhoneInput from 'react-phone-number-input'
import { TextField, PhoneField, ImageDropzoneField } from '../../forms/fields'
import { FormGroup } from '../../forms'
import Tooltip from '../Tooltip'
import Resource from '../../Resource'
import { MESSAGES } from '../../constants/strings'

const CustomHostField = ({ formik: { values, errors, setFieldValue } }) => {
  const isHost = !!values?.hostId
  const isCustom = !!(values?.readReceipt && values?.readReceipt.length)
  const isNone = !values.hostId && !values?.readReceipt?.length
  const defaultHost = isHost ? values.hostId : isCustom ? 'Custom' : isNone ? 'None' : null

  const [selectedHost, setHost] = useState(defaultHost)
  const [customPhone, setCustomPhone] = useState(isCustom ? values?.readReceipt[0].contactNumber : '')
  const [customEmail, setCustomEmail] = useState(isCustom ? values?.readReceipt[0].email : '')

  useEffect(() => {
    formatHostValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHost, customPhone, customEmail])

  const formatHostValues = () => {
    if (selectedHost === 'None') {
      setFieldValue('hostId', null)
      setFieldValue('readReceipt', [])
    } else if (selectedHost === 'Custom') {
      setFieldValue('readReceipt', [{ contactNumber: customPhone, email: customEmail }])
      setFieldValue('hostId', null)
    } else {
      setFieldValue('readReceipt', [])
      setFieldValue('hostId', selectedHost)
    }
  }

  return (
    <Resource collection path={['hosts']} placeholder={[]}>
      {results => (
        <div>
          <Input
            type="select"
            value={selectedHost}
            onChange={evt => setHost(evt.target.value)}
            style={{ position: 'relative' }}>
            <option value="" hidden>
              Please Select
            </option>
            <option key="None" value="None">
              None
            </option>
            {results.length > 0 &&
              results.map(host => {
                if (host.name && host.contactNumber) {
                  return (
                    <option key={host.id} value={host.id}>
                      {host.name}
                    </option>
                  )
                } return <></>;
              })}
            <option key="Custom" value="Custom">
              Custom
            </option>
          </Input>

          {selectedHost === 'Custom' && (
            <div className="sub-options read-recipient">
              <div>
                <PhoneInput
                  defaultCountry="US"
                  international
                  countryCallingCodeEditable={false}
                  onChange={value => setCustomPhone(value)}
                  maxLength={15}
                  placeholder="Phone"
                  className="input"
                  inputClassName="form-control"
                  value={customPhone}
                />
                {errors?.readReceipt && errors?.readReceipt[0]?.contactNumber && (
                  <div className="invalid-feedback invalid-phone">
                    {errors?.readReceipt[0]?.contactNumber === 'required' ? 'required' : 'Invalid phone'}
                  </div>
                )}
              </div>
              <span className="and-or mb-2">and / or</span>
              <div className="has-email-field">
                <input
                  name="email"
                  className="input"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  value={customEmail}
                  onChange={evt => setCustomEmail(evt.target.value)}
                />
                {errors?.readReceipt && errors?.readReceipt[0]?.email && (
                  <div className="invalid-feedback">
                    {errors?.readReceipt[0]?.email === 'required' ? 'required' : 'Invalid email'}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Resource>
  )
}

export const HostFields = ({ size }) => {
  return (
    <Fragment>
      <FormGroup label="Host Name" htmlFor="name" size={size}>
        <TextField name="name" placeholder="ex. Joe" data-cy="input-host-name" maxLength="50" />
      </FormGroup>
      <FormGroup
        rowClassName="align-items-center"
        htmlFor="contactNumber"
        size={size}
        label={
          <>
            {MESSAGES.GUEST_SERVICE_NUMBER}
            <Tooltip tabIndex={-1} target="GuestServicesNumber">
              This number will be displayed to guests <br /> as the "Customer <br /> Service" Number.
            </Tooltip>
          </>
        }>
        <PhoneField data-cy="input-host-contact-number" name="contactNumber" />
      </FormGroup>
      <FormGroup label="ext." htmlFor="phoneExt" size={size}>
        <TextField name="phoneExt" data-cy="input-host-contact-number-ext" />
      </FormGroup>
      <FormGroup label="Host Photo" htmlFor="photoURL" size={size}>
        <ImageDropzoneField name="photoURL" label="Upload" desiredSize={{ width: 200, height: 200 }} />
      </FormGroup>
    </Fragment>
  )
}

export default connect(CustomHostField)
