import { get } from 'lodash'
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { GoToIcon } from '../../icons'
import '../../styles/WelcomeVideoContent.scss'
import { withContent } from '../content/ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import Select from 'react-select'
import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { ListCellBadge } from '../list'
import { MESSAGES } from '../../constants/strings'
import PropertyDrawer from '../drawer/PropertyDrawer'
import { pluralize } from '../../Utils'

const SelectLabel = ({ video: { title, status } }) => (
  <div className="SelectLabel">
    {title}
    {!status && <ListCellBadge label="Disabled" />}
  </div>
)

const WelcomeVideoContent = ({
  data = [],
  property,
  manageLink,
  onUpdateItem,
  emptyState,
  totalProperties,
  history,
  content,
}) => {
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})
  const selectedWelcomeVideo = data && data.find(video => video.propertyIds && video.propertyIds.includes(property.id))
  const EmptyIcon = get(emptyState, 'icon')
  const emptyText = get(emptyState, 'text')

  const changeHandler = ({ value }) => {
    if (selectedWelcomeVideo && selectedWelcomeVideo.id === value.id) return
    onUpdateItem({ ...value, propertyIds: [...value.propertyIds, property.id] })
  }

  const filterHandler = (option, searchText) => {
    if (option.data.value.title.toLowerCase().includes(searchText.toLowerCase())) {
      return true
    }
    return false
  }

  const editVideoHandler = () => {
    history.push(`${manageLink}/?edit=${selectedWelcomeVideo.id}`)
  }

  const addVideoHandler = () => {
    history.push(`${manageLink}/?add=${content.type}`)
  }

  const openRecipientProperties = (data, event) => {
    event.stopPropagation()
    setDrawerContent(data)
    if (data.properties.length > 0) {
      setDrawer(true)
    }
  }

  const options = data.reduce((options, video) => {
    options.push({
      value: video,
      label: <SelectLabel video={video} />,
    })
    return options
  }, [])

  return (
    <>
      <PropertyDrawer header="Content" drawerData={drawerContent} showDrawer={showDrawer} setDrawer={setDrawer} />
      <div className="ContentPicker WelcomeVideoCard">
        <div className="select-wrapper">
          <Select
            classNamePrefix="select"
            className="select-list"
            options={options}
            filterOption={filterHandler}
            onChange={changeHandler}
            value={options.filter(option => option.value.id === (selectedWelcomeVideo && selectedWelcomeVideo.id))}
          />
          <PopoverMenu>
            {selectedWelcomeVideo && (
              <PopoverMenuItem className="properties-applied">
                <div className="meta">{MESSAGES.USED_BY}</div>
                <div className="bold">
                  {selectedWelcomeVideo.propertyIds ? (
                    <div
                      className="link-text"
                      onClick={event => {
                        openRecipientProperties(selectedWelcomeVideo, event)
                        event.stopPropagation()
                      }}>
                      {`${
                        selectedWelcomeVideo.propertyIds ? selectedWelcomeVideo.propertyIds.length : 0
                      } of ${totalProperties} ${pluralize(totalProperties, 'property')}`}
                    </div>
                  ) : (
                    <div>
                      {`${selectedWelcomeVideo.propertyIds.length} of ${selectedWelcomeVideo} ${pluralize(
                        selectedWelcomeVideo,
                        'property'
                      )}`}
                    </div>
                  )}
                </div>
              </PopoverMenuItem>
            )}
            {selectedWelcomeVideo && (
              <PopoverMenuItem onClick={editVideoHandler}>
                <GoToIcon /> {MESSAGES.OPEN_THIS_COMPONENT}
              </PopoverMenuItem>
            )}
            <PopoverMenuItem onClick={addVideoHandler}>
              <GoToIcon /> {MESSAGES.CREATE_NEW_COMPONENT}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
        {selectedWelcomeVideo ? (
          <div className="welcome-video-preview px-4">
            <img
              className="welcome-video-preview-img"
              key={selectedWelcomeVideo.imageURL}
              src={selectedWelcomeVideo.imageURL}
              alt="welcome-video-preview"
            />
            <h4>{selectedWelcomeVideo.title}</h4>
            <p
              className="welcome-video-preview-text"
              dangerouslySetInnerHTML={{ __html: selectedWelcomeVideo.message }}
            />
          </div>
        ) : (
          <div className="empty-list no-border welcome-video-empty">
            {EmptyIcon && <EmptyIcon size={40} />}
            {emptyText && <div>{emptyText}</div>}
            <p>
              <strong className="cursor-pointer link" onClick={addVideoHandler}>
                Click here{' '}
              </strong>
              to add Welcome Video
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default withRouter(withContent(WelcomeVideoContent))
