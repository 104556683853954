import React from 'react'
import { SlideshowContainer } from '../../containers'

const SlideshowOld = () => {
  return (
    <div className="Slideshow TabContent pt-0">
      <SlideshowContainer />
    </div>
  )
}

export default SlideshowOld
