import React from 'react'
import classNames from 'classnames'

const PopoverMenuItem = ({
 children, setShowMenu, className, onClick, ...rest
}) => {
  const clickItem = event => {
    event.stopPropagation()
    setShowMenu(false)
    setTimeout(() => {
      onClick && onClick()
    }, 100)
  }
  return (
    <div
      className={classNames('PopoverMenuItem', className, { link: onClick })}
      onClick={event => clickItem(event)}
      {...rest}
    >
      {children}
    </div>
  )
}

export default PopoverMenuItem
