/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { Loading } from '../../components/PageStates'
import { OrderableList } from '../../components/list'
import { ServiceIcon } from '../../icons'
import { ADVERTISEMENT_COLUMNS } from '../../constants/tables/advertisements'

const NEW_ADVERTISEMENT_COLUMNS = [
  {
    id: 'advertisement',
    columns: [
      ADVERTISEMENT_COLUMNS[0].columns[0],
      ADVERTISEMENT_COLUMNS[0].columns[1],
      ADVERTISEMENT_COLUMNS[0].columns[2],
      ADVERTISEMENT_COLUMNS[0].columns[4],
    ],
  },
]

const AdvertisementTable = ({ data, onUpdateItem }) => {
  const filteredData = data.filter(ads => !Array.isArray(ads))

  const memorizedColumns = useMemo(() => NEW_ADVERTISEMENT_COLUMNS, [])

  return (
    <OrderableList
      columns={memorizedColumns}
      data={filteredData}
      orderable={false}
      updateOrder="reorder"
      relation="property_advertisement"
      onUpdateItem={onUpdateItem}
      item="advertisement"
      manageLink="/slideshow/advertisement"
      emptyState={{
        icon: ServiceIcon,
        text: 'You have not yet added any Advertisements for this property',
      }}
    />
  )
}

const Advertisement = ({ NestedResource }) => {
  return (
    <NestedResource collection path={['advertisements']} placeholderComponent={Loading}>
      {(active, { onUpdateItem }) => {
        return <AdvertisementTable data={active} onUpdateItem={onUpdateItem} />
      }}
    </NestedResource>
  )
}

export default Advertisement
