import React from 'react'
import moment from 'moment'
import { RRule } from 'rrule'
import { ordinalDate, pluralize } from '../../Utils'

const weekdays = [
  { value: 'SU', label: 'S' },
  { value: 'MO', label: 'M' },
  { value: 'TU', label: 'T' },
  { value: 'WE', label: 'W' },
  { value: 'TH', label: 'T' },
  { value: 'FR', label: 'F' },
  { value: 'SA', label: 'S' },
]

const weekButtons = frequency => {
  return (
    <div className="flex flex-row flex-wrap weekdays">
      {weekdays.map(days => {
        const { value, label } = days
        return (
          <button
            type="button"
            className={frequency.byweekday?.includes(RRule[value]) ? 'selected' : ''}
            value={value}
          >
            {label}
          </button>
        )
      })}
    </div>
  )
}

const monthDate = frequency => {
  const date = frequency.bymonthday[frequency.bymonthday.length - 1]
  return ordinalDate(date || frequency.bymonthday)
}

const plural = (interval, frequency) => {
  return pluralize(interval, frequency, null, false)
}

const MessagesDateType = ({ original }) => {
  const schedule = original.datetime
  const frequency = schedule.type === 'recurring' ? RRule.parseString(schedule.value) : ''
  const date = schedule.type === 'datetime' ? moment(schedule.value).format('MM/DD/YYYY') : ''
  const time = schedule.type === 'recurring' ? moment(schedule.time, 'HH:mm').format('hh:mm a') : moment(schedule.value).format('hh:mm a')

  switch (schedule.type) {
    case 'now':
      return 'Immediately'

    case 'datetime':
      return (
        <small>
          <date>{`Once on ${date} `}</date>
          <time>{`at ${time}`}</time>
        </small>
      )

    case 'relative':
      const relTime = schedule.frequencyType === 'hours' ? Math.abs(schedule.value / 60) : Math.abs(schedule.value)
      return (
        <small>
          {relTime}
          {' '}
          {schedule.frequencyType === 'hours' ? (relTime > 1 ? 'hours' : 'hour') : (relTime > 1 ? 'minutes' : 'minute')}
          {schedule.to === 'checkoutTime' ? ' before Check Out' : ' after Check In'}
        </small>
      )

    case 'recurring':
      return (
        <small>
          <interval>
            {frequency.freq === RRule.DAILY && (!frequency.interval || frequency.interval === 1) && 'Every day'}
            {frequency.freq === RRule.DAILY && frequency.interval > 1 && `Every ${frequency.interval} ${plural(frequency.interval, 'day')}`}
            {frequency.freq === RRule.WEEKLY && `Every ${frequency.interval} ${plural(frequency.interval, 'week')}`}
            {frequency.freq === RRule.MONTHLY && `Every month on ${monthDate(frequency)}`}
          </interval>
          {
            frequency.freq === RRule.WEEKLY
              ? weekButtons(frequency)
              : <time>{` at ${time}`}</time>
          }
        </small>
      )

    default:
      return '';
  }
}

export default MessagesDateType
