import React, { useState } from 'react'
import { Trash } from '../TinySvgs'

const SlideshowImageGrid = props => {
  const [insertionPoint, setinsertionPoint] = useState(-1)
  const [draggedImage, setdraggedImage] = useState(null)

  const onDragOver = e => {
    // get the insertion point from the event location
    const { clientX, clientY, currentTarget } = e
    const { top, left } = currentTarget.getBoundingClientRect()

    const relX = clientX - left
    const relY = clientY - top + currentTarget.scrollTop

    const idx = Math.floor(relY / 200) * 3 + Math.floor(relX / 120)
    setinsertionPoint(idx)
    e.preventDefault()
  }

  const onDrop = e => {
    const { images, onChange } = props

    e.stopPropagation()
    e.preventDefault()

    const standardURL = e.dataTransfer.getData('key')
    if (!standardURL) {
      return
    }

    const itemIndex = images.findIndex(i => i.image.standard === standardURL)
    if (itemIndex !== insertionPoint) {
      const next = [].concat(images)
      next.splice(itemIndex, 1)
      if (insertionPoint > itemIndex) {
        setinsertionPoint(insertionPoint - 1)
      }
      next.splice(insertionPoint, 0, images[itemIndex])
      onChange(next)
    }

    onClearInsertionPoint()
  }

  const onDragStart = (item, e) => {
    if (props.disabled) {
      e.preventDefault()
      return
    }
    e.dataTransfer.setData('key', item.image.standard)
    setdraggedImage(item.image.standard)
  }

  const onClearInsertionPoint = () => {
    setinsertionPoint(-1)
    setdraggedImage(null)
  }

  const { images, onSelect, onRemove, selectedIndex, disabled } = props

  const imageComponents = images.map((item, idx) => {
    const image = new Image()
    image.src = item.image.hiRes

    return (
      <div
        className={`item
      ${draggedImage === item.image.standard && 'dragging'}
      ${selectedIndex === idx && 'selected'}`}
        onDragStart={e => onDragStart(item, e)}
        onClick={() => onSelect(idx)}
        draggable={!disabled}
        key={`img${idx}`}>
        {!disabled && (
          <div className="trash" onClick={() => onRemove(item)}>
            {Trash}
          </div>
        )}
        {image.complete && (image.height < 1920 || image.width < 1080) && <span className="necicon-alert red-alert" />}
        <img key={item.image.standard} src={item.image.standard} alt="preview" width={1080} height={1920} />
      </div>
    )
  })

  if (insertionPoint !== -1) {
    imageComponents.splice(insertionPoint, 0, <div key="insertion" className="insertion-point" />)
  }

  return (
    <div className="DraggableImageGrid" onDragEnd={onClearInsertionPoint} onDragOver={onDragOver} onDrop={onDrop}>
      {imageComponents}
    </div>
  )
}

export default SlideshowImageGrid
