import { Drawer } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router'

const AdvertisementDrawer = ({
 showDrawer, setDrawer, data = {}, properties = [],
}) => {
  const newProperties = properties.length
    ? properties.filter(property => data.propertyIds && data.propertyIds.includes(property.id))
    : data.properties
  return (
    <Drawer anchor="right" open={showDrawer} onClose={() => setDrawer(false)}>
      <div className="dashboard-side-panel">
        <div className="close-side-panel">
          <span onClick={() => setDrawer(false)}>
            <i className="cross1" />
            <i className="cross2" />
          </span>
        </div>
        <div>
          <h4>{data.title || data.name}</h4>
          <p>
            {newProperties && newProperties.length
              ? `The ${data.type ? 'Ad' : 'Slideshow'} is assigned to ${newProperties.length} ${
                  newProperties.length === 1 ? 'property' : 'properties'
                }.`
              : `The ${data.type ? 'Ad' : 'Slideshow'} is not assigned to any properties.`}
          </p>
          <div>
            <p className="listing-bold">
              {newProperties && (
                <ul>
                  {newProperties.map(property => (
                    <li>{property.nickname ? property.nickname : property.addressLine1}</li>
                  ))}
                </ul>
              )}
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default withRouter(AdvertisementDrawer)
