import React from 'react'
import classNames from 'classnames'

const DeviceAddress = ({ nickname, address, status }) => {
  const addressClass = classNames({ disabled: status === 'UNPAIRED' })

  return (
    <div className={classNames('title-cell', addressClass)}>
      <div className="first-line">{nickname}</div>
      <div className="second-line">{address}</div>
    </div>
  )
}

export default DeviceAddress
