import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { CARD_CONTENT_FIRST, CARD_CONTENT_SECOND, ADDITIONAL_STRINGS } from '../constants/home'
import { AddPropertyCard, HomeCard, HomeDrawer } from '../components/home'
import Resource from '../Resource'
import { Loading } from '../components/PageStates'

function HomeContainer() {
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})

  const populateDrawer = (cardData = {}) => {
    setDrawerContent(cardData)
    setDrawer(true)
  }
  return (
    <Resource path={['home', 'properties-highlights']} placeholderComponent={Loading}>
      {propertyHighlights => (
        <Resource path={['home', 'cards']} placeholderComponent={Loading}>
          {homeCards => (
            <div className="pm-dashboard row m-0">
              <HomeDrawer drawerData={drawerContent} showDrawer={showDrawer} setDrawer={setDrawer} />
              <div className="col-lg-4 add-card">
                <AddPropertyCard
                  propertyHighlights={propertyHighlights}
                  cardData={homeCards.cards.find(data => data.name === 'RESERVATIONS')}
                  populateDrawer={populateDrawer}
                />
              </div>
              <div className="col-lg-4 property-col1">
                <h4 className="db-card-title" title="Content for your digital concierge">
                  <i>{ADDITIONAL_STRINGS.NUMBER_TWO}</i>
                  <span>{ADDITIONAL_STRINGS.CONCIERGE_HEADING}</span>
                </h4>
                <div className="property-data">
                  {CARD_CONTENT_FIRST.map(card => (
                    <HomeCard
                      cardDetails={card}
                      cardData={homeCards.cards.find(data => data.name === card.name)}
                      populateDrawer={populateDrawer}
                    />
                  ))}
                </div>
              </div>
              <div className="col-lg-4 property-col2">
                <h4>&nbsp;</h4>
                <div className="property-data">
                  {CARD_CONTENT_SECOND.map(card => (
                    <HomeCard
                      cardDetails={card}
                      cardData={homeCards.cards.find(data => data.name === card.name)}
                      populateDrawer={populateDrawer}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </Resource>
      )}
    </Resource>
  )
}

export default withRouter(HomeContainer)
