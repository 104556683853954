import React from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { AddIcon } from '../../icons'

const AddButton = ({
  to, onClick, history, item, verb, dataCy, className, id, style = {}, disabled, type = undefined,
}) => {
  const action = to ? () => history.push(to) : onClick
  return (
    <button disabled={disabled} type={type} id={id} style={style} className={classNames('button', className)} onClick={() => action()} data-cy={dataCy}>
      <AddIcon />
      {verb}
      {' '}
      {item}
    </button>
  )
}

export default withRouter(AddButton)

AddButton.defaultProps = {
  verb: 'New',
}
