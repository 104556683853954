import React from 'react'
import Icon from './Icon'

function EditIcon(props) {
  return (
    <Icon viewBox="0 0 12 12" {...props} width="1em" height="1em">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.5.5l2 2-7 7-3 1 1-3 7-7z"
      />
    </Icon>
  )
}

export default EditIcon
