import React from 'react'
import Icon from './Icon'

function AmenitiesIcon(props) {
  return (
    <Icon viewBox="0 0 24 17" {...props} width="1em" height="1em">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 .462a.462.462 0 0 1 .923 0v11.23H23.54a.461.461 0 0 1 .435.616.46.46 0 0 1 .026.153v3.693a.461.461 0 1 1-.923 0v-3.539H.923v3.539a.462.462 0 1 1-.923 0V.462zm7.077 6.615a2.154 2.154 0 1 1-4.308 0 2.154 2.154 0 0 1 4.308 0zm13.538-2.154H8.308v4.923h14.769V7.385a2.461 2.461 0 0 0-2.462-2.462z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h24v16.615H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default AmenitiesIcon
