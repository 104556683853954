import React from 'react'
import { ContentEditor, ContentEditorDescription, ContentEditorTable } from '.'
import {
  CHECK_IN_OUT,
  CHECKIN_SURVEY,
  CHECKOUT_SURVEY,
  CHECKOUT_DETAILS,
  CHECKOUT_CHECKLIST,
  CHECK_IN_OUT_NOTIFICATION,
} from '../../constants/forms/content'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const CONTENT_SECTIONS = [
  CHECK_IN_OUT_NOTIFICATION,
  CHECK_IN_OUT,
  CHECKOUT_DETAILS,
  CHECKOUT_CHECKLIST,
  CHECKIN_SURVEY,
  CHECKOUT_SURVEY,
]

const CheckInOut = ({ properties, recordAnalytics }) => {
  return (
    <div className="CheckInOut">
      {CONTENT_SECTIONS.map((section, index) => (
        <ContentEditor key={index}>
          <ContentEditorDescription title={section.title} description={section.description} />
          <ContentEditorTable
            header={section.title}
            content={section}
            columns={section.columns}
            properties={properties}
            recordAnalytics={recordAnalytics.bind(this, SECTIONS.CHECK_IN_OUT_INFO)}
          />
        </ContentEditor>
      ))}
    </div>
  )
}

export default CheckInOut
