export const PAYABLE_FEE_TYPES = ['hourly', 'daily', 'fixed']
export const NON_PAYABLE_FEE_TYPES = ['free', 'custom']

export const SERVICE_TEMPLATES = {
  BUNDLE: 'bundle',
}

export const SERVICE_FEE_TYPES = {
  CUSTOM: 'custom',
}

export const FEE_OPTIONS = {
  fixed: 'Fixed',
  hourly: 'Hourly',
  daily: 'Daily',
  free: 'Free',
  custom: 'Custom',
}
