import React from 'react'
import { get, isArray, isFunction } from 'lodash'
import classNames from 'classnames'

const TextPreview = ({
 formik: { values }, name, prefix, format, placeholder, placeholderOnly, suffix, ...props
}) => {
  let value = get(values, name)
  if (isArray(name)) {
    const currentValue = Object.entries(values).find(([key, value]) => value && name.includes(key))
    if (currentValue) {
      [name, value] = currentValue
    }
  }

  if (format && isFunction(format)) value = format(value, values)

  let content = ''
  if (placeholderOnly) {
    if (placeholder && value) {
      content = placeholder
    }
  } else {
    content = (
      <>
        {prefix}
        {value || placeholder}
        {' '}
        {suffix}
      </>
    )
  }

  return (
    <div {...props.props} className={classNames('PreviewText', props.className, { placeholderOnly })}>
      {content}
    </div>
  )
}

export default TextPreview
