import React, { useEffect } from 'react'
import ImageDropzoneInput from './ImageDropzoneInput'

import '../styles/RulesSetInput.scss'

const StepsSetInput = ({ onChange, value, page }) => {
  let _lastEl

  useEffect(() => {
    onStart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStart = () => {
    if (value === undefined || value.length === 0) {
      onChange([].concat(value, [{ text: '', imageURL: '' }]))
    }
  }

  const onAddItem = () => {
    onChange([].concat(value, [{ text: '', imageURL: '' }]))
    window.requestAnimationFrame(onSelectLast)
  }

  const onChangeItem = (idx, text, imageURL) => {
    const next = JSON.parse(JSON.stringify(value))
    next[idx].imageURL = imageURL
    next[idx].text = text
    onChange(next)
  }

  const onRemoveItem = idx => {
    const next = JSON.parse(JSON.stringify(value))
    next.splice(idx, 1)
    onChange(next)

    window.requestAnimationFrame(onSelectLast)
  }

  const onSelectLast = () => {
    if (_lastEl) {
      _lastEl.focus()
    }
  }

  return (
    <div className="TipsSetInput">
      {value.map((v, idx) => {
        const isLast = idx === value.length - 1
        const isEmpty = v.text === ''

        return (
          <div id="step-by-step-uploader" className="tip" key={idx}>
            {page === 'PropertyInfoModal' && (
              <div className="d-flex align-items-center flex-direction-row">
                <div>
                  <ImageDropzoneInput
                    style={{
                      maxWidth: '237px',
                    }}
                    value={v.imageURL}
                    label="Upload"
                    desiredSize={{ width: 800, height: 600 }}
                    onChange={newImageURL => onChangeItem(idx, v.text, newImageURL)}
                  />
                </div>
                <span className="necicon-trash-bin amenity-delete-icon" onClick={() => onRemoveItem(idx)} />
              </div>
            )}
            <textarea
              type="text"
              maxLength="180"
              placeholder="Caption"
              className="form-control overflow-auto"
              style={{ marginTop: 10, marginBottom: 10 }}
              value={v.text}
              ref={isLast ? el => (_lastEl = el) : undefined}
              onChange={e => onChangeItem(idx, e.target.value, v.imageURL)}
              onKeyDown={e => (e.keyCode === 13 && !isEmpty ? onAddItem() : null)}
              onKeyUp={e => (value.length > 1 && e.keyCode === 8 && isEmpty ? onRemoveItem(idx) : null)}
            />
          </div>
        )
      })}
      <div className="link inline" onClick={onAddItem}>
        + Add Another
      </div>
    </div>
  )
}

export default StepsSetInput
