import React from 'react'
import { Tabs } from '../../components'
import { WelcomeScreenIcon, WelcomeVideosIcon } from '../../icons'
import { WelcomeVideo, WelcomeScreen } from '.'

const WelcomeScreenComponent = ({ property }) => {
  return (
    <div>
      <WelcomeScreen property={property} />
    </div>
  )
}

const WelcomeVideoComponent = ({ property, NestedResource }) => {
  return (
    <div>
      <WelcomeVideo property={property} />
    </div>
  )
}

const WELCOME_SCREEN_AND_VIDEO_TABS = [
  {
    slug: '',
    component: WelcomeScreenComponent,
    label: 'Welcome Screen',
    icon: WelcomeScreenIcon,
  },
  {
    slug: 'videos',
    component: WelcomeVideoComponent,
    label: 'Welcome Video',
    icon: WelcomeVideosIcon,
  },
]

const WelcomeScreenAndVideo = ({ property, NestedResource }) => {
  return (
    <div className="WelcomeScreenAndVideo">
      <Tabs
        navTitle="Welcome Screen"
        path={`properties/${property.id}/welcome`}
        tabs={WELCOME_SCREEN_AND_VIDEO_TABS}
        property={property}
        isSubTabs
        NestedResource={NestedResource}
      />
    </div>
  )
}

export default WelcomeScreenAndVideo
