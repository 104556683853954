import React, { useEffect, useRef, useState } from 'react'
import { Pencil } from '../TinySvgs'

const InlineEditableTitle = props => {
  const [editing, setediting] = useState(false)
  const { value, onChange } = props
  const didMount = useRef(false)
  const _titleInput = useRef()

  useEffect(() => {
    // Return early, if this is the first render:
    if (!didMount.current) {
      return (didMount.current = true)
    }
    _titleInput.current.focus()
    _titleInput.current.select()
  }, [editing])

  const onEdit = () => setediting(true)

  return editing ? (
    <div>
      <input
        type="text"
        className="title-input"
        data-cy="title-input"
        maxLength="50"
        defaultValue={value}
        ref={_titleInput}
        onKeyDown={e => e.keyCode === 13 && e.target.blur()}
        onBlur={() => {
          onChange(_titleInput.current.value)
        }}
      />
    </div>
  ) : (
    <h2 style={{ fontWeight: 300 }} onClick={onEdit} data-cy="title-input-edit" className="has-inline-edit">
      {value}{' '}
      <div className="title-edit-link link inline" onClick={onEdit}>
        {Pencil}
      </div>
    </h2>
  )
}

export default InlineEditableTitle
