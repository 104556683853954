import { COMMON_FIELDS } from '.'
import { Yup } from '../../../forms'
import {
 CheckboxField, PhoneField, TextareaField, TextField, TextFieldMeta, TimeSelector,
} from '../../../forms/fields'
import PaymentProvider from '../../../forms/fields/PaymentProvider'
import ServiceChoices from '../../../forms/fields/ServiceChoices'
import fadedServicePromoCheckout from '../../../images/FadedServicePromoCheckout.png'
import fadedServicePromoHomePage from '../../../images/FadedServicePromoHomePage.png'
import fadedServicePromoServiceListing from '../../../images/FadedServicePromoListing.png'
import servicePromoCheckout from '../../../images/ServicePromoCheckout.png'
import servicePromoHomePage from '../../../images/ServicePromoHomePage.png'
import servicePromoServiceListing from '../../../images/ServicePromoListing.png'
import { PAYABLE_FEE_TYPES } from '../../services'

const feeOptions = [
  { fixed: 'Fixed' },
  { hourly: 'Hourly' },
  { daily: 'Daily' },
  { free: 'Free' },
  { custom: 'Custom' },
]

const REBOOK_FIELDS = {
  name: {
    ...COMMON_FIELDS.name,
    props: {
      ...COMMON_FIELDS.name.props,
      placeholder: 'ex: Rebook within 7 days and save!',
    },
  },
  draggableMedia: {
    ...COMMON_FIELDS.draggableMedia,
    name: 'serviceMedias',
    validation: Yup.array()
      .required('required')
      .default([
        {
          mediaType: 'IMAGE',
          mediaUrl: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_rebooking-1280x960.jpg',
        },
      ]),
  },
  phone: {
    label: 'Reservation Phone',
    name: 'phone',
    component: PhoneField,
  },
  requestMessage: {
    label: 'Message to Guest',
    name: 'requestMessage',
    component: TextareaField,
    props: {
      placeholder: 'Save money when you book directly through our website.',
      maxLength: 120,
      shouldToggle: true,
    },
  },
  rebookUrl: {
    label: 'Rebooking Url',
    name: 'rebookUrl',
    component: TextField,
    validation: Yup.string().matches(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      'value is not a valid url',
    ),
  },
  promoCode: {
    label: 'Promo Code',
    name: 'promoCode',
    component: TextField,
    validation: Yup.string().max(255, 'Promo code should be less than 256 characters'),
    options: {
      size: 'small',
    },
  },
  promoExpirationDays: {
    label: 'Promo Expiration',
    name: 'promoExpirationDays',
    component: TextFieldMeta,
    props: {
      formatter: 'days',
      afterField: 'days',
    },
    options: {
      size: 'x-small',
    },
  },
  nickName: {
    ...COMMON_FIELDS.nickName,
    props: {
      ...COMMON_FIELDS.nickName.props,
      placeholder: 'ex: Rebooking with 10% coupon with 7 day expiration',
    },
  },
}

const CUSTOM_FIELDS = {
  name: {
    ...COMMON_FIELDS.name,
    props: {
      ...COMMON_FIELDS.name.props,
      placeholder: 'ex: Custom Service',
    },
  },
  image: {
    ...COMMON_FIELDS.image,
    validation: Yup.string().default(
      'https://nec-assets.s3.amazonaws.com/default-images/img_service_generic-1280x960.jpg',
    ),
  },
  feeFields: {
    ...COMMON_FIELDS.feeFields,
    props: {
      feeOptions,
      customFeeMessage: {
        maxLength: 25,
      },
      price: {
        isAllowed: values => {
          const { formattedValue, value } = values
          return formattedValue === '' || (value && value.length) <= 9
        },
      },
    },
    validation: {
      ...COMMON_FIELDS.feeFields.validation,
      feeType: Yup.string()
        .required('required')
        .default('fixed'),
    },
  },
  nickName: {
    ...COMMON_FIELDS.nickName,
    props: {
      ...COMMON_FIELDS.nickName.props,
      placeholder: 'ex: Custom Service',
    },
  },
  options: {
    label: 'Options',
    name: 'options',
    className: 'mb-0',
    validation: { allowQuantity: Yup.boolean().default(false) },
    component: [
      {
        component: CheckboxField,
        props: {
          name: 'allowQuantity',
          label: 'Allow Guest to request a specific quantity',
        },
      },
    ],
  },
  orderDeadline: {
    ...COMMON_FIELDS.orderDeadline,
    validation: Yup.string()
      .nullable()
      .notRequired(),
  },
  serviceInstructions: {
    ...COMMON_FIELDS.detailHTML,
    label: 'Service Instructions',
    name: 'serviceInstructions',
  },
  promote: {
    ...COMMON_FIELDS.promote,
    props: {
      options: [
        {
          value: 'enabled',
          label: 'Enabled',
          isHead: true,
          image: {
            enabled: servicePromoServiceListing,
            disabled: fadedServicePromoServiceListing,
          },
        },
        {
          value: 'homepage',
          label: 'Homepage',
          head: 'enabled',
          image: {
            enabled: servicePromoHomePage,
            disabled: fadedServicePromoHomePage,
          },
        },
        {
          value: 'checkout',
          label: 'Check Out',
          head: 'enabled',
          image: {
            enabled: servicePromoCheckout,
            disabled: fadedServicePromoCheckout,
          },
        },
      ],
      row: true,
    },
    validation: Yup.array().default(['enabled']),
  },
  draggableMedia: {
    ...COMMON_FIELDS.draggableMedia,
    name: 'serviceMedias',
    validation: Yup.array()
      .required('required')
      .default([
        {
          mediaType: 'IMAGE',
          mediaUrl: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_generic-1280x960.jpg',
        },
      ]),
  },
  serviceChoices: {
    additionalFieldClass: 'col-sm-12',
    component: ServiceChoices,
    name: 'serviceChoices',
    validation: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string()
            .required('Required')
            .matches(/^(?!\s+$).*/, 'Blank spaces are not allowed in this field'),
          serviceChoiceMedias: Yup.array()
            .of(
              Yup.object().shape({
                mediaUrl: Yup.string().required('Required'),
                mediaType: Yup.string().required('Required'),
              }),
            )
            .atLeast(1)
            .label('serviceChoiceMedias')
            .required('Required'),
          feeRates: Yup.array()
            .of(
              Yup.object().shape({
                price: Yup.number()
                  .nullable()
                  .when('feeType', {
                    is: feeType => !!PAYABLE_FEE_TYPES.includes(feeType),
                    then: Yup.number()
                      .required('Required')
                      .test('is-positive-integer', 'fee must be greater than or equal to $0.50', v => v >= 50),
                  }),
                customFeeMessage: Yup.string()
                  .max(25)
                  .nullable()
                  .when('feeType', {
                    is: feeType => feeType === 'custom',
                    then: Yup.string()
                      .trim()
                      .max(100, '100 characters allowed')
                      .required('Required'),
                  }),
                feeType: Yup.string()
                  .required('Required')
                  .nullable(),
              }),
            )
            .atLeast(1)
            .label('feeRates')
            .required('Required'),
        }),
      )
      .default([
        {
          title: '',
          serviceChoiceMedias: [],
          feeRates: [
            {
              feeType: 'fixed',
              price: null,
              customFeeMessage: '',
            },
          ],
          detailHTML: '',
          order: 1,
        },
      ])
      .atLeast(1)
      .label('serviceChoices'),
  },
  allowMultiSelection: {
    label: 'Service Selection',
    name: 'multiSelection',
    className: 'mb-0',
    validation: { allowMultiSelection: Yup.boolean().default(true) },
    component: [
      {
        component: CheckboxField,
        props: {
          name: 'allowMultiSelection',
          label: 'Yes',
        },
      },
    ],
  },
  hasPaymentProvider: {
    name: 'hasPaymentProvider',
    component: PaymentProvider,
    className: 'mb-0',
    validation: Yup.boolean().default(false),
  },
}

const LATECHECKOUT_FIELDS = {
  name: {
    ...COMMON_FIELDS.name,
    props: {
      ...COMMON_FIELDS.name.props,
      placeholder: 'ex: Late Check Out',
    },
  },
  draggableMedia: {
    ...COMMON_FIELDS.draggableMedia,
    name: 'serviceMedias',
    validation: Yup.array()
      .required('required')
      .default([
        {
          mediaType: 'IMAGE',
          mediaUrl: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_latecheckout-1280x960.jpg',
        },
      ]),
  },
  feeFields: {
    ...COMMON_FIELDS.feeFields,
    props: {
      feeOptions: [{ fixed: 'Fixed' }, { free: 'Free' }],
    },
  },
  checkoutTimeSelector: {
    label: 'Time to add to late check out',
    name: 'additionalCheckoutTime',
    component: TimeSelector,
    required: true,
    options: {
      size: 'small',
    },
    validation: Yup.number().required('required'),
  },
  nickName: {
    ...COMMON_FIELDS.nickName,
    props: {
      ...COMMON_FIELDS.nickName.props,
      placeholder: 'ex: Late Check Out +2 hours for 5-7 bedroom homes',
    },
  },
  enabledWithBtbRes: {
    label: 'Service Status',
    name: 'enabled',
    fieldClassName: 'd-flex',
    validation: {
      enabled: Yup.boolean().default(true),
      extra: Yup.object().shape({
        disableOnBtbRes: Yup.boolean()
          .default(true)
          .nullable(),
      }),
    },
    component: [
      {
        component: CheckboxField,
        props: {
          name: 'enabled',
          label: 'Enable',
          isHead: true,
          className: 'mr-4',
          relations: [
            {
              name: 'extra.disableOnBtbRes',
              onCheck: true,
              onUnCheck: false,
            },
            {
              name: 'promote',
              onCheck: true,
              onUnCheck: false,
              defaultValue: 'serviceListing',
            },
          ],
        },
      },
      {
        component: CheckboxField,
        props: {
          name: 'extra.disableOnBtbRes',
          // label: 'Disable Late Check Out in case of back to back reservations',
          label: 'Disable in case of back to back reservations',
          className: 'row-choice-field service-status',
          related: [
            {
              name: 'enabled',
              disableOnUnCheck: true,
            },
          ],
        },
        head: 'enabled',
      },
    ],
  },
}

const MID_STAY_CLEAN_FIELDS = {
  name: {
    ...COMMON_FIELDS.name,
    props: {
      ...COMMON_FIELDS.name.props,
      placeholder: 'ex: Mid-Stay Cleaning',
    },
  },
  draggableMedia: {
    ...COMMON_FIELDS.draggableMedia,
    name: 'serviceMedias',
    validation: Yup.array()
      .required('required')
      .default([
        {
          mediaType: 'IMAGE',
          mediaUrl: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_midstay_cleaning-1280x960.jpg',
        },
      ]),
  },
  summarySubtitle: {
    ...COMMON_FIELDS.summarySubtitle,
    props: {
      placeholder: '(Clean the entire property)',
      maxLength: 180,
      shouldToggle: true,
    },
  },
  nickName: {
    ...COMMON_FIELDS.nickName,
    props: {
      ...COMMON_FIELDS.nickName.props,
      placeholder: 'ex: Mid-Stay Cleaning for 4000-5000 sqft homes',
    },
  },
  approvalRequests: {
    ...COMMON_FIELDS.approvalRequests,
    validation: {
      ...COMMON_FIELDS.approvalRequests.validation,
      requireApproval: Yup.boolean().default(true),
    },
  },
}

export {
 REBOOK_FIELDS, CUSTOM_FIELDS, LATECHECKOUT_FIELDS, MID_STAY_CLEAN_FIELDS,
}
