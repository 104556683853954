import React, { Fragment, useEffect, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
// import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Resource from '../Resource'
// import { Maintenance } from '../components'

import {
  MetricsHeader,
  PageViews,
  TopProperties,
  TopSections,
  Services,
  FeedbackReportContainer,
  TopPages,
  TopPhoneMessages,
  AnalyticsControls,
  PropertyAnalyticsInfo,
} from './Metrics'
import '../styles/AnalyticsContainer.scss'
import { Loading } from '../components/PageStates'
import WelcomeVideos from './Metrics/WelcomeVideos'
// import { thirdPartyAnalytics } from '../services'
// import { ACTIONS, EVENTS } from '../constants/thirdPartyAnalytics'

const AnalyticsContainer = ({ location: { search }, property }) => {
  const [propertyId, setPropertyId] = useQueryParam('propertyId', NumberParam) // todo: make array param to handle multiple propertyIds
  // const [maintenanceStatus, setMaintenanceStatus] = useState(false)

  // const { me: userInfo } = useSelector(state => state.data)
  // const analyticsProperties = {
  //   organization: userInfo && userInfo.organization.name,
  //   user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  // }

  // useEffect(() => {
  //   setMaintenanceStatus(JSON.parse(localStorage.getItem('maintenance')))
  // }, [])

  // useEffect(() => {
  //   if (search) {
  //     thirdPartyAnalytics.track(EVENTS.ANALYTICS, { ...analyticsProperties, action: ACTIONS.SEARCH })
  //   }
  // }, [search])

  const ready = !property || property.id === propertyId
  if (!!property && propertyId !== property.id) {
    setPropertyId(property.id)
  }

  return (
    <div className="AnalyticsContainer">
      <div className="MetricsContainer">
        <AnalyticsControls property={property} />
        {search && ready && (
          <Resource collection path={['analytics', search]} placeholderComponent={Loading}>
            {analytics => {
              const { singleProperty, spying, hasCompletedReservations } = analytics
              return (
                <Fragment>
                  {singleProperty && spying && <PropertyAnalyticsInfo property={singleProperty} />}
                  <div className="CardsContainer">
                    {!analytics.error ? (
                      <Fragment>
                        <div className="MetricsMain card shadow-sm rounded">
                          {hasCompletedReservations && <MetricsHeader params={search} />}
                          <PageViews params={search} />
                          <TopSections params={search} />
                          {!singleProperty && <TopProperties {...analytics} params={search} />}
                          <TopPages {...analytics} params={search} />
                          <TopPhoneMessages {...analytics} params={search} />
                        </div>
                        <div className="MetricsSidebar">
                          <div className="card shadow-sm rounded">
                            <Services {...analytics} />
                          </div>
                          <div className="card shadow-sm rounded">
                            <WelcomeVideos params={search} />
                          </div>
                          <div className="card shadow-sm rounded">
                            <FeedbackReportContainer {...analytics} params={search} />
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <div>{analytics.error}</div>
                    )}
                  </div>
                </Fragment>
              )
            }}
          </Resource>
        )}
      </div>
    </div>
  )
}

export default withRouter(AnalyticsContainer)
