import React from 'react'
import { get, debounce } from 'lodash'
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'
import { fieldify } from './utils'

const FORMATTERS = {
  currency: {
    prefix: '$',
    placeholder: '$',
    format: value => {
      if (!Number(value)) return ''
      const amount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value / 100)
      return `${amount}`
    },
  },
  hours: {
    format: '####',
  },
  days: {
    format: '####',
  },
  number: {
    placeholder: '0',
    format: value => {
      if (!Number(value)) return ''
      return value
    },
  },
}

const TextField = fieldify(
  ({
    onFormat,
    field,
    form,
    formatter,
    fast,
    invalid,
    maxLength,
    extraInformation,
    saveOnExit,
    skipBlurMode = false,
    validateForm = null,
    textprefix,
    ...rest
  }) => {
    const {
      prefix, placeholder, thousandSeparator, format,
    } = get(FORMATTERS, formatter, '')

    const onChangeFormatInput = values => {
      const value = values.value ? values.value : null
      field.onChange({ target: { name: field.name, value } })
      // validateForm && validateForm()
    }

    const onBlur = evt => {
      if (!skipBlurMode) {
        rest.onBlur && rest.onBlur(evt)
        field.value && form.setFieldValue(field.name, field.value.trim())
      }
      validateForm && validateForm()
      form.validateOnBlur && form.validateForm()
      saveOnExit && form.handleSubmit()
    }

    const onFocus = evt => {
      const input = evt.target.value
      if (input.length < 1 && textprefix) {
        evt.target.value = textprefix
      }
    }

    return formatter ? (
      <NumberFormat
        className={invalid && 'is-invalid form-control'}
        customInput={Input}
        thousandSeparator={thousandSeparator}
        placeholder={rest.disabled ? null : placeholder}
        prefix={prefix}
        format={format}
        onValueChange={onChangeFormatInput}
        {...rest}
        onBlur={debounce(onBlur, 300)}
        value={field.value === null ? '' : field.value}
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault()
        }}
      />
    ) : (
      <>
        <Input
          invalid={invalid}
          {...field}
          value={field.value === null ? '' : field.value}
          {...rest}
          onBlur={debounce(onBlur, 300)}
          maxLength={maxLength}
          onFocus={onFocus}
        />
        {extraInformation && (
          <div style={{ marginTop: 8 }} className="small">
            {extraInformation}
          </div>
        )}
      </>
    )
  },
)

export default TextField
