import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { generateWizard } from '../../forms/utils'
import { RESERVATION_WIZARD } from '../../constants/forms/reservation'
import { Wizard } from '../../components/wizard'

const ReservationModalContainer = ({ onSave, dispatch, timezone, reservation = {} }) => {
  const reservationForm = generateWizard({ form: RESERVATION_WIZARD({ timezone }), props: reservation })

  const footerComponent = _props => <ModalFooter {..._props} onSaveText="Done" />

  const titleComponent = _props => (
    <ModalHeader {..._props} name="Reservation" verb={{ create: 'Add a' }} hasItem={reservation} />
  )

  const formatter = data => {
    if (reservation) {
      data = { ...reservation, ...data }
    }
    return data
  }

  return (
    <Wizard
      titleComponent={titleComponent}
      bodyWrapper={ModalBody}
      formData={reservationForm}
      footerWrapper={footerComponent}
      onSave={onSave}
      dispatch={dispatch}
      formatValues={formatter}
    />
  )
}

export default ReservationModalContainer
