import React, { useMemo } from 'react'
import classNames from 'classnames'
import { isEmpty, sortBy } from 'lodash'
import { PopoverMenu } from '../ui'
import { FilterIcon } from '../../icons'

import '../../styles/ListFilters.scss'

const FilterPills = ({
 column: {
 Header, filterValue, setFilter, preFilteredRows, id,
},
}) => {
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    const sortedOptions = sortBy([...options.values()], option => option.toLowerCase())
    return sortedOptions
  }, [id, preFilteredRows])

  const onChange = e => {
    const { value } = e.target
    if (filterValue === undefined || isEmpty(filterValue)) {
      const newValues = [value]
      setFilter(newValues)
    } else if (!filterValue.includes(value)) {
      const newValues = filterValue
      newValues.push(value)
      setFilter(newValues)
    } else {
      const newValues = filterValue.filter(v => v !== value)
      setFilter(newValues)
    }
  }

  const filterOn = !isEmpty(filterValue)

  return (
    <PopoverMenu Icon={FilterIcon} autoClose={false} className={classNames('FilterPills', { 'filters-on': filterOn })}>
      <div className="header">
        <h5 className="title bold">{Header}</h5>
        <button className={classNames('link s', { disabled: !filterOn })} onClick={() => setFilter([])}>
          Clear
        </button>
      </div>
      <div className="filter-options">
        {options.map(option => {
          return (
            <label className="filter-option" key={option}>
              <input
                type="checkbox"
                id={option}
                name={option}
                value={option}
                onChange={e => onChange(e)}
                checked={filterValue ? filterValue.includes(option) : false}
              />
              <div className="pseudo-input">{option}</div>
            </label>
          )
        })}
      </div>
    </PopoverMenu>
  )
}

export default FilterPills
