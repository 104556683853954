import React, { useEffect, useRef, useState } from 'react'

const FocusRegion = (props) => {
  const [hasFocus, setHasFocus] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (isActive()) {
      document.addEventListener('focus', onDocumentFocusWillChange, true)
      document.addEventListener('blur', onDocumentFocusWillChange, true)
    }

    return () => {
      if (isActive()) {
        document.removeEventListener('focus', onDocumentFocusWillChange, true)
        document.removeEventListener('blur', onDocumentFocusWillChange, true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus, props.onBlur, props.onFocus]);

  const isActive = () => {
    const { onBlur, onFocus } = props
    return !!onBlur || !!onFocus
  }

  const onDocumentFocusWillChange = () => {
    setTimeout(() => {
      if (!ref.current) return false
      const _hasFocus = document.activeElement && ref.current.contains(document.activeElement)
      if (_hasFocus !== hasFocus) {
        setHasFocus(_hasFocus);
        if (_hasFocus === false && props.onBlur) {
          props.onBlur();
        }
        if (_hasFocus === true && props.onFocus) {
          props.onFocus();
        }
      }
    }, 0)
  }

  return (
    <div
      tabIndex={isActive() ? 0 : null}
      ref={ref}
      className={`focus-region ${hasFocus ? 'focused' : 'unfocused'} ${props.className}`}
    >
      {props.children}
    </div>
  )
}

export default FocusRegion
