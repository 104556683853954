import * as React from 'react'

function AdsIcon() {
  return (
    <span className="Icon is-slideshow2">
      <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.64872 12.0592C4.74083 12.0592 4.83088 12.0865 4.90747 12.1377C4.98406 12.1889 5.04376 12.2616 5.07901 12.3467C5.11426 12.4318 5.12348 12.5255 5.10551 12.6158C5.08754 12.7062 5.04318 12.7891 4.97805 12.8543C4.91291 12.9194 4.82993 12.9638 4.73958 12.9817C4.64924 12.9997 4.55559 12.9905 4.47049 12.9552C4.38538 12.92 4.31264 12.8603 4.26147 12.7837C4.21029 12.7071 4.18298 12.6171 4.18298 12.5249C4.18298 12.4014 4.23204 12.283 4.31939 12.1956C4.40673 12.1083 4.5252 12.0592 4.64872 12.0592ZM3.72021 12.3338L4.08158 13.2846C4.16065 13.4906 4.31804 13.657 4.51939 13.7474C4.72073 13.8378 4.94967 13.8449 5.15621 13.7671L5.52626 13.6269C5.53292 13.654 5.54125 13.6806 5.5512 13.7067L6.16333 15.3167L7.76551 14.7474L7.1401 13.1029C7.13027 13.0767 7.11877 13.0512 7.10567 13.0265L7.57819 12.8467C7.61697 12.8687 7.6601 12.8818 7.70452 12.8852L11.2372 13.139L9.17928 7.67096L6.6936 10.2258C6.6624 10.2577 6.63878 10.2963 6.62447 10.3385L4.20249 11.2603C3.99661 11.3392 3.83032 11.4964 3.73996 11.6976C3.6496 11.8987 3.6425 12.1275 3.72021 12.3338Z"
          fill="#5591C7"
        />
        <path
          d="M10.3118 6.50742L9.58957 7.24941L11.822 13.1821L12.8793 13.2585C12.9297 13.2622 12.9802 13.2531 13.0261 13.232C13.072 13.211 13.1119 13.1787 13.142 13.1381C13.1721 13.0976 13.1915 13.0501 13.1984 13.0001C13.2053 12.95 13.1994 12.8991 13.1813 12.8519L10.8073 6.61016C10.7894 6.56301 10.7599 6.52113 10.7215 6.4884C10.6831 6.45567 10.6371 6.43315 10.5877 6.42291C10.5383 6.41267 10.4872 6.41504 10.4389 6.42981C10.3907 6.44458 10.347 6.47127 10.3118 6.50742Z"
          fill="#5591C7"
        />
        <path
          d="M13.9929 0H2.50712C1.84308 0.00284974 1.20706 0.264567 0.737512 0.728176C0.267959 1.19178 0.00288628 1.81975 0 2.47539V19.5246C0.00288628 20.1802 0.267959 20.8082 0.737512 21.2718C1.20706 21.7354 1.84308 21.9972 2.50712 22H13.9929C14.6569 21.9972 15.2929 21.7354 15.7625 21.2718C16.2321 20.8082 16.4971 20.1802 16.5 19.5246V2.47848C16.4979 1.82231 16.2332 1.19355 15.7636 0.729271C15.294 0.264995 14.6575 0.00285511 13.9929 0V0ZM8.25 21.0114C8.06089 21.0117 7.87593 20.9566 7.71854 20.8531C7.56114 20.7496 7.43839 20.6023 7.3658 20.4299C7.29322 20.2575 7.27407 20.0677 7.31077 19.8845C7.34748 19.7013 7.43839 19.533 7.572 19.4009C7.70561 19.2687 7.87593 19.1787 8.06139 19.1422C8.24684 19.1056 8.43911 19.1242 8.61385 19.1956C8.7886 19.267 8.93797 19.3879 9.04307 19.5432C9.14817 19.6984 9.20427 19.8809 9.20427 20.0677C9.20427 20.3177 9.10379 20.5575 8.92487 20.7344C8.74596 20.9114 8.50324 21.011 8.25 21.0114V21.0114ZM14.4896 18.4138H2.0104V2.84979H14.4896V18.4138Z"
          fill="#5591C7"
        />
        <rect x="6" y="19" width="4" height="2" />
      </svg>
    </span>
  )
}

export default AdsIcon
