import React, { useEffect, useState } from 'react'

import { pluralize } from '../../Utils'
import { Custom } from '../../constants'
import EmailSetup from '../../images/email-setup.svg'
import { MESSAGES } from '../../constants/strings'

const AdvertisementMetric = ({ metric = 0, name, plural }) => (
  <span>
    {metric} {pluralize(metric, name, plural)}
  </span>
)

const AdvertisementMetrics = ({
  advertisement: {
    type,
    analytics: { impression = '?', click = '?', phone = '?', scan = '?' },
    link,
    message,
  },
  extractReportModalOpen,
}) => {
  const [maintenanceStatus, setMaintenanceStatus] = useState(false)

  useEffect(() => {
    setMaintenanceStatus(JSON.parse(localStorage.getItem('maintenance')))
  }, [])

  return maintenanceStatus ? (
    <small className="text-secondary font-italic"> {MESSAGES.ANALYTICS_NOT_AVAILABLE} </small>
  ) : (
    <small className="metrics">
      <div className="adsReportContainer" onClick={extractReportModalOpen}>
        <img src={EmailSetup} alt="pdf" />
        <p> Email Report </p>
      </div>
      <AdvertisementMetric name="Play" plural="Plays" metric={impression} />
      {type !== Custom && (
        <span>
          {' / '} <AdvertisementMetric name="Click" metric={click} />
        </span>
      )}
      {type == Custom && (link || message) && (
        <span>
          {' / '} <AdvertisementMetric name="Share" metric={phone + scan} />
          {/* {' / '} <AdvertisementMetric name={'Scan'} metric={scan} /> */}
        </span>
      )}
      {type !== Custom && type === 'Service' && (
        <span>
          {' / '} <AdvertisementMetric name="Request" metric={phone} />
        </span>
      )}
      {type !== Custom && type !== 'Service' && (
        <span>
          {' / '} <AdvertisementMetric name="Share" metric={phone + scan} />
          {/* {' / '} <AdvertisementMetric name={'Scan'} metric={scan} /> */}
        </span>
      )}
    </small>
  )
}

export default AdvertisementMetrics
