import { clone } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'
import { AmenityIconOptions } from '../../constants/generic'
import TooltipComponent from '../TooltipComponent'

const RequestIconsList = ({ topic = {}, disabled, onChange, saveOnExit, handleSubmit }) => {
  const IconOptions = clone(AmenityIconOptions).map(item => {
    const updated = { ...item, iconURL: item.url }
    delete updated.url
    return updated
  })
  let defaultIcon = IconOptions.find(icon => icon.name === 'Default')

  if (topic.iconURL) {
    defaultIcon = topic
  }

  const [selectedIcon, setSelectedIcon] = useState(defaultIcon)

  useEffect(() => {
    onChange(selectedIcon?.iconURL)
    return () => saveOnExit && handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIcon?.iconURL])

  const onSelect = selectedIcon => {
    setSelectedIcon(selectedIcon)
  }

  return (
    <>
      <UncontrolledButtonDropdown>
        <DropdownToggle
          disabled={topic.isDefault || disabled}
          caret={!topic.isDefault}
          className="btn btn-outline-primary"
          data-cy="reservations-sync-menu">
          <img src={selectedIcon.iconURL} alt="icon" />
        </DropdownToggle>
        {!topic.isDefault && !disabled && (
          <DropdownMenu>
            {IconOptions.map((icon, index) => {
              return (
                <DropdownItem key={`${index}${icon.iconURL}`} onClick={() => onSelect(icon)}>
                  <img data-tip data-for={icon.iconURL} src={icon.iconURL} alt="Icon" />
                  <TooltipComponent className="icons-tooltip" place="bottom" id={icon.iconURL}>
                    <div>{icon.name}</div>
                  </TooltipComponent>
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        )}
      </UncontrolledButtonDropdown>
    </>
  )
}

export default RequestIconsList
