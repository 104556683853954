/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import { connect, getIn } from 'formik'
import FormGroup from '../FormGroup'
import { SelectField, ImageDropzoneField, DraggableMediaField } from '.'

import DropzoneInput from '../../components/DropzoneInput'
import StepsSetInput from '../../components/StepsSetInput'
import { apiRequest } from '../../reducers/data'
import { S3_ROOT_URL } from '../../constants'

const MEDIA_TYPES = {
  default: {
    label: 'Text with Photo',
    field: fieldsPath => (
      <ImageDropzoneField
        name={`${fieldsPath}.imageURL`}
        label="Add an Image"
        desiredSize={{ width: 800, height: 600 }}
      />
    ),
  },
  video: {
    label: 'Video',
    field: (fieldsPath, setFieldValue, getIn, values) => {
      return (
        <DraggableMediaField
          name={`${fieldsPath}.videoURL`}
          label="Add a Video"
          desiredSize={{ width: 800, height: 600 }}
          value={getIn(values, `${fieldsPath}.videoURL`) || []}
          accept={'video/*'}
          maxSize={1024 * 1024 * 300}
          maxSizeText="300 MB"
          type="video"
          mediaType="video"
          imageURLShouldSet
          skip
        />
      )
    },
  },
  pdf: {
    label: 'PDF',
    field: (fieldsPath, setFieldValue, getIn, values, dispatch) => (
      <DropzoneInput
        type="pdf"
        mediaType="pdf"
        style={{ maxWidth: '240px' }}
        value={getIn(values, `${fieldsPath}.resourceURL`)}
        remove={() => {
          setFieldValue(`${fieldsPath}.videoURL`, null)
          setFieldValue(`${fieldsPath}.imageURL`, null)
          setFieldValue(`${fieldsPath}.resourceURL`, null)
        }}
        onFinish={async info => {
          const resourceURL = info.fileUrl
          setFieldValue(`${fieldsPath}.resourceURL`, resourceURL)
        }}
        maxSize={1024 * 1024 * 25}
        maxSizeText="25 MB"
        accept="application/pdf"
        upload={{
          contentDisposition: 'inline',
          server: '/api/dashboard',
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': 'application/pdf',
          },
          signingUrlQueryParams: {
            path: 'uploaded-pdf/',
          },
        }}
        btnText="Add a PDF"
        dragInfo="or drag and drop a PDF"
        extraInformation="Remove hyperlinks from PDFs before uploading"
      />
    ),
  },
  steps: {
    label: 'Step by step',
    field: (fieldsPath, setFieldValue, getIn, values) => (
      <StepsSetInput
        page="PropertyInfoModal"
        value={getIn(values, `${fieldsPath}.steps`) || []}
        onChange={steps => setFieldValue(`${fieldsPath}.steps`, steps)}
      />
    ),
  },
}

const MediaField = ({ name, fieldsPath, dispatch, formik: { values, setFieldValue }, ...props }) => {
  const options = Object.keys(MEDIA_TYPES).reduce((options, optionKey) => {
    options.push({ [optionKey]: MEDIA_TYPES[optionKey].label })
    return options
  }, [])

  useEffect(() => {
    const initial = getIn(values, name)
    if (!initial) {
      setFieldValue(name, 'default')
    }
  }, [])

  const selectedFormat = getIn(values, name)
  const selectedField =
    selectedFormat && MEDIA_TYPES[selectedFormat].field(fieldsPath, setFieldValue, getIn, values, dispatch)

  return (
    <FormGroup label="Media Type" for="MediaType">
      <SelectField name={name} options={options} readOnly={props.readOnly} fast={false} defaultValue="default" />
      {selectedField && selectedField}
    </FormGroup>
  )
}

export default connect(MediaField)
