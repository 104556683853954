import React from 'react'

const BundleServiceIcon = props => (
  <svg className="bandle-table-icon" width="1em" height="1em" viewBox="0 0 11 13" {...props}>
    <rect
      x="0.323529"
      y="0.323529"
      width="10.3529"
      height="12.3529"
      fill="white"
      stroke="#5591C7"
      strokeWidth="0.647059"
    />
    <line x1="1.94141" y1="3.23527" x2="9.05905" y2="3.23527" stroke="#5591C7" strokeWidth="1.29412" />
    <line x1="1.94141" y1="6.23527" x2="9.05905" y2="6.23527" stroke="#5591C7" strokeWidth="1.29412" />
    <line x1="1.94141" y1="9.23527" x2="9.05905" y2="9.23527" stroke="#5591C7" strokeWidth="1.29412" />
  </svg>
)

export default BundleServiceIcon
