import React from 'react'
import { withRouter } from 'react-router-dom'
import Resource from '../../Resource'
import { Yup, SubmitButton } from '../../forms'
import { Loading } from '../PageStates'
import { withHostForm, HostFields, HostDescription } from '../Host'
import OnboardingBreadcrumbs from './OnboardingBreadcrumbs'
import dummyURL from '../../images/avatar_placeholder.png'
import SkipButton from './buttons/SkipButton'
import { DefaultContentForm } from '../content'
import { RECOMMENDATIONS } from '../../constants/forms/recommendations'
import { MESSAGES } from '../../constants/strings'

function _HostAndQuotesStep({ handleSubmit }) {
  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="onboardingForm">
              <div id="header">
                <img src="/gvg-logo-white-updated.svg" alt="GuestView Guide" className="signup-hdr-logo" />
              </div>
              <div className="main-content">
                <OnboardingBreadcrumbs step={2} length={5} />
                <h2 className="h2">{MESSAGES.PERSONALIZE_EXPERIENCE}</h2>
                <h3>{MESSAGES.HOSTS}</h3>
                <HostDescription />
                <div className="row">
                  <div className="col-md-9">
                    <div className="default-body">
                      <HostFields size="large" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="gray-body">
                      {`${MESSAGES.EXAMPLE}:`}
                      <img
                        src="/host-example-placement.png"
                        alt="Example of how host appears"
                        className="signup-hdr-logo"
                      />
                    </div>
                  </div>
                </div>
                <h3>{MESSAGES.RECOMMENDATION_MESSAGES}</h3>
                <DefaultContentForm content={RECOMMENDATIONS} />
                <div id="footer">
                  <div />
                  <div>
                    {/* <SkipButton to="/onboarding/3" /> */}
                    <SubmitButton style={{ marginLeft: '15px' }}>{MESSAGES.SAVE_AND_CONTINUE}</SubmitButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

_HostAndQuotesStep.defaultProps = {
  handleSubmit: () => { },
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('required')
    .default('')
    .nullable(),
  contactNumber: Yup.string()
    .phone()
    .nullable()
    .required('required'),
  phoneExt: Yup.string().nullable(),
  photoURL: Yup.string()
    .default(dummyURL)
    .nullable(),
})

const HostAndQuotesStep = withHostForm({ validationSchema })(_HostAndQuotesStep)

export default withRouter(({ history, isDefault, ...props }) => (
  <Resource collection path={['hosts']} placeholderComponent={Loading}>
    {(hosts, { onCreateItem, onUpdateItem }) => {
      const host = hosts.length ? hosts[0] : {}
      host.isDefault = isDefault
      const next = () => history.push('/onboarding/3')
      return <HostAndQuotesStep next={next} host={host} onSave={host.id ? onUpdateItem : onCreateItem} {...props} />
    }}
  </Resource>
))
