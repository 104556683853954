import React, { useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import classNames from 'classnames'

import '../styles/PropertyGridContainer.scss'
import { MasterList } from '../components/list'
import BundleServiceIcon from '../icons/BundleServiceIcon'
import SingleServiceIcon from '../icons/SingleServiceIcon'
import ServicePrice from '../components/services/ServicePrice'
import { formatters } from '../Utils'
import { PAYABLE_FEE_TYPES, SERVICE_TEMPLATES } from '../constants/services'
import PhoneNumber from '../components/PhoneNumber'
import ServiceRequestResponse from '../components/services/ServiceRequestResponse'
import ServiceRequestName from '../components/services/ServiceRequestName'

const PENDING_MODES = ['PAYMENT_CHECKOUT_PENDING', 'PAYMENT_PENDING', 'APPROVAL_PENDING', 'PAYMENT_CHECKOUT_PENDING']

const COLUMNS = [
  {
    accessor: 'service.template',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      return <>{value === SERVICE_TEMPLATES.BUNDLE ? <BundleServiceIcon /> : <SingleServiceIcon />}</>
    },
    options: {
      classNames: 'shrink',
    },
  },
  {
    id: 'service-requests',
    columns: [
      {
        Header: 'Service Name',
        accessor: 'service.name',
        Cell: ({
          // cell: { value },
          row: {
            original: { service, parameters },
          },
        }) => {
          const quantity = get(parameters, 'quantity', 1)
          return <ServiceRequestName service={service} quantity={quantity} />
        },
      },
      {
        Header: 'Price',
        id: 'price',
        accessor: ({ service: { price, feeType }, parameters: { quantity = 1 } }) => {
          if (PAYABLE_FEE_TYPES.includes(feeType)) {
            return quantity * price
          }
          return price
        },
        Cell: ({
          cell: { value },
          row: {
            original: { service, parameters },
          },
          initialState: { supportPaymentProvider, dispatch },
        }) => {
          return (
            <>
              <ServicePrice
                service={service}
                amount={value}
                hasPaymentProvider={!!parameters.checkout_id}
                supportPaymentProvider={supportPaymentProvider}
                dispatch={dispatch}
                request
                parameters={parameters}
              />
              {!parameters.checkout_id && (
                <small>
                  <strong>Manual Payment</strong>
                </small>
              )}
            </>
          )
        },
      },
      {
        Header: 'Property Address',
        id: 'address',
        accessor: ({ property }) => formatters.address(property),
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <div>{value[0]}</div>
              <div>{value[1]}</div>
            </>
          )
        },
      },
      {
        Header: 'Guest Name',
        accessor: 'reservation.partyName',
        Cell: ({
          cell: { value },
          row: {
            original: { phone },
          },
        }) => (
          <>
            <div>{value && value.trim().length ? value.trim() : 'Guest'}</div>
            <small>
              <PhoneNumber>{typeof phone !== 'object' && phone}</PhoneNumber>
            </small>
          </>
        ),
      },
      {
        Header: 'Check In - Out',
        accessor: 'reservation.beginDate',
        Cell: ({
          row: {
            original: { reservation },
          },
        }) => {
          return (
            <>
              <div>
                {reservation && reservation.beginDate && moment(reservation.beginDate).format('L')}
                {' '}
                –
              </div>
              <div>{reservation && reservation.endDate && moment(reservation.endDate).format('L')}</div>
            </>
          )
        },
      },
      {
        Header: 'Response',
        accessor: 'status',
        Cell: ({ cell: { value }, row: { original }, initialState: { onUpdateItem, dispatch, checkCartAbandon } }) => {
          return (
            <ServiceRequestResponse
              {...original}
              onUpdateItem={onUpdateItem}
              dispatch={dispatch}
              checkCartAbandon={checkCartAbandon}
            />
          )
        },
      },
      {
        Header: 'Received',
        id: 'createdAt',
        accessor: ({ createdAt }) => moment(createdAt),
        Cell: ({ cell: { value } }) => <div title={value.format('LLLL')}>{value.fromNow()}</div>,
      },
    ],
  },
]

const ServiceRequestListContainer = ({
  data,
  onUpdateItem,
  supportPaymentProvider,
  dispatch,
  checkCartAbandon,
  searchTerm,
}) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  const externalSearch = useRef(null)

  useEffect(() => {
    externalSearch.current(searchTerm)
  }, [searchTerm])

  const initialState = {
    sortBy: [{ id: 'createdAt', desc: false }],
    onUpdateItem,
    supportPaymentProvider,
    dispatch,
    checkCartAbandon,
  }

  return (
    <MasterList
      columns={memoizedColumns}
      initialState={initialState}
      getRowProps={({ original }) => ({
        className: classNames(original.status, { 'is-pending': PENDING_MODES.includes(original.status) }),
      })}
      data={data}
      className="ServiceRequestList"
      search={false}
      externalSearch={externalSearch}
    />
  )
}

export default ServiceRequestListContainer
