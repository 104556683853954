/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import PhoneNumber from '../PhoneNumber'
import Resource from '../../Resource'
import User from '../User'
import { MasterList, AddButton, ListActions } from '../list'
import { openModalWithContainer } from '../../reducers/modals'
import { EmptyNavItemPlaceholder } from '../PageStates'
import { PopoverMenu, PopoverMenuItem } from '../ui'

import '../../styles/User.scss'
import { SECTIONS, SUB_SECTIONS } from '../../constants/thirdPartyAnalytics'
import { MESSAGES } from '../../constants/strings'

const alignCellTextMiddle = {
  options: {
    classNames: 'middle',
  },
}

const COLUMNS = [
  {
    id: 'hosts',
    columns: [
      {
        Header: MESSAGES.NAME,
        accessor: 'name',
        Cell: ({ row: { original } }) => {
          const host = {
            photoURL: original.photoURL,
            name: original.name,
            isDefault: original.isDefault,
          }
          return <User user={host} />
        },
      },
      {
        Header: MESSAGES.PHONE,
        accessor: 'contactNumber',
        Cell: ({ row: { original } }) => {
          return <PhoneNumber>{typeof original?.contactNumber !== 'object' && original.contactNumber}</PhoneNumber>
        },
        ...alignCellTextMiddle,
      },
      {
        Header: MESSAGES.EXTRA,
        accessor: 'phoneExt',
        Cell: ({ row: { original } }) => {
          return <div>{original?.phoneExt}</div>
        },
        ...alignCellTextMiddle,
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original: host }, initialState: { onDeleteItem, onUpdateItem,rowAction } }) => {
          const edit = () => rowAction(host)
          const setAsDefault = () => {
            window.confirm(MESSAGES.REPLACE_DEFAULT_HOST) &&
              onUpdateItem({ ...host, isDefault: true })
          }
          const remove = () => {
            window.confirm(MESSAGES.REMOVE_HOST_CONFIRMATION) && onDeleteItem(host)
          }
          return (
            <PopoverMenu data-cy="host-options">
              <PopoverMenuItem data-cy="host-option-edit" onClick={edit}>
                {MESSAGES.EDIT}
              </PopoverMenuItem>
              {!host.isDefault && (
                <PopoverMenuItem data-cy="host-option-remove" onClick={remove}>
                  {MESSAGES.DELETE}
                </PopoverMenuItem>
              )}
              {!host.isDefault && (
                <PopoverMenuItem data-cy="notification-option-edit" disabled onClick={setAsDefault}>
                  Set As Default
                </PopoverMenuItem>
              )}
            </PopoverMenu>
          )
        },
        options: {
          classNames: 'middle-right',
        },
      },
    ],
  },
]

const HostsTable = ({ hosts, onUpdateItem, onDeleteItem, dispatch, recordAnalytics }) => {
  const rowAction = host => {
    dispatch(
      openModalWithContainer('HostContainer', {
        onSave: onUpdateItem,
        host,
      })
    )
  }
  const initialState = {
    onUpdateItem,
    onDeleteItem,
    rowAction,
  }

  const memoizedColumns = useMemo(() => COLUMNS, [])
  return (
    <MasterList
      columns={memoizedColumns}
      data={hosts}
      rowAction={rowAction}
      initialState={initialState}
      data-cy="hosts-table"
      recordAnalytics={data => recordAnalytics({ action: data, subSection: SUB_SECTIONS.MANAGE_HOST })}
    />
  )
}

const addHost = (dispatch, onCreateItem, recordAnalytics) => {
  dispatch(
    openModalWithContainer('HostContainer', {
      host: {},
      onSave: onCreateItem,
      recordAnalytics,
    })
  )
}

const Hosts = ({ recordAnalytics }) => {
  return (
    <Resource collection path={['hosts']} placeholder={[]}>
      {(hosts, { onCreateItem, onUpdateItem, onDeleteItem, dispatch }) => (
        <div className="settings-section">
          <h2>{MESSAGES.PROPERTY_HOSTS}</h2>
          <ListActions>
            <AddButton item="Host" onClick={() => addHost(dispatch, onCreateItem, recordAnalytics)} />
          </ListActions>
          {hosts.length === 0 ? (
            <EmptyNavItemPlaceholder dataPiece="host" navItem="a host" />
          ) : (
            <HostsTable
              hosts={hosts}
              recordAnalytics={recordAnalytics}
              onUpdateItem={onUpdateItem}
              onDeleteItem={onDeleteItem}
              dispatch={dispatch}
            />
          )}
        </div>
      )}
    </Resource>
  )
}

export default Hosts
