import { CustomHostField } from '../../components/Host'
import { Yup } from '../../forms'
import {
  MessageCategoryGroup,
  RecurringDatetimeField,
  SectionHeader,
  SelectPropertiesField,
  TextField,
  ToggleSwitchField,
} from '../../forms/fields'
import { urlPattern } from '../regex'
import { COMMON_FIELDS } from './services'

const sendOptions = [
  { NOW: 'Immediately' },
  { DAILY: 'Daily' },
  { BEFORE_CHECKOUT: 'Before Check Out' },
  { AFTER_CHECKIN: 'After Check In' },
  { SPECIFIC_DATE_TIME: 'Specific Date and Time' },
  { CUSTOM: 'Custom' },
]

const clearOptions = [
  { AFTER_READ: 'Only after reading' },
  { AFTER_RESERVATION_END: 'After reservation ends' },
  { DAY: 'After a day' },
  { HOUR: 'After an hour' },
  { CUSTOM: 'Custom' },
]

export const MESSAGE_WIZARD = {
  0: [
    {
      ...COMMON_FIELDS.name,
      name: 'title',
      props: {
        ...COMMON_FIELDS.name.props,
        maxLength: 80,
      },
    },
    {
      ...COMMON_FIELDS.draggableMedia,
      name: 'media',
      props: {
        ...COMMON_FIELDS.draggableMedia.props,
      },
    },
    {
      ...COMMON_FIELDS.detailHTML,
      name: 'content',
      label: 'Description',
      props: {
        maxLength: 1000,
      },
      validation: Yup.string().required('required'),
    },
    {
      label: 'Category',
      name: 'category',
      component: MessageCategoryGroup,
      validation: Yup.string().required('required'),
    },
    {
      component: SectionHeader,
      props: {
        title: 'Announcement Schedule',
      },
    },
    {
      label: 'Send Announcement',
      name: 'datetime',
      component: RecurringDatetimeField,
      props: {
        options: [...sendOptions],
        fieldName: 'datetime',
      },
      validation: Yup.string().required('required'),
    },
    {
      label: 'Expire Announcement',
      name: 'clearAt',
      component: RecurringDatetimeField,
      props: {
        options: [...clearOptions],
        fieldName: 'clearAt',
      },
      validation: Yup.string().required('required'),
    },
    {
      label: 'Read Receipt',
      labelTooltipText: 'This contact will be notified when an announcement has been read by the guest.',
      component: CustomHostField,
      validation: {
        readReceipt: Yup.array().when('hostId', {
          is: hostId => hostId !== [] || hostId === null,
          then: Yup.array()
            .default([])
            .of(
              Yup.object().shape(
                {
                  contactNumber: Yup.string()
                    .when('email', {
                      is: email => email === '',
                      then: Yup.string().required('required'),
                    })
                    .phone('Invalid Phone')
                    .nullable(),
                  email: Yup.string()
                    .when('contactNumber', {
                      is: contactNumber => contactNumber === '',
                      then: Yup.string().required('required'),
                    })
                    .email('Invalid email')
                    .nullable(),
                },
                [
                  ['contactNumber', 'email'],
                  ['email', 'contactNumber'],
                ],
              ),
            ),
        }),
      },
    },
    {
      label: 'QR Code URL',
      name: 'qrCode',
      component: TextField,
      props: {
        placeholder: 'http://',
        textprefix: 'http://',
      },
      validation: Yup.string().matches(urlPattern, {
        message: 'Enter a valid url',
        excludeEmptyString: true,
      }),
    },
  ],
  1: [
    {
      label: 'Apply to properties',
      name: 'propertyIds',
      component: SelectPropertiesField,
      validation: Yup.array()
        .default([])
        .atLeast(1)
        .label('property')
        .required(),
    },
    {
      label: 'Enable',
      name: 'enabled',
      component: ToggleSwitchField,
      props: {
        name: 'enabled',
        showLabel: false,
        toggleSwitch: true,
      },
      validation: Yup.boolean().default(true),
    },
  ],
}
