/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-var */
import { COMMON_FIELDS, CUSTOM_FIELDS, LATECHECKOUT_FIELDS, MID_STAY_CLEAN_FIELDS, REBOOK_FIELDS } from '.'

var REBOOK_WIZARD = {
  0: [REBOOK_FIELDS.name, REBOOK_FIELDS.draggableMedia, COMMON_FIELDS.summarySubtitle, COMMON_FIELDS.detailHTML],
  1: [
    REBOOK_FIELDS.nickName,
    REBOOK_FIELDS.phone,
    REBOOK_FIELDS.requestMessage,
    REBOOK_FIELDS.rebookUrl,
    REBOOK_FIELDS.promoCode,
    REBOOK_FIELDS.promoExpirationDays,
    COMMON_FIELDS.terms,
  ],
  2: [COMMON_FIELDS.enabled, COMMON_FIELDS.promote, COMMON_FIELDS.propertyIds],
}

var CUSTOM_WIZARD = {
  0: [
    CUSTOM_FIELDS.name,
    CUSTOM_FIELDS.draggableMedia,
    CUSTOM_FIELDS.hasPaymentProvider,
    CUSTOM_FIELDS.feeFields,
    CUSTOM_FIELDS.options,
    COMMON_FIELDS.summarySubtitle,
    COMMON_FIELDS.detailHTML,
    CUSTOM_FIELDS.orderDeadline,
    CUSTOM_FIELDS.serviceInstructions,
  ],
  1: [
    COMMON_FIELDS.approvalRequests,
    CUSTOM_FIELDS.nickName,
    COMMON_FIELDS.approvalMessage,
    COMMON_FIELDS.declineMessage,
    COMMON_FIELDS.terms,
    COMMON_FIELDS.phone,
  ],
  2: [COMMON_FIELDS.enabled, COMMON_FIELDS.promote, COMMON_FIELDS.propertyIds],
}

var BUNDLE_WIZARD = {
  0: [
    {
      ...CUSTOM_FIELDS.name,
      props: {
        ...CUSTOM_FIELDS.name.props,
        placeholder: 'ex: Bundle Service',
      },
    },
    CUSTOM_FIELDS.draggableMedia,
    COMMON_FIELDS.summarySubtitle,
    COMMON_FIELDS.detailHTML,
    CUSTOM_FIELDS.orderDeadline,
    CUSTOM_FIELDS.serviceInstructions,
  ],
  1: [
    CUSTOM_FIELDS.options,
    CUSTOM_FIELDS.allowMultiSelection,
    CUSTOM_FIELDS.hasPaymentProvider,
    CUSTOM_FIELDS.serviceChoices,
  ],
  2: [
    COMMON_FIELDS.approvalRequests,
    {
      ...CUSTOM_FIELDS.nickName,
      props: {
        ...CUSTOM_FIELDS.nickName.props,
        placeholder: 'ex: Bundle Service',
      },
    },
    COMMON_FIELDS.approvalMessage,
    COMMON_FIELDS.declineMessage,
    COMMON_FIELDS.terms,
    COMMON_FIELDS.phone,
    COMMON_FIELDS.serviceNote,
  ],
  3: [COMMON_FIELDS.enabled, COMMON_FIELDS.promote, COMMON_FIELDS.propertyIds],
}

var LATECHECKOUT_WIZARD = {
  0: [
    LATECHECKOUT_FIELDS.name,
    LATECHECKOUT_FIELDS.draggableMedia,
    CUSTOM_FIELDS.hasPaymentProvider,
    LATECHECKOUT_FIELDS.feeFields,
    COMMON_FIELDS.summarySubtitle,
    COMMON_FIELDS.detailHTML,
    COMMON_FIELDS.orderDeadline,
    LATECHECKOUT_FIELDS.checkoutTimeSelector,
  ],
  1: [
    COMMON_FIELDS.approvalRequests,
    LATECHECKOUT_FIELDS.nickName,
    COMMON_FIELDS.approvalMessage,
    COMMON_FIELDS.declineMessage,
    COMMON_FIELDS.terms,
    COMMON_FIELDS.phone,
  ],
  2: [
    LATECHECKOUT_FIELDS.enabledWithBtbRes,
    {
      ...COMMON_FIELDS.promote,
      // sublabel: 'Promotion will start 24 hours before check out time',
    },
    COMMON_FIELDS.propertyIds,
  ],
}

var MID_STAY_CLEAN_WIZARD = {
  0: [
    MID_STAY_CLEAN_FIELDS.name,
    MID_STAY_CLEAN_FIELDS.draggableMedia,
    CUSTOM_FIELDS.hasPaymentProvider,
    LATECHECKOUT_FIELDS.feeFields,
    MID_STAY_CLEAN_FIELDS.summarySubtitle,
    COMMON_FIELDS.detailHTML,
    COMMON_FIELDS.orderDeadline,
  ],
  1: [
    MID_STAY_CLEAN_FIELDS.approvalRequests,
    MID_STAY_CLEAN_FIELDS.nickName,
    COMMON_FIELDS.approvalMessage,
    COMMON_FIELDS.declineMessage,
    COMMON_FIELDS.phone,
  ],
  2: [COMMON_FIELDS.enabled, COMMON_FIELDS.promote, COMMON_FIELDS.propertyIds],
}

export { REBOOK_WIZARD, MID_STAY_CLEAN_WIZARD, LATECHECKOUT_WIZARD, CUSTOM_WIZARD, BUNDLE_WIZARD }

export const SERVICE_REQUEST_VIEWS_TYPES = { LIST: 'listView', GRID: 'gridView' }
