import React from 'react'
import Icon from './Icon'

function ZoomIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
        <circle cx={7.5} cy={7.5} r={6} />
        <path d="M15.5 15.5l-3.758-3.758" />
        <path data-color="color-2" d="M7.5 4.5v6M10.5 7.5h-6" />
      </g>
    </Icon>
  )
}

export default ZoomIcon
