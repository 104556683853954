import React from 'react'
import Icon from './Icon'

function WelcomeVideosIcon(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props} width="1em" height="1em">
      <path d="M8.94531 6.28125C8.78906 6.17708 8.61979 6.125 8.4375 6.125C7.86458 6.17708 7.55208 6.48958 7.5 7.0625V13.9375C7.55208 14.5104 7.86458 14.8229 8.4375 14.875C8.61979 14.875 8.78906 14.8229 8.94531 14.7188L14.5703 11.2812C14.8568 11.099 15 10.8385 15 10.5C15 10.1615 14.8568 9.90104 14.5703 9.71875L8.94531 6.28125ZM10 0.5C8.125 0.526042 6.44531 0.981771 4.96094 1.86719C3.45052 2.7526 2.2526 3.95052 1.36719 5.46094C0.481771 6.94531 0.0260417 8.625 0 10.5C0.0260417 12.375 0.481771 14.0547 1.36719 15.5391C2.2526 17.0495 3.45052 18.2474 4.96094 19.1328C6.44531 20.0182 8.125 20.474 10 20.5C11.875 20.474 13.5547 20.0182 15.0391 19.1328C16.5495 18.2474 17.7474 17.0495 18.6328 15.5391C19.5182 14.0547 19.974 12.375 20 10.5C19.974 8.625 19.5182 6.94531 18.6328 5.46094C17.7474 3.95052 16.5495 2.7526 15.0391 1.86719C13.5547 0.981771 11.875 0.526042 10 0.5ZM10 18.625C7.70833 18.5729 5.79427 17.7786 4.25781 16.2422C2.72135 14.7057 1.92708 12.7917 1.875 10.5C1.92708 8.20833 2.72135 6.29427 4.25781 4.75781C5.79427 3.22135 7.70833 2.42708 10 2.375C12.2917 2.42708 14.2057 3.22135 15.7422 4.75781C17.2786 6.29427 18.0729 8.20833 18.125 10.5C18.0729 12.7917 17.2786 14.7057 15.7422 16.2422C14.2057 17.7786 12.2917 18.5729 10 18.625Z" />
    </Icon>
  )
}

export default WelcomeVideosIcon
