import pluralize from 'pluralize'
import React from 'react'
import { Button } from 'reactstrap'
import { MoreIcon } from '../../icons/index'

const PreviewFooter = ({ verb, more = true }) => {
  return (
    <div className="PreviewFooter">
      <Button disabled className="Button">{`${verb}`}</Button>
      {more && (
        <small className="More">
          <MoreIcon />
          {' '}
          More
          {pluralize('service')}
        </small>
      )}
    </div>
  )
}

export default PreviewFooter
