import React from 'react'
import { range } from 'lodash'
import moment from 'moment'
import {
 ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid,
} from 'recharts'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'

import '../../styles/AnalyticsContainer.scss'
import { MESSAGES } from '../../constants/strings'

const PageViews = ({ params }) => {
  return (
    <Resource collection path={['analytics', 'pageviews', params]} placeholderComponent={Loading}>
      {({ dateRange: { start, end }, pageViewsByDate }) => {
        const diff = moment(end).diff(start, 'days') + 1

        const group = {}
        pageViewsByDate.forEach(pageView => {
          const key = moment(pageView.date).format('YYYY-MM-DD')
          group[key] = pageView
        })

        const data = range(0, diff).map(n => {
          const date = moment(start).add(n, 'day')
          const key = date.format('YYYY-MM-DD')
          return {
            views: 0,
            date: key,
            ...(group[key] || {}),
          }
        })
        return (
          <div className="PageView">
            <div className="title">{MESSAGES.PAGE_VIEWS}</div>
            <div style={{ width: '100%', height: '300px' }}>
              <ResponsiveContainer>
                <BarChart data={data}>
                  <CartesianGrid stroke="#f5f5f5" vertical={false} />
                  <XAxis
                    dataKey="date"
                    tickFormatter={tick => {
                      return moment(tick).format('MM/DD')
                    }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="views" barSize={20} fill="#3EA4E6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )
      }}
    </Resource>
  )
}

export default PageViews
