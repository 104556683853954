import React, { Fragment } from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { TextField, SelectField, PhoneField } from '../../forms/fields'
import { Yup, FormModal, FormGroup, SubmitButton } from '../../forms'
import Alert from '../../components/Alert'

const AdminContainer = props => {
  const {
    handleSubmit,
    admin,
    values,
    me,
    errors: { _error },
  } = props

  const hasItem = !!admin.id

  const noun = 'User'
  const verb = {
    create: 'New',
    edit: 'Edit Current',
  }

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader
        name={noun}
        verb={verb}
        hasItem={hasItem}
        subtitle={!hasItem && `Invite a ${noun} to share the management work with you.`}
      />
      <ModalBody>
        {_error && _error.message && <Alert text={_error.message} type="error" />}
        <Fragment>
          <FormGroup label="Role" for="role">
            {/* <SelectField name="role" readOnly={hasItem}>
              <option value="MANAGER">Manager</option>
              {hasItem && <option value="OWNER">Owner</option>}
  </SelectField> */}
            <label className="modal-label col-form-label">Manager</label>
          </FormGroup>
          <Fragment>
            {/*(values.role === 'MAINTENANCE' || hasItem === true) && (
                <Fragment>
                  <FormGroup required label="First Name" for="firstName">
                    <TextField name="firstName" maxLength="50" />
                  </FormGroup>
                  <FormGroup required label="Last Name" for="lastName">
                    <TextField name="lastName" maxLength="50" />
                  </FormGroup>
                </Fragment>
              ) */}

            <FormGroup label="Email" for="email">
              <TextField
                placeholder="joe@example.com"
                name="email"
                readOnly={hasItem && admin.email !== me.email}
                maxLength="254"
              />
            </FormGroup>

            {/*(values.role === 'MAINTENANCE' || hasItem === true) && (
                <FormGroup required label="Mobile Number" for="phone">
                  <PhoneField name="phone" />
                </FormGroup>
              ) */}
          </Fragment>
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <SubmitButton>Save</SubmitButton>
      </ModalFooter>
    </form>
  )
}

const validationSchema = Yup.object().shape({
  role: Yup.string()
    .required()
    .label('Role')
    .matches(/(OWNER|MANAGER|MAINTENANCE)/, {
      excludeEmptyString: true,
    }),
  firstName: Yup.mixed().when('role', {
    is: role => role === 'MAINTENANCE',
    then: Yup.string()
      .required()
      .label('First Name'),
    otherwise: Yup.string(),
  }),
  lastName: Yup.mixed().when('role', {
    is: role => role === 'MAINTENANCE',
    then: Yup.string()
      .required()
      .label('Last Name'),
    otherwise: Yup.string(),
  }),
  email: Yup.mixed().when('role', {
    is: role => role !== 'MAINTENANCE',
    then: Yup.string()
      .email()
      .required()
      .label('Email'),
    otherwise: Yup.string(),
  }),
  phone: Yup.mixed().when('role', {
    is: 'MAINTENANCE',
    then: Yup.string()
      .phone()
      .required()
      .label('Phone number'),
  }),
})

export default props => {
  const {
    admin: { id, email, firstName, lastName, phone, role = 'MANAGER' },
    onSave,
    dispatch,
    onSuccess,
  } = props

  const initialValues = {
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
    phone,
    role: role || '',
  }

  const render = p => <AdminContainer {...p} {...props} />

  const formatValues = v => {
    return {
      id,
      ...v,
    }
  }

  return (
    <FormModal
      formatValues={formatValues}
      validationSchema={validationSchema}
      initialValues={initialValues}
      render={render}
      onSave={onSave}
      dispatch={dispatch}
      onSuccess={onSuccess}
    />
  )
}
