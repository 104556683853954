import React, { useState } from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty, isObject } from 'lodash'
import { Loading } from '../components/PageStates'
import Resource from '../Resource'
import TabHeader from '../components/TabHeader'
import { PropertyHeader, Properties } from '../components/Property'
import { changePropertyView } from '../reducers/config'
import { openModalWithContainer } from '../reducers/modals'
import { Tabs } from '../components'
import {
  PROPERTY_STATUS,
  PROPERTY_TYPES,
  PROPERTY_TYPES_TABS_LABEL,
  PROPERTY_VIEWS_TYPES,
} from '../constants/forms/propertyInfo'
import { MESSAGES } from '../constants/strings'
import { confirm } from './modals/AlertMessageContainer'
import { apiRequest } from '../reducers/data'
import { thirdPartyAnalytics } from '../services'
import { EVENTS } from '../constants/thirdPartyAnalytics'

let analyticsTimer = null
const ANALYTICS_TIME = 1000

const PropertyContainer = ({
  history,
  activePropertyView,
  userInfo,
  search,
  archive,
  openModalWithContainer,
  changePropertyView,
  tab = '',
}) => {
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  const viewToggle = () => {
    const newActivePropertyView =
      activePropertyView === PROPERTY_VIEWS_TYPES.listView
        ? PROPERTY_VIEWS_TYPES.gridView
        : PROPERTY_VIEWS_TYPES.listView
    changePropertyView(newActivePropertyView)
    // recordAnalytics(EVENTS.PROPERTY_VIEW_TOGGLE, { view: newActivePropertyView })
  }

  const recordAnalytics = (_event, properties) => {
    let updatedProperties = { ...analyticsProperties }
    if (isObject(properties)) updatedProperties = { ...updatedProperties, ...properties }
    clearTimeout(analyticsTimer)
    analyticsTimer = setTimeout(() => {
      thirdPartyAnalytics.track(_event, updatedProperties)
    }, ANALYTICS_TIME)
  }

  const query = qs.parse(search, { ignoreQueryPrefix: true })
  const shouldOpenImportModal = query.import === 'true'
  const type = archive ? `?type=${PROPERTY_TYPES.archived}` : `?type=${PROPERTY_TYPES.current}`

  const [userSearch, setUserSearch] = useState('')

  const PROPERTY_TYPES_TABS = [
    {
      slug: '',
      component: Properties,
      label: PROPERTY_TYPES_TABS_LABEL.listedProperties,
      // eventLabel: EVENTS.PROPERTY_TAB,
    },
    {
      slug: 'archive',
      component: Properties,
      label: PROPERTY_TYPES_TABS_LABEL.archivedProperties,
      eventLabel: EVENTS.ARCHIVED_PROPERTY_TAB,
    },
  ]

  return (
    <div className="PropertyContainer">
      <Resource collection path={['properties', type]} placeholderComponent={Loading}>
        {(results, { onRefresh, dispatch, NestedResource, onUpdateItem }) => {
          const onCloseModal = () => {
            onRefresh()
            history.push('/properties')
          }

          // show confirmation message to archive property
          // archive property on confirm
          const showConfirmation = (property, status) => {
            const title = status === PROPERTY_STATUS.ARCHIVE ? MESSAGES.ARCHIVE : MESSAGES.RELIST
            let titleEx = ''
            if (!isEmpty(property.nickname)) {
              titleEx = (
                <p>
                  {title} <b>{property.nickname}</b>
                </p>
              )
            }
            confirm({
              title: titleEx || title,
              body: status === PROPERTY_STATUS.ARCHIVE ? MESSAGES.ARCHIVE_CONFIRMATION : MESSAGES.RELIST_CONFIRMATION,
              onConfirm: () => {
                onUpdateItem({ path: ['properties', property.id, status] }, true)
                status === PROPERTY_STATUS.ARCHIVE
                  ? recordAnalytics(EVENTS.PROPERTY_ARCHIVED, {
                      propertyName: property.nickname,
                    })
                  : recordAnalytics(EVENTS.PROPERTY_RELIST, {
                      propertyName: property.nickname,
                    })
              },
            })
          }

          // check if property is paired or not before archiving
          // calls confirmation function on confirm
          const onArchiveProperty = async (property, status) => {
            const { json } = await dispatch(apiRequest([`properties/${property.id}/device/status`], 'GET'))
            const deviceStatus = json.status
            if (deviceStatus === PROPERTY_STATUS.PAIRED && status === PROPERTY_STATUS.ARCHIVE) {
              if (window.confirm(MESSAGES.ARCHIVE_PAIRING_CONFIRMATION)) {
                showConfirmation(property, status)
              }
            } else {
              showConfirmation(property, status)
            }
          }

          const openImportModal = () => {
            openModalWithContainer('ImportPropertiesContainer', null, { onClose: onCloseModal })
          }

          return (
            <>
              <TabHeader title="Properties" count={results.length} />
              <PropertyHeader
                shouldOpenImportModal={shouldOpenImportModal}
                openImportModal={openImportModal}
                onClick={viewToggle}
                activePropertyView={activePropertyView}
                setUserSearch={setUserSearch}
                count={results.length}
                recordAnalytics={recordAnalytics}
              />
              <Tabs
                path="properties"
                tabs={PROPERTY_TYPES_TABS}
                recordAnalytics={recordAnalytics}
                dispatch={dispatch}
                onArchive={onArchiveProperty}
                NestedResource={NestedResource}
                results={results}
                userSearch={userSearch}
                archive={archive}
                activePropertyView={activePropertyView}
                selectedTab={tab}
              />
            </>
          )
        }}
      </Resource>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    activePropertyView: state.config.activePropertyView,
    userInfo: state.data.me,
  }
}

export default withRouter(connect(mapStateToProps, { changePropertyView, openModalWithContainer })(PropertyContainer))
