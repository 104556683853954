import React from 'react'
import Icon from './Icon'

function RecommendationIcon(props) {
  return (
    <Icon viewBox="0 0 30 43" fill="none" {...props}>
      <path
        d="M26.758 8.477l-.012-.259-.218-.14-2.809-1.797-1.798-2.809-.14-.218-.258-.012-3.332-.152-2.961-1.534-.23-.12-.23.12-2.961 1.534-3.332.153-.259.011-.14.218-1.797 2.81-2.809 1.797-.218.14-.012.258-.152 3.332-1.534 2.961-.12.23.12.23L3.09 18.19l.153 3.332.011.259.219.14 2.808 1.797 1.798 2.809.14.218.258.012 3.332.152 2.961 1.534.23.12.23-.12 2.961-1.534 3.332-.152.259-.012.14-.218 1.797-2.81 2.809-1.797.218-.14.012-.258.152-3.331 1.534-2.962.12-.23-.12-.23-1.534-2.961-.152-3.332z"
        fill="#EFD358"
        stroke="#B39824"
      />
      <path
        d="M9 26.5h-.5v14.934l.777-.518L15 37.101l5.723 3.815.777.518V26.5h-3.133l-.115.066L15 28.424l-3.252-1.858-.115-.066H9z"
        fill="#EFD358"
        stroke="#B29824"
      />
    </Icon>
  )
}

export default RecommendationIcon
