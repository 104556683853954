import { isNil } from 'lodash'
import moment from 'moment'
import { ExpressServerRoot } from '../constants'
import Logger from '../logger/logger'
import { islogoutTimeArrive } from '../services/logoutEvent'

/*
This Redux reducer provides action creators that make API requests, actions that
save fetched data to the local redux store, and convenience getters that allow
the data to be retrieved for display in the UI.

When you make an API request or request cached data you provide a "path", like
['properties', '1'] that corresponds to a REST route `/properties/1`. Using
paths allows the reducer to be generic - we structure the cache as a tree and
update it as requests are made.
*/

// Getters
function isNumber(n) {
  return `${n / 1}` === `${n}`
}

export function dataForResourcePath(state, path) {
  const { parent, parentKey } = resolveResourcePath(state, path)
  if (parent instanceof Array) {
    return parent.find(i => i.id / 1 === parentKey / 1)
  }
  return parent[parentKey]
}

// Helpers

function resolveResourcePath(state, path) {
  let parent = state
  let child = state

  // eslint-disable-next-line no-restricted-syntax
  // eslint-disable-next-line no-unused-vars
  for (const part of path.slice(0, -1)) {
    if (parent instanceof Array) {
      const id = part / 1
      child = parent.find(i => i.id / 1 === id)
      if (!child) {
        child = { id }
        parent.push(child)
      }
    } else {
      child = parent[part]
      if (!child) {
        const nextPart = path[path.indexOf(part) + 1]
        child = parent[part] = isNumber(nextPart) ? [] : {}
      }
    }

    parent = child
  }

  return { parent, parentKey: path[path.length - 1] }
}

function upsertDataAtPath(state, path, data) {
  const nextState = JSON.parse(JSON.stringify(state))

  // First, find the leaf node where we're going to insert this data
  // into our local state.
  const { parent, parentKey } = resolveResourcePath(nextState, path)

  if (parent instanceof Array) {
    // If we're saving an item that is in an array, find/replace the existing
    // item with the same ID, or insert it at the end.
    const idx = parent.findIndex(i => {
      if (typeof i.id === 'string') {
        return `${i.id}` === `${parentKey}`
      }
      return i.id === parentKey / 1
    })
    if (idx === -1) {
      parent.push(data)
    } else {
      parent[idx] = { ...parent[idx], ...data }
    }
  } else {
    // If we're saving an item that is in an object, upsert the existing data
    // with the new data (so keys not present in the new data are not overwritten)
    if (data instanceof Array) {
      parent[parentKey] = data
    } else {
      parent[parentKey] = Object.assign(parent[parentKey] || {}, data)
    }
  }

  return nextState
}

// Action Types

export const REQUEST_STARTED = 'REQUEST_STARTED'
export const REQUEST_FINISHED = 'REQUEST_FINISHED'
export const SET_MESSAGE = 'SET_MESSAGE'

// Action Creators

export function apiRequest(resourcePath, method = 'GET', itemPayload = null, apiPath = null, message = null) {
  return async dispatch => {
    dispatch({
      type: REQUEST_STARTED,
      method,
      resourcePath,
    })

    const apiUrl = !isNil(apiPath) ? apiPath : resourcePath
    const path = `/api/dashboard/${apiUrl.join('/')}`
    const options = {
      credentials: 'include',
      method,
    }

    if (itemPayload) {
      options.body = JSON.stringify(itemPayload)
      options.headers = {
        'Content-Type': 'application/json',
      }
    }

    // log request
    Logger.http({
      module: resourcePath[0],
      // sessionId :   ,
      metadata: itemPayload,
      apiInfo: { endpoint: path, method },
    })

    const response = await fetch(path, options)

    if (response.status === 401) {
                                   //Check if logout time has arrived
                                   islogoutTimeArrive()
                                   window.location = `${ExpressServerRoot}/login?next=${encodeURIComponent(
                                     window.location.href
                                   )}`
                                   return
                                 }

    const text = await response.text()
    let json = { error: `${response?.status} ${response?.statusText}` }

    // log response
    Logger.http({
      module: resourcePath[0],
      metadata: itemPayload,
      apiInfo: { endpoint: path, method },
      httpResponse: text,
    })

    try {
      json = JSON.parse(text)
    } catch (err) {
      // eslint-disable-next-line no-console
      json = { error: 'Something went wrong, please try again later.' }
      console.error(`${method} ${path} returned invalid JSON: ${text}`)
    }

    dispatch({
      type: REQUEST_FINISHED,
      method,
      resourcePath,
      resourceJSON: json,
      message,
    })

    return { json, response }
  }
}

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message,
  }
}

// Initial State

const initialState = {
  pending: 0,
  pendingMutations: 0,
  properties: {},
}

// Reducer

export default function reducer(state = initialState, action) {
  const nextState = { ...state }

  switch (action.type) {
    case REQUEST_STARTED:
      nextState.pending += 1
      if (action.method !== 'GET') {
        nextState.pendingMutations += 1
        nextState.error = null
      }
      return nextState

    case REQUEST_FINISHED:
      let { resourcePath } = action
      const { method, resourceJSON } = action
      nextState.pending -= 1
      if (action.method !== 'GET') {
        nextState.pendingMutations -= 1
        // implementing this check for only specific orgs for now
        // TODO: remove this check in future
        if (
          resourceJSON &&
          process.env.REACT_APP_ORGANIZATIONS_FOR_DELETE_RESERVATIONS_CHECK.split(',').includes(
            state.me.organizationId.toString()
          ) &&
          resourcePath[0] === 'properties' &&
          resourcePath[2] === 'reservations' &&
          method === 'DELETE'
        ) {
          if (action.resourceJSON.error) {
            nextState.error = action.resourceJSON.error
          }
        }
      }
      if (action.message) {
        nextState.message = action.message
      }

      if (!resourceJSON) {
        return nextState
      }

      if (method === 'POST') {
        resourcePath = [...resourcePath, `${resourceJSON.id}`]
      }

      return upsertDataAtPath(nextState, resourcePath, resourceJSON)

    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      }

    default:
      return state
  }
}
