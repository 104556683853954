import moment from 'moment'
import { thirdPartyAnalytics } from '.'
import { TIME_OPTIONS } from '../constants'

export const islogoutTimeArrive = () => {
  // **************  Mixpanel Signout Event Implementation Start ************
  //2. We are checking here if last loggedInTime is above or equal 1 day then we will send signOut request to mixpanel and will
  //remove loggedInTime.
  const isLoggedInTime = localStorage.getItem('loggedInTime')
  if (isLoggedInTime) {
    const millisecondsPerDay = 1000 * 60 * 60 * 24
    const daysDifference = (new Date() - new Date(isLoggedInTime)) / millisecondsPerDay
    if (daysDifference >= 1) {
      const me = localStorage.getItem('me')
      const organizationData = me && JSON.parse(me)
      thirdPartyAnalytics.track('sign_out', {
        date_of_logout: `${new Date().toLocaleDateString('en-US', TIME_OPTIONS)} `,
        time_of_logout: new Date().toLocaleTimeString(),
        organization: organizationData && organizationData.organization ? organizationData.organization.name : '',
        user: organizationData && `${organizationData.firstName} ${organizationData.lastName}`,
        pm_id: organizationData && organizationData.email,
      })
      localStorage.clear()
    }
  }
  // **************  Mixpanel Signout Event Implementation End ************
}
