import React, { useState, useEffect } from 'react'
import { connect, getIn } from 'formik'
import classNames from 'classnames'
import { ImageDropzoneField } from '.'
import '../../styles/ImageSelection.scss'
import { get } from 'lodash'

const ImageSelection = ({
  name,
  defaultImages,
  dimensions,
  displayScale,
  saveOnExit,
  formik: { values, setFieldValue, submitForm },
}) => {
  const displayDimensions = {
    width: dimensions.width / displayScale,
    height: dimensions.height / displayScale,
  }

  const [showUploaded, setShowUploaded] = useState()
  const [currentImage, setCurrentImage] = useState(getIn(values, name))

  useEffect(() => {
    let currentValue = get(values, name)
    if (!currentValue) currentValue = defaultImages[0]
    setCurrentImage(currentValue)
    setFieldValue(name, currentValue)
    setShowUploaded(currentValue && !defaultImages.includes(currentValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.content.background])

  const selectDefault = image => {
    setFieldValue(name, image)
    setCurrentImage(image)
    setTimeout(() => {
      saveOnExit && submitForm()
    }, 0)
  }

  if (showUploaded === undefined) {
    return null
  }
  return (
    <div className="ImageSelection">
      {!showUploaded && (
        <div className="default-images card shadow-sm rounded">
          {defaultImages.map(image => (
            <div
              key={image}
              className={classNames('default-image', {
                selected: currentImage === image,
              })}
              onClick={() => selectDefault(image)}
            >
              <img key={image} src={image} alt={image} />
            </div>
          ))}
        </div>
      )}
      <ImageDropzoneField
        name={name}
        desiredSize={dimensions}
        displaySize={displayDimensions}
        showSelectNew
        defaultLabel
        saveOnExit={saveOnExit}
        showClear={showUploaded}
        clearLabel="Remove and choose from default images"
        showUploaded={showUploaded}
        fast={false}
      />
    </div>
  )
}

export default connect(ImageSelection)
