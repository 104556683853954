import React from 'react'

const HamburgerIcon = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 7H14V5H0V7ZM0 12H14V10H0V12ZM0 0V2H14V0H0Z" fill="#222529" />
    </svg>
    
  )
}

export default HamburgerIcon;
