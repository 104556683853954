import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { CARD_INDICATORS, ADDITIONAL_STRINGS } from '../../constants/home'

const HomeCard = ({
 cardDetails, cardData = {}, history, populateDrawer,
}) => {
  const showInfoButton = cardData.status === ADDITIONAL_STRINGS.DEFAULT_CONTENT || cardData.status === ADDITIONAL_STRINGS.INCOMPLETE
  return (
    <div className="data-row">
      <h4>{cardDetails.label}</h4>
      <p>{cardDetails.cardDescription}</p>
      <div className="action-panel">
        <span>
          <i className={`icon-status ${CARD_INDICATORS[cardData.status]}`} />
          <strong>{cardData.statusLabel}</strong>
          {showInfoButton && (
            <strong>
              <Link to="/home" onClick={() => populateDrawer(cardData)}>
                {ADDITIONAL_STRINGS.VIEW_INFO}
              </Link>
            </strong>
          )}
        </span>
        <button className="button" onClick={() => history.push(cardDetails.link)}>
          {cardDetails.buttonText}
        </button>
      </div>
    </div>
  )
}

export default withRouter(HomeCard)
