import React from 'react'
import { AddButton, ListActions } from '../list'

export const MessageContainerHeader = ({
 data, onCreateItem, openModal, status,
}) => {
  return (
    <>
      <ListActions>
        <div className="wrapper-left">
          <AddButton verb="New" item="Announcement" onClick={() => openModal({ onSave: onCreateItem })} />
        </div>
      </ListActions>
    </>
  )
}
