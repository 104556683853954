import React from 'react'
import { Tabs } from '../../components'
import { AdsIcon, SlideshowNew } from '../../icons'
import { SlideShow, Advertisement } from '.'
import { SECTIONS, SUB_SECTIONS } from '../../constants/thirdPartyAnalytics'

const Slideshow = ({ property: { id }, recordAnalytics }) => {
  return (
    <div className="slideshow has-max-width">
      <h6>Slideshow</h6>
      <SlideShow recordAnalytics={recordAnalytics.bind(this, SUB_SECTIONS.SLIDESHOW)} />
    </div>
  )
}

const Ads = ({ property: { id }, NestedResource, recordAnalytics }) => {
  return (
    <div className="">
      <Advertisement NestedResource={NestedResource} />
    </div>
  )
}

const SLIDESHOWS_AND_ADVERTISEMENTS_TABS = [
  {
    slug: '',
    component: Slideshow,
    label: 'Slideshow',
    icon: SlideshowNew,
  },
  {
    slug: 'Advertisement',
    component: Ads,
    label: 'Advertisement',
    icon: AdsIcon,
  },
]

const SlideshowAndAdvertisement = ({ property, NestedResource, recordAnalytics }) => {
  const handleAnalytics = subSection => {
    recordAnalytics && recordAnalytics({ section: SECTIONS.SLIDESHOW_AND_ADS, subSection })
  }

  return (
    <div className="AmenitiesAndSupplies">
      <Tabs
        navTitle="Slideshow / Ads"
        path={`properties/${property.id}/SlideshowAndAds`}
        tabs={SLIDESHOWS_AND_ADVERTISEMENTS_TABS}
        property={property}
        recordAnalytics={handleAnalytics}
        isSubTabs
        NestedResource={NestedResource}
        navText="Assign slideshows that will provide a luxurious ambience to your property while the GuestView Guide is not actively being used. View and manage slideshow ads for your property."
      />
    </div>
  )
}

export default SlideshowAndAdvertisement
