/* eslint-disable react/no-danger */
import React from 'react'
import { reduce, flatten, sortBy } from 'lodash'
import classNames from 'classnames'
import { MESSAGES } from '../../constants/strings'

const ContactHostPreview = props => {
  const {
    formik: { values },
  } = props

  const { message = '' } = values?.content || {}

  const { content = {} } = values
  const allTopics = flatten(
    reduce(
      content?.contacts,
      (result, value) => {
        return [...result, value.topics]
      },
      [],
    ),
  )

  const topics = sortBy(allTopics, t => t.isDefault)
  const requestEnabled = values?.content?.requestEnabled
  let renderContent = <></>;
  if (requestEnabled) {
    renderContent = (
      <>
        <div className="icons-container">
          <div className="icons-container-inner">{content.message.length ? <p>{content.message}</p> : null}</div>
          <p>{`${MESSAGES.WHAT_TYPE_OF_ISSUE}?`}</p>
          <div className="icons-grid has-thin-scroll">
            <ul>
              {!!topics?.length
                && React.Children.toArray(topics.map(topic => (
                  <li>
                    <img src={topic.iconURL} alt="topic" />
                    <span>{topic.text}</span>
                  </li>
                )))}
            </ul>
          </div>
        </div>
        <div className="footer">
          <span>{MESSAGES.BACK}</span>
        </div>
      </>
    )
  } else {
    renderContent = (
      <>
        <div className="message-container has-thin-scroll">
          <div className="message-container-inner">
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
        <div className="footer">
          <span>{MESSAGES.OK}</span>
        </div>
      </>
    )
  }

  return (
    <div className={classNames(
      {
        'contact-host-preview-switch-on': requestEnabled,
        'contact-host-preview-switch-off': !requestEnabled,
      },
      'content-panel',
    )}
    >
      <div className="title">Contact Us</div>
      {renderContent}
    </div>
  )
}

export default ContactHostPreview
