import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Resource from '../../Resource'
import PhoneNumber from '../PhoneNumber'
import { openModalWithContainer } from '../../reducers/modals'
import FallBackImage from '../../images/hostinput-fallback.png'
import HostCreateImage from '../../images/hostinput-create.png'

import '../../styles/HostInput.scss'
import { GENERAL_INFO } from '../../constants/forms/content'

const HostRow = props => {
  return (
    // TODO: Use new user component
    <div className={`HostInputRow ${props.openState}`} data-cy="input-host" onClick={props.onClick}>
      <img src={props.host.photoURL || FallBackImage} alt="" />
      {props.host.name}
      <span>
        <PhoneNumber>{typeof props.host?.contactNumber !== 'object' && props.host.contactNumber}</PhoneNumber>
      </span>
      {props.host.phoneExt && <span>ext.</span>}
      <span className="phoneExt">{props.host.phoneExt || ''}</span>
    </div>
  )
}

function HostInputWithHosts({
  hosts: _hosts, autoselectIfEmpty, value, onCreateHost, ...props
}) {
  const [open, setOpen] = useState(false)
  const [hosts, setHosts] = useState(_hosts)
  const current = hosts.find(h => h.id === value)

  useEffect(() => { autofillIfEmpty() }, [])

  useEffect(() => {
    // automatically select newly created hosts or the single host available
    const existingIds = hosts.map(h => h.id)
    const created = _hosts.filter(h => !existingIds.includes(h.id))
    if (created.length !== 0) {
      onSetHost(created[0])
    }
    setHosts(_hosts)
  }, [_hosts])

  const onSetHost = h => {
    props.onChange(h.id)
    setOpen(false)
  }

  const autofillIfEmpty = () => {
    if (autoselectIfEmpty && value === null && hosts && hosts.length > 0) {
      onSetHost(hosts[0])
    }
  }

  return (
    <div className="HostInput" tabIndex={-1} onBlur={() => setOpen(false)}>
      <HostRow
        onClick={() => setOpen(true)}
        host={current || { name: GENERAL_INFO.HOST.NO_HOST_SELECTED, contactNumber: '+15555555555' }}
        openState={open ? 'active' : 'closed'}
      />
      {open && (
        <div className="dropdown">
          {hosts
            .sort((a, b) => (a === current ? -1 : 1))
            .map(h => (
              <HostRow
                key={h.id}
                onClick={() => onSetHost(h)}
                host={h}
                openState={open ? 'active' : 'closed'}
              />
            ))}
          <HostRow
            onClick={onCreateHost}
            data-cy="option-create-host"
            host={{
              name: GENERAL_INFO.HOST.CREATE_NEW,
              contactNumber: '+15555555555',
              photoURL: HostCreateImage,
            }}
          />
        </div>
      )}
    </div>
  )
}

const HostInput = props => {
  return (
    <Resource collection path={['hosts']} placeholder={[]}>
      {(results, { onCreateItem }) => (results.length > 0 || !props.value ? (
        <HostInputWithHosts
          hosts={results}
          onCreateHost={() => props.dispatch(
            openModalWithContainer('HostContainer', {
              host: {},
              onSave: onCreateItem,
            }),
          )}
          {...props}
        />
      ) : null)}
    </Resource>
  )
}

HostInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default HostInput;
