import React from 'react'
import portraitIconURL from '../images/icons/portrait-window.png'

function PortraitIcon(props) {
  return (
    <span fill="none" viewBox="0 0 71 100" {...props}>
      <img src={portraitIconURL} alt="portrait" />
    </span>
  )
}

export default PortraitIcon
