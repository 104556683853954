import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { find, toNumber } from 'lodash'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import AdvertisementDrawer from './AdvertisementDrawer'
import { AddButton, MasterList } from '../list'
import { ADVERTISEMENT_COLUMNS } from '../../constants/tables/advertisements'
import { openModalWithContainer } from '../../reducers/modals'
import { setOrientation } from '../../reducers/advertisementData'
import { SLIDESHOW_COUNT_NUMBERS } from '../../constants/home'
import { MESSAGES } from '../../constants/strings'
import { pluralize } from '../../Utils'
import { apiRequest } from '../../reducers/data'

const AdvertisementTable = ({
  advertisement,
  item,
  steps,
  emptyMessage,
  dispatch,
  onUpdateItem,
  onCreateItem,
  onDeleteItem,
  properties,
  openRecipientProperties,
  customization,
  onCustomizationUpdate,
  recordAnalytics,
}) => {
  const memorizedColumns = useMemo(() => ADVERTISEMENT_COLUMNS, [])

  const openModal = async advertisement => {
    const val = advertisement ? advertisement.style : MESSAGES.ORIGINAL
    dispatch(setOrientation(val))

    const onSave = advertisement
      ? values => onUpdateItem({ id: `${advertisement.id}`, ...values })
      : values => values.title !== '' && onCreateItem(values)

    let medias = []
    let selectedImageId = 0
    let image
    if (advertisement?.type === 'Service') {
      const { json } = await dispatch(apiRequest([`services/${advertisement.serviceId}`], 'GET'))
      medias = json.serviceMedias
        && json.serviceMedias.length > 0
        && json.serviceMedias.sort((a, b) => a.mediaOrder - b.mediaOrder).filter(media => media.mediaType === 'IMAGE')
    }
    if (advertisement?.type === 'Recommendation') {
      const { json } = await dispatch(apiRequest([`customizations/${advertisement.subType}/id/${advertisement.customizationId}`], 'GET'))
      medias = [{ mediaUrl: json.content.imageURL, id: json.id }]
    }

    if (advertisement) {
      const match = find(medias, m => m.mediaUrl === advertisement.originalImageURL)
      selectedImageId = match ? match.id : 0
      if (!match) { image = advertisement.imageURL }
    }

    dispatch(
      openModalWithContainer('AdvertisementsContainer', {
        advertisement: advertisement || {},
        propertiesCount: properties.length,
        item,
        steps,
        onSave,
        medias,
        selectedImageId,
        image
      })
    )
  }

  const emailSetup = advertisement => {
    dispatch(
      openModalWithContainer(
        'EmailSetup',
        {
          advertisement: advertisement || {},
          propertiesCount: properties.length,
          item,
          steps,
        },

        {
          customClass: 'xs report-modal',
        }
      )
    )
  }

  const populateDrawer = data => {
    setDrawerContent(data)
    setDrawer(true)
  }

  const initialState = {
    onDeleteItem,
    rowAction: openModal,
    properties,
    emailSetup,
    propertiesCount: properties.length,
    openRecipientProperties,
    populateDrawer,
    onUpdateItem,
  }

  const [adsCount, setAdsCount] = useState(customization.content.noOfAds)
  const [slidesCount, setSlidesCount] = useState(customization.content.noOfSlideshows)

  const handleAdsCountChange = value => {
    setAdsCount(value)
    onCustomizationUpdate({
      ...customization,
      content: {
        ...customization.content,
        noOfAds: toNumber(value),
        noOfSlideshows: slidesCount,
      },
    })
  }

  const handleSlideshowCountChange = value => {
    setSlidesCount(value)
    onCustomizationUpdate({
      ...customization,
      content: {
        ...customization.content,
        noOfSlideshows: toNumber(value),
        noOfAds: adsCount,
      },
    })
  }

  const popover = (
    <Popover id="popover-positioned-bottom">
      <div className="cadence">
        <p>{MESSAGES.NO_OF_ADS}</p>
        <ul className="first-child">
          {SLIDESHOW_COUNT_NUMBERS.map(count => (
            <li
              className={classNames({ selected: adsCount >= count })}
              onClick={() => adsCount !== count && handleAdsCountChange(count)}>
              {count}
            </li>
          ))}
        </ul>
        <p>{MESSAGES.NO_OF_SLIDESHOWS}</p>
        <ul>
          {SLIDESHOW_COUNT_NUMBERS.map(count => (
            <li
              className={classNames({ selected: slidesCount >= count })}
              onClick={() => slidesCount !== count && handleSlideshowCountChange(count)}>
              {count}
            </li>
          ))}
        </ul>
      </div>
    </Popover>
  )

  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})

  const buttons = () => (
    <>
      <AddButton item={item} onClick={openModal} />
      <OverlayTrigger trigger="click" key="bottom" placement="bottom" overlay={popover} rootClose>
        <button className="button primary outline">
          {adsCount} {pluralize(adsCount, MESSAGES.AD_SINGULAR_STRING, MESSAGES.AD_PLURAL_STRING)} {MESSAGES.BETWEEN}{' '}
          {slidesCount} {pluralize(slidesCount, MESSAGES.SLIDESHOW_SINGULAR_STRING, MESSAGES.SLIDESHOW_PLURAL_STRING)}
        </button>
      </OverlayTrigger>
    </>
  )
  return (
    <>
      <MasterList
        columns={memorizedColumns}
        initialState={initialState}
        data={advertisement}
        emptyState={{
          text: emptyMessage,
        }}
        actions={buttons()}
        recordAnalytics={recordAnalytics}
        rowAction={openModal}
      />
      <AdvertisementDrawer data={drawerContent} properties={properties} showDrawer={showDrawer} setDrawer={setDrawer} />
    </>
  )
}

export default AdvertisementTable
