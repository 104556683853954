import React, { useState } from 'react'
import classNames from 'classnames'
import { connect, FieldArray } from 'formik'
import { get, isFunction } from 'lodash'
import { MdCancel, MdLock } from 'react-icons/md'
import { TextField, CharactersLeft } from '.'

import '../../styles/ListField.scss'

const ListField = ({
  maxItems,
  name,
  saveOnExit,
  disabled,
  addNew,
  fast,
  type,
  rows,
  maxLength,
  formik: { values, setFieldValue, handleSubmit },
  skipBlurMode = false,
}) => {
  const [currentLength, setCurrentLength] = useState(0)
  const items = get(values, name, [])
  const isDisabled = isFunction(disabled) ? disabled(values) : disabled
  const hasMaxItems = maxItems && items.length >= maxItems

  const onClickField = name => {
    const value = get(values, name)
    value && setCurrentLength(value.length)
  }

  const onChange = (e, name) => {
    const { value } = e.target
    setFieldValue(name, value)
    setCurrentLength(value.length)
  }

  return (
    <FieldArray
      name={name}
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        const add = () => {
                            // items && arrayHelpers.insert(items.length - items.filter(({ readOnly }) => readOnly).length, { text: '' })
                            arrayHelpers.push({ text: '' })
                            setCurrentLength(0)
                          }
        return (
          <div className="ListField">
            {items?.map(({ readOnly }, index) => {
              const fieldName = `${name}.${index}.text`
              const remove = () => {
                if (items.length > 1) {
                  arrayHelpers.remove(index)
                  saveOnExit
                    && setTimeout(() => {
                      handleSubmit()
                    }, 500)
                }
              }
              return (
                <div key={index} className="ListItemField">
                  <TextField
                    name={fieldName}
                    saveOnExit={!readOnly && saveOnExit}
                    readOnly={readOnly}
                    disabled={readOnly || isDisabled}
                    type={type || 'text'}
                    rows={rows}
                    fast={fast}
                    maxLength={maxLength}
                    onClick={() => onClickField(fieldName)}
                    onChange={e => onChange(e, fieldName)}
                    skipBlurMode={skipBlurMode}
                  />
                  {readOnly ? (
                    <MdLock />
                  ) : (
                    <MdCancel
                      onClick={!isDisabled ? remove : null}
                      className={classNames({ clickable: !isDisabled })}
                    />
                  )}
                </div>
              )
            })}
            <div className="list-field-controls">
              <div
                tabIndex={0}
                className={classNames('link', {
                  disabled: isDisabled || hasMaxItems,
                })}
                onClick={isDisabled || hasMaxItems ? null : add}
              >
                + Add
                {' '}
                {addNew || 'Item'}
                {' '}
                {maxItems && `(${maxItems} max.)`}
              </div>
              {maxLength && !isDisabled && <CharactersLeft length={currentLength} maxLength={maxLength} />}
            </div>
          </div>
        )
      }}
    />
  )
}

export default connect(ListField)

ListField.defaultProps = {
  disabled: false,
}
