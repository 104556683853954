import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FaCheck } from 'react-icons/fa'

import '../styles/SavingToast.scss'
import { setMessage } from '../reducers/data'
import { MESSAGES } from '../constants/strings'

const DISMISS_DELAY = 3000

const Message = {
  Hidden: 'hidden',
  Saving: 'saving',
  Saved: 'saved',
  Error: 'error',
}

function SavingToast({ pendingMutations, error, ...props }) {
  const [_pendingMutations, setPendingMutations] = useState(pendingMutations)
  const [message, setMessage] = useState(Message.Hidden)
  const [messageStartedAt, setMessageStartedAt] = useState(Date.now())
  let timeout = null

  useEffect(() => {
    return clearTimeout(timeout)
  })

  useEffect(() => {
    if (pendingMutations > 0) {
      setMessage(Message.Saving)
      setMessageStartedAt(Date.now())
      setPendingMutations(pendingMutations)
    }

    if (_pendingMutations > 0 && pendingMutations === 0) {
      const delay = Math.max(0, Math.min(400, messageStartedAt - Date.now()))
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        if (error) {
          setMessage(Message.Error)
        } else {
          setMessage(Message.Saved)
        }
        onClearAfterDelay()
      }, delay)
    }
  }, [pendingMutations])

  const onClearAfterDelay = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setMessage(Message.Hidden)
      if (props.message && message !== Message.Saving) {
        props.setMessage(null)
      }
    }, DISMISS_DELAY)
  }

  if (message === Message.Saving) {
    return <div className="SavingToast saving">{MESSAGES.SAVING}</div>
  }
  if (message === Message.Saved) {
    return (
      <div className="SavingToast saved">
        <FaCheck style={{ marginRight: 5 }} />
        {props.message || MESSAGES.SAVED}
      </div>
    )
  }
  if (message === Message.Error) {
    return (
      <div className="SavingToast error">
        <FaCheck style={{ marginRight: 5 }} />
        {error}
      </div>
    )
  }
  return <span />
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMessage: ownProps.setMessage ? ownProps.setMessage : msg => dispatch(setMessage(msg)),
  }
}

export default connect(state => {
  return {
    pendingMutations: state.data.pendingMutations,
    error: state.data.error,
    message: state.data.message,
  }
}, mapDispatchToProps)(SavingToast)
