import React from 'react'
import { WizardStep } from '.'
import { withWizard } from './WizardProvider'
import { FormModal } from '../../forms'

const Wizard = ({
 formData: { steps, initialValues }, preview = [], step, onSave, dispatch, ...etc
}) => {
  return (
    <FormModal
      onSave={onSave}
      dispatch={dispatch}
      initialValues={initialValues}
      validationSchema={steps[step].validation}
      render={props => (
        <WizardStep
          className={steps[step].className}
          stepComponent={steps[step].component}
          stepPreview={preview[step]}
          step={step}
          {...etc}
          {...props}
        />
      )}
      {...etc}
    />
  )
}

export default withWizard(Wizard)
