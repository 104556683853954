import React from 'react'
import Icon from './Icon'

function WelcomeScreenIcon(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props} width="1em" height="1em">
      <path d="M12.2727 0H2.72727C1.96023 0.0286458 1.32102 0.300781 0.809659 0.816406C0.298295 1.33203 0.0284091 1.97656 0 2.75V19.25C0.0284091 20.0234 0.298295 20.668 0.809659 21.1836C1.32102 21.6992 1.96023 21.9714 2.72727 22H12.2727C13.0398 21.9714 13.679 21.6992 14.1903 21.1836C14.7017 20.668 14.9716 20.0234 15 19.25V2.75C14.9716 1.97656 14.7017 1.33203 14.1903 0.816406C13.679 0.300781 13.0398 0.0286458 12.2727 0ZM12.9545 19.25C12.9261 19.6797 12.6989 19.9089 12.2727 19.9375H2.72727C2.30114 19.9089 2.07386 19.6797 2.04545 19.25V2.75C2.07386 2.32031 2.30114 2.09115 2.72727 2.0625H12.2727C12.6989 2.09115 12.9261 2.32031 12.9545 2.75V19.25Z" />
      <path d="M7.92614 10.3297C8.03977 10.1578 8.19602 10.0719 8.39489 10.0719C8.62216 10.0719 8.77841 10.1578 8.86364 10.3297L12.0597 15.1422C12.1733 15.3427 12.1733 15.5576 12.0597 15.7867C11.946 15.9872 11.7756 16.0875 11.5483 16.0875H3.19602C2.99716 16.0875 2.84091 15.9872 2.72727 15.7867C2.61364 15.5576 2.61364 15.3427 2.72727 15.1422L4.73011 12.1344C4.84375 11.9625 5.0142 11.8766 5.24148 11.8766C5.44034 11.8766 5.59659 11.9625 5.71023 12.1344L6.22159 12.9078L7.92614 10.3297Z" />
      <path d="M5.66761 9.51328C5.41193 9.77109 5.08523 9.9 4.6875 9.9C4.28977 9.9 3.96307 9.77109 3.70739 9.51328C3.4517 9.25547 3.32386 8.92604 3.32386 8.525C3.32386 8.12396 3.4517 7.79453 3.70739 7.53672C3.96307 7.27891 4.28977 7.15 4.6875 7.15C5.08523 7.15 5.41193 7.27891 5.66761 7.53672C5.9233 7.79453 6.05114 8.12396 6.05114 8.525C6.05114 8.92604 5.9233 9.25547 5.66761 9.51328Z" />
    </Icon>
  )
}

export default WelcomeScreenIcon
