import React from 'react'
import { WelcomeScreenIcon, WelcomeVideosIcon } from '../../icons'
import { Tabs } from '..'
import WelcomeScreen from './WelcomeScreen'
import { ContentEditor, ContentEditorTable } from '.'
import { WELCOME_VIDEO, WELCOME_SCREEN } from '../../constants/forms/propertyInfo'
import { WELCOME_VIDEO_COLUMNS, WELCOME_SCREEN_COLUMNS } from '../../constants/tables/content'
import '../../styles/WelcomeScreenAndVideo.scss'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const WELCOME_SCREEN_AND_VIDEO_TABS = [
  {
    slug: '',
    label: 'Welcome Screen',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Welcome Screen"
        content={WELCOME_SCREEN}
        columns={WELCOME_SCREEN_COLUMNS}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
      />
    ),
    icon: WelcomeScreenIcon,
  },
  {
    slug: 'videos',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Welcome Video"
        content={WELCOME_VIDEO}
        columns={WELCOME_VIDEO_COLUMNS}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
      />
    ),
    label: 'Welcome Video',
    icon: WelcomeVideosIcon,
  },
]

const WelcomeScreenAndVideo = ({ properties, recordAnalytics }) => {
  return (
    <div className="WelcomeScreenAndVideo">
      <ContentEditor className="ContentEditor WelcomeScreenAndVideoContent">
        <Tabs
          properties={properties}
          recordAnalytics={recordAnalytics.bind(this, SECTIONS.WELCOME_SCREEN_AND_VIDEO)}
          path="content/welcome-screen"
          tabs={WELCOME_SCREEN_AND_VIDEO_TABS}
          isSubTabs
          navTitle="Welcome Screen / Videos"
        />
      </ContentEditor>
    </div>
  )
}

export default WelcomeScreenAndVideo
