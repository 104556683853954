import { getIn } from 'formik'
import React from 'react'
import DropzoneInput from '../../components/DropzoneInput'
import { S3_ROOT_URL } from '../../constants'
import { apiRequest } from '../../reducers/data'
import { fieldify } from './utils'
import '../../styles/DropzoneInput.scss'
import { confirm } from '../../containers/modals/AlertMessageContainer'

const getSizeInMbs = size => parseFloat((size / 1e6).toFixed(2))

const VideoDropzoneField = fieldify(
  ({
 field, fieldsPath, warnOnRemove, removeMessage, form, invalid, dispatch, saveOnExit, ...rest
}) => {
    const removeVideo = () => {
      form.setFieldValue(field.name, null)
      form.setFieldValue('duration', null)
      form.setFieldValue('size', null)
      form.setFieldValue('imageUrl', null)
    }

    const remove = eve => {
      eve.preventDefault()
      if (warnOnRemove && form.values.propertyIds && form.values.propertyIds.length) {
        confirm({
          title: 'Confirmation',
          body: removeMessage,
          onConfirm: removeVideo,
        })
      } else {
        removeVideo()
      }
    }

    const onDropHandler = file => {
      form.setFieldValue('size', getSizeInMbs(file.size))
      const preview = URL.createObjectURL(file)
      const video = document.createElement('video')
      video.src = preview
      video.addEventListener('loadedmetadata', () => {
        form.setFieldValue('duration', Math.round(video.duration))
      })
    }

    const onFinish = async (info, onComplete) => {
      const { json } = await dispatch(
        apiRequest(['transcoder/start'], 'POST', {
          uploadedFileKey: info.fileKey,
        }),
      )
      const videoURL = `${S3_ROOT_URL}/${json.outputFileKey}`
      form.setFieldValue('imageURL', `${videoURL}.0000000.jpg`)
      form.setFieldValue(field.name, videoURL)
      onComplete && onComplete()
    }

    const value = getIn(form.values, field.name)

    return (
      <DropzoneInput
        {...field}
        invalid={invalid}
        className="VideoDropZone"
        value={value}
        {...rest}
        remove={remove}
        onFinish={onFinish}
        onDropHandler={onDropHandler}
      />
    )
  },
)

export default VideoDropzoneField
