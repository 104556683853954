/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import {
 find, get, isUndefined, orderBy,
} from 'lodash'
import classNames from 'classnames'

import { FormModal } from '../../forms'
import { generateForm } from '../../forms/utils'
import { withContent } from './ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { ListCellBadge } from '../list'
import { CONTENT_FIELDS_PATH } from '../../constants/forms/content'

import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { GoToIcon } from '../../icons'

const NEW_CUSTOM_CUSTOMIZATION_KEY = 'new_custom'

const SelectLabel = ({ customization: { name, isDefault } }) => (
  <div className="SelectLabel">
    {name}
    {' '}
    {isDefault && <ListCellBadge label="Default" />}
  </div>
)

const CustomizationForm = ({ handleSubmit, formFields }) => {
  return <form onSubmit={handleSubmit}>{formFields}</form>
}

const ContentPickerAddProperty = ({
  propertyId,
  data,
  content,
  onCreateItem,
  onUpdateItem,
  history,
  totalProperties,
  onWifiUpdation,
}) => {
  const { fields } = content
  const [selectedCustomizationId, setSelectedCustomizationId] = useState()

  useEffect(() => {
    const currentSet = find(data, ({ propertyIds }) => propertyIds && propertyIds.includes(propertyId))
    if (currentSet && isUndefined(selectedCustomizationId)) {
      setSelectedCustomizationId(currentSet.id)
    }
  }, [data])
  // Create Options
  const globalCustomizations = Object.values(data).filter(({ propertyId }) => !propertyId)
  const sortedCustomizations = orderBy(globalCustomizations, ['isDefault', 'name'], ['desc', 'asc'])

  const options = Object.values(sortedCustomizations).reduce((options, customization) => {
    options.push({
      value: customization.id,
      label: <SelectLabel customization={customization} />,
    })
    return options
  }, [])

  const custom = find(data, customization => customization.propertyId === propertyId)

  const customOption = {
    value: get(custom, 'id', NEW_CUSTOM_CUSTOMIZATION_KEY),
    label: 'Custom for this property...',
  }

  options.push(customOption)

  // Create Form
  const isCustom = selectedCustomizationId === customOption.value
  const customization = find(data, ({ id }) => id === selectedCustomizationId, {})
  const formFields = fields({ readOnly: !isCustom, saveOnExit: isCustom }).map(field => ({
    ...field,
    name: `${CONTENT_FIELDS_PATH}.${field.name}`,
  }))

  const customizationForm = generateForm({
    form: formFields,
    props: customization,
  })

  const onSubmit = async values => {
    if (selectedCustomizationId === NEW_CUSTOM_CUSTOMIZATION_KEY) {
      if (values === NEW_CUSTOM_CUSTOMIZATION_KEY) {
        values = {
          content: { wifiFormat: 'password', wifiName: '', wifiPass: '' },
        }
      }
      onWifiUpdation(
        {
          ...values,
          propertyIds: [propertyId],
          propertyId,
        },
        'new',
      )
    } else {
      onWifiUpdation(
        {
          ...values,
          propertyIds: [propertyId],
          propertyId,
          id: selectedCustomizationId,
        },
        'update',
      )
    }
  }
  const customizatioPropertyIds = get(customization, 'propertyIds', [])
  const propertiesApplied = customizatioPropertyIds.length

  return (
    <div className="ContentPicker Card">
      <div className={classNames('select-wrapper', { custom: isCustom })}>
        <Select
          classNamePrefix="select"
          className="select-list"
          options={options}
          onChange={customization => {
            setSelectedCustomizationId(customization.value)
            onWifiUpdation(customization.value, 'switch')
          }}
          value={selectedCustomizationId && options.filter(option => option.value === selectedCustomizationId)}
        />
        <PopoverMenu>
          <PopoverMenuItem className="properties-applied">
            <div className="meta">Used By</div>
            <div className="bold">
              {`${propertiesApplied} of ${totalProperties} Properties`}
            </div>
          </PopoverMenuItem>
        </PopoverMenu>
      </div>
      <div className="card-content padding-bottom">
        {selectedCustomizationId && (
          <FormModal
            enableReinitialize
            initialValues={customizationForm.initialValues}
            validationSchema={customizationForm.validationNew}
            onSubmit={onSubmit}
            render={_ => <CustomizationForm formFields={customizationForm.component} />}
          />
        )}
      </div>
    </div>
  )
}

export default withRouter(withContent(ContentPickerAddProperty))
