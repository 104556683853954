import React from 'react'
import { Link } from 'react-router-dom'

const SaveContinueButton = ({ to, onClick, children }) => {
  return (
    <Link to={to} className="btn btn-primary white" onClick={onClick}>
      {children}
    </Link>
  )
}

export default SaveContinueButton

SaveContinueButton.defaultProps = {
  children: 'Save & Continue',
}
