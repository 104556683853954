// Action Types

export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const EDIT_MODAL_PROPS = 'EDIT_MODAL_PROPS'
export const EDIT_MODAL_OPTIONS = 'EDIT_MODAL_OPTIONS'

// Action Creators

export function openModalWithContainer(container, props = {}, options = null) {
  return {
    type: SET_ACTIVE_MODAL,
    active: container,
    props,
    options,
  }
}

export const closeModal = () => ({ type: CLOSE_MODAL })

export function changeModalProps(props = {}) {
  return {
    type: EDIT_MODAL_PROPS,
    props,
  }
}

export function changeModalOptions(options = {}) {
  return {
    type: EDIT_MODAL_OPTIONS,
    options,
  }
}

// Initial State

const initialState = {
  active: null,
}

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        active: action.active,
        props: action.props,
        options: action.options,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        active: null,
      }
    case EDIT_MODAL_PROPS:
      return {
        ...state,
        props: {
          ...state.props,
          ...action.props,
        },
      }
    case EDIT_MODAL_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options,
        },
      }

    default:
      return state
  }
}
