/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect, getIn } from 'formik'
import { SelectField, TextField, TextareaField } from '.'
import FormGroup from '../FormGroup'

const WIFI_FIELDS = {
  password: {
    label: 'Network and Password',
    fields: [
      {
        label: 'WiFi Network',
        name: 'wifiName',
      },
      {
        label: 'WiFi Password',
        name: 'wifiPass',
      },
    ],
  },
  instruction: {
    label: 'Network and Instructions',
    fields: [
      {
        label: 'WiFi Network',
        name: 'wifiName',
      },
      {
        label: 'Instructions',
        name: 'wifiPass',
        component: TextareaField,
        maxLength: 96,
      },
    ],
  },
  url: {
    label: 'Connect Via Website',
    fields: [
      {
        label: 'URL',
        name: 'wifiName',
      },
      {
        label: 'Instructions',
        name: 'wifiPass',
        component: TextareaField,
        maxLength: 96,
      },
    ],
  },
}

const WifiFields = ({
 name, fieldsPath, formik: { values, setFieldValue }, ...props
}) => {
  const options = Object.keys(WIFI_FIELDS).reduce((options, optionKey) => {
    options.push({ [optionKey]: WIFI_FIELDS[optionKey].label })
    return options
  }, [])

  useEffect(() => {
    const initial = getIn(values, name)
    if (!initial) {
      setFieldValue(name, 'password')
    }
  }, [])

  const selectedFormat = getIn(values, name)
  const selected = WIFI_FIELDS[selectedFormat]

  return (
    <>
      <FormGroup label="WiFi Format">
        <SelectField name={name} options={options} readOnly={props.readOnly} fast={false} defaultValue="password" />
      </FormGroup>
      {selected
        && selected.fields.map(field => {
          const Component = field.component || TextField
          return (
            <FormGroup key={field.name} label={field.label}>
              <Component {...props} {...field} name={`${fieldsPath}.${field.name}`} fast={false} invalid={false} />
            </FormGroup>
          )
        })}
    </>
  )
}

export default connect(WifiFields)
