import React, { useEffect, useState } from 'react'
import { fieldify } from '../../forms/fields/utils'
import plusIcon from '../../images/icons/plus-icon.png'
import { ImageDropzoneField } from '../../forms/fields'
import MediaDisplay from '../MediaDisplay'
import { MdCancel } from 'react-icons/md'
import classNames from 'classnames'

const AdvertisementMedia = fieldify(({ form: { setFieldValue, values } }) => {
  const { selectedImageId, medias } = values
  const [image, setImage] = useState(values.image || null)
  const [selectedImg, setSelectedImgId] = useState(selectedImageId || 0)

  useEffect(() => {
    image !== values.image && setImage(values.image)
    selectedImg !== values.selectedImageId && setSelectedImgId(values.selectedImageId)
  }, [values])

  const onDeleteImg = () => {
    setFieldValue('image', null)
    setFieldValue('imageURL', (medias && medias[0]?.mediaUrl) || null)
    setFieldValue('originalImageURL', (medias && medias[0]?.mediaUrl) || null)
    setFieldValue('selectedImageId', (medias && medias[0]?.id) || 0)
  }

  const onSelectImg = media => {
    setFieldValue('imageURL', media.mediaUrl)
    setFieldValue('originalImageURL', media.mediaUrl)
    setSelectedImgId(media.id)
  }

  const onUpload = imgURL => {
    setImage(imgURL)
    setFieldValue('imageURL', imgURL)
    // setFieldValue('originalImageURL', imgURL)
    setSelectedImgId(0)
  }

  return (
    <div className="thumb-gallery has-thin-scroll scroll-dark-grey scroll-x">
      <div className="add-to-gallery">
        {image && (
          <div className="Delete" onClick={onDeleteImg}>
            <MdCancel />
          </div>
        )}
        {image && <img
          className={classNames({ 'selected-img': selectedImg === 0 })}
          src={image}
          height={100}
          width={100}
          onClick={() => onSelectImg({ mediaUrl: image, id: 0 })}
        />}
        {!image && (
          <ImageDropzoneField
            flexible
            addNew
            saveOriginal
            desiredSize={{ width: 1080, height: 1920 }}
            displaySize={{ width: 100, height: 100 }}
            name="image"
            onChange={onUpload}
          />
        )}
      </div>
      {medias &&
        medias.length > 0 &&
        medias.map((media, index) => (
          <div key={index}>
            <img
              className={classNames({ 'selected-img': selectedImg === media.id })}
              src={media.mediaUrl}
              height={100}
              width={100}
              onClick={() => onSelectImg(media)}
            />
          </div>
        ))}
    </div>
  )
})

export default AdvertisementMedia
