import React from 'react'
import { Field, FastField } from 'formik'
import { isFunction, get } from 'lodash'

export const fieldify = WrappedComponent => {
  const WithErrorComponent = props => {
    const { name } = props.field
    const error = get(props.form.errors, name)
    let { disabled } = props
    if (isFunction(disabled)) {
      disabled = disabled(props.form)
    }
    return (
      <div>
        <WrappedComponent {...props} invalid={!!error} disabled={disabled} />
        {!!error && <div className="invalid-feedback">{error}</div>}
      </div>
    )
  }

  const FieldifyComponent = ({ fast, ...props }) => (fast === false ? (
    <Field component={WithErrorComponent} {...props} />
  ) : (
    <FastField component={WithErrorComponent} {...props} />
  ))
  return FieldifyComponent
}
