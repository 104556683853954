import Accordion from '../../../components/Accordion'
import MessagePreview from '../../../forms/previews/MessagePreview'

const MESSAGE__PREVIEW = {
  className: 'ListingPreview ServiceDetail device-frame announcement-preview-window',
  Parent: Accordion,
  title: 'Announcement Preview',
  hideArrow: true,
  children: [
    {
      className: ' has-min-height',
      children: [
        {
          component: MessagePreview,
        },
      ],
    },
  ],
}

export const MESSAGE_PREVIEW = {
  0: [MESSAGE__PREVIEW],
}
