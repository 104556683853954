import React from 'react'

const RoleHAU = ({ firstName, role }) => {
  if (!firstName) {
    return <div className="inactive-column">{role}</div>
  }

  return <div>{role}</div>
}

export default RoleHAU
