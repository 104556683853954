import React from 'react'
import face1 from '../../images/smileys/face-1-angry.png'
import face2 from '../../images/smileys/face-2-sad.png'
import face3 from '../../images/smileys/face-3-meh.png'
import face4 from '../../images/smileys/face-4-good.png'
import face5 from '../../images/smileys/face-5-great.png'

export default function SmileyForFeedback(rating) {
  let src
  switch (rating) {
    case 1:
      src = face1
      break
    case 2:
      src = face2
      break
    case 3:
      src = face3
      break
    case 4:
      src = face4
      break
    case 5:
      src = face5
      break
    default:
      src = undefined
  }
  if (src) {
    return <img src={src} alt="rating" height="24" />
  }
  return <span>No rating</span>
}
