import * as Yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { some, get } from 'lodash'

import { pluralize } from '../Utils'

Yup.addMethod(Yup.string, 'phone', function () {
  return this.test({
    name: 'is-phone',
    // eslint-disable-next-line no-template-curly-in-string
    message: 'Invalid ${path}',
    test(value) {
      if (value) {
        const phoneNumber = parsePhoneNumberFromString(value)
        return phoneNumber ? phoneNumber.isPossible() : false
      }
      return true
    },
  })
})

Yup.addMethod(Yup.array, 'atLeast', function (minimum) {
  return this.test({
    name: 'at-least',
    message({ path }) {
      return `At least ${minimum} ${pluralize(minimum, path)} must be selected`
    },
    params: { minimum },
    test(v) {
      return v.length >= minimum
    },
  })
})

Yup.addMethod(Yup.string, 'atLeastOne', function (fieldNames) {
  return this.test({
    name: 'at-least-one',
    message() {
      return 'One of these fields is required'
    },
    test() {
      return some(fieldNames, fieldName => {
        const val = get(this.parent, fieldName, false)
        return val
      })
    },
  })
})

export default Yup
