import React from 'react'
import Countdown from 'react-countdown'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import moment from 'moment'

const CountdownTimer = ({ date, checkCartAbandon }) => {
  // eslint-disable-next-line radix
  const cartAbandonDelay = parseInt(process.env.REACT_APP_CART_ABANDONED_DELAY)
  let diffInSecs = moment(date + cartAbandonDelay).diff(moment(), 'seconds')
  if (diffInSecs < 0) {
    diffInSecs = 0
  }

  const renderer = ({ minutes, seconds }) => {
    if (minutes < 1) {
      return <strong>{`${seconds} secs`}</strong>
    }
    return <strong>{`${minutes + 1} mins`}</strong>
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <CountdownCircleTimer
        isPlaying
        size={24}
        strokeWidth={3}
        duration={process.env.REACT_APP_CART_ABANDONED_DELAY / 1000}
        colors={['#77C910', '#E8AB10', '#FC3636']}
        colorsTime={[10, 5, 0]}
        initialRemainingTime={diffInSecs}
      />
      <Countdown date={date + cartAbandonDelay} renderer={renderer} onComplete={checkCartAbandon} />
    </div>
  )
}

export default CountdownTimer
