import React from 'react'
import { Hosts, Notifications } from '.'
import TabAdmin from '../../containers/PropertyDetail/TabAdmin'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const HostsAndUsers = ({ recordAnalytics }) => {
  return (
    <>
      <Hosts recordAnalytics={recordAnalytics} />
      {/* <Notifications /> */}
      <TabAdmin />
    </>
  )
}

export default HostsAndUsers
