import React, { useState } from 'react'
import {
 connect, Field, FieldArray, getIn,
} from 'formik'
import { capitalize, get } from 'lodash'
import { Input } from 'reactstrap'
import classNames from 'classnames'
import SelectField from './SelectField'
import TextField from './TextField'
import { fieldify } from './utils'

const FEE_OPTIONS = [
  { fixed: 'Fixed' },
  { hourly: 'Hourly' },
  { daily: 'Daily' },
  { free: 'Free' },
  { custom: 'Custom' },
]

const initialFeeMarkup = { feeType: 'fixed', price: null, customFeeMessage: '' }

const FeeField = fieldify(
  ({
    form: { setFieldValue, values, errors },
    feeOptions,
    field,
    fieldPath,
    fieldIndex,
    canAdd,
    onAdd,
    onRemove,
    validateForm = null,
    ...props
  }) => {
    const { feeType } = field

    const currentValues = get(values, fieldPath)
    const currentErrors = get(errors, fieldPath)

    const error = currentErrors && currentErrors.length && currentErrors[fieldIndex]

    const value = get(values, `${fieldPath}[${fieldIndex}].feeType`)

    let feeOptionsArr = [...FEE_OPTIONS]

    for (const index in currentValues) {
      const { feeType } = currentValues[index]
      const capitalizedFeeType = capitalize(feeType)
      feeOptionsArr = feeOptionsArr.filter(item => Object.values(item)[0] !== capitalizedFeeType)
    }

    if (field.feeType) {
      feeOptionsArr.unshift({ [field.feeType]: capitalize(field.feeType) })
    }

    feeOptionsArr = [...feeOptionsArr]

    const onChange = evt => {
      if (evt.target.value === 'custom') {
        setFieldValue(`${fieldPath}[${fieldIndex}].price`, null)
      } else {
        setFieldValue(`${fieldPath}[${fieldIndex}].customFeeMessage`, null)
      }
      return setFieldValue(`${fieldPath}[${fieldIndex}].feeType`, evt.target.value)
    }

    return (
      <>
        <div className="row pb-2 mb-1">
          <div className="col-6 pr-0">
            <div>
              <Input
                type="select"
                className={classNames('SelectField', { 'is-invalid': error && error.feeType })}
                name={`${fieldPath}[${fieldIndex}].feeType`}
                value={value}
                onChange={onChange}
                // onBlur={validateForm && validateForm()}
              >
                <option hidden value="">
                  Please Select
                </option>
                {feeOptionsArr &&
                  feeOptionsArr.map(option => {
                    const entries = Object.entries(option)
                    const [value, label] = entries[0]
                    return (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    )
                  })}
              </Input>
              {!!error && error.feeType && <div className="invalid-feedback">{error.feeType}</div>}
            </div>
          </div>

          {(feeType === 'custom' || feeType === 'free') && (
            <div className="col-5">
              <TextField
                name={`${fieldPath}[${fieldIndex}].customFeeMessage`}
                {...props.customFeeMessage}
                fast={false}
                allowNegative={false}
                validateForm={validateForm}
                disabled={feeType === 'free' || !feeType}
              />
            </div>
          )}

          {/* {feeType === 'free' && null} */}

          {feeType !== 'custom' && feeType !== 'free' && (
            <div className="col-5">
              <TextField
                formatter={feeType !== 'free' ? 'currency' : null}
                name={`${fieldPath}[${fieldIndex}].price`}
                {...props.price}
                fast={false}
                allowNegative={false}
                skipBlurMode
                validateForm={validateForm}
              />
            </div>
          )}

          {fieldIndex === 0 && canAdd && (
            <span className="necicon-add-circle" onClick={onAdd}>
              <span className="path1" />
              <span className="path2" />
            </span>
          )}
          {fieldIndex !== 0 && <span className="necicon-trash-bin" onClick={onRemove.bind(this, fieldIndex)} />}
        </div>
      </>
    )
  },
)

const FeeFieldArray = ({
 renderMethods, feeRates, onAdd, onRemove, onEnd, canAdd, fieldPath, validateForm,
}) => {
  if (feeRates && feeRates.length) {
    return feeRates.map((field, fieldIndex) => (
      <div key={fieldIndex}>
        <FeeField
          fieldPath={`${fieldPath}['feeRates']`}
          field={field}
          // component={FeeField}
          feeOptions={FEE_OPTIONS}
          customFeeMessage={{
            maxLength: 25,
          }}
          price={{
            isAllowed: v => {
              const { formattedValue, value } = v
              return formattedValue === '' || (value && value.length) <= 9
            },
          }}
          onAdd={onAdd}
          onRemove={onRemove}
          onEnd={onEnd}
          fieldIndex={fieldIndex}
          canAdd={canAdd}
          formik={renderMethods.form}
          validateForm={validateForm}
        />
      </div>
    ))
  }
}

const MultiFeeRate = ({
 formik: { values }, parentFieldName, parentIndex, fieldPath, validateForm = null,
}) => {
  const [canAdd, setCanAdd] = useState(true)

  const getSelectedFeeTypes = () => {
    let selectedFeeTypes = null
    if (
      values
      && values[parentFieldName]
      && values[parentFieldName][parentIndex]
      && values[parentFieldName][parentIndex].feeRates
    ) {
      const selectedValues = [...values[parentFieldName][parentIndex].feeRates]
      selectedFeeTypes = selectedValues.map(item => item.feeType)
    }
    return selectedFeeTypes
  }

  const feeOptionHandler = () => {
    const selectedFeeTypes = getSelectedFeeTypes()
    if (!selectedFeeTypes) return

    let updatedFeeOptions = [...FEE_OPTIONS]
    updatedFeeOptions = updatedFeeOptions.filter(
      item => !selectedFeeTypes.includes(Object.values(item)[0].toLowerCase()),
    )
    return updatedFeeOptions
  }

  const onAdd = renderMethods => {
    const newFeeOptions = feeOptionHandler()
    if (newFeeOptions.length <= 1) setCanAdd(false)
    const updatedFeeType = Object.values(newFeeOptions[0])[0].toLowerCase()
    const initialFeeOption = { ...initialFeeMarkup, feeType: updatedFeeType }
    if (updatedFeeType === 'free') {
      initialFeeOption.price = null
    }
    renderMethods.unshift(initialFeeOption)
  }

  const onRemove = (renderMethods, fieldIndex) => {
    renderMethods.remove(fieldIndex)
    !canAdd && setCanAdd(true)
  }

  let feeRates = null

  if (
    values
    && values[parentFieldName]
    && values[parentFieldName][parentIndex]
    && values[parentFieldName][parentIndex].feeRates
    && values[parentFieldName][parentIndex].feeRates.length
  ) {
    feeRates = values[parentFieldName][parentIndex].feeRates
  }

  return (
    <FieldArray
      key={feeRates.length}
      name={`${fieldPath}['feeRates']`}
      validateOnChange={false}
      render={renderMethods => (
        <FeeFieldArray
          renderMethods={renderMethods}
          feeRates={feeRates}
          onAdd={onAdd.bind(this, renderMethods)}
          onRemove={onRemove.bind(this, renderMethods)}
          canAdd={canAdd}
          fieldPath={fieldPath}
          validateForm={validateForm}
        />
      )}
    />
  )
}

export default connect(MultiFeeRate)
