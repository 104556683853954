import React from 'react'
import Icon from './Icon'

function GoToIcon(props) {
  return (
    <Icon viewBox="0 0 12 12" {...props} width="1em" height="1em">
      <path
        d="M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default GoToIcon
