const S3_ROOT_URL = process.env.REACT_APP_S3_BUCKET_URL

export const IconsS3Path = `${S3_ROOT_URL}/standard-icons`

export const defaultIcon = {
  name: 'Default',
  url: `${IconsS3Path}/icon-propertyinfo-default.svg`,
}

export const AmenityIconOptions = [
  {
    name: 'Default',
    url: `${IconsS3Path}/icon-propertyinfo-default.svg`,
  },
  {
    name: 'Dishwasher',
    url: `${IconsS3Path}/icon-propertyinfo-dishwasher.svg`,
  },
  {
    name: 'ATM/Bank',
    url: `${IconsS3Path}/icon-propertyinfo-atm.svg`,
  },
  {
    name: 'Coffee',
    url: `${IconsS3Path}/icon-propertyinfo-coffeecup.svg`,
  },
  {
    name: 'Checkmark',
    url: `${IconsS3Path}/icon-propertyinfo-checkmark.svg`,
  },
  {
    name: 'Gas',
    url: `${IconsS3Path}/icon-propertyinfo-gasstation.svg`,
  },
  {
    name: 'Games',
    url: `${IconsS3Path}/icon-propertyinfo-games.svg`,
  },
  {
    name: 'Groceries',
    url: `${IconsS3Path}/icon-propertyinfo-groceries.svg`,
  },
  {
    name: 'Hospital',
    url: `${IconsS3Path}/icon-propertyinfo-hospital.svg`,
  },
  {
    name: 'Hot Tub',
    url: `${IconsS3Path}/icon-propertyinfo-hottub.svg`,
  },
  {
    name: 'Lock',
    url: `${IconsS3Path}/icon-propertyinfo-lock.svg`,
  },
  {
    name: 'Music',
    url: `${IconsS3Path}/icon-propertyinfo-music.svg`,
  },
  {
    name: 'No Smoking',
    url: `${IconsS3Path}/icon-propertyinfo-nosmoking.svg`,
  },
  {
    name: 'Parking',
    url: `${IconsS3Path}/icon-propertyinfo-parking.svg`,
  },
  {
    name: 'Parties',
    url: `${IconsS3Path}/icon-propertyinfo-parties.svg`,
  },
  {
    name: 'Pets',
    url: `${IconsS3Path}/icon-propertyinfo-pets.svg`,
  },
  {
    name: 'Pharmacy',
    url: `${IconsS3Path}/icon-propertyinfo-pharmacy.svg`,
  },
  {
    name: 'Policies',
    url: `${IconsS3Path}/icon-propertyinfo-policies.svg`,
  },
  {
    name: 'Fridge',
    url: `${IconsS3Path}/icon-propertyinfo-refrigerator.svg`,
  },
  {
    name: 'Scissors',
    url: `${IconsS3Path}/icon-propertyinfo-scissors.svg`,
  },
  {
    name: 'Sink',
    url: `${IconsS3Path}/icon-propertyinfo-sink.svg`,
  },
  {
    name: 'Stove',
    url: `${IconsS3Path}/icon-propertyinfo-stove.svg`,
  },
  {
    name: 'Top Shopping',
    url: `${IconsS3Path}/icon-propertyinfo-shoppingbag.svg`,
  },
  {
    name: 'Thermostat',
    url: `${IconsS3Path}/icon-propertyinfo-thermostat.svg`,
  },
  {
    name: 'Top Groceries',
    url: `${IconsS3Path}/icon-propertyinfo-shoppingcart.svg`,
  },
  {
    name: 'Toiletries',
    url: `${IconsS3Path}/icon-propertyinfo-toiletries.svg`,
  },
  {
    name: 'Trash',
    url: `${IconsS3Path}/icon-propertyinfo-trash.svg`,
  },
  {
    name: 'Television',
    url: `${IconsS3Path}/icon-propertyinfo-TV.svg`,
  },
  {
    name: 'Washing Machine',
    url: `${IconsS3Path}/icon-propertyinfo-washingmachine.svg`,
  },
  {
    name: 'Pool',
    url: `${IconsS3Path}/icon-propertyinfo-pool.svg`,
  },
  {
    name: 'Elevator',
    url: `${IconsS3Path}/icon-propertyinfo-elevator.svg`,
  },
  {
    name: 'Bicycle',
    url: `${IconsS3Path}/icon-propertyinfo-bicycle.svg`,
  }, {
    name: 'Car',
    url: `${IconsS3Path}/icon-propertyinfo-car.svg`,
  }, {
    name: 'IT/Computer',
    url: `${IconsS3Path}/icon-propertyinfo-computer-it.svg`,
  }, {
    name: 'Fan',
    url: `${IconsS3Path}/icon-propertyinfo-fan.svg`,
  }, {
    name: 'Fitness',
    url: `${IconsS3Path}/icon-propertyinfo-fitness.svg`,
  }, {
    name: 'Housekeeping',
    url: `${IconsS3Path}/icon-propertyinfo-house-keeping.svg`,
  }, {
    name: 'Pencil/Supplies',
    url: `${IconsS3Path}/icon-propertyinfo-pencil-supplies.svg`,
  }, {
    name: 'Shower',
    url: `${IconsS3Path}/icon-propertyinfo-shower.svg`,
  }, {
    name: 'Speaker',
    url: `${IconsS3Path}/icon-propertyinfo-speaker.svg`,
  }, {
    name: 'Toys/Games',
    url: `${IconsS3Path}/icon-propertyinfo-toys-games.svg`,
  }, {
    name: 'Vacuum',
    url: `${IconsS3Path}/icon-propertyinfo-vacuum.svg`,
  }, {
    name: 'Wifi',
    url: `${IconsS3Path}/icon-propertyinfo-wifi.svg`,
  },
];
