import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'
import SlideshowInput from '../../components/SlideshowInput'

// TODO: FIX ME. Hook up with customizations
const SlideShowContent = ({ property, onSave, recordAnalytics }) => {
  const [artModeBundleId, setArtModeBundleId] = useState(property.artModeBundleId)

  return (
    <div className="card-content padding-y p-0 pb-3">
      <div className="form-group row">
        <div className="col-sm-12">
          <SlideshowInput
            value={artModeBundleId}
            onChange={artModeBundleId => {
              setArtModeBundleId(artModeBundleId)
              recordAnalytics && recordAnalytics()
              onSave({
                ...property,
                artModeBundleId,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

const SlideShow = ({ match, recordAnalytics }) => {
  return (
    <Resource path={['properties', match.params.id]} placeholderComponent={Loading}>
      {(property, { dispatch, onSave }) => (
        <div className="Card">
          <SlideShowContent property={property} onSave={onSave} dispatch={dispatch} recordAnalytics={recordAnalytics} />
        </div>
      )}
    </Resource>
  )
}

export default withRouter(SlideShow)
