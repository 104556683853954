import React from 'react'
import WelcomeScreenContent from '../../components/WelcomeScreenAndVideo/WelcomeScreenContent'
import { WELCOME_SCREEN } from '../../constants/forms/propertyInfo'
import { WelcomeScreenIcon } from '../../icons'

const WelcomeScreen = ({ property }) => {
  return (
    <WelcomeScreenContent
      property={property}
      content={WELCOME_SCREEN}
      emptyState={{
        icon: WelcomeScreenIcon,
        text: 'You have not yet added Welcome Screen for this property',
      }}
      manageLink="/content/welcome-screen"
    />
  )
}

export default WelcomeScreen
