/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import {
 find, get, isUndefined, orderBy,
} from 'lodash'
import classNames from 'classnames'

import { FormModal } from '../../forms'
import { generateForm } from '../../forms/utils'
import { withContent } from './ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { ListCellBadge } from '../list'
import { CONTENT_FIELDS_PATH } from '../../constants/forms/content'

import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { GoToIcon } from '../../icons'
import { MESSAGES } from '../../constants/strings'

const NEW_CUSTOM_CUSTOMIZATION_KEY = 'new_custom'

const SelectLabel = ({ customization: { name, isDefault } }) => (
  <div className="SelectLabel">
    {name}
    {' '}
    {isDefault && <ListCellBadge label="Default" />}
  </div>
)

const CustomizationForm = ({ handleSubmit, formFields }) => {
  return <form onSubmit={handleSubmit}>{formFields}</form>
}

const ContentPickerForNotification = ({
  propertyId,
  data,
  content,
  onCreateItem,
  onUpdateItem,
  history,
  totalProperties,
}) => {
  const { fields } = content
  const [selectedCustomizationId, setSelectedCustomizationId] = useState([])

  useEffect(() => {
    // const currentSet = find(data, ({ propertyIds }) => propertyIds.includes(propertyId))
    const currentSet = data.filter(item => item && item.propertyIds && item.propertyIds.includes(propertyId))
    const currentSetIds = currentSet && currentSet.map(curr => curr.id)
    currentSetIds.length ? setSelectedCustomizationId(currentSetIds) : setSelectedCustomizationId([])
  }, [data])
  // Create Options
  const globalCustomizations = Object.values(data).filter(({ propertyId }) => !propertyId)
  const sortedCustomizations = orderBy(globalCustomizations, ['isDefault', 'name'], ['desc', 'asc'])
  const options = Object.values(sortedCustomizations).reduce((options, customization) => {
    options.push({
      value: customization.id,
      label: customization.isDefault ? <SelectLabel customization={customization} /> : customization.name,
    })
    return options
  }, [])

  // Create Form
  const isCustom = false
  const customization = find(data, ({ id }) => id === selectedCustomizationId, {})
  const formFields = fields({ readOnly: !isCustom, saveOnExit: isCustom }).map(field => ({
    ...field,
    name: `${CONTENT_FIELDS_PATH}.${field.name}`,
  }))

  const customizationForm = generateForm({
    form: formFields,
    props: customization,
  })

  // const onSubmit = async values => {
  //   if (selectedCustomizationId === NEW_CUSTOM_CUSTOMIZATION_KEY) {
  //     const newCustomization = await onCreateItem({
  //       ...values,
  //       propertyIds: [propertyId],
  //       propertyId,
  //     })

  //     //If creating new custom customization at property level, handle switch once created
  //     if (propertyId) {
  //       setSelectedCustomizationId(newCustomization.json.id)
  //     }
  //   } else {
  //     return onUpdateItem({
  //       ...values,
  //       propertyIds: [propertyId],
  //       propertyId: isCustom && propertyId,
  //       id: selectedCustomizationId,
  //     })
  //   }
  // }

  // const customizatioPropertyIds = get(customization, 'propertyIds', [])
  // const propertiesApplied = customizatioPropertyIds.length

  return (
    <div className="ContentPicker Card">
      <div className={classNames('select-wrapper', { custom: isCustom })}>
        <Select
          isMulti
          classNamePrefix="select"
          className="select-list"
          options={options}
          onChange={customization => {
            // customization &&
            // setSelectedCustomizationId([...selectedCustomizationId, customization[customization.length - 1].value])
            if (customization) {
              const notificationsIds = customization.map(item => item.value)
              onUpdateItem({
                notifications: notificationsIds,
                id: `${propertyId}/switch`,
              })
            } else {
              selectedCustomizationId.length && onUpdateItem({ notifications: [], id: `${propertyId}/switch` })
            }
          }}
          value={selectedCustomizationId && options.filter(option => selectedCustomizationId.includes(option.value))}
        />
        <PopoverMenu>
          {/*     <PopoverMenuItem className="properties-applied">
            <div className="meta">Used By</div>
            <div className="bold">
              {propertiesApplied} of {totalProperties} Properties
            </div>
        </PopoverMenuItem>
          <PopoverMenuItem
            className={classNames({ disabled: isCustom })}
            onClick={() =>
              !isCustom
                ? content.type == 'checkInOutNotification'
                  ? history.push(`/settings/hosts-and-users?edit=${selectedCustomizationId}`)
                  : history.push(`/content/${content.location}?edit=${customization.id}`)
                : null
            }
          >
            <GoToIcon /> Open This Component
          </PopoverMenuItem>  */}
          <PopoverMenuItem onClick={() => history.push(`/content/${content.location}?add=${content.type}`)}>
            <GoToIcon />
            {MESSAGES.CREATE_NEW_COMPONENT}
          </PopoverMenuItem>
        </PopoverMenu>
      </div>
      {/*   <div className="card-content padding-bottom">
        <FormModal
          enableReinitialize
          initialValues={customizationForm.initialValues}
          validationSchema={customizationForm.validationNew}
          onSubmit={onSubmit}
          render={_ => <CustomizationForm formFields={customizationForm.component} />}
        />
        </div> */}
    </div>
  )
}

export default withRouter(withContent(ContentPickerForNotification))
