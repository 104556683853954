import React from 'react'
import Icon from './Icon'

function TabLeftArrowIcon() {
  return (
    <Icon width="8" height="13" viewBox="0 0 8 13" fill="none">
      <path
        d="M5.91016 11.9648L0.90625 6.71484C0.778646 6.58724 0.714844 6.44141 0.714844 6.27734C0.714844 6.11328 0.778646 5.95833 0.90625 5.8125L5.91016 0.589844C6.20182 0.334635 6.51172 0.325521 6.83984 0.5625C7.09505 0.872396 7.09505 1.18229 6.83984 1.49219L2.24609 6.30469L6.83984 11.0352C7.09505 11.3633 7.09505 11.6732 6.83984 11.9648C6.51172 12.2201 6.20182 12.2201 5.91016 11.9648Z"
        fill="#5591C7"
      />
    </Icon>
  )
}

export default TabLeftArrowIcon
