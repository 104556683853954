import { Drawer } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { MessageDrawerList, SentMessagesDrawerList } from './MessageDrawerList'
import { debounce } from 'lodash'
import { PropertyIcon } from '../../icons'
import { MESSAGES } from '../../constants/strings'

const MessagesDrawer = ({ showDrawer, setDrawer, properties, drawerData = {}, sentList = false }) => {
  const [findProperty, setFindProperty] = useState('')

  let recipientProperties = []

  if (sentList) {
    recipientProperties = drawerData.properties
  } else {
    const recipientPropertiesID = drawerData.properties
    for (let index = 0; index < recipientPropertiesID?.length; index++) {
      recipientProperties.push(properties.find(property => property.id === recipientPropertiesID[index].property.id))
    }
  }

  const filterProperties = () => {
    return (
      recipientProperties?.filter(propertyList => {
        const { property, nickname, addressLine1 } = propertyList || {}

        const matchesFindProperty = str => str?.toLowerCase().includes(findProperty)

        return sentList
          ? matchesFindProperty(property?.nickname) || matchesFindProperty(property?.addressLine1)
          : matchesFindProperty(nickname) || matchesFindProperty(addressLine1)
      }) || []
    )
  }

  const filteredProperties = findProperty ? filterProperties() : recipientProperties

  const debounceSearch = useCallback(debounce(setFindProperty, 250), [])

  function handleSearchChange(event) {
    const { value } = event.target
    debounceSearch(value.toLowerCase())
  }

  return (
    <Drawer anchor="right" open={showDrawer} onClose={() => setDrawer(false)}>
      <div className="dashboard-side-panel property-list-panel">
        <div className="close-side-panel">
          <span onClick={() => setDrawer(false)}>
            <i className="cross1" />
            <i className="cross2" />
          </span>
        </div>
        <div className="side-panel-content">
          <h2>Announcements</h2>
          <p>
            {`Below is the list of all properties that this announcement has been ${
              sentList ? 'sent to' : 'assigned to'
            }:`}
          </p>
          {recipientProperties?.length ? (
            <input
              type="search"
              className="search property-search property-drawer mb-2"
              placeholder="Search address, nickname"
              data-cy="property-search"
              onChange={handleSearchChange}
            />
          ) : (
            ''
          )}
          <div className="properties-list-wrapper">
            {!filteredProperties?.length ? (
              <div className="no-property-container">
                <div className="property-icon">
                  <PropertyIcon width="72" height="72" stroke='none' />
                </div>
                <div className='no-property-text'>
                  <b>{MESSAGES.NO_PROPERTY_FOUND}</b>
                </div>
              </div>
            ) : (
              <>
                {!sentList && <MessageDrawerList data={filteredProperties} />}
                {sentList && <SentMessagesDrawerList data={filteredProperties} />}
              </>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default MessagesDrawer
