import React, { useState } from 'react'

const IMAGE_SRC_REPLACE_TIME = 3 * 1000
const IMAGE_RETRY_LIMIT = 10

const TablePreviewImage = ({
 key, style, contain, width = 110, height = 88, fallbackIcon, ...props
}) => {
  const [loaded, setLoaded] = useState(true)

  const addDefaultSrc = ev => {
    loaded && setLoaded(false)
    if (!ev.target.hasOwnProperty('retryCount')) {
      ev.target.retryCount = 0
    }
    ev.target.retryCount += 1
    if (ev.target.retryCount < IMAGE_RETRY_LIMIT) {
      // for closure, unable to persist ev.target
      const eventTarget = ev.target
      setTimeout(() => {
        eventTarget.src = props.src
      }, IMAGE_SRC_REPLACE_TIME)
    } else {
      ev.target.src = fallbackIcon
      ev.target.style.objectFit = 'contain'
    }
  }

  return (
    <>
      <img
        key={key}
        onError={addDefaultSrc}
        onLoad={setLoaded.bind(this, true)}
        alt=""
        style={{
          width,
          height,
          objectFit: contain ? 'contain' : 'cover',
          padding: contain && 12,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          ...styles,
          ...style,
        }}
        {...props}
      />
      {!loaded && (
        <img
          key={key}
          alt=""
          style={{
            width,
            height,
            position: 'absolute',
            left: '12px',
            backgroundColor: '#ddd',
            objectFit: 'contain',
            padding: '10px',
            ...styles,
            ...style,
          }}
          {...props}
          src={fallbackIcon}
        />
      )}
    </>
  )
}

const styles = {}

TablePreviewImage.defaultProps = {
  alt: 'preview',
  contain: false,
}
export default TablePreviewImage
