if (window.location.href.indexOf('127.0.0.1') !== -1) {
  throw new Error('Use localhost instead of 127.0.0.1 for development!')
}
export const S3_ROOT_URL = process.env.REACT_APP_S3_BUCKET_URL
export const ExpressServerRoot = window.location.href.indexOf('localhost') !== -1 ? 'http://localhost:5000' : ''
export const Custom = 'Custom'
export const Recommendation = 'Recommendation'
export const Service = 'Service'
export const Timezones = [
  { value: 'America/Los_Angeles', label: 'Pacific Time / Los Angeles (UTC-8)' },
  { value: 'America/Denver', label: 'Mountain Time / Denver (UTC-7)' },
  { value: 'America/Phoenix', label: 'Mountain Time / Arizona (UTC-7)' },
  { value: 'America/Chicago', label: 'Central Time / Chicago (UTC-6)' },
  { value: 'America/New_York', label: 'Eastern Time / New York (UTC-5)' },
  { value: 'US/Hawaii', label: 'Standard Time / Hawaii (UTC-10)' },
  { value: 'America/Alaska', label: 'Standard Time / Alaska  (UTC-9)' },
  {
    value: 'America/Puerto Rico',
    label: 'Standard time / Puerto Rico (UTC-4)',
  },
]

export const TIME_OPTIONS = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
   