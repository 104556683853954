import React from 'react'
import classNames from 'classnames'

const DeviceAddressFormatter = ({
 addressLine1, addressCity, addressState, status,
}) => {
  const addressClass = classNames({ disabled: status === 'UNPAIRED' })

  return (
    <div className={classNames('title-cell', addressClass)}>
      <div className="second-line">
        {`${addressLine1}, ${addressCity}, ${addressState}.`}
      </div>
    </div>
  )
}

export default DeviceAddressFormatter
