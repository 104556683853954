import { get } from 'lodash'
import React from 'react'
import { PlayIcon, SoundMuteIcon, FullScreenVideoIcon } from '../icons'

import '../styles/MediaDisplay.scss'

const str_pad_left = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length)
}
const minutingDuration = time => {
  if (!time) return null
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60

  return `${str_pad_left(minutes, '0', 2)}:${str_pad_left(seconds, '0', 2)}`
}

const MediaDisplay = ({
 item, showDuration, className, parentClassName, sound, video, ...props
}) => {
  const addPreviewSrc = (media, event) => {
    if (media.preview) event.target.src = media.preview
  }
  const mediaUrl = get(item, 'mediaUrl')

  let content = null
  if (item && item.mediaType === 'VIDEO') {
    content = (
      <div className={`media-display-video ${parentClassName || ''}`}>
        <video onError={addPreviewSrc.bind(this, item)} className={className} src={mediaUrl} {...props} />
        {item.mediaDuration && showDuration ? (
          <div className="duration">
            <p>{minutingDuration(item.mediaDuration)}</p>
          </div>
        ) : (
          <div className="media-display-icon">
            <PlayIcon className="" />
          </div>
        )}
        {video && (
          <div className="video-fullscreen-icon">
            <FullScreenVideoIcon />
          </div>
        )}
        {sound && (
          <div className="media-sound-mute-icon">
            <SoundMuteIcon />
          </div>
        )}
      </div>
    )
  } else {
    content = (
      <img onError={addPreviewSrc.bind(this, item)} alt="Media" className={className} src={mediaUrl} {...props} />
    )
  }

  return content
}

export default MediaDisplay
