import React from 'react'
import * as Yup from 'yup'

import { SplitField } from '../../forms/fields'
import { FormModal, FormGroup, SubmitButton } from '../../forms'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import '../../styles/Link.scss'
import { MESSAGES } from '../../constants/strings'

function PasscodeContainer({ setFieldValue, handleSubmit }) {
  const verb = { create: 'Change' }

  const randomisePasscode = () => {
    const passcode = Math.floor(100000 + Math.random() * 900000)
    setFieldValue('passcode', passcode)
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <ModalHeader name="Passcode" verb={verb} subtitle="Enter your new passcode" />
      <ModalBody>
        <FormGroup required label="New passcode " htmlFor="passcode">
          <SplitField length={6} name="passcode" />
        </FormGroup>
        <div className="row">
          <div className="col-sm-3" />
          <div className="col-sm-9">
            <div className="link bold" onClick={randomisePasscode}>
              {MESSAGES.GENERATE_RANDOM_PASSCODE}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <SubmitButton>{MESSAGES.CHANGE}</SubmitButton>
      </ModalFooter>
    </form>
  )
}

PasscodeContainer.defaultProps = {
  setFieldValue: () => { },
  handleSubmit: () => { },
}

const validationSchema = Yup.object().shape({
  passcode: Yup.string()
    .ensure()
    .required()
    .length(6)
    .label('Passcode'),
})

export default props => {
  const {
    organization: { id, passcode },
    onSave,
    dispatch,
  } = props
  const initialValues = {
    passcode,
  }

  const render = p => <PasscodeContainer {...p} {...props} />

  const formatValues = v => {
    return {
      id,
      ...v,
    }
  }

  return (
    <FormModal
      formatValues={formatValues}
      validationSchema={validationSchema}
      initialValues={initialValues}
      render={render}
      onSave={onSave}
      dispatch={dispatch}
    />
  )
}
