import React from 'react'
import Icon from './Icon'

function ClockIcon2(props) {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      <path
        d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM14 14C13.9075 14.0927 13.7976 14.1663 13.6766 14.2164C13.5557 14.2666 13.426 14.2924 13.295 14.2924C13.164 14.2924 13.0343 14.2666 12.9134 14.2164C12.7924 14.1663 12.6825 14.0927 12.59 14L9.3 10.71C9.20551 10.6174 9.13034 10.5069 9.07885 10.3851C9.02735 10.2632 9.00055 10.1323 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9.59L14 12.59C14.39 12.98 14.39 13.61 14 14Z"
        fill="#3E92CC"
      />
      {/* <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" /> */}
    </Icon>
  )
}

export default ClockIcon2
