import React, { useEffect, useState, useCallback } from 'react'
import { has } from 'lodash'
import classNames from 'classnames'
import { SearchIcon } from '../../icons'
import '../../styles/Search.scss'

function GlobalFilter({
 globalFilter, setGlobalFilter, theme, data,
}) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useCallback(value => {
    setGlobalFilter(value || undefined)
  })
  useEffect(() => {
    onChange(value)
  }, [data, value])

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  return (
    <div className={classNames('Search', theme)}>
      <div className="search-icon">
        <SearchIcon size={18} />
      </div>
      <input
        className="search-input"
        placeholder="Search..."
        onKeyDown={e => handleKeyDown(e)}
        value={value || ''}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

const ListSearchAndFilter = ({
 preGlobalFilteredRows, globalFilter, setGlobalFilter, headerGroup, theme, data,
}) => {
  return (
    <div className="ListSearchAndFilter wrapper-right">
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        data={data}
        theme={theme}
      />
      {headerGroup.headers.map(column => has(column, 'Filter') && column.render('Filter'))}
    </div>
  )
}

export default ListSearchAndFilter

ListSearchAndFilter.defaultProps = {
  theme: 'light',
}
