import React from 'react'
import { Tabs } from '../../components'
import { ContentTable } from '.'
import { AMENITIES, SUPPLIES, POLICIES } from '../../constants/forms/propertyInfo'
import { PROPERTY_INFO_COLUMNS, PROPERTY_INFO_SELECT_COLUMNS } from '../../constants/tables/propertyInfo'
import { AmenitiesIcon, SuppliesIcon, PolicyIcon } from '../../icons'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const Amenities = ({ property, recordAnalytics }) => (
  <ContentTable
    content={AMENITIES}
    columns={PROPERTY_INFO_COLUMNS}
    selectExistingColums={PROPERTY_INFO_SELECT_COLUMNS}
    propertyId={property.id}
    emptyState={{ icon: AmenitiesIcon, text: AMENITIES.contentEmptyMessage }}
    propertyOnly
    recordAnalytics={recordAnalytics}
  />
)

const Supplies = ({ property, recordAnalytics }) => (
  <ContentTable
    content={SUPPLIES}
    columns={PROPERTY_INFO_COLUMNS}
    selectExistingColums={PROPERTY_INFO_SELECT_COLUMNS}
    propertyId={property.id}
    emptyState={{ icon: SuppliesIcon, text: SUPPLIES.contentEmptyMessage }}
    propertyOnly
    recordAnalytics={recordAnalytics}
  />
)

const Policies = ({ property, recordAnalytics }) => (
  <ContentTable
    content={POLICIES}
    columns={PROPERTY_INFO_COLUMNS}
    selectExistingColums={PROPERTY_INFO_SELECT_COLUMNS}
    propertyId={property.id}
    emptyState={{ icon: PolicyIcon, text: POLICIES.contentEmptyMessage }}
    propertyOnly
    recordAnalytics={recordAnalytics}
  />
)

const AMENITIES_AND_SUPPLIES_TABS = [
  {
    slug: '',
    component: Amenities,
    label: 'Amenities',
    icon: AmenitiesIcon,
  },
  {
    slug: 'supplies',
    component: Supplies,
    label: 'Supplies',
    icon: SuppliesIcon,
  },
  {
    slug: 'policies',
    component: Policies,
    label: 'Policies',
    icon: PolicyIcon,
  },
]

const AmenitiesAndSupplies = ({ property, recordAnalytics }) => {
  const handleAnalytics = (subSection, action) => {
    recordAnalytics && recordAnalytics({ section: SECTIONS.PROPERTY_INFO, subSection, action })
  }

  return (
    <div className="AmenitiesAndSupplies">
      <Tabs
        navTitle="Property Info"
        path={`properties/${property.id}/property-info`}
        tabs={AMENITIES_AND_SUPPLIES_TABS}
        property={property}
        recordAnalytics={handleAnalytics}
        isSubTabs
        navText="Create information on how to use amenities and where to find supplies in this property. Amenities and Supplies that are common across multiple properties should be added at the multi-property content level in the Content menu."
      />
    </div>
  )
}

export default AmenitiesAndSupplies
