/* eslint-disable react/display-name */
import React, { Fragment, Component, createRef } from 'react'
import { Input } from 'reactstrap'
import {
  sortBy, difference, get, mapValues, isArray, isObject, isEmpty,
} from 'lodash'
import { Offline, Online } from 'react-detect-offline'
import { withRouter } from 'react-router-dom'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { FormGroup } from '../../forms'
import { apiRequest } from '../../reducers/data'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'
import { checkProgress, dismissTask } from '../../resources/tasks'

import api from '../../api'

import Choices from '../../components/Choices'

import escapiaLogoURL from '../../images/logo-escapia.png'
import lodgixLogoURL from '../../images/logo-lodgix.png'
import streamlineLogoURL from '../../images/logo-streamline.jpg'
import trackLogoURL from '../../images/logo-track.png'
import lmpmLogoURL from '../../images/logo-lmpm.png'
import liveRezLogoURL from '../../images/logo-liverez.jpg'
import guestyLogoURL from '../../images/logo-guesty.png'
import ownerRezLogoURL from '../../images/logo-ownerRez.png'
import barefootLogoURL from '../../images/logo-barefoot.png'
import { ChevronRightIcon, HomeIcon } from '../../icons'
import CreateNewProperty from '../CreateNewProperty'
import openOAuthPopup from '../../services/oauth'

/*
This is a React component that renders the "Connect a service" UI prompting the user to link
an external reservation service and then fetches the properties that are available and
passes them to a function you provide, rendering the results. (Sort of like <Resource>)

So if you say:
  <WithServiceProperties me={props.me}>
  {(properties) =>
    <div>bla</div>
  }
  </WithServiceProperties>

The component will first render the "Connect a Service" screen with the Lodgix form, error handling,
etc., and then render <div>bla</div> once it has fetched the Lodigx properties.
*/

const SERVICES = {
  airbnb: {
    name: 'AirBnb',
    key: 'airbnb',
    props: {
      'data-cy': 'properties-import-airbnb',
    },
    options: {
      syncReservations: false,
      syncProperties: false,
      hasSearch: false,
    },
    required: {
      calendarLink: {
        modelField: 'calendarLink',
        label: 'Enter your AirBnB Calendar Link',
        placeholder: 'e.g. http://airbnb.com/scalendarlink.ics',
      },
    },
  },
  streamline: {
    name: 'Streamline',
    key: 'streamline',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: streamlineLogoURL,
    props: {
      'data-cy': 'properties-import-streamline',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: false,
    },
    required: {
      tokenKey: {
        modelField: 'key',
        label: 'Enter your Streamline Key',
        placeholder: 'e.g. 12345678901234567890123456789012',
        position: 0,
      },
      tokenSecret: {
        modelField: 'secret',
        label: 'Enter your Streamline Secret',
        placeholder: 'e.g. 1234567890123456789012345678901234567890',
        position: 1,
      },
    },
  },
  liveRez: {
    name: 'LiveRez',
    key: 'liveRez',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: liveRezLogoURL,
    props: {
      'data-cy': 'properties-import-liverez',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: true,
    },
    required: {
      tokenPartnerKey: {
        modelField: 'tokenPartnerKey',
        label: 'Enter your LiveRez Partner key',
        placeholder: 'e.g. 123456789012',
        position: 0,
      },
    },
  },
  guesty: {
    name: 'Guesty',
    key: 'guesty',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: guestyLogoURL,
    props: {
      'data-cy': 'properties-import-guesty',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: false,
    },
    required: {
      integrationToken: {
        modelField: 'integrationToken',
        label: 'Enter your Guesty Integration Token',
        placeholder: 'e.g. 12345',
        position: 0,
      },
    },
  },
  track: {
    name: 'Track',
    key: 'track',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: trackLogoURL,
    props: {
      'data-cy': 'properties-import-track',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: true,
    },
    required: {
      tokenKey: {
        modelField: 'key',
        label: 'Enter your Track Key',
        placeholder: 'e.g. 12345678901234567890123456789012',
        position: 0,
      },
      tokenSecret: {
        modelField: 'secret',
        label: 'Enter your Track Secret',
        placeholder: 'e.g. 1234567890123456789012345678901234567890',
        position: 1,
      },
      url: {
        modelField: 'url',
        label: 'Enter your Track Domain',
        placeholder: 'your track domain',
        position: 2,
        className: 'track-url',
      },
    },
  },
  lmpm: {
    name: 'LMPM',
    key: 'lmpm',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: lmpmLogoURL,
    props: {
      'data-cy': 'properties-import-track',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: true,
    },
    required: {
      companyId: {
        modelField: 'companyId',
        label: 'Enter your Company Id',
        placeholder: 'e.g. 12345678901234567890123456789012',
        position: 0,
      },
    },
  },
  escapia: {
    name: 'Escapia',
    key: 'escapia',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: escapiaLogoURL,
    props: {
      'data-cy': 'properties-import-escapia',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: true,
    },
    required: {
      pmcid: {
        modelField: 'id',
        label: 'Enter your Escapia PMCID',
        placeholder: 'e.g. 12345',
      },
    },
    info: props => (
      <div className="white-content-box import-properties-info-container">
        <h6>Before syncing your HomeAway reservations:</h6>
        <p>
          You must request HomeAway to add GuestView Guide as a new partner then configure your HomeAway permissions for
          integrating with GuestView Guide.
        </p>
        <a
          className="instructions-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://guestviewguide.com/wp-content/uploads/2019/11/Escapia-Integration-Instructions-for-Customers.pdf?x64788"
        >
          Click here for detailed instructions
          {' '}
          <ChevronRightIcon />
        </a>
      </div>
    ),
  },
  ownerRez: {
    name: 'OwnerRez',
    key: 'ownerRez',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: ownerRezLogoURL,
    requiresOAuth: true,
    authConfig: {
      url: `https://secure.ownerreservations.com/oauth/authorize?responseType=code&client_id=${process.env.REACT_APP_OWNERREZ_CLIENT_ID}`,
    },
    authMessageHandler: message => {
      try {
        const type = message && message.data && message.data.type
        if (!type) {
          return { code: null, error: null }
        }
        if (type === 'OAUTH_RESPONSE') {
          return { code: message.data.code, error: null }
        }
        if (type === 'OAUTH_ERROR') {
          return { code: null, error: message.data.error }
        }
      }
      catch {
        return { code: null, error: 'Error while retrieving code' }
      }
    },
    props: {
      'data-cy': 'properties-import-ownerRez',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: false,
    },
    required: {},
    credentials: {
      client_id: process.env.REACT_APP_OWNERREZ_CLIENT_ID,
      client_secret: process.env.REACT_APP_OWNERREZ_CLIENT_SECRET,
    },
  },
  barefoot: {
    name: 'Barefoot',
    key: 'barefoot',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: barefootLogoURL,
    props: {
      'data-cy': 'properties-import-barefoot',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: false,
    },
    required: {
      barefootAccount: {
        modelField: 'barefootAccount',
        label: 'Enter your Barefoot Account ID',
        placeholder: 'e.g. v*********_*******1',
        position: 0,
      },
    },
  },
  lodgix: {
    name: 'Lodgix',
    key: 'lodgix',
    templateDescription: 'Sync property information',
    additionalInformation: 'and reservations',
    iconURL: lodgixLogoURL,
    props: {
      'data-cy': 'properties-import-lodgix',
    },
    options: {
      syncReservations: true,
      syncProperties: true,
      hasSearch: false,
    },
    required: {
      lodgixID: {
        modelField: 'id',
        label: 'Enter your Lodgix ID',
        placeholder: 'e.g. 12345',
        position: 0,
      },
      lodgixAPIKey: {
        modelField: 'apiKey',
        label: 'Enter your Lodgix API Key',
        placeholder: 'e.g. 12345678901234567890123456789012',
        position: 1,
      },
    },
  },
}

const createNewProperty = {
  templateName: 'Create New',
  templateDescription: 'Add a property manually and then add reservation info or sync via iCal',
  icon: <HomeIcon />,
  setChoice: (onCancel, history) => {
    onCancel()
    history.push('/properties/create')
  },
}

class WithExternalPropertiesWrapper extends Component {
  constructor(props) {
    super(props)
    const { me } = this.props
    const serviceKey = get(me, ['organization', 'reservationService'])
    this.popupRef = createRef();
    this.popupIntervalRef
    let data = {}
    if (serviceKey) {
      const reservationServiceConfig = get(me, ['organization', 'reservationServiceConfig'], {})
      const { required } = SERVICES[serviceKey]
      data = mapValues(required, ({ modelField }, key) => reservationServiceConfig[modelField])
    }

    this.state = {
      externalProperties: undefined,
      totalProperties: undefined,
      error: null,
      serviceKey,
      data,
      hiddenPms: '',
      pmsLoading: true,
    }
  }


  componentDidMount() {
    const { serviceKey } = this.state
    const { isImporting } = this.props

    if (serviceKey && !isImporting) {
      this.fetchProperties()
    }
    this.getHiddenPms()
  }

  componentDidUpdate = async prevProps => {
    if (prevProps.isImporting !== false && this.props.isImporting === false) {
      const { serviceKey } = this.state
      const service = SERVICES[serviceKey]
      const path = service.path || serviceKey
      if (path === 'escapia') {
        await this.fetchImportedProperties()
      } else {
        this.fetchExternalProperties({})
      }
    }
  }

  componentWillUnmount = () => {
    this.popupRef?.current?.close()
    clearInterval(this.popupIntervalRef)
    window.removeEventListener('message', this.handleOAuthMessageListener)
  }

  handleIsReservationServiceCorrectForOwnerRez = () => {
    return !!(
      this.props.me
      && this.props.me.organization
      && this.props.me.organization.reservationService === 'ownerRez'
      && this.props.me.organization.reservationServiceConfig.access_token
    )
  };

  handleOAuthMessageListener = async message => {
    const { serviceKey } = this.state
    const { code, error } = SERVICES[serviceKey].authMessageHandler(message)
    const { credentials } = SERVICES[serviceKey];
    if (error) {
      this.setState({ isLoading: false, serviceKey: null })
      clearInterval(this.popupIntervalRef)
      if (!this.handleIsReservationServiceCorrectForOwnerRez()) this.props.onCancel();
    } else if (code) {
      let updatedData = { ...this.state.data, code }
      if (credentials && isObject(credentials)) {
        updatedData = { ...updatedData, ...credentials };
      }
      this.setState({ data: updatedData })
      clearInterval(this.popupIntervalRef)
      this.fetchExternalProperties()
    }
  }

  handleOAuthForceClosed = () => {
    const popupClosed = !this.popupRef?.current || !this.popupRef?.current.window || this.popupRef?.current?.window?.closed;
    if (popupClosed) {
      console.warn('Authorization popup was closed before completing authentication.')
      clearInterval(this.popupIntervalRef)
      window.removeEventListener('message', this.handleOAuthMessageListener)
      this.setState({ isLoading: false, serviceKey: null })
      if (!this.handleIsReservationServiceCorrectForOwnerRez()) this.props.onCancel();
    }
  }

  retrieveOAuthToken = serviceKey => {
    const { url } = SERVICES[serviceKey].authConfig
    this.popupRef.current = openOAuthPopup(url)
    this.popupIntervalRef = setInterval(() => {
      this.handleOAuthForceClosed()
    }, 250)
    window.addEventListener('message', this.handleOAuthMessageListener)
  }

  fetchProperties = async (args = {}) => {
    const hasReservationServiceConfig = !isEmpty(get(this.props.me, [
      'organization',
      'reservationServiceConfig',
    ]))
    const hasImportedProperties = get(this.props.me, [
      'organization',
      'reservationServiceConfig',
      'hasImportedProperties',
    ])

    const isReservationServiceCorrectForOwnerRez = this.handleIsReservationServiceCorrectForOwnerRez();
    this.setState({ isLoading: true })
    const { serviceKey } = this.state
    const service = SERVICES[serviceKey]
    if (hasImportedProperties && !args.resync) {
      const path = service.path || serviceKey
      if (path === 'escapia') {
        await this.fetchImportedProperties()
      } else {
        this.fetchExternalProperties(args)
      }
    } else {
      if (service.requiresOAuth && (!hasReservationServiceConfig || !isReservationServiceCorrectForOwnerRez)) {
        this.retrieveOAuthToken(serviceKey)
      } else {
        this.fetchExternalProperties(args)
      }
    }
  }

  fetchImportedProperties = async () => {
    this.props.dispatch(dismissTask('escapia/progress'))
    const response = await api.get('escapia/properties')
    if (response.status === 200) {
      const externalProperties = response.data.map(p => p.data)
      this.setState({
        externalProperties,
        totalProperties: externalProperties.length,
        isLoading: false,
      })
    } else {
      // this.setState({ error: response.error })
    }
  }

  fetchExternalProperties = async args => {
    const { onRefreshMe } = this.props
    const { serviceKey, data } = this.state

    const service = SERVICES[serviceKey]
    let path = service.path || serviceKey

    if (path !== 'escapia' && path !== 'lodgix') {
      path = [`pms/properties?pms=${path}`]
    } else {
      path = [path]
    }

    this.props.dispatch(dismissTask('pms/progress'))
    const { json } = await this.props.dispatch(apiRequest(path, 'POST', { ...data, ...args }))
    this.setState({
      externalProperties: json.externalProperties,
      totalProperties: json.total,
      isLoading: false,
      error: json.error ? json.error : null,
    })
    await onRefreshMe()
    if (json.checkProgress) {
      this.checkProgress()
    }
  }

  checkProgress = () => {
    const { serviceKey } = this.state
    const { to, dispatch, componentType } = this.props
    serviceKey === 'escapia'
      ? dispatch(
        checkProgress(`${serviceKey}/progress`, {
          to,
          modal: componentType === 'Properties' ? 'ImportPropertiesContainer' : 'ImportReservationsContainer',
        }),
      )
      : dispatch(
        checkProgress('pms/progress', {
          to,
          modal: componentType === 'Properties' ? 'ImportPropertiesContainer' : 'ImportReservationsContainer',
        }),
      )
  }

  onSubmit = evt => {
    evt.preventDefault()
    this.fetchProperties()
  }

  onChange = attribute => evt => {
    const data = {
      ...this.state.data,
      [attribute]: evt.target.value.trim(),
    }
    if (evt.target.value.trim().length === 0) {
      delete data[attribute]
    }
    this.setState({ error: null, data })
  }

  setIntegration = serviceKey => {
    this.setState({ serviceKey })
  }

  getHiddenPms = async () => {
    const { json } = await this.props.dispatch(apiRequest(['get-configs'], 'GET'))
    this.setState({ hiddenPms: json.hiddenPms, pmsLoading: false })
  }

  renderStepLinkService() {
    const { serviceKey, error, data } = this.state
    const { componentType, title, onCancel } = this.props
    const service = SERVICES[serviceKey]

    let disabled = true
    let inputs = null
    if (serviceKey) {
      if (service.required) {
        disabled = difference(Object.keys(service.required), Object.keys(this.state.data)).length > 0
        inputs = sortBy(Object.values(mapValues(service.required, (value, key) => ({ ...value, key }))), 'position').map(
          ({
            key, placeholder, label, className,
          }) => {
            return (
              <FormGroup
                vertical
                label={label}
                key={key}
                className={className == 'track-url' ? { display: 'flex' } : {}}
                size="large"
                required
              >
                <div className="d-flex justify-content-start align-items-baseline">
                  <strong>{className == 'track-url' ? 'https:// ' : ''}</strong>
                  <Input
                    type="text"
                    name={key}
                    autoFocus
                    value={data[key]}
                    placeholder={placeholder}
                    onChange={this.onChange(key)}
                    style={className == 'track-url' ? { width: '50%', display: 'flex' } : {}}
                  />
                  <strong>
                    {className == 'track-url' ? ' .trackhs.com/api/' : ''}
                    {' '}
                  </strong>
                </div>
              </FormGroup>
            )
          },
        )
      }
      if (service.info) {
        inputs.unshift(<service.info />)
      }
    }

    const serviceChoices = Object.values(SERVICES)
      .filter(({ options: { syncReservations, syncProperties } }) => {
        if (componentType === 'Reservations') {
          return syncReservations
        }
        if (componentType === 'Properties') {
          return syncProperties
        }
        return false
      })
      .filter(service => !(this.state.hiddenPms != null && this.state.hiddenPms.includes(service.key))) // if hidden file contain the name of pms then hide
      .map(service => ({
        props: service.props,
        iconURL: service.iconURL,
        icon: service.icon,
        templateKey: service.key,
        templateName: service.templateName,
        templateDescription: service.templateDescription,
        additionalInformation: service.additionalInformation,
      }))

    if (isArray(inputs) && !inputs.length) {
      this.fetchProperties();
      return <></>;
    }

    if (!inputs) {
      return (
        <Fragment>
          <ModalHeader closeModal={onCancel}>{title}</ModalHeader>
          <ModalBody>
            {componentType === 'Properties' && (
              <CreateNewProperty
                choices={createNewProperty}
                onCancel={this.props.onCancel}
                history={this.props.history}
              />
            )}
            <div className="property-choices">
              <h5>Import your selected properties from:</h5>
              {/* <div>Import your selected properties from:</div> */}
              <div>Sync property and reservation information from the following property management systems</div>
              <Choices choices={serviceChoices} setChoice={this.setIntegration} setKey="templateKey" />
            </div>
          </ModalBody>
        </Fragment>
      )
    }
    return (
      <form onSubmit={this.onSubmit}>
        <ModalHeader closeModal={onCancel}>{title}</ModalHeader>
        <ModalBody>
          {inputs}
          {error && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {error}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" disabled={disabled} type="submit">
            Continue
          </button>
        </ModalFooter>
      </form>
    )
  }

  render() {
    const {
      externalProperties, serviceKey, totalProperties, isLoading, error,
    } = this.state
    const { title, isImporting } = this.props
    const reservationServiceKey = get(this.props.me, ['organization', 'reservationService'])
    if (isImporting) {
      return this.props.children({ service: SERVICES[serviceKey] })
    }
    if (isLoading) {
      const service = SERVICES[serviceKey]
      // TODO could this ModalHeader, ModalBody be abstracted into one place?
      return (
        <Fragment>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <Online>
              {' '}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loading
                  style={{
                    margin: 0,
                    marginRight: '18px',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                />
                <div>
                  Retrieving
                  {' '}
                  {service.name}
                  {' '}
                  Properties. This may take some time.
                </div>
              </div>
            </Online>
            <Offline>
              <div style={{ color: 'red' }}>
                Unable to fetch properties due to internet connectivity. Please try again.
              </div>
            </Offline>
          </ModalBody>
        </Fragment>
      )
    }

    if (!externalProperties && !reservationServiceKey) {
      return this.state.pmsLoading ? <Loading /> : this.renderStepLinkService()
    }

    if (error) {
      return (
        <Fragment>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <div className="text-danger">{error}</div>
          </ModalBody>
        </Fragment>
      )
    }

    return this.props.children({
      externalProperties,
      fetchProperties: this.fetchProperties,
      service: SERVICES[serviceKey],
      totalProperties,
      isLoading,
    })
  }
}

const WithExternalProperties = props => (
  <Resource path={['me']} placeholder={{}}>
    {(me, { dispatch, onRefresh }) => (
      <WithExternalPropertiesWrapper me={me} onRefreshMe={onRefresh} dispatch={dispatch} {...props} />
    )}
  </Resource>
)

export default withRouter(WithExternalProperties)