import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { get, isArray, isObject } from 'lodash'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { generateWizard } from '../../forms/utils'
import { CONTENT_FIELDS_PATH, COMMONS_FIELDS, ALLOWED_MULTIPLE, DEFAULT_WIFI } from '../../constants/forms/content'
import { Wizard } from '../../components/wizard'
import '../../styles/ContentContainerModal.scss'

const ContentContainer = ({ content, data, dispatch, onSave, isCustom, propertyId, fieldsConfig }) => {
  const titleComponent = props => (
    <ModalHeader
      name={data.name || data.title || content.title}
      verb={content.verb}
      hasItem={data.id}
      isDuplicate={data.isDuplicate}
      {...props}
    />
  )

  const formatValues = value => {
    if (content.item === 'Welcome Video' && content.type === 'videos' && isObject(value.url)) {
      const data = {
        ...value,
        size: value.url[0].mediaDuration,
        imageURL: `${value.url[0].mediaUrl}.0000000.jpg`,
        url: value.url[0].mediaUrl,
      }
      return data
    }
    return value
  }

  let WIZARD = {}
  if (content.fields) {
    const contentFields = content.fields({ saveOnExit: false, dispatch }).map(field => ({
      ...field,
      name: `${CONTENT_FIELDS_PATH}.${field.name}`,
    }))

    // Hide name field in case of check in/check out survey
    const fields =
      content.type === 'checkinSurvey' || content.type === 'checkoutSurvey'
        ? [...contentFields]
        : [COMMONS_FIELDS.name, ...contentFields]

    if (content.type === 'wifi') {
      fields.push(DEFAULT_WIFI.isDefault())
    }

    if (!isCustom) {
      fields.push(
        COMMONS_FIELDS.propertyIds({
          isDefault: data.isDefault,
          isAllowedMultiple: ALLOWED_MULTIPLE.includes(content.type),
        })
      )
    }
    WIZARD = {
      0: [...fields],
    }
  } else {
    const readOnly = get(fieldsConfig, 'readOnly', false) || get(data, 'content.provider') === 'yelp'
    const steps = content.steps({
      saveOnExit: false,
      readOnly,
      isEdit: data && data.id,
      content,
      data,
    })
    const contentSteps = steps.map(step => {
      if (step.component) {
        return {
          component: step.component,
          className: step.className,
        }
      }
      const commonFieldNames = Object.keys(COMMONS_FIELDS)
      const fields = step.fields.map(field => {
        const name =
          commonFieldNames.includes(field.name) || content.isNotCustomization
            ? field.name
            : `${CONTENT_FIELDS_PATH}.${field.name}`
        return { ...field, name }
      })

      return {
        fields: [...fields],
        className: step.className,
      }
    })

    WIZARD = {
      ...contentSteps,
    }
  }

  const contentData = generateWizard({
    form: WIZARD,
    props: data,
    newValidation: true,
  })

  let formData = contentData
  if (content.type === 'videos' && data.url && !isArray(data.url)) {
    formData = {
      ...contentData,
      initialValues: {
        ...contentData.initialValues,
        url: [
          {
            mediaUrl: data.url,
            mediaDuration: data.duration,
            id: data.id,
            mediaType: 'VIDEO',
          },
        ],
      },
    }
  }

  if (content.type === 'rules') {
    formData = {
      ...contentData,
      initialValues: {
        ...contentData.initialValues,
        content: {
          ...contentData.initialValues.content,
          includeInCheckIn: contentData.initialValues?.content?.includeInCheckIn || false,
        },
      },
    }
  }

  if (content.type === 'checkinSurvey') {
    formData = {
      ...contentData,
      initialValues: {
        ...contentData.initialValues,
        content: {
          ...contentData.initialValues.content,
          removeFromCheckIn: true,
        },
      },
    }
  }

  if (content.type === 'checkoutSurvey') {
    formData = {
      ...contentData,
      initialValues: {
        ...contentData.initialValues,
        content: {
          ...contentData.initialValues.content,
          removeFromCheckOut: true,
        },
      },
    }
  }

  return (
    <div className={classNames('ContentContainerModal', content.parent)}>
      <Wizard
        titleComponent={titleComponent}
        bodyWrapper={ModalBody}
        formData={formData}
        footerWrapper={ModalFooter}
        formatValues={formatValues}
        onSave={onSave}
        dispatch={dispatch}
        propertyId={propertyId}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    fieldsConfig: state.modals.props.fieldsConfig,
  }
}

export default connect(mapStateToProps)(ContentContainer)

ContentContainer.defaultProps = {
  isCustomm: false,
}
