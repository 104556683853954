/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Resource from '../Resource'
import { Loading } from '../components/PageStates'
import { Tabs, TabHeader } from '../components'
import { ServiceContainerHeader, ServiceTable, ServiceRequestTable } from '../components/services'
import '../styles/ServicesContainer.scss'
import { PROPERTY_TYPES } from '../constants/forms/propertyInfo'
import { apiRequest } from '../reducers/data'
import { thirdPartyAnalytics } from '../services'
import { ACTIONS, EVENTS } from '../constants/thirdPartyAnalytics'
import { MESSAGES } from '../constants/strings'

const SERVICE_TABS = newlyRequestedServicesCount => [
  {
    slug: '',
    component: ServiceTable,
    label: MESSAGES.SERVICES,
  },
  {
    slug: 'requests',
    component: ServiceRequestTable,
    label: MESSAGES.SERVICE_REQUESTS,
    count: String(newlyRequestedServicesCount),
  },
]

const ServicesContainerWithData = props => {
  const {
    properties,
    services,
    onCreateItem,
    onDeleteItem,
    dispatch,
    onUpdateItem,
    NestedResource,
    paymentProvider,
    me,
    analyticsProperties,
    recordAnalytics,
  } = props

  const getServiceRequestCount = async path => {
    if (path === '/services/requests') {
      const response = await dispatch(apiRequest(['requests/newlyRequestedCount'], 'GET'))
      if (response && response.json) {
        const newlyRequestedCount = response.json
        setNewlyRequestedServicesCount(newlyRequestedCount ? newlyRequestedCount.count : 0)
      }
    }
  }

  const [newlyRequestedServicesCount, setNewlyRequestedServicesCount] = useState(0)

  useEffect(() => {
    if (props.newlyRequestedServicesCount !== newlyRequestedServicesCount) {
      setNewlyRequestedServicesCount(props.newlyRequestedServicesCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newlyRequestedServicesCount])

  return (
    <div>
      <div className="ServicesContainer">
        <TabHeader title={MESSAGES.SERVICES} count={services.length} className="with-notes" />
        <ServiceContainerHeader
          analyticsProperties={analyticsProperties}
          dispatch={dispatch}
          onCreateItem={onCreateItem}
          paymentProvider={paymentProvider}
          me={me}
        />
        <Tabs
          path="services"
          tabs={SERVICE_TABS(newlyRequestedServicesCount)}
          NestedResource={NestedResource}
          properties={properties}
          services={services}
          paymentProvider={paymentProvider}
          onDeleteItem={onDeleteItem}
          onUpdateItem={onUpdateItem}
          dispatch={dispatch}
          onNavClick={getServiceRequestCount}
          recordAnalytics={recordAnalytics}
          activeTabView="list"
        />
      </div>
    </div>
  )
}

const ServicesContainer = props => {
  const { match, me } = props

  const { me: userInfo } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  const recordAnalytics = (action, name) => {
    const identifier = Number(name) === name ? { id: name } : { name }
    const updatedProperties = { ...analyticsProperties, action, ...identifier }
    thirdPartyAnalytics.track(EVENTS.SERVICE, updatedProperties)
  }

  return (
    <Resource path={['integrations']} collection placeholderComponent={Loading}>
      {integrations => (
        <Resource collection path={['services']} placeholderComponent={Loading}>
          {(services,
            {
              onUpdateItem, onCreateItem, onDeleteItem, dispatch, NestedResource,
            }) => {
            const onAction = (action, func, extra) => {
              action !== ACTIONS.DELETE && recordAnalytics(action, extra.name)
              return func(extra)
            }

            const onUpdate = (extra, toggle) => {
              if (toggle) {
                recordAnalytics(extra.enabled ? ACTIONS.ENABLE : ACTIONS.DISABLE, extra.name)
              } else {
                recordAnalytics(ACTIONS.UPDATE, extra.name)
              }
              return onUpdateItem(extra)
            }

            return (
              <Resource
                collection
                path={['properties', `?type=${PROPERTY_TYPES.current}`]}
                placeholderComponent={Loading}
              >
                {properties => (
                  <Resource path={['requests/newlyRequestedCount']}>
                    {newlyRequestedCount => (
                      <ServicesContainerWithData
                        paymentProvider={integrations.find(({ type }) => type === 'STRIPE') || {}}
                        properties={properties}
                        services={services}
                        dispatch={dispatch}
                        NestedResource={NestedResource}
                        newlyRequestedServicesCount={newlyRequestedCount ? newlyRequestedCount.count : 0}
                        analyticsProperties={analyticsProperties}
                        onCreateItem={onAction.bind(this, ACTIONS.CREATE, onCreateItem)}
                        onUpdateItem={onUpdate}
                        onDeleteItem={onAction.bind(this, ACTIONS.DELETE, onDeleteItem)}
                        recordAnalytics={recordAnalytics}
                        match={match}
                        me={me}
                      />
                    )}
                  </Resource>
                )}
              </Resource>
            )
          }}
        </Resource>
      )}
    </Resource>
  )
}

export default ServicesContainer
