import * as React from 'react'
import Icon from './Icon'

function ConnectIocn() {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.59998 9.19995C7.70607 9.19995 7.80781 9.24209 7.88282 9.31711C7.95784 9.39212 7.99998 9.49386 7.99998 9.59995V10.8H8.80398C8.91007 10.8 9.01181 10.8421 9.08682 10.9171C9.16184 10.9921 9.20398 11.0939 9.20398 11.2C9.20398 11.306 9.16184 11.4078 9.08682 11.4828C9.01181 11.5578 8.91007 11.6 8.80398 11.6H7.59998C7.4939 11.6 7.39215 11.5578 7.31714 11.4828C7.24212 11.4078 7.19998 11.306 7.19998 11.2V9.59995C7.19998 9.49386 7.24212 9.39212 7.31714 9.31711C7.39215 9.24209 7.4939 9.19995 7.59998 9.19995Z"
        fill="#52CFE3"
      />
      <path
        d="M14.2416 6.21599L14.3672 5.55199C14.4928 4.89439 14.2512 4.19359 13.7232 3.72799C12.4688 2.62079 10.6592 1.99999 7.99359 2.00319C5.33279 2.00639 3.28159 2.63039 2.14079 3.73759C1.90348 3.96971 1.73481 4.26282 1.65337 4.58464C1.57193 4.90646 1.58086 5.24452 1.67919 5.56159L1.87759 6.22559C1.97284 6.52942 2.16966 6.79127 2.43505 6.96722C2.70043 7.14318 3.01825 7.22253 3.33519 7.19199L4.63759 7.06159C5.19679 7.00559 5.44159 6.53599 5.59999 5.99999C5.83679 5.19679 5.99999 4.59999 5.99999 4.59999C6.60959 4.37199 7.18879 4.19999 7.99999 4.19999C8.81119 4.19999 9.40159 4.35519 9.99999 4.59999C9.99999 4.59999 10.1568 5.19599 10.4 5.99999C10.5792 6.59199 11.0024 7.00879 11.5736 7.06399L12.8832 7.19039C13.5416 7.25439 14.1232 6.83679 14.2416 6.21519V6.21599Z"
        fill="#52CFE3"
      />
      <path
        d="M4.39999 10.8C4.39999 10.3272 4.49311 9.85906 4.67403 9.42229C4.85494 8.98552 5.12012 8.58866 5.45441 8.25437C5.7887 7.92008 6.18556 7.6549 6.62233 7.47398C7.05911 7.29307 7.52724 7.19995 7.99999 7.19995C8.47275 7.19995 8.94088 7.29307 9.37765 7.47398C9.81443 7.6549 10.2113 7.92008 10.5456 8.25437C10.8799 8.58866 11.145 8.98552 11.326 9.42229C11.5069 9.85906 11.6 10.3272 11.6 10.8C11.6 11.7547 11.2207 12.6704 10.5456 13.3455C9.87045 14.0207 8.95477 14.4 7.99999 14.4C7.04521 14.4 6.12954 14.0207 5.45441 13.3455C4.77928 12.6704 4.39999 11.7547 4.39999 10.8V10.8ZM7.99999 7.99995C7.25739 7.99995 6.5452 8.29495 6.0201 8.82005C5.49499 9.34515 5.19999 10.0573 5.19999 10.8C5.19999 11.5426 5.49499 12.2547 6.0201 12.7798C6.5452 13.305 7.25739 13.6 7.99999 13.6C8.7426 13.6 9.45479 13.305 9.97989 12.7798C10.505 12.2547 10.8 11.5426 10.8 10.8C10.8 10.0573 10.505 9.34515 9.97989 8.82005C9.45479 8.29495 8.7426 7.99995 7.99999 7.99995Z"
        fill="#52CFE3"
      />
    </Icon>
  )
}

export default ConnectIocn
