/* eslint-disable react/display-name */
import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import { PROPERTIES_APPLIED, UPDATED_CELL, CREATED_CELL, SELECT_ROW, RECIPIENT_PROPERTY } from './common'
import { SelectColumnFilter, includesFilter } from './commonFilters'
import { CONTENT_IMAGE, CONTENT_IMAGE_SMALL, CONTENT_OPTIONS, PROPERTY_CONTENT_OPTIONS } from './content'
import yelpImageForRating from '../../components/YelpRating'
import { CONTENT_FIELDS_PATH } from '../forms/content'
import FilterPills from '../../components/list/FilterPills'

export const RECOMMENDATION_NAME = {
  Header: 'Name',
  accessor: ({ name }) => name.toLowerCase(),
  Cell: ({ row: { original: customization }, initialState: { onDeleteItem, properties } }) => {
    const isCustom = customization.propertyId && !customization.name
    const name = isCustom ? 'Custom' : customization.name
    const rating = get(customization, `${CONTENT_FIELDS_PATH}.providerData.rating`)
    const quote = get(customization, `${CONTENT_FIELDS_PATH}.hostQuote`)
    return (
      <>
        <div className={classNames('content-name bold', { link: !isCustom })}>
          {name} {customization.isDefault}
        </div>
        {rating && yelpImageForRating(rating)}
        <small style={{ marginTop: 4 }}>{quote}</small>
      </>
    )
  },
}

export const RECOMMENDATION_CATEGORY = {
  id: 'category',
  Header: 'Category',
  accessor: ({ content: { category } }) => category.toLowerCase(),
  Cell: ({ row: { original: customization } }) => {
    const category = get(customization, `${CONTENT_FIELDS_PATH}.category`)
    return category
  },
  Filter: FilterPills,
  filter: includesFilter,
  options: {
    hideOn: ['mobile'],
  },
}

export const RECOMMENDATION_SELECT_COLUMNS = [
  {
    id: 'recommendations',
    columns: [
      SELECT_ROW,
      CONTENT_IMAGE_SMALL,
      RECOMMENDATION_NAME,
      RECOMMENDATION_CATEGORY,
      PROPERTIES_APPLIED,
      {
        id: 'city',
        Header: 'City',
        accessor: ({ content: { location } }) => location.city,
        Filter: SelectColumnFilter,
        filter: 'includes',
        extraFilterOptions: initialState => initialState.totalCities,
        options: {
          hideOn: ['desktop', 'tablet', 'mobile'],
        },
      },
    ],
  },
]

export const ADVERTISEMENT_BUTTON = {
  id: 'button',
  Header: ' ',
  Cell: ({ row: { original }, initialState: { onAdvertise } }) => {
    return (
      <button onClick={() => onAdvertise(original)} className="button">
        Create
      </button>
    )
  },
  options: {
    classNames: 'middle',
  },
}

export const RECOMMENDATION_ADVERTISEMENT_SELECT_COLUMNS = [
  {
    id: 'recommendations',
    columns: [
      CONTENT_IMAGE_SMALL,
      RECOMMENDATION_NAME,
      RECOMMENDATION_CATEGORY,
      PROPERTIES_APPLIED,
      ADVERTISEMENT_BUTTON,
    ],
  },
]

export const RECOMMENDATION_COLUMNS = [
  {
    id: 'recommendations',
    columns: [
      CONTENT_IMAGE,
      RECOMMENDATION_NAME,
      RECOMMENDATION_CATEGORY,
      PROPERTIES_APPLIED,
      UPDATED_CELL,
      PROPERTY_CONTENT_OPTIONS,
    ],
  },
]

export const RECOMMENDATIONS_CONTENT_COLUMNS = [
  {
    id: 'content',
    columns: [
      CONTENT_IMAGE,
      {
        ...RECOMMENDATION_NAME,
        Header: 'Component Name',
      },
      RECOMMENDATION_CATEGORY,
      // PROPERTIES_APPLIED,
      RECIPIENT_PROPERTY,
      UPDATED_CELL,
      CREATED_CELL,
      CONTENT_OPTIONS,
    ],
  },
]
