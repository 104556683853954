import React from 'react'
import { Yup } from '../../forms'
import { COMMONS_FIELDS, CONTENT_FIELDS_PATH } from './content'
import {
  TextField,
  TextareaField,
  SelectCategoryField,
  ImageDropzoneField,
  AddressField,
  PhoneField,
  CheckboxField,
} from '../../forms/fields'
import RecommendationAddContainer from '../../containers/RecommendationAddContainer'
import { urlPattern } from '../regex'
import { SUB_SECTIONS } from '../thirdPartyAnalytics'
import recommendedBadge from '../../images/icon-recommendedbadge-sm.png'
import '../../styles/RecommendationName.scss'
import { MESSAGES } from '../strings'

export const RECOMMENDATIONS = {
  type: 'recommendationQuotes',
  title: 'Recommendations',
  description:
    'Add personalized restaurant, activity, and directory recommendations (hospitals, banks, gas stations, etc.) for your guests to discover during their stay. All recommendations are on the organizational level and can be applied to any nearby property.',
  addNew: '+ Add New Recommendation',
  verb: {
    create: 'Add',
    edit: 'Edit',
  },
  fields: ({ readOnly, saveOnExit }) => [
    {
      label: 'Restaurants Intro Message',
      name: 'restaurant',
      component: TextField,
      validation: Yup.string(),
      vertical: true,
      props: {
        maxLength: 250,
        saveOnExit,
        readOnly,
        type: 'textarea',
        rows: 6,
        'data-cy': 'input-restaurants-quote',
      },
    },
    {
      label: 'Activities Intro Message',
      name: 'activity',
      component: TextField,
      validation: Yup.string(),
      vertical: true,
      props: {
        maxLength: 250,
        saveOnExit,
        readOnly,
        type: 'textarea',
        rows: 6,
        'data-cy': 'input-activities-quote',
      },
    },
    {
      label: 'Directory Intro Message',
      name: 'directory',
      component: TextField,
      validation: Yup.string(),
      vertical: true,
      props: {
        maxLength: 250,
        saveOnExit,
        readOnly,
        type: 'textarea',
        rows: 6,
        'data-cy': 'input-directory-quote',
      },
    },
  ],
}

const RECOMMENDATION_STEPS = ({ readOnly, saveOnExit, isEdit, content, data }) => {
  const steps = [
    {
      className: 'recommendation-select',
      component: <RecommendationAddContainer content={content} />,
    },
    {
      className: 'recommendation-inputs',
      fields: [
        {
          name: 'imageURL',
          label: 'Business Image',
          sublabel: 'Click image to change',
          className: 'image',
          vertical: true,
          additionalFieldClass: 'sublabel-present',
          component: ImageDropzoneField,
          props: {
            // readOnly,
            desiredSize: { width: 800, height: 600 },
          },
        },
        {
          ...COMMONS_FIELDS.name,
          label: 'Business Name',
          className: 'name',
          vertical: true,
          props: {
            readOnly,
            placeholder: 'Business Name',
          },
        },
        {
          label: 'Business Address',
          name: 'location',
          className: 'address',
          vertical: true,
          component: AddressField,
          required: ({ values }) => values.content,
          validation: Yup.object()
            .shape({
              coordinates: Yup.object().shape({
                latitude: Yup.number().required('invalid address'),
                longitude: Yup.number().required(),
              }),
            })
            .required('required'),
          props: {
            readOnly,
            placeholder: 'Business Address',
          },
        },
        {
          label: 'Business Phone',
          name: 'phone',
          className: 'phone',
          vertical: true,
          component: PhoneField,
          props: {
            readOnly,
            placeholder: 'Business Phone',
          },
        },
        {
          label: 'Business URL',
          name: 'url',
          className: 'url',
          vertical: true,
          validation: Yup.string().matches(urlPattern, { message: 'Enter a valid url', excludeEmptyString: true }),
          component: TextField,
          props: {
            placeholder: 'Business URL',
          },
        },
        {
          label: 'Sub-Category',
          name: 'category',
          vertical: true,
          className: 'category',
          component: SelectCategoryField,
          validation: Yup.string().required('required'),
          props: {
            initalData: data,
            content: {
              type: 'recommendationCategories',
            },
            categoryType: content.type,
            fast: false,
            fieldName: 'category',
            fieldsPath: CONTENT_FIELDS_PATH,
          },
        },
        {
          label: 'Your Recommendation',
          name: 'hostQuote',
          vertical: true,
          className: 'quote',
          component: TextareaField,
          validation: Yup.string(),
          props: {
            placeholder: 'Why would you recommend this place?',
            maxLength: 600,
            saveOnExit,
            type: 'textarea',
            rows: 4,
          },
        },
        {
          label: (
            <div className='RecommendationName'>
              {MESSAGES.DISPLAY_RECOMMENDATION_BADGE}
              <div className='recommended-badge-container'>
                <img alt="badge" src={recommendedBadge} className="recommended-badge-img" />
              </div>
            </div>
          ),
          name: 'isRecommended',
          component: CheckboxField,
          vertical: true,
          className: 'recommended',
          validation: Yup.boolean().default(true),
          props: {
            toggleSwitch: true,
            enableDisable: true,
            saveOnExit,
            fast: false,
          },
        },
        {
          name: 'id',
          component: TextField,
          className: 'hidden',
          props: {
            type: 'hidden',
          },
        },
        {
          name: 'providerData',
          component: TextField,
          className: 'hidden',
          props: {
            type: 'hidden',
          },
        },
      ],
    },
    {
      fields: [
        {
          ...COMMONS_FIELDS.propertyIds({
            isDefault: false,
            isAllowedMultiple: true,
          }),
        },
      ],
    },
  ]

  isEdit && steps.splice(0, 1)
  return steps
}

export const RESTAURANTS = {
  parent: 'recommendations',
  location: 'recommendations/',
  type: 'restaurant',
  title: 'Restaurant',
  addNew: '+ Add Restaurant',
  item: 'Restaurant',
  term: 'Restaurants, Food, Cafe, Bars', // https://blog.yelp.com/2018/01/yelp_category_list
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any restaurants',
  event: SUB_SECTIONS.RESTAURANTS,
  steps: RECOMMENDATION_STEPS,
}

export const ACTIVITIES = {
  parent: 'recommendations',
  location: 'recommendations/activities',
  type: 'activity',
  title: 'Activity',
  addNew: '+ Add Activity',
  item: 'Activity',
  term: 'Activities',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any activities',
  event: SUB_SECTIONS.ACTIVITIES,
  steps: RECOMMENDATION_STEPS,
}

export const DIRECTORY = {
  parent: 'recommendations',
  location: 'recommendations/directory',
  type: 'directory',
  title: 'Directory',
  addNew: '+ Add Directory',
  item: 'Directory',
  term: 'Shopping',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any directory items',
  event: SUB_SECTIONS.DIRECTORY,
  steps: RECOMMENDATION_STEPS,
}

export const RECOMMENDATION_TYPES = [RESTAURANTS, ACTIVITIES, DIRECTORY]
