/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { MESSAGES } from '../../constants/strings'
import { openModalWithContainer } from '../../reducers/modals'

function ManageCompanyName({
  me, dispatch, onSaveMe, recordAnalytics,
}) {
  return (
    <div className="settings-section">
      <h2>{MESSAGES.MANAGE_COMPANY_NAME}</h2>
      <div className="">
        <div className="passcode">
          <div className="label">
            {MESSAGES.CURRENT_COMPANY_NAME}
            {' '}
            <h3 style={{ display: 'inline' }}>
              {' '}
              {me.organization.name}
              {' '}
            </h3>
          </div>

          <div className="action-container">
            <a
              className="action"
              onClick={() => dispatch(
                openModalWithContainer('CompanyNameEditContainer', {
                  organization: me.organization,
                  onSave: organization => onSaveMe({ organization }),
                  recordAnalytics,
                }),
              )}
            >
              {MESSAGES.EDIT}
            </a>
            {/* todo: determine if we need to add a share passcode modal or let users request from gvg */}
            {/* <a className="action">Share passcode</a>  */}
          </div>
        </div>
      </div>
    </div>
  )
}

ManageCompanyName.defaultProps = {
  me: {},
  dispatch: () => { },
  onSaveMe: () => { },
  recordAnalytics: () => { },
}

export default ManageCompanyName
