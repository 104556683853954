const mapPropertiesOnEvent = (event, properties) => {
  let result = event
  // for (const key in properties) {
  //     const element = properties[key];
  //     if (element) result = `${result}_${element}`
  // }
  if (properties.section) result = `${result}:${properties.section}`
  if (properties.subSection) result = `${result}:${properties.subSection}`
  if (properties.action) result = `${result}:${properties.action}`

  return result
}

export { mapPropertiesOnEvent }
