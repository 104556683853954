import React, { useState, useEffect } from 'react'
import { get } from 'lodash'

import Resource from '../Resource'

import '../styles/SlideshowInput.scss'

const SlideshowInputWithBundles = ({
  bundles, value, onChange, ...rest
}) => {
  const [previewIndex, setPreviewIndex] = useState(0)

  useEffect(() => {
    ensureHasSelection()
  })

  const _onChange = evt => {
    setPreviewIndex(0)
    onChange(evt.target.value / 1)
  }

  const ensureHasSelection = () => {
    if (!value && bundles.length) {
      // this.props.onChange(this.props.bundles[0].id)
    }
  }

  const selectedBundle = bundles.find(b => b.id / 1 === value / 1)
  const selectedBundleItem = selectedBundle && selectedBundle.images[previewIndex]

  const onShiftPreview = (e, delta) => {
    e.preventDefault()
    setPreviewIndex((previewIndex + selectedBundle.images.length + delta) % selectedBundle.images.length)
  }

  const stock = bundles.filter(b => b.organizationId === null)
  const custom = bundles.filter(b => b.organizationId !== null)
  const defaultItem = selectedBundle && selectedBundle.default

  return (
    <div className="SlideshowInput slideshow-container" {...rest}>
      <div className={defaultItem ? 'slideShowDefault slideShow' : 'slideShow slideShowCustom'}>
        <select className="form-control" value={value || ''} onChange={_onChange}>
          {custom.length !== 0 && <option disabled>Custom Collections</option>}
          {custom.map(b => (
            <option key={b.id} value={b.id}>
              {b.name}
            </option>
          ))}
          {custom.length !== 0 && <option disabled>──────────</option>}
          <option disabled>Stock Collections</option>
          {stock.map(b => (
            <option key={b.id} value={b.id}>
              {b.name}
            </option>
          ))}
        </select>
      </div>

      {selectedBundleItem && (
        <div className="preview px-4">
          <img key={selectedBundleItem.image.standard} src={selectedBundleItem.image.standard} alt="preview" />
          <h5>{selectedBundleItem.title}</h5>
          <p>{selectedBundleItem.description}</p>
          <div className="controls">
            <div onClick={e => onShiftPreview(e, -1)}>
              <i className="necicon-arrow-next" />
            </div>
            <div onClick={e => onShiftPreview(e, 1)}>
              <i className="necicon-arrow-next" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const SlideshowInput = props => {
  return (
    <Resource collection path={['art-mode-bundles']} placeholder={[]}>
      {results => {
        const value = props.value
          || get(
            results.find(r => r.default),
            'id',
          )
        return <SlideshowInputWithBundles bundles={results} {...props} value={value} />
      }}
    </Resource>
  )
}

export default SlideshowInput
