import React from 'react'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import DateTime from 'react-datetime'
import moment from 'moment'
import { connect } from 'react-redux'
import jstz from 'jstz'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'
import '../../styles/AnalyticsContainer.scss'
import 'react-select/dist/react-select.cjs'
import 'react-datetime/css/react-datetime.css'
import { hasAccessTo } from '../../resources/features'

// todo: what if start comes after end?
const timezone = jstz.determine()
const setDate = (date, setFn) => {
  date && date.format ? setFn(date.format('YYYY-MM-DD')) : setFn(null)
}
// this will control the url params and handle displaying the controls as printable in pdf
const AnalyticsControls = ({ me, property }) => {
  const canAccessAllAnalytics = hasAccessTo('super_powers', me)
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)
  const [organizationId, setOrganizationId] = useQueryParam('organizationId', NumberParam)
  const [, setPropertyId] = useQueryParam('propertyId', NumberParam) // todo: make array param to handle multiple propertyIds
  const [tz, setTz] = useQueryParam('tz', StringParam)

  // default to the last 30 days for their organization if params not set
  if (!start) {
    setStart(
      moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
    )
  }
  if (!end) {
    setEnd(moment().format('YYYY-MM-DD'))
  }

  if (!organizationId || (!canAccessAllAnalytics && organizationId !== me.organizationId)) {
    setOrganizationId(me.organizationId)
  }
  if (!tz) {
    setTz(timezone.name())
  }

  const DateControls = () => (
    <>
      <div className="analytics-start analytics-date">
        <div className="analytics-label">From</div>
        <DateTime
          onChange={dt => setDate(dt, setStart)}
          inputProps={{ placeholder: 'Start Date' }}
          viewMode="days"
          isValidDate={date => {
            return date.isBefore(moment()) && date.isBefore(moment(end).add(1, 'day'))
          }}
          value={start}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          closeOnSelect
        />
      </div>
      <div className="analytics-end analytics-date">
        <div className="analytics-label">To</div>
        <DateTime
          onChange={dt => setDate(dt, setEnd)}
          inputProps={{ placeholder: 'End Date' }}
          viewMode="days"
          isValidDate={date => {
            return date.isBefore(moment()) && date.isAfter(moment(start).subtract(1, 'day'))
          }}
          value={end}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          closeOnSelect
        />
      </div>
    </>
  )

  return canAccessAllAnalytics && !property && organizationId ? (
    <Resource collection path={['organizations']} placeholderComponent={Loading}>
      {organizations => {
        const organization = organizations.find(organization => organizationId / 1 === organization.value)
        return !organizations.error ? (
          <div className="analytics-controls-container">
            <div className="analytics-controls">
              <div className="analytics-organization">
                <div className="analytics-label">Organization</div>
                <Select
                  name="organization"
                  defaultValue={organization}
                  options={organizations}
                  onChange={option => {
                    setPropertyId(undefined)
                    setOrganizationId(option.value)
                  }}
                />
              </div>
              <DateControls />
            </div>
            <div className="analytics-info">
              {organization && <div className="analytics-org">{organization.label}</div>}
              <div className="analytics-daterange-container">
                <div className="analytics-report-label">Analytics Report:</div>
                <div className="analytics-daterange">
                  {moment(start).format('LL')}
                  {' '}
                  -
                  {moment(end).format('LL')}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>{organizations.error}</div>
        )
      }}
    </Resource>
  ) : (
    <div className="analytics-date-controls">
      <DateControls />
    </div>
  )
}

export default withRouter(connect(state => ({ me: state.data.me }))(AnalyticsControls))
