import React from 'react'
import { CheckboxField } from './ChoiceField'

const ToggleSwitchField = ({
  name,
  label,
  labelFirst,
  toggleSwitch,
  saveOnExit,
  defaultValue,
  customLabel,
  showLabel,
}) => {
  return (
    <CheckboxField
      name={name}
      label={label}
      labelFirst={labelFirst}
      customLabel={customLabel}
      toggleSwitch={toggleSwitch}
      saveOnExit={saveOnExit}
      defaultChecked={defaultValue}
      showLabel={showLabel}
    />
  )
}

export default ToggleSwitchField
