import React, { useState } from 'react'

export const withWizard = Wrapped => {
  const WithWizard = ({ ...props }) => {
    const [step, setStep] = useState(0)

    const previous = () => {
      setStep(step - 1)
    }

    const multiPrevious = stepCount => {
      setStep(step - stepCount)
    }

    const next = () => {
      setStep(step + 1)
    }

    const stepsLength = props.formData && props.formData.steps && props.formData.steps.length

    const isFirstStep = step === 0
    const isLastStep = step === stepsLength - 1

    return (
      <Wrapped
        step={step}
        setStep={setStep}
        previous={previous}
        multiPrevious={multiPrevious}
        next={isLastStep ? null : next}
        stepsLength={stepsLength}
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        {...props}
      />
    )
  }

  return WithWizard
}
