import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import dummyURL from '../../images/avatar_placeholder.png'
import fallbackAvatar from '../../images/home-photo-placeholder.jpg'
import { PropertyAddress } from '.'
import '../../styles/PropertyGridContainer.scss'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { PROPERTY_OPTIONS, PROPERTY_STATUS } from '../../constants/forms/propertyInfo'

const _PropertyItem = (props) => {
  const {
    id, photoURL, archive, onArchive, ...rest
  } = props;
  const [defaultImage, setDefaultImage] = useState(false)

  useEffect(() => {
    if (photoURL === '' || photoURL == null) {
      setDefaultImage(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const host = rest.host || {}
  const addDefaultSrc = ev => {
    ev.target.src = fallbackAvatar
    ev.target.style.objectFit = 'contain'
    setDefaultImage(true)
  }

  return (
    <div className="PropertyItem" data-cy="property-item-card">
      <PopoverMenu className="popover-overlay">
        <PopoverMenuItem
          onClick={() => {
            onArchive(props, archive ? PROPERTY_STATUS.UNARCHIVE : PROPERTY_STATUS.ARCHIVE)
          }}
        >
          {archive ? PROPERTY_OPTIONS.RELIST_PROPERTY : PROPERTY_OPTIONS.ARCHIVE_PROPERTY}
        </PopoverMenuItem>
      </PopoverMenu>
      <Link
        className={`preview${archive ? ' archived-property-cursor' : ''}`}
        to={archive ? '/properties/archive' : `/properties/${id}`}
        onClick={() => {
          if (archive) {
            // e.preventDefault()
          }
        }}
      >
        <img
          className={classNames('grid-property-photo', {
            default: defaultImage,
          })}
          src={photoURL || fallbackAvatar}
          alt="preview"
          onError={addDefaultSrc}
        />
        {/* TODO: Address component consistency */}
        <div className="preview-overlay">
          <PropertyAddress {...props} />
          <img src={host.photoURL ? host.photoURL : dummyURL} alt={host.name} />
        </div>
      </Link>
    </div>
  )
}

const PropertyItem = connect(() => {
  return {}
})(_PropertyItem)
export default PropertyItem
