import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import '../styles/NotificationPopup.scss'
import { CheckIcon } from '../icons'
import Progress from './Progress'

function EscapiaImportProgressTask(props) {
  return (
    <>
      <h3>Getting property info from Escapia</h3>
      <Progress {...props} />
    </>
  )
}

function EscapiaImportCompletedTask(props) {
  return (
    <>
      <CheckIcon />
      <h3>Properties are ready to import.</h3>
      <Link className="btn btn-outline-primary" to={'properties?import=true'}>
        Select Properties
      </Link>
    </>
  )
}

function GenralImportProgressTask(props) {
  return (
    <>
      <h3>
        Getting property info from
        {props && props.reservationService && props.reservationService.toUpperCase()}
      </h3>
      <Progress {...props} />
    </>
  )
}

function GeneralImportCompletedTask() {
  return (
    <>
      <CheckIcon />
      <h3>Properties are ready to import.</h3>
      <Link className="btn btn-outline-primary" to={'properties?import=true'}>
        Select Properties
      </Link>
    </>
  )
}

const TASKS = {
  'escapia/progress': {
    components: {
      InProgressComponent: EscapiaImportProgressTask,
      CompletedComponent: EscapiaImportCompletedTask,
    },
  },
  'pms/progress': {
    components: {
      InProgressComponent: GenralImportProgressTask,
      CompletedComponent: GeneralImportCompletedTask,
    },
  },
}

function TaskPopup(props) {
  const { taskName, isCompleted, show } = props
  const {
    components: { InProgressComponent, CompletedComponent },
  } = TASKS[taskName]
  const TaskComponent = isCompleted ? CompletedComponent : InProgressComponent
  return (
    <CSSTransition in={show} timeout={400} classNames="alert" unmountOnExit>
      <div className={classNames('NotificationPopup')}>
        <TaskComponent {...props} />
      </div>
    </CSSTransition>
  )
}

function TaskPopupContainer({ tasks, active }) {
  return (
    <div className="NotificationContainer">
      {Object.keys(tasks).map(taskName => {
        const task = tasks[taskName]
        return <TaskPopup key={taskName} taskName={taskName} show={active !== task.modal} {...task} />
      })}
    </div>
  )
}

const mapStateToProps = ({ tasks, modals: { active } }) => ({ tasks, active })
export default connect(mapStateToProps)(TaskPopupContainer)
