import React from 'react'
import { get, capitalize } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import { FaExclamationCircle, FaBan } from 'react-icons/fa'
import { CheckIcon } from '../../icons'

import ServiceButtons from './ServiceButtons'
import { SERVICE_TEMPLATES } from '../../constants/services'
import CountdownTimer from '../CountdownTimer'
import CartAbandonButtons from './CartAbandonButtons'
import { MESSAGES } from '../../constants/strings'
import { openModalWithContainer } from '../../reducers/modals'

const ServiceRequestResponse = ({
  id,
  status,
  text,
  parameters,
  createdAt,
  service,
  onUpdateItem,
  dispatch,
  checkCartAbandon,
}) => {
  const className = classNames('ServiceRequestResponse', status)
  const { requireAcceptTerms } = service
  const acceptTerms = get(parameters, ['acceptTerms'], false)
  const humanStatus = capitalize(get(text, ['pm', 'humanStatus'], status))
  let children

  switch (status) {
    case 'PAYMENT_CAPTURED':
    case 'PM_APPROVED':
      const sentence = [
        !!parameters.payment_intent && 'Payment captured',
        requireAcceptTerms && acceptTerms && 'Terms Accepted',
      ]
        .filter(v => v)
        .join(' and ')
      children = (
        <>
          <div className="ServiceRequestResponseStatus">
            <CheckIcon />
            {humanStatus}
          </div>
          {sentence.length > 0 && <small>{sentence}</small>}
        </>
      )
      break

    case 'PAYMENT_CANCELED':
    case 'PM_DECLINED':
      children = (
        <>
          <div className="ServiceRequestResponseStatus">
            <FaBan />
            {humanStatus}
          </div>
          {!!parameters.payment_intent && (
            <div style={{ marginTop: '8px' }}>
              <small>{MESSAGES.PAYMENT_AUTHORIZED}</small>
            </div>
          )}
        </>
      )
      break

    case 'APPROVAL_PENDING':
      if (service.template === SERVICE_TEMPLATES.BUNDLE) {
        children = (
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              dispatch(
                openModalWithContainer(
                  'BundleServiceResponseContainer',
                  {
                    service,
                    id,
                    onUpdateItem,
                    hasPaymentProvider: !!parameters.payment_intent,
                    parameters,
                  },
                  { size: 's', customClass: 'modal-sm' },
                ),
              )
            }}
          >
            {MESSAGES.PENDING_RESPONSE}
          </button>
        )
      } else {
        children = (
          <>
            <div className="ServiceRequestResponseStatus">
              <ServiceButtons id={id} onUpdateItem={onUpdateItem} hasPaymentProvider={!!parameters.payment_intent} />
            </div>
            {!!parameters.payment_intent && (
              <div style={{ marginTop: '8px' }}>
                <small>Payment authorized</small>
              </div>
            )}
          </>
        )
      }
      break

    case 'PAYMENT_FAILED':
      children = (
        <div className="ServiceRequestResponseStatus">
          <FaExclamationCircle />
          <b className="text-danger">{humanStatus}</b>
        </div>
      )
      break

    case 'PAYMENT_PENDING':
      children = (
        <div className="ServiceRequestResponseStatus waiting-auth">
          <div className="icon-timer">
            <CountdownTimer date={moment(createdAt)} checkCartAbandon={checkCartAbandon} />
          </div>
          <p>{humanStatus}</p>
        </div>
      )
      break

    case 'CART_ABANDONED':
      children = (
        <div className="response-status">
          <div className="ServiceRequestResponseStatus status-cart-abandoned">
            <FaExclamationCircle />
            {humanStatus}
          </div>
          <div>
            <CartAbandonButtons id={id} phone={service.phone} dispatch={dispatch} createdAt={createdAt} />
          </div>
        </div>
      )
      break

    default:
      children = <div className="ServiceRequestResponseStatus">{humanStatus}</div>
  }
  return <div className={className}>{children}</div>
}

export default ServiceRequestResponse
