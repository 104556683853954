import { combineReducers } from 'redux'
import data from './data'
import modals from './modals'
import config from './config'
import tasks from '../resources/tasks'
import content from '../resources/content'
import advertisementData from './advertisementData'

export default combineReducers({
  data,
  modals,
  config,
  tasks,
  content,
  advertisementData,
})
