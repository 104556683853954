import React, { useState } from 'react'
import { FormModal, FormGroup, SubmitButton } from '../../forms'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { apiRequest } from '../../reducers/data'
import { SUB_SECTIONS, ACTIONS } from '../../constants/thirdPartyAnalytics'

import '../../styles/Link.scss'

const CompanyNameEditContainer = props => {
  const {
    handleSubmit, recordAnalytics, setFieldValue, dispatch, organization,
  } = props
  const oldCompanyName = organization?.name || ''
  const [companyName, setCompanyName] = useState(organization?.name || '')
  const [disableUpdate, setDisableUpdate] = useState(false)
  const [error, setError] = useState(false)
  const verb = { create: 'Change' }
  const errorMessage = 'There is already a company with the same name. Please use a different company name.'

  const handleChangename = async e => {
    setCompanyName(e.target.value)
    setFieldValue('name', e.target.value)
    setTimeout(organizationNameCheck, 1200)
    setDisableUpdate(true)
  }
  const organizationNameCheck = async () => {
    const orgName = companyName
    const { json } = await dispatch(apiRequest([`/me/organizationNameAlreadyExist/${orgName}`], 'GET'))
    setDisableUpdate(false)

    if (json && json.alreadyExist) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const handleChangeButton = e => {
    e.preventDefault()
    recordAnalytics({
      subSection: SUB_SECTIONS.MANAGE_COMPANY_NAME,
      action: ACTIONS.UPDATE,
      oldName: oldCompanyName,
      newName: companyName,
    })
    handleSubmit()
  }
  return (
    <form onSubmit={handleChangeButton}>
      <ModalHeader name="Company Name" verb={verb} subtitle="Enter your new company name" />
      <ModalBody>
        <FormGroup required label="Company Name " htmlFor="passcode">
          <input className="form-control" value={companyName} onChange={handleChangename} type="text" />
          {error && <h6 className="text-danger">{errorMessage}</h6>}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {error ? (
          ''
        ) : companyName.trim().length ? (
          disableUpdate ? (
            <button className="button disablebutton" disabled>
              Change
            </button>
          ) : (
            <SubmitButton>Change</SubmitButton>
          )
        ) : (
          ''
        )}
      </ModalFooter>
    </form>
  )
}

export default props => {
  const {
    organization: { id, name },
    onSave,
    dispatch,
  } = props
  const initialValues = {
    name,
  }

  const render = p => <CompanyNameEditContainer {...p} {...props} />

  const formatValues = v => {
    return {
      id,
      ...v,
    }
  }

  return (
    <FormModal
      formatValues={formatValues}
      initialValues={initialValues}
      render={render}
      onSave={onSave}
      dispatch={dispatch}
    />
  )
}
