import React, { useMemo } from 'react'
import { connect as formikConnect, getIn } from 'formik'
import Resource from '../../Resource'
import { MasterList } from '../list'
import { Loading } from '../PageStates'
import { PROPERTIES_SELECT } from '../../constants/tables/properties'
import { RECOMMENDATION_ADVERTISEMENT_SELECT_COLUMNS } from '../../constants/tables/recommendations'
import { SERVICE_ADVERTISEMENT_SELECT_COLUMNS } from '../../constants/tables/services'
import { PROPERTY_TYPES } from '../../constants/forms/propertyInfo'

const ADVERTISEMENT_TYPE = {
  Property: {
    path: ['properties'],
    columns: PROPERTIES_SELECT,
    formatSelected: item => ({
      title: item.addressLine1,
      // image:
    }),
  },
  Recommendation: {
    path: ['promos', 'recommendations', 'all'],
    dataPath: ['customizations'],
    columns: RECOMMENDATION_ADVERTISEMENT_SELECT_COLUMNS,
    subtitle1: 'Select or search for a recommendation you would like to advertise on the slideshow.',
    subtitle2: 'You can only create an ad for the recommendations that have already been added to the console.',

    formatSelected: item => {
      const medias = []
      item?.content?.imageURL && medias.push({ mediaUrl: item.content.imageURL, id: item.id })
      return {
        title: item.name,
        type: 'Recommendation',
        customizationId: item.id,
        style: item.style ? item.style : 'original',
        imageURL: item.content.imageURL,
        originalImageURL: item.content.imageURL,
        description: item.content.hostQuote,
        subType: item.type,
        selectedImageId: medias && medias.length > 0 ? medias[0]?.id : null,
        medias
      }
    },
  },
  Service: {
    path: ['promos', 'services'],
    columns: SERVICE_ADVERTISEMENT_SELECT_COLUMNS,
    subtitle1: 'Select or search for a service you would like to advertise on the slideshow.',
    subtitle2: 'You can only create an ad for the services that have already been added to the console.',

    formatSelected: item => {
      const medias = item.serviceMedias
        && item.serviceMedias.length
        && item.serviceMedias.sort((a, b) => a.mediaOrder - b.mediaOrder).filter(media => media.mediaType === 'IMAGE')
      const imgMedia = medias && medias.length && medias[0]
      const adsImg = imgMedia ? imgMedia.mediaUrl : item.iconURL ? item.iconURL : null
      return {
        title: item.name,
        style: item.style ? item.style : 'original',
        type: 'Service',
        serviceId: item.id,
        imageURL: adsImg,
        originalImageURL: adsImg,
        selectedImageId: imgMedia ? imgMedia.id : null,
        description: item.summarySubtitle,
        medias
      }
    },
  },
  // This is skipped
  Custom: {
    path: '',
  },
}

const AdvertisementContentSelectWithData = ({
  data,
  columns,
  setValues,
  next,
  formatSelected,
  subtitle1,
  subtitle2,
  properties,
}) => {
  const memoizedColumns = useMemo(() => columns, [columns])

  const onAdvertise = selectedContent => {
    setValues(formatSelected(selectedContent))
    next()
  }

  const initialState = {
    propertiesCount: properties.length,
    onAdvertise,
  }
  return (
    <div>
      <div>{subtitle1}</div>
      <div>{subtitle2}</div>
      <br />
      <MasterList
        initialState={initialState}
        columns={memoizedColumns}
        data={data}
        searchTheme="light square"
        emptyState={{ text: 'You have not yet added any items' }}
      />
    </div>
  )
}

const AdvertisementContentSelect = ({ formik: { values, setValues }, next }) => {
  const type = getIn(values, 'type')

  // TODO: Handle protection here to avoid error on back
  const { columns } = ADVERTISEMENT_TYPE[type]
  const { dataPath } = ADVERTISEMENT_TYPE[type]
  const { formatSelected } = ADVERTISEMENT_TYPE[type]
  const { subtitle1 } = ADVERTISEMENT_TYPE[type]
  const { subtitle2 } = ADVERTISEMENT_TYPE[type]
  return (
    <Resource collection path={ADVERTISEMENT_TYPE[type].path} placeholderComponent={Loading}>
      {data => {
        const dataToUse = dataPath ? data[dataPath] : data

        return (
          <Resource collection path={['properties', `?type=${PROPERTY_TYPES.current}`]} placeholder={[]}>
            {properties => (
              <AdvertisementContentSelectWithData
                data={dataToUse}
                columns={columns}
                setValues={setValues}
                next={next}
                formatSelected={formatSelected}
                subtitle1={subtitle1}
                subtitle2={subtitle2}
                properties={properties}
              />
            )}
          </Resource>
        )
      }}
    </Resource>
  )
}

export default formikConnect(AdvertisementContentSelect)
