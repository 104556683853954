import { parsePhoneNumber } from 'libphonenumber-js'

function PhoneNumber({ children }) {
  let phone = children
  if (!phone || typeof phone === 'object') {
    return null
  }
  try {
    const phoneNumber = parsePhoneNumber(children)
    phone = phoneNumber.formatNational()
  } catch (error) {
    console.warn(`Couldn't parse phone number ${children}`, error)
  }
  return phone
}

export default PhoneNumber
