import { get, isFunction } from 'lodash';
import React, { Fragment } from 'react'
import { Input } from 'reactstrap'
import { CharactersLeft } from '../../forms/fields'
import { fieldify } from '../../forms/fields/utils';
import RichTextEditor from '../RichTextEditor';

const ContactHostMessageField = fieldify(({
  field, form, invalid, maxLength, saveOnExit, readOnly, ...rest
}) => {
  const requestEnabled = get(form.values, 'content.requestEnabled', false);
  const className = ['form-control', invalid ? 'is-invalid' : null].filter(v => v).join(' ')
  const length = isFunction(maxLength) ? maxLength(form.values) : maxLength;

  const onBlur = () => {
    saveOnExit && form.handleSubmit()
  }

  if (requestEnabled) {
    return (
      <Fragment>
        <Input
          className="TextareaField"
          type="textarea"
          invalid={invalid}
          {...field}
          {...rest}
          maxLength={length}
          onBlur={onBlur}
          disabled={readOnly || rest.disabled}
        />
        <CharactersLeft length={field.value ? field.value.length : 0} maxLength={length} />
      </Fragment>
    )
  }
  return (
    <>
      <div className={className} style={{ padding: 0, backgroundColor: 'transparent', border: 'none' }}>
        <RichTextEditor
          {...field}
          {...rest}
          onBlur={onBlur}
          readOnly={readOnly}
          maxLength={length}
          value={field.value}
        />
      </div>
    </>
  )
})

export default ContactHostMessageField
