import React from 'react'
import Icon from './Icon'

function RecommendationsAdvertisementIcon(props) {
  return (
    <Icon
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 42 70"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.prefix__st2{fill:#f7dc54}'}</style>
      <path fill="#fff" d="M1.5 1.5h39v67h-39z" />
      <path d="M39 3v64H3V3h36m3-3H0v70h42V0z" fill="#5691c7" />
      <path className="prefix__st2" d="M21.15 46.32l5.65 3.1V33.36H15.5v16.06z" />
      <path
        className="prefix__st2"
        d="M32.54 28.77l-1.23 2.25.04 2.57-2.12 1.43-1.1 2.31-2.54.38-1.97 1.64-2.47-.71-2.47.71-1.97-1.64-2.54-.38-1.1-2.31-2.13-1.43.05-2.57-1.23-2.25 1.23-2.24-.05-2.57 2.13-1.43 1.1-2.31 2.54-.38 1.97-1.64 2.47.71 2.47-.71 1.97 1.64 2.54.38 1.1 2.31 2.12 1.43-.04 2.57z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M32.54 28.77l-1.23 2.25.04 2.57-2.12 1.43-1.1 2.31-2.54.38-1.97 1.64-2.47-.71-2.47.71-1.97-1.64-2.54-.38-1.1-2.31-2.13-1.43.05-2.57-1.23-2.25 1.23-2.24-.05-2.57 2.13-1.43 1.1-2.31 2.54-.38 1.97-1.64 2.47.71 2.47-.71 1.97 1.64 2.54.38 1.1 2.31 2.12 1.43-.04 2.57z"
      />
      <path
        fill="none"
        stroke="#3a3a3a"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M26.39 26.03l-8.63 6.62-2.13-2.77"
      />
    </Icon>
  )
}
export default RecommendationsAdvertisementIcon
