import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { closeModal } from '../../reducers/modals'

export default class StockArtCollectionsContainer extends Component {
  state = {
    bundleIndex: 0,
    imageIndex: 0,
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  onCancel = () => {
    this.props.dispatch(closeModal())
  }

  onFinish = async () => {
    const regexName = await this.state.name.replace(/\//g, '%2F')
    this.setState({ name: regexName })
    const { json, response } = await this.props.onSave(this.state)
    if (!this._mounted) return false
    if (response.status === 200) {
      this.props.dispatch(closeModal())
    } else {
      this.setState({ error: json.error })
    }
  }

  onClose = async () => {
    this.props.dispatch(closeModal())
  }

  onCustomize = async () => {
    const selected = this.props.stockBundles[this.state.bundleIndex]

    const { json } = await this.props.onCreate({
      name: `${selected.name}`,
      images: selected.images,
      propertyIds: [],
    })

    this.onClose()
    this.props.setEditingView(json, true)
  }

  render() {
    const { stockBundles } = this.props
    const { bundleIndex, imageIndex } = this.state

    const selected = stockBundles[bundleIndex]
    const onShiftPreview = (e, delta) => {
      this.setState({
        imageIndex: (imageIndex + selected.images.length + delta) % selected.images.length,
      })
      e.preventDefault()
    }

    return (
      <div>
        <ModalHeader>Browse Stock Collections</ModalHeader>
        <ModalBody>
          <select
            value={bundleIndex}
            className="form-control"
            style={{ width: '100%', marginBottom: 15 }}
            onChange={e => this.setState({ bundleIndex: e.target.value, imageIndex: 0 })}>
            {stockBundles.map((bundle, idx) => (
              <option key={bundle.name} value={idx}>
                {bundle.name}
              </option>
            ))}
          </select>

          {selected && (
            <div className="SlideshowInput">
              <div className="preview" style={{ display: 'flex', height: 'auto' }}>
                <img
                  style={{ width: 325, height: 'auto', objectFit: 'cover' }}
                  key={selected.images[imageIndex].image.standard}
                  src={selected.images[imageIndex].image.standard}
                  alt="preview"
                />
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <h5>{selected.images[imageIndex].title}</h5>
                  <p>{selected.images[imageIndex].description}</p>
                  <div className="controls">
                    <div onClick={e => onShiftPreview(e, -1)}>
                      <i className="necicon-arrow-next" />
                    </div>
                    <div onClick={e => onShiftPreview(e, 1)}>
                      <i className="necicon-arrow-next" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-outline-primary mr-1" onClick={this.onClose}>
            Cancel
          </Button>
          <Button className="btn btn-primary" onClick={this.onCustomize}>
            Select This Collection
          </Button>
        </ModalFooter>
      </div>
    )
  }
}
