import React from 'react'
import classNames from 'classnames'
import '../styles/EmptyNavItemPlaceholder.scss'
import { MESSAGES } from '../constants/strings'

export function EmptyNavItemPlaceholder({
  navItem, icon, text, size, children,
}) {
  return (
    <div className={classNames('EmptyNavItem', size)}>
      {icon}
      {text ? (
        <p>
          <b>{text}</b>
        </p>
      ) : (
        <p>
          <b>{`${MESSAGES.NO_ITEM_ADDED_YET} ${navItem}`}</b>
        </p>
      )}
      {children}
    </div>
  )
}

export function Empty() {
  return (
    <div>
      {' '}
      {MESSAGES.EMPTY}
    </div>
  )
}

export function Loading({ style }) {
  return (
    <div style={{ textAlign: 'center', margin: '4rem 0', ...style }}>
      <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#666">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  )
}

EmptyNavItemPlaceholder.defaultProps = {
  size: 'medium',
}
