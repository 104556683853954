import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { MESSAGES } from '../../constants/strings'
import '../../styles/AdminContainer.scss'

const ManageRevenue = ({ onSync }) => {
  return (
    <Fragment>
      <div className="feature-container">
        <div className="feature-name">
          <Link to="/admin/manage-revenue-share/revenue-share">{MESSAGES.MANAGE_REVENUE_SHARE}</Link>
        </div>
        <div className="feature-zuora pt-3">
          <h5 className="mb-0">{`${MESSAGES.ZOURA_PRODUCTS_RATE_PLANS}`}</h5>
          <button className="button" onClick={onSync}>{`${MESSAGES.SYNC_NOW}`}</button>
        </div>
      </div>
    </Fragment>
  )
}

export default ManageRevenue
