import { useEffect } from 'react'

const rootNode = document.getElementById('root')
const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    rootNode.addEventListener('click', handleClick)

    return () => {
      rootNode.removeEventListener('click', handleClick)
    }
  })
}

export default useOutsideClick
