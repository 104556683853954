import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { apiRequest, dataForResourcePath } from './reducers/data'

/*
The `Resource` component is a Redux-connected component that takes a render prop as it's child.
Given a resource `path`, it calls the render prop with the currently cached data at that path,
(or the `placeholder` data if provided) and makes an API request to refresh it with the latest
data if necessary.

The `Resource` component also provides it's children with helper functions that they can call to
update or delete the resource. Calling these helper methods makes the appropriate API requests
and then updates the local cache.

If you pass true for the `collection` prop, the `Resource` component expects an array to be
available at the path provided and exposes functions that allow items to be created, deleted
and updated.

Examples:

```jsx

  <Resource path={['me']} placeholder={{ emailAddress: 'Loading...' }}>
    {(me, { onSave }) => (
      <div>{me.emailAddress}</div>
    )}
  </Resource>
```

```jsx

  <Resource collection path={['properties']} placeholder={[]}>
    {(properties, { onCreateItem, onUpdateItem, onDeleteItem}) => (
      ...
      ... (call onCreateItem to add an item, etc.)
      ...
    )}
  </Resource>
```

Empty States:

Typically you want to handle the "loading" state in one of two ways:

1. Assume content is empty until we get data back.

  To do this, pass `placeholder={ < your default data > }` to the component.
  For example, if we're loading an array, you might say: `<Resource placeholder={[]} />`

2. Display a loading state until we get data back.

  To do this, pass `placeholderComponent={MyComponent}`. The placecholder
  component will be rendered instead of the Resource's children until the server
  has returned data.

*/

// Collection data provider

function pathIsValid(path) {
  return path.findIndex(component => component == null || component === false) === -1
}

class _Resource extends React.Component {
  componentDidMount() {
    const {
 parent, dispatch, path, result, placeholder, apiPath,
} = this.props

    const needInitialLoad = !parent || result === placeholder

    if (needInitialLoad && pathIsValid(path)) {
      dispatch(apiRequest(path, 'GET', null, apiPath))
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path.join('/') !== this.props.path.join('/')) {
      this.onRefresh()
    }
  }

  onRefresh = () => {
    const {
 parent, dispatch, path, apiPath,
} = this.props
    if (parent) {
      parent.onRefresh()
    }
    if (pathIsValid(path)) {
      return dispatch(apiRequest(path, 'GET', null, apiPath))
    }
  }
  // Collection mutations

  onCreateItem = async newItem => {
    const { dispatch, path, apiPath } = this.props
    const result = await dispatch(apiRequest(path, 'POST', newItem, apiPath))
    await this.onRefresh()
    return result
  }

  onUpdateItem = async (updatedItem, escapePreviousPath) => {
    let { dispatch, path, apiPath } = this.props
    if (escapePreviousPath) {
      path = updatedItem.path
      updatedItem = {}
    }
    const result = await dispatch(
      apiRequest([...path, updatedItem.id], 'PUT', updatedItem, apiPath ? [...apiPath, updatedItem.id] : null),
    )

    await this.onRefresh()
    return result
  }

  onDeleteItem = async deleteItem => {
    const { dispatch, path, apiPath } = this.props
    await dispatch(apiRequest([...path, deleteItem.id], 'DELETE', {}, apiPath ? [...apiPath, deleteItem.id] : null))
    await this.onRefresh()
  }

  // Single item mutations

  onSave = async updatedJSON => {
    const { dispatch, path, apiPath } = this.props
    return dispatch(apiRequest(path, 'PUT', updatedJSON, apiPath))
  }

  onDelete = async () => {
    const { dispatch, path, apiPath } = this.props
    return dispatch(apiRequest(path, 'DELETE', {}, apiPath))
  }

  NestedResource = props => <Resource {...props} path={[...this.props.path].concat(props.path)} parent={this} />

  render() {
    const {
 children, result, collection, dispatch, placeholderComponent: PlaceholderComponent,
} = this.props
    if (!result && PlaceholderComponent) {
      return <PlaceholderComponent />
    }

    return children(result, {
      dispatch,
      onCreateItem: collection && this.onCreateItem,
      onUpdateItem: collection && this.onUpdateItem,
      onDeleteItem: collection && this.onDeleteItem,
      onRefresh: this.onRefresh,
      onSave: !collection && this.onSave,
      onDelete: !collection && this.onDelete,
      NestedResource: this.NestedResource,
    })
  }
}

const Resource = connect((state, ownProps) => {
  const data = pathIsValid(ownProps.path) && dataForResourcePath(state.data, ownProps.path)

  return {
    result: data || ownProps.placeholder,
  }
})(_Resource)

export default Resource
