/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import { FieldArray } from 'formik'
import { Accordion } from 'react-bootstrap'
import classNames from 'classnames'
import { get, isArray, isFunction } from 'lodash'
import { AddButton } from '../components/list'
import { ErrorIcon } from '../icons'

const Add = ({ group, onClick, disabled }) => {
  if (group.customAddButton) {
    const Add = group.customAddButton
    return <Add verb={'Add'} item={group.addTitle} onClick={onClick} />
  }
  return (
    <AddButton
      type="button"
      disabled={disabled}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      className={'btn btn-primary mt-4'}
      verb={'Add'}
      item={group.addTitle}
      onClick={onClick}
    />
  )
}

const ConditionWrapper = ({ children, wrapper, condition }) => {
  return condition ? wrapper(children) : children
}

const AccordionHead = ({
  eventKey,
  activeKey,
  setActiveKey,
  remove,
  submit,
  title,
  hasError,
  disabled,
  hideDeleteIconOnFirstIndex = false,
}) => {
  const clickHandler = () => {
    const newIndex = activeKey === eventKey ? null : eventKey
    setActiveKey(newIndex)
  }
  return (
    <div className={classNames({ 'has-error': hasError }, 'AccordionHead')} eventKey={eventKey} onClick={clickHandler}>
      <div className="d-flex">
        {hasError && <ErrorIcon />}
        <h4>
          {'\t\t'}
          {title}
        </h4>
      </div>
      {!disabled && !hideDeleteIconOnFirstIndex && (
        <div>
          <i
            className="necicon-trash-bin"
            onClick={event => {
              remove(Number(eventKey))
              if (isFunction(submit)) submit()
              event.stopPropagation()
            }}
          />
        </div>
      )}
    </div>
  )
}

const GenerateFieldArray = ({
  group, formGroupWrapper, field: _field, next, size,
}) => {
  const [activeKey, setActiveKey] = useState('0')
  const FormGroupWrapper = formGroupWrapper
  const Field = _field

  return (
    <div className="standard-form topic-accordion">
      <FieldArray
        name={group.name}
        validateOnChange={false}
        validateOnBlur={false}
        render={({ form: { values, errors, handleSubmit }, push, remove }) => {
          const addHandler = () => {
            push({ ...group.validation._default[0] })
            setActiveKey(fields.length.toString())
          }
          const groupErrors = get(errors, group.name)
          const fields = get(values, group.name)
          const isDisabled = (isFunction(group.disabled) ? group.disabled(values) : group.disabled)

          return (
            <>
              <ConditionWrapper
                condition={group.shouldCollapse && fields?.length > 1}
                wrapper={children => (
                  <Accordion activeKey={activeKey}>{children}</Accordion>
                )}
              >
                {!fields ? (
                  <></>
                ) : (
                  fields.map((item, index) => {
                    const title = item[group.headerIdentifier] || `${group.label} ${index + 1}`
                    const hasError = !!groupErrors?.[index]
                    return (
                      <ConditionWrapper
                        key={`${group.name}.${index}`}
                        condition={group.shouldCollapse && fields?.length > 1}
                        wrapper={children => (
                          <div className="accordion">
                            <AccordionHead
                              activeKey={activeKey}
                              setActiveKey={setActiveKey}
                              eventKey={index.toString()}
                              remove={remove}
                              submit={group.saveOnExit && handleSubmit}
                              title={title}
                              hasError={hasError}
                              disabled={group.readOnly || isDisabled}
                              hideDeleteIconOnFirstIndex={group.hideDeleteIconOnFirstIndex && index === 0}
                            />
                            <Accordion.Collapse eventKey={index.toString()}>{children}</Accordion.Collapse>
                          </div>
                        )}
                      >
                        <div className="my-2">
                          {group.fields.map(field => {
                            const newField = { ...field }
                            let fieldName = `${group.name}.${index}.${newField.name}`
                            if (isArray(newField.name)) {
                              fieldName = newField.name.map(
                                currentFieldName => `${group.name}.${index}.${currentFieldName}`,
                              )
                            }
                            newField.props = {
                              ...newField.props,
                              label: newField.label,
                              name: fieldName,
                              disabled: newField.disabled,
                            }
                            if (newField.manualGroup) {
                              return <Field key={fieldName} field={newField} next={next} />
                            }
                            return (
                              <FormGroupWrapper key={fieldName} size={size} group={newField}>
                                <Field key={fieldName} field={newField} next={next} />
                              </FormGroupWrapper>
                            )
                          })}
                        </div>
                      </ConditionWrapper>
                    )
                  })
                )}
              </ConditionWrapper>
              <Add disabled={isDisabled || (fields?.length >= group.maxLength)} group={group} onClick={addHandler} />
            </>
          )
        }}
      />
    </div>
  )
}

export default GenerateFieldArray
