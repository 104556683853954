import React from 'react'
import Icon from './Icon'

function ServicesAdvertisementIcon(props) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 70" {...props}>
      <path fill="#fff" d="M1.5 1.5h39v67h-39z" />
      <path d="M39 3v64H3V3h36m3-3H0v70h42V0z" fill="#5691c7" />
      <g>
        <path d="M33.18 37.75c-.65-6.11-5.75-10.88-12.03-10.88S9.76 31.64 9.12 37.75h24.06z" fill="#4392d0" />
        <path fill="none" stroke="#4392d0" strokeWidth={3} d="M9.12 41.09h24.06" />
        <path fill="none" stroke="#4392d0" strokeWidth={2} d="M21.15 24.64v4.69" />
        <g>
          <path
            fill="none"
            stroke="#4392d0"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.8 24h4.69"
          />
        </g>
      </g>
    </Icon>
  )
}

export default ServicesAdvertisementIcon
