import React from 'react'
import classNames from 'classnames'
import { RadioField, CheckboxField } from './ChoiceField'
import '../../styles/Fields.scss'

const ChoiceGroup = ({
  fieldComponent, name, options, row, flex,
}) => {
  const Choice = fieldComponent
  return (
    <div className={classNames('ChoiceGroup', { 'group-row': row }, { 'd-flex': flex })}>
      {options.map(choice => {
        const {
          value, label, icon, head, isHead, image, toggleSwitch, related,
        } = choice
        return (
          <Choice
            key={value}
            name={name}
            label={label}
            image={image}
            value={value}
            icon={icon}
            group
            head={head}
            isHead={isHead}
            toggleSwitch={toggleSwitch}
            related={related}
          />
        )
      })}
    </div>
  )
}

export const RadioGroup = props => <ChoiceGroup {...props} fieldComponent={RadioField} />
export const CheckboxGroup = props => <ChoiceGroup {...props} fieldComponent={CheckboxField} />

export default ChoiceGroup

ChoiceGroup.defaultProps = {
  row: false,
}
