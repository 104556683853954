import { isObject } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { mapPropertiesOnEvent } from '../utils/index'

mixpanel.init(process.env.REACT_APP_THIRD_PARTY_ANALYTICS_API_KEY, { debug: true })

const Mixpanel = {
  track: (event, properties) => {
    let updatedProperties = {}
    if (isObject(properties)) updatedProperties = { ...updatedProperties, ...properties }
    const eventName = mapPropertiesOnEvent(event, updatedProperties)
    mixpanel.track(eventName, { ...properties, environment: 'Admin' })
  },
}

export default Mixpanel
