import React from 'react'
import Icon from './Icon'

function HandleIcon(props) {
  return (
    <Icon viewBox="0 0 12 12" {...props} width="1em" height="1em">
      <g fill="currentColor">
        <circle cx={9.5} cy={2.5} r={2.5} data-color="color-2" />
        <circle cx={2.5} cy={2.5} r={2.5} />
        <circle cx={9.5} cy={9.5} r={2.5} />
        <circle cx={2.5} cy={9.5} r={2.5} data-color="color-2" />
      </g>
    </Icon>
  )
}

export default HandleIcon
