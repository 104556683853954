import React from 'react'
import Icon from './Icon'

function SortArrowIcon() {
  return (
    <Icon viewBox="0 0 8 5">
      <path d="M4 5L0 0h8z" className="fill" fillRule="evenodd" />
    </Icon>
  )
}

export default SortArrowIcon
