import React from 'react'
import { connect } from 'formik'

import Loading from '../components/Loading'

function SubmitButton({ isSubmitting, children, ...rest }) {
  if (isSubmitting) {
    children = <Loading />
  }
  return (
    <button type="submit" className="button" disabled={isSubmitting} {...rest}>
      {children}
    </button>
  )
}

export default connect(({ formik, ...rest }) => <SubmitButton isSubmitting={formik.isSubmitting} {...rest} />)
