import React from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { get, set, orderBy } from 'lodash'
import qs from 'qs'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'

import propertyDummyURL from '../../images/home-photo-placeholder-2x.png'
import '../../styles/AnalyticsContainer.scss'
import '../../styles/ImportContainer.scss'
import { StatBar } from '../../components'
import { PropertyAddress } from '../../components/Property'
import { pluralize } from '../../Utils'
import { STATUS_LABELS } from '../../constants/forms/propertyInfo'
import { MESSAGES } from '../../constants/strings'

const TopProperties = ({
 servicesPurchased, history, spying, location, params,
}) => {
  const getPropertyAnalyticsLink = propertyId => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (spying) {
      params.propertyId = propertyId
      return `${location.pathname}?${qs.stringify(params)}`
    }
    return `/properties/${propertyId}/analytics?${qs.stringify(params)}`
  }

  const servicesByProperties = {}
  if (servicesPurchased && Array.isArray(servicesPurchased.services)) {
    servicesPurchased.services.forEach(({ properties }) => {
      Object.keys(properties).forEach(propertyId => {
        set(servicesByProperties, propertyId, get(servicesByProperties, propertyId, 0) + properties[propertyId])
      })
    })
  }
  return (
    <Resource collection path={['analytics', 'properties', params]} placeholderComponent={Loading}>
      {({ topProperties }) => (
        <div className="TopProperties">
          <div className="title">{MESSAGES.PROPERTIES_BY_USAGE}</div>
          <div className="top-properties-list">
            {topProperties
              && orderBy(topProperties, ['pageViews'], ['desc']).map(({ pageViews, servicesPurchased, ...property }) => (
                <div
                  key={property.id}
                  className={`property-list-item${property.isArchived ? ' archived-property-cursor' : ''}`}
                  onClick={e => {
                    if (!property.isArchived) {
                      history.push(getPropertyAnalyticsLink(property.id))
                    } else {
                      e.preventDefault()
                    }
                  }}
                >
                  <img
                    src={property.photoURL || propertyDummyURL}
                    className={classNames('grid-property-photo property-image', { default: !property.photoURL })}
                    alt="property"
                  />
                  <div className="address-wrapper">
                    <PropertyAddress {...property} numberOfLines={3} />
                    {property.isArchived && <em className="archive-status">{STATUS_LABELS.ARCHIVED}</em>}
                  </div>
                  <StatBar
                    stats={[
                      {
                        value: pageViews,
                        label: pluralize(pageViews, 'Pageview'),
                      },
                      {
                        value: servicesByProperties[property.id],
                        label: pluralize(servicesByProperties[property.id], 'Service Sold', 'Services Sold'),
                      },
                    ]}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </Resource>
  )
}

export default withRouter(TopProperties)
