import React from 'react'
import { Alert } from 'reactstrap'
import { FaTimesCircle, FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa'
import '../styles/Alert.scss'

const types = {
  error: { icon: <FaTimesCircle />, color: 'danger' },
  success: { icon: <FaCheckCircle />, color: 'success' },
  warning: { icon: <FaExclamationTriangle />, color: 'warning' },
}

export default function ({ type, text }) {
  const { icon, color } = types[type]
  return (
    <Alert color={color}>
      {icon}
      {' '}
      {text}
    </Alert>
  )
}
