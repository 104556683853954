import React from 'react'
import classNames from 'classnames'
import Skeleton from '../../components/Skeleton'
import DetailHTMLPreview from './DetailHTMLPreview'
import TextPreview from './TextPreview'
import imagePlaceholder from '../../images/image-placeholder.png'
import normalMessage from '../../images/normal-message.png'
import alertMessage from '../../images/alert-banner.png'
import qrImage from '../../images/qr.svg'
import phoneImage from '../../images/phone.svg'
import alertIcon from '../../images/icons/alert.svg'
import ImageCarouselPreview from './ImageCarouselPreview'

const MessagePreview = props => {
  const {
    formik: { values },
    className,
  } = props

  const isImage = values.media.length
  const isAlert = values.category === 'ALERT'
  const isQrCode = values.qrCode
  return (
    <div className={classNames('announcement-preview', className)}>
      <div className="message-top">Message From Your Host</div>
      {isImage ? (
        <ImageCarouselPreview {...props} name="media" placeholder={imagePlaceholder} />
      ) : isAlert ? (
        <img src={alertMessage} className="msg-alert" alt="message-icon" />
      ) : (
        <img src={normalMessage} className="default-icon" alt="message-icon" />
      )}

      {isImage && isAlert ? <img src={alertIcon} className="alert-sm" alt="message-icon" /> : ''}
      <div className={`message-title ${isAlert ? 'alert-title' : ''} `}>
        <TextPreview {...props} placeholder={<Skeleton height={15} width="50%" />} name="title" />
      </div>
      <div className="description-block has-thin-scroll scroll-grey">
        <DetailHTMLPreview {...props} heading={null} name="content" placeholder={<Skeleton height={8} count={8} />} />
      </div>

      {isQrCode && (
        <div className="d-flex justify-content-center preview-phone-qrcode">
          <div className="phoneBox">
            <img src={phoneImage} width="60px" alt="message-icon" />
            <p> View on your phone </p>
          </div>
          <div className="d-flex ">
            <div className="vl" />
            <div className="qrIconBox">
              <img src={qrImage} width="60px" alt="qr-icon" />
              <p> Scan for details </p>
            </div>
          </div>
        </div>
      )}

      <span className="preview-action">
        <i>&nbsp;</i>
        Got it!
      </span>
    </div>
  )
}

export default MessagePreview
