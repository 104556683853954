import React, { Children, useState, useRef } from 'react'
import classNames from 'classnames'
import useOutsideClick from '../../hooks/useOutsideClick'
import { DotsIcon } from '../../icons'

import '../../styles/PopoverMenu.scss'

const PopoverMenu = ({
 Icon, autoClose, className, children, ...rest
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    setShowMenu(false)
  })

  const resolvedChildren = children.type === React.Fragment ? children.props.children : children

  const enhancedChildren = Children.map(resolvedChildren, child => child && React.cloneElement(child, { setShowMenu }))

  const menuClassName = Icon === DotsIcon && 'menu-button'
  return (
    <div className={classNames('PopoverMenu', className)} ref={ref} {...rest}>
      <div
        className={classNames('icon-button', menuClassName)}
        onClick={event => {
          event.stopPropagation()
          setShowMenu(!showMenu)
        }}
      >
        <Icon size={16} />
      </div>
      {showMenu && (
        <div className={classNames('menu', { show: showMenu })} onClick={() => autoClose && setShowMenu(false)}>
          {enhancedChildren}
        </div>
      )}
    </div>
  )
}

export default PopoverMenu

PopoverMenu.defaultProps = {
  Icon: DotsIcon,
  autoClose: true,
}
