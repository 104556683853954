import React, { Fragment, useState } from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import SlideshowDetailContainer from '../SlideshowDetailContainer'
import PropertySelectionContainer from './PropertySelectionContainer'
import '../../styles/List.scss'

const SlideshowWithEditContainer = ({
  properties,
  closeModal,
  slideShow,
  onUpdateItem,
  onSave,
  onDeleteItem,
  firstTime,
  ...props
}) => {
  const [propertyIds, setpropertyIds] = useState([])

  const [steps, setSteps] = useState(0)
  const [updatedSlideshow, setSlideshow] = useState(slideShow)
  const [isTitleEmpty, setisTitleEmpty] = useState(false)
  const [isImagesEmpty, setIsImagesEmpty] = useState(false)

  const submit = () => {
    if (steps === 0) {
      if (isTitleEmpty || isImagesEmpty) {
        return
      }
      // if (updatedSlideshow.default) {
      //   onUpdateItem({ ...updatedSlideshow })
      //   closeModal()
      // } else {
      setSteps(steps + 1)
      // }
    } else {
      onUpdateItem({ ...updatedSlideshow, propertyIds })
      closeModal()
    }
  }

  const setNewSlideShow = slideshowUpdated => {
    slideshowUpdated ? setSlideshow(slideshowUpdated) : setSlideshow(slideShow)
  }

  const setTitle = value => {
    if (value.trim()) {
      setisTitleEmpty(false)
    } else {
      setisTitleEmpty(true)
    }
  }

  const checkAndRemoveSlideshow = () => {
    if (firstTime) {
      onDeleteItem(updatedSlideshow)
    }
    closeModal()
  }

  return (
    <Fragment style={{ width: '1050px' }}>
      <ModalHeader
        closeModal={() => checkAndRemoveSlideshow()}
        className="slideshowModal"
        name="Slideshow Add / Edit"
        verb={false}
      />
      <ModalBody>
        {steps === 0 ? (
          <SlideshowDetailContainer
            id={props.id}
            totalProperties={properties.length}
            setNewSlideShow={setNewSlideShow}
            setTitle={setTitle}
            setImagesStatus={setIsImagesEmpty}
            slideShow={updatedSlideshow}
          />
        ) : (
          <PropertySelectionContainer
            slideShow={updatedSlideshow}
            propertiesCount={properties.length}
            properties={properties}
            propertyIds={propertyIds}
            setpropertyIds={setpropertyIds}
            onSave={onSave}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {steps === 1 && (
          <button className="btn btn-outline-primary mr-2" onClick={() => setSteps(0)}>
            Back
          </button>
        )}

        <button className="btn btn-primary" onClick={submit}>
          {steps === 0 ? 'Next' : 'Save'}
        </button>
      </ModalFooter>
    </Fragment>
  )
}

export default SlideshowWithEditContainer
