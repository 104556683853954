import React from 'react'
import Icon from './Icon'

function TabRightArrowIcon() {
  return (
    <Icon width="8" height="13" viewBox="0 0 8 13" fill="none">
      <path
        d="M2.08984 1.03516L7.09375 6.28516C7.22135 6.41276 7.28516 6.55859 7.28516 6.72266C7.28516 6.88672 7.22135 7.04167 7.09375 7.1875L2.08984 12.4102C1.79818 12.6654 1.48828 12.6745 1.16016 12.4375C0.904948 12.1276 0.904948 11.8177 1.16016 11.5078L5.75391 6.69531L1.16016 1.96484C0.904948 1.63672 0.904948 1.32682 1.16016 1.03516C1.48828 0.779948 1.79818 0.779948 2.08984 1.03516Z"
        fill="#5591C7"
      />
    </Icon>
  )
}

export default TabRightArrowIcon
