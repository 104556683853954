import { connect } from 'formik'
import React from 'react'

const rerender = (formik, preview, index) => {
  const Component = preview.component
  const { Parent } = preview

  return !Parent ? (
    <div key={`previewDiv${index}`} className={preview.className}>
      {preview.children
        ? preview.children.map(rerender.bind(this, formik))
        : Component && <Component key={`preview${index}`} {...preview} formik={formik} />}
    </div>
  ) : (
    <Parent key={`previewDiv${index}`} {...preview} formik={formik}>
      {preview.children
        ? preview.children.map(rerender.bind(this, formik))
        : Component && <Component key={`preview${index}`} {...preview} formik={formik} />}
    </Parent>
  )
}

const GeneratePreview = ({ preview, formik }) => {
  return preview.map(rerender.bind(this, formik))
}

export default connect(GeneratePreview)
