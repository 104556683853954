import React from 'react'
import { Loading } from '../../components/PageStates'
import { MESSAGES } from '../../constants/strings'
import Resource from '../../Resource'
import '../../styles/AnalyticsContainer.scss'

const WelcomeVideos = ({ params }) => {
  return (
    <Resource collection path={['analytics', 'videos', params]} placeholderComponent={Loading}>
      {({ videoCompletionPercentage }) => (
        <div className="WelcomeVideos">
          <div className="title">{MESSAGES.WELCOME_VIDEOS}</div>
          <div className="services">
            <div className="service-container">
              <div className="service-info">
                <div className="service-name">{MESSAGES.AVERAGE_COMPLETION_RATE}</div>
              </div>
              <div className="service-total">
                {videoCompletionPercentage || 0}
                %
              </div>
            </div>
          </div>
        </div>
      )}
    </Resource>
  )
}

export default WelcomeVideos
