import React from 'react'
import { connect as formikConnect } from 'formik'
import { PencilIcon, ServiceIcon } from '../../icons'
import Choices from '../Choices'

const ADVERTISEMENT_TYPE_CHOICES = [
  {
    icon: ServiceIcon,
    templateName: 'New ad for a local business',
    templateDescription: 'Add a advertisement for yor recommended business and activities',
    templateKey: 'Recommendation',
  },
  {
    icon: ServiceIcon,
    templateName: 'Advertise your services',
    templateDescription: 'Remind your guests about the various services that you offer',
    templateKey: 'Service',
  },
  {
    icon: ServiceIcon,
    templateName: 'Cross advertise your properties',
    templateDescription: 'Show your guests the other properties you manage',
    templateKey: 'Property',
  },
  {
    icon: PencilIcon,
    templateName: 'Custom Ad',
    templateDescription:
      'Show guests advertisements for events, messages, or other ads that are not tied to a business, service , or property',
    templateKey: 'Custom',
  },
]

const AdvertisementTypeChoices = ({ formik: { setFieldValue }, next, setStep }) => {
  const setChoice = index => {
    setFieldValue('type', ADVERTISEMENT_TYPE_CHOICES[index].templateKey)

    if (ADVERTISEMENT_TYPE_CHOICES[index].templateKey === 'Custom') {
      setStep(2)
    } else {
      next()
    }
  }

  return <Choices choices={ADVERTISEMENT_TYPE_CHOICES} setChoice={index => setChoice(index)} />
}

export default formikConnect(AdvertisementTypeChoices)
