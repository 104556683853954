import { Yup } from '../../../forms'
import {
  ApprovalField,
  CheckboxField,
  FeeFields,
  ImageDropzoneField,
  OrderDeadline,
  PhoneField,
  RichTextEditorField,
  SelectPropertiesField,
  SMSField,
  TextareaField,
  TextField,
} from '../../../forms/fields'
import { CheckboxGroup } from '../../../forms/fields/ChoiceGroup'
import DraggableMediaField from '../../../forms/fields/DraggableMediaField'
import { ServicePromoCheckoutIcon, ServicePromoHomepageIcon } from '../../../icons'
import { PAYABLE_FEE_TYPES } from '../../services'
import fadedServicePromoCheckout from '../../../images/FadedServicePromoCheckout.png'
import fadedServicePromoHomePage from '../../../images/FadedServicePromoHomePage.png'
import fadedServicePromoServiceListing from '../../../images/FadedServicePromoListing.png'
import servicePromoCheckout from '../../../images/ServicePromoCheckout.png'
import servicePromoHomePage from '../../../images/ServicePromoHomePage.png'
import servicePromoServiceListing from '../../../images/ServicePromoListing.png'

FeeFields.FIXED = { fixed: 'Fixed' }
FeeFields.HOURLY = { hourly: 'Hourly' }
FeeFields.DAILY = { daily: 'Daily' }
FeeFields.FREE = { free: 'Free' }
FeeFields.CUSTOM = { custom: 'Custom' }

const COMMON_FIELDS = {
  name: {
    label: 'Title',
    name: 'name',
    component: TextField,
    validation: Yup.string()
      .trim()
      .required('required'),
    props: {
      maxLength: 80,
    },
  },
  nickName: {
    label: 'Nickname',
    name: 'nickName',
    component: TextField,
    props: {
      maxLength: 60,
      extraInformation: 'Nickname is for internal use only and is not visible to guests',
    },
  },
  summarySubtitle: {
    label: 'Summary',
    name: 'summarySubtitle',
    component: TextareaField,
    props: {
      placeholder: '(This will appear on the Services list page)',
      maxLength: 180,
      shouldToggle: true,
    },
  },
  iconURL: {
    label: 'Upload',
    sublabel: 'Click image to change',
    name: 'iconURL',
    component: ImageDropzoneField,
    props: {
      desiredSize: { width: 1280, height: 960 },
    },
  },
  draggableMedia: {
    label: 'Add Images / Videos',
    name: 'medias',
    component: DraggableMediaField,
    props: {
      sublabel: 'You can add up to 5 images/videos',
      mediaType: ['image', 'video'],
      accept: 'video/*,image/*,.mkv,.3gp',
      upload: {
        server: '/api/dashboard',
        signingUrlQueryParams: { path: 'uploaded-media/' },
      },
      desiredSize: { width: 1280, height: 960 },
      maxMediaLimit: 5,
      minMediaLimit: 1,
    },
    validation: Yup.array().default([]),
  },
  detailHTML: {
    label: 'Description',
    name: 'detailHTML',
    component: RichTextEditorField,
  },
  orderDeadline: {
    label: 'Order Deadline',
    name: 'orderDeadline',
    component: OrderDeadline,
    props: {
      placeholder: 'Ex: 24',
      formatter: 'hours',
      description: 'The order deadline will be shown on the service detail screen.',
      options: [
        {
          hours: 'hours',
        },
        {
          minutes: 'minutes',
        },
      ],
    },
    validation: Yup.string()
      .trim()
      .required('required'),
  },
  terms: {
    label: 'Terms and Conditions',
    name: 'terms',
    required: ({ values }) => values.requireAcceptTerms,
    validation: {
      requireAcceptTerms: Yup.boolean().default(false),
      terms: Yup.string().when('requireAcceptTerms', {
        is: requireAcceptTerms => requireAcceptTerms,
        then: Yup.string()
          .trim()
          .required('required'),
      }),
    },
    component: [
      {
        component: CheckboxField,
        props: {
          name: 'requireAcceptTerms',
          label: 'Require that Guests agree to terms before completing order.',
        },
      },
      {
        component: RichTextEditorField,
        props: {
          name: 'terms',
          maxLength: 30000,
        },
      },
    ],
  },
  phone: {
    label: 'Customer Service Phone #',
    name: 'phone',
    component: PhoneField,
    validation: Yup.string()
      .phone()
      .required(),
  },
  promote: {
    label: 'Where to Promote',
    name: 'promote',
    component: CheckboxGroup,
    props: {
      options: [
        {
          value: 'serviceListing',
          label: 'Listing',
          head: 'enabled',
          image: {
            enabled: servicePromoServiceListing,
            disabled: fadedServicePromoServiceListing,
          },
          related: [
            {
              name: 'enabled',
              disableOnUnCheck: true,
              disableOnCheck: true,
            },
          ],
        },
        {
          value: 'homepage',
          label: 'Homepage',
          head: 'enabled',
          image: {
            enabled: servicePromoHomePage,
            disabled: fadedServicePromoHomePage,
          },
          related: [
            {
              name: 'enabled',
              disableOnUnCheck: true,
            },
          ],
        },
        {
          value: 'checkout',
          label: 'Check Out',
          head: 'enabled',
          image: {
            enabled: servicePromoCheckout,
            disabled: fadedServicePromoCheckout,
          },
          related: [
            {
              name: 'enabled',
              disableOnUnCheck: true,
            },
          ],
        },
      ],
      row: true,
    },
    validation: Yup.array().default(['serviceListing']),
  },
  propertyIds: {
    label: 'Apply to Properties',
    name: 'propertyIds',
    component: SelectPropertiesField,
    validation: Yup.array()
      .atLeast(1)
      .label('property')
      .default([])
      .required(),
  },
  enabled: {
    label: 'Service Status',
    name: 'enabled',
    validation: { enabled: Yup.boolean().default(true) },
    component: [
      {
        component: CheckboxField,
        props: {
          name: 'enabled',
          label: 'Enable',
          isHead: true,
          relations: [
            {
              name: 'promote',
              onCheck: true,
              onUnCheck: false,
              defaultValue: 'serviceListing',
            },
          ],
        },
      },
    ],
  },
  feeFields: {
    name: 'feeFields',
    label: 'Fee/Rate',
    component: FeeFields,
    required: ({ values }) => values.feeType !== 'free',
    validation: {
      price: Yup.number()
        .nullable()
        .when('feeType', {
          is: feeType => !!PAYABLE_FEE_TYPES.includes(feeType),
          then: Yup.number()
            .required('required')
            .test('is-positive-integer', 'fee must be greater than or equal to $0.50', v => v >= 50),
        }),
      customFeeMessage: Yup.string()
        .nullable()
        .when('feeType', {
          is: feeType => feeType === 'custom',
          then: Yup.string()
            .trim()
            .max(100, '100 characters allowed')
            .required('required'),
        }),
      feeType: Yup.string().required('required'),
      hasPaymentProvider: Yup.boolean().default(false),
    },
  },
  approvalRequests: {
    label: 'Where should request notifications be sent?',
    name: 'approvalRequests',
    component: ApprovalField,
    required: ({ values }) => values.requireApproval === true,
    validation: {
      requireApproval: Yup.boolean().default(false),
      approvalEmail: Yup.string().when('requireApproval', {
        is: true,
        then: schema => schema.atLeastOne(['approvalEmail', 'approvalNumber']),
      }),
      approvalNumber: Yup.string().when('requireApproval', {
        is: true,
        then: schema => schema.atLeastOne(['approvalEmail', 'approvalNumber']),
      }),
    },
  },
  approvalMessage: {
    label: 'Approval Message to Guest',
    name: 'approvalMessage',
    component: SMSField,
    props: {
      maxLength: 120,
      shouldToggle: true,
      approveOrDeny: 'Approved',
    },
  },
  declineMessage: {
    label: 'Decline Message to Guest',
    name: 'declineMessage',
    component: SMSField,
    props: {
      maxLength: 120,
      shouldToggle: true,
      approveOrDeny: 'Declined',
    },
  },
  serviceNote: {
    label: 'Service Note',
    name: 'serviceNote',
    component: TextareaField,
    props: {
      placeholder: '(This will appear on the Services Request Summary page)',
      maxLength: 180,
      shouldToggle: true,
    },
  },
}

export default COMMON_FIELDS
