/* eslint-disable react/display-name */
import React from 'react'
import classNames from 'classnames'
import { PROPERTIES_APPLIED, UPDATED_CELL, SELECT_ROW } from './common'
import { CONTENT_IMAGE, CONTENT_IMAGE_SMALL, PROPERTY_CONTENT_OPTIONS } from './content'

const PROPERTY_INFO_NAME = {
  id: 'name',
  Header: 'Name',
  accessor: ({ name }) => name && name.toLowerCase(),
  Cell: ({ row: { original: customization } }) => {
    return <div className={classNames('bold nowrap link')}>{customization.name}</div>
  },
}

// PROPERTY LEVEL
export const PROPERTY_INFO_COLUMNS = [
  {
    id: 'property-info',
    columns: [
      CONTENT_IMAGE,
      PROPERTY_INFO_NAME,
      {
        id: 'mediaType',
        accessor: 'content.mediaType',
        Header: 'Media Type',
        options: {
          classNames: 'capitalize',
          hideOn: ['mobile'],
        },
      },
      PROPERTIES_APPLIED,
      UPDATED_CELL,
      PROPERTY_CONTENT_OPTIONS,
    ],
  },
]

// PROPERTY LEVEL - SELECT EXISTING
export const PROPERTY_INFO_SELECT_COLUMNS = [
  {
    id: 'property-info',
    columns: [SELECT_ROW, CONTENT_IMAGE_SMALL, PROPERTY_INFO_NAME, PROPERTIES_APPLIED],
  },
]

// CONTENT LEVEL
export const PROPERTY_INFO_CONTENT_COLUMNS = [
  {
    id: 'property-info',
    columns: [
      CONTENT_IMAGE,
      PROPERTY_INFO_NAME,
      {
        id: 'mediaType',
        accessor: 'content.mediaType',
        Header: 'Media Type',
        options: {
          classNames: 'capitalize',
          hideOn: ['mobile'],
        },
      },
      PROPERTIES_APPLIED,
      UPDATED_CELL,
      PROPERTY_CONTENT_OPTIONS,
    ],
  },
]
