import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { HostInput } from '../../components/Host'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'
import FocusRegion from '../../components/FocusRegion'
import { Timezones } from '../../constants'
import { GENERAL_INFO } from '../../constants/forms/content'

// TODO: FIX ME. No more focus region. Add Formik too
const GeneralInfoContent = ({
 property, onSave, dispatch, recordAnalytics,
}) => {
  const [selectedHost, setSelectedHost] = useState(property.hostId)
  const [nickname, setNickname] = useState(property.nickname)
  const [timezone, setTimezone] = useState(property.timezone)

  return (
    <div className="card-content padding-y">
      <div className="form-group row">
        <label htmlFor="nickname" className="col-sm-3 col-form-label col-form-label-sm">
          {GENERAL_INFO.HOST.title}
        </label>
        <div className="col-sm-9">
          <HostInput
            value={selectedHost}
            dispatch={dispatch}
            onChange={hostId => {
              setSelectedHost(hostId)
              recordAnalytics && recordAnalytics()
              onSave({
                ...property,
                hostId,
              })
            }}
          />
        </div>
      </div>
      <FocusRegion
        onBlur={() => {
          recordAnalytics && recordAnalytics()
          onSave({
            ...property,
            nickname,
            config: { ...property.config, nickname: null },
          })
        }}
      >
        <div className="form-group row">
          <label htmlFor="nickname" className="col-sm-3 col-form-label col-form-label-sm">
            {GENERAL_INFO.NICKNAME}
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              id="nickname"
              className="form-control"
              data-cy="input-property-nickname"
              value={nickname || ''}
              onChange={e => setNickname(e.target.value)}
            />
          </div>
        </div>
      </FocusRegion>
      <div className="form-group row">
        <label htmlFor="nickname" className="col-sm-3 col-form-label col-form-label-sm">
          {GENERAL_INFO.TIMEZONE}
        </label>
        <div className="col-sm-9">
          <select
            className="form-control"
            name="timezone"
            id="timezone"
            value={timezone}
            onChange={e => {
              setTimezone(e.target.value)
              recordAnalytics && recordAnalytics()
              onSave({ ...property, timezone: e.target.value })
            }}
          >
            {Timezones.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

const GeneralInfo = ({ match, history, recordAnalytics }) => {
  return (
    <Resource path={['properties', match.params.id]} placeholderComponent={Loading}>
      {(property, { dispatch, onSave, onDelete }) => (
        <div className="Card">
          <GeneralInfoContent
            property={property}
            onSave={onSave}
            dispatch={dispatch}
            onDelete={onDelete}
            history={history}
            recordAnalytics={recordAnalytics}
          />
        </div>
      )}
    </Resource>
  )
}

export default withRouter(GeneralInfo)
