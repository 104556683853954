import React, { useEffect } from 'react'
import { withContent } from './ContentProvider'
import { FormModal } from '../../forms'
import { generateForm } from '../../forms/utils'
import { CONTENT_FIELDS_PATH } from '../../constants/forms/content'

const DefaultContentForm = ({
 content, data, onUpdateItem, fields,
}) => {
  let customization = data.find(customization => customization.isDefault)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    customization = data.find(customization => customization.isDefault)
  }, [data])

  const formFields = fields || content.fields({ saveOnExit: true })

  const customizationForm = generateForm({
    form: formFields.map(field => ({
      ...field,
      name: `${CONTENT_FIELDS_PATH}.${field.name}`,
    })),
    props: customization,
  })

  const onSubmit = values => {
    onUpdateItem({
      id: customization.id,
      ...values,
    })
  }

  return (
    <FormModal
      enableReinitialize
      initialValues={customizationForm.initialValues}
      validationSchema={customizationForm.validationNew}
      onSubmit={onSubmit}
      render={() => customizationForm.component}
    />
  )
}

export default withContent(DefaultContentForm)
