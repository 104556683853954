import React, { Fragment } from 'react'
import moment from 'moment'

import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { PropertyAddress } from '../../components/Property'

import '../../styles/List.scss'

const MessageStatus = ({ status, updatedAt, isRecurring }) => {
  let message = null
  let style = null
  if (status === 'read') {
    message = `${isRecurring ? 'Last read:' : 'Read:'} ${moment(updatedAt).format('dddd, MMMM Do YYYY [at] h:mm:ss a')}`
  }
  if (status === 'unread') {
    message = 'Unread'
    style = { color: '#b8b8b8' }
  }
  return <div style={style}>{message}</div>
}

const PropertyListContainer = ({ message, closeModal, ...props }) => {
  const properties = {}
  props.properties.forEach(property => {
    properties[property.id] = property
  })
  const read = message.properties.filter(({ status }) => status === 'read')
  const subtitle = `Message read by ${read.length} of ${message.properties.length} properties`
  return (
    <Fragment>
      <ModalHeader name="Message Status" verb={false} />
      <ModalBody>
        <p>{subtitle}</p>
        <div className="List">
          {message.properties.map(({ id, status, updatedAt }) => {
            return (
              <div
                className="ListRow"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  padding: '12px 0',
                }}>
                <PropertyAddress {...properties[id]} />
                <MessageStatus
                  isRecurring={message.datetime.type === 'recurring'}
                  status={status}
                  updatedAt={updatedAt}
                />
              </div>
            )
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={closeModal}>
          Done
        </button>
      </ModalFooter>
    </Fragment>
  )
}

export default PropertyListContainer
