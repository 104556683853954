import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import {
  find, get, isUndefined, orderBy,
} from 'lodash'
import classNames from 'classnames'

import { FormModal } from '../../forms'
import { generateForm } from '../../forms/utils'
import { withContent } from '../content/ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { ListCellBadge } from '../list'
import { CONTENT_FIELDS_PATH, EXCLUDE_FROM_CONTENT } from '../../constants/forms/content'

import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { GoToIcon } from '../../icons'
import { MESSAGES } from '../../constants/strings'
import useData from '../../hooks/useData'
import { IconsS3Path } from '../../constants/generic'
import { apiRequest } from '../../reducers/data'
import PropertyDrawer from '../drawer/PropertyDrawer'
import { pluralize } from '../../Utils'

const NEW_CUSTOM_CUSTOMIZATION_KEY = 'new_custom'

const SelectLabel = ({ customization: { name, isDefault } }) => (
  <div className="SelectLabel">
    {name}
    {' '}
    {isDefault && <ListCellBadge label="Default" />}
  </div>
)

const CustomizationForm = ({ handleSubmit, formFields }) => {
  return <form onSubmit={handleSubmit}>{formFields}</form>
}

const ContactHostPicker = ({
  propertyId,
  data,
  content,
  onCreateItem,
  onUpdateItem,
  history,
  totalProperties,
  recordAnalytics,
  dispatch,
}) => {
  const { fields } = content
  const [showDrawer, setDrawer] = useState(false)
  const [selectedCustomizationId, setSelectedCustomizationId] = useState()
  const contactHosts = useData('contact-host')
  const contactHostOptions = contactHosts
    .filter(contact => !!contact.contactName)
    .map(option => ({
      ...option,
      label: option.contactName,
      value: option.contactName,
    }))

  useEffect(() => {
    const currentSet = find(data, ({ propertyIds }) => propertyIds && propertyIds.includes(propertyId))
    if (currentSet && isUndefined(selectedCustomizationId)) {
      setSelectedCustomizationId(currentSet.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  // Create Options
  const globalCustomizations = Object.values(data).filter(({ propertyId }) => !propertyId)
  const sortedCustomizations = orderBy(globalCustomizations, ['isDefault', 'name'], ['desc', 'asc'])

  const options = Object.values(sortedCustomizations).reduce((options, customization) => {
    options.push({
      value: customization.id,
      label: <SelectLabel customization={customization} />,
    })
    return options
  }, [])

  const custom = find(data, customization => customization.propertyId === propertyId)

  const customOption = {
    value: get(custom, 'id', NEW_CUSTOM_CUSTOMIZATION_KEY),
    label: 'Custom for this property...',
  }

  options.push(customOption)

  // Create Form
  const isCustom = selectedCustomizationId === customOption.value
  const customization = find(data, ({ id }) => id === selectedCustomizationId, {})

  const formFields = fields({
    readOnly: !isCustom,
    saveOnExit: isCustom,
    contactHostOptions,
  }).map(field => (EXCLUDE_FROM_CONTENT.includes(field.name)
    ? field
    : {
      ...field,
      name: `${CONTENT_FIELDS_PATH}.${field.name}`,
    }))
  if (customization?.content?.contacts) {
    customization.content.contacts = customization.content.contacts.map(item => {
      if (!item.id) return item
      const contactHost = contactHosts.find(cHost => cHost.id === item.id)
      if (!contactHost) return item
      item.contactName = contactHost.contactName
      item.contactPhone = contactHost.contactPhone
      item.contactEmail = contactHost.contactEmail
      return item
    })
    if (!customization.content.contacts.length) {
      customization.content.contacts[0] = {
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        topics: [],
      }
    }
  }

  const customizationForm = generateForm({
    form: formFields,
    props: customization,
    newValidation: true,
  })

  const openRecipientProperties = (event) => {
    event.stopPropagation()
    setDrawer(true)
  }

  const refreshContactHosts = async () => {
    await dispatch(apiRequest(['contact-host']), 'GET')
  }

  const onSubmit = async values => {
    recordAnalytics && recordAnalytics()
    if (selectedCustomizationId === NEW_CUSTOM_CUSTOMIZATION_KEY) {
      const newCustomization = await onCreateItem({
        ...values,
        propertyIds: [propertyId],
        propertyId,
      })
      // If creating new custom customization at property level, handle switch once created
      if (propertyId) {
        await refreshContactHosts()
        setSelectedCustomizationId(newCustomization.json.id)
      }
    } else {
      await onUpdateItem({
        ...values,
        propertyIds: [propertyId],
        propertyId: isCustom && propertyId,
        id: selectedCustomizationId,
      })
      await refreshContactHosts()
    }
  }

  const customizationPropertyIds = get(customization, 'propertyIds', [])
  const propertiesApplied = customizationPropertyIds.length

  /** This is to add the *General Support* default field in first contact host topics  */
  if (!customization) {
    customizationForm.newInitialValues.content.contacts[0] = {
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      topics: [
        {
          text: 'General Support',
          /** Needs to be changed once icon from figma is uploaded to s3 */
          iconURL: `${IconsS3Path}/icon-propertyinfo-connect.svg`,
          isDefault: true,
        },
      ],
    }
  }

  return (
    <>
      <PropertyDrawer header="Content" drawerData={customization} showDrawer={showDrawer} setDrawer={setDrawer} />
      <div className="ContentPicker Card">
        <div className={classNames('select-wrapper', { custom: isCustom })}>
          <Select
            classNamePrefix="select"
            className="select-list"
            options={options}
            onChange={customization => {
              setSelectedCustomizationId(customization.value)
              if (customization.value !== NEW_CUSTOM_CUSTOMIZATION_KEY) {
                onUpdateItem({ id: `${propertyId}/${customization.value}/switch` })
                recordAnalytics && recordAnalytics()
              }
            }}
            value={selectedCustomizationId && options.filter(option => option.value === selectedCustomizationId)}
          />
          <PopoverMenu>
            <PopoverMenuItem className="properties-applied">
              <div className="meta">{MESSAGES.USED_BY}</div>
              <div className="bold">
                {
                  <div
                    className="link-text"
                    onClick={openRecipientProperties}
                  >
                    {`${propertiesApplied} of ${totalProperties} ${pluralize(totalProperties, 'property')}`}
                  </div>
                }
              </div>
            </PopoverMenuItem>
            {selectedCustomizationId && (
              <PopoverMenuItem
                className={classNames({ disabled: isCustom })}
                onClick={() => (!isCustom
                  ? history.push(`/content/${content.location}?edit=${customization.id}`)
                  : null)}
              >
                <GoToIcon />
                {' '}
                Open This Component
              </PopoverMenuItem>
            )}
            <PopoverMenuItem onClick={() => history.push(`/content/${content.location}?add=${content.type}`)}>
              <GoToIcon />
              {' '}
              {MESSAGES.CREATE_NEW_COMPONENT}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
        <div className="card-content padding-bottom">
          <FormModal
            enableReinitialize
            initialValues={customizationForm.newInitialValues}
            validationSchema={customizationForm.validationNew}
            onSubmit={onSubmit}
            render={_ => <CustomizationForm formFields={customizationForm.component} />}
          />
        </div>
      </div>
    </>
  )
}

export default withRouter(withContent(ContactHostPicker))
