import React from 'react'

const EmailHAU = ({ firstName, email }) => {
  if (!firstName) {
    return <div className="inactive-column">{email}</div>
  }
  return <div>{email}</div>
}

export default EmailHAU
