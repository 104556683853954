import React from 'react'
import classNames from 'classnames'
import { RadioField } from './ChoiceField'
import '../../styles/Fields.scss'
import { useDispatch } from 'react-redux'
import { setOrientation } from '../../reducers/advertisementData'
import { MESSAGES } from '../../constants/strings'

const ChoiceGroup = ({
  fieldComponent, name, options, row,
}) => {
  const Choice = fieldComponent
  const dispatch = useDispatch()
  const changeOrientation = val => {
    dispatch(setOrientation(val))
  }

  return (
    <>
      <div className={classNames('ChoiceGroup ads-view', { 'group-row': row })}>
        {options.map(choice => {
          const { value, label, icon } = choice
          return (
            <Choice key={value} name={name} label={label} value={value} icon={icon} group onSelect={changeOrientation} />
          )
        })}
      </div>
      <p className='short-hint'>{MESSAGES.AD_ORIENTATION_TEXT}</p>
    </>
  )
}

export const AdvertisementChoiceGroup = props => <ChoiceGroup {...props} fieldComponent={RadioField} />
