import React, { useEffect } from 'react'
import { fieldify } from './utils'
import { ReactComponent as GreetingIcon } from '../../images/icons/smiley.svg'
import { ReactComponent as AlertTriangleIcon } from '../../images/icons/alert-alert.svg'
import { ReactComponent as ReminderIcon } from '../../images/icons/alert-reminder.svg'
import { ReactComponent as InformationIcon } from '../../images/icons/alert-info.svg'

const MessageCategoryGroup = fieldify(({ field, form }) => {
  const CATEGORIES = {
    ALERT: { value: 'ALERT', label: 'Alert', priority: 1 },
    REMINDER: { value: 'REMINDER', label: 'Reminder', priority: 2 },
    INFORMATION: { value: 'INFORMATION', label: 'Information', priority: 3 },
    GREETING: { value: 'GREETING', label: 'Greetings', priority: 4 },
  }

  useEffect(() => {
    if (field.value) {
      setCategory(CATEGORIES[field.value])
    } else {
      setCategory('')
    }
  }, [])

  const setCategory = async category => {
    await form.setFieldValue(field.name, category.value)
    await form.setFieldValue('priority', category.priority)
  }

  return (
    <div className="category-options">
      <div>
        <input type="radio" name="category" id="isReminder" checked={field.value === CATEGORIES.REMINDER.value} />
        <label htmlFor="isReminder" onClick={() => setCategory(CATEGORIES.REMINDER)}>
          <span>
            <ReminderIcon />
          </span>
          Reminder
        </label>
      </div>

      <div>
        <input type="radio" name="category" id="isAlert" checked={field.value === CATEGORIES.ALERT.value} />
        <label htmlFor="isAlert" onClick={() => setCategory(CATEGORIES.ALERT)}>
          <span>
            <AlertTriangleIcon />
          </span>
          Alert
        </label>
      </div>

      <div>
        <input type="radio" name="category" id="isInformation" checked={field.value === CATEGORIES.INFORMATION.value} />
        <label htmlFor="isInformation" onClick={() => setCategory(CATEGORIES.INFORMATION)}>
          <span>
            <InformationIcon />
          </span>
          Information
        </label>
      </div>

      <div>
        <input type="radio" name="category" id="isGreeting" checked={field.value === CATEGORIES.GREETING.value} />
        <label htmlFor="isGreeting" onClick={() => setCategory(CATEGORIES.GREETING)}>
          <span>
            <GreetingIcon />
          </span>
          Greetings
        </label>
      </div>
    </div>
  )
})

export default MessageCategoryGroup
