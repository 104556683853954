import React from 'react'
import classNames from 'classnames'

import '../styles/TabHeader.scss'
import TabTitle from './TabTitle'

function TabHeader({
 title, count, steps, extraInfo, children, className,
}) {
  return (
    <>
      <div className={classNames('TabHeader', className)}>
        {title && (
          <div className="title">
            <TabTitle title={title} count={count} steps={steps} />
          </div>
        )}
        {extraInfo && <div className="extra-info">{extraInfo}</div>}
      </div>
      {children}
    </>
  )
}

export default TabHeader
