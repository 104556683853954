import React, { Component, useState } from 'react'
import { Prompt, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'

import Step1 from './PropertyAdd/Step1'
import { apiRequest } from '../reducers/data'
import { Timezones } from '../constants'

import TabHeader from '../components/TabHeader'
import '../styles/PropertyAddContainer.scss'
import { MESSAGES } from '../constants/strings'

// TODO: Change to add property, remove what we don't need. Formik and validation

const InitialState = {
  nickname: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  addressZIP: '',
  addressCountry: '',
  photoURL: null,
  latitude: null,
  longitude: null,
  timezone: Timezones[0].value,
  hostId: null,
  artModeBundleId: null,
  error: null,
  wifi: '',
  type: '',
}

const _PropertyAddContainer = props => {
  // static contextTypes = {
  //   router: () => {},
  // }

  const [state, setState] = useState(InitialState)
  const step = props.match.params.step / 1
  const StepComponent = [Step1][step - 1]

  // eslint-disable-next-line react/sort-comp
  const _onFinish = async () => {
    if (state.addressLine1) {
      const { json, response } = await props.dispatch(apiRequest(['properties'], 'POST', state))
      if (response.status === 200) {
        if (json.id) {
          if (state.type === 'switch' && state.wifi && state.wifi !== 'new_custom') {
            const res = await props.dispatch(
              apiRequest([`/customizations/wifi/${json.id}/${state.wifi}/switch`], 'PUT', {
                id: `${json.id}/${state.wifi}/switch`,
              })
            )
          } else if (state.type === 'new' && state.wifi) {
            const newWifiObject = {
              ...state.wifi,
              propertyId: json.id,
              propertyIds: [json.id],
            }
            const res = await props.dispatch(apiRequest(['/customizations/wifi'], 'POST', newWifiObject))
          } else if (state.type === 'switch' && state.wifi === 'new_custom') {
            const newWifiData =
              state.wifi === 'new_custom'
                ? {
                    content: { wifiFormat: 'password', wifiName: '', wifiPass: '' },
                  }
                : state.wifi
            const newWifiObject = {
              ...newWifiData,
              propertyId: json.id,
              propertyIds: [json.id],
            }
            const res = await props.dispatch(apiRequest(['/customizations/wifi'], 'POST', newWifiObject))
          }
        }
        setState(InitialState)
        props.history.push('/properties')
      } else {
        setState({ ...state, error: json.error })
      }
    }
  }

  const onWifiUpdation = (value, type) => {
    setState({ ...state, type, wifi: value })
  }

  // Debounce to keep people from clicking more than once in a five second period
  const onFinish = debounce(_onFinish, 5000, { leading: true, trailing: false })

  const onCopyChange = (format, text) => {
    setState({ ...state, config: { ...state.config, [format]: text } })
  }

  return (
    <div className="PropertyAddContainer">
      <Prompt
        message={location => {
          const dirty = JSON.stringify(state) !== JSON.stringify(InitialState)
          const movingAway = !/\/properties\/.+/gi.test(location.pathname)
          return dirty && movingAway ? MESSAGES.ARE_YOU_SURE_TO_LEAVE : true
        }}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <TabHeader title="Add Property" />
          </div>
        </div>
        <StepComponent
          {...props}
          {...state}
          onChange={_state => {
            setState({ ...state, ..._state })
          }}
          onFinish={onFinish}
          onCopyChange={onCopyChange}
          onWifiUpdation={onWifiUpdation}
        />
      </div>
    </div>
  )
}

const PropertyAddContainer = connect(() => {
  return {}
})(_PropertyAddContainer)

export default withRouter(PropertyAddContainer)
