import React from 'react'
import { Input } from 'reactstrap'

const NumberField = ({
 value, allowNegative, allowDecimal, onChange, ...props
}) => {
  const handleKeyDown = e => {
    const restricted = []
    !allowNegative && restricted.push('Minus')
    !allowDecimal && restricted.push('Period')
    if (restricted.includes(e.code)) {
      e.preventDefault()
    }
  }

  return (
    <Input required value={value} type="number" step={1} onKeyDown={handleKeyDown} onChange={onChange} {...props} />
  )
}

export default NumberField

NumberField.defaultProps = {
  allowNegative: false,
  allowDecimal: false,
}
