import { COMMONS_FIELDS } from './content'
import { TextField } from '../../forms/fields'

export const SLIDESHOW = {
  type: 'slideshow',
  title: 'Slideshow',
  description:
    'A screensaver that will provide a luxurious ambience to your property while the GuestView Guide is not actively being used. Add a Title and Description to provide the guest with context about the image when the guest touches the display or motion is detected near the display.',
}

const SLIDESHOW_STEPS = ({ readOnly, saveOnExit, dispatch }) => {
  const steps = [
    [
      {
        ...COMMONS_FIELDS.name,
      },

      {
        label: 'Slides Test',
        name: 'slides',
        component: TextField,
        props: {
          placeholder: 'Slides test',
        },
      },
    ],
    [
      COMMONS_FIELDS.propertyIds({
        isDefault: false,
        isAllowedMultiple: true,
      }),
    ],
  ]

  return steps
}

export const SLIDES = {
  location: 'slideshow-new',
  type: 'slideshow',
  title: 'Slideshow',
  addNew: '+ Add Collection',
  item: 'Slideshow',
  verb: {
    create: 'Add a',
    edit: 'Edit',
  },
  emptyMessage: 'You have not yet added any slideshows',
  steps: SLIDESHOW_STEPS,
}
