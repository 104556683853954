import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'

const TooltipComponent = ({ effect, id, children, type, place, ...props }) => {
  return (
    <>
      <ReactTooltip place={place} type={type} effect={effect} id={id} {...props}>
        {children}
      </ReactTooltip>
    </>
  )
}

TooltipComponent.defaultProps = {
  effect: "solid",
  type: "light",
  place: "top",
  id: ""
}

export default TooltipComponent
