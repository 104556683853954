import { get } from 'lodash'
import React from 'react'
import { Carousel } from 'react-bootstrap'
import MediaDisplay from '../../components/MediaDisplay'

const IMAGE_SHIFT_INTERVAL = 5 * 1000

const ImageCarouselPreview = ({ formik: { values }, name, placeholder }) => {
  const value = get(values, name, placeholder)

  return (
    <Carousel
      className={`ImagePreviewCarousel ${value && value.length === 1 && 'carousel-indicators-none'}`}
      controls={false}
      interval={IMAGE_SHIFT_INTERVAL}
    >
      {value && value.length ? (
        value.map((media, index) => (
          <Carousel.Item key={`imagePreview${index}`}>
            <MediaDisplay sound video item={media} className="d-block w-100 bg-light" />
          </Carousel.Item>
        ))
      ) : (
        <Carousel.Item>
          <MediaDisplay sound video item={{ mediaUrl: placeholder }} className="d-block w-100 bg-light" />
        </Carousel.Item>
      )}
    </Carousel>
  )
}

export default ImageCarouselPreview
