import React from 'react'
import { get, includes, some } from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Modal } from 'reactstrap'
import '../styles/Modal.scss'

import { withRouter } from 'react-router'
import { closeModal } from '../reducers/modals'

const _ModalContainer = props => {
  const toggle = () => {
    const {
      options: { closeOnEscape },
    } = props
    if (closeOnEscape === true) {
      return close
    }
    return null
  }

  const close = () => {
    const {
      dispatch,
      options: { onClose },
    } = props
    onClose && onClose()
    dispatch(closeModal())
  }

  const {
    Component, componentProps, dispatch, isMobile, options, location,
  } = props
  const size = get(options, 'size', 'm')
  const customClass = get(options, 'customClass', '')
  return (
    <Modal
      isOpen={!!Component}
      toggle={toggle()}
      className={classNames('ModalContainer', size, {
        'm-modal': isMobile,
        l: some(['slideshow'], val => includes(location.pathname, val)),
        [customClass]: customClass,
      })}
      fade={!isMobile}
    >
      {Component && <Component dispatch={dispatch} closeModal={close} {...componentProps} />}
    </Modal>
  )
}

_ModalContainer.defaultProps = {
  options: {},
}

const ModalContainer = connect(({ modals }) => {
  return {
    Component: modals.active ? require(`./modals/${modals.active}`).default : null,
    componentProps: modals.props,
    options: modals.options || {},
  }
})(_ModalContainer)

export default withRouter(ModalContainer)
