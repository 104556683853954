const winston = require('winston')

const { format, transports } = winston

const logLevels = {
  error: 0,
  http: 1,
  info: 2,
  debug: 3,
  other: 4,
}

const logFormat = format.printf(
  info => `\n \n
  ***********************************************************************************************************
  [${info.level}] <=> ${info.message}
  ***********************************************************************************************************
  \n \n`,
)

const winstonLogger = winston.createLogger({
  level: process.env.REACT_APP_LOG_LEVEL,
  levels: logLevels,
  format: format.combine(format.metadata({ fillExcept: ['message', 'level'] })),
  transports: [
    new transports.Console({
      format: format.combine(format.colorize(), logFormat),
    }),
  ],
  exitOnError: false,
})

export default winstonLogger
