import Storage from '../Storage'

// Action Types

export const SET_ACTIVE_PROPERTY_VIEW = 'SET_ACTIVE_PROPERTY_VIEW'
export const TOGGLE_NAVIGATION_VIEW = 'TOGGLE_NAVIGATION_VIEW'

// Action Creators

const storage = new Storage('ui')

export function changePropertyView(view) {
  return {
    type: SET_ACTIVE_PROPERTY_VIEW,
    activePropertyView: view,
  }
}

export function toggleNavigationView() {
  return {
    type: TOGGLE_NAVIGATION_VIEW
  }
}

// Initial State

const initialState = {
  activePropertyView: storage.get('propertyView', 'gridView'),
  showNavigation: storage.get('showNavigation', true),
}

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PROPERTY_VIEW:
      storage.set('propertyView', action.activePropertyView)
      return {
        ...state,
        activePropertyView: action.activePropertyView,
      }
    case TOGGLE_NAVIGATION_VIEW:
      storage.set('showNavigation', !state.showNavigation)
      return {
        ...state,
        showNavigation: !state.showNavigation,
      }
    default:
      return state
  }
}
