import React from 'react'
import classNames from 'classnames'
import '../styles/Review.scss'
import moment from 'moment'
import { get } from 'lodash'
import SmileyForFeedback from '../containers/PropertyDetail/SmileyForFeedback'

function Review({
 rating, createdAt, name, extra,
}) {
  return (
    <div
      className={classNames('Review', {
        positive: rating > 3,
        negative: rating < 4,
      })}
    >
      <div className="smiley">{SmileyForFeedback(rating)}</div>
      <div className="review-container">
        <div className="review-details-container">
          <div className="review-name">{name}</div>
          <div className="review-date">{moment(createdAt).format('L')}</div>
        </div>
        <div className="keywords-container">
          {get(extra, 'keywords', []).map(keyword => (
            <div className="review-keyword" key={keyword}>
              {keyword}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Review
