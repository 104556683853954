import React from 'react'
import { template } from 'lodash'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'

import { apiRequest } from '../../reducers/data'
import { openModalWithContainer } from '../../reducers/modals'

const createMarkup = (message, me) => {
  let __html = 'Sorry, we couldn\'t display announcement\'s message'
  try {
    const compiled = template(message)
    __html = compiled({ me })
    // eslint-disable-next-line no-empty
  } catch (error) { }
  return { __html }
}

const Announcement = ({ message, me }) => {
  // eslint-disable-next-line react/no-danger
  return <p dangerouslySetInnerHTML={createMarkup(message, me)} />
}

const AnnouncementsModal = ({
  dispatch,
  closeModal,
  announcements = [],
  me,
  onRefresh,
  reAuthorizePMS,
  getConfigs,
}) => {
  const close = () => {
    if (reAuthorizePMS) {
      closeModal()
      dispatch(openModalWithContainer('ImportPropertiesContainer', { reAuthorizePMS }))
      return
    }
    const data = {
      readAnnouncementAt: 'now',
    }
    dispatch(apiRequest(['me'], 'PUT', data))
      .then(onRefresh)
      .then(closeModal)
  }

  const { id } = announcements[0] || {}
  let { message, title } = announcements[0] || {}
  if (reAuthorizePMS && getConfigs && getConfigs.ownerRezReAuthorizeMessage) {
    title = 'Re-Authorize OwnerRez'
    message = getConfigs.ownerRezReAuthorizeMessage
  }

  return (
    <div>
      <ModalHeader hideClose>{title}</ModalHeader>
      <ModalBody>
        <Announcement key={id} me={me} message={message} reAuthorizePMS={reAuthorizePMS} />
      </ModalBody>
      <ModalFooter>
        <button onClick={close} className="btn btn-primary">
          {reAuthorizePMS ? 'Proceed' : 'Ok, got it!'}
        </button>
      </ModalFooter>
    </div>
  )
}

export default AnnouncementsModal
