import React from 'react'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
import Tabs from '../components/Tabs'
import {
  PropertyDetails,
  Recommendations,
  CheckInOut,
  WelcomeScreenAndVideo,
  AmenitiesAndSupplies,
} from '../components/content'
import TabHeader from '../components/TabHeader'

import '../styles/ContentContainer.scss'
import { Loading } from '../components/PageStates'
import Resource from '../Resource'
import { PROPERTY_TYPES } from '../constants/forms/propertyInfo'
import { thirdPartyAnalytics } from '../services'
import { EVENTS } from '../constants/thirdPartyAnalytics'

const CONTENT_TABS = [
  {
    slug: '',
    component: PropertyDetails,
    label: 'Property Details',
  },
  {
    slug: 'check-in-out',
    component: CheckInOut,
    label: 'Check In / Out',
  },
  {
    slug: 'amenities-and-supplies',
    component: AmenitiesAndSupplies,
    label: 'Property Info',
  },
  {
    slug: 'recommendations',
    component: Recommendations,
    label: 'Recommendations',
  },
  {
    slug: 'welcome-screen',
    component: WelcomeScreenAndVideo,
    label: 'Welcome Screen / Videos',
  },
]

const ContentContainer = props => {
  const { me: userInfo } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }

  const recordAnalytics = (section, properties) => {
    let updatedProperties = { ...analyticsProperties }
    if (isObject(properties)) updatedProperties = { ...updatedProperties, ...properties, section }
    thirdPartyAnalytics.track(EVENTS.CONTENT, updatedProperties)
  }

  return (
    <div className="ContentContainer">
      <TabHeader title="Multi-Property Content" />
      <Resource collection path={['properties', `?type=${PROPERTY_TYPES.current}`]} placeholderComponent={Loading}>
        {properties => (
          <Tabs
            path="content"
            tabs={CONTENT_TABS}
            recordAnalytics={recordAnalytics}
            properties={properties}
            {...props}
          />
        )}
      </Resource>
    </div>
  )
}

export default ContentContainer
