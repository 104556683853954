import React, { useState, useEffect } from 'react'
import '../../styles/preferences.scss'
import '../../styles/Card.scss'
import '../../styles/ToggleSwitchCustom.scss'
import { connect } from 'react-redux'
import Resource from '../../Resource'
import { Loading } from '../PageStates'
import { apiRequest } from '../../reducers/data'
import { SECTIONS, SUB_SECTIONS, ACTIONS } from '../../constants/thirdPartyAnalytics'

import { MESSAGES } from '../../constants/strings'

const Preferences = ({ me, dispatch, recordAnalytics, onSaveMe }) => {
  const { organization } = me
  const reviewReminderDefaultMsg = `Thanks again for staying with ${organization.name}. We hope you arrived home safely. Please take a moment to leave us a review and share your experience with others.`
  const [preferenceMessage, setpreferenceMessage] = useState('')
  const [reviewReminderStatus, setreviewReminderStatus] = useState(false)
  const [feedBack, setfeedBack] = useState('4star')
  const [errors, setErrors] = useState({})
  const { yelpRecommendations } = organization

  const fetchReviewReminder = async () => {
    const response = await dispatch(apiRequest(['organizations/review_reminder'], 'GET'))
    if (response && response.json) {
      const results = response.json
      setreviewReminderStatus(results.status)
      setfeedBack(results.feedback || '4star')
      setpreferenceMessage(results.message || reviewReminderDefaultMsg)
    }
  }

  useEffect(() => {
    fetchReviewReminder()
    return function cleanup() {
      // Set errors to initial state
      setErrors({})
    }
  }, [])

  return (
    <div>
      <Resource collection path={['/organizations/review_reminder']} placeholderComponent={Loading}>
        {(results, { onUpdateItem }) => {
          const switchPreferenceUpdate = (value, mode) => {
            // Restrict saved in case of an error
            if (Object.keys(errors).length > 0) return

            if (mode === 'on/off') {
              setreviewReminderStatus(value)
              recordAnalytics({
                subSection: SUB_SECTIONS.REVIEW_REMINDER_CHECKOUT,
                action: ACTIONS.UPDATE,
                mode: 'on/off',
                value,
              })
              onUpdateItem({
                status: value ? 1 : 0,
                feedback: feedBack,
                message: preferenceMessage,
              })
            } else if (mode === 'rating') {
              value === 1 ? setfeedBack('4star') : setfeedBack('5star')
              onUpdateItem({
                status: reviewReminderStatus,
                feedback: value === 1 ? '4star' : '5star',
                message: preferenceMessage,
              })
              recordAnalytics({
                subSection: SUB_SECTIONS.REVIEW_REMINDER_VALUE,
                action: ACTIONS.UPDATE,
                mode: 'rating',
                value: value === 1 ? '4 star or higher' : '5 star only',
              })
            } else if (mode == 'message') {
              setErrors({})
              onUpdateItem({
                status: reviewReminderStatus,
                feedback: feedBack,
                message: value,
              })
              recordAnalytics({
                subSection: SUB_SECTIONS.REVIEW_REMINDER_TEXT,
                action: ACTIONS.UPDATE,
                mode: 'message',
                value,
              })
            }
          }

          return (
            <div className="preferences">
              <div className="Card">
                <div className="card-content padding-y">
                  <div className="reminder-title">
                    <h4>
                      {MESSAGES.REVIEW_REMINDER}
                      <span>{MESSAGES.SETUP_TEXT_MESSAGES}</span>
                    </h4>
                    <div className="preferenceState">
                      <div className="d-flex justify-content-around align-items-center">
                        <span className="mr-2"> {reviewReminderStatus ? 'ON' : 'OFF'} </span>
                        <label className="switch mb-0">
                          <input
                            type="checkbox"
                            onChange={e => switchPreferenceUpdate(e.target.checked, 'on/off')}
                            checked={reviewReminderStatus}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <p className="mb-2">{MESSAGES.REVIEW_REMINDER_APPEARANCE}</p>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reviewreminderOptions"
                        id="inlineRadio1"
                        onChange={() => switchPreferenceUpdate(1, 'rating')}
                        checked={feedBack === '4star'}
                        disabled={!reviewReminderStatus}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        4 or 5
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reviewreminderOptions"
                        id="inlineRadio2"
                        value="option2"
                        onChange={() => switchPreferenceUpdate(2, 'rating')}
                        checked={feedBack === '5star'}
                        disabled={!reviewReminderStatus}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        5 only
                      </label>
                    </div>
                  </div>
                  <p className="mb-2 mt-3">{MESSAGES.TEXT_MESSAGES_ON_GUEST_PHONE}</p>
                  <div>
                    <textarea
                      disabled={!reviewReminderStatus}
                      value={preferenceMessage}
                      onBlur={() => {
                        if (!preferenceMessage.trim()) {
                          setErrors({ ...errors, preferenceMessageError: MESSAGES.MESSAGE_FIELD_REQD })
                        } else {
                          switchPreferenceUpdate(preferenceMessage, 'message')
                        }
                      }}
                      onChange={e => {
                        setErrors({})
                        setpreferenceMessage(e.target.value)
                      }}
                    />
                    {errors && errors.preferenceMessageError && (
                      <p style={{ color: 'red', marginTop: 5 }}>{errors.preferenceMessageError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Resource>
      <div className="mt-4 preferences">
        <div className="Card">
          <div className="card-content padding-y">
            <div className="reminder-title">
              <h4>{MESSAGES.YELP_RECOMMENDATIONS}</h4>
              <div className="preferenceState">
                <div className="d-flex justify-content-around align-items-center">
                  <span className="mr-2"> {yelpRecommendations ? 'ON' : 'OFF'} </span>
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      onChange={e =>
                        onSaveMe({ organization: { id: organization.id, yelpRecommendations: !yelpRecommendations } })
                      }
                      checked={yelpRecommendations}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
            </div>
            <p className="mb-2">{MESSAGES.SHOW_YELP_RESTAURANTS}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(Preferences)
