import React, { useEffect, useState } from 'react'
import { isEqual, pick } from 'lodash'

import FocusRegion from '../FocusRegion'
import DefaultSlideshowIcon from '../../images/icon-art-collection-default.png'
import { MESSAGES } from '../../constants/strings'

function SlideshowItemEdit({
  selectedIndex, isCustom, src, onSaveSlideshow, ...props
}) {
  const maxLength = 140
  const FIELDS = ['title', 'description', 'enablePromo', 'textmessage', 'smspromourl']
  const [_fields, setFields] = useState({})
  const [image, setImage] = useState(new Image())

  useEffect(() => {
    const values = { ..._fields }
    FIELDS.forEach(field => { values[field] = props.image[field] })
    setFields(values)
  }, [])

  useEffect(() => {
    if (src) {
      const img = image
      img.src = src
      setImage(img)
    }
  }, [src])

  useEffect(() => {
    if (Object.keys(_fields).length) {
      if (props.image.enablePromo !== _fields.enablePromo) {
        onBlur()
      }
    }
  }, [_fields.enablePromo])

  const onChange = (key, value) => {
    const values = { ..._fields }
    values[key] = value
    setFields(values)
  }

  const onBlur = () => {
    if (!isEqual(pick(_fields, FIELDS), pick(image, FIELDS))) {
      onSaveSlideshow({
        ...props.image,
        ..._fields,
      })
    }
  }

  return (
    <div className="section-large-preview">
      <div>
        {src && image.complete && (image.width < 1080 || image.height < 1920) && (
          <div className="large-image-alert">
            <i className="necicon-alert red-alert" />
            <span>{MESSAGES.LOW_RESOLUTION_IMAGE}</span>
          </div>
        )}
        <div
          className="large-image-preview"
          style={{
            backgroundImage: `url(${src || DefaultSlideshowIcon})`,
            backgroundSize: src ? 'cover' : null,
            borderColor: !src ? '#ccc' : null,
          }}
        />
      </div>
      {!!src && (
        <div className="image-fields pr-0">
          <div>
            <div className="tiny-header form">{MESSAGES.TITLE}</div>
            <FocusRegion onBlur={onBlur}>
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                maxLength="50"
                onChange={e => onChange('title', e.target.value)}
                disabled={!isCustom}
                value={_fields.title}
                data-cy="artwork-title"
              />
            </FocusRegion>

            <div className="tiny-header form">{MESSAGES.DESCRIPTION}</div>
            <FocusRegion onBlur={onBlur}>
              <textarea
                className="form-control"
                placeholder="Add a Description"
                maxLength="650"
                onChange={e => onChange('description', e.target.value)}
                disabled={!isCustom}
                value={_fields.description}
                data-cy="artwork-description"
              />
            </FocusRegion>

            {isCustom && (
              <div className="promo-fields">
                <div className="promo-fields-header">
                  <label>
                    <input
                      className="promo-form-checkbox"
                      type="checkbox"
                      onClick={evt => {
                        onChange('enablePromo', evt.target.checked)
                      }}
                      id="enableArtPromo"
                      checked={_fields.enablePromo}
                      value={selectedIndex}
                      data-cy="artwork-promo-checkbox"
                    />
                    {MESSAGES.SLIDESHOW_CUSTOM}
                  </label>
                  {/* _fields.enablePromo && (
                    <Tooltip target="PromoFormCheckbox">
                      <div className="promo-instructions">{MESSAGES.PROMO_INSTRUCTIONS}</div>
                    </Tooltip>
                  ) */}
                </div>
                {_fields.enablePromo && (
                  <div>
                    <div className="tiny-header form">
                      Message for text (limit
                      {maxLength} char.)
                    </div>
                    <FocusRegion onBlur={onBlur}>
                      <textarea
                        className="form-control"
                        placeholder="Add your text message"
                        maxLength={maxLength}
                        value={_fields.textmessage ? decodeURIComponent(_fields.textmessage) : null}
                        onChange={evt =>
                          onChange('textmessage', encodeURIComponent(evt.target.value.substr(0, maxLength)))
                        }
                        style={{ marginBottom: '5px' }}
                        data-cy="artwork-promo-text"
                      />
                    </FocusRegion>
                    <p style={{ float: 'right' }}>
                      {_fields.textmessage && `${_fields.textmessage.length} / ${maxLength}`}
                    </p>

                    <div className="tiny-header form" style={{ marginTop: '10px' }}>
                      {MESSAGES.PROMOTE_LINK_TO_TEXT}
                    </div>
                    <FocusRegion onBlur={onBlur}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add an SMS promo URL"
                        onChange={evt => onChange('smspromourl', encodeURIComponent(evt.target.value))}
                        value={_fields.smspromourl ? decodeURIComponent(_fields.smspromourl) : null}
                        data-cy="artwork-promo-url"
                      />
                    </FocusRegion>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

SlideshowItemEdit.defaultProps = {
  image: {},
}

export default SlideshowItemEdit
