import React, { useEffect } from 'react'
import { connect } from 'formik'
import '../../styles/Fields.scss'
import moment from 'moment'
import { isNull, isUndefined } from 'lodash'
import DateTime from 'react-datetime'

const ToggleComponent = ({
 label, value, setValue, name,
}) => {
  return (
    <div className="preferenceState ads-switches" style={{ width: '163px' }}>
      <div className="d-flex justify-content-start align-items-center ">
        <label className="switch mb-0">
          <input
            type="checkbox"
            checked={value}
            onChange={async e => {
              setValue(name, e.target.checked)
              if (e.target.checked) {
                if (name === 'status') {
                  setValue('schedule', false)
                  setValue('schedule_to', null)
                  setValue('schedule_from', null)
                } else {
                  setValue('status', false)
                }
              } else {
                setValue('schedule_to', null)
                setValue('schedule_from', null)
              }
            }}
          />
          <span className="slider round" />
        </label>
        <span>
          {' '}
          {label}
          {' '}
        </span>
      </div>
    </div>
  )
}

const ToggleGroup = ({ formik: { values, setFieldValue }, ...props }) => {
  const {
 status, schedule_to, schedule_from, schedule,
} = values
  useEffect(() => {
    if (status) {
      setFieldValue('schedule', false)
    } else if (schedule) {
      setFieldValue('status', false)
    }
  }, [status, schedule, schedule_to, schedule_from, setFieldValue])

  useEffect(() => {
    !schedule_to && setFieldValue('schedule_to', null)
    !schedule_from && setFieldValue('schedule_from', null)
    if (!status) {
      setFieldValue('status', false)
      if (schedule_from || schedule_to) {
        setFieldValue('schedule', true)
      }
    }
  }, [schedule_from, schedule_to, setFieldValue, status])

  const toRequired = isNull(schedule_from) || isUndefined(schedule_from)
  const fromRequired = isNull(schedule_to) || isUndefined(schedule_to)
  return (
    <div className="d-flex flex-column justify-content-start toggle-container enable-and-schedule">
      <div className="d-flex mb-4 pb-2 schedule-toggle-container">
        <ToggleComponent value={status} label="Enable Now" setValue={setFieldValue} name="status" />
        <ToggleComponent value={schedule} label="Schedule" setValue={setFieldValue} name="schedule" />
      </div>
      {schedule && (
        <div className="d-flex">
          <div className="d-flex flex-column date-field">
            <label htmlFor="startDate">Start Date</label>
            <DateTime
              onChange={date => {
                date
                  ? setFieldValue('schedule_from', moment(date).format('YYYY-MM-DD'))
                  : setFieldValue('schedule_from', null)
              }}
              inputProps={{
                placeholder: 'YYYY-MM-DD',
                disabled: !schedule,
                required: schedule ? fromRequired : false,
              }}
              viewMode="days"
              isValidDate={date => {
                return schedule_to
                  ? date.isAfter(moment().subtract(1, 'day'))
                      && (date.isBefore(moment(schedule_to)) || date.isSame(moment(schedule_to)))
                  : date.isAfter(moment().subtract(1, 'day'))
              }}
              value={schedule_from}
              timeFormat={false}
              closeOnSelect
            />
          </div>
          <div className="d-flex flex-column date-field" style={{ marginLeft: '10px' }}>
            <label htmlFor="endDate">End Date</label>
            <DateTime
              onChange={date => {
                date
                  ? setFieldValue('schedule_to', moment(date).format('YYYY-MM-DD'))
                  : setFieldValue('schedule_to', null)
              }}
              inputProps={{
                placeholder: 'YYYY-MM-DD',
                disabled: !schedule,
                required: schedule ? toRequired : false,
              }}
              viewMode="days"
              isValidDate={date => {
                return schedule_from
                  ? (date.isAfter(moment(schedule_from)) || date.isSame(moment(schedule_from)))
                      && date.isAfter(moment().subtract(1, 'day'))
                  : date.isAfter(moment().subtract(1, 'day'))
              }}
              value={schedule_to}
              timeFormat={false}
              closeOnSelect
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(ToggleGroup)
