import React from 'react'

const JoinedHAU = ({ firstName, date }) => {
  if (!firstName) {
    return <div className="inactive-column">Invited</div>
  }
  return <div title={date.format('LLLL')}>{date.format('LLL')}</div>
}

export default JoinedHAU
