import React from 'react'
import Icon from './Icon'

function AlertIcon(props) {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <defs>
        <path
          d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
          id="a"
        />
        <path id="c" d="M0 0h50v50H0z" />
      </defs>
      <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <use fill="#000" xlinkHref="#c" transform="translate(-13 -13)" />
        </g>
      </g>
    </Icon>
  )
}

export default AlertIcon
