import React, { Fragment } from 'react'
import { connect } from 'formik'
import { isFinite } from 'lodash'
import { TextField } from '.'

import '../../styles/Fields.scss'

const PseudoSplitField = ({ length, clearAll }) => {
  const outputItems = []

  for (let char = 0; char < length; char++) {
    outputItems.push(<div key={char} className="char" />)
  }

  return (
    <div className="pseudo-split-field">
      {outputItems}
      <div className="clear link" onClick={clearAll}>
        Clear
      </div>
    </div>
  )
}

const SplitField = ({ length, name, formik: { setFieldValue } }) => {
  const handleChange = e => {
    const newValue = e.target.value
    const isValidNumber = isFinite(parseInt(newValue, 10))
    if (newValue === '' || isValidNumber) {
      setFieldValue(name, newValue)
    }
  }

  const clearAll = () => {
    setFieldValue(name, '')
  }

  return (
    <Fragment>
      <PseudoSplitField length={length} clearAll={clearAll} />
      <TextField className="split-input" name={name} type="text" maxLength={length} onChange={e => handleChange(e)} />
    </Fragment>
  )
}

export default connect(SplitField)
