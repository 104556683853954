import React from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import { openModalWithContainer } from '../../reducers/modals'
import { CheckIcon } from '../../icons'
import PaymentIcon from './PaymentIcon'
import '../../styles/StripeButton.scss'
import '../../styles/PropertyHeader.scss'
import { ListActions, AddButton } from '../list'
import { thirdPartyAnalytics } from '../../services'
import { EVENTS } from '../../constants/thirdPartyAnalytics'

import { MESSAGES } from '../../constants/strings'

const PaymentProviderButton = ({ type, me, analyticsProperties }) => {
  if (type === 'STRIPE') {
    const revenueShare = get(me, 'organization.revenueShare', '')
    return (
      <div>
        <Link to="/settings" style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
          <CheckIcon style={{ marginRight: '8px' }} />
          {' '}
          {MESSAGES.STRIPE_PAYMENT_ON}
          <PaymentIcon style={{ marginLeft: '8px' }} />
        </Link>
        {revenueShare !== 100 && (
          <div className="text-right">
            <p>
              <small>
                {revenueShare}
                /
                {100 - revenueShare}
                {' '}
                {MESSAGES.REVENUE_SHARE_PLAN}
              </small>
            </p>
          </div>
        )}
      </div>
    )
  }
  const recordAnalytics = e => {
    e.preventDefault()
    window.open('/payments/auth/stripe', '_blank')
    thirdPartyAnalytics.track(EVENTS.STRIPE, analyticsProperties)
  }
  return (
    <div className="PaymentProviderButton">
      <div>{MESSAGES.WANT_TO_ACCEPT_PAYMENTS}</div>
      <div onClick={recordAnalytics}>
        <a className="stripe-connect" href="#">
          <span>{MESSAGES.CONNECT_WITH_STRIPE}</span>
        </a>
      </div>
    </div>
  )
}
const ServiceContainerHeader = ({
 dispatch, onCreateItem, paymentProvider, me, analyticsProperties,
}) => {
  return (
    <>
      {/* {has9010Split && (
        <Alert color="info">You are under our 90/10 revenue split for services purchased through Stripe.</Alert>
      )} */}
      <ListActions>
        <div className="wrapper-left">
          <AddButton
            item="Service"
            onClick={() => dispatch(
                openModalWithContainer(
                  'ServiceContainer',
                  {
                    onSave: onCreateItem,
                    me,
                    paymentProviderId: paymentProvider.pk,
                    service: {},
                  },
                  {
                    size: 'l',
                  },
                ),
              )}
            data-cy="btn-add-service"
          />
        </div>
        <div className="wrapper-right">
          <PaymentProviderButton analyticsProperties={analyticsProperties} type={paymentProvider.type} me={me} />
        </div>
      </ListActions>
    </>
  )
}
export default ServiceContainerHeader
