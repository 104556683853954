import React, { useEffect, useState } from 'react'
import { Accordion as AccordionComponent, Card, useAccordionButton } from 'react-bootstrap'
import { FaCaretLeft, FaCaretDown } from 'react-icons/fa'

// const AccordionHead = ({ title, subtitle, opened, id }) => {
//   return (
//     <AccordionComponent.Toggle as="div" eventKey={id}>
//       <div className="AccordionHead">
//         <h4>{title}</h4>
//         <div eventKey={id}>
//           <small>{subtitle}</small>
//           {opened ? <FaCaretDown /> : <FaCaretLeft />}
//         </div>
//       </div>
//     </AccordionComponent.Toggle>
//   )
// }

function CustomToggle({
 eventKey, title, subtitle, opened, id, setOpened, hideArrow = false,
}) {
  const decoratedOnClick = useAccordionButton(eventKey)

  return (
    <div
      className="AccordionHead"
      onClick={() => {
        // If the arrow on accordion header is hided, then restrict the click event
        if (hideArrow) return
        setOpened(!opened)
        decoratedOnClick()
      }}
    >
      <h4>{title}</h4>
      {!hideArrow && (
        <div eventKey={id}>
          <small>{subtitle}</small>
          {opened ? <FaCaretDown /> : <FaCaretLeft />}
        </div>
      )}
    </div>
  )
}

const Accordion = ({
 children, className, id, ...props
}) => {
  const [opened, setOpened] = useState(true)

  useEffect(() => {
    setOpened(true)
  }, [id])

  return (
    <AccordionComponent key={id} defaultActiveKey={id}>
      {/* <AccordionHead {...props} opened={opened} id={id} /> */}
      <CustomToggle eventKey={id} {...props} opened={opened} setOpened={setOpened} id={id} />
      <AccordionComponent.Collapse eventKey={id}>
        <Card.Body className={className}>{children}</Card.Body>
      </AccordionComponent.Collapse>
    </AccordionComponent>
  )
}

export default Accordion
