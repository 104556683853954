import React from 'react'
import { get } from 'lodash'
import { FormGroup } from '.'
import GenerateFieldArray from './GenerateFieldArray'

const FormGroupWrapper = ({ size, group, children }) => (
  <FormGroup
    label={group.label}
    sublabel={group.sublabel}
    note={group.note}
    rowClassName={group.rowClassName}
    fieldClassName={group.fieldClassName}
    htmlFor={group.name}
    required={group.required}
    size={size}
    vertical={group.vertical}
    className={group.className}
    additionalFieldClass={group.additionalFieldClass}
    labelTooltipText={group.labelTooltipText}
  >
    {children}
  </FormGroup>
)

const Fields = ({ group, next }) => {
  return group.fields.map((field, index) => <Field key={`${index}${field.props.name}`} field={field} next={next} />)
}

const Field = ({ field, next }) => {
  const InputField = field.component

  return <InputField {...field.component} {...field.props} next={next} />
}

const GenerateForm = ({ formGroups, next }) => {
  return formGroups.map((group, index) => {
    const size = get(group, 'options.size')
    if (group.manualGroup) {
      return <Fields group={group} key={`${index}${group.name}`} />
    } if (group.type === 'fieldArray') {
      return (
        <GenerateFieldArray
          key={`${index}${group.name}s`}
          group={group}
          formGroupWrapper={FormGroupWrapper}
          field={Field}
          next={next}
          size={size}
        />
      )
    } return (
      <FormGroupWrapper key={`${index}${group.name}`} size={size} group={group}>
        <Fields group={group} next={next} />
      </FormGroupWrapper>
    )
  })
}

export default GenerateForm
