import React from 'react'
import classNames from 'classnames'
import { formatters } from '../../Utils'

const PropertyAddress = ({ nickname, linkStyle, numberOfLines, noAddress, ...props }) => {
  const addressParts = formatters.address(props)
  let [line1, line2] = addressParts
  let line3 = null
  if (nickname) {
    line1 = nickname
    line2 = addressParts.join(', ')
    if (numberOfLines === 3) {
      line2 = addressParts[0]
      line3 = addressParts[1]
    }
  }
  if (line2[0] === ',') {
    line2 = line2.slice(2)
  }
  return (
    // TODO: Remove title cell, not specific to Property Address
    <div className="address title-cell">
      <div className={classNames('first-line', { link: linkStyle })}>{line1}</div>
      <div className="second-line">
        {noAddress ? (
          <p className="required">This Property can not be imported because it does not have an address.</p>
        ) : (
          line2
        )}
      </div>
      {line3 && <div className="second-line">{line3}</div>}
    </div>
  )
}

export default PropertyAddress

PropertyAddress.defaultProps = {
  linkStyle: false,
  numberOfLines: 2,
}
