import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'formik'
import { SelectField, TextField } from '.'

const FeeFields = ({ formik: { values, setFieldValue }, feeOptions, ...props }) => {
  const { feeType, id } = values

  const [disabledFee, setDisabledFee] = useState(false)

  useEffect(() => {
    if (feeType === 'custom') {
      setFieldValue('price', null)
      setFieldValue('hasPaymentProvider', false)
      // Only unset require approval in add mode
      !id && setFieldValue('requireApproval', false)
    } else {
      setFieldValue('customFeeMessage', null)
    }
    if (feeType === 'free') {
      setFieldValue('price', null)
      setFieldValue('customFeeMessage', null)
      setFieldValue('hasPaymentProvider', false)
      setDisabledFee(true)
    } else {
      if (disabledFee) {
        setDisabledFee(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeType])

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-6">
          <SelectField name="feeType" options={feeOptions} />
        </div>

        {(feeType === 'custom' || feeType === 'free') && (
          <div className="col-sm-4 no-padding">
            <TextField
              name="customFeeMessage"
              {...props.customFeeMessage}
              fast={false}
              disabled={disabledFee}
              allowNegative={false}
            />
          </div>
        )}

        {/* {feeType === 'free' && null} */}

        {feeType !== 'custom' && feeType !== 'free' && (
          <div className="col-sm-4 no-padding">
            <TextField
              formatter="currency"
              name="price"
              {...props.price}
              fast={false}
              disabled={disabledFee}
              allowNegative={false}
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

FeeFields.defaultProps = {
  feeOptions: [],
}

export default connect(FeeFields)
