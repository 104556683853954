import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import classNames from 'classnames';
import { fieldify } from './utils'

import '../../styles/ContactField.scss'

const PhoneField = ({
  field, form, invalid, fast, saveOnExit, readOnly, disabled, ...props
}) => {
  const [focus, setFocus] = useState(false)

  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
    field.value && form.setFieldValue(field.name, field.value)
    form.validateOnBlur && form.validateForm()
    saveOnExit && form.handleSubmit()
  }

  const onChangeValue = values => {
    const value = values || ''
    field.onChange({ target: { name: field.name, value } })
  }

  return (
    <div className={`ContactField ${focus ? 'focused' : ''}`}>
      <PhoneInput
        defaultCountry="US"
        international
        type="tel"
        {...props}
        {...field}
        countryCallingCodeEditable={false}
        value={field.value === null ? '' : field.value}
        className={classNames({ 'is-invalid': invalid }, 'input-type-phone')}
        onChange={onChangeValue}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={readOnly || disabled}
      />
    </div>
  )
}

export default fieldify(PhoneField)
