/* eslint-disable react/display-name */
/* eslint-disable no-undef */

import React from 'react'
import { PropertyAddress } from '../../components/Property'
import { getSortableAddress } from '../../Utils'

// TODO: Add properties list columns here

// For Advertisements
export const PROPERTIES_SELECT = [
  {
    id: 'properties',
    columns: [
      {
        id: 'id',
        Header: 'Address',
        accessor: row => getSortableAddress(row),
        sortType: (a, b) => {
          const addressNameA = get(a, 'original.addressLine1', '')
          const addressNameB = get(b, 'original.addressLine1', '')
          return addressNameA.toLowerCase().localeCompare(addressNameB.toLowerCase())
        },
        Cell: ({ row: { original } }) => {
          return <PropertyAddress linkStyle {...original} />
        },
      },
    ],
  },
]
