import React from 'react'
import { ModalHeader, ModalBody } from '../../components/modal'
import { FEE_OPTIONS, SERVICE_FEE_TYPES } from '../../constants/services'
import { MESSAGES } from '../../constants/strings'
import { formatPrice } from '../../Utils'

const BundleServiceDetailContainer = ({ service }) => {
  const getPrice = amount => {
    const { price, priceType } = formatPrice(service, amount)
    return `${price} ${priceType}`
  }

  return (
    <>
      <div className="modal-title-has-border">
        <ModalHeader className="bundle-service-modal-title">
          <b className="weight-500">{`${MESSAGES.PRICE_FOR} ${service.name}`}</b>
        </ModalHeader>
      </div>
      <ModalBody className="bundle-service-modal p-0 has-thin-scroll scroll-grey no-bg">
        {service.serviceChoices.map(choice => {
          return (
            <>
              <h6>{choice.title}</h6>
              {choice.feeRates.map(fee => {
                if (fee.feeType === SERVICE_FEE_TYPES.CUSTOM) {
                  return <p>{`${MESSAGES.CUSTOM}: ${fee.customFeeMessage}`}</p>
                }
                return <p>{`${FEE_OPTIONS[fee.feeType]}: ${getPrice(parseInt(fee.price))}`}</p>
              })}
            </>
          )
        })}
      </ModalBody>
    </>
  )
}

export default BundleServiceDetailContainer
