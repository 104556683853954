import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'

import Resource from '../../Resource'
import { apiRequest } from '../../reducers/data'
import WithExternalProperties from './WithExternalProperties'
import { formatters, pluralize } from '../../Utils'
import '../../styles/ImportContainer.scss'
import '../../styles/Modal.scss'
import '../../styles/SelectWithCheckboxes.scss'
import { ProgressContainer, PropertyListItem } from '../../components/import-properties'
import { List, ListBody } from '../../components/list'
import { PROPERTY_TYPES } from '../../constants/forms/propertyInfo'
import { MESSAGES } from '../../constants/strings'

const ImportPropertiesContainer = props => {
  const [selectedProperties, setSelectedProperties] = useState([])
  const [showSelected, setShowSelected] = useState(false)
  const [query, setQuery] = useState('')
  const [disableImport, setDisableImport] = useState(false)

  const onSaveProperties = async service => {
    const { dispatch } = props

    const serviceName = service.toLowerCase()
    let path = `${serviceName}/saveProperties`
    if (serviceName !== 'escapia' && serviceName !== 'lodgix') {
      path = `pms/saveProperties?pms=${serviceName}`
    }

    const { response } = await dispatch(apiRequest([path], 'POST', selectedProperties))
    if (response) {
      closeModal()
    }
  }

  const addToSelectedProperties = property => {
    if (selectedProperties.includes(property)) {
      setSelectedProperties(selectedProperties.filter(selectedProperty => selectedProperty !== property))
    } else {
      setSelectedProperties([...selectedProperties, property])
    }
  }

  const closeModal = () => {
    props.closeModal()
  }

  const filterImportedProperties = (properties, externalProperties) => {
    return externalProperties.filter(
      property => !properties.find(({ externalServiceId }) => externalServiceId === property.externalServiceId)
    )
  }

  const getFilteredPropertiesByAddress = externalProperties => {
    return externalProperties.filter(property => {
      const addressParts = formatters.address(property)
      const [, line2] = addressParts
      return line2?.trim()
    })
  }

  const selectAll = (properties, externalProperties) => {
    let propertiesToAdd = filterImportedProperties(properties, externalProperties)
    propertiesToAdd = getFilteredPropertiesByAddress(propertiesToAdd)

    propertiesToAdd = propertiesToAdd.filter(property => !selectedProperties.includes(property))

    setSelectedProperties([...selectedProperties, ...propertiesToAdd])
  }

  const clearSelected = filteredProperties => {
    let propertiesToSelect = []
    if (query) {
      propertiesToSelect = selectedProperties.filter(property => !filteredProperties.includes(property))
    }

    setShowSelected(false)
    setSelectedProperties(propertiesToSelect)
  }

  const getFilteredProperties = externalProperties => {
    const fields = ['nickname', 'addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZIP']
    return externalProperties.filter(property => {
      const includes = fields.filter(field => {
        return property[field]
          ? property[field]
              .toString()
              .toLowerCase()
              .includes(query.toLowerCase())
          : false
      })
      return includes.length
    })
  }

  const { tasks } = props
  const count = selectedProperties.length
  const progressPath = tasks['pms/progress'] ? 'pms/progress' : 'escapia/progress'
  const isImporting = tasks[progressPath] ? !tasks[progressPath].isCompleted : null

  return (
    <WithExternalProperties
      isImporting={isImporting}
      onCancel={closeModal}
      componentType="Properties"
      to="/properties"
      title="Add Properties">
      {({ externalProperties = [], fetchProperties, service }) => {
        const title =
          externalProperties.length === 0 && service ? `Import from ${service.name}` : MESSAGES.SELECT_PROPERTIES

        const filteredProperties = !query ? externalProperties : getFilteredProperties(externalProperties)
        const serviceKey = service ? service.name : ''
        const showingPropertiesString = `Showing ${filteredProperties.length} properties ${
          filteredProperties.length !== externalProperties.length ? `of ${externalProperties.length}` : ''
        }`
        return (
          <Resource collection path={['properties', `?type=${PROPERTY_TYPES.all}`]} placeholder={[]}>
            {(properties, { onRefresh }) => (
              <Fragment>
                <ModalHeader closeModal={closeModal}>{title}</ModalHeader>
                {isImporting ? (
                  <ProgressContainer
                    tasks={tasks}
                    progressPath={progressPath}
                    closeModal={closeModal}
                    serviceKey={serviceKey}
                  />
                ) : (
                  externalProperties.length > 0 && (
                    <Fragment>
                      <ModalBody>
                        <div className="import-properties-filter-container">
                          <span>{showingPropertiesString}</span>
                          <button
                            className="link inline"
                            onClick={() => {
                              setSelectedProperties([])
                              fetchProperties({ resync: true })
                            }}>
                            {MESSAGES.RESYNC_PROPERTY_INFO}
                          </button>
                        </div>
                        <Input
                          type="search"
                          placeholder="Search by property address or nickname"
                          value={query}
                          onChange={evt => setQuery(evt.target.value)}
                        />
                        <div className="import-properties-selected-actions-container">
                          {selectedProperties.length > 0 && (
                            <div className="select-controls">
                              <div className="selected-count">
                                {selectedProperties.length} {MESSAGES.PROPERTIES_SELECTED}
                              </div>
                              <button
                                className="view-selected link inline"
                                onClick={() => setShowSelected(!showSelected)}>
                                {showSelected ? MESSAGES.VIEW_FILTERED_PROPERTIES : MESSAGES.VIEW_SELECTED_PROPERTIES}
                              </button>
                              <button className="link inline" onClick={() => clearSelected(filteredProperties)}>
                                {MESSAGES.CLEAR_SELECTION}
                              </button>
                            </div>
                          )}
                          <div className="select-all-controls">
                            <button className="link inline" onClick={() => selectAll(properties, filteredProperties)}>
                              {MESSAGES.SELECT_ALL}
                            </button>
                          </div>
                        </div>
                        <List className="import-properties-list scrollable">
                          <ListBody data-cy="properties-import-list">
                            {(showSelected ? selectedProperties : filteredProperties).map((propObj, index) => (
                              <PropertyListItem
                                {...propObj}
                                onClick={() => addToSelectedProperties(propObj)}
                                isSelected={selectedProperties.includes(propObj)}
                                isImported={properties.find(
                                  ({ externalServiceId }) => externalServiceId === propObj.externalServiceId
                                )}
                                key={index}
                              />
                            ))}
                          </ListBody>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <div
                          className="link footer-item right"
                          onClick={() => {
                            closeModal()
                            props.history.push('/properties/create')
                          }}>
                          {MESSAGES.ADD_PROPERTY_MANUALLY}
                        </div>
                        <button
                          disabled={!count || disableImport}
                          className="btn btn-primary btn-small footer-item"
                          data-cy="properties-import-button"
                          onClick={() => {
                            setDisableImport(true)
                            onSaveProperties(service.key).then(() => {
                              setDisableImport(false)
                              onRefresh()
                            })
                          }}>
                          Import {count > 0 ? count : ''} {pluralize(count, 'Property')}
                          {service.options.syncReservations ? ' and Sync Reservations' : ''}
                        </button>
                      </ModalFooter>
                    </Fragment>
                  )
                )}
              </Fragment>
            )}
          </Resource>
        )
      }}
    </WithExternalProperties>
  )
}

export default withRouter(connect(state => ({ tasks: state.tasks }))(ImportPropertiesContainer))
