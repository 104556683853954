import React from 'react'
import { get, isArray, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { closeModal } from '../../reducers/modals'
import { CloseIcon, StepperCheckIcon, StepperDisabledCheckIcon } from '../../icons'
import AddButton from '../list/AddButton'

const ModalHeader = ({
  hasItem,
  isDuplicate,
  verb,
  name,
  subtitle,
  closeModal,
  progressText,
  hideClose,
  children,
  steps,
  step,
  showProgressText = true,
  setChoice,
  hideActionButtons = true,
  multiPrevious,
  validateForm,
}) => {
  if (name) {
    if (verb !== false) {
      const edit = get(verb, 'edit', 'Edit ')
      const create = get(verb, 'create', 'Create a ')
      const duplicate = get(verb, 'duplicate', 'Duplicate a ')
      // TODO: Switch here instead of ternary chaos
      children = isDuplicate ? `${duplicate} ${name}` : hasItem ? `${edit} ${name}` : `${create} ${name}`
    } else {
      children = name
    }
  }

  const goBackToPreviousSteps = (index, currStep) => {
    if (hasItem) {
      validateForm && validateForm()
      multiPrevious
        && validateForm
        && validateForm().then(validation => isEmpty(validation) && multiPrevious(currStep - index))
    } else if (index < currStep && !hasItem) {
      multiPrevious(currStep - index)
    } 
  }

  return (
    <div className="ModalHeader">
      <div className="modal-header">
        <div className="title-container">
          <div className="title">{children}</div>
          {showProgressText && progressText && <div className="step-progress">{progressText}</div>}
        </div>

        {!hideActionButtons && (
          <div className="button-container d-flex align-items-center">
            {
              // TODO : set template key
            }
            <AddButton className="mr-3" verb="ADD" item="CUSTOM SERVICE" onClick={() => setChoice(3)} />
            <AddButton verb="ADD" item="BUNDLE/PACKAGE" onClick={() => setChoice(4)} />
          </div>
        )}

        {!hideClose && (
          <>
            {steps && isArray(steps) && (
              <div>
                <Stepper className="Stepper" activeStep={step} alternativeLabel>
                  {steps.map((label, index) => {
                    return (
                      <Step
                        key={`${label}${index}`}
                        className="VerticalStep"
                        onClick={() => goBackToPreviousSteps(index, step)}
                      >
                        <StepLabel
                          StepIconComponent={step >= index ? StepperCheckIcon : StepperDisabledCheckIcon}
                          className="Label"
                        >
                          {`${index + 1}. ${label}`}
                        </StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              </div>
            )}

            <div className="close-container">
              <button type="button" className="icon-btn close-button" onClick={closeModal} data-cy="modal-close">
                <CloseIcon width={10} height={10} />
              </button>
            </div>
          </>
        )}
      </div>
      {subtitle && (
        <div className="modal-sub-header">
          <div className="modal-subtitle">{subtitle}</div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: ownProps.closeModal ? ownProps.closeModal : () => dispatch(closeModal()),
  }
}

export default connect(null, mapDispatchToProps)(ModalHeader)

ModalHeader.defaultProps = {
  hideClose: false,
}
