import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabHeader } from '../components'
import { Form, SubmitButton, Yup } from '../forms'
import { generateForm } from '../forms/utils'
import { PasswordField } from '../forms/fields'
import { apiRequest } from '../reducers/data'
import Alert from '../components/Alert'
import Tooltip from '../components/Tooltip'

const PASSWORD_FIELDS = {
  fields: () => [
    {
      label: 'Old Password',
      name: 'oldPassword',
      component: PasswordField,
      vertical: true,
      validation: Yup.string().required('Old password is required'),
      props: {
        placeholder: 'Old Password',
      },
    },
    {
      label: (
        <span>
          New Password
          <Tooltip target="NewPassword">
            Must Contain Between 8 and 20 Characters, One Uppercase, One Lowercase, One Number and One Special Character
          </Tooltip>
        </span>
      ),
      name: 'newPassword',
      component: PasswordField,
      vertical: true,
      validation: Yup.string()
        .required('New password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
          'Must Contain Between 8 and 20 Characters, One Uppercase, One Lowercase, One number and One Special Character',
        ),
      props: {
        placeholder: 'New Password',
      },
    },
    {
      label: 'Confirm Password',
      name: 'passwordConfirm',
      component: PasswordField,
      vertical: true,
      validation: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
      props: {
        placeholder: 'Confirm New Password',
      },
    },
  ],
}

function PasswordChangeContainer() {
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const me = useSelector(store => store.data.me)

  function PasswordChangeForm(props) {
    const { formFields, handleSubmit } = props
    return (
      <div className="PasswordChangeContainer">
        <TabHeader title="Password Change" />
        {showError && <Alert type="error" text={errorMessage} />}
        <div className="Card col-sm-12 col-lg-5 col-md-6">
          <div className="card-content padding-y pb-0">{formFields}</div>
          <div className="d-flex pb-4 pr-4 justify-content-end">
            <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
          </div>
        </div>
      </div>
    )
  }

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    passwordConfirm: '',
  }

  const onSubmit = async (values, { resetForm }) => {
    const { response, json } = await dispatch(apiRequest([`users/${me.id}/change-password`], 'PUT', values))
    if (response.ok) {
      setShowError(false)
      resetForm(initialValues)
    } else {
      setErrorMessage(json.error)
      setShowError(true)
      resetForm(values)
    }
  }
  const form = generateForm({ form: PASSWORD_FIELDS.fields(), props: {} })
  return (
    <Form
      enableReinitialize
      validationSchema={form.validationNew}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={props => <PasswordChangeForm formFields={form.component} {...props} />}
    />
  )
}

export default PasswordChangeContainer
