import React from 'react'
import { connect as formikConnect } from 'formik'
import { get, isFunction } from 'lodash'
import classNames from 'classnames'
import { Label as RSLabel, FormGroup as RSFormGroup } from 'reactstrap'
import '../styles/FormGroup.scss'
import { FormNote } from '.'
import infoImg from '../images/info.png'

const isFieldRequired = (fields, name) => {
  const path = name
    .split('.')
    .reduce((r, a) => r.concat(a, 'fields'), [])
    .shift()

  const field = get(fields, path)

  if (field) {
    if (field.fields) {
      return isFieldRequired(field.fields, name.split('.').pop())
    }
    const exclusive = get(field, '_exclusive[required]')
    return exclusive === false
  }
  return false
}

const Label = formikConnect(({
 required, vertical, children, className, size, htmlFor, name, formik, ...etc
}) => {
  if (isFunction(required)) {
    required = required(formik)
  } else if (htmlFor || name) {
    if (formik.validationSchema) {
      required = isFieldRequired(formik.validationSchema.fields, htmlFor || name)
    }
  }
  return (
    <RSLabel
      {...etc}
      htmlFor={htmlFor || name}
      className={classNames(
        'modal-label',
        {
          'col-form-label': !vertical,
          'col-sm-3': !vertical && (size === 'medium' || size === 'small' || size === 'x-small'),
          'col-sm-5': !vertical && size === 'large',
        },
        className,
      )}
    >
      {required && <span className="required">*</span>}
      {' '}
      {children}
    </RSLabel>
  )
})

const FormGroup = ({
  className,
  rowClassName,
  fieldClassName,
  required,
  label,
  labelClassName,
  sublabel,
  note,
  children,
  vertical,
  name,
  htmlFor,
  size,
  additionalFieldClass = null,
  labelTooltipText = null,
}) => {
  const labelComponent = label && (
    <Label className={additionalFieldClass === 'sublabel-present' ? 'label-recommendation' : labelClassName} required={required} vertical={vertical} htmlFor={htmlFor || name} size={size}>
      {label}
      {sublabel && (
        <div style={{ textAlign: 'left' }}>
          <small className={additionalFieldClass === 'sublabel-present' ? '' : ''}>{sublabel}</small>
        </div>
      )}
      {labelTooltipText && (
        <img
          title={labelTooltipText}
          style={{
            height: '18px',
            marginLeft: '6px',
          }}
          src={infoImg}
          alt="info"
        />
      )}
    </Label>
  )

  let content = (
    <div className={classNames('FormGroup row', rowClassName)}>
      {labelComponent}
      <div
        className={classNames(
          'field-component',
          additionalFieldClass || {
            'col-sm-3': size === 'x-small',
            'col-sm-4': size === 'small',
            'col-sm-9': size === 'medium',
            'col-sm-7': size === 'large',
          },
          fieldClassName,
        )}
      >
        {note && (
          <FormNote className="warning">
            <strong>NOTE: </strong>
            {note}
          </FormNote>
        )}
        {children}
      </div>
    </div>
  )
  if (vertical) {
    content = (
      <div className="FormGroup">
        {labelComponent}
        {children}
      </div>
    )
  }

  return <RSFormGroup className={className}>{content}</RSFormGroup>
}

FormGroup.defaultProps = {
  size: 'medium',
}

export default FormGroup
