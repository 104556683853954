import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const {
  Map: GMap,
  MapTypeId,
  Marker,
  InfoWindow,
  TravelMode,
  DirectionsService,
  DirectionsRenderer,
  event: GEvent,
  Size: GSize,
} = window.google.maps

const directionsService = new DirectionsService()
let directionsDisplay = null

export default class Map extends Component {
  static contextTypes = {
    router: () => { },
  }

  componentDidMount() {
    const map = new GMap(ReactDOM.findDOMNode(this), {
      mapTypeId: MapTypeId.ROADMAP,
      zoom: 15,
      maxZoom: 15,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
    })

    this._map = map
    this._markers = []
    this._infowindow = new InfoWindow()
    this._infoWindowContent = document.createElement('div')
    this._infowindow.setContent(this._infoWindowContent)
    GEvent.addListener(this._infowindow, 'closeclick', function () {
      directionsDisplay.setMap(null)
    })

    const first = this.props.home ? this.props.home : this.props.markers[0]
    map.setCenter(first.position)

    // block clicks on standard places of interest
    map.addListener('click', event => {
      if (event.placeId) {
        event.stop()
      }
    })

    // ensure directions are shown on the active map
    directionsDisplay = new DirectionsRenderer()
    directionsDisplay.setMap(map)
    directionsDisplay.setOptions({
      suppressMarkers: true,
      preserveViewport: !this.props.showDirections,
    })

    this.buildMarkersForProps()
  }

  componentDidUpdate() {
    this.buildMarkersForProps()
  }

  buildMarkersForProps() {
    const {
      home: homeConfig, markers: configs, showDirections, onClickMarker,
    } = this.props

    // remove markers that no longer exist in the marker configs passed
    // in via props.
    this._markers = this._markers.filter(m => {
      if (configs.find(c => c.id === m.id) || m.home) {
        return true
      }
      m.setMap(null)
      return false
    })

    // create markers that we don't have that are present in the props
    configs.forEach(config => {
      if (this._markers.find(m => config.id === m.id)) {
        return
      }
      const marker = new Marker({ map: this._map, ...config })
      marker.addListener('click', () => {
        onClickMarker ? onClickMarker(config) : this.showInfoPanel(marker)
      })
      this._markers.push(marker)
    })

    // add the Home marker if it doesn't exist
    if (homeConfig && !this._markers.find(m => m.home)) {
      this._markers.push(
        new Marker({
          map: this._map,
          icon: {
            url: require('../images/icons/icon-map-home@2x.png'),
            scaledSize: new GSize(36, 36),
          },
          home: true,
          ...homeConfig,
        }),
      )
    }

    // if we're supposed to immediately show directions, run the request
    if (showDirections) {
      this.showDirections(configs[0])
    }

    // if we're showing a marker that is no longer on the map, hide the directions and infowindow
    if (!this._markers.find(m => m.id === this._infowindow.markerId)) {
      directionsDisplay.setMap(null)
      this._infowindow.close()
    }
  }

  render() {
    return <div className="map-element" />
  }

  // Public Component Methods

  center = () => {
    const first = this.props.home ? this.props.home : this.props.markers[0]
    this._map.panTo(first.position)
  }

  panTo = position => {
    this._map.panTo(position)
  }

  panToIfInvisible = position => {
    if (this._map.getBounds() && !this._map.getBounds().contains(position)) {
      this._map.panTo(position)
    }
  }

  fitBounds = bounds => {
    this._map.fitBounds(bounds, 50) // 50px of padding
    if (this._map.getZoom() > 15) {
      this._map.setZoom(15)
    }
  }

  showInfoPanel = markerOrMarkerConfig => {
    const marker = markerOrMarkerConfig instanceof Marker
      ? markerOrMarkerConfig
      : this._markers.find(m => m.id === markerOrMarkerConfig.id)

    ReactDOM.render(this.props.markerInfoContentBuilder(marker, this.context, this), this._infoWindowContent)
    this._infowindow.close()
    this._infowindow.open(this._map, marker)
    this._infowindow.markerId = marker.id

    this.showDirections(marker)
  }

  showDirections = marker => {
    directionsDisplay.setMap(this._map)
    directionsService.route(
      {
        origin: this.props.home.position,
        destination: marker.position,
        travelMode: TravelMode.WALKING,
      },
      (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response)
        } else {
          console.error(`Directions request failed due to ${status}`)
        }
      },
    )
  }
}

Map.RecenterButton = ({ onClick }) => {
  return (
    <div className="p-l recenter-link" onClick={onClick}>
      <img style={{ height: 20 }} src={require('../images/icons/map/re-center@2x.png')} alt="re-center" />
      {' '}
      Re-center Map
    </div>
  )
}
