import React from 'react'
import { Icon } from '.'

function DotsIcon({ rotate, ...props }) {
  return (
    <Icon viewBox="0 0 4 18" {...props} style={props.rotate ? { transform: 'rotate(90deg)' } : {}}>
      <circle cx={2} cy={2} r={2} fill="currentColor" />
      <circle cx={2} cy={9} r={2} fill="currentColor" />
      <circle cx={2} cy={16} r={2} fill="currentColor" />
    </Icon>
  )
}

export default DotsIcon

DotsIcon.defaultProps = {
  rotate: false,
  size: 16,
}
