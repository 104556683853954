import React, { useEffect, useState } from 'react'
import { closeModal } from '../../reducers/modals'

const PilotHost = process.env.REACT_APP_PILOT_HOST

const ConciergePreview = props => {
  const {
    devicePIN, width, height, token,
  } = props
  const scale = height / 1920

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          transform: `scale(${scale})`,
          position: 'absolute',
          top: Math.floor((height - 1920) / 2),
          left: Math.floor((width - 1040) / 2),
        }}>
        <iframe
          width="1040"
          height="1920"
          title="device preview"
          border="0"
          src={`//${PilotHost}/device-preview?pin=${devicePIN}&token=${token}`}
        />
      </div>
    </div>
  )
}

const ConciergePreviewContainer = props => {
  const borderSize = (40 + 10) * 2
  const height = window.innerHeight * 0.9 - borderSize
  const width = Math.floor((height * 1040) / 1920)
  const { devicePIN, token } = props?.property || {}
  const [domNode, setDomNode] = useState(null)

  useEffect(() => {
    if (domNode) {
      domNode.parentElement.style.width = `${width + borderSize}px`
      domNode.parentElement.style.margin = 'auto'
    }
  }, [domNode, borderSize, width])

  const onCancel = () => {
    props.dispatch(closeModal())
  }

  return (
    <div ref={node => setDomNode(node)}>
      <div
        onClick={onCancel}
        style={{
          position: 'absolute',
          right: -51,
          color: 'white',
          padding: 20,
          top: -27,
          fontSize: 22,
          userSelect: 'none',
          cursor: 'pointer',
        }}>
        ✕
      </div>
      <div
        style={{
          border: '10px solid black',
          boxShadow: '1px 4px 19px rgba(0,0,0,0.4)',
        }}>
        <div style={{ border: '40px solid white' }}>
          <ConciergePreview devicePIN={devicePIN} width={width} height={height} token={token} />
        </div>
      </div>
    </div>
  )
}

export default ConciergePreviewContainer
