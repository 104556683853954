import React, { Fragment, useState } from 'react'
import Select from 'react-select';
import { displayErrorToast } from '../../utils/displayToast'
import { MESSAGES } from '../../constants/strings'
import { confirm } from '../../containers/modals/AlertMessageContainer'
import '../../styles/AdminContainer.scss'

const ChangePropertyAddress = ({ organizatons, dispatch, apiRequest }) => {
    const ZIPCODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
    const [addressChangeOrganition, setAddressChangeOrganition] = useState()
    const [addressChangeSelectedProperty, setAddressChangeSelectedProperty] = useState()
    const [Lat, setLat] = useState()
    const [Long, setLong] = useState()
    const [address, setAddress] = useState()
    const [city, setCity] = useState()
    const [country, setCountry] = useState()
    const [state, setState] = useState()
    const [zipcode, setZipcode] = useState()
    const [unit, setUnit] = useState()
    const [addressChangeProperties, setAddressChangeProperties] = useState([])
    const getPropertiesForAddressChange = async orgId => {
        const propertiesByOrganizationId = await dispatch(apiRequest([`/properties/byOrganizationId/${orgId}`], 'GET'))
        propertiesByOrganizationId.json && setAddressChangeProperties(propertiesByOrganizationId.json)
        propertiesByOrganizationId.json && onPropertySelection(propertiesByOrganizationId.json)
      }
      const onPropertySelection = data => {
        setAddress(data.addressLine1)
        setState(data.addressState)
        setCity(data.addressCity)
        setCountry(data.addressCountry)
        setLat(data.latitude)
        setLong(data.longitude)
        setZipcode(data.addressZIP)
        setUnit(data.addressLine2)
      }
      const onChangeAddress = async () => {
        if (!Lat || !Long || !address.trim() || !country.trim()) {
          displayErrorToast('Please fill all fields. ')
          return
        }

        if (Lat > 90 || Lat < -90 || Long > 180 || Long < -180) {
          displayErrorToast('Invalid Latitude or Longitude value. ')
          return
        }

        if (zipcode && !ZIPCODE_REGEX.test(zipcode)) {
          displayErrorToast('Invalid Zipcode value. ')
          return
        }
        await dispatch(
          apiRequest([`properties/addressUpdate/${addressChangeSelectedProperty.id}`], 'PUT', {
            addressLine1: address.trim(),
            latitude: Lat,
            longitude: Long,
            addressZIP: zipcode,
            isManuallyUpdated: true,
            addressLine2: unit.trim(),
            addressState: state.trim(),
            addressCountry: country.trim(),
            addressCity: city.trim(),
          }),
        )
      }
      const showConfirmation = () => {
        confirm({
          title: 'Confirmation',
          body: 'Address is entered manually and is not automatically verified. Ensure that the address is entered correctly and verify the coordinates are displaying correctly on the map.',
          onConfirm: () => {
            onChangeAddress()
          },
        })
      }
      const propertyMapForAddressChange = addressChangeProperties.map(propertyData => ({
        ...propertyData,
        value: propertyData.id,
        label: propertyData.nickname ?? propertyData.addressLine1,
      }))
  return (
    <Fragment>
      <div className="feature-name pt-3">
        <h4>{`${MESSAGES.CHANGE_ADDRESS}`}</h4>
      </div>
      <div className="row property-address-section mb-3">
        <div className="col-md-6 org-select">
          <div className="select-box">
            <Select
              options={organizatons}
              value={addressChangeOrganition}
              placeholder={MESSAGES.ORGANIZATION}
              onChange={e => {
                    setAddressChangeSelectedProperty(null)
                    getPropertiesForAddressChange(e.value)
                    setAddressChangeOrganition(e)
                  }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="select-box">
            <Select
              options={propertyMapForAddressChange}
              value={addressChangeSelectedProperty}
              placeholder={MESSAGES.PROPERTY}
              onChange={e => {
                    setAddressChangeSelectedProperty(e)
                    onPropertySelection(e)
                  }}
            />
          </div>
        </div>
      </div>

      <div className="row address-lat-long">
        <div className="col-md-6 mb-3">
          <h6>{`${MESSAGES.ADDRESS}`}</h6>
          <input
            className="form-control unit"
            type="text"
            maxLength="200"
            value={address}
            onChange={e => setAddress(e.target.value)}
            placeholder="Address"
            disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
          />
        </div>

        <div className="col-md-6 mb-3">
          <h6>{`${MESSAGES.CITY}`}</h6>
          <input
            className="form-control unit"
            type="text"
            maxLength="200"
            value={city}
            onChange={e => setCity(e.target.value)}
            placeholder="City"
            disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
          />
        </div>

        <div className="col-md-6 mb-3">
          <h6>{`${MESSAGES.STATE}`}</h6>
          <input
            className="form-control unit"
            type="text"
            maxLength="200"
            value={state}
            onChange={e => setState(e.target.value)}
            placeholder="State"
            disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
          />
        </div>

        <div className="col-md-6 mb-3">
          <h6>{`${MESSAGES.COUNTRY}`}</h6>
          <input
            className="form-control unit"
            type="text"
            maxLength="200"
            value={country}
            onChange={e => setCountry(e.target.value)}
            placeholder="Country"
            disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
          />
        </div>

        <div className="col-md-3 mb-3">
          <div>
            <h6>{`${MESSAGES.UNIT}`}</h6>

            <input
              className="form-control"
              type="text"
              value={unit}
              placeholder={'Unit or Apt#'}
              data-cy="autocomplete-address"
              onChange={e => setUnit(e.target.value)}
              disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
            />
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div>
            <h6>{`${MESSAGES.ZIP}`}</h6>

            <input
              className="form-control"
              type="number"
              value={zipcode}
              placeholder={'Zipcode'}
              data-cy="autocomplete-address"
              onChange={e => setZipcode(e.target.value)}
              disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
            />
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div>
            <h6>{`${MESSAGES.LAT}`}</h6>
            <input
              className="form-control"
              type="number"
              value={Lat}
              step="0.0001"
              placeholder={'Latitude'}
              data-cy="autocomplete-address"
              onChange={e => {
                    setLat(e.target.value)
                  }}
              disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
            />
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div>
            <h6>{`${MESSAGES.LONG}`}</h6>
            <input
              className="form-control"
              type="number"
              step="0.0001"
              value={Long}
              placeholder={'Longitude'}
              data-cy="autocomplete-address"
              onChange={e => setLong(e.target.value)}
              disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
            />
          </div>
        </div>

        <div className="col-md-12">
          <button
            className="button"
            onClick={showConfirmation}
            disabled={!addressChangeOrganition || !addressChangeSelectedProperty}
          >
            Change Address
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default ChangePropertyAddress
