import * as React from 'react'

function SlideshowNew() {
  return (
    <span className="Icon is-slideshowNew nav-slideshow">
      {/* <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.4665 2.07727H4.61596C4.53105 2.07998 4.45053 2.11561 4.39143 2.17663C4.33233 2.23764 4.29929 2.31926 4.29929 2.4042C4.29929 2.48915 4.33233 2.57076 4.39143 2.63178C4.45053 2.69279 4.53105 2.72843 4.61596 2.73113H17.1394V11.0306C17.1421 11.1155 17.1777 11.196 17.2388 11.2551C17.2998 11.3142 17.3814 11.3472 17.4663 11.3472C17.5513 11.3472 17.6329 11.3142 17.6939 11.2551C17.7549 11.196 17.7906 11.1155 17.7933 11.0306V2.40437C17.7933 2.36143 17.7849 2.3189 17.7685 2.27922C17.7521 2.23954 17.728 2.20347 17.6977 2.1731C17.6673 2.14272 17.6313 2.11862 17.5916 2.10218C17.552 2.08573 17.5094 2.07727 17.4665 2.07727Z"
          fill="#5591C7"
        />
        <path
          d="M19.6179 0H6.76765C6.6809 0 6.5977 0.0344613 6.53636 0.095804C6.47501 0.157147 6.44055 0.240346 6.44055 0.327097C6.44055 0.413849 6.47501 0.497047 6.53636 0.55839C6.5977 0.619732 6.6809 0.654195 6.76765 0.654195H19.2911V8.9533C19.2938 9.0382 19.3294 9.11872 19.3904 9.17782C19.4515 9.23692 19.5331 9.26997 19.618 9.26997C19.703 9.26997 19.7846 9.23692 19.8456 9.17782C19.9066 9.11872 19.9422 9.0382 19.945 8.9533V0.327097C19.945 0.240346 19.9105 0.157147 19.8492 0.095804C19.7878 0.0344613 19.7046 0 19.6179 0Z"
          fill="#5591C7"
        />
        <path
          d="M14.8982 4.42517H0.334411C0.24609 4.42526 0.161383 4.46024 0.0987449 4.52251C0.0361066 4.58477 0.000613902 4.66927 0 4.75759V14.0985C-4.37463e-08 14.1872 0.0352216 14.2723 0.0979262 14.3351C0.160631 14.3978 0.245689 14.4331 0.334411 14.4332H14.8982C14.9422 14.4332 14.9857 14.4246 15.0263 14.4077C15.0669 14.3909 15.1038 14.3662 15.1348 14.3352C15.1659 14.3041 15.1905 14.2672 15.2073 14.2266C15.2241 14.1859 15.2327 14.1424 15.2326 14.0985V4.75759C15.2321 4.66924 15.1966 4.58469 15.134 4.52241C15.0713 4.46013 14.9866 4.42517 14.8982 4.42517ZM10.1982 9.57031L8.0089 10.8335L5.81928 12.0967C5.79455 12.111 5.76649 12.1185 5.73791 12.1185C5.70934 12.1186 5.68127 12.111 5.65653 12.0967C5.63179 12.0825 5.61125 12.0619 5.59698 12.0371C5.58272 12.0124 5.57524 11.9843 5.57528 11.9557V6.90101C5.57524 6.87243 5.58272 6.84435 5.59698 6.8196C5.61125 6.79484 5.63179 6.77428 5.65653 6.75998C5.68127 6.74569 5.70934 6.73817 5.73791 6.73819C5.76649 6.7382 5.79455 6.74575 5.81928 6.76006L8.0089 8.02324L10.1982 9.28642C10.2235 9.30053 10.2445 9.32112 10.2592 9.34607C10.2738 9.37102 10.2815 9.39943 10.2815 9.42837C10.2815 9.4573 10.2738 9.48571 10.2592 9.51067C10.2445 9.53562 10.2235 9.55621 10.1982 9.57031Z"
          fill="#5591C7"
        />
      </svg> */}
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5145 2.91598H4.62865C4.44761 2.91598 4.30078 3.06278 4.30078 3.24384C4.30078 3.42494 4.44761 3.57171 4.62865 3.57171H17.1866V11.8938C17.1866 12.0749 17.3334 12.2217 17.5145 12.2217C17.6955 12.2217 17.8423 12.0749 17.8423 11.8938V3.24384C17.8423 3.06278 17.6955 2.91598 17.5145 2.91598Z"
          fill="#BDC6CD"
        />
        <path
          d="M19.6721 0.896444H6.78636C6.6053 0.896444 6.4585 1.04324 6.4585 1.22431C6.4585 1.40541 6.6053 1.55221 6.78636 1.55221H19.3443V9.87428C19.3443 10.0553 19.4911 10.2021 19.6721 10.2021C19.8532 10.2021 20 10.0553 20 9.87428V1.22431C20 1.04324 19.8532 0.896444 19.6721 0.896444Z"
          fill="#BDC6CD"
        />
        <path
          d="M10.2264 10.4296L8.03087 11.6972L5.8353 12.9648C5.72657 13.0276 5.5906 12.9491 5.5906 12.8235V10.2883V7.75311C5.5906 7.62751 5.72657 7.54905 5.8353 7.61185L8.03087 8.87941L10.2264 10.147C10.3352 10.2098 10.3352 10.3668 10.2264 10.4296ZM14.9394 5.26901H0.335433C0.1502 5.26901 0 5.41921 0 5.60448V14.9722C0 15.1574 0.1502 15.3076 0.335433 15.3076H14.9394C15.1246 15.3076 15.2748 15.1574 15.2748 14.9722V5.60448C15.2748 5.41921 15.1246 5.26901 14.9394 5.26901Z"
          fill="#BDC6CD"
        />
      </svg>
    </span>
  )
}

export default SlideshowNew
