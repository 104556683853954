import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { CharactersLeft } from '../forms/fields'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../styles/Fields.scss'

class RichTextEditor extends Component {
  constructor(props) {
    super(props)
    const contentBlock = htmlToDraft(props.value || '')
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      this.state = {
        editorState,
      }
    }
  }

  onEditorStateChange = editorState => {
    const currentContentLength = editorState.getCurrentContent().getPlainText('').length
    if (currentContentLength > this.props.maxLength) {
      this._handleBeforeInput()
      this.setState({ editorState: this.state.editorState })
    } else {
      this.setState({
        editorState,
      })

      const raw = convertToRaw(editorState.getCurrentContent())

      let value = draftToHtml(raw)
      const blocks = raw.blocks || []
      const isEmpty = !blocks.find(({ text }) => text.trim().length !== 0)
      if (isEmpty) {
        value = ''
      }
      this.props.onChange({
        target: {
          name: this.props.name,
          value,
        },
      })
    }
  }

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection()
    const isCollapsed = currentSelection.isCollapsed()

    let length = 0

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent()
      const startKey = currentSelection.getStartKey()
      const endKey = currentSelection.getEndKey()
      const startBlock = currentContent.getBlockForKey(startKey)
      const isStartAndEndBlockAreTheSame = startKey === endKey
      const startBlockTextLength = startBlock.getLength()
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset()
      const endSelectedTextLength = currentSelection.getEndOffset()
      const keyAfterEnd = currentContent.getKeyAfter(endKey)

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset()
      } else {
        let currentKey = startKey

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1
          } else if (currentKey === endKey) {
            length += endSelectedTextLength
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1
          }

          currentKey = currentContent.getKeyAfter(currentKey)
        }
      }
    }

    return length
  }

  _getTotalLength = () => {
    const currentContent = this.state.editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length

    return currentContentLength
  }

  _handleBeforeInput = () => {
    const { maxLength } = this.props
    const currentContentLength = this._getTotalLength()
    const selectedTextLength = this._getLengthOfSelectedText()
    if (currentContentLength - selectedTextLength > maxLength - 1) {
      return 'handled'
    }
  }

  _handlePastedText = pastedText => {
    const { maxLength } = this.props
    const currentContentLength = this._getTotalLength()
    const selectedTextLength = this._getLengthOfSelectedText()

    if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
      return 'handled'
    }
  }

  render() {
    const { editorState } = this.state
    const { className, maxLength, readOnly } = this.props

    return (
      <>
        <Editor
          readOnly={readOnly}
          onFocus={this.toggle}
          onBlur={this.toggle || this.props.onBlur}
          editorState={editorState}
          wrapperClassName={`demo-wrapper ${className || ''} ${readOnly ? 'is-read-only' : ''}`}
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          stripPastedStyles
          toolbar={{
            options: ['inline', 'list', 'emoji', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
          }}
        />
        <CharactersLeft length={this._getTotalLength()} maxLength={maxLength} />
      </>
    )
  }
}

RichTextEditor.defaultProps = {
  maxLength: 600,
}

export default RichTextEditor
