/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'
import { openModalWithContainer } from '../../reducers/modals'
import { ListFilterAction, MasterList } from '../list'
import { CATEGORY, MESSAGE_LOGO, MESSAGE_OPTIONS, MESSAGE_TITLE } from '../../constants/tables/messages'
import RecipientProperties from './RecipientProperties'
import MessagesDrawer from './MessagesDrawer'
import { pluralize } from '../../Utils'
import { apiRequest } from '../../reducers/data'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import { Loading } from '../PageStates'
import { Announcement } from '../../icons'

const COLUMNS = [
  {
    id: 'messages',
    columns: [
      MESSAGE_LOGO,
      {
        ...MESSAGE_TITLE,
        options: {
          classNames: 'valigntop pl-1 font13',
          headerClassNames: 'pl-1',
        },
      },
      {
        Header: 'Sent to',
        accessor: 'properties',
        sortType: (a, b) => {
          const propertyA = get(a, 'original.properties.length', '')
          const propertyB = get(b, 'original.properties.length', '')
          return propertyA - propertyB
        },
        Cell: ({ row: { original }, initialState: { properties, openRecipientProperties } }) => {
          return (
            <RecipientProperties
              openRecipientProperties={e => openRecipientProperties(original, e)}
              properties={properties}
              propertiesLength={original.readCount || 0}
              totalPropertiesCount={original.totalCount}
              original={original}
              isHistoryView
            />
          )
        },
        options: {
          classNames: 'clickable',
          hideOn: ['tablet', 'mobile'],
        },
      },
      CATEGORY,
      {
        Header: 'Date Sent',
        accessor: 'sent',
        sortType: (a, b) => {
          const dateA = get(a, 'original.sent', '')
          const dateB = get(b, 'original.sent', '')
          return moment(dateA).diff(moment(dateB))
        },
        Cell: ({ row: { original } }) => {
          return (
            <small>
              <date>{original?.sent && moment(original?.sent).format('MMM DD, YYYY')}</date>
              <br />
              <time>{original?.sent && moment(original?.sent).format('hh:mm a')}</time>
              {/* {moment(original.sent).format('MMM DD, YYYY')} */}
            </small>
          )
        },
      },
      {
        ...MESSAGE_OPTIONS,
        Cell: ({ row: { original: message }, initialState: { duplicateMessage } }) => {
          const duplicate = () => {
            duplicateMessage(message.content)
          }

          return (
            <PopoverMenu>
              <PopoverMenuItem onClick={duplicate}>Duplicate</PopoverMenuItem>
            </PopoverMenu>
          )
        },
      },
      {
        accessor: 'updatedAt',
      },
    ],
  },
]

const SentMessagesTable = ({
  messages,
  properties,
  openRecipientProperties,
  filterOptions,
  setFilter,
  selectedFilter,
  duplicateMessage,
}) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  const initialState = {
    properties,
    hiddenColumns: 'updatedAt',
    // sortBy: [{ id: 'updatedAt', desc: true }],
    openRecipientProperties,
    duplicateMessage,
  }

  return (
    <MasterList
      columns={memoizedColumns}
      data={messages}
      actions={<ListFilterAction filterOptions={filterOptions} setFilter={setFilter} selectedFilter={selectedFilter} />}
      initialState={initialState}
      search
      emptyState={{ icon: Announcement, text: 'No sent announcements yet' }}
    />
  )
}

export default connect(null, { openModalWithContainer })(
  ({ properties, openModalWithContainer, onUpdateItem, openModal, status, onCreateItem }) => {
    const dispatch = useDispatch()
    const [isLoading, setisLoading] = useState(false)
    const [showDrawer, setDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})
    const [params, setParams] = useState('?type=DAY&value=30')
    const [messages, setMessages] = useState([])
    const [selectedFilter, setSelectedFilter] = useState(1)

    useEffect(() => {
      const getSentMessages = async () => {
        try {
          setisLoading(true)
          const data = await dispatch(apiRequest(['messages/sent', params], 'GET'))
          if (data && data.json) {
            let instances = data.json
            instances = instances.map(m => ({ ...m, title: m.content.title, category: m.content.category }))
            setMessages(instances)
            setisLoading(false)
          }
        } catch (error) {
          setisLoading(false)
        }
      }
      getSentMessages()
    }, [params, dispatch])

    const filterOptions = [
      { type: 'DAY', value: 15, label: 'Last 15 Days' },
      { type: 'DAY', value: 30, label: 'Last Month (30 Days)' },
      { type: 'DAY', value: 90, label: 'Last Quarter (90 Days)' },
      { type: 'MONTH', value: 6, label: 'Last 6 Months' },
      { type: 'YEAR', value: 1, label: 'Last Year' },
    ]

    const changeFilter = filter => {
      setSelectedFilter(filter)
      setParams(`?type=${filterOptions[filter].type}&value=${filterOptions[filter].value}`)
    }

    // functions the same as rowAction but performed on an individual cell,
    // open message is used twice. 1. in messages cell 2. for the edit tab in dot options
    const openMessage = message => {
      openModalWithContainer('MessageContainer', {
        onSave: onUpdateItem,
        message,
        properties,
      })
    }

    const saveDuplicate = message => {
      delete message.id
      onCreateItem(message)
    }

    const duplicateMessage = message => {
      const originalMessageId = message.id
      openModalWithContainer(
        'MessageContainer',
        {
          message,
          originalMessageId,
          isDuplicate: true,
          properties,
          onSave: saveDuplicate,
        },
        {
          size: 'xl',
          customClass: 'add-custom-service-modal',
        }
      )
    }

    const openRecipientProperties = (message, event) => {
      event.stopPropagation()
      setDrawerContent(message)
      setDrawer(true)
    }

    return (
      <>
        <MessagesDrawer
          drawerData={drawerContent}
          showDrawer={showDrawer}
          setDrawer={setDrawer}
          properties={properties}
          sentList
        />

        {isLoading && <Loading />}

        {!isLoading && (
          <SentMessagesTable
            messages={messages}
            properties={properties}
            columns={COLUMNS}
            onUpdateItem={onUpdateItem}
            openMessage={openMessage}
            openModal={openModal}
            duplicateMessage={duplicateMessage}
            status={status}
            openRecipientProperties={openRecipientProperties}
            filterOptions={filterOptions}
            setFilter={changeFilter}
            selectedFilter={selectedFilter}
          />
        )}
        {/* {messages.length === 0 && !isLoading && (
          <EmptyNavItemPlaceholder
            text={'No sent announcements yet.'}
            navItem="an announcement"
            icon={<MessageIcon height="72" width="72" />}
          />
        )} */}
      </>
    )
  }
)
