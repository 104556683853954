import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import learnmore from '../../images/more.svg'
import adframe from '../../images/frame.png'
import { MESSAGES } from '../../constants/strings'

const SetupTipsCard = () => {
  return (
    <div className="ads-platform">
      <h6>{MESSAGES.SETUP_TIPS}</h6>
      <h4>
        <strong>{MESSAGES.USE_DISPLAY_AS_ADS}</strong>
        <img src={adframe} alt="ad frame" />
      </h4>
      <p>{MESSAGES.INCREASE_AWARENESS_MSG}</p>
      <p className="ads-learn-more">
        <Link to="/home">
          {MESSAGES.LEARN_MORE}
          <img src={learnmore} alt="learn more" />
        </Link>
      </p>
    </div>
  )
}

export default withRouter(SetupTipsCard)
