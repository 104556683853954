import React, { useState } from 'react'
import { Tooltip as RSTooltip } from 'reactstrap'
import '../styles/Tooltip.scss'

function TooltipCue({ target, ...props }) {
  return (
    <span style={{ margin: '0 8px' }} className="tooltip-cue" id={target} {...props}>
      ?
    </span>
  )
}

const Tooltip = ({ target, tabIndex, children, cue = true, placement = "bottom", ...props }) => {
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!isOpen)
  return (
    <>
      {cue && <TooltipCue target={target} tabIndex={tabIndex} />}
      <RSTooltip
        autohide={false}
        style={{
          opacity: 1,
          backgroundColor: 'white',
          textAlign: 'left',
          color: 'black',
        }}
        placement={placement}
        isOpen={isOpen}
        target={target}
        toggle={toggle}
      >
        {children}
      </RSTooltip>
    </>
  )
}

export default Tooltip
