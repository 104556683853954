import React from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import '../../styles/AnalyticsContainer.scss'
import { CloseIcon } from '../../icons'

const PropertyAnalyticsInfo = ({ property: { nickname, addressLine1 } }) => {
  const [, setPropertyId] = useQueryParam('propertyId', NumberParam)
  const name = nickname ? `${nickname}, ${addressLine1}` : addressLine1
  return (
    <div className="PropertyAnalyticsInfo">
      <div className="property-container">
        {name}
        <CloseIcon onClick={() => setPropertyId()} />
      </div>
    </div>
  )
}

export default PropertyAnalyticsInfo
