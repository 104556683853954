import { get, set } from 'lodash'

function isSupported(getStorage) {
  try {
    const key = '__test_storage_for_safari'
    getStorage().setItem(key, key)
    getStorage().removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

const adapter = {}
if (isSupported(() => localStorage)) {
  adapter.setItem = function () {
    const args = Array.from(arguments)
    return localStorage.setItem.apply(localStorage, args)
  }
  adapter.getItem = function () {
    const args = Array.from(arguments)
    return localStorage.getItem.apply(localStorage, args)
  }
} else {
  adapter.setItem = function () {}
  adapter.getItem = function () {}
}

// Abstraction on top of localStorage to store a object and it takes care of [de]serializing
// localStorage value
class Storage {
  constructor(key, defaults = {}) {
    this._key = key
    this._defaults = defaults
  }

  set(path, value) {
    const data = this._json()
    set(data, path, value)
    return adapter.setItem(this._key, JSON.stringify(data))
  }

  get(path, defaults) {
    return get(this._json(), path, defaults)
  }

  remove(path) {
    return this.set(path)
  }

  _json() {
    return JSON.parse(adapter.getItem(this._key) || `${JSON.stringify(this._defaults)}`)
  }
}

export default Storage
