import React, { useState } from 'react'
import { connect, Field, FieldArray } from 'formik'
import { Accordion as AccordionComponent, Card, useAccordionButton } from 'react-bootstrap'
import classNames from 'classnames'
import pluralize from 'pluralize'
import DraggableMediaField from './DraggableMediaField'
import TextField from './TextField'
import MultiFeeRate from './MultiFeeRate'
import { TextareaField } from '.'
import { ErrorIcon } from '../../icons'

const initialServiceChoiceMarkup = {
  title: '',
  serviceChoiceMedias: [],
  feeRates: [
    {
      feeType: 'fixed',
      price: null,
      customFeeMessage: '',
    },
  ],
  detailHTML: '',
}

// const AccordionHead = ({ title, removeField, fieldIndex, error, setActiveAccordion }) => {
//   return (
//     <AccordionComponent.Toggle as="div" eventKey={fieldIndex}>
//       <div className="AccordionHead">
//         <div className="d-flex">
//           {error && <ErrorIcon />}
//           <h4>
//             {'\t\t'}
//             {title}
//           </h4>
//         </div>
//         <div eventKey={fieldIndex}>
//           <i
//             className="necicon-trash-bin"
//             onClick={event => {
//               removeField(fieldIndex)
//               // setActiveAccordion('0') // Initialize accordion to level 0 state
//               event.stopPropagation()
//             }}
//           ></i>
//         </div>
//       </div>
//     </AccordionComponent.Toggle>
//   )
// }

function CustomToggle({
 eventKey, title, removeField, fieldIndex, error, onSelect,
}) {
  const decoratedOnClick = useAccordionButton(eventKey)

  return (
    <div
      className="AccordionHead"
      onClick={() => {
        onSelect()
        decoratedOnClick()
      }}
    >
      <div className="d-flex">
        {error && <ErrorIcon />}
        <h4>
          {'\t\t'}
          {title}
        </h4>
      </div>
      <div eventKey={fieldIndex}>
        <i
          className="necicon-trash-bin"
          onClick={event => {
            removeField(fieldIndex)
            // setActiveAccordion('0') // Initialize accordion to level 0 state
            event.stopPropagation()
          }}
        />
      </div>
    </div>
  )
}

const Accordion = ({
 children, activeAccordion, setActiveAccordion, fieldIndex, error, ...props
}) => {
  const showError = error ? error.title || error.serviceChoiceMedias || !!error.feeRates.filter(n => n).length : false
  const onSelect = () => setActiveAccordion(activeAccordion === fieldIndex ? '-1' : fieldIndex)

  return (
    <AccordionComponent
      // onSelect={() => setActiveAccordion(activeAccordion === fieldIndex ? '-1' : fieldIndex)}
      defaultActiveKey="0"
      activeKey={activeAccordion}
      className={classNames({ 'has-error': showError })}
    >
      {/* <AccordionHead {...props} setActiveAccordion={setActiveAccordion} fieldIndex={fieldIndex} error={error} /> */}
      <CustomToggle {...props} eventKey={fieldIndex} fieldIndex={fieldIndex} error={error} onSelect={onSelect} />

      <AccordionComponent.Collapse eventKey={fieldIndex}>
        <Card.Body className="AccordionBody">{children}</Card.Body>
      </AccordionComponent.Collapse>
    </AccordionComponent>
  )
}

const RenderField = ({
  name,
  label,
  component,
  isRequired,
  fields,
  fieldIndex,
  fieldKey,
  validateForm,
  key,
  ...props
}) => {
  // const isMount = useIsMount()
  // useEffect(() => {
  //   if (!isMount) validateForm()
  // }, [fields[fieldIndex][fieldKey]])

  return (
    <div className="service-choice row pb-3" key={key}>
      <div className="col-md-3 text-right">
        <label>
          {isRequired && <span className="required">*</span>}
          {label}
        </label>
      </div>
      <div className="col-md-9 pr-md-0">
        <Field name={name} component={component} validateForm={validateForm} {...props} />
      </div>
    </div>
  )
}

// Manage state of individual service choice option
const SingleChoiceComponent = ({
 formKeyName, fieldIndex, fields, validateForm, validateField,
}) => {
  return (
    <div key={fieldIndex}>
      <RenderField
        label="Title"
        name={`${formKeyName}[${fieldIndex}].title`}
        component={TextField}
        isRequired
        maxLength={80}
        skipBlurMode
        validateForm={validateForm}
        fields={fields}
        fieldIndex={fieldIndex}
        fieldKey="title"
      />
      <RenderField
        label="Add Image"
        name={`${formKeyName}[${fieldIndex}].serviceChoiceMedias`}
        component={DraggableMediaField}
        maxMediaLimit={1}
        minMediaLimit={0}
        mediaType={['image']}
        accept="image/*"
        upload={{
          server: '/api/dashboard',
          signingUrlQueryParams: { path: 'uploaded-media/' },
        }}
        desiredSize={{ width: 1280, height: 960 }}
        isRequired
        validateForm={validateForm}
        fields={fields}
        fieldIndex={fieldIndex}
        fieldKey="serviceChoiceMedias"
      />

      <RenderField
        label="Fee/Rate"
        name={`${formKeyName}[${fieldIndex}]['feeRates']`}
        component={MultiFeeRate}
        isRequired
        fieldPath={`${formKeyName}[${fieldIndex}]`}
        parentFieldName={formKeyName}
        parentIndex={fieldIndex}
        key={`${formKeyName}[${fieldIndex}]`}
        validateForm={validateForm}
        fields={fields}
        fieldIndex={fieldIndex}
        fieldKey="feeRates"
      />

      <RenderField
        label="Service Choice Description"
        name={`${formKeyName}[${fieldIndex}].detailHTML`}
        component={TextareaField}
        isRequired={false}
        maxLength={80}
        validateForm={validateForm}
        fields={fields}
        fieldIndex={fieldIndex}
        fieldKey="detailHTML"
      />
    </div>
  )
}

const ServiceChoices = ({ formik, name }) => {
  const [activeAccordion, setActiveAccordion] = useState('0')
  // const isMount = useIsMount()
  const {
 values, errors, validateForm, validateField,
} = formik
  const fields = values[name]

  // useEffect(() => {
  //   if (!isMount) validateForm()
  // }, [fields])

  return (
    <>
      <div className="standard-form">
        <div className="choices-header">
          <h3 className="">Add Multiple Service Choices</h3>
          <p>{`${String(fields.length).padStart(2, '0')} ${pluralize('choice', fields.length)}`}</p>
        </div>
        <FieldArray
          name={name}
          validateOnChange={false}
          validateOnBlur={false}
          render={renderMethods => (
            <>
              {/** Render service choices fields */}
              {fields.map((field, fieldIndex) => {
                fieldIndex = fieldIndex.toString()
                const error = errors[name] && errors[name][fieldIndex]
                return fields.length > 1 ? (
                  <Accordion
                    activeAccordion={activeAccordion}
                    fieldIndex={fieldIndex}
                    setActiveAccordion={setActiveAccordion}
                    title={field.title || `Service Choice ${parseInt(fieldIndex, 10) + 1}`}
                    removeField={renderMethods.remove}
                    error={error}
                  >
                    <SingleChoiceComponent
                      formKeyName={name}
                      fieldIndex={fieldIndex}
                      fields={fields}
                      validateForm={validateForm}
                      validateField={validateField}
                    />
                  </Accordion>
                ) : (
                  <SingleChoiceComponent
                    formKeyName={name}
                    fieldIndex={fieldIndex}
                    fields={fields}
                    validateForm={validateForm}
                    validateField={validateField}
                  />
                )
              })}

              {/** Add new field in the fields array and maintain accordion state */}
              <button
                className="btn btn-primary mt-4"
                type="button"
                disabled={fields.length >= 10}
                onClick={() => {
                  renderMethods.push({ ...initialServiceChoiceMarkup, order: fields[fields.length - 1].order + 1 })
                  fields.length > 0 && setActiveAccordion(String(fields.length))
                }}
                style={{ cursor: fields.length >= 10 ? 'not-allowed' : 'pointer' }}
              >
                {fields.length > 0 ? 'Add another' : 'Add new'}
              </button>
            </>
          )}
        />
      </div>
    </>
  )
}

export default connect(ServiceChoices)
