import React from 'react'
import classNames from 'classnames'
import '../../styles/List.scss'

const List = ({ className, children, ...props }) => {
  return (
    <div className={classNames('list-wrapper', className)}>
      <table className="List" {...props}>
        {children}
      </table>
    </div>
  )
}

export default List
