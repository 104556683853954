import React from 'react'
import Icon from './Icon'

function ChartIcon(props) {
  return (
    <Icon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
      {...props}
    >
      <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </Icon>
  )
}

export default ChartIcon
