export const EVENTS = {
         PAGE_VIEW: 'page_view',
         SEARCH_PROPERTY: 'search_property',
         CONTENT: 'content',
         PROPERTY_VIEW_TOGGLE: 'property_view_toggle',
         ARCHIVED_PROPERTY_TAB: 'archived_property_tab',
         PROPERTY_ARCHIVED: 'property_archived',
         PROPERTY_RELIST: 'property_relist',
         ADD_PROPERTY_FROM_PROPERTY: 'add_property_from_property',
         HOME_ADD_PROPERTY: 'add_property_from_home',
         PROPERTY_DETAILS: 'property_details',
         MESSAGE: 'message',
         SERVICE: 'service',
         STRIPE: 'stripe',
         ANALYTICS: 'analytics',
         SLIDESHOW: 'slideshow',
         ADVERTISEMENT: 'advertisement',
         SEARCH: 'search',
         ACCOUNT_AND_BILLING: 'account_and_billing',
         CHANGE_PASSWORD: 'change_password',
         SIGNING_OUT: 'signing_out',
         SIGN_OUT: 'sign_out',
         SETTINGS: 'settings',
         ANNOUNCEMENT: 'announcement',
       }

export const SECTIONS = {
  GLOBAL: 'global',
  PROPERTY_DETAILS: 'property_details',
  PROPERTY_INFO: 'property_info',
  CHECK_IN_OUT_INFO: 'check_in_out_info',
  RECOMMENDATION: 'recommendation',
  RESERVATION: 'reservation',
  SERVICES: 'services',
  SLIDESHOW_AND_ADS: 'slideshow_and_ads',
  WELCOME_SCREEN_AND_VIDEO: 'welcome_screen_and_video',
  GENERAL_INFO: 'general_info',
  HOSTS_AND_USERS: 'hosts_and_users',
  PREFERENCES: 'preferences',
}

export const SUB_SECTIONS = {
  SUPPLIES: 'supplies',
  AMENITIES: 'amenities',
  POLICIES: 'policies',
  GENERAL_INFO: 'general_info',
  HOUSE_RULES: 'house_rules',
  WIFI: 'wifi',
  CONTACT_US: 'contact_us',
  DIRECTORY: 'directory',
  ACTIVITIES: 'activities',
  RESTAURANTS: 'restaurants',
  CHECKIN_SURVEY: 'checkin_survey',
  CHECKOUT_SURVEY: 'checkout_survey',
  CHECKOUT_CHECKLIST: 'checkout_checklist',
  CHECKOUT_DETAILS: 'checkout_details',
  CHECK_IN_OUT: 'check_in_out',
  SLIDESHOW: 'slideshow',
  WELCOME_VIDEO: 'welcome_video',
  WELCOME_SCREEN: 'welcome_screen',
  MANAGE_PASSCODE: 'manage_passcode',
  MANAGE_COMPANY_NAME: 'manage_company_name',
  MANAGE_HOST: 'manage_hosts',
  REVIEW_REMINDER_CHECKOUT: 'review_reminder_checkout',
  REVIEW_REMINDER_VALUE: 'review_reminder_value',
  REVIEW_REMINDER_TEXT: 'review_reminder_text',
  SEARCH: 'search',
}

export const ACTIONS = {
  CREATE: 'create',
  REORDER: 'reorder',
  DISPLAY_PREVIEW: 'display_preview',
  UPDATE: 'update',
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
  DISABLE: 'disable',
  ENABLE: 'enable',
  EDIT: 'edit',
  CLICK: 'click',
  APPROVE: 'approve',
  DECLINE: 'decline',
  SEARCH: 'search',
  CHANGE_DEFAULT: 'change_default',
  CHANGE_DURATION: 'change_duration',
  CHANGE_CADENCE: 'change_cadence',
  COPY_STOCK: 'copy_stock',
}
