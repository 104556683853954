import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { uniqueId } from 'lodash'
import Accordion from '../../../components/Accordion'
import {
  DescriptionPreview,
  DetailHTMLPreview,
  Divider,
  ImageCarouselPreview,
  PreviewFooter,
  TextPreview,
  OrderDeadlinePreview,
  ChoicesPreview,
  ImagePreview,
} from '../../../forms/previews'
import { COMMON_FIELDS, CUSTOM_FIELDS } from '../services'
import { ClockIcon3 } from '../../../icons'
import Skeleton from '../../../components/Skeleton'
import { COMMON_PREVIEW, FORMATTERS } from './commonPreview'
import imagePlaceholder from '../../../images/image-placeholder.png'

const SERVICE_LISTING_BUNDLE_PREVIEW = {
  className: 'ListingPreview only-list',
  Parent: Accordion,
  title: 'Listing Preview',
  subtitle: 'Service Listing',
  id: uniqueId('service_listing_bundle_preview'),
  children: [
    {
      name: CUSTOM_FIELDS.draggableMedia.name,
      component: ImagePreview,
      placeholder: imagePlaceholder,
      props: {},
    },
    {
      className: 'ServiceListPreview',
      children: [
        {
          component: TextPreview,
          className: 'PreviewTitle',
          name: COMMON_FIELDS.name.name,
          placeholder: <Skeleton height={12} width="30%" />,
        },
        {
          component: TextPreview,
          name: 'serviceChoices',
          placeholder: <Skeleton height={8} width="40%" />,
          formDependant: true,
          format: FORMATTERS.priceRangeFormatter,
        },
        {
          component: DescriptionPreview,
          name: COMMON_FIELDS.summarySubtitle.name,
          placeholder: <Skeleton height={8} count={2} width="100%" />,
        },
      ],
    },
  ],
}

const SERVICE_DETAIL_BUNDLE_PREVIEW = {
  className: 'ListingPreview ServiceDetail kiosk-ratio',
  Parent: Accordion,
  title: 'Service Detail Page',
  subtitle: 'Service Detail',
  id: uniqueId('service_detail_bundle_preview'),
  children: [
    {
      className: 'ServiceDetailPreview',
      children: [
        {
          className: 'ServiceDetailPreviewMain',
          children: [
            {
              component: TextPreview,
              className: 'PreviewTitle',
              name: COMMON_FIELDS.name.name,
              placeholder: <Skeleton height={12} width="30%" />,
            },
            {
              component: TextPreview,
              name: 'serviceChoices',
              placeholder: <Skeleton height={8} width="40%" />,
              formDependant: true,
              format: FORMATTERS.priceRangeFormatter,
            },
            {
              component: ImageCarouselPreview,
              name: CUSTOM_FIELDS.draggableMedia.name,
              placeholder: imagePlaceholder,
            },
            {
              component: OrderDeadlinePreview,
              name: COMMON_FIELDS.orderDeadline.name,
              className: 'DeadlinePreview',
              placeholder: '24 hours',
              prefix: <ClockIcon3 className="Icon" />,
              suffix: 'before check out',
              format: FORMATTERS.orderDeadlineFormatter,
            },
            { component: Divider },
            {
              className: 'scrollable-container',
              children: [
                {
                  component: DescriptionPreview,
                  name: COMMON_FIELDS.summarySubtitle.name,
                  // className: 'ServiceDetailSummary',
                  placeholder: <Skeleton height={8} />,
                },
                // {
                //     component: TextPreview,
                //     name: 'serviceChoices',
                //     placeholder: 'Service Choices',
                //     placeholderOnly: true,
                //     formDependant: true,
                // },
                {
                  component: DetailHTMLPreview,
                  heading: 'Description',
                  name: COMMON_FIELDS.detailHTML.name,
                  placeholder: (
                    <>
                      <Skeleton height={8} style={{ marginBottom: 4 }} count={4} />
                      <Skeleton height={8} width="50%" />
                    </>
                  ),
                },
              ],
            },
          ],
        },
        {
          children: [
            {
              component: TextPreview,
              className: 'PreviewTitle',
              name: CUSTOM_FIELDS.serviceInstructions.name,
              placeholder: 'Service Instructions',
              placeholderOnly: true,
            },
            {
              component: PreviewFooter,
              verb: 'view service choices',
              more: true,
            },
          ],
        },
      ],
    },
  ],
}

const CHOICES_PREVIEW = {
  className: 'ListingPreview ServiceDetail device-frame',
  Parent: Accordion,
  title: 'Service Choices Preview',
  subtitle: 'Service Choices',
  id: uniqueId('service_choices_preview'),
  children: [
    {
      className: 'device-preview has-min-height',
      children: [
        {
          name: 'serviceChoices',
          component: ChoicesPreview,
        },
      ],
    },
  ],
}

export const BUNDLE_PREVIEW = {
  0: [SERVICE_LISTING_BUNDLE_PREVIEW, SERVICE_DETAIL_BUNDLE_PREVIEW],
  1: [CHOICES_PREVIEW],
  2: [COMMON_PREVIEW.NOTIFICATION_PREVIEW],
  3: [],
}
