/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'
import { withContent } from '../../components/content/ContentProvider'
import { MasterList } from '../../components/list'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { SubmitButton } from '../../forms'
import { closeModal } from '../../reducers/modals'

import '../../styles/ContentSelectContainer.scss'

const ContentSelectContainer = ({
  selectExistingColums,
  content,
  data,
  propertyId,
  propertyCity,
  totalProperties,
  totalCities,
  openCreateModal,
  dispatch,
  onUpdateItemProperty,
}) => {
  const memoizedColumns = useMemo(() => selectExistingColums, [])

  const currentItems = data.filter(({ propertyIds }) => propertyIds.includes(propertyId)).map(({ id }) => id)

  const selectedRows = currentItems.reduce((accum, curr) => {
    const index = data.findIndex(item => item.id === curr)
    return {
      ...accum,
      [index]: true,
    }
  }, {})

  const [selectedItems, setSelectedItems] = useState(currentItems)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const applyAndUpdate = () => {
    const formattedSelectedItems = selectedItems.map(id => parseInt(id, 10))
    setIsSubmitting(true)
    onUpdateItemProperty({
      id: 'apply/',
      customizationIds: formattedSelectedItems,
    })
      .then(res => {
        setIsSubmitting(false)
        dispatch(closeModal())
      })
      .catch(err => setIsSubmitting(false))
  }

  return (
    <div className="ContentSelectContainer">
      <ModalHeader name={`${content.title} from Content`} verb={{ create: 'Add' }} hasItem={false} />
      <ModalBody>
        <MasterList
          className=""
          setSelectedItems={setSelectedItems}
          columns={memoizedColumns}
          data={data}
          initialState={{
            propertiesCount: totalProperties,
            totalCities,
            selectedRowIds: selectedRows,
            sortBy: [{ id: 'name', desc: false }],
            filters: [{ id: 'city', value: propertyCity }],
          }}
          searchTheme="light square"
          emptyState={{
            text: `You have not yet added any ${content.title} items`,
          }}
        />
      </ModalBody>
      <ModalFooter>
        <div className="link footer-item right" onClick={openCreateModal}>
          + New
          {' '}
          {content.title}
        </div>
        <div className="footer-item">
          <SubmitButton className="button" onClick={applyAndUpdate} isSubmitting={isSubmitting}>
            Apply
          </SubmitButton>
        </div>
      </ModalFooter>
    </div>
  )
}

export default withContent(ContentSelectContainer)
