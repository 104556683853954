import React from 'react'
import Icon from './Icon'

function AdvertisementExampleIcon(props) {
  return (
    <Icon
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 300 150"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {'.prefix__st1{fill:#727272}.prefix__st2{fill:none;stroke:#3f3f3f;stroke-width:4;stroke-miterlimit:10}'}
      </style>
      <path
        d="M300.24 144.68c0 2.75-2.25 5-5 5h-290c-2.75 0-5-2.25-5-5v-140c0-2.75 2.25-5 5-5h290c2.75 0 5 2.25 5 5v140z"
        opacity={0.8}
      />
      <path
        className="prefix__st1"
        d="M22.2 23.68l-1-2.78h-3.82l-.98 2.78h-1.72l3.74-10.04h1.78l3.74 10.04H22.2zm-1.43-4.18l-.94-2.72c-.07-.18-.16-.47-.28-.86s-.2-.68-.25-.86c-.12.56-.3 1.17-.54 1.84l-.9 2.6h2.91zM27.63 23.82c-.94 0-1.68-.34-2.21-1.03s-.79-1.64-.79-2.88.27-2.2.8-2.9 1.28-1.04 2.23-1.04c.99 0 1.75.37 2.27 1.1h.08c-.08-.54-.12-.97-.12-1.29v-2.75h1.61v10.64h-1.26l-.28-.99h-.06c-.52.76-1.27 1.14-2.27 1.14zm.43-1.3c.66 0 1.14-.19 1.44-.56s.46-.97.46-1.81v-.23c0-.95-.15-1.63-.46-2.03s-.8-.6-1.46-.6c-.56 0-1 .23-1.31.69s-.46 1.11-.46 1.96c0 .84.15 1.48.44 1.92s.76.66 1.35.66zM41.07 23.68h-1.63v-8.59H36.5v-1.4h7.51v1.4h-2.94v8.59zM45.27 14.12c0-.29.08-.51.24-.66s.38-.23.67-.23c.28 0 .5.08.66.23s.24.38.24.66c0 .27-.08.49-.24.65s-.38.23-.66.23c-.29 0-.52-.08-.67-.24s-.24-.37-.24-.64zm1.7 9.56h-1.61v-7.56h1.61v7.56zM52.09 22.52c.39 0 .78-.06 1.18-.18v1.21c-.18.08-.41.14-.69.19s-.58.08-.88.08c-1.52 0-2.28-.8-2.28-2.41v-4.07h-1.03v-.71l1.11-.59.55-1.6h.99v1.68h2.15v1.22h-2.15v4.05c0 .39.1.67.29.86s.44.27.76.27zM56.38 23.68h-1.61V13.04h1.61v10.64zM61.98 23.82c-1.18 0-2.1-.34-2.76-1.03s-.99-1.63-.99-2.83c0-1.23.31-2.21.92-2.91s1.46-1.06 2.54-1.06c1 0 1.79.3 2.37.91s.87 1.44.87 2.5v.87h-5.04c.02.73.22 1.3.59 1.69s.9.59 1.58.59c.45 0 .86-.04 1.25-.13s.8-.22 1.24-.42v1.31c-.39.19-.79.32-1.19.4s-.87.11-1.38.11zm-.3-6.62c-.51 0-.92.16-1.23.49s-.49.8-.55 1.42h3.43c-.01-.62-.16-1.1-.45-1.42s-.69-.49-1.2-.49z"
      />
      <g>
        <path
          className="prefix__st2"
          d="M14.68 49.08h273.14M14.68 37.91h273.14M14.68 60.24h273.14M14.68 71.4h273.14M14.68 82.56h164.86"
        />
      </g>
      <g>
        <path
          d="M239.25 127.89c0 1.65-1.35 3-3 3h-170c-1.65 0-3-1.35-3-3v-20c0-1.65 1.35-3 3-3h170c1.65 0 3 1.35 3 3v20z"
          fill="#0086e0"
        />
      </g>
    </Icon>
  )
}

export default AdvertisementExampleIcon
