import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'

import '../../styles/AnalyticsContainer.scss'
import { MESSAGES } from '../../constants/strings'

const COLORS = [
  '#236082',
  '#3d92cc',
  '#f03a48',
  '#46b29d',
  '#d322bc',
  '#15425b',
  '#ff9000',
  '#56adeb',
  '#8E3481',
  '#5DD3DF',
  '#7456eb',
]

const TopSections = ({ params }) => {
  const pieChartSize = 240

  return (
    <Resource collection path={['analytics', 'sections', params]} placeholderComponent={Loading}>
      {({ topSections: { sections, total } }) => {
        const data = Object.values(sections)
          .filter(({ label }) => label !== 'Total')
          .map(({ label: name, count }, index) => ({
            name,
            count,
            color: COLORS[index % COLORS.length],
          }))

        return (
          <div className="TopSections">
            <div className="title">{MESSAGES.TOP_SECTION_BY_PAGEVIEW}</div>
            <div className="donut-wrapper">
              <div
                className="donut-container"
                style={{
                  width: `${pieChartSize}px`,
                  height: `${pieChartSize}px`,
                }}
              >
                <div className="donut-hole">
                  <div className="total-pageviews">{total}</div>
                  <div className="total-label">{MESSAGES.PAGE_VIEWS_LOWERCASE}</div>
                  {' '}
                </div>
                <PieChart width={pieChartSize} height={pieChartSize}>
                  <Pie
                    data={data}
                    cx={pieChartSize / 2 - 5}
                    cy={pieChartSize / 2 - 5}
                    innerRadius={85}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="count"
                  >
                    {data.map(({ color }, index) => (
                      <Cell key={`cell-${index}`} fill={color} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
              <div className="donut-legend">
                {data.map(({ name, count, color }) => (
                  <div className="donut-legend-item" key={name}>
                    <div className="legend-square" style={{ backgroundColor: color }} />
                    <div className="legend-name">{name}</div>
                    <div className="legend-count">{count}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }}
    </Resource>
  )
}

export default TopSections
