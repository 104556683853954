/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { EmptyNavItemPlaceholder } from '../PageStates'
import { ServiceIcon } from '../../icons'
import { openModalWithContainer } from '../../reducers/modals'
import { MasterList } from '../list'
import { tableSettings, updateSortSettings } from '../list/tableSettings'
import { SERVICE_COLUMNS } from '../../constants/tables/services'
import PropertyDrawer from '../drawer/PropertyDrawer'

const ServiceTable = ({
  data,
  rowAction,
  onUpdateItem,
  onDeleteItem,
  properties,
  propertiesCount,
  openRecipientProperties,
  supportPaymentProvider,
  dispatch,
}) => {
  const memoizedColumns = useMemo(() => SERVICE_COLUMNS, [])

  const stateReducer = (state, action) => {
    if (action.type === 'toggleSortBy') {
      updateSortSettings('services', state.sortBy)
    }
  }

  const sortByPreference = tableSettings.get('services.sort', [{ id: 'createdAt', desc: true }])

  const initialState = {
    hiddenColumns: ['createdAt'],
    sortBy: sortByPreference,
    properties,
    propertiesCount,
    supportPaymentProvider,
    openRecipientProperties,
    onUpdateItem,
    onDeleteItem,
    rowAction,
    dispatch,
  }

  return (
    <MasterList
      columns={memoizedColumns}
      data={data}
      rowAction={rowAction}
      initialState={initialState}
      stateReducer={stateReducer}
    />
  )
}

export default connect(null, { openModalWithContainer })(
  ({ services, properties, openModalWithContainer, onUpdateItem, onDeleteItem, paymentProvider, dispatch }) => {
    const [showDrawer, setDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})

    const openRecipientProperties = (data, event) => {
      event.stopPropagation()
      setDrawerContent(data)
      if (data.properties.length > 0) {
        setDrawer(true)
      }
    }

    const rowAction = service => {
      openModalWithContainer(
        'ServiceContainer',
        {
          onSave: onUpdateItem,
          service,
          paymentProviderId: paymentProvider.pk,
        },
        {
          size: 'l',
        }
      )
    }

    return services.length === 0 ? (
      <EmptyNavItemPlaceholder
        icon={<ServiceIcon height="92" width="92" />}
        text={"You haven't setup any services"}
        navItem="a service">
        <span style={{ color: '#3e92cc' }}>
          Click on <b>New Service button</b> to add services
        </span>
      </EmptyNavItemPlaceholder>
    ) : (
      <>
        <PropertyDrawer
          header="Service"
          drawerData={drawerContent}
          showDrawer={showDrawer}
          setDrawer={setDrawer}
          properties={properties}
        />
        <ServiceTable
          data={services}
          rowAction={rowAction}
          properties={properties}
          propertiesCount={properties.length}
          onUpdateItem={onUpdateItem}
          onDeleteItem={onDeleteItem}
          openRecipientProperties={openRecipientProperties}
          supportPaymentProvider={!isEmpty(paymentProvider)}
          dispatch={dispatch}
        />
      </>
    )
  }
)
