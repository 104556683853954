import React from 'react'
import squareIconURL from '../images/icons/Square-window.png'

function SquareIcon(props) {
  return (
    <span fill="none" viewBox="0 0 71 100" {...props}>
      <img src={squareIconURL} alt="square-icon" />
    </span>
  )
}

export default SquareIcon
