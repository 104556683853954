import React, { useMemo, useCallback } from 'react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import { SearchIcon } from '../../icons'
import { MasterList } from '../list'
import { MESSAGES } from '../../constants/strings'

const DevicesTable = ({
  devices,
  COLUMNS,
  blacklistCheckbox,
  selectedDevices,
  setSelectedDevices,
  updatedDevice,
  setRequestSearch,
}) => {
  const memoizedColumns = useMemo(() => COLUMNS, [COLUMNS])
  const handleButtonClick = value => {
    const updatedSelectedDevices = { ...selectedDevices, enabled: value }
    setSelectedDevices(updatedSelectedDevices)
    updatedDevice(updatedSelectedDevices)
  }
  const debounceSearch = useCallback(debounce(setRequestSearch, 250), [])
  const handleSearchChange = event => {
    const { value } = event.target
    debounceSearch(value)
  }
  return (
    <>
      <div className="col-12 mb-3 d-flex justify-content-end">
        <div className="ListSearchAndFilter">
          <div className="Search light">
            <div className="search-icon">
              <SearchIcon size={18} />
            </div>
            <input
              type="search"
              className="search-input"
              placeholder={MESSAGES.SEARCH_PLACEHOLDER}
              data-cy="device-search"
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end mr-3 mt-3 mb-3">
        <b>
          {`${selectedDevices.devices.length} ${selectedDevices.devices.length > 1 ? 'Devices' : 'Device'}  Selected`}
        </b>
        <div className="d-flex">
          <button className="button mr-2" onClick={() => handleButtonClick(true)}>
            {MESSAGES.ENABLE}
          </button>
          <button
            className={classNames('button', { 'disabled-button': blacklistCheckbox })}
            disabled={blacklistCheckbox}
            onClick={() => handleButtonClick(false)}>
            {MESSAGES.DISABLE}
          </button>
        </div>
      </div>
      <MasterList columns={memoizedColumns} data={devices} search={false} />
    </>
  )
}

export default DevicesTable
