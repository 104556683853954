/* eslint-disable no-useless-escape */
import React, { useState, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Wizard } from '../../components/wizard'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { PencilIcon } from '../../icons'
import { generateWizard } from '../../forms/utils'
import {
  CUSTOM_STEPS,
  CUSTOM_WIZARD,
  LATECHECKOUT_WIZARD,
  MID_STAY_CLEAN_WIZARD,
  REBOOK_WIZARD,
  BUNDLE_WIZARD,
  BUNDLE_STEPS,
} from '../../constants/forms/services'
import { changeModalOptions } from '../../reducers/modals'
import Preview from '../../components/form-builder/Preview'
import TemplateChoices from '../../components/services/TemplateChoices'
import { formatProviderId } from '../../utils/'

const DEFAULT_SERVICE_TEMPLATES = [
  {
    // icon: CalendarIcon,
    templateName: 'Property Rebooking',
    templateDescription:
      'Allow your guests to send themselves a promotion to save money when they book directly through your site.',
    name: 'Rebooking Promo',
    verb: 'Add',
    form: REBOOK_WIZARD,
    steps: CUSTOM_STEPS,
    templateKey: 'rebook',
    defaultImage: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_rebooking-1280x960.jpg',
    isUnique: true,
  },
  {
    // icon: ClockIcon,
    templateName: 'Late Check Out',
    templateDescription: 'Give your guest the option of a late check out',
    name: 'Late Check Out',
    verb: 'Add a',
    form: LATECHECKOUT_WIZARD,
    steps: CUSTOM_STEPS,
    templateKey: 'late_checkout',
    defaultImage: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_latecheckout-1280x960.jpg',
    isUnique: true,
  },
  {
    // icon: MopIcon,
    templateName: 'Mid-Stay Cleaning',
    templateDescription: 'Offer your guests the option of an extra cleaning during their stay',
    name: 'Mid-Stay Cleaning',
    verb: 'Add a',
    form: MID_STAY_CLEAN_WIZARD,
    steps: CUSTOM_STEPS,
    templateKey: 'mid_stay_clean',
    defaultImage: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_midstay_cleaning-1280x960.jpg',
    isUnique: true,
  },
]

const SERVICE_TEMPLATES = [
  ...DEFAULT_SERVICE_TEMPLATES,
  {
    icon: PencilIcon,
    templateName: 'Custom Service',
    templateDescription: 'Offer your guests a wide variety of additional services and promotions.',
    name: 'Custom Service',
    verb: 'Add a',
    form: CUSTOM_WIZARD,
    steps: CUSTOM_STEPS,
    templateKey: 'custom',
    showProgressText: false,
  },
  {
    icon: PencilIcon,
    templateName: 'Bundle Service',
    templateDescription: 'Offer your guests a wide variety of additional bundled services.',
    name: 'Bundle Service',
    verb: 'Add a',
    form: BUNDLE_WIZARD,
    steps: BUNDLE_STEPS,
    templateKey: 'bundle',
    showProgressText: false,
  },
]

const ServiceContainer = ({ service, paymentProviderId, onSave, dispatch }) => {
  const { options } = useSelector(state => state.modals)

  const changeModalSize = size => {
    if (options && options.size !== size) {
      dispatch(changeModalOptions({ size, customClass: 'add-custom-service-modal' }))
    }
  }

  const currentSelectedTemplateIndex = SERVICE_TEMPLATES.findIndex(
    ({ templateKey }) => templateKey === service.template
  )

  useEffect(() => {
    if (currentSelectedTemplateIndex !== -1) {
      changeModalSize('xl')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedTemplateIndex])

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(currentSelectedTemplateIndex)
  const selectedTemplate = SERVICE_TEMPLATES[selectedTemplateIndex] || null

  const setSelectedTemplateIndexHandler = _index => {
    changeModalSize('xl')
    setSelectedTemplateIndex(_index)
  }

  if (selectedTemplateIndex === -1) {
    return (
      <Fragment>
        <ModalHeader
          name="Template"
          verb={{ create: 'Choose a' }}
          setChoice={setSelectedTemplateIndexHandler}
          hideActionButtons={false}
        />

        <div className="choose-template-modal">
          <ModalBody>
            <TemplateChoices choices={DEFAULT_SERVICE_TEMPLATES} setChoice={setSelectedTemplateIndexHandler} />
          </ModalBody>
          <div className="footer-bar">
            <span>
              <i className="necicon-star" />
              Unique Templates
            </span>
          </div>
        </div>
      </Fragment>
    )
  }
  const serviceFormData = generateWizard({
    form: selectedTemplate.form,
    props: service,
  })

  const formatValues = v => {
    const data = {
      id: service.id,
      ...v,
      paymentProviderId: formatProviderId(v, paymentProviderId, selectedTemplate.templateKey),
      template: selectedTemplate.templateKey,
      orderDeadline: v.orderDeadline
        ? v.orderDeadlineFormat === 'hours'
          ? v.orderDeadline * 60
          : v.orderDeadline
        : null,
      serviceMedias: v.serviceMedias ? v.serviceMedias : [],
      serviceChoices: v.serviceChoices ? v.serviceChoices : [],
    }

    return data
  }

  const formData = {
    ...serviceFormData,
    initialValues: {
      ...serviceFormData.initialValues,
      supportPaymentProvider: !!paymentProviderId,
      hasPaymentProvider: service.id ? !!service.paymentProviderId : !!paymentProviderId,
      template: selectedTemplate.templateKey,
      id: service.id,
    },
  }

  const preview = Preview('service', selectedTemplate.templateKey, selectedTemplate.form)

  const titleComponent = props => (
    <ModalHeader
      name={service.name || selectedTemplate.name}
      verb={{ create: selectedTemplate.verb }}
      hasItem={service.id}
      showProgressText={false}
      {...props}
    />
  )
  const footerComponent = props => <ModalFooter {...props} />

  const onSaveText = service.id ? 'Update' : 'Publish'

  return (
    <Wizard
      titleComponent={titleComponent}
      bodyWrapper={ModalBody}
      bodyWrapperClass="ServiceModalBody"
      formData={formData}
      steps={SERVICE_TEMPLATES[selectedTemplateIndex].steps}
      previewTemplate={SERVICE_TEMPLATES[selectedTemplateIndex].preview}
      footerWrapper={footerComponent}
      formatValues={formatValues}
      onSave={onSave}
      preview={preview}
      dispatch={dispatch}
      onSaveText={onSaveText}
    />
  )
}

export default ServiceContainer
