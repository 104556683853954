import React, { useState, useEffect } from 'react'
import { template } from 'lodash'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import moment from 'moment'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { apiRequest } from '../../reducers/data'
import '../../styles/EmailSetup.scss'
import { CloseIcon } from '../../icons'
import Tooltip from '../../components/Tooltip'
import emailIcon from '../../images/emailicon.png'
import Loader from '../../icons/Loader'
import ExtractAdReport from './ExtractAdsReport'
import ChipsInput from '../../components/ChipsInput'
import { displayErrorToast, displaySuccessToast } from '../../utils/displayToast'

const EmailSetup = ({ dispatch, closeModal, advertisement, me, onRefresh }) => {
  const advertisementId = advertisement.id
  const apiUrl = `promos/${advertisementId}/report_email`
  const monthDays = [...Array(31).keys()]

  const [status, setStatus] = useState(true)
  const [reportingDay, setReportingDay] = useState(1)
  const [editMode, setEditMode] = useState(true)
  const [loading, setLoading] = useState(false)
  const [emailNow, setEmailNow] = useState(true)

  const [adEnabled, setAdEnabled] = useState(true)
  const yesterday = moment()
    .subtract(1, 'd')
    .toDate()
  const [startDate, setStartDate] = useState(yesterday)
  const [endDate, setEndDate] = useState(yesterday)

  useEffect(() => {
    const fetchReportEmail = async () => {
      const { json } = await dispatch(apiRequest([apiUrl], 'GET'))
      if (!json.length) {
        setEditMode(false)
      } else {
        const { status, reportingDay, emails } = json[0]
        setStatus(status)
        setReportingDay(reportingDay)
        setSelectedItem(emails)
      }
    }
    !emailNow && fetchReportEmail()
  }, [emailNow])

  const getDayName = day => {
    let name
    switch (day) {
      case 1:
      case 21:
      case 31:
        name = `${day}st`
        break
      case 2:
      case 22:
        name = `${day}nd`
        break
      case 3:
      case 23:
        name = `${day}rd`
        break
      default:
        name = `${day}th`
        break
    }
    return name
  }

  const close = () => {
    if (!selectedItem.length && status) {
      displayErrorToast('Please enter atleast 1 email address')
      return
    }
    setLoading(true)
    const email = {
      advertisementId,
      status,
      reportingDay,
      emails: selectedItem,
    }
    const method = editMode ? 'PUT' : 'POST'
    dispatch(apiRequest([apiUrl], method, email)).then(closeModal)
  }

  // Tags Input For Schedule
  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState([])

  // Tags Input For Imediate
  const [inputValueNow, setInputValueNow] = React.useState('')
  const [selectedItemNow, setSelectedItemNow] = React.useState([])
  const [reportType, setReportType] = useState(0)

  const downloadReport = () => {
    if (!startDate) {
      displayErrorToast('Please select date first')
      return
    }

    if (!selectedItemNow.length) {
      displayErrorToast('Please enter atleast 1 email address')
      return
    }
    const modifAds = JSON.stringify(advertisement)
    const { id } = advertisement
    const _startDate = moment(startDate).format('YYYY-MM-DD')
    const _endDate = moment(endDate).format('YYYY-MM-DD')
    fetch(`/api/dashboard/promos/${id}/ads_report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: _startDate,
        endDate: _endDate,
        advertisement: modifAds,
        type: reportType ? 'excel' : 'pdf',
        emailsTo: selectedItemNow,
      }),
    })
      // .then(response => response.blob())
      .then(blob => {
        displaySuccessToast('The ads report will be sent to the provided email address(es)')
        closeModal()
      })
  }

  return (
    <div>
      <ModalHeader>Email Report Setup</ModalHeader>
      <ModalBody className="email-report-setup pt-2">
        <ul className="nav Tabs">
          <li className="nav-item">
            <a className={emailNow ? 'nav-link active' : 'nav-link'} onClick={() => setEmailNow(true)}>
              Send Email Immediately
            </a>
          </li>
          <li className="nav-item">
            <a className={emailNow ? 'nav-link' : 'nav-link active'} onClick={() => setEmailNow(false)}>
              Schedule an Email
            </a>
          </li>
        </ul>
        <br />
        {emailNow ? (
          <ExtractAdReport
            dispatch={dispatch}
            advertisement={advertisement}
            closeModal={closeModal}
            setLoading={setLoading}
            adEnabled={adEnabled}
            setAdEnabled={setAdEnabled}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            inputValueNow={inputValueNow}
            setInputValueNow={setInputValueNow}
            selectedItemNow={selectedItemNow}
            setSelectedItemNow={setSelectedItemNow}
            reportType={reportType}
            setReportType={setReportType}
          />
        ) : (
          <div>
            <p className="noteInEmail">
              {' '}
              Note: An email will be send out on the selected date with the previous month’s data{' '}
            </p>
            <div className="d-flex mt-2 ">
              <div>
                <img src={emailIcon} alt="emailIcon" width="100px" height="100px" />
              </div>
              <div className="ml-4 w-100">
                <div className="d-flex align-items-center mb-2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      value={status}
                      checked={status}
                      onChange={e => setStatus(e.target.checked)}
                    />
                    <span className="slider round" />
                  </label>
                  <h4 className="mb-2 ml-2">
                    Receive Monthly Reports
                    <Tooltip tabIndex={-1} target="emailScheduled">
                      Turn on to receive Ads PDF report on your specified email addresses on a set date
                    </Tooltip>
                  </h4>
                </div>

                <div className="mb-3">
                  <h4 className="mb-2">Select a day to receive reports</h4>
                  <div className="select-month-day">
                    <select
                      name="day"
                      id="day"
                      value={reportingDay}
                      onChange={e => setReportingDay(Number(e.target.value))}>
                      {monthDays.map((day, i) => (
                        <option key={i} value={day + 1}>
                          {getDayName(day + 1)}
                        </option>
                      ))}
                    </select>
                    <p className="everyMonth"> Of every month </p>
                  </div>
                  {reportingDay > 28 ? (
                    <p className="noteOnEmailSetup">
                      {' '}
                      Note : If the selected date is not arriving in current month, then report will be emailed on last
                      day of the month.
                    </p>
                  ) : (
                    ''
                  )}
                </div>

                <div>
                  <h4 className="mt-1 mb-2">
                    Email Recipients
                    <Tooltip tabIndex={-1} target="email">
                      Press enter after every email to add multiple recipients
                    </Tooltip>
                  </h4>

                  <ChipsInput
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <div className="LoadingDonwload">
            <Loader />
          </div>
        ) : emailNow ? (
          <button onClick={downloadReport} disabled={!adEnabled} className="btn btn-primary">
            Send Email
          </button>
        ) : (
          <button onClick={close} className="btn btn-primary">
            Save Changes
          </button>
        )}
      </ModalFooter>
    </div>
  )
}

export default EmailSetup
