import { get } from 'lodash'
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import Select from 'react-select'
import { GoToIcon } from '../../icons'
import '../../styles/WelcomeScreenContent.scss'
import { withContent } from '../content/ContentProvider'
import { PopoverMenu, PopoverMenuItem } from '../ui'
import '../../styles/ContentPicker.scss'
import '../../styles/Select.scss'
import { ListCellBadge } from '../list'
import { MESSAGES } from '../../constants/strings'
import PropertyDrawer from '../drawer/PropertyDrawer'
import { pluralize } from '../../Utils'

const SelectLabel = ({
  screen: {
    name,
    isDefault,
    content: { status },
  },
}) => (
  <div className="SelectLabel">
    {name}
    {isDefault && <ListCellBadge label="Default" />}
    {!isDefault && !status && <ListCellBadge label="Disabled" />}
  </div>
)

const WelcomeScreenContent = ({
  data = [],
  property,
  manageLink,
  onUpdateItem,
  emptyState,
  totalProperties,
  history,
  content,
}) => {
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})
  const selectedWelcomeScreen =
    data && data.find(screen => screen.propertyIds && screen.propertyIds.includes(property.id))
  const EmptyIcon = get(emptyState, 'icon')
  const emptyText = get(emptyState, 'text')

  const changeHandler = ({ value }) => {
    if (selectedWelcomeScreen && selectedWelcomeScreen.id === value.id) return
    onUpdateItem({ ...value, propertyIds: [...value.propertyIds, property.id] })
  }

  const filterHandler = (option, searchText) => {
    if (option.data.value.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true
    }
    return false
  }

  const editScreenHandler = () => {
    history.push(`${manageLink}/?edit=${selectedWelcomeScreen.id}`)
  }

  const addScreenHandler = () => {
    history.push(`${manageLink}/?add=${content.type}`)
  }

  const openRecipientProperties = (data, event) => {
    event.stopPropagation()
    setDrawerContent(data)
    setDrawer(true)
  }

  const options = data.reduce((options, screen) => {
    options.push({
      value: screen,
      label: <SelectLabel screen={screen} />,
    })
    return options
  }, [])

  return (
    <>
      <PropertyDrawer header="Content" drawerData={drawerContent} showDrawer={showDrawer} setDrawer={setDrawer} />
      <div className="ContentPicker WelcomeScreenCard">
        <div className="select-wrapper">
          <Select
            classNamePrefix="select"
            className="select-list"
            options={options}
            onChange={changeHandler}
            filterOption={filterHandler}
            value={options.filter(option => option.value.id === (selectedWelcomeScreen && selectedWelcomeScreen.id))}
          />
          <PopoverMenu>
            {selectedWelcomeScreen && (
              <PopoverMenuItem className="properties-applied">
                <div className="meta">{MESSAGES.USED_BY}</div>
                <div className="bold">
                  {selectedWelcomeScreen.propertyIds ? (
                    <div
                      className="link-text"
                      onClick={event => {
                        openRecipientProperties(selectedWelcomeScreen, event)
                        event.stopPropagation()
                      }}>
                      {`${
                        selectedWelcomeScreen.propertyIds ? selectedWelcomeScreen.propertyIds.length : 0
                      } of ${totalProperties} ${pluralize(totalProperties, 'property')}`}
                    </div>
                  ) : (
                    <div>
                      {`${selectedWelcomeScreen.propertyIds.length} of ${selectedWelcomeScreen} ${pluralize(
                        selectedWelcomeScreen,
                        'property'
                      )}`}
                    </div>
                  )}
                </div>
              </PopoverMenuItem>
            )}
            {selectedWelcomeScreen && (
              <PopoverMenuItem onClick={editScreenHandler}>
                <GoToIcon /> {MESSAGES.OPEN_THIS_COMPONENT}
              </PopoverMenuItem>
            )}
            <PopoverMenuItem onClick={addScreenHandler}>
              <GoToIcon /> {MESSAGES.CREATE_NEW_COMPONENT}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
        {selectedWelcomeScreen ? (
          <div className="welcome-screen-preview px-4">
            <img
              className="welcome-screen-preview-img"
              key={selectedWelcomeScreen.content.background || content.defaultUrl}
              src={selectedWelcomeScreen.content.background || content.defaultUrl}
              alt="welcome-screen-preview"
            />
            <h4>{selectedWelcomeScreen.name}</h4>
            <p
              className="welcome-screen-preview-text"
              dangerouslySetInnerHTML={{
                __html: selectedWelcomeScreen.content.text,
              }}
            />
          </div>
        ) : (
          <div className="empty-list no-border welcome-screen-empty">
            {EmptyIcon && <EmptyIcon size={40} />}
            {emptyText && <div>{emptyText}</div>}
            <a href={addScreenHandler}>
              <p onClick={addScreenHandler}>Click here to add Welcome Video</p>
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default withRouter(withContent(WelcomeScreenContent))
