import React from 'react'
import Icon from './Icon'

function PencilIcon(props) {
  return (
    <Icon viewBox="0 0 36 36" {...props}>
      <defs>
        <path
          d="M29.547.785c.78-.78 2.039-.785 2.84.015l2.801 2.802c.789.789.808 2.048.015 2.84L8.33 33.316c-.78.78-2.28 1.586-3.382 1.806l-3.132.626C.728 35.966.02 35.275.24 34.173l.627-3.132c.217-1.088 1.014-2.59 1.806-3.382L29.547.785zM4.089 26.243L9.746 31.9l-1.414 1.414-5.657-5.657 1.414-1.414zM25.302 5.03l5.657 5.656-1.414 1.415-5.657-5.657 1.414-1.414z"
          id="ap"
        />
      </defs>
      <g fill="fill" fillRule="evenodd">
        <mask id="pb" fill="#fff">
          <use xlinkHref="#ap" />
        </mask>
        <use fill="fill" xlinkHref="#ap" />
        <g mask="url(#pb)" fill="fill">
          <path d="M-6-6h48v48H-6z" />
        </g>
      </g>
    </Icon>
  )
}

export default PencilIcon
