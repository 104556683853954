/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import Resource from '../../Resource'
import { Loading } from '../../components/PageStates'

import '../../styles/AnalyticsContainer.scss'
import { MasterList } from '../../components/list'
import { MESSAGES } from '../../constants/strings'

const COLUMNS = [
  {
    id: 'topPages',
    columns: [
      {
        Header: 'Page Name',
        accessor: 'name',
        Cell: ({ cell: { value } }) => (
          <div className="page-name capitalize">{value == 'house rules' ? 'Property Guidelines' : value}</div>
        ),
      },
      {
        Header: 'Section',
        accessor: 'section',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => <div>{value}</div>,
      },
      {
        Header: 'Properties',
        accessor: 'properties',
        Cell: ({ cell: { value }, initialState: { numProperties } }) => (
          <div>
            {value} of {numProperties}
          </div>
        ),
        options: {
          hideOn: ['mobile'],
        },
      },
      {
        id: 'views',
        Header: 'Pageviews',
        accessor: 'views',
        Cell: ({ cell: { value } }) => <span className="views">{value}</span>,
        // options: {
        //   classNames: 'right',
        // },
      },
    ],
  },
]

const TopPages = ({ singleProperty, numProperties, params }) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  const hiddenColumns = singleProperty ? ['properties'] : []
  const initialState = { hiddenColumns, numProperties, sortBy: [{ id: 'views', desc: true }] }
  return (
    <Resource collection path={['analytics', 'pages', params]} placeholderComponent={Loading}>
      {({ topPages = [] }) => {
        return (
          <div className="TopPages">
            <div className="title">{MESSAGES.TOP_PAGES}</div>
            <div className="top-pages-list">
              <MasterList columns={memoizedColumns} data={topPages} initialState={initialState} search={false} />
            </div>
          </div>
        )
      }}
    </Resource>
  )
}

export default TopPages
