import React from 'react'
import { connect } from 'formik'
import { CheckboxField } from './ChoiceField'
import { PhoneField, TextField } from '.'

const ApprovalField = ({ formik: { values, setFieldValue } }) => {
  const { hasPaymentProvider } = values
  const label = !hasPaymentProvider ? (
    'Require Service Request Approval'
  ) : (
    <>
      Automatically required when processing payment through
      {' '}
      <b>Stripe</b>
    </>
  )
  return (
    <div className="ApprovalField row">
      <div className="col-sm-12 mb-3">
        <CheckboxField fast={false} name="requireApproval" label={label} disabled={hasPaymentProvider} />
      </div>
      <div className="col-sm-12 fields phone-email">
        <div className="field">
          <PhoneField name="approvalNumber" placeholder="Mobile Phone Number" fast={false} />
        </div>
        <div className="or-text">and / or</div>
        <div className="field">
          <TextField name="approvalEmail" placeholder="Email Address" fast={false} />
        </div>
      </div>
    </div>
  )
}

export default connect(ApprovalField)
