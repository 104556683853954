import React, { useContext, createContext } from 'react'

const FilterContext = createContext()

export const FilterProvider = props => {
  return <FilterContext.Provider {...props} />
}

export const useTableFilter = () => {
  const filterContext = useContext(FilterContext)
  return filterContext
}

export const ListFilterAction = ({ filterOptions, setFilter, selectedFilter }) => {
  return (
    <select
      defaultValue={selectedFilter}
      onChange={evt => setFilter(evt.target.value)}
      className="form-control select-filter"
    >
      {filterOptions.map((option, index) => (
        <option value={index}>{option.label}</option>
      ))}
    </select>
  )
}
