import React from 'react'
import '../styles/PropertyGridContainer.scss'
import moment from 'moment'
import ServiceRequestItem from '../components/services/ServiceRequestItem'

const ServiceRequestGridContainer = ({ data, ...props }) => {
  return (
    <div className="PropertyGrid service-request-grid grid-height-auto">
      {data
        .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt))
        .map(request => (
          <ServiceRequestItem request={request} {...props} />
        ))}
    </div>
  )
}

export default ServiceRequestGridContainer
