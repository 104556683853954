/* eslint-disable react/display-name */
import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { get } from 'lodash'
import { formatters, getSortableAddress } from '../Utils'
import { MasterList } from '../components/list'
import { TablePreviewImage } from '../components/Table'
import PropertyReservationItem from '../components/Property/PropertyReservationItem'
import { PropertyAddress } from '../components/Property'
import { User } from '../components'
// import propertyDummyURL from '../images/home-photo-placeholder-2x.png'
import fallbackAvatar from '../images/home-photo-placeholder.jpg'

import { PopoverMenu, PopoverMenuItem } from '../components/ui'
import { PROPERTY_OPTIONS, PROPERTY_STATUS, DATE_FORMATS } from '../constants/forms/propertyInfo'
import { MESSAGES } from '../constants/strings'

const ARCHIVE_COLUMNS = [
  {
    id: 'properties',
    columns: [
      {
        accessor: 'photoURL',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <TablePreviewImage
            style={!value ? { objectFit: 'contain' } : null}
            src={value || fallbackAvatar}
            fallbackIcon={fallbackAvatar}
          />
        ),
        options: {
          classNames: 'shrink',
        },
      },
      {
        id: 'id',
        Header: 'Address',
        accessor: row => getSortableAddress(row),
        sortType: (a, b) => {
          const addressNameA = get(a, 'original.addressLine1', '')
          const addressNameB = get(b, 'original.addressLine1', '')
          return addressNameA.toLowerCase().localeCompare(addressNameB.toLowerCase())
        },
        Cell: ({ row: { original } }) => <PropertyAddress {...original} />,
      },
      {
        Header: 'ARCHIVED ON',
        accessor: 'updatedAt',
        Cell: ({ cell: { value } }) => <div>{moment(value).format(DATE_FORMATS.FULL_DATE)}</div>,
        options: {
          hideOn: ['tablet', 'mobile'],
        },
      },
      {
        Header: 'HOST',
        accessor: 'host.name',
        Cell: ({ row }) => (row.original.host ? <User user={row.original.host} /> : null),
        options: {
          hideOn: ['tablet', 'mobile'],
        },
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original }, initialState: { onArchive } }) => (
          <PopoverMenu>
            <PopoverMenuItem
              onClick={() => {
                onArchive(original, PROPERTY_STATUS.UNARCHIVE)
              }}
            >
              {`${PROPERTY_OPTIONS.RELIST_PROPERTY}`}
            </PopoverMenuItem>
          </PopoverMenu>
        ),
        options: {
          classNames: 'shrink',
        },
      },
    ],
  },
]
const COLUMNS = [
  {
    id: 'properties',
    columns: [
      {
        accessor: 'photoURL',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <TablePreviewImage
            style={!value ? { objectFit: 'contain' } : null}
            src={value || fallbackAvatar}
            fallbackIcon={fallbackAvatar}
          />
        ),
        options: {
          classNames: 'shrink',
        },
      },
      {
        id: 'id',
        Header: 'Address',
        accessor: row => getSortableAddress(row),
        sortType: (a, b) => {
          const addressNameA = get(a, 'original.addressLine1', '')
          const addressNameB = get(b, 'original.addressLine1', '')
          return addressNameA.toLowerCase().localeCompare(addressNameB.toLowerCase())
        },
        Cell: ({ row: { original } }) => <PropertyAddress linkStyle {...original} />,
      },
      {
        Header: 'Property ID',
        accessor: 'devicePIN',
        Cell: ({ cell: { value } }) => <div>{formatters.ccode(value)}</div>,
        options: {
          hideOn: ['tablet', 'mobile'],
        },
      },
      {
        Header: 'Reservation Status',
        accessor: row => get(row, 'currentOrNextReservation.checkin', null),
        sortType: (a, b) => {
          const beginDateA = get(a, 'original.currentOrNextReservation.checkin', null)
          const beginDateB = get(b, 'original.currentOrNextReservation.checkin', null)

          return (
            (beginDateA === null) - (beginDateB === null) || +(beginDateA > beginDateB) || -(beginDateA < beginDateB)
          )
        },
        Cell: ({ row }) => <PropertyReservationItem {...row.original.currentOrNextReservation} />,
        options: {
          hideOn: ['mobile'],
        },
      },
      {
        Header: 'HOST',
        accessor: 'host.name',
        Cell: ({ row }) => (row.original.host ? <User user={row.original.host} /> : null),
        options: {
          hideOn: ['tablet', 'mobile'],
        },
      },
      {
        accessor: 'updatedAt',
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original }, initialState: { onArchive } }) => (
          <PopoverMenu>
            <PopoverMenuItem
              onClick={() => {
                onArchive(original, PROPERTY_STATUS.ARCHIVE)
              }}
            >
              {`${PROPERTY_OPTIONS.ARCHIVE_PROPERTY}`}
            </PopoverMenuItem>
          </PopoverMenu>
        ),
        options: {
          classNames: 'shrink',
        },
      },
    ],
  },
]

function PropertyList({
 data, history, archive, onArchive,
}) {
  const rowAction = ({ id }) => {
    if (archive) {
      return null
    }
    history.push(`/properties/${id}`)
  }

  const memorizedColumns = archive ? ARCHIVE_COLUMNS : COLUMNS
  const initialState = {
    hiddenColumns: archive ? [] : ['updatedAt'],
    sortBy: [{ id: 'updatedAt', desc: true }],
    archive,
    onArchive,
  }

  return (
    <div className>
      {archive && (
        <p className="pb-2">
          <b>{`${MESSAGES.IMPORTANT_NOTE}:`}</b>
          {MESSAGES.ARCHIVED_PROPERTY_CANNOT_BE_UPDATED}
        </p>
      )}
      <MasterList
        className={archive ? 'archive-table' : ''}
        columns={memorizedColumns}
        disabled={archive === true}
        data={data}
        rowAction={rowAction}
        initialState={initialState}
        search={false}
      />
    </div>
  )
}

function PropertyListContainer({
 results, history, archive, onArchive,
}) {
  return <PropertyList data={results} columns={COLUMNS} history={history} archive={archive} onArchive={onArchive} />
}

export default withRouter(PropertyListContainer)
