import S3Upload from 'react-s3-uploader/s3upload'
import { S3_ROOT_URL } from '../constants'

const func = () => null

const s3 = {
  upload: ({
 file, subfolder, onProgress = func, onError = func, onFinished = func, ...props
}) => new S3Upload({
      files: [file],
      contentDisposition: 'auto',
      uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
      onProgress,
      onError,
      onFinishS3Put: onFinished,
      server: '/api/dashboard',
      signingUrl: '/s3/sign',
      signingUrlQueryParams: { path: subfolder || 'uploaded-media/' },
      s3Url: S3_ROOT_URL,
      ...props,
    }),
}

export default s3
