import React from 'react'
import { isObject } from 'lodash'
import { useSelector } from 'react-redux'
import Tabs from '../components/Tabs'
import { GeneralInfo, HostsAndUsers, Preferences } from '../components/settings'
import TabHeader from '../components/TabHeader'
import { thirdPartyAnalytics } from '../services'
import { EVENTS } from '../constants/thirdPartyAnalytics'

import '../styles/SettingsContainer.scss'
import '../styles/Link.scss'

const SETTINGS_TABS = [
  {
    slug: '',
    component: GeneralInfo,
    label: 'General Info',
  },
  {
    slug: 'hosts-and-users',
    component: HostsAndUsers,
    label: 'Hosts & Users',
  },
  {
    slug: 'preferences',
    component: Preferences,
    label: 'Preferences',
  },
]

const SettingsContainer = props => {
  const { me: userInfo } = useSelector(state => state.data)
  const analyticsProperties = {
    organization: userInfo && userInfo.organization.name,
    user: userInfo && `${userInfo.firstName} ${userInfo.lastName}`,
  }
  const recordAnalytics = properties => {
    let updatedProperties = { ...analyticsProperties }
    if (isObject(properties)) updatedProperties = { ...updatedProperties, ...properties }
    thirdPartyAnalytics.track(EVENTS.SETTINGS, updatedProperties)
  }
  return (
    <div className="SettingsContainer">
      <TabHeader title="Settings" />
      <Tabs path="settings" tabs={SETTINGS_TABS} recordAnalytics={recordAnalytics} {...props} />
    </div>
  )
}

export default SettingsContainer
