import React from 'react'
import WelcomeVideoContent from '../../components/WelcomeScreenAndVideo/WelcomeVideoContent'
import { WELCOME_VIDEO } from '../../constants/forms/propertyInfo'
import { WelcomeVideosIcon } from '../../icons'

const WelcomeVideo = ({ property }) => {
  return (
    <WelcomeVideoContent
      property={property}
      content={WELCOME_VIDEO}
      emptyState={{
        icon: WelcomeVideosIcon,
        text: 'You have not yet added Welcome Video for this property',
      }}
      manageLink="/content/welcome-screen/videos"
    />
  )
}

export default WelcomeVideo
