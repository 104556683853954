/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import AddAdminsInstallers from './AddAdminsInstallers'
import OnboardingBreadcrumbs from './OnboardingBreadcrumbs'
import QuestionCircleIcon from '../../icons/QuestionCircleIcon'
import BackButton from './buttons/BackButton'
import SaveContinueButton from './buttons/SaveContinueButton'

const AddAdminsInstallersStep = ({ me, onSaveOrganization }) => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="onboardingForm">
            <div id="header">
              <img src="/gvg-logo-white-updated.svg" alt="GuestView Guide" className="signup-hdr-logo" />
            </div>
            <div className="main-content">
              <OnboardingBreadcrumbs step={5} length={5} />
              <h2 className="h2">Add Users</h2>
              <div className="default-body green-body definitions-area">
                <div className="row">
                  <div className="col-sm-1 tooltip-add-users">
                    <QuestionCircleIcon height="30" width="30" />
                  </div>
                  <div className="col-sm-11 definition">
                    <p>
                      <strong>Administrators / Managers</strong> will be able to login to the GuestView Guide Management
                      Console and view and edit content across all properties.
                    </p>
                    {/*  <p>
                      <strong>Installers / Maintenance</strong>
                      {' '}
                      users will NOT be able to access the GuestView Guide
                      Management Console, but will have access to the Display Passcode to pair and manage displays.
  </p> */}
                  </div>
                </div>
              </div>
              <div className="default-body">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Administrators / Managers</strong>
                  </div>
                  <div className="col-sm-8">
                    <AddAdminsInstallers role="MANAGER" me={me} />
                  </div>
                </div>
                {/*  <div className="row" style={{ marginTop: '30px' }}>
                  <div className="col-sm-4">
                    <strong>Installers / Maintenance</strong>
                  </div>
                  <div className="col-sm-8">
                    <AddAdminsInstallers role="MAINTENANCE" />
                  </div>
</div> */}
              </div>
              <div id="footer">
                <BackButton to="/onboarding/4" />
                <SaveContinueButton to="/properties" onClick={() => onSaveOrganization({ hasOnboarded: true })}>
                  Finish
                </SaveContinueButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AddAdminsInstallersStep
