import * as React from 'react'
import Icon from './Icon'

function HomeIcon(props) {
  return (
    <Icon viewBox="0 0 51 50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.98 10c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10-10 4.477-10 10zm18 0a8 8 0 11-16 0 8 8 0 0116 0zm-8-6a1 1 0 00-1 1v4h-4a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V5a1 1 0 00-1-1zM24.013 6.021l3.062 2.465A12.124 12.124 0 0026.98 10c0 6.627 5.373 12 12 12 1.412 0 2.767-.244 4.024-.691l4.813 3.874a.489.489 0 01-.305.87h-4.958a.488.488 0 00-.488.488v14.657H28.404v-5.374a.488.488 0 00-.488-.489H20.11a.488.488 0 00-.488.489v5.374H5.96v-13.68a.977.977 0 00-.976-.977H.488a.488.488 0 01-.31-.866L24.013 6.021z"
      />
    </Icon>
  )
}

export default HomeIcon
