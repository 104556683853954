import {
  CONTACT_HOST_PREVIEW,
} from '../../constants/forms/preview/contactHostPreview'

const { CUSTOM_PREVIEW, BUNDLE_PREVIEW, REBOOK_PREVIEW, MESSAGE_PREVIEW } = require('../../constants/forms/preview')

const getGenericModulePreview = (module, template) => {
  switch (module) {
    case 'contact-host':
      return CONTACT_HOST_PREVIEW
    case 'message':
      return MESSAGE_PREVIEW
    case 'service':
      switch (template) {
        case 'custom':
          return CUSTOM_PREVIEW
        case 'rebook':
          return REBOOK_PREVIEW
        case 'late_checkout':
          return CUSTOM_PREVIEW
        case 'mid_stay_clean':
          return CUSTOM_PREVIEW
        case 'bundle':
          return BUNDLE_PREVIEW

        default:
          return {}
      }

    default:
      return {}
  }
}

export default getGenericModulePreview
