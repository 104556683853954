import React from 'react'
import Icon from './Icon'

function BackArrowIcon(props) {
  return (
    <Icon viewBox="0 0 20 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.38 6.217A1.002 1.002 0 0 0 0 7.01a.99.99 0 0 0 .292.702l5.663 5.663c.382.382 1.02.387 1.41-.003a.995.995 0 0 0 .004-1.411L3.407 8h15.601c.537 0 .992-.448.992-1 0-.556-.444-1-.992-1H3.425l3.944-3.944A1.004 1.004 0 0 0 7.366.645.995.995 0 0 0 5.955.642L.38 6.217z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default BackArrowIcon
