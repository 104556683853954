import React from 'react'
import { connect } from 'formik'
import { isArray } from 'lodash'
import { PhoneField, TextField } from '.'

const ContactToggleField = ({
  saveOnExit, phoneLabel, name, readOnly, formik, disabled,
}) => {
  const isDisabled = disabled?.(formik) || readOnly;
  const isNameArray = isArray(name)
  return (
    <div className="ApprovalField row">
      <div className="col-sm-12 fields phone-email">
        <div className="field">
          <PhoneField
            name={isNameArray ? name[0] : 'content.contactPhone'}
            placeholder="Phone Number"
            fast={false}
            disabled={isDisabled}
            saveOnExit={saveOnExit}
            label={phoneLabel}
          />
        </div>
        <div className="or-text">and / or</div>
        <div className="field">
          <TextField
            name={isNameArray ? name[1] : 'content.contactEmail'}
            placeholder="Email Address"
            fast={false}
            disabled={isDisabled}
            saveOnExit={saveOnExit}
            label="Email Address"
          />
        </div>
      </div>
    </div>
  )
}

export default connect(ContactToggleField)
