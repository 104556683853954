import React, { useEffect, useState } from 'react'
import { QueryParamProvider } from 'use-query-params'
import {
  withRouter, Route, Switch, Redirect,
} from 'react-router-dom'
import { Container, Row } from 'reactstrap'
import { ToastContainer, Zoom } from 'react-toastify'
import * as Navigation from './Navigation'
import { apiRequest } from './reducers/data'
import { toggleNavigationView } from './reducers/config'

import {
  AnnouncementContainer,
  MessagesContainer,
  ModalContainer,
  OnboardingContainer,
  PropertyContainer,
  PropertyAddContainer,
  PropertyDetailContainer,
  RecommendationAddContainer,
  ServicesContainer,
  SettingsContainer,
  AnalyticsContainer,
  AdminContainer,
  ContentContainer,
  AdvertisementsContainer,
  RevenueShareContainer,
  PasswordChangeContainer,
  HomeContainer,
} from './containers'
import OrganizationSwitcherContainer from './containers/superadmin/OrganizationSwitcherContainer'
import { Loading } from './components/PageStates'
import TaskManager from './components/TaskManager'
import Resource from './Resource'
import { NotificationPopup, ScrollToTop } from './components'
import { hasAccessTo } from './resources/features'
import './styles/App.scss'
import { thirdPartyAnalytics } from './services'
import 'react-toastify/dist/ReactToastify.css';
import OAuthPopup from './containers/Popup/OAuthPopup'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { TIME_OPTIONS } from './constants'
const ManagementApp = ({ me, onSave, onRefresh, dispatch, props }) => {
  const [config, setConfig] = useState({})
  useEffect(() => {
    window.gtag('set', 'userId', me.id)
  }, [me.id])

  // useEffect(() => {
  //   const eventName = 'Page View Admin'
  //   const path = props.location && props.location.pathname ? props.location.pathname : ''
  //   thirdPartyAnalytics.track(eventName, {
  //     path,
  //     organizationName: me.organization ? me.organization.name : '',
  //     organizationId: me.organization ? me.organization.id : '',
  //     userId: me.id,
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.location])
  useEffect(() => {
    //1. We are saving loggedInTime key to local storage on every login and sending mixpanel event for login.
    const isLoggedInTime = localStorage.getItem(
      'loggedInTime'
    )

    if (!isLoggedInTime) {
      thirdPartyAnalytics.track('sign_in', {
        organization: me.organization ? me.organization.name : '',
        user: me && `${me.firstName} ${me.lastName}`,
        pm_id: me.email,
        date_of_login: `${new Date().toLocaleDateString('en-US', TIME_OPTIONS)} `,
        time_of_login: new Date().toLocaleTimeString(),
      })
      localStorage.setItem(
        'loggedInTime',
        new Date()
      )
      localStorage.setItem(
        'me',
        JSON.stringify(me)
      )
    }
  }, [])

  useEffect(() => {
    const getMaintenanceStatus = async () => {
      const { json } = await dispatch(
        apiRequest(['get-configs'], 'GET')
      )
      setConfig(json)
      localStorage.setItem(
        'maintenance',
        json.maintenance
      )
    }
    const getContactHostMetaData = async () => {
      await dispatch(
        apiRequest(['contact-host']),
        'GET'
      )
    }
    getMaintenanceStatus()
    getContactHostMetaData()
  }, [dispatch])

  return (
    <div
      className={classNames(
        'App',
        { 'nav-collapse': !props.showNavigation },
        { 'nav-expand': props.showNavigation }
      )}>
      <AnnouncementContainer
        me={me}
        getConfigs={config}
      />

      <TaskManager />
      <Navigation.Top
        me={me}
        dispatch={dispatch}
        canSwitchOrgs={me.role === 'SUPERADMIN'}
        onSwitchOrgs={() =>
          onSave({ currentOrganizationId: null })
        }>
        <NotificationPopup />
      </Navigation.Top>
      <Navigation.Left me={me}>
        <NotificationPopup />
      </Navigation.Left>
      <main>
        <Switch>
          <Route
            exact
            path="/home"
            component={HomeContainer}
          />
          <Route
            exact
            path="/properties"
            render={({
              location: { search },
            }) => (
              <PropertyContainer
                search={search}
                me={me}
                onSaveMe={onSave}
              />
            )}
          />
          <Route
            exact
            path="/properties/archive"
            render={({
              location: { search },
            }) => (
              <PropertyContainer
                search={search}
                archive
                me={me}
                onSaveMe={onSave}
              />
            )}
          />
          <Route
            exact
            path="/properties/create/:step"
            component={PropertyAddContainer}
          />
          <Redirect
            exact
            from="/properties/create"
            to="/properties/create/1"
          />
          <Route
            exact
            path="/properties/:id/:tab?/:subTab?"
            render={props => (
              <PropertyDetailContainer
                {...props}
                me={me}
              />
            )}
          />

          <Route
            exact
            path="/oauth"
            component={OAuthPopup}
          />

          <Route
            exact
            path="/content/:tab?/:subTab?"
            component={ContentContainer}
          />

          <Route
            exact
            path="/content/recommendations/add/create"
            component={RecommendationAddContainer}
          />
          <Route
            exact
            path="/services/:tab?"
            render={props => (
              <ServicesContainer
                {...props}
                me={me}
              />
            )}
          />
          <Route
            exact
            path="/announcements/:tab?"
            component={MessagesContainer}
          />
          <Route
            exact
            path="/analytics"
            render={props => (
              <AnalyticsContainer
                {...props}
                dispatch={dispatch}
                me={me}
              />
            )}
          />
          <Route
            exact
            path="/slideshow/:tab?"
            render={props => (
              <AdvertisementsContainer
                {...props}
                me={me}
              />
            )}
          />
          {hasAccessTo('admin', me) && (
            <Route
              exact
              path="/admin"
              render={() => (
                <AdminContainer
                  dispatch={dispatch}
                  me={me}
                />
              )}
            />
          )}
          {hasAccessTo('admin', me) && (
            <Route
              exact
              path="/admin/:tab?"
              render={() => (
                <AdminContainer
                  dispatch={dispatch}
                  me={me}
                />
              )}
            />
          )}
          {hasAccessTo('admin', me) && (
            <Route
              exact
              path="/admin/:tab?/:subTab?"
              component={RevenueShareContainer}
            />
          )}
          <Route
            exact
            path="/settings/:tab?"
            render={() => (
              <SettingsContainer
                me={me}
                onSaveMe={onSave}
                onRefreshMe={onRefresh}
                dispatch={dispatch}
              />
            )}
          />
          <Route
            exact
            path="/password-change"
            component={PasswordChangeContainer}
          />
          <Redirect to="/home" />
        </Switch>
      </main>
      <ModalContainer />
    </div>
  )
                                                                      }

const SuperadminApp = ({ me, onSave }) => (
  <div>
    <div className="App is-super-admin">
      <Navigation.Top me={me} />
      <Navigation.LeftSuperAdmin me={me} />
    </div>
    <main>
      <Container fluid>
        <Row>
          <Switch>
            <Route
              exact
              path="/choose-account"
              render={() => (
                <OrganizationSwitcherContainer
                  me={me}
                  organizations={me.organizations}
                  onSelect={org => onSave({ currentOrganizationId: org.id })}
                  onCreate={org => onSave({ newOrganization: org })}
                />
              )}
            />
            <Redirect to="/choose-account" />
          </Switch>
        </Row>
      </Container>
    </main>
    <ModalContainer />
  </div>
)

const OnboardingSteps = ({ me, onSave }) => (
  <div>
    <Container fluid>
      <Row>
        <main role="main" className="onboarding-container">
          <Switch>
            <Route
              exact
              path="/onboarding/:screen"
              render={({ match }) => <OnboardingContainer screen={match.params.screen} me={me} onSaveMe={onSave} />}
            />
            <Redirect to="/onboarding/2" />
          </Switch>
        </main>
      </Row>
    </Container>
    <ModalContainer />
  </div>
)

const App = (props) => {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <ToastContainer
        closeOnClick
        theme="colored"
        position="top-center"
        hideProgressBar
        closeButton={false}
        autoClose={4000}
        transition={Zoom}
        limit={1}
      />
      <ScrollToTop />
      <Resource path={['me']} placeholderComponent={Loading}>
        {(me, { onSave, onRefresh, dispatch }) => {
          return me.role === 'SUPERADMIN' && me.organization === null ? (
            <SuperadminApp me={me} onSave={onSave} />
          ) : me.organization.hasOnboarded === false ? (
            <div>
              <OnboardingSteps me={me} onSave={onSave} />
            </div>
          ) : (
            <div>
              <ManagementApp me={me} onSave={onSave} onRefresh={onRefresh} dispatch={dispatch} props={props} />
            </div>
          )
        }}
      </Resource>
    </QueryParamProvider>
  )
}

const mapStateToProps = state => {
  return {
    showNavigation: state.config.showNavigation,
  }
}

export default withRouter(connect(mapStateToProps, { toggleNavigationView })(App))
