import React from 'react'
import Icon from './Icon'

function EnabledIcon(props) {
  return (
    <Icon viewBox="0 0 19 19" {...props}>
      <path
        d="M8.45 10.85L6.8375 9.2375C6.7 9.1 6.525 9.03125 6.3125 9.03125C6.1 9.03125 5.925 9.1 5.7875 9.2375C5.65 9.375 5.58125 9.55 5.58125 9.7625C5.58125 9.975 5.65 10.15 5.7875 10.2875L7.925 12.425C8.075 12.575 8.25 12.65 8.45 12.65C8.65 12.65 8.825 12.575 8.975 12.425L13.2125 8.1875C13.35 8.05 13.4188 7.875 13.4188 7.6625C13.4188 7.45 13.35 7.275 13.2125 7.1375C13.075 7 12.9 6.93125 12.6875 6.93125C12.475 6.93125 12.3 7 12.1625 7.1375L8.45 10.85ZM9.5 17C8.4625 17 7.4875 16.803 6.575 16.409C5.6625 16.015 4.86875 15.4808 4.19375 14.8063C3.51875 14.1313 2.9845 13.3375 2.591 12.425C2.1975 11.5125 2.0005 10.5375 2 9.5C2 8.4625 2.197 7.4875 2.591 6.575C2.985 5.6625 3.51925 4.86875 4.19375 4.19375C4.86875 3.51875 5.6625 2.9845 6.575 2.591C7.4875 2.1975 8.4625 2.0005 9.5 2C10.5375 2 11.5125 2.197 12.425 2.591C13.3375 2.985 14.1313 3.51925 14.8063 4.19375C15.4813 4.86875 16.0157 5.6625 16.4097 6.575C16.8037 7.4875 17.0005 8.4625 17 9.5C17 10.5375 16.803 11.5125 16.409 12.425C16.015 13.3375 15.4808 14.1313 14.8063 14.8063C14.1313 15.4813 13.3375 16.0157 12.425 16.4097C11.5125 16.8037 10.5375 17.0005 9.5 17Z"
        fill="#01A652"
      />
    </Icon>
  )
}

export default EnabledIcon
