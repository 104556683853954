import React, { Fragment } from 'react'
import { keyBy, get } from 'lodash'
import SelectWithCheckboxesField from './SelectWithCheckboxesField'
import Resource from '../../Resource'
import { formatters } from '../../Utils'
import { PROPERTY_TYPES } from '../../constants/forms/propertyInfo'

export const formatPropertiesForField = (properties, propertyIds = []) => {
  return keyBy(
    properties.map(property => {
      const nickname = get(property, ['nickname'], '')
      const address = formatters.address(property).join(', ')
      return {
        group: property.addressCity,
        value: property.id,
        searchText: `${nickname} ${address}`,
        label: (
          <Fragment>
            {!!nickname && (
              <Fragment>
                <strong>{nickname}</strong>
                {' '}
                |
                {' '}
              </Fragment>
            )}
            {address}
          </Fragment>
        ),
        selected: (propertyIds || []).includes(property.id),
      }
    }),
    'value',
  )
}

export const getPropertyIdsFromProperties = properties => Object.values(properties)
    .filter(({ selected }) => selected)
    .map(({ value }) => value)

export default props => {
  return (
    <Resource collection path={['properties', `?type=${PROPERTY_TYPES.current}`]} placeholder={[]}>
      {properties => (
        <SelectWithCheckboxesField fast={false} options={formatPropertiesForField(properties)} {...props} />
      )}
    </Resource>
  )
}
