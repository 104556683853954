import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import fallbackAvatar from '../../images/home-photo-placeholder.jpg'
import { formatters } from '../../Utils'

const DrawerListItem = withRouter(({ property, history }) => {
  const rowAction = id => {
    history.push(`/properties/${id}`)
  }

  const addDefaultSrc = ev => {
    ev.target.src = fallbackAvatar
    ev.target.style.objectFit = 'contain'
  }

  return (
    <li key={property.id} onClick={() => rowAction(property.id)} style={{ cursor: 'pointer' }}>
      <img src={property.photoURL || fallbackAvatar} alt="property" onError={addDefaultSrc} />
      <div>
        <h6>{property.nickname || property.addressLine1}</h6>
        <p>{formatters.address(property).join(',')}</p>
      </div>
    </li>
  )
})

export const MessageDrawerList = ({ data }) => {
  return (
    <>
      <div className="properties-list-container">
        <ul className="list-of-properties has-thin-scroll scroll-dark-grey">
          {data.map(property => {
            return <DrawerListItem property={property} />
          })}
        </ul>
      </div>
    </>
  )
}

export const SentMessagesDrawerList = ({ data }) => {
  const [filter, setFilter] = useState('READ')
  const [properties, setProperties] = useState([])

  useEffect(() => {
    setProperties(data.filter(property => property.status === filter))
  }, [filter, data])

  return (
    <>
      <div className="property-list-top-bar">
        <div className="read-unread-buttons">
          <button className={classNames('btn-read', { selected: filter === 'READ' })} onClick={() => setFilter('READ')}>
            Read
          </button>
          <button
            className={classNames('btn-unread', { selected: filter === 'UNREAD' })}
            onClick={() => setFilter('UNREAD')}
          >
            Unread
          </button>
        </div>
      </div>
      <div className="properties-list-container">
        <ul className="list-of-properties has-thin-scroll scroll-dark-grey">
          {properties.map(({ property }) => (
            <DrawerListItem property={property} />
          ))}
        </ul>
      </div>
    </>
  )
}
