import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import yelpImageForRating from './YelpRating'
import { RecommendationIcon } from '../icons'

const YelpResultList = ({
  onHoverResult, onChooseResult, results, recommendations, onEditRecommendation, propertyId,
}, ref) => {
  return (
    <div className="results" ref={ref}>
      {results.map((result, idx) => {
        const recommendation = recommendations.find(recommendation => {
          const id = get(recommendation, 'content.providerData.id')
          return id === result.id
        })
        const hasProperties = recommendation && recommendation.propertyIds.length > 0
        const addedToCurrentProperty = recommendation && propertyId && recommendation.propertyIds.includes(parseInt(propertyId, 10))
        const price = result.price ? `${result.price} •` : ''
        const priceAndCategories = `${price} ${result.categories.map(a => a.title).join(', ')}`
        return (
          <div
            className="result"
            key={result.id}
            onMouseEnter={() => onHoverResult(result)}
            onMouseLeave={() => onHoverResult(null)}
            data-result-id={result.id}
          >
            <div className="index">{`${idx + 1}.`}</div>
            <img className="place-image" src={result.image_url} alt="preview" />
            <div className="result-details">
              <div className="name bold" title={result.name}>
                {result.name}
              </div>
              <div className="categories" title={priceAndCategories}>
                {priceAndCategories}
              </div>
              {hasProperties && (
                <div
                  className={classNames('properties-applied', {
                    'current-property': addedToCurrentProperty,
                  })}
                >
                  {addedToCurrentProperty
                    ? 'Added to this property'
                    : `Properties Applied: ${recommendation.propertyIds.length}`}
                </div>
              )}
              <div className="rating-container">
                <div className="rating">{yelpImageForRating(result.rating)}</div>
                <div className="review-count">{`${result.review_count} reviews`}</div>
              </div>
            </div>
            {recommendation ? (
              <div onClick={() => onEditRecommendation(recommendation)} className="button outline s">
                <RecommendationIcon />
                Edit
              </div>
            ) : (
              <div onClick={() => onChooseResult(result)} className="button s add">
                <RecommendationIcon />
                Add
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default forwardRef(YelpResultList);
