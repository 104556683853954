import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { MESSAGES } from '../constants/strings'
import fallbackAvatar from '../images/home-photo-placeholder.jpg'

function ImportPropertyItem({
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressZIP,
  photoURL,
  onClick,
  isSelected,
  isImported,
}) {
  // check is image valid or not

  const [defaultImage, setDefaultImage] = useState(false)

  useEffect(() => {
    if (photoURL.trim() === '') {
      setDefaultImage(true)
    }
  }, [photoURL])

  const addDefaultSrc = ev => {
    ev.target.src = fallbackAvatar
    ev.target.style.objectFit = 'contain'
    setDefaultImage(true)
  }

  return (
    <div
      className={classNames('property-item-border', {
        active: isSelected,
        'is-imported': isImported,
      })}
    >
      <img src={photoURL} style={{ display: 'none' }} onError={addDefaultSrc} alt="property_image" />

      <div
        className="ImportPropertyItem"
        style={{
          backgroundImage: `url("${defaultImage ? fallbackAvatar : photoURL}")`,
        }}
        onClick={!isImported ? onClick : null}
        aria-hidden="true"
      >
        {isImported && <div className="badge secondary">{MESSAGES.IMPORTED}</div>}
        <div className="property-address">
          <div className="address-1">
            {addressLine1}
            {' '}
            {addressLine2}
          </div>
          <div>
            {addressCity}
            {' '}
            {addressState}
            {' '}
            {addressZIP}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportPropertyItem
