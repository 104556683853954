import React from 'react'
import classNames from 'classnames'
import '../styles/Choices.scss'

function Choices({ choices, setChoice, setKey }) {
  return (
    <div className={classNames('Choices')}>
      {choices.map((choice, index) => {
        const ChoiceIcon = choice.icon
        const props = choice.props || {}
        const setWith = setKey ? choice[setKey] : index

        return (
          <>
            <div className="template-choice" onClick={() => setChoice(setWith)} key={index} {...props}>
              {choice.icon ? (
                <div className="icon">
                  <ChoiceIcon />
                </div>
              ) : choice.defaultImage || choice.iconURL ? (
                <div className="icon">
                  <img src={choice.defaultImage || choice.iconURL} alt="" />
                </div>
              ) : null}
              {choice.templateName && <div className="name">{choice.templateName}</div>}
              {choice.templateDescription && <div className="desc">{choice.templateDescription}</div>}
              {choice.additionalInformation && <div className="desc">{choice.additionalInformation}</div>}
              {choices.name}
            </div>
          </>
        )
      })}
    </div>
  )
}

export default Choices
