import React from 'react'
import NumberFormat from 'react-number-format'

const Currency = ({ children, value, ...props }) => {
  return (
    <NumberFormat
      prefix="$"
      displayType="text"
      thousandSeparator
      {...props}
      value={value !== undefined ? value : children}
    />
  )
}

export default Currency
