import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { PlayIcon } from '@primer/octicons-react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { openModalWithContainer } from '../../reducers/modals'
import { BackLink } from '../../components'
import { PropertyAddress } from '../../components/Property'

import ImageDropzoneInput from '../../components/ImageDropzoneInput'
import propertyDummyURL from '../../images/home-photo-placeholder-2x.png'

import '../../styles/PropertyDetailHeader.scss'
import { ACTIONS } from '../../constants/thirdPartyAnalytics'
import { MESSAGES } from '../../constants/strings'

const PropertyDetailHeader = ({
 property, onSave, dispatch, recordAnalytics,
}) => {
  const { photoURL, devicePIN } = property
  const data = useSelector(store => store.data)
  const passcode = get(data, 'me.organization.passcode')
  const [defaultImage, setDefaultImage] = useState(false)
  useEffect(() => {
    if (photoURL == '' || photoURL == null) {
      setDefaultImage(true)
    }
  }, [])

  const addDefaultSrc = ev => {
    ev.target.src = propertyDummyURL
    ev.target.style.objectFit = 'contain'
    setDefaultImage(true)
  }
  const openDeviceView = () => {
    // recordAnalytics && recordAnalytics({ action: ACTIONS.DISPLAY_PREVIEW })
    dispatch(openModalWithContainer('ConciergePreviewContainer', { property }))
  }
  return (
    <div className="PropertyDetailHeader pb-2">
      <div className="back">
        <BackLink to="/properties" text="Back to Properties" />
      </div>
      <div className={classNames('photo', { placeholder: defaultImage })}>
        <ImageDropzoneInput
          name="photoURL"
          value={defaultImage ? propertyDummyURL : photoURL || propertyDummyURL}
          desiredSize={{ width: 370 * 2, height: 244 * 2 }}
          onChange={photoURL => onSave({ photoURL })}
        />
        <img src={photoURL} style={{ display: 'none' }} onError={addDefaultSrc} />
        {!photoURL && <div className="placeholder-link link s">{MESSAGES.ADD_PHOTO}</div>}
      </div>
      <div className="details">
        <PropertyAddress {...property} />
        <div className="code-and-device-view">
          <span className="passcode">{`${MESSAGES.PASSCODE}: ${passcode}`}</span>
          <span>{`${MESSAGES.PROPERTY_ID}: C${devicePIN}`}</span>
          <button onClick={openDeviceView} className="button round ml-3">
            <PlayIcon className="mr-2" size={20} />
            {' '}
            {MESSAGES.DISPLAY_PREVIEW}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PropertyDetailHeader
