import React from 'react'
import { isEmpty } from 'lodash'
import { SubmitButton } from '../../forms'
import '../../styles/Wizard.scss'
import classNames from 'classnames'
import { MESSAGES } from '../../constants/strings'

const WizardStep = ({
  titleComponent,
  bodyWrapper,
  bodyWrapperClass,
  footerWrapper,
  handleSubmit,
  validateForm,
  stepComponent,
  stepPreview,
  next,
  previous,
  isFirstStep,
  isLastStep,
  step,
  stepsLength,
  className,
  setStep,
  onSaveText,
  steps,
  previewTemplate,
  formNote = null,
  multiPrevious,
}) => {
  const validateAndNext = () => {
    validateForm().then(validation => isEmpty(validation) && next())
  }

  const TitleComponent = titleComponent
  const BodyWrapper = bodyWrapper
  const FooterWrapper = footerWrapper
  const onSaveMessage = onSaveText || 'Save'

  const previousButton = !isFirstStep && (
    <button type="button" className="btn btn-outline-primary" onClick={previous}>
      {MESSAGES.BACK}
    </button>
  )
  const continueButton = isLastStep ? (
    <SubmitButton>{onSaveMessage}</SubmitButton>
  ) : (
    <button type="button" className="btn btn-primary" onClick={validateAndNext}>
      {MESSAGES.NEXT}
    </button>
  )

  const component = React.cloneElement(stepComponent, { next, setStep })

  const previewExists = previewTemplate && previewTemplate[step] && previewTemplate[step].length

  return (
    <div>
      <form className={className} onSubmit={handleSubmit}>
        <TitleComponent
          steps={steps}
          step={step}
          multiPrevious={multiPrevious}
          validateForm={validateForm}
          progressText={`Step ${step + 1} of ${stepsLength}`}
        />
        <div className={classNames({ WizardStepBodyWrapper: stepPreview })}>
          <BodyWrapper className={`${bodyWrapperClass} ${!previewExists ? 'no-preview' : ''}`}>
            {formNote && <p style={{ color: '#4A4A4A' }}>{formNote}</p>}
            {component}
          </BodyWrapper>
          {stepPreview && <div className={classNames({ Preview: stepPreview })}>{stepPreview}</div>}
        </div>

        <FooterWrapper
          isLastStep={isLastStep}
          previousButton={previousButton}
          continueButton={continueButton}
          {...footerWrapper}
        />
      </form>
    </div>
  )
}

export default WizardStep
