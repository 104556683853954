import React from 'react'
import Icon from './Icon'

function SearchIcon(props) {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8.333c0 1.54-.523 2.96-1.4 4.088L18 16.823A.834.834 0 1 1 16.823 18L12.42 13.6A6.667 6.667 0 1 1 15 8.333zm-1.667 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
        fill="#AAA"
      />
    </Icon>
  )
}

export default SearchIcon
