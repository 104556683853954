import React from 'react'
import { get } from 'lodash'
import classNames from 'classnames'

const RebookSMSPreview = ({ formik: { values }, ...props }) => {
  const requestMessage = get(values, 'requestMessage')
  const rebookUrl = get(values, 'rebookUrl')
  const phone = get(values, 'phone')
  const promoCode = get(values, 'promoCode')

  const useMessage = requestMessage || 'Ready to book your next stay?'
  const url = rebookUrl ? ` Visit ${rebookUrl}` : ''
  const needOr = rebookUrl && phone ? ' or' : ''
  const phoneNumber = phone ? ` Call ${phone}` : ''
  const usePromo = promoCode ? `Promo code: ${promoCode}` : ''
  const availabilities = ' to check availabilities. '

  const message = useMessage + url + needOr + phoneNumber + availabilities + usePromo

  return (
    <div {...props.props} className={classNames('PreviewSMS', props.className)}>
      {message}
    </div>
  )
}

export default RebookSMSPreview
