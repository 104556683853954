import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Loading } from '../PageStates'
import Resource from '../../Resource'
import {
 List, ListHead, ListBody, ListRow, ListHeadCell, ListCell,
} from '../list'
import { closeTask } from '../../resources/tasks'
import { apiRequest } from '../../reducers/data'
import { confirm } from '../../containers/modals/AlertMessageContainer'
import { MESSAGES } from '../../constants/strings'

const ManageIntegrations = ({ me, onRefreshMe }) => {
  const dispatch = useDispatch()
  const [isRemoveOn, setisRemoveOn] = useState(true)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function getProgressFromPms() {
      const progressStatus = await dispatch(apiRequest(['pms/progress'], 'GET'))
      if (progressStatus && progressStatus.json) {
        setisRemoveOn(progressStatus.json.isCompleted)
      }
    }
    getProgressFromPms()
  }, [dispatch])
  return (
    <Resource path={['integrations']} collection placeholderComponent={Loading}>
      {(integrations, { onDeleteItem, dispatch }) => {
        if (integrations.length === 0) {
          return null
        }

        const remove = async type => {
          confirm({
            title: 'Remove Integration',
            body: 'Are you sure you want to remove this integration?',
            onConfirm: async () => {
              try {
                setLoading(true)
                await onDeleteItem({ id: type })

                dispatch(closeTask(`${type}/progress`))
                dispatch(apiRequest([`pms/removeUnimportedProperties?pms=${type}`], 'GET'))
                await onRefreshMe()
                setLoading(false)
              } catch (err) {
                setLoading(false)
              }
            },
          })
        }
        return loading ? (
          <Loading />
        ) : (
          <div className="settings-section">
            <h2>
              {MESSAGES.MANAGE_INTEGRATIONS}
              {' '}
            </h2>
            <List className="table-element info-table">
              <ListHead>
                <tr>
                  <ListHeadCell>{MESSAGES.INTEGRATION_NAME}</ListHeadCell>
                  <ListHeadCell>{MESSAGES.ID}</ListHeadCell>
                  <ListHeadCell />
                </tr>
              </ListHead>
              <ListBody data-cy="integrations-list">
                {integrations.map(integration => {
                  const isDelete = !((integration.type === 'TRACK' || integration.type === 'LIVEREZ') && !isRemoveOn)
                  return (
                    <ListRow>
                      <ListCell>{integration.type.toLowerCase()}</ListCell>
                      <ListCell>
                        {integration.type === 'STREAMLINE' || integration.type === 'TRACK'
                          ? me.organization.reservationServiceConfig.key
                          : integration.externalId}
                        {integration.type === 'LIVEREZ' && me.organization.reservationServiceConfig.tokenPartnerKey}
                        {integration.type === 'LMPM' && me.organization.reservationServiceConfig.companyId}
                        {integration.type === 'GUESTY' && me.organization.reservationServiceConfig.client_id}
                        {integration.type === 'OWNERREZ' && me.organization.reservationServiceConfig.user_id}
                        {integration.type === 'BAREFOOT' && me.organization.reservationServiceConfig.barefootAccount}
                      </ListCell>
                      <ListCell>
                        <div
                          className="link danger"
                          onClick={() => {
                            isDelete && remove(integration.type)
                          }}
                          data-cy={`integrations-${integration.type.toLowerCase()}-remove`}
                        >
                          {isDelete && 'Delete'}
                        </div>
                      </ListCell>
                    </ListRow>
                  )
                })}
              </ListBody>
            </List>
          </div>
        )
      }}
    </Resource>
  )
}

export default ManageIntegrations
