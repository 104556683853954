import React from 'react'
import '../styles/Maintenance.scss'
import MaintenanceImage from '../images/maintenance.png'

export default function Maintenance() {
  return (
    <div className="container-fluid">
      <div className="maintenance-section">
        <img src={MaintenanceImage} alt="Maintenance" />
        <div>
          <h3>We are under maintenance</h3>
          <p>Will be back soon!</p>
        </div>
      </div>
    </div>
  )
}
