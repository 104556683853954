import React from 'react'
import { MESSAGES } from '../../constants/strings'
import {
  List, ListHead, ListBody, ListRow, ListHeadCell, ListCell,
} from '../list'

function MyInfo({ me = {} }) {
  return (
    <div className="settings-section">
      <h2>{MESSAGES.MY_INFO}</h2>
      <List>
        <ListHead>
          <tr>
            <ListHeadCell>{MESSAGES.EMAIL}</ListHeadCell>
            <ListHeadCell className="hide-mobile">{MESSAGES.FIRST_NAME}</ListHeadCell>
            <ListHeadCell className="hide-mobile">{MESSAGES.LAST_NAME}</ListHeadCell>
            <ListHeadCell>{MESSAGES.ROLE}</ListHeadCell>
            <ListHeadCell className="hide-mobile">{MESSAGES.INTEGRATION}</ListHeadCell>
          </tr>
        </ListHead>
        <ListBody>
          <ListRow>
            <ListCell>{me.email}</ListCell>
            <ListCell className="hide-mobile">{me.firstName}</ListCell>
            <ListCell className="hide-mobile">{me.lastName}</ListCell>
            <ListCell data-cy="user-role">{me.role}</ListCell>
            <ListCell className="hide-mobile">
              {me.organization && me.organization.reservationService
                ? me.organization.reservationService.toUpperCase()
                : 'N/A'}
            </ListCell>
          </ListRow>
        </ListBody>
      </List>
    </div>
  )
}
export default MyInfo
