/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { OrderableList } from '../../components/list'
import { withContent } from '../../components/content/ContentProvider'
import { openModalWithContainer } from '../../reducers/modals'
import { ACTIONS } from '../../constants/thirdPartyAnalytics'
import PropertyDrawer from '../../components/drawer/PropertyDrawer'

const ContentTable = ({
  columns,
  selectExistingColums,
  content,
  data,
  totalProperties,
  propertyId,
  propertyCity,
  emptyState,
  dispatch,
  onCreateItem,
  onUpdateItem,
  onDeleteItem,
  modalSize,
  recordAnalytics,
}) => {
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})

  const openRecipientProperties = (data, event) => {
    event.stopPropagation()
    setDrawerContent(data)
    setDrawer(true)
  }
  const memorizedColumns = useMemo(() => columns, [])

  const onUpdate = props => {
    recordAnalytics && recordAnalytics(content.event, ACTIONS.UPDATE)
    return onUpdateItem(props)
  }

  const onSaveHandler = props => {
    recordAnalytics && recordAnalytics(content.event, ACTIONS.CREATE)
    return onCreateItem(props)
  }

  const openCreateModal = data => {
    data = { ...data, propertyIds: [propertyId] }
    dispatch(
      openModalWithContainer(
        'ContentContainer',
        {
          data,
          content,
          onSave: onSaveHandler,
          isCustom: false,
          dispatch,
        },
        {
          size: modalSize,
        }
      )
    )
  }

  const openModal = data => {
    dispatch(
      openModalWithContainer(
        'ContentSelectContainer',
        {
          propertyId,
          openCreateModal,
          content,
          onSave: onSaveHandler,
          dispatch,
          onUpdateItemProperty: onUpdate,
          selectExistingColums,
          propertyCity,
        },
        {
          size: 's',
        }
      )
    )
  }

  const initialState = {
    propertyId,
    propertiesCount: totalProperties,
    openModal,
    openRecipientProperties,
    onDeleteItem,
    onUpdateItem,
    content,
  }

  return (
    <>
      <PropertyDrawer header="Content" drawerData={drawerContent} showDrawer={showDrawer} setDrawer={setDrawer} />
      <OrderableList
        columns={memorizedColumns}
        data={data}
        item={content.item}
        hasRowAction={row => row.propertyId}
        rowAction={row => (row.propertyId ? openModal(row) : null)}
        onCreateItem={openModal}
        onUpdateItem={onUpdateItem}
        updateOrder="reorder/"
        relation="property_customization"
        initialState={initialState}
        emptyState={emptyState}
      />
    </>
  )
}

export default withContent(ContentTable)

ContentTable.defaultProps = {
  modalSize: 'm',
}
