import React, { useState } from 'react'
import { Alert } from 'reactstrap'
import { last } from 'lodash'
import classNames from 'classnames'
import { ExpressServerRoot, S3_ROOT_URL } from '../constants'
import pdfIconUrl from '../images/gvg-pdf.png'
import videoIconUrl from '../images/icon-video.png'
import '../styles/DropzoneInput.scss'
import { s3 } from '../services'
import { displayErrorToast } from '../utils/displayToast'

const DropzoneContent = props => {
  const {
    value,
    maxSizeText,
    dragInfo,
    btnText,
    progress,
    remove,
    mediaType,
    component,
    invalid,
    onClick,
    isUploading,
  } = props

  let inputUploaded
  if (mediaType === 'video') {
    inputUploaded = inputUploaded = (
      <div>
        <img src={videoIconUrl} alt="video-still" />
      </div>
    )
  } else if (mediaType === 'pdf') {
    inputUploaded = (
      <div>
        <img src={pdfIconUrl} alt="pdf-still" />
      </div>
    )
  }
  if (value) {
    if (component) {
      const Component = component
      return <Component />
    }
    return (
      <div className="DropzoneInput">
        {inputUploaded}
        <div className="info-container">
          <div className="filename">{last(value.split('_'))}</div>
          <button className="remove link inline" onClick={remove}>
            remove
          </button>
        </div>
      </div>
    )
  }
  return (
    <div className={invalid && 'is-invalid form-control'}>
      <div className="DropzoneInput">
        <div className="progress" style={{ width: `${Math.round(progress)}%` }} />
        <div
          className={classNames('btn', 'btn-outline-primary', 'btn-sm', {
            disableOnProgress: isUploading,
          })}
          onClick={onClick}>
          {btnText}
        </div>
        <div className="info-container">
          <div className="drag-info">{dragInfo}</div>
          <div className="drag-sub-info">
            Max size:
            {maxSizeText}
          </div>
        </div>
        <br />
      </div>
    </div>
  )
}

const DropzoneInput = props => {
  const {
    mediaType,
    value,
    accept,
    maxSizeText,
    btnText,
    dragInfo,
    extraInformation,
    component,
    invalid,
    className,
  } = props
  const inputRef = React.createRef()
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState({})

  const notify = () => {
    displayErrorToast('Error Notification !')
  }

  const onClickHandler = () => {
    inputRef && inputRef.current && inputRef.current.click()
  }

  const onPickedFile = e => {
    setIsUploading(true)
    e.target.files && e.target.files.length && handleOnDrop(e.target.files[0])
    e.target.value = null
  }

  const upload = file => {
    let uploadParams = {
      file,
      subfolder: 'uploaded-media/',
      onProgress,
      onError: onUploadError,
      onFinished: onFinishHandler,
    }
    if (props.upload) {
      uploadParams = { ...uploadParams, ...props.upload }
    }
    return s3.upload(uploadParams)
  }

  const onFinishHandler = async info => {
    const fileUrl = `${S3_ROOT_URL}/${info.fileKey}`
    ;(await props.onFinish) && props.onFinish({ ...info, fileUrl }, reset)
  }

  const onProgress = _progress => {
    if (props.onStart) {
      props.onStart()
    }
    setProgress(_progress)
  }

  const onUploadError = err => {
    if (err.includes('401')) {
      window.location = `${ExpressServerRoot}/login?next=${encodeURIComponent(window.location.href)}`
      return
    }
    notify(
      `An error occurred while uploading the file: ${err.toString()}. You may want to disable ad-blockers and try again.`
    )
  }

  const reset = () => {
    setProgress(0)
    setError({})
    setIsUploading(false)
  }

  const handleOnDrop = file => {
    let result = true
    if (file) {
      if (!file.type.includes(props.mediaType)) {
        displayErrorToast('Invalid file type.')
        result = false
      } else if (file.size > props.maxSize) {
        displayErrorToast('File size should not be greater than 300 mb')
        result = false
      }
      reset()
      if (!result) return
    }
    if (props.mediaType === 'video' && props.onDropHandler) {
      props.onDropHandler(file)
    }
    upload(file)
  }

  const onDragOver = e => {
    e.preventDefault()
  }

  const onDrop = e => {
    e.preventDefault()
    handleOnDrop(e.dataTransfer.files[0])
  }

  const removeHandler = e => {
    reset()
    props.remove && props.remove(e)
  }

  return (
    <div className={className}>
      <div aria-disabled={!!value} style={{ width: '100%', height: '100%' }} onDrop={onDrop} onDragOver={onDragOver}>
        <input ref={inputRef} type="file" accept={accept} style={{ display: 'none' }} onChange={onPickedFile} />
        <DropzoneContent
          customError={error}
          value={value}
          btnText={btnText}
          dragInfo={dragInfo}
          maxSizeText={maxSizeText}
          remove={removeHandler}
          mediaType={mediaType}
          progress={progress}
          isUploading={isUploading}
          component={component}
          onClick={onClickHandler}
          invalid={invalid}
        />
      </div>
      {extraInformation && (
        <div style={{ marginTop: 8, marginBottom: 8 }} className="small">
          {extraInformation}
        </div>
      )}
    </div>
  )
}

export default DropzoneInput;
