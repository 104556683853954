import React from 'react'
import { FormModal } from '../../forms'

const withHostForm = ({ validationSchema }) => WrappedComponent => {
  return props => {
    const {
      host: {
 id, contactNumber, name, phoneExt, photoURL,isDefault
},
      onSave,
      next,
      dispatch,
    } = props

    const initialValues = {
      name,
      contactNumber,
      phoneExt,
      photoURL,
      isDefault
    }

    const render = p => <WrappedComponent {...p} {...props} />

    const formatValues = v => {
      return {
        id,
        ...v,
      }
    }

    return (
      <FormModal
        formatValues={formatValues}
        validationSchema={validationSchema}
        initialValues={initialValues}
        render={render}
        next={next}
        onSave={onSave}
        dispatch={dispatch}
      />
    )
  }
}

export default withHostForm
