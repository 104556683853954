import React from 'react'
import { useSelector } from 'react-redux'
import ImageDropzoneInput from '../../components/ImageDropzoneInput'
import { fieldify } from './utils'

const ImageDropzoneField = fieldify(({ field, form, invalid, saveOnExit, originalImageURL, ...rest }) => {
  const onChange = value => {
    field.onChange({ target: { name: field.name, value } })
    rest.onChange && rest.onChange(value)
  }

  const onSaveOriginal = (value) => {
    form.setFieldValue('selectedImageId', 0)
    form.setFieldValue('originalImageURL', value)
    form.setFieldValue('image', value)
  }

  const { orientation } = useSelector(state => state.advertisementData)
  return (
    <ImageDropzoneInput
      {...field}
      {...rest}
      invalid={invalid}
      onChange={onChange}
      orientation={rest.advertisement ? orientation : null}
      originalImageURL={form.values.originalImageURL}
      saveOnExit={saveOnExit && form.handleSubmit}
      onSaveOriginal={onSaveOriginal}
    />
  )
})

export default ImageDropzoneField
