import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Downshift from 'downshift'
import { CloseIcon } from '../icons'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}))

export default function TagsInput({
  handleChange,
  inputValue,
  setInputValue,
  selectedItem,
  setSelectedItem,
  handleKeyDown,
  ...props
}) {
  const classes = useStyles()
  const {
    selectedTags, placeholder, tags, label, ...other
  } = props
  useEffect(() => {
    setSelectedItem(tags)
  }, [tags])
  useEffect(() => {
    selectedTags(selectedItem)
  }, [selectedItem, selectedTags])

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  function handleInputChange(event) {
    setInputValue(event.target.value)
    // Press enter if email has .com
    // if (event.target.value.includes(".com")) {
    //   handleKeyDown({ ...event, key: "Enter" })
    // }
  }
  return (
    <React.Fragment>
      <Downshift inputValue={inputValue} onChange={handleChange} selectedItem={selectedItem}>
        {({ getInputProps, getLabelProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            onBlur: handleKeyDown,
            placeholder: 'Add Emails',
          })
          return (
            <div>
              <TextField
                InputProps={{
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      deleteIcon={<CloseIcon />}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: event => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
              />
            </div>
          )
        }}
      </Downshift>
    </React.Fragment>
  )
}
TagsInput.defaultProps = {
  tags: [],
}
TagsInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
