import React, { Fragment, useEffect, useState } from 'react'
import { range } from 'lodash'
import { Input } from 'reactstrap'
import { RRule } from 'rrule'
import moment from 'moment'
import { connect } from 'formik'
import { getRelativeTimeInMinutesOrHours } from '../utils'
import NumberField from './NumberField'

const SEND_FREQUENCIES = [{ DAYS: 'Day' }, { WEEKS: 'Week' }, { MONTHS: 'Month' }]
const CLEAR_FREQUENCIES = [{ HOURS: 'Hour' }, { DAYS: 'Day' }, { WEEKS: 'Week' }]

const weekdays = [
  { value: 'SU', label: 'S' },
  { value: 'MO', label: 'M' },
  { value: 'TU', label: 'T' },
  { value: 'WE', label: 'W' },
  { value: 'TH', label: 'T' },
  { value: 'FR', label: 'F' },
  { value: 'SA', label: 'S' },
]

const Weekdays = ({ weekDays, setWeekDays, singleSelect = false }) => {
  if (singleSelect && weekDays.length > 1) {
    setWeekDays([RRule.MO])
  }

  const selectDay = day => {
    setWeekDays([RRule[day]])
  }

  const addDays = day => {
    const alreadyExists = weekDays.indexOf(RRule[day])
    const updatedWeekDays = [...weekDays]
    if (alreadyExists !== -1) {
      updatedWeekDays.splice(alreadyExists, 1)
    } else {
      updatedWeekDays.push(RRule[day])
    }
    if (updatedWeekDays.length) {
      setWeekDays(updatedWeekDays)
    }
  }

  const onClick = day => {
    singleSelect ? selectDay(day) : addDays(day)
  }

  return (
    <div className="flex flex-row mb-2">
      {weekdays.map(days => {
        const { value, label } = days
        return (
          <button
            type="button"
            className={weekDays?.includes(RRule[value]) ? 'selected' : ''}
            value={value}
            onClick={evt => onClick(evt.target.value)}
          >
            {label}
          </button>
        )
      })}
      {/* <Time value={time} onChange={setTime} /> */}
    </div>
  )
}

const Time = ({ value, onChange }) => {
  return (
    <div className="d-flex flex-column sub-field time-field mb-2">
      <span>at</span>
      <Input
        type="time"
        value={value}
        onChange={evt => {
          onChange(evt.target.value || `${new Date().getHours()}:${new Date().getMinutes()}`)
        }}
        style={{ width: '125px' }}
        required
      />
    </div>
  )
}

const SpecificDate = ({
 date, setDate, time, setTime,
}) => {
  const onDateChange = date => {
    if (date < moment().format('YYYY-MM-DD')) {
      setDate(moment().format('YYYY-MM-DD'))
      return
    }
    setDate(date)
  }

  return (
    <div className="inline-fields date-time-fields">
      <div className="sub-field mr-2">
        <span>on</span>
        <Input
          type="date"
          value={date}
          onChange={evt => onDateChange(evt.target.value)}
          min={moment().format('YYYY-MM-DD')}
          required
        />
      </div>
      <Time value={time} onChange={setTime} />
    </div>
  )
}

// const Days = ({ time, setTime }) => {
//   return <div className="inline-fields">{/* <Time value={time} onChange={setTime} /> */}</div>
// }

const Hours = ({
 frequency, hours, setHours, relativeFrequencyOption, setRelativeFrequencyOption,
}) => {
  const onChange = evt => {
    const input = evt.target.value
    if (relativeFrequencyOption === 'hours' && input < 24) {
      setHours(input)
    } else if (relativeFrequencyOption === 'minutes' && input < 60) {
      setHours(input)
    }
  }

  return (
    <div className="d-flex flex-column sub-field">
      <span>Send</span>
      <div className="d-flex align-items-center">
        <NumberField
          type="number"
          value={hours}
          min="1"
          max={relativeFrequencyOption === 'hours' ? '24' : '59'}
          step={1}
          onBlur={() => {
            if (!hours || hours === '0') setHours(1)
          }}
          onChange={onChange}
          style={{ width: '80px' }}
        />

        <div className="ml-2">
          <select
            name="relativeFrequencyOption"
            id="relativeFrequencyOption"
            value={relativeFrequencyOption}
            onChange={evt => setRelativeFrequencyOption(evt.target.value)}
          >
            <option value="hours">{'hour'}</option>
            <option value="minutes">{'minute'}</option>
          </select>
        </div>

        <div>
          {frequency === 'AFTER_CHECKIN' && <p>After Check In</p>}
          {frequency === 'BEFORE_CHECKOUT' && <p>Before Check Out</p>}
        </div>
      </div>
    </div>
  )
}

const Months = ({ monthDay, setMonthDay }) => {
  return (
    <div className="inline-fields">
      <div className="sub-field mr-2">
        <span>Date</span>
        <Input
          className="date-select-field"
          type="select"
          value={monthDay}
          onChange={evt => setMonthDay(evt.target.value)}
        >
          {range(1, 32).map(date => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </Input>
      </div>
    </div>
  )
}

const RecurringDatetimeField = ({ formik: { values, setFieldValue }, options, fieldName }) => {
  const [frequency, setFrequency] = useState(
    fieldName === 'datetime' ? 'NOW' : fieldName === 'clearAt' ? 'AFTER_RESERVATION_END' : 'DAY',
  )
  const [weekDays, setWeekDays] = useState([RRule.MO])
  const [monthDay, setMonthDay] = useState(1)
  const [time, setTime] = useState(moment().format('HH:mm'))
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [hours, setHours] = useState(1)
  const [relativeFrequencyOption, setRelativeFrequencyOption] = useState('hours')
  const [customFrequency, setCustomFrequency] = useState(fieldName === 'datetime' ? 'DAYS' : 'HOURS')
  const [customValue, setCustomValue] = useState(1)
  const [maxInterval, setMaxInterval] = useState(fieldName === 'datetime' ? 15 : 99)

  useEffect(() => {
    if (Object.keys(values[fieldName]).length) {
      initiateValues()
    }
  }, [])

  useEffect(() => {
    setInterval()
    format()
  }, [frequency, weekDays, monthDay, time, date, hours, customFrequency, customValue, relativeFrequencyOption])

  const onChangeFrequency = evt => {
    setFrequency(evt.target.value)
  }

  const setInterval = () => {
    if (fieldName === 'datetime') {
      if (customFrequency === 'DAYS') setMaxInterval(15)
      if (customFrequency === 'WEEKS') setMaxInterval(6)
    }
  }

  const initiateValues = () => {
    const value = values[fieldName]
    if (fieldName === 'datetime') {
      if (value.type === 'now') {
        setFrequency('NOW')
        const m = moment(value.value)
        setTime(m.format('HH:mm'))
        setDate(m.format('YYYY-MM-DD'))
      } else if (value.type === 'datetime') {
        setFrequency('SPECIFIC_DATE_TIME')
        const m = moment(value.value)
        setTime(m.format('HH:mm'))
        setDate(m.format('YYYY-MM-DD'))
      } else if (value.type === 'relative') {
        setFrequency(value.to === 'checkinTime' ? 'AFTER_CHECKIN' : 'BEFORE_CHECKOUT')
        value.frequencyType === 'hours' ? setHours(Math.abs(value.value / 60)) : setHours(Math.abs(value.value))
        setRelativeFrequencyOption(value.frequencyType)
      } else if (value.type === 'recurring') {
        const options = RRule.parseString(value.value)
        if (options.freq === RRule.DAILY && !options.interval) {
          setFrequency('DAILY')
          setTime(value.time)
        } else if (options.freq === RRule.DAILY) {
          setFrequency('CUSTOM')
          setCustomFrequency('DAYS')
          setCustomValue(options.interval)
          setWeekDays([options.wkst])
          setTime(value.time)
        } else if (options.freq === RRule.WEEKLY) {
          setFrequency('CUSTOM')
          setCustomFrequency('WEEKS')
          setCustomValue(options.interval)
          setWeekDays(options.byweekday)
          setTime(value.time)
        } else if (options.freq === RRule.MONTHLY) {
          const date = options.bymonthday[options.bymonthday.length - 1]
          setFrequency('CUSTOM')
          setCustomFrequency('MONTHS')
          setMonthDay(date || options.bymonthday)
          setTime(value.time)
        }
      }
    } else {
      if (value.type === 'CUSTOM') {
        setFrequency(value.type)
        setCustomFrequency(value.value)
        setCustomValue(value.interval)
        setTime(value.time)
      } else {
        setFrequency(value.type)
      }
    }
  }

  const format = () => {
    let value = {}
    if (fieldName === 'datetime') {
      if (frequency === 'NOW') {
        value = {
          type: 'now',
          value: new Date(),
        }
      } else if (frequency === 'SPECIFIC_DATE_TIME' && (date || time)) {
        value = {
          type: 'datetime',
          value: moment(`${date}T${time}`).format('YYYY-MM-DD HH:mm:SS'),
        }
      } else if (frequency === 'BEFORE_CHECKOUT' || frequency === 'AFTER_CHECKIN') {
        value = {
          type: 'relative',
          value: getRelativeTimeInMinutesOrHours(frequency, relativeFrequencyOption, hours),
          to: frequency === 'BEFORE_CHECKOUT' ? 'checkoutTime' : 'checkinTime',
          frequencyType: relativeFrequencyOption,
        }
      } else if (frequency === 'DAILY') {
        setDate(moment().format('YYYY-MM-DD'))
        const rule = new RRule({
          freq: RRule.DAILY,
        })
        value = {
          type: 'recurring',
          value: rule.toString(),
          time,
        }
      } else if (frequency === 'CUSTOM') {
        if (customFrequency === 'DAYS') {
          const rule = new RRule({
            freq: RRule.DAILY,
            interval: customValue,
            wkst: weekDays[0],
          })
          value = {
            type: 'recurring',
            value: rule.toString(),
            time,
          }
        } else if (customFrequency === 'WEEKS') {
          if (customValue > 6 && fieldName === 'datetime') {
            setCustomValue(6)
          }
          const rule = new RRule({
            freq: RRule.WEEKLY,
            interval: customValue,
            byweekday: weekDays,
          })
          value = {
            type: 'recurring',
            value: rule.toString(),
            time,
          }
        } else if (customFrequency === 'MONTHS') {
          const byMonthDay = []
          if (['29', '30', '31'].includes(monthDay)) {
            range(28, monthDay).map(d => {
              byMonthDay.push(d)
            })
          }
          byMonthDay.push(monthDay)
          const rule = new RRule({
            freq: RRule.MONTHLY,
            interval: 1,
            bymonthday: byMonthDay,
            bysetpos: -1,
          })
          value = {
            type: 'recurring',
            value: rule.toString(),
            time,
          }
        }
      }
    } else {
      if (frequency === 'AFTER_READ') {
        value = {
          type: 'AFTER_READ',
        }
      } else if (frequency === 'AFTER_RESERVATION_END') {
        value = {
          type: 'AFTER_RESERVATION_END',
        }
      } else if (frequency === 'DAY') {
        value = {
          type: 'DAY',
        }
      } else if (frequency === 'HOUR') {
        value = {
          type: 'HOUR',
        }
      } else if (frequency === 'CUSTOM') {
        value = {
          type: 'CUSTOM',
          value: customFrequency,
          interval: customValue,
          time,
        }
      }
    }
    setFieldValue(fieldName, value)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <Input type="select" value={frequency} onChange={onChangeFrequency}>
            {options.map(option => {
              const entries = Object.entries(option)
              const [value, label] = entries[0]
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </Input>
        </div>
        <div
          className={
            ['DAILY', 'SPECIFIC_DATE_TIME', 'AFTER_CHECKIN', 'BEFORE_CHECKOUT', 'CUSTOM'].includes(frequency)
              ? 'sub-options align-items-start flex-column'
              : ''
          }
        >
          {(frequency === 'AFTER_CHECKIN' || frequency === 'BEFORE_CHECKOUT') && (
            <Hours
              frequency={frequency}
              hours={hours}
              setHours={setHours}
              relativeFrequencyOption={relativeFrequencyOption}
              setRelativeFrequencyOption={setRelativeFrequencyOption}
            />
          )}
          {frequency === 'DAILY' && <Time value={time} onChange={setTime} />}
          {frequency === 'SPECIFIC_DATE_TIME' && (
            <SpecificDate date={date} setDate={setDate} time={time} setTime={setTime} />
          )}
          {frequency === 'CUSTOM' && (
            <>
              <div className="d-flex flex-row sub-field field-custom flex-wrap">
                <div className="d-flex flex-column mb-2">
                  <span>{fieldName === 'datetime' ? 'Repeat Every' : 'Expire after'}</span>
                  <div className="d-flex">
                    <Input
                      className="repeat-select mr-2"
                      type="select"
                      value={customFrequency}
                      onChange={evt => setCustomFrequency(evt.target.value)}
                    >
                      {fieldName === 'datetime'
                        ? SEND_FREQUENCIES.map(customFreq => {
                            const entries = Object.entries(customFreq)
                            const [value, label] = entries[0]
                            return (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            )
                          })
                        : CLEAR_FREQUENCIES.map(customFreq => {
                            const entries = Object.entries(customFreq)
                            const [value, label] = entries[0]
                            return (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            )
                          })}
                    </Input>
                  </div>
                </div>
                {customFrequency !== 'MONTHS' && (
                  <div className="d-flex flex-column mb-2">
                    <span>Interval</span>
                    <div className="mr-2">
                      <NumberField
                        type="number"
                        value={customValue}
                        min={1}
                        step={1}
                        max={maxInterval}
                        onBlur={() => {
                          if (!customValue || customValue === '0') setCustomValue(1)
                          if (customFrequency === 'DAYS' && customValue > 15 && fieldName === 'datetime') setCustomValue(15)
                          if (customFrequency === 'WEEKS' && customValue > 6 && fieldName === 'datetime') setCustomValue(6)
                        }}
                        onChange={evt => {
                          const { value } = evt.target
                          if (value > 99) return
                          setCustomValue(value)
                        }}
                        style={{ width: '55px' }}
                      />
                    </div>
                  </div>
                )}
                {customFrequency === 'MONTHS' && fieldName === 'datetime' && (
                  <div className="week-days flex-grow-1 justify-content-start">
                    <Months monthDay={monthDay} setMonthDay={setMonthDay} time={time} setTime={setTime} />
                  </div>
                )}
                {customFrequency !== 'HOURS' && <Time value={time} onChange={setTime} />}
              </div>
              <div>
                {customFrequency === 'DAYS' && fieldName === 'datetime' && (
                  <div className="week-days flex-grow-1 justify-content-start">
                    <Weekdays weekDays={weekDays} setWeekDays={setWeekDays} singleSelect />
                  </div>
                )}

                {customFrequency === 'WEEKS' && fieldName === 'datetime' && (
                  <div className="week-days flex-grow-1 justify-content-start">
                    <div className="d-flex flex-column">
                      <Weekdays weekDays={weekDays} setWeekDays={setWeekDays} />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  )
}

RecurringDatetimeField.defaultProps = {
  options: [],
}

export default connect(RecurringDatetimeField)
