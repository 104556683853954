import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { asyncDelay } from '../Utils'

import '../styles/AutocompletingInput.scss'
import { TextField } from '../forms/fields'

const ThrottleMsec = 400

const AutocompletingInput = props => {
  let _fetching = false
  let _fetchingCompletedAt = 0
  let _version = 0
  const _mounted = useRef(false)
  const [selectedIndex, setselectedIndex] = useState(0)
  const [autocompletions, setautocompletions] = useState([])
  const [autocompletionsVisible, setautocompletionsVisible] = useState(false)

  useEffect(() => {
    _mounted.current = true
    return () => {
      _mounted.current = false
    }
  }, [])

  const onChange = async e => {
    const { onChange, onFetchCompletions } = props
    const { target } = e

    props.onChange(target.value)

    if (_fetching) {
      return
    }

    const v = _version + 1
    _version = v
    _fetching = true

    await asyncDelay(Math.max(0, ThrottleMsec - (Date.now() - _fetchingCompletedAt)))

    const autocompletions = await onFetchCompletions(target.value)
    _fetching = false
    _fetchingCompletedAt = Date.now()

    if (!_mounted.current) {
      return
    }
    if (_version !== v) {
      onChange()
      return
    }

    setautocompletions(autocompletions)
    setautocompletionsVisible(autocompletions.length > 0)
    setselectedIndex(0)

    props.isAutocompleteSelected({
      autocompletions,
      autocompletionsVisible: autocompletions.length > 0,
      selectedIndex: 0,
    })
  }

  const onKeyDown = e => {
    if (e.keyCode === 40) {
      setselectedIndex(Math.min(autocompletions.length - 1, selectedIndex + 1))

      e.preventDefault()
    }
    if (e.keyCode === 38) {
      setselectedIndex(Math.max(0, selectedIndex - 1))
      e.preventDefault()
    }
    if (e.keyCode === 13) {
      const selected = autocompletions[selectedIndex]
      if (selected) {
        onSelectAutocompletion(selected)
      }
    }
  }

  const onSelectAutocompletion = selected => {
    setautocompletionsVisible(false)
    setselectedIndex(autocompletions.indexOf(selected))
    props.onChooseAutocompletion(selected)
  }

  const onBlur = () => {
    setautocompletionsVisible(false)
    if (selectedIndex === 0) {
      if (autocompletions.length) {
        props.onChooseAutocompletion(autocompletions[selectedIndex])
      }
    }
    props.onBlur()
  }

  const { formik, className, value, valueRenderer, placeholder, name, readOnly, disabled } = props

  return (
    <div className={`AutocompletingInput ${autocompletionsVisible && 'completions-visible'} ${className}`}>
      {formik ? (
        <TextField
          name={name}
          readOnly={readOnly}
          placeholder="Address"
          fast={false}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onChange}
          onBlur={onBlur}
          maxLength="255"
          autoComplete="__away"
          disabled={disabled}
        />
      ) : (
        <input
          className="form-control"
          type="text"
          value={value}
          placeholder={placeholder}
          data-cy="autocomplete-address"
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onChange}
          onBlur={onBlur}
          maxLength="255"
          autoComplete="__away"
          disabled={disabled}
        />
      )}
      {autocompletionsVisible && !readOnly && (
        <ul className="autocompletions">
          {autocompletions.map((completion, idx) => (
            <li
              key={idx}
              className={`option ${idx === selectedIndex && 'active'}`}
              onMouseDown={() => onSelectAutocompletion(completion)}>
              {valueRenderer(completion)}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default AutocompletingInput

AutocompletingInput.propTypes = {
  value: PropTypes.string,
  valueRenderer: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onFetchCompletions: PropTypes.func,
  onChooseAutocompletion: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

AutocompletingInput.defaultProps = {
  className: '',
  valueRenderer: a => a,
  onFetchCompletions: () => [],
  onChooseAutocompletion: () => {},
  onChange: () => {},
  onBlur: () => {},
}
