import React from 'react'
import { get } from 'lodash'
import classNames from 'classnames'

const DescriptionPreview = ({
 formik: { values }, className, heading, placeholder, ...props
}) => {
  const value = get(values, props.name)

  return (
    <div className={classNames('PreviewDescription', className)}>
      {heading && <strong>{heading}</strong>}

      <small>{value || placeholder}</small>
    </div>
  )
}

export default DescriptionPreview
