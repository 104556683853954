import { Drawer } from '@material-ui/core'
import React, { Fragment } from 'react'

import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { CARD_INDICATORS, ADDITIONAL_STRINGS } from '../../constants/home'

const HomeDrawer = ({ showDrawer, setDrawer, drawerData = {} }) => {
  const getLink = _property => {
    if (drawerData.name === 'WELCOME_VIDEO') return '/content/welcome-screen/videos'
    return `/properties/${_property.id}/reservations`
  }

  let description
  let subDescription
  if (drawerData.data) {
    description = { __html: drawerData.data.description }
    subDescription = { __html: drawerData.data.subDescription }
  }
  const isReservation = drawerData.name === ADDITIONAL_STRINGS.RESERVATIONS
  return (
    <Drawer anchor="right" open={showDrawer} onClose={() => setDrawer(false)}>
      <div className="dashboard-side-panel">
        <div className="close-side-panel">
          <span onClick={() => setDrawer(false)}>
            <i className="cross1" />
            <i className="cross2" />
          </span>
        </div>
        <div>
          {isReservation ? (
            <h4 className="no-icon">{ADDITIONAL_STRINGS.SET_UP_RESERVATIONS}</h4>
          ) : (
            <h4>
              <i className={`icon-status ${CARD_INDICATORS[drawerData.status]}`} />
              {drawerData.statusLabel}
            </h4>
          )}
          <div className="side-panel-content">
            <p dangerouslySetInnerHTML={description} />
            <p dangerouslySetInnerHTML={subDescription} />
            <p className="listing-bold">
              {drawerData.dashboardCardContents
                && drawerData.dashboardCardContents.map(dashboardCardContent => (
                  <Fragment>
                    {!isReservation && <span>{dashboardCardContent.nameLabel}</span>}
                    <ul className="links-list">
                      {dashboardCardContent.data.properties
                        && dashboardCardContent.data.properties.map(property => {
                          return (
                            <li>
                              <Link to={getLink(property)}>
                                {property.nickname ? property.nickname : property.addressLine1}
                              </Link>
                            </li>
                          )
                        })}
                    </ul>
                  </Fragment>
                ))}
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default withRouter(HomeDrawer)
