/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isUndefined } from 'lodash'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { withContent } from './ContentProvider'
import { openModalWithContainer } from '../../reducers/modals'
import { MasterList, AddButton } from '../list'
import { CONTENT_COLUMNS } from '../../constants/tables/content'
import { ACTIONS } from '../../constants/thirdPartyAnalytics'
import PropertyDrawer from '../drawer/PropertyDrawer'

const multipleAllowedCheck = (content, data, previousValue, currentValue) => {
  const checkList = ['videos', 'welcome']

  if (!checkList.includes(content.type)) return true

  if (!currentValue.propertyIds.length) return true

  const alreadyExists = data.some(
    ({ propertyIds, status, id, content }) =>
      (status || (content && content.status)) &&
      id !== (previousValue && previousValue.id) &&
      propertyIds.some(property => currentValue.propertyIds.includes(property))
  )
  if (alreadyExists) {
    return window.confirm(
      `Listed properties already have a ${content.title} assigned to them. Are you sure you want to replace it?`
    )
  }
  return true
}

// const isProviderYelp = values => {
//   if (values && values.content && values.content.provider === 'yelp') {
//     return 'yes'
//   } else return 'no'
// }

const ContentEditorTable = ({
  header,
  columns,
  content,
  data,
  onUpdateItem,
  onCreateItem,
  onDeleteItem,
  dispatch,
  properties,
  addButtonTop,
  fieldsConfig,
  modalSize,
  searchTheme,
  recordAnalytics,
}) => {
  const memorizedColumns = useMemo(() => columns, [])
  const [editId] = useQueryParam('edit', NumberParam)
  const [addType] = useQueryParam('add', StringParam)
  const [showDrawer, setDrawer] = useState(false)
  const [drawerContent, setDrawerContent] = useState({})

  const openRecipientProperties = (data, event) => {
    event.stopPropagation()
    setDrawerContent(data)
    setDrawer(true)
  }

  // Handles create, edit and duplicate of an item
  const openModal = _data => {
    const onSave = values => {
      const result = multipleAllowedCheck(content, data, _data, values)
      if (!result) return result

      if (_data && _data.id && !_data.isDuplicate) {
        if (
          ((_data && _data.status) || (_data.content && _data.content.status)) &&
          !(values.status || (values.content && values.content.status))
        ) {
          recordAnalytics &&
            recordAnalytics({
              subSection: content.event,
              name: values.name,
              action: ACTIONS.DISABLE,
            })
        } else {
          recordAnalytics &&
            recordAnalytics({
              subSection: content.event,
              name: values.name,
              action: ACTIONS.UPDATE,
            })
        }
        return onUpdateItem({ id: `${_data.id}`, propertyId: null, ...values })
      }
      if (_data && _data.isDuplicate && recordAnalytics) {
        recordAnalytics({ subSection: content.event, action: ACTIONS.DUPLICATE, name: _data.name })
      } else if (recordAnalytics) {
        recordAnalytics({
          subSection: content.event,
          name: values.name,
          action: ACTIONS.CREATE,
        })
      }
      return onCreateItem(values)
    }

    switch (content.type) {
      case 'checkInOutNotification':
        dispatch(
          openModalWithContainer('NotificationContainer', {
            properties,
            notification: !isUndefined(_data) && _data,
            onSave,
          })
        )
        break

      case 'contact':
        dispatch(
          openModalWithContainer(
            'ContactHostContainer',
            {
              data: !isUndefined(_data) && _data,
              content,
              onSave,
              fieldsConfig: fieldsConfig && fieldsConfig(_data),
            },
            {
              size: 'xl',
              customClass: 'contacthost-modal',
            }
          )
        )
        break
      default:
        dispatch(
          openModalWithContainer(
            'ContentContainer',
            {
              data: !isUndefined(_data) && _data,
              content,
              onSave,
              fieldsConfig: fieldsConfig && fieldsConfig(_data),
            },
            {
              size: modalSize,
            }
          )
        )
    }

    // if (content.type == 'checkInOutNotification') {
    //   dispatch(
    //     openModalWithContainer('NotificationContainer', {
    //       properties,
    //       notification: _data,
    //       onSave,
    //     }),
    //   )
    // } else {
    //   dispatch(
    //     openModalWithContainer(
    //       'ContentContainer',
    //       {
    //         data: !isUndefined(_data) && _data,
    //         content,
    //         onSave,
    //         fieldsConfig: fieldsConfig && fieldsConfig(_data),
    //       },
    //       {
    //         size: modalSize,
    //       },
    //     ),
    //   )
    // }
  }

  const onDelete = _data => {
    // recordAnalytics &&
    //   recordAnalytics({
    //     subSection: content.event,
    //     action: ACTIONS.DELETE,
    //     name: _data.name,
    //   })
    return onDeleteItem(_data)
  }

  const onUpdate = _data => {
    if (!_data.status) {
      recordAnalytics &&
        recordAnalytics({
          subSection: content.event,
          action: ACTIONS.DISABLE,
          name: _data.name || _data.title,
        })
    }
    return onUpdateItem(_data)
  }

  // Opening modals from params
  useEffect(() => {
    if (editId) {
      const customizationToOpen = data.find(customization => customization.id === editId)
      if (customizationToOpen) {
        openModal(customizationToOpen)
      }
    }
  }, [editId])

  useEffect(() => {
    if (addType && content.type === addType) {
      openModal()
    }
  }, [addType])

  const hiddenColumns = ['createdAt', 'isDefault', 'propertyId']

  const initialState = {
    properties,
    propertiesCount: properties.length,
    openRecipientProperties,
    openModal,
    onDeleteItem: onDelete,
    onUpdateItem: onUpdate,
    sortBy: [
      { id: 'isDefault', desc: true },
      { id: 'propertyId', desc: false },
      { id: 'createdAt', desc: false },
    ],
    hiddenColumns,
    recordAnalytics,
  }

  return (
    <>
      <PropertyDrawer
        header={header}
        drawerData={drawerContent}
        showDrawer={showDrawer}
        setDrawer={setDrawer}
        properties={properties}
      />
      <div className="ContentEditorTable">
        <MasterList
          columns={memorizedColumns}
          initialState={initialState}
          data={data}
          rowAction={openModal}
          emptyState={{
            text: content.emptyMessage,
          }}
          actions={addButtonTop && <AddButton item={content.item} onClick={openModal} />}
          search={addButtonTop}
          searchTheme={searchTheme}
        />
        {!addButtonTop && (
          <div className="create-new link" onClick={openModal.bind(this, false)}>
            {content.addNew}
          </div>
        )}
      </div>
    </>
  )
}

export default withContent(connect(null, { openModalWithContainer })(ContentEditorTable))

ContentEditorTable.defaultProps = {
  addButtonTop: false,
  columns: CONTENT_COLUMNS,
  modalSize: 'm',
  search: false,
  searchTheme: 'dark',
}
