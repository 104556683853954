import React, { useEffect, useState } from 'react'
import { MESSAGES } from '../../constants/strings'

const WelcomeVideoMetric = ({ metric = 0, name }) => (
  <span>
    {name}
    {' '}
    {metric}
  </span>
)

const WelcomeVideoMetrics = ({ welcomeVideo }) => {
  const [maintenanceStatus, setMaintenanceStatus] = useState(false)
  const analytics = welcomeVideo.analytics ? welcomeVideo.analytics : {}
  const {
 videoPlayed = '0', videoRewatched = '0', videoSkipped = '0', videoWatched = '0',
} = analytics

  useEffect(() => {
    setMaintenanceStatus(JSON.parse(localStorage.getItem('maintenance')))
  }, [])

  return maintenanceStatus ? (
    <small className="text-secondary font-italic">
      {' '}
      {MESSAGES.ANALYTICS_NOT_AVAILABLE}
      {' '}
    </small>
  ) : (
    <small className="metrics">
      <WelcomeVideoMetric name="Played" metric={videoPlayed} />
      {' / '}
      <WelcomeVideoMetric name="Watched" metric={videoWatched} />
      {' / '}
      <WelcomeVideoMetric name="Re-watched" metric={videoRewatched} />
      {' / '}
      <WelcomeVideoMetric name="Skipped" metric={videoSkipped} />
    </small>
  )
}

export default WelcomeVideoMetrics
