/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect, getIn } from 'formik'
import FormGroup from '../FormGroup'
import Resource from '../../Resource'
import { SelectField } from '.'

const IconSelector = connect(({ name, options, formik: { values, setFieldValue } }) => {
  useEffect(() => {
    const initial = getIn(values, name)

    if (!initial && options.length) {
      const [key] = Object.keys(options[0])
      setFieldValue(name, key)
    }
  }, [options])

  const selectedIcon = getIn(values, name)

  return (
    // TODO: Required should be hooked up to validation. No need to set inital val here either then
    <FormGroup label="Icon" required>
      <SelectField name={name} options={options} fast={false} />
      {selectedIcon && (
        <div className="icon-wrapper">
          <img alt="Icon" src={selectedIcon} />
        </div>
      )}
    </FormGroup>
  )
})

const IconsField = ({ name }) => {
  return (
    <div className="IconsField">
      <Resource collection path={['constants', 'amenity-icons']} placeholder={[]}>
        {icons => {
          const options = icons.reduce((options, iconObj) => {
            options.push({ [iconObj.url]: iconObj.name })
            return options
          }, [])

          return <IconSelector name={name} options={options} />
        }}
      </Resource>
    </div>
  )
}

export default IconsField
