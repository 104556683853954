import { isFunction } from 'lodash'
import React, { useEffect, useState } from 'react'
import AutoComplete from 'react-autocomplete'
import { fieldify } from './utils'

const AutoCompleteField = fieldify(({
  options, onChange: changeHandler, field, form, readOnly, disabled,
}) => {
  const [val, setVal] = useState(field.value)

  useEffect(() => {
    setVal(field.value)
  }, [field.value])

  const _onBlur = () => {
    _onSelect(null, val)
    form.validateOnBlur && form.validateForm()
  }

  const _onSelect = (label, item) => {
    if (isFunction(changeHandler)) changeHandler(item, field, form)
  }
  const matchStateToTerm = (state, value) => state.label.toLowerCase().indexOf(value.toLowerCase()) !== -1

  const _renderMenu = (items, _value, style) => (
    <div style={{ ...style }} className="select-options has-thin-scroll scroll-dark-grey">
      {items}
    </div>
  )

  const _renderItem = (item, isHighlighted) => {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div key={`${item.id}`} className="select-options-item" highlighted={isHighlighted}>
        {item.label}
      </div>
    )
  }

  return (
    <AutoComplete
      inputProps={{ disabled: readOnly || disabled, className: 'form-control', onBlur: _onBlur }}
      name={field.name}
      wrapperProps={{ className: 'w-100 position-relative' }}
      items={options}
      value={val}
      getItemValue={item => item.label}
      renderMenu={_renderMenu}
      renderItem={_renderItem}
      shouldItemRender={matchStateToTerm}
      onChange={e => setVal(e.target.value)}
      onSelect={_onSelect}
    />
  )
})

export default AutoCompleteField
