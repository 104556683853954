import loadImage from 'blueimp-load-image/js'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import S3Upload from 'react-s3-uploader/s3upload'
import { ExpressServerRoot, S3_ROOT_URL } from '../constants'
import { displayErrorToast } from '../utils/displayToast'
import Cropper from './Cropper'

const MultiDropZoneInput = ({
 name, accept, invalid, orientation, readOnly, component, onChange, value, ...props
}) => {
  const [fileData, setFileData] = useState({})
  const [progress, setProgress] = useState(0)
  const inputRef = useRef(null)

  const onClick = () => {
    inputRef.current.click()
  }

  const onPickedFile = e => {
    e.target.files && e.target.files.length && onProcessFile(e.target.files[0])
    e.target.value = null
  }

  const notify = msg => {
    displayErrorToast(msg)
  }

  const onProcessFile = file => {
    if (!file) return

    if (accept.includes('video') && file.type.includes('video')) {
      upload(file)
      return
    }
    if (accept.includes('video') && !accept.includes('image') && !file.type.includes('video')) {
      notify('Sorry, please select a valid video.')
      return
    }

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
      notify(
        accept.includes('video')
          ? 'Sorry, please select a PNG or JPG image or a valid video.'
          : 'Sorry, please select a PNG or JPG image.'
      )
      return
    }
    if (file.size > 1024 * 1024 * 12) {
      notify('Sorry, please choose an image that is less than 12MB in size.')
      return
    }
    if (file.type.includes('image')) {
      loadImage(
        file,
        img => {
          try {
            const base64data = img.toDataURL(file.type)
            setFileData({
              localFileURL: base64data,
              localFileType: file.type,
              localImage: img,
            })
            setCropperVisibility(true)
          } catch (error) {}
        },
        { orientation: true, meta: true, canvas: true }
      )
    }
  }

  const upload = file => {
    setCropperVisibility(false)
    return new S3Upload({
      files: [file],
      contentDisposition: 'auto',
      uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
      onError: onUploadError,
      onFinishS3Put: onUploadFinished.bind(this, file),
      onProgress: progress => {
        if (value) {
          onChange(null)
        }
        setProgress(progress)
      },
      server: '/api/dashboard',
      signingUrl: '/s3/sign',
      signingUrlQueryParams: { path: 'uploaded-media/' },
      s3Url: S3_ROOT_URL,
    })
  }

  const onUploadError = err => {
    if (err.includes('401')) {
      window.location = `${ExpressServerRoot}/login?next=${encodeURIComponent(window.location.href)}`
      return
    }
    notify(
      `An error occurred while uploading the image: ${err.toString()}. You may want to disable ad-blockers and try again.`,
    )
    setProgressHandler()
  }

  const setProgressHandler = () => {
    setProgress(0)
  }

  const onUploadFinished = (file, info) => {
    // onReset()
    onChange(info, file, setProgressHandler())
    if (props.saveOnExit) {
      props.saveOnExit()
    }
    setFileData({})
  }

  const Component = component

  const [showCropper, setCropperVisibility] = useState(false)
  return (
    <div
      className={classNames('ImageDropzoneInput', { 'is-invalid': invalid, square: orientation === 'square' })}
      onClick={progress ? null : onClick}
      disabled={progress}
    >
      <input
        ref={inputRef}
        name={name}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        onChange={onPickedFile}
        disabled={readOnly}
      />
      {Component && <Component progress={progress} />}
      {fileData && fileData.localFileURL && showCropper && (
        <Cropper
          {...props}
          file={fileData}
          progress={progress}
          setProgress={setProgress}
          uploadHandler={upload}
          onChange={onChange}
          setFileData={setFileData}
        />
      )}
    </div>
  )
}

export default MultiDropZoneInput
