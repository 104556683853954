import React from 'react'
import classNames from 'classnames'
import { reduce } from 'lodash'
import { ModalBody, ModalFooter, ModalHeader } from '../../components/modal'
import { Wizard } from '../../components/wizard'
import Preview from '../../components/form-builder/Preview'
import { generateWizard } from '../../forms/utils'
import { IconsS3Path } from '../../constants/generic'
import { CONTACT_HOST_WIZARD } from '../../constants/forms/contactHost'
import '../../styles/ContentContainerModal.scss'
import { CONTENT_FIELDS_PATH, EXCLUDE_FROM_CONTENT } from '../../constants/forms/content'
import useData from '../../hooks/useData'
import { apiRequest } from '../../reducers/data'

const ContactHostContainer = ({
  content, data, dispatch, onSave, propertyId,
}) => {
  const contactHosts = useData('contact-host')
  const contactHostOptions = contactHosts
    .filter(contact => !!contact.contactName)
    .map(option => ({
      ...option,
      label: option.contactName,
      value: option.contactName,
    }))

  if (data?.content?.contacts) {
    data.content.contacts = data.content.contacts.map(item => {
      const contactHost = contactHosts.find(cHost => cHost.id === item.id)
      if (!contactHost) return item
      item.contactName = contactHost.contactName
      item.contactPhone = contactHost.contactPhone
      item.contactEmail = contactHost.contactEmail
      return item
    })
  }

  const onSaveHandler = _data => {
    onSave(_data).then(async () => {
      await dispatch(apiRequest(['contact-host']), 'GET')
    })
  }

  const TitleComponent = props => (
    <ModalHeader
      name={data.name || data.title || content.title}
      verb={content.verb}
      hasItem={data.id}
      isDuplicate={data.isDuplicate}
      {...props}
    />
  )

  const onSaveText = data.id ? 'Update' : 'Publish'

  const WIZARD = reduce(
    CONTACT_HOST_WIZARD({
      saveOnExit: false,
      dispatch,
      contactHostOptions,
    }),
    (result, value, key) => {
      const contentFields = value.map(field => (EXCLUDE_FROM_CONTENT.includes(field.name)
        ? field
        : {
          ...field,
          name: `${CONTENT_FIELDS_PATH}.${field.name}`,
        }))
      return {
        ...result,
        [key]: [...contentFields],
      }
    },
    {},
  )
  const contactHostData = generateWizard({
    form: WIZARD,
    props: data,
    newValidation: true,
  })
  console.log('file: ContactHostContainer.js:84 -> contactHostData:', contactHostData)

  /** This is to add the *General Support* default field in first contact host topics  */
  if (!data || !data?.content?.contacts?.length) {
    contactHostData.initialValues.content.contacts[0] = {
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      topics: [
        {
          text: 'General Support',
          /** Needs to be changed once icon from figma is uploaded to s3 */
          iconURL: `${IconsS3Path}/icon-propertyinfo-connect.svg`,
          isDefault: true,
        },
      ],
    }
  }

  const preview = Preview('contact-host', '', {})

  return (
    <div className={classNames('ContentContainerModal', content.parent)}>
      <Wizard
        titleComponent={TitleComponent}
        bodyWrapper={ModalBody}
        formData={contactHostData}
        footerWrapper={ModalFooter}
        onSave={onSaveHandler}
        dispatch={dispatch}
        propertyId={propertyId}
        preview={preview}
        enableReinitialize
        onSaveText={onSaveText}
      />
    </div>
  )
}

export default ContactHostContainer;

ContactHostContainer.defaultProps = {
  isCustom: false,
}
