import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import parser from 'parse-address'
import AutocompletingInput from './AutocompletingInput'

import '../styles/AddressInput.scss'

const GeocoderRestrictions = {
  // country: 'us',
}

const partsOfGoogleAddress = (googleAddress, parts) => parts
  .map(p => googleAddress.find(c => c.types.includes(p)))
  .filter(p => !!p)
  .map(p => p.long_name)
  .join(' ')

const AddressInput = props => {
  const _autocomplete = new window.google.maps.places.AutocompleteService()
  const _geocoder = new window.google.maps.Geocoder()
  const _mounted = useRef(false)

  const {
    addressLine1, addressCity, addressState, addressCountry, addressZIP,
  } = props.value
  const [stringValue, setStringValue] = useState(
    [addressLine1, addressCity, addressState, addressCountry, addressZIP].filter(p => p && p !== '').join(', '),
  )

  useEffect(() => {
    _mounted.current = true
    return () => {
      _mounted.current = false
    }
  }, [])

  const onFetchCompletions = async text => new Promise(resolve => {
    if (!text || text === '') {
      resolve([])
      return
    }
    _autocomplete.getPlacePredictions(
      {
        input: text,
        types: ['geocode'],
        componentRestrictions: GeocoderRestrictions,
      },
      results => {
        if (!_mounted.current) return
        resolve((results || []).map(r => r.description))
      },
    )
  })

  const isAutocompleteSelected = autoCompletionObject => {
    if (autoCompletionObject && autoCompletionObject.autocompletions && !autoCompletionObject.autocompletions.length) {
      if (props.formik) {
        props.setFieldValue('content.location', undefined)
      }
    }
  }

  const onGeocode = address => {
    if (address.trim().length === 0) {
      props.onChange({
        addressLine1: '',
        addressLine2: props.value.addressLine2,
        addressCity: '',
        addressState: '',
        addressZIP: '',
        addressCountry: '',
        latitude: null,
        longitude: null,
      })
      return
    }
    _geocoder.geocode({ address, componentRestrictions: GeocoderRestrictions }, results => {
      if (!_mounted.current) return
      if (results && results.length) {
        const addr = results[0].address_components
        const loc = results[0].geometry.location

        let addressLine1
        // if google doesn't have the street info, parse it and set addressLine1
        // if (!addr.find(component => component.types.includes('street_number') && component.types.includes('route'))) {
        if (
          !addr.find(component => component.types.includes('street_number'))
          || !addr.find(component => component.types.includes('route'))
        ) {
          const parts = ['number', 'prefix', 'street', 'type']
          const parsed = parser.parseLocation(address)

          const passTest = parts.filter(part => parsed[part])
          addressLine1 = passTest.map(pass => parsed[pass]).join(' ')
        }

        props.onChange({
          addressLine1: addressLine1 || partsOfGoogleAddress(addr, ['street_number', 'route']),
          addressLine2: props.value.addressLine2,
          addressCity: partsOfGoogleAddress(addr, ['locality']),
          addressState: partsOfGoogleAddress(addr, ['administrative_area_level_1']),
          addressZIP: partsOfGoogleAddress(addr, ['postal_code']),
          addressCountry: partsOfGoogleAddress(addr, ['country']),
          latitude: loc.lat(),
          longitude: loc.lng(),
        })
      }
    })
  }

  const { formik, name, setFieldValue, readOnly, placeholder, hideUnitBlock, disabled } = props

  return (
    <div className="AddressInput">
      <AutocompletingInput
        formik={formik}
        readOnly={readOnly}
        name={name}
        placeholder={formik ? placeholder : 'Street Address'}
        className="street"
        isAutocompleteSelected={isAutocompleteSelected}
        value={!formik && stringValue}
        onChange={stringValue => (formik ? setFieldValue(name, stringValue) : setStringValue(stringValue))}
        onBlur={() => !formik && onGeocode(stringValue)}
        onChooseAutocompletion={stringValue => {
          onGeocode(stringValue)
          formik ? setFieldValue(name, stringValue) : setStringValue(stringValue)
        }}
        onFetchCompletions={onFetchCompletions}
        disabled={disabled}
      />
      {!formik && (
        <input
          className="form-control unit"
          type="text"
          maxLength="20"
          style={hideUnitBlock ? { display: 'none' } : {}}
          value={props.value.addressLine2}
          onChange={e =>
            props.onChange({
              ...props.value,
              addressLine2: e.target.value,
            })
          }
          placeholder="Unit or Apt #"
        />
      )}
    </div>
  )
}

export default AddressInput

AddressInput.propTypes = {
  value: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
    addressZIP: PropTypes.string,
    addressCountry: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

AddressInput.defaultProps = {
  formik: false,
  readOnly: false,
}
