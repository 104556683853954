import React, { useState, useEffect } from 'react'
import Resource from '../Resource'
import { Padlock } from '../TinySvgs'
import { formatters } from '../Utils'
import ImageDropzoneInput from '../components/ImageDropzoneInput'
import SlideshowImageGrid from '../components/SlideshowImageGrid'
import InlineEditableTitle from '../components/InlineEditableTitle'
import { Loading } from '../components/PageStates'
import SlideshowItemEdit from '../components/Slideshow/SlideshowItemEdit'
import { ListCellBadge } from '../components/list'
import '../styles/SlideshowDetailContainer.scss'
import { confirm } from './modals/AlertMessageContainer'
import { MESSAGES } from '../constants/strings'

function SlideshowDetailContainer({ onSave, bundle, onRefresh, ...props }) {
  const contextTypes = {
    router: () => {},
  }
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [titleRequired, setTitleRequired] = useState(false)
  const [imagesRequired, setImagesRequired] = useState(true)
  const isCustom = bundle.organizationId !== null
  const currentImage = bundle.images[selectedIndex]

  useEffect(() => {
    props.setTitle(bundle.name)
    props.setImagesStatus(bundle.images.length <= 0)
    if (bundle.images.length) {
      setImagesRequired(false)
    }
  }, [])

  const onSaveSlideshow = image => {
    const images = [...bundle.images]
    images[selectedIndex] = image

    props.setNewSlideShow({
      ...bundle,
      images,
    })

    return onSave({
      ...bundle,
      images,
    })
  }

  return (
    <div className="SlideshowDetailContainer">
      <div className="card p-3 shadow-sm rounded" style={{ display: 'inline-flex' }}>
        {isCustom ? (
          <InlineEditableTitle
            value={bundle.name}
            data-cy="artwork-collection-title"
            onChange={name => {
              if (!name.trim()) {
                setTitleRequired(true)
                props.setTitle('')
                props.setNewSlideShow({ ...bundle, name: '' })
              } else {
                setTitleRequired(false)
                props.setTitle(name.trim())

                props.setNewSlideShow({ ...bundle, name: name.trim() })
                return onSave({
                  ...bundle,
                  name: name.trim(),
                })
              }
            }}
          />
        ) : (
          <h2 aria-hidden style={{ fontWeight: 300 }} onClick={onSave} data-cy="artwork-collection-title">
            {bundle.name}
            <span style={{ color: 'gray', marginLeft: 7, fontSize: 25 }}>
              ({Padlock} {MESSAGES.STOCK})
            </span>
          </h2>
        )}
        {titleRequired && <p className="validation-error"> {MESSAGES.NAME_FIELD_REQD} </p>}
        {bundle.default && (
          <p className="my-2">
            <ListCellBadge className="ml-0" label="Default" />
            <small className="default-note"> {MESSAGES.SLIDESHOW_DEFAULT_NOTE}</small>
          </p>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h6>{`Images ${formatters.count(bundle.images.length)}`}</h6>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="section-images">
                {isCustom && (
                  <div className="dropzone-outer">
                    <ImageDropzoneInput
                      value={null}
                      label="Upload"
                      desiredSize={{ width: 1080, height: 1920 }}
                      onChange={async newImageURL => {
                        bundle.images = [
                          {
                            title: '',
                            description: '',
                            image: {
                              standard: newImageURL,
                              hiRes: newImageURL,
                            },
                          },
                          ...bundle.images,
                        ]
                        props.setImagesStatus(false)
                        setImagesRequired(false)
                        props.setNewSlideShow(bundle)
                        if (bundle.images.length === 1) {
                          setSelectedIndex(0)
                        }
                        await onSave(bundle)
                        onRefresh()
                      }}
                    />
                    {imagesRequired && <p className="validation-error"> {MESSAGES.MIN_ONE_IMAGE_REQD} </p>}
                  </div>
                )}
                {bundle.images.length > 0 && (
                  <SlideshowImageGrid
                    images={bundle.images}
                    selectedIndex={selectedIndex}
                    disabled={!isCustom}
                    onSelect={index => setSelectedIndex(index)}
                    onRemove={image => {
                      confirm({
                        title: 'Confirmation',
                        body: 'Are you sure you want to remove this image?',
                        onConfirm: () => {
                          onSave({
                            ...bundle,
                            images: bundle.images.filter(i => i !== image),
                          })
                          setSelectedIndex(bundle.images.filter(i => i !== image).length - 1)
                          props.setNewSlideShow({
                            ...bundle,
                            images: bundle.images.filter(i => i !== image),
                          })
                          if (bundle.images.length === 1) {
                            props.setImagesStatus(true)
                            setImagesRequired(true)
                          }
                          onRefresh()
                        },
                      })
                    }}
                    onChange={images => {
                      props.setNewSlideShow({ ...bundle, images })
                      onSave({
                        ...bundle,
                        images,
                      })
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-8">
              {currentImage && (
                <SlideshowItemEdit
                  key={currentImage.image.standard}
                  image={currentImage}
                  src={currentImage.image.standard}
                  isCustom={isCustom}
                  selectedIndex={selectedIndex}
                  onSaveSlideshow={onSaveSlideshow}
                />
              )}
            </div>
          </div>
        </div>
        <p className="copyright-txt" style={{ marginTop: '20px' }}>
          **Note: By uploading images, you agree that you have obtained appropriate rights from the copyright holder,
          and will indemnify Sharp NEC, and its subsidiaries, affiliates and partners from any liability. Sharp NEC and
          its subsidiaries can remove any photos which could infringe other person’s copyright without notification.
        </p>
      </div>
    </div>
  )
}

export default function({ id, onBack, totalProperties, setNewSlideShow, setTitle, setImagesStatus, slideShow }) {
  return (
    <Resource path={['art-mode-bundles', id]} placeholderComponent={Loading}>
      {(bundle, { onSave, onDelete, dispatch, onRefresh }) =>
        bundle ? (
          <SlideshowDetailContainer
            bundle={slideShow}
            onBack={onBack}
            onSave={onSave}
            onRefresh={onRefresh}
            onDelete={onDelete}
            dispatch={dispatch}
            totalProperties={totalProperties}
            setNewSlideShow={setNewSlideShow}
            setImagesStatus={setImagesStatus}
            setTitle={setTitle}
          />
        ) : null
      }
    </Resource>
  )
}
