import React from 'react'
import { withRouter } from 'react-router'

const TopPropertiesCard = () => {
  return (
    <div className="top-properties">
      <h4>Top properties by usage</h4>
      <div className="stats-row">
        <h5>Monterey View</h5>
        <p>
          <span>Pageviews 118</span>
          <span>Services sold 2</span>
        </p>
      </div>
      <div className="stats-row">
        <h5>Seabreeze Way</h5>
        <p>
          <span>Pageviews 88</span>
          <span>Services sold 1</span>
        </p>
      </div>
      <div className="stats-row">
        <h5>Camel Closeup</h5>
        <p>
          <span>Pageviews 78</span>
          <span>Services sold 1</span>
        </p>
      </div>
      <h5>
        <a href="#">View Analytics</a>
      </h5>
    </div>
  )
}

export default withRouter(TopPropertiesCard)
