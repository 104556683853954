import React from 'react'
import { Table } from 'reactstrap'
import { FaPlus } from 'react-icons/fa'

import PhoneNumber from '../PhoneNumber'
import { EmptyNavItemPlaceholder } from '../PageStates'
import { openModalWithContainer } from '../../reducers/modals'
import Resource from '../../Resource'
import AdminIcon from '../../icons/AdminIcon'

const AddAdminsInstallers = ({ me, role }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Resource collection path={['users', 'admin']} placeholder={{}}>
        {(admin, { onRefresh }) => (
          <Resource collection path={['users', 'invite']} placeholder={[]}>
            {(invites, { onCreateItem, dispatch }) => (
              // eslint-disable-next-line react/jsx-indent
              <div className="SettingsContainer">
                <div
                  className="onboarding-link"
                  onClick={() =>
                    dispatch(
                      openModalWithContainer('AdminContainer', {
                        admin: { role },
                        onSave: onCreateItem,
                        onSuccess: onRefresh,
                        me,
                      })
                    )
                  }>
                  {' '}
                  <FaPlus style={{ marginTop: '-2px' }} />
                  Invite New Administrator / Manager
                </div>

                <Resource collection path={['users', 'admin']} placeholder={[]}>
                  {admins => (
                    <div className="SettingsContainer">
                      {admins.length === 0 ? (
                        <EmptyNavItemPlaceholder
                          icon={<AdminIcon height="72" width="72" />}
                          navItem="an administrator"
                        />
                      ) : (
                        <Table className="table-element">
                          <tbody>
                            {role === 'MANAGER' &&
                              invites
                                .filter(invite => invite.role === role)
                                .map(invite => (
                                  <tr key={`invite-${invite.id}`} className="invited onboarding">
                                    <td>{invite.email}</td>
                                    <td className="right">Invited</td>
                                  </tr>
                                ))}
                            { /* role === 'MAINTENANCE' &&
                              admins
                                .filter(user => user.role === role)
                                .map(user => (
                                  <tr key={`user-${user.id}`} className="invited onboarding">
                                    <td>
                                      {user.firstName} {user.lastName}
                                    </td>
                                    <td>
                                      <PhoneNumber>{typeof user?.phone !== 'object' && user.phone}</PhoneNumber>
                                    </td>
                                    <td className="right">Info Sent</td>
                                  </tr>
                                )) */}
                          </tbody>
                        </Table>
                      ) }
                    </div>
                                ) }
                </Resource>
              </div>
            )}
          </Resource>
        )}
      </Resource>
    </div>
  )
}

export default AddAdminsInstallers
