import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { BackArrowIcon } from '../icons'
import '../styles/BackLink.scss'

function BackLink({
 to, text, forward, ...rest
}) {
  return (
    <Link className={classNames('BackLink link bold', { forward })} to={to} {...rest}>
      <BackArrowIcon size={16} />
      {text}
    </Link>
  )
}

export default BackLink

BackLink.defaultProps = {
  forward: false,
}
