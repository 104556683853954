import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { GoToIcon } from '../icons'

import '../styles/GoToLink.scss'

// TODO: Merge with BackLink... Icon Link
function GoToLink({
 to, text, where, className,
}) {
  return (
    <Link className={classNames('GoToLink link', className)} to={to}>
      <div className="link-icon">
        {text}
        {' '}
        <GoToIcon size={12} />
      </div>
      <small className="where">
        Go to
        {' '}
        {where}
      </small>
    </Link>
  )
}

export default GoToLink
