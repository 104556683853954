import { CUSTOM_FIELDS } from './services'

const MESSAGE_PREVIEW_WIZARD = {
  0: [
    {
      ...CUSTOM_FIELDS.name,
      props: {
        ...CUSTOM_FIELDS.name.props,
        placeholder: 'ex: Announcement',
      },
    },
  ],
}

export { MESSAGE_PREVIEW_WIZARD }
