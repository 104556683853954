import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { MasterList } from '../../components/list'
import { Loading } from '../../components/PageStates'
import { CREATED_CELL } from '../../constants/tables/common'
import {
  CATEGORY,
  MESSAGE_CREATED_CELL,
  MESSAGE_LOGO,
  MESSAGE_OPTIONS,
  MESSAGE_STATUS,
  MESSAGE_TITLE,
  RECURRENCE,
  SCHEDULE,
} from '../../constants/tables/messages'
import { ServiceIcon } from '../../icons'

const COLUMNS = [
  {
    id: 'messages',
    columns: [
      MESSAGE_LOGO,
      MESSAGE_TITLE,
      MESSAGE_STATUS,
      MESSAGE_CREATED_CELL,
      RECURRENCE,
      SCHEDULE,
      CATEGORY,
    ],
  },
]

const manageComponent = () => (
  <Link className="button" to="/announcements">
    Manage Announcements
  </Link>
)

const MessageTable = ({ data }) => {
  const memorizedColumns = useMemo(() => COLUMNS, [])

  return (
    <MasterList
      columns={memorizedColumns}
      data={data}
      search
      emptyState={{ icon: ServiceIcon, text: 'You have not yet added any announcement for this property' }}
      actions={manageComponent()}
    />
  )
}

const Messages = ({ NestedResource }) => {
  return (
    <NestedResource collection path={['messages']} placeholderComponent={Loading}>
      {active => {
        return <MessageTable data={active} />
      }}
    </NestedResource>
  )
}

export default Messages
