import Accordion from '../../../components/Accordion'
import ContactHostPreview from '../../../forms/previews/ContactHostPreview'

const CONTACT_HOST = {
  className: 'device-frame contact-host-preview-window',
  Parent: Accordion,
  title: 'Contact Us Preview',
  hideArrow: true,
  component: ContactHostPreview,
}

export const CONTACT_HOST_PREVIEW = {
  0: [CONTACT_HOST],
}
