import React from 'react'

function ProgressBar({ progress }) {
  const progressRemaining = 100 - progress

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="progress-barber-pole" />
        <div className="progress-remaining" style={{ width: `${progressRemaining}%` }} />
      </div>
    </div>
  )
}

export default ProgressBar
