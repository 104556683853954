import React from 'react'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { MdCancel } from 'react-icons/md'
import MediaDisplay from './MediaDisplay'
import MultiDropZoneInput from './MultiDropZoneInput'
import plusIcon from '../images/icons/plus-icon.png'

const AddMedia = props => {
  return (
    <div className="AddButton" {...props}>
      {props.progress ? (
        `${Math.round(props.progress)}%`
      ) : (
        <img src={plusIcon} style={{ width: 20, height: 20 }} alt="plus" />
      )}
    </div>
  )
}

const sortList = value => {
  return value && value.sort((a, b) => a.mediaOrder - b.mediaOrder)
}

const DraggableMediaInput = ({
  className,
  value,
  dragProps,
  deleteItem,
  onFinish,
  maxMediaLimit = 1,
  invalid,
  ...props
}) => {
  const sortedList = sortList(value || [])
  return (
    <>
      <ReactDragListView {...dragProps}>
        <div className={className}>
          {sortedList.map((item, index) => (
            <div key={index} className="list">
              {value && value.length ? (
                <div className="Delete" onClick={deleteItem.bind(this, item)}>
                  <MdCancel />
                </div>
              ) : null}
              <MediaDisplay className="handle shrink" item={item} showDuration />
            </div>
          ))}
          {value && value.length < maxMediaLimit ? (
            <MultiDropZoneInput {...props} onChange={onFinish} component={AddMedia} />
          ) : null}
        </div>
      </ReactDragListView>
      {props.sublabel && <small style={{ marginLeft: 4 }}>{props.sublabel}</small>}
    </>
  )
}

export default DraggableMediaInput
