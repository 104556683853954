import React from 'react'

import SmileyForFeedback from '../PropertyDetail/SmileyForFeedback'
import { StatBar } from '../../components'
import Review from '../../components/Review'
import { round } from '../../Utils'

const FeedbackReport = props => {
  const ratingOptions = [5, 4, 3, 2, 1]
  const {
 question, ratings, allTimeRatings = {}, reviews = [], title,
} = props
  return (
    <div className="FeedbackReport">
      <div className="title">{title}</div>
      <div className="question">{question}</div>
      <div className="ratings-bar">{ratings.length && <StatBar precision={1} horizontalItem stats={ratings} />}</div>
      <div className="alltime-ratings-container">
        <div className="title small">All-Time Ratings</div>
        <div className="ratings-container">
          {ratingOptions.map(rating => {
            const value = allTimeRatings[rating] ? allTimeRatings[rating] : 0
            const percentage = (value / allTimeRatings.total) * 100 || 0
            return (
              <div className="rating-container" key={rating}>
                <div className="smiley">{SmileyForFeedback(rating)}</div>
                <div className="ratings-progress">
                  <progress max="100" value={percentage} />
                </div>
                <div className="rating-percentage">
                  {round(percentage)}
                  %
                </div>
              </div>
            )
          })}
        </div>
        {reviews.length > 0 && (
          <div className="recent-reviews">
            <div className="title">Recent Ratings</div>
            {reviews.map(review => (
              <Review key={review.createdAt} {...review} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default FeedbackReport
