import React from 'react'
import { find, get } from 'lodash'
import moment from 'moment'
import { RECIPIENT_PROPERTY, UPDATED_CELL } from './common'
import { TablePreviewImage } from '../../components/Table'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'
import { AdvertisementMetrics } from '../../components/metrics'
import { confirm } from '../../containers/modals/AlertMessageContainer'

const ADVERTISEMENT_IMAGE = {
  id: 'image',
  Cell: ({ row, cell: { value } }) => {
    const image = get(row.original, 'imageURL') || get(row.original, 'image_url')
    const fallbackIcon = get(row.original, 'iconURL')
    return <TablePreviewImage src={image || fallbackIcon} contain={!image} height={137} width={77} />
  },
  options: {
    classNames: 'shrink',
  },
}

const ADVERTISEMENT_NAME = {
  id: 'name',
  Header: 'Name',
  accessor: ({ title }) => title,
  Cell: ({ row: { original: advertisement }, initialState: { rowAction, emailSetup } }) => {
    const edit = () => rowAction && rowAction(advertisement)
    const name = advertisement.title
    const quote = advertisement.promotion ? advertisement.promotion : advertisement.description
    const subType = advertisement.subType ? `(${advertisement.subType})` : ''
    const startDate = moment(new Date())
      .subtract(30, 'd')
      .format('DD MMM, YYYY')
    const endDate = moment(new Date())
      .subtract(1, 'd')
      .format('DD MMM, YYYY')

    const extractReportModalOpen = event => {
      emailSetup(advertisement)
      event.stopPropagation()
    }
    return (
      <>
        <div className="advertisement-title-head">
          <div>
            <strong>
              {advertisement.type} {subType}
            </strong>
            <div
              aria-hidden="true"
              className={`content-name bold ${rowAction ? 'link-text' : ''}`}
              onClick={() => edit(advertisement)}>
              {name}
            </div>
            <small className="quote">{quote}</small>
          </div>
          <div>
            <AdvertisementMetrics
              advertisement={advertisement}
              extractReportModalOpen={extractReportModalOpen}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </>
    )
  },
}

const PROPERTIES_APPLIED = {
  Header: 'Properties',
  accessor: ({ propertyIds }) => (propertyIds ? propertyIds.length : 0),
  Cell: ({ row: { original }, cell: { value }, initialState: { propertiesCount, properties, populateDrawer } }) => {
    const customPropertyId = get(original, 'propertyId')
    const customProperty = find(properties, ({ id }) => id === customPropertyId)
    const propertyName = get(customProperty, 'nickname') || get(customProperty, 'addressLine1') || 'this property'
    const text = customPropertyId
      ? `Custom for ${propertyName}`
      : `${value} of ${propertiesCount} ${propertiesCount > 1 ? 'properties' : 'property'}`
    return (
      <div
        className="link-text"
        onClick={event => {
          if (propertiesCount === 0) return
          populateDrawer(original)
          event.stopPropagation()
        }}>
        {text}
      </div>
    )
  },
  options: {
    hideOn: ['mobile'],
  },
}

const ADVERTISEMENT_STATUS = {
  id: 'status',
  Header: 'Status',
  accessor: ({ status }) => status,
  Cell: ({ row: { original: advertisement } }) => {
    const { status } = advertisement
    const { schedule_to } = advertisement
    const { schedule_from } = advertisement
    let value = <span className="indicator-disabled">Disabled</span>
    if (status) {
      value = <span className="indicator-enable">Enabled</span>
    }
    if (schedule_to || schedule_from) {
      value = schedule_to ? (
        <span className="indicator-schedule">
          Scheduled
          <strong>
            Ends:
            {moment(schedule_to).format('DD MMM, YYYY')}
          </strong>
        </span>
      ) : (
        <span className="indicator-schedule">
          Scheduled
          <strong>
            Starts:
            {moment(schedule_from).format('DD MMM, YYYY')}
          </strong>
        </span>
      )
    }
    if (schedule_to && schedule_from) {
      value = (
        <span className="indicator-schedule">
          Scheduled
          <strong>
            {moment(schedule_from).format('DD MMM, YYYY')} -{moment(schedule_to).format('DD MMM, YYYY')}
          </strong>
        </span>
      )
    }
    return value
  },
  options: {
    hideOn: ['mobile'],
  },
}

// const ADVERTISEMENT_CATEGORY = {
//   id: 'type',
//   accessor: ({ type, subType }) => {
//     subType = subType ? `(${subType})` : ''
//     return `${type} ${subType}`
//   },
//   Header: 'Category',
// }

const ADVERTISEMENT_OPTIONS = {
  accessor: 'Options',
  disableSortBy: true,
  Cell: ({ row: { original: advertisement }, initialState: { rowAction, onDeleteItem, onUpdateItem, emailSetup } }) => {
    // const edit = () => rowAction(advertisement)
    const emailReportSetup = () => emailSetup(advertisement)

    const remove = () => {
      confirm({
        title: 'Confirmation',
        body: 'Are you sure you want to delete this advertisement?',
        onConfirm: () => {
          onDeleteItem(advertisement)
        },
      })
    }

    const update = () => rowAction(advertisement)

    const toggleStatus = () => onUpdateItem({ ...advertisement, status: !advertisement.status })

    return (
      <PopoverMenu>
        {/* <PopoverMenuItem onClick={edit}>Edit</PopoverMenuItem> */}
        {!advertisement.schedule_from && !advertisement.schedule_to && (
          <PopoverMenuItem onClick={toggleStatus}>{advertisement.status ? 'Disable' : 'Enable'}</PopoverMenuItem>
        )}
        <PopoverMenuItem onClick={update}>Edit</PopoverMenuItem>
        <PopoverMenuItem onClick={emailReportSetup}>Email Report</PopoverMenuItem>
        <PopoverMenuItem onClick={remove}>Delete</PopoverMenuItem>
      </PopoverMenu>
    )
  },
}

export const ADVERTISEMENT_COLUMNS = [
  {
    id: 'property-info',
    columns: [
      ADVERTISEMENT_IMAGE,
      ADVERTISEMENT_NAME,
      ADVERTISEMENT_STATUS,
      RECIPIENT_PROPERTY,
      UPDATED_CELL,
      ADVERTISEMENT_OPTIONS,
    ],
  },
]
