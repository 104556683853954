import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import '../styles/Tabs.scss'
import { TabLeftArrowIcon, TabRightArrowIcon } from '../icons'
import Arrow from './Arrow'

const Tabs = ({
  tabs,
  path,
  match,
  isSubTabs,
  navTitle,
  navText,
  navChildren: NavChildren,
  onNavClick = null,
  selectedTab = null,
  ...rest
}) => {
  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false)

  const showArrowKeysHandler = useCallback(() => {
    const container = containerRef.current
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0)
      let { scrollLeft, clientWidth, scrollWidth } = container
      scrollLeft = Math.ceil(scrollLeft)
      clientWidth = Math.ceil(clientWidth)
      scrollWidth = Math.ceil(scrollWidth)
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth)
    }
  }, [containerRef])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', showArrowKeysHandler)
    }
    showArrowKeysHandler()
    return () => {
      if (container) {
        container.removeEventListener('scroll', showArrowKeysHandler)
      }
    }
  }, [showArrowKeysHandler])

  const handleScroll = useCallback(
    direction => {
      const container = containerRef.current
      if (container) {
        const scrollDistance = 200
        if (direction === 'left') {
          container.scrollTo({
            left: container.scrollLeft - scrollDistance,
            behavior: 'smooth',
          })
        } else if (direction === 'right') {
          container.scrollTo({
            left: container.scrollLeft + scrollDistance,
            behavior: 'smooth',
          })
        }
      }
    },
    [containerRef]
  )

  let currentTab
  if (!selectedTab) {
    const { params } = match
    const tabToUse = isSubTabs ? params.subTab : params.tab
    currentTab = tabs.find(tab => tab.slug === tabToUse) || tabs[0]
  } else {
    currentTab = tabs.find(tab => tab.slug === selectedTab) || tabs[0]
  }

  const Component = currentTab.component

  const onTabClick = (eventLabel, routePath) => {
    onNavClick && onNavClick(routePath)
    if (!eventLabel) return
    rest.recordAnalytics && rest.recordAnalytics(eventLabel)
  }

  return (
    <>
      <div className="d-flex alig-items-center">
        <Arrow hide={!showLeftArrow} icon={TabLeftArrowIcon} onClick={() => handleScroll('left')} />
        <div
          className={classNames({ Tabs: !isSubTabs }, { SubTabs: isSubTabs }, { TabsWithArrows: false })}
          ref={containerRef}>
          {navTitle && <h4>{navTitle}</h4>}
          {tabs.map(tab => {
            const Icon = tab.icon
            const routePath = `/${path}/${tab.slug}`
            return (
              <NavLink
                key={tab.slug}
                to={`/${path}/${tab.slug}`}
                exact={!tab.slug}
                onClick={onTabClick.bind(this, tab.eventLabel, routePath)}
                activeClassName="active">
                {Icon && <Icon size="20" />}
                {tab.label}
                {tab.count && ` (${tab.count})`}
              </NavLink>
            )
          })}
          {navText && <p className="nav-text text">{navText}</p>}
          {NavChildren && <NavChildren />}
        </div>
        <Arrow
          hide={!showRightArrow}
          icon={TabRightArrowIcon}
          onClick={() => {
            handleScroll('right')
          }}
        />
      </div>
      <div className={classNames({ TabContent: !isSubTabs }, { SubTabContent: isSubTabs })}>
        <Component {...rest} />
      </div>
    </>
  )
}

export default withRouter(Tabs)

Tabs.defaultProps = {
  isSubTabs: false,
}
