import React, { Fragment } from 'react'
import { Input } from 'reactstrap'
import { fieldify } from './utils'

const PasswordField = fieldify(
  ({
 field, form, extraInformation, invalid, maxLength, saveOnExit, shouldToggle, ...rest
}) => {
    const onBlur = () => {
      saveOnExit && form.handleSubmit()
    }
    return (
      <Fragment>
        <Input
          className="PasswordField"
          type="password"
          invalid={invalid}
          maxLength={maxLength || 20}
          {...field}
          {...rest}
          onBlur={onBlur}
        />
        {extraInformation && (
          <div style={{ marginTop: 8 }} className="small">
            {extraInformation}
          </div>
        )}
      </Fragment>
    )
  },
)

export default PasswordField
