import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import moment from 'moment'
import { fieldify } from './utils'

function DatetimeField({ dateOnly, field }) {
  const m = moment(field.value)
  const [date, setDate] = useState(m.format('YYYY-MM-DD'))
  const [time, setTime] = useState(m.format('HH:mm'))

  useEffect(() => {
    const { name } = field
    if (dateOnly) {
      const value = moment(date).format('YYYY-MM-DD')
      field.onChange({ target: { name, value } })
    } else {
      const value = new Date(`${date}T${time}`)
      field.onChange({ target: { name, value } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time])

  return (
    <div style={{ display: 'flex' }}>
      <Input
        type="date"
        value={date}
        onChange={e => setDate(e.target.value)}
      />
      {!dateOnly && (
        <Input
          type="time"
          value={time}
          onChange={e => setTime(e.target.value)}
        />
      )}
    </div>
  )
}

export default fieldify(DatetimeField)
