import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { debounce } from 'lodash'
import { FaList, FaThLarge } from 'react-icons/fa'

import '../../styles/PropertyHeader.scss'
import { ListActions, AddButton } from '../list'
import { EVENTS } from '../../constants/thirdPartyAnalytics'

let recordAnalyticsTimer = null

const PropertyHeader = ({
  openImportModal,
  onClick,
  activePropertyView,
  shouldOpenImportModal,
  setUserSearch,
  recordAnalytics,
}) => {
  useEffect(() => {
    if (shouldOpenImportModal) {
      openImportModal()
    }
  }, [shouldOpenImportModal, openImportModal])

  const debounceSearch = useCallback(debounce(setUserSearch, 250), [])

  function handleSearchChange(event) {
    const { value } = event.target
    // clearTimeout(recordAnalyticsTimer)
    // if (value) {
    //   recordAnalyticsTimer = setTimeout(() => {
    //     recordAnalytics && recordAnalytics(EVENTS.SEARCH_PROPERTY)
    //   }, 250)
    // }
    debounceSearch(value)
  }

  return (
    // TODO: Change as not tab actions
    <ListActions className="PropertyHeader space-between">
      <div className="wrapper-left">
        <AddButton
          item="Property"
          onClick={() => {
            recordAnalytics(EVENTS.ADD_PROPERTY_FROM_PROPERTY)
            openImportModal()
          }}
          verb='New'
          data-cy="btn-add-property"
        />
      </div>
      <div className="wrapper-right">
        <input
          type="search"
          className="search property-search"
          placeholder="Search address, nickname, host..."
          data-cy="property-search"
          onChange={handleSearchChange}
        />
        <button
          className="header-btn grid disabled-header"
          disabled={activePropertyView === 'gridView'}
          onClick={onClick}
          data-cy="grid-view"
        >
          <FaThLarge style={{ marginTop: '-2px' }} />
        </button>
        <button
          className="header-btn list disabled-header"
          disabled={activePropertyView === 'listView'}
          onClick={onClick}
          data-cy="list-view"
        >
          <FaList style={{ marginTop: '-2px' }} />
        </button>
      </div>
    </ListActions>
  )
}

export default withRouter(PropertyHeader)
