import React from 'react'
import { ContentPicker, ContentPickerForNotification } from '../../components/content'

import {
  CHECK_IN_OUT,
  CHECKOUT_DETAILS,
  CHECKOUT_CHECKLIST,
  CHECK_IN_OUT_NOTIFICATION,
  CHECKIN_SURVEY,
  CHECKOUT_SURVEY,
} from '../../constants/forms/content'
import { SECTIONS, SUB_SECTIONS } from '../../constants/thirdPartyAnalytics'
import SwitchPicker from '../../components/content/SwitchPicker'

// TODO: Pull property ID in contentPicker
const CheckInOut = ({ property: { id }, recordAnalytics }) => {
  const handleAnalytics = subSection => {
    recordAnalytics && recordAnalytics({ section: SECTIONS.CHECK_IN_OUT_INFO, subSection })
  }

  return (
    <div className="CheckInOut PropertyDetail">
      <div className="notifications">
        {/* TODO: Make title part of content picker */}
        <h6>{CHECK_IN_OUT_NOTIFICATION.title}</h6>
        <ContentPickerForNotification content={CHECK_IN_OUT_NOTIFICATION} propertyId={id} />
      </div>
      <div className="times">
        {/* TODO: Make title part of content picker */}
        <h6>{CHECK_IN_OUT.title}</h6>
        <ContentPicker
          content={CHECK_IN_OUT}
          propertyId={id}
          recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.CHECK_IN_OUT)}
        />
      </div>
      <div className="details">
        <h6>{CHECKOUT_DETAILS.title}</h6>
        <ContentPicker
          content={CHECKOUT_DETAILS}
          propertyId={id}
          recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.CHECKOUT_DETAILS)}
        />
      </div>
      <div className="list">
        <h6>{CHECKOUT_CHECKLIST.title}</h6>
        <div className='checkout-list'>
          <ContentPicker
            content={CHECKOUT_CHECKLIST}
            propertyId={id}
            recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.CHECKOUT_CHECKLIST)}
          />
        </div>
      </div>
      <div className='survey'>
        <h6>{CHECKIN_SURVEY.title}</h6>
        <SwitchPicker
          content={CHECKIN_SURVEY}
          recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.CHECKIN_SURVEY)}
          propertyId={id}
        />
        <h6>{CHECKOUT_SURVEY.title}</h6>
        <SwitchPicker
          content={CHECKOUT_SURVEY}
          recordAnalytics={handleAnalytics.bind(this, SUB_SECTIONS.CHECKOUT_SURVEY)}
          propertyId={id}
        />
      </div>
    </div>
  )
}

export default CheckInOut
