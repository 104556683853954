import { PAYABLE_FEE_TYPES } from '../constants/services'

const formatProviderId = (v, paymentProviderId, selectedTemplate) => {
  if (v.hasPaymentProvider && paymentProviderId) {
    if (selectedTemplate === 'bundle') {
      return paymentProviderId
    }
    if (PAYABLE_FEE_TYPES.includes(v.feeType)) {
      return paymentProviderId
    }
  }

  return null
}

export { formatProviderId }
