import React from 'react'
import Icon from './Icon'

function DirectoryIcon(props) {
  return (
    <Icon viewBox="0 0 24 21" {...props} width="1em" height="1em">
      <g clipPath="url(#map_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.839 5.032c0 2.78-5.033 10.452-5.033 10.452S6.774 7.81 6.774 5.032a5.032 5.032 0 1110.065 0zm-3.097 0a1.935 1.935 0 11-3.871 0 1.935 1.935 0 013.87 0zm-8.01 6.477a.58.58 0 00-1.013-.566L.074 19.265a.58.58 0 00.507.864h22.645a.58.58 0 00.507-.864l-4.645-8.322a.58.58 0 00-1.014.566l4.163 7.459H1.57l4.163-7.46z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="map_svg__clip0">
          <path fill="#fff" d="M0 0h24v20.129H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default DirectoryIcon
