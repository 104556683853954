import moment from 'moment'
import React from 'react'

import { MESSAGES } from '../../constants/strings'
import { apiRequest } from '../../reducers/data'
import '../../styles/ServicesContainer.scss'

const CartAbandonButtons = (props) => {
  const { createdAt } = props
  const duration = moment.duration(moment().diff(createdAt))
  const hours = duration.asHours()

  const onResendLink = async () => {
    const { dispatch, id } = props
    await dispatch(apiRequest([`requests/resendLink/${id}`], 'POST', null, null, MESSAGES.PAYMENT_LINK_SENT))
  }

  return (
    <div className="cart-abandoned-inline-data" style={{ display: 'flex' }}>
      <button
        className="btn resend-link"
        data-cy="btn-decline-service"
        onClick={() => onResendLink()}
        disabled={hours > 24}
      >
        {`${MESSAGES.RESEND_PAYMENT_LINK}`}
      </button>
    </div>
  )
}
export default CartAbandonButtons
