import React from 'react'
import Icon from './Icon'

function Announcement(props) {
  return (
    <Icon className="nav-announcement" height="24" width="24" viewBox="0 0 13 19" {...props}>
      <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.75103 10.6641C1.91527 10.6641 2.07583 10.7128 2.21239 10.8041C2.34895 10.8953 2.45539 11.025 2.51824 11.1768C2.5811 11.3285 2.59754 11.4955 2.5655 11.6566C2.53346 11.8176 2.45437 11.9656 2.33823 12.0818C2.22209 12.1979 2.07412 12.277 1.91304 12.309C1.75195 12.3411 1.58498 12.3246 1.43324 12.2618C1.2815 12.1989 1.1518 12.0925 1.06055 11.9559C0.969306 11.8193 0.920602 11.6588 0.920602 11.4946C0.920602 11.2743 1.00809 11.0631 1.16383 10.9074C1.31956 10.7516 1.53079 10.6641 1.75103 10.6641ZM0.0954922 11.1538L0.739821 12.8489C0.880802 13.2164 1.16143 13.5131 1.52043 13.6743C1.87943 13.8355 2.28763 13.8481 2.65589 13.7093L3.31569 13.4594C3.32757 13.5077 3.34242 13.5551 3.36016 13.6016L4.45161 16.4723L7.30831 15.4572L6.19318 12.5251C6.17567 12.4784 6.15517 12.4329 6.13179 12.3888L6.9743 12.0683C7.04346 12.1074 7.12035 12.1308 7.19955 12.1369L13.4983 12.5894L9.82908 2.83984L5.39707 7.39511C5.34145 7.45206 5.29933 7.52078 5.27382 7.59619L0.955405 9.23964C0.588303 9.38041 0.291818 9.66073 0.130702 10.0194C-0.0304146 10.378 -0.0430724 10.7858 0.0954922 11.1538Z"
          fill="#BDC6CD"
        />
        <path
          d="M11.8482 0.764856L10.5605 2.08783L14.541 12.6659L16.4261 12.8022C16.516 12.8087 16.606 12.7924 16.6878 12.7549C16.7697 12.7174 16.8408 12.6598 16.8945 12.5875C16.9482 12.5152 16.9828 12.4305 16.995 12.3413C17.0073 12.2521 16.9968 12.1612 16.9646 12.0771L12.7318 0.948052C12.6998 0.863975 12.6472 0.789308 12.5787 0.730951C12.5103 0.672594 12.4283 0.632429 12.3402 0.61417C12.2521 0.595911 12.1609 0.600145 12.0749 0.626483C11.9889 0.65282 11.9109 0.700411 11.8482 0.764856Z"
          fill="#BDC6CD"
        />
        <path
          d="M7.95412 17.1544L7.64621 16.3438L4.78951 17.3588L5.1211 18.2313C5.23879 18.5377 5.47294 18.7852 5.77244 18.9196C6.07193 19.054 6.41243 19.0644 6.7196 18.9486L7.2368 18.7519C7.54321 18.6343 7.79064 18.4002 7.92504 18.1008C8.05944 17.8014 8.0699 17.461 7.95412 17.1539V17.1544Z"
          fill="#BDC6CD"
        />
      </svg>
    </Icon>
  )
}

export default Announcement
