import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import '../../styles/PropertyListContainer.scss'

const PropertyReservationItem = ({
 checkout, checkin, beginDate, endDate, partyName, id, ...props
}) => {
  const isActive = checkin && moment().isBetween(checkin, checkout)

  let firstLine = ''
  let secondLine = ''

  const name = partyName && partyName.trim().length ? partyName.trim() : 'Guest'

  const begin = checkin && moment(checkin).format('MMM. D, YYYY')
  const end = checkout && moment(checkout).format('MMM. D, YYYY')

  switch (isActive) {
    case true:
      firstLine = name
      secondLine = [begin, end].join(' - ')
      break
    case false:
      firstLine = 'Next reservation:'
      secondLine = `${name} arriving on ${begin}`
      break
    default:
      firstLine = 'No reservations'
  }

  return (
    <div className={classNames('PropertyReservationItem', { active: isActive })}>
      <div className="first-line" data-cy={isActive && 'reservation-name'}>
        {firstLine}
      </div>
      {secondLine && (
        <div className="second-line" data-cy={!isActive && 'reservation-name'}>
          {secondLine}
        </div>
      )}
    </div>
  )
}

export default PropertyReservationItem
