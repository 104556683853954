/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import TableColumns from './DeviceTableColumns';
import { MESSAGES } from '../../constants/strings';
import '../../styles/AdminContainer.scss';
import { Loading } from '../PageStates'
import { displayErrorToast } from '../../utils/displayToast'
import DevicesTable from './DeviceTables';

const Devices = ({
 organizatons, dispatch, apiRequest,
}) => {
    const [devices, setDevices] = useState([])
    const [loading, setLoading] = useState(false);
    const [blacklistCheckbox, setBlacklistCheckbox] = useState(false)
    const [organization, setOrganization] = useState();
    const [orgId, setOrgID] = useState()
    const [selectedDevices, setSelectedDevices] = useState({ devices: [] });
    const [headerCheckboxChecked, setHeaderCheckboxChecked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
      if (selectedDevices.devices.length === devices.length && devices.length > 0) {
        setHeaderCheckboxChecked(true)
      } else {
        setHeaderCheckboxChecked(false)
      }
    }, [selectedDevices, devices])

    const getDevices = async orgId => {
      try {
        setLoading(true)
        const response = await dispatch(apiRequest([`/devices/organizations/${orgId}`], 'GET'))
        if (response.json) {
          setDevices(
            response.json.map(device => {
              device.isChecked = false
              device.headerCheckboxChecked = false
              return device
            })
          )
          setSearchTerm('')
          setSelectedDevices({ devices: [] })
          setHeaderCheckboxChecked(false)
        }
      } finally {
        setLoading(false)
      }
    }
    const search = results => {
      const filteredResults = results.filter(result => {
        const serialNumber = result.serialNumber.toLowerCase()
        const Address = result.property
          ? `${result.property.addressLine1}, ${result.property.addressCity}, ${result.property.addressState}.`.toLowerCase()
          : 'N/A'
        const organization = (result.organization && result.organization.name) || ''
        const searchableItems = [serialNumber, Address, organization].join(' ').toLowerCase()
        return searchableItems.includes(searchTerm.toLowerCase())
      })
      return filteredResults
    }
    const [filteredDevices, setFilteredDevices] = useState(search(devices))
    const updatedDevice = async selectedDevices => {
      if (selectedDevices.devices.length === 0) {
        displayErrorToast(MESSAGES.SELECT_DEVICE_ERROR)
      } else {
        await dispatch(apiRequest(['devices/update-devices'], 'PUT', selectedDevices))
        blacklistCheckbox
          ? (getBlacklistedDevices(true), setSelectedDevices({ devices: [] }), setHeaderCheckboxChecked(false))
          : getDevices(orgId)
      }
    }
    const getBlacklistedDevices = async checked => {
      checked
        ? (async () => {
            const devices = await dispatch(apiRequest(['devices/blacklisted-devices'], 'GET'))
            if (devices.json) {
              setDevices(devices.json)
            }
          })()
        : setDevices([])
    }
    const handleCheckboxChange = (id, isChecked) => {
      setDevices(prevState => prevState.map(device => (device.serialNumber === id ? { ...device, isChecked } : device)))

      setSelectedDevices(prevState => {
        const isDuplicate = prevState.devices.some(device => device === id)
        if (isChecked) {
          return isDuplicate ? prevState : { devices: [...prevState.devices, id] }
        }

        return { devices: prevState.devices.filter(device => device !== id) }
      })
    }
    useEffect(() => {
      setFilteredDevices(search(devices))
    }, [devices, searchTerm])

    const toggleHeaderCheckbox = (e) => {
        setHeaderCheckboxChecked(!headerCheckboxChecked);
        setFilteredDevices(prevState => {
            const updatedDevices = prevState.map(device => {
                device.isChecked = e.target.checked;
                return device;
            });
            const selectedDevices = {
                devices: updatedDevices
                    .filter(device => device.isChecked)
                    .map(device => (device.serialNumber)),
            };
            setSelectedDevices(selectedDevices);
            return updatedDevices;
        });
    };
    const COLUMNS = TableColumns({
        headerCheckboxChecked,
        toggleHeaderCheckbox,
        handleCheckboxChange,
    });
    return (
      <>
        <div className="device-checkbox">
          <input
            type="checkbox"
            className="mr-2"
            onChange={(e) => {
                setBlacklistCheckbox(e.target.checked);
                getBlacklistedDevices(e.target.checked);
                setOrganization(null);
                setSelectedDevices({ devices: [] })
                setHeaderCheckboxChecked(false)
            }}
          />
          <b>{MESSAGES.DISPLAY_BLACKLISTED_DEVICE}</b>
        </div>
        <div className="col-md-3 mb-2 from-select">
          <div className="select-box">
            <Select
              options={organizatons}
              value={organization}
              placeholder={MESSAGES.ORGANIZATION}
              isDisabled={blacklistCheckbox}
              onChange={(e) => {
                setOrganization(e.name);
                getDevices(e.value);
                setOrgID(e.value)
            }}
            />
          </div>
        </div>
        <div>
          {loading ? (
            <Loading
              style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: window.innerWidth > 768 ? '3rem' : '0',
            }}
            />
            ) : (
              <div className='table-content'>
                <DevicesTable
                  devices={filteredDevices}
                  COLUMNS={COLUMNS}
                  blacklistCheckbox={blacklistCheckbox}
                  selectedDevices={selectedDevices}
                  setSelectedDevices={setSelectedDevices}
                  updatedDevice={updatedDevice}
                  setRequestSearch={setSearchTerm}
                />

              </div>
      )}
        </div>
      </>
    );
};

export default Devices;
