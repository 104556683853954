import React from 'react'
import { get, isArray } from 'lodash'
import MediaDisplay from '../../components/MediaDisplay'

const ImagePreview = ({ formik: { values }, placeholder, ...props }) => {
  const value = get(values, props.name)

  let Component
  if (isArray(value) && value.length) {
    Component = <MediaDisplay item={value[0] || placeholder} className="PreviewImage" />
  } else {
    Component = <img src={placeholder} alt="preview" className="PreviewImage" />
  }

  return <div>{Component}</div>
}

export default ImagePreview
