import React, { useEffect } from 'react'
import { connect } from 'formik'
import PaymentIcon from '../../components/services/PaymentIcon'
import { CheckboxField } from './ChoiceField'
import { NON_PAYABLE_FEE_TYPES } from '../../constants/services'

const hasCustomSelected = serviceChoices => {
  let hasCustom = false
  serviceChoices.forEach(choice => {
    if (choice && choice.feeRates && choice.feeRates.some(feeRate => feeRate.feeType === 'custom')) {
      hasCustom = true
    }
  })

  return hasCustom
}

const PaymentProvider = ({ formik: { values, setFieldValue } }) => {
  const {
    feeType = null,
    supportPaymentProvider,
    hasPaymentProvider,
    template,
    serviceChoices,
    requireApproval,
  } = values

  useEffect(() => {
    // Enable require approval in case
    if (hasPaymentProvider) {
      // Approval required will always required in case of payment enabled
      setFieldValue('requireApproval', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPaymentProvider, serviceChoices])

  // - Always show accept payment checkbox in case of bundle template
  // - If not bundle template, hide accept payment checkbox if fee type is custom or free

  let showPaymentProvider
  if (template === 'bundle') {
    if (hasCustomSelected(serviceChoices)) {
      hasPaymentProvider && setFieldValue('hasPaymentProvider', false)
      showPaymentProvider = false
    } else showPaymentProvider = true
  }
  if (template !== 'bundle') {
    showPaymentProvider = !NON_PAYABLE_FEE_TYPES.includes(feeType)
  }

  return (
    supportPaymentProvider
    && showPaymentProvider && (
      <div className="row mb-2">
        <div className="col-sm-4 text-right">
          <label htmlFor="hasPaymentProvider" className="modal-label col-form-label">
            Payment Method
          </label>
        </div>
        <div className="col-sm-8">
          <CheckboxField
            name="hasPaymentProvider"
            fast={false}
            label={(
              <>
                Accept payment through Stripe
                <PaymentIcon style={{ marginLeft: '4px' }} />
              </>
            )}
          />
        </div>
      </div>
    )
  )
}

export default connect(PaymentProvider)
