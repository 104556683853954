import { capitalize, get } from 'lodash'
import React from 'react'
import Skeleton from '../../components/Skeleton'
import DetailHTMLPreview from './DetailHTMLPreview'
import TextPreview from './TextPreview'
import { ConnectIcon } from '../../icons'
import imagePlaceholder from '../../images/image-placeholder.png'

const ChoicesPreview = props => {
  const {
    formik: { values },
    name,
  } = props

  const choices = get(values, name)
  const isQuantityAllowed = get(values, 'allowQuantity')

  const getFeeContent = values => {
    const feeType = get(values, 'feeType')
    const price = get(values, 'price')
    const customFeeMessage = get(values, 'customFeeMessage')

    if (feeType === 'custom') return `${customFeeMessage}`
    if (feeType === 'free') return 'Free'
    if (!Number(price)) return price
    const amount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price / 100)
    let result = amount
    result = `${capitalize(feeType)}: ${result}`
    return result
  }

  const getImageSource = choice => {
    let result = imagePlaceholder
    if (choice.serviceChoiceMedias && choice.serviceChoiceMedias.length) {
      result = choice.serviceChoiceMedias[0].mediaUrl
    }
    return result
  }

  return (
    <>
      <div className="device-header-body">
        <h3 className="main-title">
          <strong>Service Choices</strong>
          <span>01 Selected</span>
        </h3>
        <p className="weight-500 mb-2 p-head">Please select from the following options:</p>
        <div className="choices-list-container has-thin-scroll">
          {choices
            && choices.length
            && choices.map((choice, index) => (
              <div key={`choice${index}`} className="choices-list">
                <div className="choice-content-wrapper">
                  <div className="choice-content">
                    <img src={getImageSource(choice)} alt="choice-preview" />
                    <div className="choice-content-text">
                      <TextPreview
                        {...props}
                        placeholder={<Skeleton height={8} width="50%" />}
                        name={`${name}[${index}][title]`}
                      />
                      <DetailHTMLPreview
                        {...props}
                        heading={null}
                        name={`${name}[${index}][detailHTML]`}
                        placeholder={<Skeleton height={6} count={3} />}
                      />
                    </div>
                  </div>
                  <div className="list-selector">
                    <input type="checkbox" checked={!index} />
                    <label />
                  </div>
                </div>
                <div className="choice-list-actions">
                  <div className="radio-options">
                    {choice
                      && choice.feeRates
                      && choice.feeRates.map((feeRate, feeRateIndex) => {
                        if (feeRate.feeType) {
                          return (
                            <span key={`feeRate${index}${feeRateIndex}`}>
                              <input checked type="radio" name="duration" />
                              <label>{getFeeContent(feeRate)}</label>
                            </span>
                          )
                        }
                        return <div />
                      })}
                  </div>
                  {isQuantityAllowed && (
                    <div className="num-counter">
                      <span>-</span>
                      <span>1</span>
                      <span>+</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="device-footer">
        <a href="#" className="service-connect">
          <ConnectIcon />
          {' '}
          Connect with the host about this service
        </a>
        <button className="btn btn-primary">Request Service</button>
        <small className="text-back">Back</small>
      </div>
    </>
  )
}

export default ChoicesPreview
