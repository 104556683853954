/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useMemo } from 'react'
import qs from 'qs'
import { withRouter } from 'react-router-dom'
import {
 UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import moment from 'moment'
import classNames from 'classnames'
import { Loading } from '../../components/PageStates'

import { openModalWithContainer } from '../../reducers/modals'
import AddButton from '../../components/list/AddButton'
import { MasterList } from '../../components/list'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'
import { ReservationsIcon } from '../../icons'
import { confirm } from '../modals/AlertMessageContainer'
import { MESSAGES } from '../../constants/strings'
import { apiRequest } from '../../reducers/data'
import { ACTIONS, SECTIONS } from '../../constants/thirdPartyAnalytics'

const COLUMNS = [
  {
    id: 'reservations',
    columns: [
      {
        id: 'beginDate',
        Header: 'Check In',
        accessor: ({ beginDate }) => beginDate,
        Cell: ({ row, cell: { value } }) => {
          const { beginDate } = row.original
          const { endDate } = row.original
          const currentDate = moment().format('YYYY-MM-DD')
          const isActiveRes = moment(currentDate).isBetween(beginDate, endDate, null, '[]')
          return <div className={classNames({ 'extra-bold': isActiveRes })}>{moment(value).format('ll')}</div>
        },
      },
      {
        id: 'endDate',
        Header: 'Check Out',
        accessor: ({ endDate }) => endDate,
        Cell: ({ row, cell: { value } }) => {
          const { beginDate } = row.original
          const { endDate } = row.original
          const currentDate = moment().format('YYYY-MM-DD')
          const isActiveRes = moment(currentDate).isBetween(beginDate, endDate, null, '[]')
          return <div className={classNames({ 'extra-bold': isActiveRes })}>{moment(value).format('ll')}</div>
        },
      },
      {
        id: 'partyName',
        Header: 'Guest Name',
        accessor: ({ partyName }) => partyName,
        Cell: ({ row, cell: { value }, initialState: { onEdit, onDelete } }) => {
          const { beginDate } = row.original
          const { endDate } = row.original
          const currentDate = moment().format('YYYY-MM-DD')
          const isActiveRes = moment(currentDate).isBetween(beginDate, endDate, null, '[]')
          const hasPartyName = value && value !== 'Guest'
          const partyName = value && value.trim().length ? value.trim() : 'Guest'
          return (
            <div className={classNames({ link: onEdit }, { 'extra-bold': isActiveRes }, { italic: !hasPartyName })}>
              {partyName}
            </div>
          )
        },
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original }, initialState: { onEdit, onDelete } }) => {
          const remove = () => {
            window.confirm(MESSAGES.CONFIRM_TO_DELETE_RESERVATION) && onDelete(original)
          }
          return (
            <PopoverMenu>
              <PopoverMenuItem onClick={() => onEdit(original)}>{MESSAGES.EDIT}</PopoverMenuItem>
              <PopoverMenuItem onClick={() => remove(original)}>{MESSAGES.REMOVE}</PopoverMenuItem>
            </PopoverMenu>
          )
        },
      },
    ],
  },
]

const ReservationTable = ({
 data, actions, dispatch, onUpdateItem, onDeleteItem, timezone, externalServiceId,
}) => {
  const memorizedColumns = useMemo(() => COLUMNS, [])

  const onEdit = reservation => {
    dispatch(
      openModalWithContainer('ReservationContainer', {
        reservation,
        timezone,
        onSave: onUpdateItem,
      }),
    )
  }

  const initialState = {
    sortBy: [{ id: 'beginDate', desc: false }],
    onEdit: !externalServiceId && onEdit,
    onDelete: onDeleteItem,
    hiddenColumns: [externalServiceId && 'options'],
  }

  return (
    <MasterList
      columns={memorizedColumns}
      data={data}
      actions={actions}
      initialState={initialState}
      rowAction={!externalServiceId && onEdit}
      emptyState={{
        icon: ReservationsIcon,
        text: MESSAGES.PROPERTY_DOESNOT_HAVE_RESERVATIONS,
        className: 'no-border',
      }}
    />
  )
}

const ReservationActions = ({
  externalServiceId,
  dispatch,
  timezone,
  onCreateItem,
  openImportModal,
  property,
  onSave,
  removeSync,
  hasReservations,
}) => {
  const canAddReservation = !externalServiceId
  const isIcal = externalServiceId === MESSAGES.EXTERNAL_SERVICE_ICAL

  const removeIcalSync = async e => {
    e.preventDefault()
    await dispatch(apiRequest(['properties', property.id, 'ical'], 'DELETE', {}))
    window.location.reload()
  }

  return (
    <>
      {canAddReservation && (
        <AddButton
          item="Reservation"
          data-cy="btn-add-reservation"
          onClick={() =>
            dispatch(
              openModalWithContainer('ReservationContainer', {
                reservation: null,
                timezone,
                onSave: onCreateItem,
                dispatch,
              })
            )
          }
        />
      )}
      {canAddReservation && (
        <UncontrolledButtonDropdown>
          <DropdownToggle caret className="btn btn-outline-primary" data-cy="reservations-sync-menu">
            Sync from...
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={openImportModal}>{MESSAGES.MANAGEMENT_SERVICE}</DropdownItem>
            <DropdownItem
              data-cy="reservations-sync-ical"
              onClick={() =>
                dispatch(
                  openModalWithContainer('ICalReservationsContainer', {
                    property,
                    hasReservations,
                    onSave,
                    createForm: true,
                  })
                )
              }>
              {MESSAGES.ICAL}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      )}
      {externalServiceId &&
        (isIcal ? (
          <>
            <button
              className="button primary outline"
              onClick={() =>
                dispatch(
                  openModalWithContainer('ICalReservationsContainer', {
                    property,
                    onSave,
                    hasReservations,
                  })
                )
              }>
              {MESSAGES.MANAGE_ICAL_LINKS}
            </button>{' '}
            <button
              className="button danger outline"
              onClick={e =>
                confirm({
                  title: MESSAGES.REMOVE_ICAL_SYNC,
                  body: MESSAGES.REMOVE_ICAL_SYNC_MESSAGE,
                  onConfirm: () => removeIcalSync(e),
                })
              }>
              {MESSAGES.REMOVE_SYNC}
            </button>
          </>
        ) : (
          <button className="button danger outline" onClick={removeSync}>
            {MESSAGES.REMOVE_SYNC}
          </button>
        ))}
    </>
  )
}

const Reservations = props => {
  const {
 NestedResource, dispatch, property, onSave, history, recordAnalytics,
} = props
  const { externalServiceId, timezone } = property

  const openImportModal = useCallback(() => dispatch(
      openModalWithContainer(
        'ImportReservationsContainer',
        {
          property,
          onSave,
        },
        { onClose: () => history.push({ search: '' }) },
      ),
    ))

  const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  const shouldOpenImportModal = search.import === 'true' && !externalServiceId

  useEffect(() => {
    if (shouldOpenImportModal) {
      openImportModal()
    }
  }, [shouldOpenImportModal, openImportModal])

  const removeSync = async () => {
    const { onSave, property } = props
    property.externalServiceId = null
    property.isImported = false
    await onSave(property)
    window.location.reload()
  }

  const handleAnalytics = action => {
    recordAnalytics && recordAnalytics({ section: SECTIONS.RESERVATION, action })
  }

  return (
    <div>
      <NestedResource collection path={['reservations']} placeholderComponent={Loading}>
        {(results, { onCreateItem, onUpdateItem, onDeleteItem }) => {
          const onAction = (action, func, extra) => {
            handleAnalytics(action)
            return func(extra)
          }

          const filteredData = results.filter(({ endDate }) => moment(endDate).diff(moment(), 'days') >= 0)
          return (
            <div>
              <ReservationTable
                data={filteredData}
                dispatch={dispatch}
                timezone={timezone}
                onUpdateItem={onAction.bind(this, ACTIONS.UPDATE, onUpdateItem)}
                onDeleteItem={onAction.bind(this, ACTIONS.DELETE, onDeleteItem)}
                externalServiceId={externalServiceId}
                actions={(
                  <ReservationActions
                    externalServiceId={externalServiceId}
                    hasReservations={filteredData.length > 0}
                    dispatch={dispatch}
                    timezone={timezone}
                    onCreateItem={onAction.bind(this, ACTIONS.CREATE, onCreateItem)}
                    openImportModal={openImportModal}
                    property={property}
                    onSave={onSave}
                    removeSync={removeSync}
                  />
                )}
              />
            </div>
          )
        }}
      </NestedResource>
    </div>
  )
}

export default withRouter(Reservations)
