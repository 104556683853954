import React from 'react'
import Icon from './Icon'

function SuppliesIcon(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props} width="1em" height="1em">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.992 10.5H22V21h-.5l-1 1-1-1-.998 1-1.002-1-1 1-1-1-1 1-1-1-1 1H12V11.5h-1V20H7c-2.761 0-5-4.03-5-9s2.239-9 5-9h10c2.668 0 4.848 3.762 4.992 8.5zM6.5 13.5c.828 0 1.5-1.231 1.5-2.75S7.328 8 6.5 8 5 9.231 5 10.75s.672 2.75 1.5 2.75z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default SuppliesIcon
