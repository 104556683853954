import React, { useMemo } from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import Resource from '../../Resource'
import { MasterList } from '../list'
import { DeviceStatus, DeviceAddress } from '.'
import '../../styles/Device.scss'
import { MESSAGES } from '../../constants/strings'

const alignCellTextMiddle = {
  options: {
    classNames: 'middle',
  },
}

const COLUMNS = [
  {
    id: 'settings',
    columns: [
      {
        Header: 'Serial number',
        accessor: 'devices',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return (
            <div
              className={classNames({
                disabled: original.status === 'UNPAIRED',
              })}
            >
              {original.serialNumber}
            </div>
          )
        },
        ...alignCellTextMiddle,
      },
      {
        Header: 'Address',
        accessor: 'properties',
        sortType: (a, b) => {
          const addressA = get(a, 'original.property.addressLine1', '')
          const addressB = get(b, 'original.property.addressLine1', '')
          return addressA.toLowerCase().localeCompare(addressB.toLowerCase())
        },
        Cell: ({ row: { original } }) => {
          return (
            <DeviceAddress
              nickname={original.property.nickname}
              address={original.property.addressLine1}
              status={original.status}
            />
          )
        },
        ...alignCellTextMiddle,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }) => {
          return <DeviceStatus status={original.status} />
        },
        ...alignCellTextMiddle,
      },
    ],
  },
]

const DevicesTable = ({ devices }) => {
  const memoizedColumns = useMemo(() => COLUMNS, [])
  return <MasterList columns={memoizedColumns} data={devices} search={false} />
}

const Devices = () => {
  return (
    <Resource collection path={['devices']} placeholder={[]}>
      {devices => (devices.length ? (
        <div className="device">
          <h2>{MESSAGES.DISPLAYS}</h2>
          <DevicesTable devices={devices} />
        </div>
        ) : null)}
    </Resource>
  )
}
export default Devices
