import React from 'react'
import { useSelector } from 'react-redux'
import ImageDropzoneInput from '../../components/ImageDropzoneInput'
import { fieldify } from './utils'

const AdvertisementImagePreview = fieldify(({ field, form, invalid, saveOnExit, ...rest }) => {
  return (
    <div>
      <img
        src={field.value}
        height={500}
        width={250}
        onClick={() => {
          alert('here')
        }}
      />
    </div>
  )
})

export default AdvertisementImagePreview
