/* eslint-disable react/display-name */
import React from 'react'
import { ContentEditor, ContentEditorTable } from '.'
import Tabs from '../Tabs'
import { AmenitiesIcon, PolicyIcon, SuppliesIcon } from '../../icons'
import { AMENITIES, SUPPLIES, POLICIES } from '../../constants/forms/propertyInfo'
import { CONTENT_WITH_IMAGE_COLUMNS, CONTENT_WITH_IMAGE_COLUMNS_PROPERTY_INFO } from '../../constants/tables/content'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const AMENITIES_AND_SUPPLIES_TABS = [
  {
    slug: '',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Amenity"
        content={AMENITIES}
        columns={CONTENT_WITH_IMAGE_COLUMNS_PROPERTY_INFO}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
      />
    ),
    label: 'Amenities',
    icon: AmenitiesIcon,
  },
  {
    slug: 'supplies',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Supply"
        content={SUPPLIES}
        columns={CONTENT_WITH_IMAGE_COLUMNS_PROPERTY_INFO}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
      />
    ),
    label: 'Supplies',
    icon: SuppliesIcon,
  },
  {
    slug: 'policies',
    component: ({ properties, recordAnalytics }) => (
      <ContentEditorTable
        header="Policy"
        content={POLICIES}
        columns={CONTENT_WITH_IMAGE_COLUMNS_PROPERTY_INFO}
        properties={properties}
        recordAnalytics={recordAnalytics}
        addButtonTop
      />
    ),
    label: 'Policies',
    icon: PolicyIcon,
  },
]

const AmenitiesAndSupplies = ({ properties, recordAnalytics }) => {
  return (
    <div className="AmenitiesAndSupplies">
      <ContentEditor>
        <Tabs
          properties={properties}
          recordAnalytics={recordAnalytics.bind(this, SECTIONS.PROPERTY_INFO)}
          path="content/amenities-and-supplies"
          tabs={AMENITIES_AND_SUPPLIES_TABS}
          isSubTabs
          navTitle="Property Info"
          navText="Create information on how to use amenities and where to find supplies in this property. Amenities and Supplies that are common across multiple properties should be added at the multi-property content level in the Content menu."
        />
      </ContentEditor>
    </div>
  )
}

export default AmenitiesAndSupplies
