import React from 'react'
import classNames from 'classnames'
import '../styles/Pill.scss'

function Pill({
 text, metaText, invert, thin, column, children,
}) {
  return (
    <div className={classNames('pill-wrapper', { column })}>
      {metaText && <div className="meta-text">{metaText}</div>}
      <div className={classNames('Pill', { invert }, { thin })}>{text || children}</div>
    </div>
  )
}

export default Pill

Pill.defaultProps = {
  invert: false,
}
