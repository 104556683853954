import moment from 'moment-timezone'
import { Yup } from '../../forms'
import { DatetimeField, TextField } from '../../forms/fields'

export const RESERVATION_WIZARD = ({ timezone }) => {
  return {
    0: [
      {
        label: 'Check In',
        name: 'beginDate',
        component: DatetimeField,
        props: {
          dateOnly: true,
        },
        validation: Yup.date().default(
          moment()
            .tz(timezone)
            .format('YYYY-MM-DD'),
        ),
      },
      {
        label: 'Check Out',
        name: 'endDate',
        component: DatetimeField,
        props: {
          dateOnly: true,
        },
        validation: Yup.date().default(
          moment()
            .add(1, 'days')
            .tz(timezone)
            .format('YYYY-MM-DD'),
        ),
      },
      {
        label: 'Guest Name',
        name: 'partyName',
        component: TextField,
        validation: Yup.string()
          .required('required')
          .max(50, 'Name should not exceed 50 characters'),
        props: {
          placeholder: '',
          style: { marginRight: '16px' },
        },
      },
    ],
  }
}
