import React from 'react'
import { AdvertisementExampleIcon, PortraitIcon, SquareIcon, OriginalIcon } from '../../icons'
import { AdvertisementContentSelect } from '../../components/advertisements'
import { Yup } from '../../forms'
import {
  SelectPropertiesField, TextareaField, TextField, ImageDropzoneField,
} from '../../forms/fields'
import { AdvertisementChoiceGroup } from '../../forms/fields/AdvertisementChoiceGroup'
import EnableOrSchedule from '../../forms/fields/EnableOrScheduleGroup'
import { urlPattern } from '../regex'
import DraggableMediaField from '../../forms/fields/DraggableMediaField'
import AdvertisementMedia from '../../components/advertisements/AdvertisementMedia'
import AdvertisementImagePreview from '../../forms/fields/AdvertisementImagePreview'

const COMMON_FIELDS = {
  title: {
    label: 'Ad Title',
    name: 'title',
    className: 'name',
    component: TextField,
    vertical: true,
    validation: Yup.string().required('required'),
    props: {
      placeholder: 'Name',
    },
  },
  promotion: {
    label: 'Promotion',
    name: 'promotion',
    className: 'promotion',
    component: TextField,
    vertical: true,
    props: {
      placeholder: 'Mention our company name to get $$ off',
    },
  },
  orientation: {
    label: 'Ad Orientation',
    sublabel: 'It is recommended to change the image for square and portrait formats',
    name: 'style',
    className: 'orientation',
    vertical: true,
    additionalFieldClass: 'sublabel-present',
    validation: Yup.string()
      .oneOf(['original', 'portrait', 'square'])
      .default('original')
      .required('required'),
    component: AdvertisementChoiceGroup,
    props: {
      options: [
        {
          value: 'original',
          label: 'Original',
          icon: OriginalIcon,
        },
        {
          value: 'square',
          label: 'Square',
          icon: SquareIcon,
        },
        {
          value: 'portrait',
          label: 'Portrait',
          icon: PortraitIcon,
        },
      ],
      row: true,
    },
  },
  description: {
    label: 'Description',
    name: 'description',
    component: TextareaField,
    vertical: true,
    validation: Yup.string().required('required'),
    props: {
      maxLength: 600,
      type: 'textarea',
      placeholder: 'Message to guests',
      rows: 4,
    },
  },
  message: {
    label: 'Text Message Content',
    name: 'message',
    className: 'message',
    component: TextField,
    vertical: true,
    props: {
      placeholder: 'Ex. Save 10% using the link',
    },
  },
  link: {
    label: 'Link to include in text message',
    name: 'link',
    className: 'messageLink',
    component: TextField,
    vertical: true,
    validation: Yup.string().matches(urlPattern, {
      message: 'Enter a valid url',
      excludeEmptyString: true,
    }),
    props: {
      placeholder: 'Ex. business.com/promotion',
    },
  },
  toggleGroup: {
    label: '',
    name: 'toggle',
    className: 'custom-form-group justify-content-start',
    validation: {
      status: Yup.boolean().default(false),
      schedule: Yup.boolean().default(false),
      schedule_to: Yup.date()
        .default(undefined)
        .nullable()
        .when('schedule', {
          is: true,
          then: Yup.date().when('schedule_from', {
            is: undefined || null,
            then: Yup.date().required('required'),
          }),
          otherwise: Yup.date()
            .default(undefined)
            .nullable(),
        }),
      schedule_from: Yup.date()
        .default(undefined)
        .nullable()
        .when('schedule', {
          is: true,
          then: Yup.date().when('schedule_to', {
            is: undefined || null,
            then: Yup.date().required('required'),
          }),
          otherwise: Yup.date()
            .default(undefined)
            .nullable(),
        }),
    },
    component: EnableOrSchedule,
    vertical: true,
  },
  imageURL: {
    name: 'imageURL',
    className: 'image',
    sublabel: 'Click image to change',
    label: 'Advertisement Image',
    vertical: true,
    component: ImageDropzoneField,
    validation: Yup.string()
      .required('Please upload an image for the advertisement')
      .nullable(),
    props: {
      desiredSize: { width: 1080, height: 1920 },
      showIcon: true,
      advertisement: true,
      saveOriginal: true,
      exampleIcon: AdvertisementExampleIcon,
    },
  },
  propertyIds: {
    label: 'Apply to Properties',
    name: 'propertyIds',
    vertical: true,
    component: SelectPropertiesField,
    validation: Yup.array()
      .default([])
      .atLeast(1)
      .label('property')
      .required(),
    props: {
      saveOnExit: false,
    },
  },
}

const CUSTOM_FIELDS = {
  media: {
    additionalFieldClass: 'col-sm-12',
    className: 'image',
    name: 'imageURL',
    sublabel: 'Click image to change',
    label: 'Advertisement Image',
    vertical: true,
    additionalFieldClass: 'sublabel-present',
    validation: Yup.string()
      .required('Please upload an image for the advertisement')
      .nullable(),
    component: [
      {
        component: ImageDropzoneField,
        props: {
          name: 'imageURL',
          className: 'image',
          desiredSize: { width: 1080, height: 1920 },
          flexible: true,
          showIcon: true,
          advertisement: true,
          saveOriginal: true,
          exampleIcon: AdvertisementExampleIcon,
        },
      },
      {
        component: AdvertisementMedia,
        props: {
          name: 'image',
        },
      },
    ],
  },
}

const SERVICE_FIELDS = {
  title: {
    label: 'Ad Title',
    name: 'title',
    className: 'name',
    component: TextField,
    vertical: true,
    validation: Yup.string().required('required'),
    props: {
      placeholder: 'Service Name',
    },
  },
  advertisementContentSelect: {
    component: AdvertisementContentSelect,
  },
}

const RECOMMENDATION_FIELDS = {
  advertisementContentSelect: {
    component: AdvertisementContentSelect,
  },
  title: {
    label: 'Ad Title',
    name: 'title',
    className: 'name',
    component: TextField,
    vertical: true,
    validation: Yup.string().required('required'),
    props: {
      placeholder: 'Business Name',
    },
  },
}

export const RECOMMENDATION_WIZARD = ({ isEdit }) => {
  const steps = [
    {
      className: 'advertisement-select',
      component: <AdvertisementContentSelect />,
    },
    {
      className: 'advertisement-inputs',
      fields: [
        CUSTOM_FIELDS.media,
        COMMON_FIELDS.orientation,
        RECOMMENDATION_FIELDS.title,
        COMMON_FIELDS.promotion,
        COMMON_FIELDS.description,
        COMMON_FIELDS.toggleGroup,
      ],
    },
  ]

  isEdit && steps.splice(0, 1)
  return steps
}

export const SERVICE_WIZARD = ({ isEdit }) => {
  const steps = [
    {
      className: 'advertisement-select',
      component: <AdvertisementContentSelect />,
    },
    {
      className: 'advertisement-inputs',
      fields: [
        CUSTOM_FIELDS.media,
        COMMON_FIELDS.orientation,
        SERVICE_FIELDS.title,
        COMMON_FIELDS.description,
        COMMON_FIELDS.toggleGroup,
      ],
    },
  ]
  isEdit && steps.splice(0, 1)
  return steps
}

export const CUSTOM_WIZARD = [
  {
    className: 'advertisement-inputs',
    fields: [
      COMMON_FIELDS.imageURL,
      COMMON_FIELDS.orientation,
      COMMON_FIELDS.title,
      COMMON_FIELDS.promotion,
      COMMON_FIELDS.description,
      COMMON_FIELDS.message,
      COMMON_FIELDS.link,
    ],
  },
  {
    className: 'advertisement-property-select',
    fields: [COMMON_FIELDS.toggleGroup, COMMON_FIELDS.propertyIds],
  },
]

export const ADVERTISEMENT = {
  type: 'advertisement',
  title: 'Advertisement',
  item: 'Ad',
  emptyMessage: 'You have not yet added any advertisements',
}

export const ADVERTISEMENT_RECOMMENDATIONS = ['Restaurant', 'Activity', 'Directory']
