import moment from 'moment'
import { RRule } from 'rrule'
import { ordinalDate, pluralize, weekdayString } from '../Utils'

const plural = (interval, frequency) => {
    return pluralize(interval, frequency, null, false)
}

const weekLabels = frequency => {
    if (!frequency.byweekday) {
        return 'Mon'
    }
    return weekdayString(frequency.byweekday)
}

const monthDate = frequency => {
    const date = frequency.bymonthday[frequency.bymonthday.length - 1]
    return ordinalDate(date || frequency.bymonthday)
}

const getAnnouncementSchedule = ({ announcement: { datetime } }) => {
    switch (datetime.type) {
        case 'now':
            return 'Immediately'
        case 'datetime':
            return `Once ${moment(datetime.value).format('DD-MM-YYYY')} on ${moment(datetime.value).format('hh:mm a')}`
        case 'relative':
            const relTime = datetime.frequencyType === 'hours'
                ? Math.abs(datetime.value / 60)
                : Math.abs(datetime.value)
            return `${relTime} ${datetime.frequencyType === 'hours' ? 'hours' : 'minutes'} ${datetime.to === 'checkoutTime' ? ' before Check Out' : ' after Check In'}`
        case 'recurring':
            const recTime = moment(datetime.time, 'HH:mm').format('hh:mm a')
            const frequency = RRule.parseString(datetime.value)
            if (frequency.freq === RRule.DAILY && (!frequency.interval || frequency.interval === 1)) { return `Daily at ${recTime}` }
            if (frequency.freq === RRule.DAILY && frequency.interval > 1) { return `Every ${frequency.interval} ${plural(frequency.interval, 'day')} at ${recTime}` }
            if (frequency.freq === RRule.WEEKLY) { return `Every ${frequency.interval} ${plural(frequency.interval, 'week')} on \n ${weekLabels(frequency)}` }
            if (frequency.freq === RRule.MONTHLY) { return `Every month on ${monthDate(frequency)} at ${recTime}` }
            return ''
        default:
            return 'Immediately'
    }
}

export { getAnnouncementSchedule }
