import React from 'react'
import { ContentEditor, ContentEditorDescription, ContentEditorTable } from '.'
import { WIFI, HOUSE_RULES, CONTACT_US } from '../../constants/forms/content'
import { SECTIONS } from '../../constants/thirdPartyAnalytics'

const CONTENT_SECTIONS = [HOUSE_RULES, CONTACT_US, WIFI]

const PropertyDetails = ({ properties, recordAnalytics }) => {
  return (
    <div className="PropertyDetails">
      {CONTENT_SECTIONS.map((section, index) => (
        <ContentEditor key={index}>
          <ContentEditorDescription title={section.title} description={section.description} />
          <ContentEditorTable
            header={section.title}
            content={section}
            properties={properties}
            recordAnalytics={recordAnalytics.bind(this, SECTIONS.PROPERTY_DETAILS)}
          />
        </ContentEditor>
      ))}
    </div>
  )
}

export default PropertyDetails
