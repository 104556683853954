/* eslint-disable react/display-name */
import React from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import ServiceName from '../../components/services/ServiceName'
import ServicePrice from '../../components/services/ServicePrice'
import { ListOptions, ListOption } from '../../components/list'
import { pluralize } from '../../Utils'
import { UPDATED_CELL, PROPERTIES_APPLIED, RECIPIENT_PROPERTY } from './common'
import MediaDisplay from '../../components/MediaDisplay'
import { PopoverMenu, PopoverMenuItem } from '../../components/ui'

const getServiceMedia = medias => {
  if (!medias || !medias.length) return null
  const defaultMedia = medias.find(media => media.mediaOrder === 1)
  return defaultMedia
}

// SERVICES
export const SERVICE_IMAGE = {
  accessor: 'iconURL',
  disableSortBy: true,
  Cell: ({
    cell: { value },
    row: {
      original: { serviceMedias, iconURL },
    },
  }) => {
    const item = getServiceMedia(serviceMedias)
    return <MediaDisplay item={item} className="MediaDisplay" parentClassName="service-ad-column" />
  },
  options: {
    classNames: 'shrink padding pr-0',
  },
}

export const SERVICE_NAME = {
  Header: 'Service Name',
  id: 'name',
  accessor: 'name',
  sortType: (a, b) => {
    const nameA = get(a, 'original.name', '')
    const nameB = get(b, 'original.name', '')
    return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
  },
  Cell: ({ row: { original } }) => {
    return (
      <ServiceName
        title={original.name}
        nickName={original.nickName}
        summary={original.summarySubtitle}
        template={original.template}
      />
    )
  },
}

export const SERVICE_PRICE = {
  Header: 'Price',
  accessor: 'price',
  minWidth: 160,
  Cell: ({ row: { original: service }, initialState: { supportPaymentProvider, dispatch } }) => {
    return (
      <ServicePrice
        service={service}
        hasPaymentProvider={service.paymentProviderId}
        supportPaymentProvider={supportPaymentProvider}
        dispatch={dispatch}
      />
    )
  },
  options: {
    hideOn: ['tablet', 'mobile'],
  },
}

export const SERVICE_STATUS = {
  Header: 'Status',
  accessor: ({ enabled }) => (enabled ? 'enabled' : 'disabled'),
  Cell: ({ cell: { value } }) => {
    return (
      <div
        className={classNames(
          'capitalize text-color',
          { success: value === 'enabled' },
          { danger: value === 'disabled' },
        )}
      >
        {value}
      </div>
    )
  },
  options: {
    hideOn: ['mobile'],
  },
}

const PROMITION_KEYS = {
  homepage: 'Homepage',
  checkout: 'Check Out',
  artMode: 'Slideshow',
  slideshow: 'Slideshow',
}

export const SERVICE_PROMOTIONS = {
  Header: 'Promoted',
  accessor: ({ promote }) => promote,
  Cell: ({ cell: { value } }) => {
    return <ul>{value.map(location => (PROMITION_KEYS[location] ? <li>{PROMITION_KEYS[location]}</li> : null))}</ul>
  },
  options: {
    classNames: 'status',
    hideOn: ['mobile'],
  },
}

export const SERVICE_COLUMNS = [
  {
    id: 'services',
    columns: [
      {
        ...SERVICE_IMAGE,
      },
      { ...SERVICE_NAME },
      {
        ...SERVICE_PRICE,
      },
      {
        Header: 'Requires',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { requireAcceptTerms, requireApproval },
          },
        }) => {
          const requiredItems = []
          if (requireApproval) {
            requiredItems.push({
              key: 'requireApproval',
              children: 'Approval',
            })
          }
          if (requireAcceptTerms) {
            requiredItems.push({
              key: 'requireAcceptTerms',
              children: 'Terms and conditions',
            })
          }

          if (requiredItems.length) {
            return (
              <ul>
                {requiredItems.map(requiredItem => (
                  <li {...requiredItem} />
                ))}
              </ul>
            )
          }
          return <div>N/A</div>
        },
        options: {
          hideOn: ['tablet', 'mobile'],
        },
      },
      RECIPIENT_PROPERTY,
      {
        ...SERVICE_STATUS,
      },
      {
        ...UPDATED_CELL,
      },
      {
        accessor: 'options',
        disableSortBy: true,
        Cell: ({ row: { original: service }, initialState: { onUpdateItem, onDeleteItem, rowAction } }) => {
          const edit = () => rowAction(service)
          const toggle = () => {
            const enabled = !service.enabled
            let { promote } = service
            if (enabled) {
              promote = ['serviceListing']
              if (service.template === 'late_checkout') {
                if (service.extra) {
                  service.extra.disableOnBtbRes = true
                } else {
                  service.extra = { disableOnBtbRes: true }
                }
              }
            }
            if (!enabled) {
              promote = []
              if (service.template === 'late_checkout') {
                if ('disableOnBtbRes' in service.extra) {
                  delete service.extra.disableOnBtbRes
                }
              }
            }
            onUpdateItem(
              {
                ...service,
                promote,
                enabled,
              },
              true,
            )
          }
          const remove = () => {
            (service.propertyIds.length === 0
              || window.confirm(
                `Are you sure you want to remove this service? It is used by ${pluralize(
                  service.propertyIds.length,
                  'property',
                  null,
                  true,
                )}.`,
              ))
              && onDeleteItem(service)
          }
          return (
            <PopoverMenu>
              <PopoverMenuItem onClick={edit}>Edit</PopoverMenuItem>
              <PopoverMenuItem onClick={toggle}>{service.enabled ? 'Disable' : 'Enable'}</PopoverMenuItem>
              <PopoverMenuItem onClick={remove}>Delete</PopoverMenuItem>
            </PopoverMenu>
          )
        },
        options: {
          classNames: 'right',
        },
      },
      {
        accessor: 'createdAt',
      },
    ],
  },
]

export const ADVERTISEMENT_BUTTON = {
  id: 'button',
  Header: ' ',
  Cell: ({ row: { original }, initialState: { onAdvertise } }) => {
    return (
      <button
        onClick={() => onAdvertise(original)}
        disabled={!original.enabled}
        className={original.enabled ? 'button' : classNames('BackLink  bold')}>
        {original.enabled ? 'Create' : 'Disabled'}
      </button>
    )
  },
  options: {
    classNames: 'middle',
  },
}

export const SERVICE_ADVERTISEMENT_SELECT_COLUMNS = [
  {
    id: 'services',
    columns: [SERVICE_IMAGE, SERVICE_NAME, PROPERTIES_APPLIED, ADVERTISEMENT_BUTTON],
  },
]
