import React from 'react'
import { connect } from 'formik'
import { get, isArray, isFunction } from 'lodash'
import classNames from 'classnames'

const OrderDeadlinePreview = ({
 formik: { values }, name, prefix, format, placeholder, suffix, ...props
}) => {
  let value = get(values, name)
  if (isArray(name)) {
    const currentValue = Object.entries(values).find(([key, value]) => value && name.includes(key))
    if (currentValue) {
      [name, value] = currentValue
    }
  }

  if (format && isFunction(format)) value = format(value, values)

  let content = ''
  content = (
    <>
      {prefix}
      {value || placeholder}
      {' '}
      {suffix}
    </>
  )

  if (!value) return null
  return (
    <div {...props.props} className={classNames('PreviewText', props.className)}>
      {content}
    </div>
  )
}

export default connect(OrderDeadlinePreview)
