/* eslint-disable react/display-name */
import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import {
 TextField, PhoneField, SelectPropertiesField, ToggleSwitchField,
} from '../../forms/fields'
import { CheckboxField } from '../../forms/fields/ChoiceField'
import { Yup, FormModal, FormGroup, SubmitButton } from '../../forms'
import face1 from '../../images/smileys/face-1-angry.png'
import face2 from '../../images/smileys/face-2-sad.png'
import face3 from '../../images/smileys/face-3-meh.png'
import face4 from '../../images/smileys/face-4-good.png'
import face5 from '../../images/smileys/face-5-great.png'
import { CheckboxGroup } from '../../forms/fields/ChoiceGroup'
import { MESSAGES } from '../../constants/strings'

const getRatingOptions = type => [
  {
    value: 1,
    image: {
      src: face1,
      fadeOnDisable: true,
      smallIcon: true,
      hideCheckBox: true,
    },
    head: type,
    related: [
      {
        name: type,
        disableOnUnCheck: true,
      },
    ],
  },
  {
    value: 2,
    image: {
      src: face2,
      fadeOnDisable: true,
      smallIcon: true,
      hideCheckBox: true,
    },
    head: type,
    related: [
      {
        name: type,
        disableOnUnCheck: true,
      },
    ],
  },
  {
    value: 3,
    image: {
      src: face3,
      fadeOnDisable: true,
      smallIcon: true,
      hideCheckBox: true,
    },
    head: type,
    related: [
      {
        name: type,
        disableOnUnCheck: true,
      },
    ],
  },
  {
    value: 4,
    image: {
      src: face4,
      fadeOnDisable: true,
      smallIcon: true,
      hideCheckBox: true,
    },
    head: type,
    related: [
      {
        name: type,
        disableOnUnCheck: true,
      },
    ],
  },
  {
    value: 5,
    image: {
      src: face5,
      fadeOnDisable: true,
      smallIcon: true,
      hideCheckBox: true,
    },
    head: type,
    related: [
      {
        name: type,
        disableOnUnCheck: true,
      },
    ],
  },
]

const relations = type => [
  {
    name: `setting.${type}_notify_rating`,
    onCheck: [5, 4, 3, 2, 1],
    onUnCheck: [],
  },
]

const NotificationContainer = ({ handleSubmit, notification }) => {
  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader name="notification" hasItem={Object.keys(notification).length !== 0} />
      <ModalBody>
        <FormGroup required label="Name " htmlFor="name">
          <TextField name="name" />
        </FormGroup>
        <FormGroup required={() => true} label="Contact Details" htmlFor="contactDetails">
          <div className="ApprovalField row">
            <div className="col-sm-12 fields">
              <div className="field">
                <PhoneField name="phone" placeholder="Mobile Phone Number" />
              </div>
              <div className="or-text">and / or</div>
              <div className="field">
                <TextField name="email" placeholder="Email Address" />
              </div>
            </div>
          </div>
        </FormGroup>
        <FormGroup label="What to be notified on?" labelClassName="mt-4" rowClassName="align-items-center">
          <h6 className="notify-title">Notify on survey rating</h6>
          <div className="check-in-check-out-rate">
            <CheckboxField name="checkin" label="Check In" isHead relations={relations('checkin')} />
            <CheckboxGroup name="setting.checkin_notify_rating" options={getRatingOptions('checkin')} row flex />
          </div>
          <div className="check-in-check-out-rate">
            <CheckboxField name="checkout" label="Check Out" isHead relations={relations('checkout')} />
            <CheckboxGroup name="setting.checkout_notify_rating" options={getRatingOptions('checkout')} row flex />
          </div>
        </FormGroup>
        <FormGroup required label="Properties">
          <SelectPropertiesField name="propertyIds" />
        </FormGroup>
        <FormGroup label="Set as Default Contact" rowClassName="align-items-center">
          <ToggleSwitchField name="isDefault" toggleSwitch />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <SubmitButton>Save</SubmitButton>
      </ModalFooter>
    </form>
  )
}

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .phone('Invalid phone')
    .atLeastOne(['phone', 'email']),
  email: Yup.string()
    .email('Invalid email')
    .atLeastOne(['phone', 'email']),
  name: Yup.string().required('required'),
  propertyIds: Yup.array()
    .atLeast(1)
    .label('property'),
  setting: Yup.object()
    .when('checkin', {
      is: checkIn => checkIn === true,
      then: Yup.object().shape({
        checkin_notify_rating: Yup.array()
          .atLeast(1)
          .label('rating'),
      }),
    })
    .when('checkout', {
      is: checkOut => checkOut === true,
      then: Yup.object().shape({
        checkout_notify_rating: Yup.array()
          .atLeast(1)
          .label('rating'),
      }),
    }),
})

export default props => {
  const {
    notification: { id, properties, phone, email, name, checkin, checkout, isDefault, setting },
    onSave,
    dispatch,
  } = props
  const initialValues = {
    phone: phone || '',
    email: email || '',
    name: name || '',
    propertyIds: (properties || []).map(({ id }) => id),
    checkin: checkin || false,
    checkout: checkout || false,
    isDefault: isDefault || false,
    setting: setting || {
      checkin_notify_rating: [],
      checkout_notify_rating: [],
    },
  }

  const render = p => <NotificationContainer {...p} {...props} />

  const formatValues = v => {
    return {
      id,
      ...v,
    }
  }

  return (
    <FormModal
      formatValues={formatValues}
      validationSchema={validationSchema}
      initialValues={initialValues}
      render={render}
      onSave={onSave}
      dispatch={dispatch}
    />
  )
}
