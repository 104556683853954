import React, { useState } from 'react'
import Moment from 'react-moment'
import { Table, Form, Input, Alert } from 'reactstrap'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { FaTrashAlt } from 'react-icons/fa'
import { SyncIcon, BellIcon } from '@primer/octicons-react'
import { closeModal } from '../../reducers/modals'
import api from '../../api'
import { ModalHeader, ModalBody, ModalFooter } from '../../components/modal'
import { apiRequest } from '../../reducers/data'
import { FormGroup } from '../../forms'
import { Loading } from '../../components/PageStates'
import { HandleIcon } from '../../icons'
import { confirm } from './AlertMessageContainer'
import { MESSAGES } from '../../constants/strings'

const ICalReservationsContainer = props => {
  const propertyId = props?.property?.id

  const [currentIcal, setCurrentIcal] = useState('')
  const [currentTitle, setCurrentTitle] = useState('')
  const [events, setEvents] = useState('')
  const [notified, setNotified] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [icalInvalid, setIcalInvalid] = useState(false)
  const [createForm, setCreateForm] = useState(props?.createForm || false)
  const [icals, setIcals] = useState(props?.property?.icals ? [...props?.property?.icals] : [])
  const [property, setProperty] = useState(props?.property || {})
  const [hasReservations, setHasReservations] = useState(props?.hasReservations || false)
  const [errorMessage, setErrorMessage] = useState(props?.hasReservations || false)
  const [unsupported, setUnSupported] = useState(null)
  const onSave = async e => {
    e.preventDefault()
    setLoading('Syncing iCal(s)')
    const { dispatch } = props
    await dispatch(
      apiRequest(['properties', propertyId, 'ical'], 'POST', {
        icals,
      })
    )
    setLoading(false)
    dispatch(closeModal())
    window.location.reload()
  }

  const setCreating = () => {
    setIsCreating(previousValue => !previousValue)
    setCurrentIcal('')
    setCurrentTitle('')
    setEvents('')
  }

  const onIcalChange = value => {
    const oldIcal = icals?.find(ical => ical.url === value)
    let isIcalInvalid = false
    let errorMessage = ''
    if (oldIcal) {
      isIcalInvalid = true
      errorMessage = MESSAGES.ICAL_ALREADY_EXISTS
    }
    setIcalInvalid(isIcalInvalid)
    setCurrentIcal(value)
    setErrorMessage(errorMessage)
  }

  const onFirstAdd = async e => {
    const newIcal = {
      url: currentIcal,
      title: currentTitle,
    }
    await icals.push(newIcal)
    await onSave(e)
  }

  const onAdd = () => {
    let newIcal = icals.find(ical => ical.url === currentIcal)
    if (!newIcal) {
      newIcal = {
        url: currentIcal,
        title: currentTitle,
      }
      setIcals([...icals, newIcal])
    }

    setIsCreating(false)
    setShowNotification(true)
    setCreateForm(false)
    setEvents('')
  }

  const onCancel = async () => {
    const { property, dispatch } = props
    setIcals([...property?.icals])
    dispatch(closeModal())
  }

  const onDelete = index => {
    const newIcals = [...icals]
    newIcals.splice(index, 1)
    setShowNotification(true)
    setIcals(newIcals)
  }

  const notifyIcal = async () => {
    const res = await api.post('sync/ical-problem', { url: currentIcal })
    // not showing an error message to the user about us not being notified.
    res && setNotified(true)
  }

  const onLoadEvents = async e => {
    e.preventDefault()

    setLoading('Retrieving Reservations')
    setEvents(null)
    setUnSupported(null)
    setNotified(false)

    const {
      json: { events, unsupported, message },
    } = await props.dispatch(
      apiRequest(['sync', 'ical-preview'], 'POST', {
        propertyId: props.property.id,
        url: currentIcal,
      })
    )

    setEvents(events)
    setUnSupported(unsupported)
    setLoading(false)
    setErrorMessage(message)
    setIcalInvalid(!!message)
  }

  const renderForm = () => {
    return (
      <div>
        <ModalHeader closeModal={onCancel}>{MESSAGES.SETUP_ICAL_LINK}</ModalHeader>
        {loading ? (
          <ModalBody>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Loading
                style={{
                  margin: 0,
                  marginRight: '18px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              />
              <div>{loading}</div>
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <Form>
              <FormGroup required label="Enter the public calendar URL with your reservations:" vertical>
                <div className="row align-items-center">
                  <div className="col-sm-8">
                    <Input
                      name="currentIcal"
                      className="form-control"
                      placeholder="Paste Calendar URL (.ics)"
                      invalid={icalInvalid}
                      autoFocus
                      type="text"
                      value={currentIcal}
                      style={{
                        flex: 1,
                        marginRight: 10,
                      }}
                      onChange={e => onIcalChange(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-4">
                    <button
                      className="btn-sm btn btn-outline-primary"
                      onClick={onLoadEvents}
                      disabled={currentIcal.length === 0 || icalInvalid}
                      data-cy="reservations-sync-ical-preview">
                      Preview
                    </button>
                  </div>
                  {icalInvalid && <div className="col-sm-12 validation-error">{errorMessage}</div>}
                </div>
              </FormGroup>
              <FormGroup label="Source Title:" vertical>
                <div className="row align-items-center">
                  <div className="col-sm-8">
                    <Input
                      name="currentTitle"
                      className="form-control"
                      placeholder="Title"
                      maxLength={50}
                      type="text"
                      value={currentTitle}
                      style={{
                        flex: 1,
                        marginRight: 10,
                      }}
                      onChange={e => setCurrentTitle(e.target.value)}
                    />
                  </div>
                </div>
              </FormGroup>
            </Form>
            {events && (
              <div>
                Upcoming reservations for this property are shown below. If these are correct, click{' '}
                <strong>Add</strong>.
                {unsupported && (
                  <div style={{ marginTop: '10px' }} className="white-content-box import-properties-info-container">
                    {!notified ? (
                      <div>
                        Guest names for all reservations will default to "Guest." Due to the industry trends of
                        protecting guest information most iCal links will not provide guest names. Each source for iCal
                        links follow different formats.{' '}
                        <div className="link inline" onClick={notifyIcal}>
                          Click here
                        </div>{' '}
                        if you want to request us to search for guest name data on your iCal.
                      </div>
                    ) : (
                      <div>Thank you helping us improve.</div>
                    )}
                  </div>
                )}
                <Table bordered size="sm" style={{ width: '100%', marginTop: 10 }}>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}>Check In</th>
                      <th style={{ width: '30%' }}>Check Out</th>
                      <th style={{ width: '30%' }}>Guest Name</th>
                    </tr>
                  </thead>
                  <tbody data-cy="reservations-sync-ical-list">
                    {events.slice(0, 3).map(e => (
                      <tr key={`${e.beginDate}-${e.partyName}`}>
                        <td>
                          <Moment format="MM/DD/YYYY">{e.beginDate}</Moment>
                        </td>
                        <td>
                          <Moment format="MM/DD/YYYY">{e.endDate}</Moment>
                        </td>
                        <td data-cy="reservations-sync-ical-list-partyName">
                          {e.partyName && e.partyName.trim().length ? e.partyName.trim() : 'Guest'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </ModalBody>
        )}
        <ModalFooter>
          <div className="row">
            {!createForm && (
              <>
                <button className="btn btn-outline-primary btn-small mr-3 min-width-md" onClick={setCreating}>
                  Back
                </button>
                <button
                  disabled={!events || loading}
                  className="btn btn-primary btn-small min-width-md"
                  onClick={onAdd}>
                  Add
                </button>
              </>
            )}

            {createForm && (
              <>
                <button disabled={!events || loading} className="btn btn-link mr-2" onClick={onAdd}>
                  Add Another iCal
                </button>
                <button
                  disabled={!events || loading}
                  className="btn btn-primary btn-small btn-has-icon"
                  // eslint-disable-next-line no-confusing-arrow
                  onClick={async e =>
                    hasReservations
                      ? confirm({
                          title: MESSAGES.SYNC_CONFIRMATION_TITLE,
                          body: MESSAGES.ICAL_SYNC_CONFIRMATION_MESSAGE,
                          onConfirm: () => onFirstAdd(e),
                        })
                      : await onFirstAdd(e)
                  }>
                  <SyncIcon />
                  Save & Sync
                </button>
              </>
            )}
          </div>
        </ModalFooter>
      </div>
    )
  }

  const renderList = () => {
    const _icals = [...icals]
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const item = _icals.splice(fromIndex, 1)[0]
        _icals.splice(toIndex, 0, item)
        const shouldShowNotification = _icals.length > 1
        setIcals(_icals)
        setShowNotification(shouldShowNotification)
      },
      nodeSelector: '.ical-list',
      handleSelector: 'span',
    }

    return (
      <div>
        <ModalHeader closeModal={onCancel}>{MESSAGES.MANAGE_ICAL_LINKS}</ModalHeader>
        {loading ? (
          <ModalBody>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Loading
                style={{
                  margin: 0,
                  marginRight: '18px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              />
              <div>{loading}</div>
            </div>
          </ModalBody>
        ) : (
          <>
            <Alert color="info" isOpen={showNotification} toggle={() => setShowNotification(!showNotification)}>
              <p>
                <BellIcon />
                {MESSAGES.ICAL_CHANGE_NOTIFICATION}
              </p>
            </Alert>

            <ModalBody>
              <div>
                <p>{MESSAGES.ICAL_INFO_MESSAGE}</p>
              </div>
              <div className="d-flex flex-row-reverse">
                <button disabled={icals.length > 9} className="btn button outline" onClick={setCreating}>
                  {MESSAGES.ADD_ICAL_LINK}
                </button>
              </div>
              <div>
                {icals.length > 0 && (
                  <ReactDragListView {...dragProps}>
                    <div className="ical-list-wrapper">
                      {icals.map((item, index) => (
                        <div key={index} className="ical-list">
                          <div>
                            <h6>{item.title ? item.title : 'No title'}</h6>
                            <a href="#">{item.url}</a>
                          </div>
                          <div className="ical-manage-actions">
                            <i>
                              <FaTrashAlt
                                onClick={() =>
                                  confirm({
                                    title: MESSAGES.DELETE_ICAL_LINK,
                                    body: MESSAGES.REMOVE_ICAL_LINK_MESSAGE,
                                    onConfirm: () => {
                                      onDelete(index)
                                    },
                                  })
                                }
                              />
                            </i>
                            <span className="handle shrink">
                              <HandleIcon />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </ReactDragListView>
                )}
              </div>
            </ModalBody>
          </>
        )}
        <ModalFooter>
          <button
            disabled={loading}
            className="btn btn-primary btn-small btn-has-icon"
            onClick={e =>
              hasReservations && property.icals.length === 0
                ? confirm({
                    title: MESSAGES.SYNC_CONFIRMATION_TITLE,
                    body: MESSAGES.ICAL_SYNC_CONFIRMATION_MESSAGE,
                    onConfirm: () => onSave(e),
                  })
                : icals.length === 0
                ? confirm({
                    title: MESSAGES.REMOVE_ICAL_SYNC,
                    body: MESSAGES.REMOVE_ICAL_SYNC_MESSAGE,
                    onConfirm: () => onSave(e),
                  })
                : onSave(e)
            }>
            <SyncIcon />
            Save & Sync
          </button>
        </ModalFooter>
      </div>
    )
  }

  return <div className="iCalReservationsModalContainer">{isCreating || createForm ? renderForm() : renderList()}</div>
}

export default ICalReservationsContainer
