import React from 'react'

const TablePreviewVideo = ({
 key, style, contain, width = 110, height = 88, fallbackIcon, ...props
}) => {
  const addDefaultSrc = ev => {
    ev.target.src = fallbackIcon
    ev.target.style.objectFit = 'contain'
  }

  return (
    <video
      key={key}
      onError={addDefaultSrc}
      alt="table-preview"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        width,
        height,
        objectFit: contain ? 'contain' : 'cover',
        padding: contain && 12,
        borderRadius: 2,
        ...style,
      }}
      {...props}
    />
  )
}

TablePreviewVideo.defaultProps = {
  alt: 'preview',
  contain: false,
}
export default TablePreviewVideo
